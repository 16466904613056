<ng-template cxOutletRef="PageNotFoundTemplate">
  <div class="container-fluid">
    <div class="col-10">
      <h1 class="pagenot-found--header">{{ title$ | async }}</h1>
    </div>
    <div class="col-10">
      <cx-page-slot class="pagenot-found--message" position="ErrorPageMessagePosition"></cx-page-slot>
    </div>
    <div class="pagenot-found--image col-10">
      <cx-media [container]="pageNotFoundImgContent.media" alt="PageNotFound"></cx-media>
    </div>
  </div>
</ng-template>
