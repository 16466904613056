<section class="container-fluid mb-80">
  <ng-container *ngIf="answerInfo$ | async as info; else faqLoader">
    <h1 class="mb-0 pb-40">
      <cx-icon type="BackButton" [routerLink]="{ cxRoute: 'FAQPage' } | cxUrl"></cx-icon>
      {{ info.FAQQuestion.FAQContainer.topicName }}
    </h1>
    <ng-container>
      <div class="row">
        <div class="col-10 col-lg-7">
          <!-- Renders the child component-->
          <ng-template
            [cxComponentWrapper]="{
              flexType: info.typeCode,
              typeCode: info.typeCode,
              uid: info.uid
            }"
          >
          </ng-template>
        </div>
        <div class="col-7 d-md-none d-lg-block">
          <cx-page-slot class="faq-right" position="VCFAQRightDownContentSlotPosition"></cx-page-slot>
        </div>
        <div class="col-10 d-lg-none d-md-block">
          <cx-page-slot
            position="TabVCFAQContactPosition"
            class="d-flex flex-column align-items-center justify-content-between"
          >
          </cx-page-slot>
        </div>
      </div>
    </ng-container>
  </ng-container>
</section>
<ng-template #faqLoader>
  <app-loader-icon></app-loader-icon>
</ng-template>
