import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { FavoriteProductListService } from 'src/app/services/favorite-product-list/favorite-product-list.service';
@Injectable({ providedIn: 'root' })
export class FavouriteListResolver implements Resolve<Observable<any>> {
  constructor(private favoriteProductListService: FavoriteProductListService) {}

  resolve(): Observable<any> {
    return this.favoriteProductListService.getFavoriteList();
  }
}
