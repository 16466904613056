import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { ProductDetailsService } from 'src/app/services/alcon-pdp/product-details.service';
import { DropdownConfig, DropdownOptions } from 'src/app/shared/model/common.mode';
import {
  BasicProductSelectedCheckboxStatus,
  BasicProductSelectorPanelConfig,
  QuantityErrorCodes,
} from 'src/app/shared/model/product.model';

@Component({
  selector: 'app-solution-products',
  templateUrl: './solution-products.component.html',
  styleUrls: ['./solution-products.component.scss'],
})
export class SolutionProductsComponent {
  radioAdditionalProducts = '';
  disableRadio = false;
  clcList: DropdownOptions[] = [];
  dryeyeList: DropdownOptions[] = [];
  editSolutionData: any;
  lensesLabel: DropdownConfig = {
    defaultSelectText: 'Select',
  };
  private selectedSolutionsValue: Subject<any> = new Subject();
  selectedProductCode = this.selectedSolutionsValue.asObservable();

  private deleteSolutionsValue: Subject<any> = new Subject();
  deleteSolutions = this.deleteSolutionsValue.asObservable();

  private selectedSolutionError: Subject<[boolean | QuantityErrorCodes, string]> = new Subject();
  selectedSolutionErrorStatus = this.selectedSolutionError.asObservable();

  private productChangedSubject: Subject<string> = new Subject();
  productChanged = this.productChangedSubject.asObservable();

  isDTP = false;
  private panelConfiguration: BasicProductSelectorPanelConfig = {
    checkboxStatus: {
      leftEye: false,
      rightEye: false,
      bothEye: false,
    } as BasicProductSelectedCheckboxStatus,
    solutions: {
      multiLine: true,
    },
  };

  index: number;

  solProducts: DropdownOptions;
  constructor(private productService: ProductDetailsService) {}

  radioClickProducts(additionalProduct): void {
    this.productChangedSubject.next(this.solProducts?.value);
    this.solProducts = null;
    this.unSelectOption();

    if (additionalProduct === 'contactLensSolution') {
      if (this.clcList?.length === 1) {
        this.solutionDrpdwnSelected(this.clcList[0]);
      }
    } else if (additionalProduct === 'dryEyeProducts') {
      if (this.dryeyeList?.length === 1) {
        this.solutionDrpdwnSelected(this.dryeyeList[0]);
      }
    }
  }

  deleteSolutionProducts(): void {
    this.unSelectOption();
    this.productChangedSubject.next(this.solProducts?.value);
    this.deleteSolutionsValue.next(this.index);
  }

  solutionDrpdwnSelected(product: DropdownOptions): void {
    this.productChangedSubject.next(this.solProducts?.value);
    this.productService.removeAddedItem(this.solProducts?.value);
    this.solProducts = product;
    this.editSolutionData = null;
  }

  selectedSolutionLensValue(value): void {
    this.selectedSolutionsValue.next({ value, index: this.index });
  }

  private unSelectOption(): void {
    this.dryeyeList?.forEach((list) => (list.selected = false));
    this.clcList?.forEach((list) => (list.selected = false));
  }

  panelErrors(value: boolean | QuantityErrorCodes, productCode: string): void {
    this.selectedSolutionError.next([value, productCode]);
  }
}
