import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbAccordionModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthGuard, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { CmsPageGuard, IconModule, MediaModule, PageComponentModule, PageSlotModule } from '@spartacus/storefront';
import { AlconCommonModule } from 'src/app/cms-components/common/alcon-common.module';
import { LegalGuard } from 'src/app/services/legal-announcement/legal-announcement.guard';
import { UserPermissionGuard } from 'src/app/services/user-permission.guard';
import { AccountSelectorGuard } from '../multi-account-selector/guards/account-selector.guard';
import { FaqAnswerComponent } from './components/faq-answer/faq-answer.component';
import { FaqQuestionComponent } from './components/faq-question/faq-question.component';
import { FaqSearchComponent } from './components/faq-search/faq-search.component';
import { FaqTopicComponent } from './components/faq-topic/faq-topic.component';
import { FaqAnswerPageComponent } from './faq-answer-page/faq-answer-page.component';
import { FaqPageComponent } from './faq-page/faq-page.component';
@NgModule({
  declarations: [
    FaqPageComponent,
    FaqTopicComponent,
    FaqQuestionComponent,
    FaqAnswerPageComponent,
    FaqAnswerComponent,
    FaqSearchComponent,
  ],
  imports: [
    NgbAccordionModule,
    BrowserAnimationsModule,
    CommonModule,
    ConfigModule,
    RouterModule,
    FormsModule,
    I18nModule,
    UrlModule,
    NgbModule,
    IconModule,
    MediaModule,
    PageComponentModule,
    AlconCommonModule,
    PageSlotModule,
    RouterModule.forChild([
      {
        path: null,
        component: FaqPageComponent,
        data: { pageLabel: 'FAQ', Type: 'FAQ', cxRoute: 'FAQPage' },
        canActivate: [AuthGuard, AccountSelectorGuard, LegalGuard, CmsPageGuard, UserPermissionGuard],
        children: [
          {
            path: 'null',
            component: FaqAnswerPageComponent,
            data: { pageLabel: 'FAQ', cxRoute: 'FAQAnswerPage' },
          },
        ],
      },
    ]),
    ConfigModule.withConfig({
      routing: {
        protected: true,
        routes: {
          FAQPage: {
            paths: ['FAQ'],
          },
          FAQAnswerPage: {
            paths: [':id'],
            paramsMapping: { id: 'id' },
          },
        },
      },
    }),
    ConfigModule.withConfig({
      cmsComponents: {
        VisioncareFAQContainer: {
          component: FaqTopicComponent,
        },
        VisioncareQuestionAnswerComponent: {
          component: FaqQuestionComponent,
        },
        VisioncareFAQSearchBoxFlexComponent: {
          component: FaqSearchComponent,
        },
        VisioncareAnswer: {
          component: FaqAnswerComponent,
        },
      },
    }),
  ],
  exports: [],
  providers: [],
})
export class FAQModule {}
