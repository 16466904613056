<button
  appAddToCart
  *ngIf="selectedProduct"
  [orderType]="orderType"
  [product]="lensSelectedValues"
  (addingtocartLoad)="addingtocartLoad($event)"
  (addingtocartSuccess)="addingtocartSuccess($event)"
  (emitErrorMessage)="emitErrorMessage($event)"
  [maxQuantity]="maxQuantity"
  class="btn btn-primary add-to-cart-button text-uppercase"
>
  {{ addToCartText }}
  <span class="addedToCartIcon" *ngIf="addingtocartLoading">
    <cx-icon class="load-icon" type="LoadIcon"></cx-icon>
  </span>
  <span class="addedToCartIcon" *ngIf="addingtocartTick">
    <cx-icon class="tick-icon" type="TickIcon"></cx-icon>
  </span>
</button>
