import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService, SiteContextConfig } from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable()
export class CustomerContactService {
  constructor(
    private httpClient: HttpClient,
    private occEndPoints: OccEndpointsService,
    private siteContextConfig: SiteContextConfig
  ) {}
  public getRegionalZone(): Observable<any> {
    const [selectedMarket] = this.siteContextConfig.context.custom;
    const params = new HttpParams().set('fields', 'FULL').set('countryIsoCode', selectedMarket.toUpperCase());
    const url = this.occEndPoints.buildUrl('regional-zone');
    return this.httpClient.get<any>(url, {
      params,
    });
  }
}
