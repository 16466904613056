import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoutesService, AuthService } from '@spartacus/core';
import { IconLoaderService } from '@spartacus/storefront';
import { Observable, Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { DashboardPageService } from 'src/app/services/dashboard/dashboard-page.service';
import { VcGlobalSearchService } from 'src/app/services/vc-global-search/vc-global-search.service';

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderNavComponent implements OnInit, OnDestroy {
  private isUserLoggedIn = false;
  private subscriptions = new Subject<void>();
  private isNewOrderDisplay = false;
  private isProfileDisplay = false;
  routeType;
  productSearch$: Observable<boolean> = this.vcGlobalSearchService.productSearch.pipe(startWith(false));
  @HostListener('document:click', ['$event'])
  hideDropdown(event): void {
    if (!this.eleRef.nativeElement.contains(event.target)) {
      this.isProfileDisplay = false;
    }
  }
  constructor(
    private authService: AuthService,
    private iconService: IconLoaderService,
    private activatedRoutesService: ActivatedRoutesService,
    private httpClient: HttpClient,
    private eleRef: ElementRef,
    private dashboardPageService: DashboardPageService,
    private vcGlobalSearchService: VcGlobalSearchService
  ) {}

  ngOnInit(): void {
    this.authService
      .isUserLoggedIn()
      .pipe(takeUntil(this.subscriptions))
      .subscribe((isUserLoggedIn) => (this.isUserLoggedIn = isUserLoggedIn));
    this.iconService.getHtml('closeProfileIcon');
    this.activatedRoutesService.routes$.pipe(takeUntil(this.subscriptions)).subscribe((routeData) => {
      this.routeType = routeData[routeData.length - 1].data.Type;
    });
  }
  toggleProfileMenu(event): void {
    event.preventDefault();
    event.stopPropagation();
    this.isProfileDisplay = !this.isProfileDisplay;
  }
  vcGlobalsearchoutsideclick(e): void {
    if (e.target === e.currentTarget) {
      this.vcGlobalSearchService.productSearch.next(false);
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.next();
    this.subscriptions.complete();
  }
}
