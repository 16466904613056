import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { GlobalMessageService, GlobalMessageType, SemanticPathService } from '@spartacus/core';
import { Observable, Subject, of } from 'rxjs';
import { skip, startWith, takeUntil } from 'rxjs/operators';
import { OrderHistoryService } from 'src/app/services/order-history/order-history.service';
import { GlobalMessage } from 'src/app/shared/constants/constants';
import { ModalOptions } from 'src/app/shared/model/common.mode';
import { PatientProfileData } from 'src/app/shared/model/patient.model';
import { getPatientInfo } from 'src/app/store/Selectors/patient.selector';

@Component({
  selector: 'app-alcon-patient-profile',
  templateUrl: './alcon-patient-profile.component.html',
  styleUrls: ['./alcon-patient-profile.component.scss'],
})
export class AlconPatientProfileComponent implements OnInit, OnDestroy {
  constructor(
    private cd: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private globalMessageService: GlobalMessageService,
    private orderService: OrderHistoryService,
    private store: Store,
    private semanticPathService: SemanticPathService
  ) {}
  patientName: string;
  patientID;
  mainAddress;
  additionalAddress;
  patientProfileInfo;
  public modalOptions = {
    open: false,
    header: false,
    modalTitle: '',
  } as ModalOptions;
  productOpen: boolean;
  patientOrderhistory;
  orderCount: Observable<boolean> = of(false);
  private componetSubscription = new Subject<void>();
  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(takeUntil(this.componetSubscription)).subscribe((value) => {
      if (value.isScheduled) {
        this.globalMessageService.add(
          value.orderScheduled,
          GlobalMessageType.MSG_TYPE_CONFIRMATION,
          GlobalMessage.TIMEOUT
        );
      }
    });
    this.patientID = this.activatedRoute?.snapshot?.params?.profileId;
    this.patientOrderhistory = this.activatedRoute?.snapshot?.data?.patientOrderhistory;
    this.orderCount = this.orderService.getPatientOrderHistoryCount.pipe(
      startWith(
        this.patientOrderhistory?.orders?.length > 0 || this.patientOrderhistory?.replenishmentOrders?.length > 0
      ),
      takeUntil(this.componetSubscription)
    );
    this.store
      .select(getPatientInfo(this.patientID))
      .pipe(
        skip(1),
        startWith(this.activatedRoute.snapshot.data.patientProfileInfo),
        takeUntil(this.componetSubscription)
      )
      .subscribe((patient: PatientProfileData) => {
        this.assignPatientData(patient);
      });
  }
  assignPatientData(data): void {
    this.patientProfileInfo = data;
    this.patientName = data?.lastName + ', ' + data?.firstName;
    if (data?.addresses) {
      if (data?.addresses?.length > 1) {
        this.mainAddress = data.addresses[0].defaultAddress ? data.addresses[0] : data.addresses[1];
        this.additionalAddress = data.addresses[1].defaultAddress ? data.addresses[0] : data.addresses[1];
      } else {
        this.mainAddress = data.addresses[0];
        this.additionalAddress = {};
      }
    }
    this.cd.detectChanges();
  }

  editPatientProfileDetails(): void {
    this.productOpen = true;
    this.modalOptions.open = true;
    document.body.classList.add('modal-open');
  }
  closeModal(): void {
    this.productOpen = false;
    this.modalOptions.open = false;
    document.body.classList.remove('modal-open');
  }
  patientAddedSuccessfully(): void {
    this.globalMessageService.add('Changes saved', GlobalMessageType.MSG_TYPE_CONFIRMATION, GlobalMessage.TIMEOUT);
  }
  addOrder(): void {
    this.router.navigate(
      this.semanticPathService.transform({
        cxRoute: 'newOrderPage',
        params: { patientID: this.patientID },
      })
    );
  }
  ngOnDestroy(): void {
    this.componetSubscription.next();
    this.componetSubscription.complete();
  }
}
