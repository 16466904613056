<div class="lens d-flex flex-column flex-wrap">
  <div class="lens__power__more d-flex justify-content-start">
    <button
      [alt]="'stockOrderPage.moreMinus' | cxTranslate"
      *ngIf="lensPowerSelector && lensPowerSelector.length > stockorderConstants.LENS_IN_VIEW && startIndex > 0"
      (click)="getMoreMinusPowers()"
    >
      <span class="lens__power__more__label">{{ 'stockOrderPage.moreMinus' | cxTranslate }}</span>
      <cx-icon class="expandUp" type="ExpandUp"></cx-icon>
    </button>
    <button
      class="lens__power__more__label"
      *ngIf="lensPowerSelector && lensPowerSelector.length > stockorderConstants.LENS_IN_VIEW && startIndex === 0"
      (click)="getLessMinusPowers()"
    >
      <span class="lens__power__label--leftwidth">{{ 'stockOrderPage.lessMinus' | cxTranslate }}</span>
      <cx-icon class="ExpandDown" type="ExpandDown"></cx-icon>
    </button>
  </div>
  <div class="lens__power d-flex flex-wrap">
    <div class="lens__power__block" *ngFor="let power of lensPowerSelector | slice: startIndex:endIndex; index as i">
      <div class="d-flex flex-column">
        <label class="lens__power__block--leftwidth mb-0">{{ power.text }}</label>
        <input
          appOnlyNumeric
          type="text"
          class="lens__power__block--input mt-0"
          [ngClass]="{ 'lens__power__block--input-error': power.displayError }"
          [(ngModel)]="power.quantity"
          (ngModelChange)="updateQuantityToPower(power)"
        />
      </div>
    </div>
  </div>
  <div class="lens__power__more d-flex justify-content-end">
    <button
      *ngIf="
        lensPowerSelector &&
        lensPowerSelector.length > stockorderConstants.LENS_IN_VIEW &&
        lensPowerSelector.length !== endIndex
      "
      (click)="getMorePlusPowers()"
      class="lens__power__more__plus"
    >
      <span class="lens__power__more__label">{{ 'stockOrderPage.morePlus' | cxTranslate }}</span>
      <cx-icon class="expandDown" type="ExpandDown"></cx-icon>
      <br />
    </button>
    <button
      class="lens__power__more__plus"
      *ngIf="
        lensPowerSelector &&
        lensPowerSelector.length > stockorderConstants.LENS_IN_VIEW &&
        lensPowerSelector.length === endIndex
      "
      (click)="getLessPlusPowers()"
    >
      <span class="lens__power__more__label">{{ 'stockOrderPage.lessPlus' | cxTranslate }}</span>
      <cx-icon class="expandUp" type="ExpandUp"></cx-icon><br />
    </button>
  </div>
</div>
