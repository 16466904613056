<app-modal [modalOptions]="modalOptions">
  <div class="modal-block">
    <div class="modal--icon"><cx-icon type="IconClock"> </cx-icon><span class="IconClock"></span></div>
    <div class="modal--text">
      {{ 'baseLoginPage.youWereLoggedOut' | cxTranslate }}
    </div>
    <div class="modal--msg">
      <div>
        {{ 'baseLoginPage.welcome' | cxTranslate }} <b>{{ userName }}!</b>
      </div>
      {{ 'baseLoginPage.logoutMsg' | cxTranslate }}
    </div>
    <div class="modal--button">
      <button class="btn btn-primary text-uppercase" (click)="closeModal()">
        {{ 'baseLoginPage.backToLogin' | cxTranslate }}
      </button>
    </div>
  </div>
</app-modal>
