<div class="checkout-wrapper">
  <div class="d-flex checkout-wrapper__start">
    <cx-icon class="deliveryIcon" type="DeliveryIcon"> </cx-icon>
    <h2>{{ 'checkoutPage.delivery' | cxTranslate }}</h2>
  </div>

  <div *ngIf="practiceOrderEntries" class="d-flex flex-column checkout-wrapper__subContainer mb-5">
    <h3>
      {{ 'checkoutPage.practiceOrders' | cxTranslate }}
      <span>({{ 'checkoutPage.orderTypes.individual' | cxTranslate }}, </span>
      <span *ngIf="isValuePackEnabled$ | async">{{ 'checkoutPage.orderTypes.valuePack' | cxTranslate }}, </span>
      <span>{{ 'checkoutPage.orderTypes.stock' | cxTranslate }})</span>
    </h3>
    <div class="d-flex flex-column align-items-center checkout-wrapper__subContainer-contents">
      <div class="d-flex checkout-wrapper__subContainer-contents-panels">
        <label>{{ 'cartPage.DeliveryMethod' | cxTranslate }}:</label>
        <app-practiceorder-deliverymode [deliveryMethod]="defaultDeliveryMethod"></app-practiceorder-deliverymode>
      </div>
      <div class="d-flex checkout-wrapper__subContainer-contents-panels">
        <label class="shipping-address__title">{{ 'cartPage.ShippingAddress' | cxTranslate }}:</label>
        <app-alcon-shipping-address [cartAddress]="deliveryAddress"></app-alcon-shipping-address>
      </div>
      <div class="d-flex checkout-wrapper__subContainer-contents-panels">
        <label>{{ 'checkoutPage.poNumber' | cxTranslate }}<span *ngIf="isPONumberRequired | async">*</span></label>
        <app-po-number [hasError]="isError" (enteredPONumber)="enteredPONumber($event)"></app-po-number>
      </div>
    </div>
  </div>

  <div *ngIf="dtporders?.length > 0" class="d-flex flex-column checkout-wrapper__subContainer">
    <h3>
      {{ 'checkoutPage.directtoPatients' | cxTranslate }}
      <span>({{ dtporders?.length }} {{ 'checkoutPage.patients' | cxTranslate }})</span>
    </h3>
    <div class="d-flex flex-column align-items-center checkout-wrapper__subContainer-contents">
      <div class="d-flex checkout-wrapper__subContainer-contents-panels">
        <label>{{ 'cartPage.DeliveryMethod' | cxTranslate }}:</label>
        <p>{{ dtporders[0]?.deliveryMode?.name }}</p>
      </div>
      <div class="d-flex checkout-wrapper__subContainer-contents-panels">
        <label class="shipping-address__title">{{ 'cartPage.ShippingAddress' | cxTranslate }}:</label>
        <div class="d-flex">
          <p class="mr-32">({{ dtporders?.length }} {{ 'checkoutPage.patients' | cxTranslate }})</p>
          <button (click)="showAll()">
            <cx-icon
              role="button"
              aria-label="address"
              class="Dropdown"
              type="DropdownExpand"
              *ngIf="!showAllAddress"
            ></cx-icon>
            <cx-icon
              role="button"
              aria-label="address"
              class="Dropdown"
              type="DropdownCollapse"
              *ngIf="showAllAddress"
            ></cx-icon>
            {{ 'checkoutPage.seeAll' | cxTranslate }}
          </button>
        </div>
      </div>
      <div class="d-flex flex-wrap checkout-wrapper__subContainer-contents-panels mt-0" *ngIf="showAllAddress">
        <app-checkout-shipping-address *ngFor="let key of dtporders" [order]="key"> </app-checkout-shipping-address>
      </div>
    </div>
  </div>
</div>
