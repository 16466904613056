<div class="confirmation-delivery-panel">
  <div class="confirmation-delivery-panel__order-details">
    <label class="confirmation-delivery-panel__order-details-patient-name" *ngIf="deliveryInfo?.patientName">
      {{ deliveryInfo?.patientName }}</label
    >
    <label class="confirmation-delivery-panel__order-details-label"
      >{{ 'orderConfirmation.orderNumber' | cxTranslate }}
      <span class="confirmation-delivery-panel__order-details-label--underline">{{
        deliveryInfo?.orderNumber
      }}</span></label
    >
    <label class="confirmation-delivery-panel__order-details-label" *ngIf="deliveryInfo?.ponumber"
      >{{ 'orderConfirmation.poNumber' | cxTranslate }}
      <span class="confirmation-delivery-panel__order-details-label--underline">{{
        deliveryInfo?.ponumber
      }}</span></label
    >
  </div>
  <div class="confirmation-delivery-panel__order-details">
    <div class="confirmation-delivery-panel__order-details-label">
      {{ 'orderConfirmation.deliveryMethod' | cxTranslate }}
    </div>
    <div class="confirmation-delivery-panel__delivery-method-name">
      <label class="confirmation-delivery-panel__order-details-label--notbold">{{
        deliveryInfo?.deliveryMethod
      }}</label>
      <div class="confirmation-delivery-panel__delivery-address">
        <p class="confirmation-delivery-pane__line" *ngIf="deliveryInfo?.deliveryAddress?.line1">
          {{ deliveryInfo?.deliveryAddress.line1 }}
        </p>
        <div class="confirmation-delivery-pane__line-two">
          <p *ngIf="deliveryInfo?.deliveryAddress?.line2">{{ deliveryInfo?.deliveryAddress?.line2 }}</p>
          <p>{{ deliveryInfo?.deliveryAddress?.town }} {{ deliveryInfo?.deliveryAddress?.postalCode }}</p>
          <p>
            <span *ngIf="deliveryInfo?.deliveryAddress?.region?.name"
              >{{ deliveryInfo?.deliveryAddress?.region?.name }},</span
            >
            {{ deliveryInfo?.deliveryAddress?.country?.name }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
