<div class="patient-address">
  <ng-container [formGroup]="address">
    <div class="d-flex flex-column">
      <div
        class="flex-lg-column d-flex patientaddressform__inputwrap--margin patientaddressform__inputwrap--margin--lg-none"
      >
        <div
          class="flex-grow-1 patientaddressform__inputwrap--padding patientaddressform__inputwrap--padding--lg-none pt-lg-0"
        >
          <label class="label" [ngClass]="{ 'label-disabled': address.get('line1').disabled }">{{
            'patientAddressPage.form.address.street' | cxTranslate
          }}</label>
          <input
            type="text"
            class="form-control input-styles has-success"
            maxlength="30"
            formControlName="line1"
            (blur)="trimString(address.get('line1'))"
            [ngClass]="{
              'invalid-field-border':
                address.get('line1').invalid && (address.get('line1').dirty || address.get('line1').touched),
              'valid-field-border':
                address.get('line1').valid && address.get('line1').dirty && address.get('line1').touched
            }"
          />
          <div
            *ngIf="address.get('line1').invalid && (address.get('line1').dirty || address.get('line1').touched)"
            class="error-message-styles"
          >
            <div *ngIf="address.get('line1').errors?.required">
              {{ 'patientAddressPage.form.errorMessages.mandatoryField' | cxTranslate }}
              {{ 'patientAddressPage.form.errorMessages.street' | cxTranslate }}
            </div>
          </div>
        </div>
        <div class="flex-grow-1 patientaddressform__inputwrap--padding patientaddressform__inputwrap--padding--lg-none">
          <label class="label" [ngClass]="{ 'label-disabled': address.get('line2').disabled }">{{
            'patientAddressPage.form.address.building' | cxTranslate
          }}</label>
          <input
            type="text"
            class="form-control input-styles"
            maxlength="30"
            formControlName="line2"
            (blur)="trimString(address.get('line2'))"
          />
        </div>
      </div>
      <div class="d-flex patientaddressform__inputwrap--margin">
        <div class="patientaddressform__inputwrap--padding flex-grow-1">
          <label class="label" [ngClass]="{ 'label-disabled': address.get('town').disabled }">{{
            'patientAddressPage.form.address.city' | cxTranslate
          }}</label>
          <input
            type="text"
            class="form-control input-styles"
            maxlength="20"
            formControlName="town"
            (blur)="trimString(address.get('town'))"
            [ngClass]="{
              'invalid-field-border':
                address.get('town').invalid && (address.get('town').dirty || address.get('town').touched),
              'valid-field-border':
                address.get('town').valid && address.get('town').dirty && address.get('town').touched
            }"
          />
          <div
            *ngIf="address.get('town').invalid && (address.get('town').dirty || address.get('town').touched)"
            class="error-message-styles"
          >
            <div *ngIf="address.get('town').invalid && address.get('town').errors?.required">
              {{ 'patientAddressPage.form.errorMessages.mandatoryField' | cxTranslate }}
              {{ 'patientAddressPage.form.errorMessages.city' | cxTranslate }}
            </div>
          </div>
        </div>
        <div class="patientaddressform__inputwrap--padding flex-grow-1">
          <label class="label" [ngClass]="{ 'label-disabled': address.get('postalCode').disabled }">{{
            'patientAddressPage.form.address.postcode' | cxTranslate
          }}</label>
          <input
            type="text"
            class="form-control input-styles"
            [maxlength]="postalCodeMaxLengthValidator(address)"
            formControlName="postalCode"
            (blur)="trimString(address.get('postalCode'))"
            [ngClass]="{
              'invalid-field-border':
                (address.get('postalCode').invalid &&
                  (address.get('postalCode').dirty || address.get('postalCode').touched)) ||
                (address.get('postalCode').valid && hasPostalCodeError),
              'valid-field-border':
                address.get('postalCode').valid &&
                !hasPostalCodeError &&
                address.get('postalCode').dirty &&
                address.get('postalCode').touched
            }"
          />
          <div
            *ngIf="
              address.get('postalCode').invalid &&
              (address.get('postalCode').dirty || address.get('postalCode').touched)
            "
            class="error-message-styles"
          >
            <ng-container *ngIf="address.get('postalCode').errors?.required">
              {{ 'patientAddressPage.form.errorMessages.mandatoryField' | cxTranslate }}
              {{ 'patientAddressPage.form.errorMessages.postcode' | cxTranslate }}
            </ng-container>

            <ng-container *ngIf="address.hasError('coutryMatch')">
              {{ 'patientAddressPage.form.errorMessages.selectCountry' | cxTranslate }}
            </ng-container>
          </div>

          <div *ngIf="address.get('postalCode').valid" class="error-message-styles">
            <ng-container *ngIf="address.hasError('coutryMatch')">
              {{ 'patientAddressPage.form.errorMessages.selectCountry' | cxTranslate }}
            </ng-container>
            <ng-container *ngIf="address.hasError('invalidPostalCode')">
              {{ 'patientAddressPage.form.errorMessages.invalidPattren' | cxTranslate }}
            </ng-container>
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-lg-column d-flex patientaddressform__inputwrap--margin patientaddressform__inputwrap--margin--lg-none"
      >
        <div class="flex-grow-1 patientaddressform__inputwrap--padding patientaddressform__inputwrap--padding--lg-none">
          <label class="label" [ngClass]="{ 'label-disabled': address.get('region').disabled }">{{
            'patientAddressPage.form.address.county' | cxTranslate
          }}</label>
          <app-dropdown-selector-v2
            [config]="dropdownConfig | async"
            [options]="counties$ | async"
            (dropDownSelected)="countySelected($event, address.get('region'))"
            [disableDropDown]="address.get('region').disabled"
          >
          </app-dropdown-selector-v2>
          <div
            *ngIf="address.get('region.isocode').invalid && address.get('region.isocode').touched"
            class="error-message-styles"
          >
            <div *ngIf="address.get('region.isocode').invalid">
              {{ 'patientAddressPage.form.errorMessages.mandatoryField' | cxTranslate }}
              {{ 'patientAddressPage.form.errorMessages.county' | cxTranslate }}
            </div>
          </div>
        </div>
        <div class="flex-grow-1 patientaddressform__inputwrap--padding patientaddressform__inputwrap--padding--lg-none">
          <label class="label" [ngClass]="{ 'label-disabled': address.get('country').disabled }">{{
            'patientAddressPage.form.address.country' | cxTranslate
          }}</label>
          <app-country
            [selectedStatus]="selectedStatus"
            [selected]="selectedCountry"
            (dropDownSelected)="countrySelected($event.value, $event.text, address.get('country'), [AddressIndex])"
            [disableDropDown]="address.get('country').disabled"
          ></app-country>
          <div *ngIf="address.get('country.isocode').invalid && showInValidIcon" class="error-message-styles">
            <div *ngIf="address.get('country.isocode').invalid">
              {{ 'patientAddressPage.form.errorMessages.mandatoryField' | cxTranslate }}
              {{ 'patientAddressPage.form.errorMessages.country' | cxTranslate }}
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end" *ngIf="AddressIndex === 0 && ShowClearAll">
        <cx-icon type="Close" class="close pr-2" (click)="clearall()"> </cx-icon>
        <label class="clear-all">{{ 'patientAddressPage.form.clearAll' | cxTranslate }}</label>
      </div>
    </div>
  </ng-container>
</div>
