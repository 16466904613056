import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService, UserIdService } from '@spartacus/core';
import { Observable, Subject, of } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InvoicesService {
  private paginateInvoiceSubject = new Subject<any>();
  invoiceListData = this.paginateInvoiceSubject.asObservable();

  constructor(
    private userIdService: UserIdService,
    private occEndPoints: OccEndpointsService,
    private http: HttpClient
  ) {}

  getInvoicesList(param = null): any {
    const sort = param?.sortDir ? param?.sortDir : 'DESC';
    let pageNo = 0;
    if (param?.currentPage) {
      pageNo = param.currentPage - 1;
    }
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap((userId) => {
        const invoiceUrl = `/users/${userId}/invoices`;
        let params = new HttpParams();
        if (param?.searchString) {
          params = params.set('searchString', param.searchString);
        }
        params = params.set('currentPage', pageNo);
        params = params.set('sortDir', sort);
        const url = this.occEndPoints.buildUrl(invoiceUrl);
        return this.http.get<any>(url, { params }).pipe(
          catchError((error) => {
            return of(error);
          })
        );
      })
    );
  }

  paginateInvoiceList(params): void {
    this.getInvoicesList(params).subscribe((list) => {
      this.paginateInvoiceSubject.next(list);
    });
  }

  downloadInvoice(invoiceNumber: string): Observable<Blob> {
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap((userId) => {
        const url = this.occEndPoints.buildUrl(`/users/${userId}/invoices/invoice-pdf/${invoiceNumber}`);
        return this.http.get(url, { responseType: 'blob' });
      })
    );
  }
}
