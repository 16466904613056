<div class="container-fluid">
  <div class="row justify-content-center toolsContainer__heading">
    <h1 class="col-10 text-center">{{ title$ | async }}</h1>
    <p class="col-10 text-center toolsContainer__subHeading" [innerHTML]="subHeading | async"></p>
  </div>
  <div class="row justify-content-center">
    <div class="col-10 col-md-5 col-xl-5 toolsPanelWrapper" *ngFor="let component of (panelList | async)?.components">
      <div class="toolsPanelWrapper__container">
        <ng-container [cxComponentWrapper]="component"> </ng-container>
      </div>
    </div>
  </div>
</div>
