<!-- <div class="faq-accordion">
  <ngb-accordion #accordian="ngbAccordion" [activeIds]="pageId" [destroyOnHide]="false">
    <ngb-panel [id]="pageId">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex acc-header align-items-center justify-content-between"
         [class.opened]="opened">
          <h5 class="m-0">{{ topic }}</h5>
          <button ngbPanelToggle class="btn acc-btn btn-link p-0">
            <cx-icon ngbPanelToggle role="button" *ngIf="!opened" type="DropdownExpand"></cx-icon>
            <cx-icon ngbPanelToggle role="button" *ngIf="opened" type="DropdownCollapse"></cx-icon>
          </button>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <ng-container *ngFor="let component of items$ | async">
          <div>
            <ng-template [cxComponentWrapper]="component"> </ng-template>
          </div>
        </ng-container>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div> -->

<!--NG-Bootstrap Attribute (16.x.x)-->

<div class="faq-accordion">
  <div ngbAccordion #accordian="ngbAccordion" [closeOthers]="true" [destroyOnHide]="false">
    <div
      ngbAccordionItem
      [collapsed]="true"
      [style.background-color]="pageId.collapsed ? null : '#e5f5ff'"
      NgbAccordionItem="pageId"
      #pageId="ngbAccordionItem"
    >
      <div ngbAccordionHeader class="d-flex acc-header align-items-center justify-content-between">
        <h5 class="m-0">{{ topic }}</h5>
        <button ngbAccordionButton class="btn acc-btn btn-link p-0">
          <cx-icon ngbAccordionButton role="button" *ngIf="pageId.collapsed" type="DropdownExpand"></cx-icon>
          <cx-icon ngbAccordionButton role="button" *ngIf="!pageId.collapsed" type="DropdownCollapse"></cx-icon>
        </button>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <ng-container *ngFor="let component of items$ | async">
              <div>
                <ng-template [cxComponentWrapper]="component"> </ng-template>
              </div>
            </ng-container>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
