<section class="container-fluid" *ngIf="!faqOutlet.isActivated">
  <div class="row justify-content-center faq-container__heading">
    <h1 class="col-10 text-center">{{ heading$ | async }}</h1>
    <p class="col-10 text-center faq-container__sub-heading d-md-none d-lg-block" [innerHTML]="subHeading$ | async"></p>
  </div>
  <div class="row justify-content-center">
    <div class="col-10">
      <cx-page-slot position="VisioncareFAQTopSearchPosition"></cx-page-slot>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-10 col-lg-7">
      <cx-page-slot position="VCfAQMainPosition"></cx-page-slot>
    </div>
    <div class="col-3 d-md-none d-lg-block mt-3">
      <div class="faq-container__right-side">
        <cx-page-slot position="VCFAQRightUPContentSlotPosition"></cx-page-slot>
        <hr class="faq-container__line" />
        <cx-page-slot position="VCFAQRightDownContentSlotPosition"></cx-page-slot>
      </div>
    </div>
    <div class="col-10 d-lg-none d-md-block mt-20 mb-20">
      <cx-page-slot
        position="TabVCFAQContactPosition"
        class="d-flex flex-column align-items-center justify-content-between"
      >
      </cx-page-slot>
      <cx-page-slot
        position="TabVCFAQGuidePosition"
        class="d-flex flex-column align-items-center mt-20 justify-content-between"
      ></cx-page-slot>
    </div>
  </div>
</section>
<router-outlet #faqOutlet="outlet"></router-outlet>
