<div class="d-flex flex-row">
  <div class="product-list">
    <div class="product-list-container mt-3" *ngFor="let product of products; index as i">
      <div class="product-list-header-wrap d-flex">
        <div class="flex-fill pb-3">
          <h2 class="product-list-container__category-header mb-0" [ngClass]="{ 'mt-5': i !== 0 }">
            {{ product.CategoryName }}
          </h2>
        </div>
      </div>

      <div *ngFor="let child of product.children">
        <div class="d-flex">
          <h5
            class="product-list-container__subcategory-header mb-0"
            [ngClass]="{ 'product-list-container__brand-header--large': !landingPage, 'mt-4': i !== 0 }"
          >
            {{ child.CategoryName }} ({{ child.count }})
          </h5>
        </div>
        <app-single-listview-product-tile
          *ngFor="let product of child.products"
          [product]="product"
          [isWishList]="false"
          (productSelected)="productExpanded($event)"
        ></app-single-listview-product-tile>
      </div>
    </div>
  </div>
</div>
