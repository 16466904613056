import { Directive, HostListener, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { CartItemsService } from 'src/app/services/cart-items/cart-items.service';
import { Remove } from '../store/Actions/cartItem.action';

@Directive({
  selector: '[appRemoveFromCart]',
})
export class RemoveFromCartDirective {
  @Input() entries: Array<number>;
  constructor(private store: Store, private cartItemsService: CartItemsService) {}
  @HostListener('click') removeFromCart(): void {
    this.cartItemsService.setRemoveCartItem();
    this.store.dispatch(Remove({ entries: this.entries }));
  }
}
