import { Injectable } from '@angular/core';
import { UserIdService } from '@spartacus/core';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { UserDetails } from 'src/app/shared/model/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserActivityService {
  constructor(private userIdService: UserIdService, private userProfileService: UserProfileFacade) {}
  userActivity: UserDetails;

  getUserActivity(): UserDetails {
    return this.userActivity;
  }

  getuserSessionData(): Observable<any> {
    return this.userIdService.getUserId().pipe(
      switchMap((userId) => {
        return userId !== 'anonymous'
          ? this.userProfileService.get().pipe(
              tap((data) => {
                this.userActivity = { ...data };
              })
            )
          : of({});
      })
    );
  }
}
