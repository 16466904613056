<div class="productTile d-flex flex-column">
  <div class="productTile__fav ml-auto">
    <app-favorite-product
      [isFavourite]="product?.favourite"
      [productCode]="product?.code"
      iconSize="sm"
      *ngIf="isFavouritePage$ | async"
    >
    </app-favorite-product>
  </div>
  <div class="productTile__image basic-edit-image-holder">
    <cx-media [container]="product?.images[0]" alt="{{ product?.name }}"></cx-media>
  </div>
  <div class="productTile__name text-center">
    {{ product?.name }}
  </div>
  <div class="productTile__price ml-auto mr-auto">
    <ng-container *ngIf="price$ | async as price">
      <span class="productTile__price__value"
        >{{ price.price?.formattedValue }}
        <span *ngIf="price.price?.formattedValue" class="productTile__price__each">{{
          'stockOrderPage.each' | cxTranslate
        }}</span></span
      >
    </ng-container>
  </div>
</div>
