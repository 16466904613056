import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslationService } from '@spartacus/core';
import { Subject, combineLatest } from 'rxjs';
import { map, skip, startWith, take, takeUntil } from 'rxjs/operators';
import { OrderHistoryPatientListService } from 'src/app/services/order-his-patient-list/order-history-patient-list.service';
import { OrderHistoryService } from 'src/app/services/order-history/order-history.service';
import { PatientService } from 'src/app/services/patient/patient.service';
import { PatientListService } from 'src/app/services/patients-list/patient-list.service';
import { PATIENTLISTFILTERS } from 'src/app/shared/constants/constants';
import { DropdownOptions } from 'src/app/shared/model/common.mode';
import { PatientProfileData } from 'src/app/shared/model/patient.model';
import { Utilities } from 'src/app/shared/utility/utility';
import { getPatientData } from 'src/app/store/Selectors/patient.selector';
import { PatientOrderData } from 'src/app/store/States/orderHistory';

@Component({
  selector: 'app-patients-list',
  templateUrl: './patients-list.component.html',
  styleUrls: ['./patients-list.component.scss'],
})
export class PatientsListComponent implements OnInit, OnDestroy {
  showAddPatientSuccessMessage = false;
  patientsList;
  page = 1;
  patientSearch = false;
  filtersSelected = false;
  pageSize = Utilities.isTablet() || Utilities.isMobile() ? 6 : 20;
  maxSize = 6;
  sortPatientsList: Array<DropdownOptions> = [];
  subscription = new Subject<void>();
  sortSelection = { text: '', iconType: 'AlphabetAsc', value: 'Name_Asc', selected: true };
  constructor(
    private patientListService: PatientListService,
    private cd: ChangeDetectorRef,
    private orderService: OrderHistoryService,
    private store: Store,
    private patientService: PatientService,
    private route: ActivatedRoute,
    private patienListtService: OrderHistoryPatientListService,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.getTranslatedValues();
    const pateintList$ = this.store.select(getPatientData).pipe(
      skip(1),
      startWith(this.route.snapshot.data.patients),
      map((data: Array<PatientProfileData>) => {
        const processedData = this.addRecentOrders(JSON.parse(JSON.stringify(data)));
        return processedData;
      })
    );
    const searchParam$ = this.patientListService.patientSearchSubject.pipe(startWith(''));
    const orderFilters$ = this.patientListService.orderFiltersSubject.pipe(startWith('All'));
    combineLatest([pateintList$, searchParam$, orderFilters$])
      .pipe(
        map(([listFromStore, searchParamInput, orderFilters]) => {
          this.patientSearch = searchParamInput ? true : false;
          this.filtersSelected =
            orderFilters !== 'All' && (orderFilters?.orderTypes?.length > 0 || orderFilters?.subfilters?.length > 0)
              ? true
              : false;
          return this.filterList(listFromStore, searchParamInput, orderFilters);
        }),
        takeUntil(this.subscription)
      )
      .subscribe((data) => {
        this.patientsList = data;
        this.cd.detectChanges();
      });
  }
  getTranslatedValues(): void {
    combineLatest([this.translationService.translate('myPatientsPage.patientName').pipe(take(1))])
      .pipe(takeUntil(this.subscription))
      .subscribe((data) => {
        this.sortPatientsList = [
          { text: data[0], iconType: 'AlphabetAsc', value: 'Name_Asc', selected: true },
          { text: data[0], iconType: 'AlphabetDesc', value: 'Name_Desc', selected: false },
        ];
        this.sortSelection = { text: data[0], iconType: 'AlphabetAsc', value: 'Name_Asc', selected: true };
      });
  }

  scrollTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  @HostListener('window:resize', ['$event'])
  private changePageSize(): void {
    const newPageSize = Utilities.isTablet() || Utilities.isMobile() ? 6 : 20;

    if (this.pageSize !== newPageSize) {
      this.page = 1;
    }

    this.pageSize = newPageSize;
  }

  /**
   * Returns filtered list based on input search param
   */
  filterList(
    listFromStore: Array<PatientProfileData>,
    searchParamInput: string | {},
    orderFilters
  ): Array<PatientProfileData> {
    const list: Array<PatientProfileData> = listFromStore.filter((patientInfo: any) => {
      const regSearch = new RegExp(searchParamInput.toString().replace(/\s+/g, '|'));
      return (
        regSearch.test(patientInfo.lastName.toLowerCase()) ||
        regSearch.test(patientInfo.firstName.toLowerCase()) ||
        regSearch.test(patientInfo.externalReferenceNumber)
      );
    });
    if ((orderFilters?.orderTypes?.length > 0 || orderFilters?.subfilters?.length > 0) && orderFilters !== 'All') {
      const filteredList = [];
      list.filter((patientData) => {
        const orders = patientData.recentOrders;
        for (const index in orders) {
          if (this.validateOrderType(orders[index], orderFilters)) {
            filteredList.push(patientData);
            break;
          }
        }
      });
      return this.sortBy(filteredList, this.sortSelection);
    }
    return this.sortBy(list, this.sortSelection);
  }
  /**
   * Individual & subscriptins are main filters if those two are only selected will return all patients info
   * @param order - patient order information
   * @param filters - selected filters information
   * @returns true if the current order details are matched with selected filters
   */
  validateOrderType(order, filters): boolean {
    const OrderTypes = filters?.orderTypes;
    const SubFilters = filters?.subfilters;
    let patientexist = false;
    if ((OrderTypes.length === 2 || OrderTypes.length === 0) && SubFilters.length > 0) {
      patientexist = this.filterPatientDetails(order, SubFilters);
    } else if (OrderTypes.length === 2 && SubFilters.length === 0) {
      patientexist = true;
    } else if (OrderTypes.includes(PATIENTLISTFILTERS.Individual) && order.isScheduledOrder === false) {
      patientexist = SubFilters.length === 0 ? true : this.filterPatientDetails(order, SubFilters);
    } else if (OrderTypes.includes(PATIENTLISTFILTERS.subscriptions) && order.isScheduledOrder === true) {
      patientexist = SubFilters.length === 0 ? true : this.filterPatientDetails(order, SubFilters);
    }
    return patientexist;
  }

  /**
   * checking if selected order type having regular or value pack type
   * @param order - patient order information
   * @param SubFilters - selected subfilters information
   * @returns true if the current order details are matched with selected pack type
   */
  filterPatientDetails(order, SubFilters): boolean {
    let patientexist = false;
    const regularSelected = SubFilters.includes(PATIENTLISTFILTERS.regular);
    const valuePackSelected = SubFilters.includes(PATIENTLISTFILTERS.valuepack);
    if (regularSelected || valuePackSelected) {
      if (
        (regularSelected && order.packType.toLowerCase() === PATIENTLISTFILTERS.regular.toLowerCase()) ||
        (valuePackSelected && order.packType.toLowerCase() === PATIENTLISTFILTERS.valuepack.toLowerCase())
      ) {
        patientexist = this.filterPatientsbyDeliveryType(order, SubFilters);
      }
    } else {
      patientexist = this.filterPatientsbyDeliveryType(order, SubFilters);
    }
    if (
      ((this.checkPackTypeorDeliverfilters(SubFilters) && patientexist) ||
        !this.checkPackTypeorDeliverfilters(SubFilters)) &&
      order.isScheduledOrder === true &&
      (SubFilters.includes(PATIENTLISTFILTERS.Active) || SubFilters.includes(PATIENTLISTFILTERS.Inactive))
    ) {
      patientexist = this.filterPatientsbySubscriptionStatus(order, SubFilters);
    }
    return patientexist;
  }
  /**
   * checking if selected order type having Active or inActive status
   * @param order - patient order information
   * @param SubFilters - selected subfilters information
   * @returns true if the current order details are matched with selected subscription filters
   */
  filterPatientsbySubscriptionStatus(order, SubFilters): boolean {
    let patientExists = false;
    const activeStatus = SubFilters.includes(PATIENTLISTFILTERS.Active);
    const inActiveStatus = SubFilters.includes(PATIENTLISTFILTERS.Inactive);
    if (
      (!activeStatus && !inActiveStatus) ||
      (activeStatus && inActiveStatus) ||
      (activeStatus && order.toggleConfig?.status === true) ||
      (inActiveStatus && order.toggleConfig?.status === false)
    ) {
      patientExists = true;
    }
    return patientExists;
  }
  /**
   * checking if selected order type having selected delivery types practice order or DTP
   * @param order - patient order information
   * @param SubFilters - selected subfilters information
   * @returns  true if the current order details are matched with selected delviery type
   */
  filterPatientsbyDeliveryType(order, SubFilters): boolean {
    let patientExists = false;
    const practiceOrderSelected = SubFilters.includes(PATIENTLISTFILTERS.practiceOrder);
    const dtpSelected = SubFilters.includes(PATIENTLISTFILTERS.directtoPatient);
    if (
      (!practiceOrderSelected && !dtpSelected) ||
      (practiceOrderSelected && dtpSelected) ||
      (practiceOrderSelected && order.orderType.toLowerCase() === PATIENTLISTFILTERS.practiceOrder.toLowerCase()) ||
      (dtpSelected && order.orderType.toLowerCase() === PATIENTLISTFILTERS.directtoPatient.toLowerCase())
    ) {
      patientExists = true;
    }
    return patientExists;
  }
  /**
   * checking if the pack type or delivery related subfilters selected
   * @param SubFilters -- selected subfilters
   * @returns true when pack type or delivery related subfilters
   */
  checkPackTypeorDeliverfilters(SubFilters): boolean {
    const otherFilters =
      SubFilters.includes(PATIENTLISTFILTERS.regular) ||
      SubFilters.includes(PATIENTLISTFILTERS.valuepack) ||
      SubFilters.includes(PATIENTLISTFILTERS.directtoPatient) ||
      SubFilters.includes(PATIENTLISTFILTERS.practiceOrder);
    return otherFilters;
  }

  sortBy(product, event): any {
    this.sortSelection = event;
    return this.patientListService.sortBy(product, event);
  }

  /**
   * adds recentOrders (inidvidual and scheduled if any)to patientList
   * this.patientList={..,recentOrders:[]}
   */
  addRecentOrders(patientInfo): any {
    patientInfo.forEach((patientData) => {
      if (patientData?.order || patientData?.replenishmentOrder) {
        const orderList = [];
        const orderObject = {} as PatientOrderData;
        let responseList = [];
        if (patientData?.replenishmentOrder) {
          orderList.push({ ...patientData.replenishmentOrder });
        }
        if (patientData?.order) {
          orderList.push({ ...patientData.order });
        }
        responseList = this.patienListtService.createpatientListData(
          orderList,
          orderObject,
          responseList,
          patientData.patientId
        );
        patientData.recentOrders = [...responseList];
      } else {
        patientData.recentOrders = [];
      }
    });
    return patientInfo;
  }
  clearallFilters(): void {
    this.patientListService.clearFiltersSubject.next('clear');
  }
  ngOnDestroy(): void {
    this.subscription.next();
    this.subscription.complete();
  }
}
