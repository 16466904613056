import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { DeliveryMode } from '@spartacus/cart/base/root';
import { CheckoutDeliveryModesFacade } from '@spartacus/checkout/base/root';
import { UserIdService } from '@spartacus/core';
import { combineLatest, Subject } from 'rxjs';
import { filter, mergeMap, take, takeUntil } from 'rxjs/operators';
import { CartItemsService } from 'src/app/services/cart-items/cart-items.service';
import { getCartCode } from 'src/app/store/Selectors/cartItem.selector';
import { PracticeorderDeliverymodeService } from '../../../services/practiceorder-deliverymode/practiceorder-deliverymode.service';

@Component({
  selector: 'app-practiceorder-deliverymode',
  templateUrl: './practiceorder-deliverymode.component.html',
  styleUrls: ['./practiceorder-deliverymode.component.scss'],
})
export class PracticeorderDeliverymodeComponent implements OnInit, OnDestroy {
  deliveryModes: DeliveryMode[];
  private destroy$ = new Subject<void>();
  deliveryCode;
  @Input() deliveryMethod;
  showLoader = false;
  constructor(
    private userIdService: UserIdService,
    private store: Store,
    public checkoutDeliveryService: CheckoutDeliveryModesFacade,
    private cd: ChangeDetectorRef,
    private cartItemsService: CartItemsService,
    private practiceOrderDeliveryService: PracticeorderDeliverymodeService
  ) {}

  ngOnInit(): void {
    combineLatest([this.userIdService.getUserId(), this.store.pipe(select(getCartCode))])
      .pipe(
        filter(([uid, cartId]) => cartId != null),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe(([userId, cartId]) => {
        this.practiceOrderDeliveryService.getSupportDeliveryModes(userId, cartId).subscribe((deliveryModes) => {
          if (deliveryModes?.length > 0) {
            this.deliveryModes = [...deliveryModes];
            this.cd.detectChanges();
          }
        });
      });
  }

  setDeliveryMethod(method: DeliveryMode, flagToUpdate): void {
    this.showLoader = true;
    if (method) {
      combineLatest([this.userIdService.getUserId(), this.store.pipe(select(getCartCode))])
        .pipe(
          filter(([uid, cartId]) => cartId != null),
          take(1),
          takeUntil(this.destroy$),
          mergeMap((data) => {
            this.deliveryCode = method.code;
            const [userId, cartId] = data;
            return this.practiceOrderDeliveryService.setDeliveryMode(userId, cartId, this.deliveryCode);
          })
        )
        .subscribe((data) => {
          if (this.deliveryCode === data.code) {
            this.cartItemsService
              .simulateFinalPrice()
              .pipe(takeUntil(this.destroy$))
              .subscribe(() => {
                this.showLoader = false;
                this.cd.detectChanges();
              });
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
