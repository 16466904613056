import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RetrievedValuePackVarients, GetValuePackVarients } from '../Actions/valuePack.action';
import { ValuePackService } from 'src/app/services/value-pack/valuePack.service';

@Injectable()
export class ValuePackEffects {
  constructor(private actions$: Actions, private valuePackService: ValuePackService) {}

  loadItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GetValuePackVarients),
      mergeMap(() =>
        this.valuePackService.getValuePackVarients().pipe(
          map((valuePackVarients: any) => {
            return RetrievedValuePackVarients({ valuePackVarients });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });
}
