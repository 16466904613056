import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule, UrlModule } from '@spartacus/core';
import { IconModule, MediaModule, PageSlotModule } from '@spartacus/storefront';
import { ShowPriceModule } from 'src/app/shared-modules/show-price/show-price.module';
import { AlconCommonModule } from '../common/alcon-common.module';
import { AlconCartSummaryComponent } from './alcon-cart-summary/alcon-cart-summary.component';
import { AlconTermsConditionsComponent } from './alcon-terms-conditions/alcon-terms-conditions.component';
import { AlconcartComponent } from './alconcart/alconcart.component';
import { CartActionsComponent } from './cart-actions/cart-actions.component';
import { CartDtpAddressComponent } from './cart-dtp-orders/cart-dtp-address/cart-dtp-address.component';
import { CartDtpOrdersComponent } from './cart-dtp-orders/cart-dtp-orders.component';
import { CartListItemsComponent } from './cart-list-items/cart-list-items.component';
import { CartSectionHeaderComponent } from './cart-section-header/cart-section-header.component';
import { CartSingleLineItemComponent } from './cart-single-line-item/cart-single-line-item.component';
import { CartValuepackLineitemComponent } from './cart-valuepack-lineitem/cart-valuepack-lineitem.component';
import { DeliveryMethodComponent } from './delivery-method/delivery-method.component';
@NgModule({
  declarations: [
    CartSingleLineItemComponent,
    CartListItemsComponent,
    CartActionsComponent,
    AlconCartSummaryComponent,
    CartValuepackLineitemComponent,
    CartDtpOrdersComponent,
    CartSectionHeaderComponent,
    AlconcartComponent,
    DeliveryMethodComponent,
    AlconTermsConditionsComponent,
    CartDtpAddressComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    I18nModule,
    PageSlotModule,
    MediaModule,
    IconModule,
    RouterModule,
    AlconCommonModule,
    UrlModule,
    ShowPriceModule,
  ],
  exports: [
    CartSingleLineItemComponent,
    CartListItemsComponent,
    CartActionsComponent,
    AlconCartSummaryComponent,
    CartValuepackLineitemComponent,
    CartDtpOrdersComponent,
    CartSectionHeaderComponent,
    AlconcartComponent,
    AlconTermsConditionsComponent,
  ],
})
export class AlconCartModule {}
