import { Component, OnInit } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Address, CmsNavigationComponent, CmsService, PageMetaService } from '@spartacus/core';
import { CmsComponentData, NavigationService } from '@spartacus/storefront';
import { User } from '@spartacus/user/account/root';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlconUserPermissionService } from 'src/app/shared-modules/alcon-user-permission-provider/service/alcon-user-permission.service';
import { NAVIGATION_LINKS } from 'src/app/shared/constants/constants';
import { AlconNavigationService } from '../../../services/alcon-navigation/alcon-navigation.service';

declare module '@spartacus/user/account/root' {
  interface User {
    eligibleOrderFlowTypes: Array<string>;
    addresses?: Address[];
    shipToAddress?: Address;
    billToAddress?: Address;
    autoOrderingTimeGroup?: any;
    autoOrdering?: boolean;
    isValuePackEnabled?: boolean;
  }
}

@Component({
  selector: 'app-header-link-nav',
  templateUrl: './header-link-nav.component.html',
  styleUrls: ['./header-link-nav.component.scss'],
})
export class HeaderLinkNavComponent implements OnInit {
  node$: Observable<any> = this.alconService.getAlconNavigationNode(this.componentData.data$);
  useraccountInfo$: Observable<User> = this.userProfileService.get().pipe(
    map((userdata: User) => {
      return {
        ...userdata,
        shipToAddress: userdata?.addresses.find((address) => address.shippingAddress),
        billToAddress: userdata?.addresses.find((address) => !address.shippingAddress),
      } as User;
    })
  );

  styleClass$: Observable<string> = this.componentData.data$.pipe(
    map((d) => {
      const { styleClasses = 'unknown' } = d;
      return styleClasses;
    })
  );

  showInvoiceOption = true;

  readonly NEW_ORDER = NAVIGATION_LINKS.NEW_ORDER;

  private dropdownRefs = new Set<NgbDropdown>();

  constructor(
    public componentData: CmsComponentData<CmsNavigationComponent>,
    public cmsService: CmsService,
    protected service: NavigationService,
    protected alconService: AlconNavigationService,
    protected pageMetaService: PageMetaService,
    private userPermissionService: AlconUserPermissionService,
    private userProfileService: UserProfileFacade
  ) {}

  ngOnInit(): void {
    this.showInvoiceOption = this.userPermissionService.getPriceStatementPermission();
  }

  onHover(dropdownRef: NgbDropdown): void {
    [...this.dropdownRefs].forEach((ref) => ref.close());

    if (!this.dropdownRefs.has(dropdownRef)) {
      this.dropdownRefs.add(dropdownRef);
    }

    dropdownRef.open();
  }
}
