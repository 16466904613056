<div class="select-product d-flex">
  <div class="select-product__container d-flex flex-column flex-grow-1">
    <div class="select-products">
      <section class="d-flex justify-content-between">
        <h4 class="select-products__selectLabel">
          {{ 'selectProductPage.selectProducts' | cxTranslate }}
        </h4>
        <span *ngIf="firstProduct" class="additional-products__mandatory d-md-block d-lg-none">{{
          'selectProductPage.mandatory' | cxTranslate
        }}</span>
      </section>
      <h3 class="select-products__select-Lenses">{{ 'selectProductPage.chooseLenses' | cxTranslate }}</h3>
    </div>

    <div class="select-product__lensdrpdn">
      <app-dropdown-selector-v2
        *ngIf="!isValuePack"
        [options]="firstproductsList | sortPipe: 'asc'"
        [config]="lensesLabel"
        (dropDownSelected)="firstProductDrpdwnSelected($event)"
      >
      </app-dropdown-selector-v2>
    </div>
    <div *ngIf="firstProduct" class="select-product__productsSelector">
      <app-basic-product-selector
        [isValuePack]="isValuePack"
        [selectedProduct]="firstProduct?.value"
        (selectedProductLensValue)="selectedProductLensValue($event, 0)"
        (checkboxStatus)="checkboxStatusChange($event, true)"
        [disableCheckbox]="disableCheckbox"
        [editproductData]="editlenses[0]"
        [isDTP]="isDTP"
        [panelConfiguration]="firstPanelConfiguration"
        (Errors)="setPanelErrors($event, firstProduct?.value)"
      >
      </app-basic-product-selector>
    </div>
    <div *ngIf="firstProduct" class="select-product__button">
      <button
        (click)="addotherLens()"
        class="btn addOtherLens__button_background"
        [ngClass]="{ 'd-none': addLens }"
        [disabled]="disableAddLens"
      >
        {{ 'selectProductPage.addOtherLenses' | cxTranslate }}
      </button>
    </div>

    <div *ngIf="addLens" class="d-flex">
      <div class="select-product__lensdrpdn">
        <app-dropdown-selector-v2
          *ngIf="!isValuePack"
          [options]="secondProductsList | sortPipe: 'asc'"
          [config]="lensesLabel"
          (dropDownSelected)="secondProductDrpdwnSelected($event)"
        >
        </app-dropdown-selector-v2>
      </div>
      <div *ngIf="!isValuePack" (click)="deleteAddedItem()" class="deleteSecondProduct__deleteItem--icon-remove">
        <cx-icon class="Remove" type="Remove"> </cx-icon
        ><a class="deleteSecondProduct__deleteItem--icon-remove--deleteLink" href="javascript:void(0)">{{
          'miniCartPage.delete' | cxTranslate
        }}</a>
      </div>
    </div>

    <div *ngIf="secondProduct" class="select-product__productsSelector">
      <app-basic-product-selector
        [isValuePack]="isValuePack"
        [selectedValuePack]="selectedValuePack"
        [selectedProduct]="secondProduct?.value"
        (selectedProductLensValue)="selectedProductLensValue($event, 1)"
        (checkboxStatus)="checkboxStatusChange($event, false)"
        [disableCheckbox]="true"
        [editproductData]="editlenses[1]"
        [isDTP]="isDTP"
        [panelConfiguration]="panelConfiguration"
        (Errors)="setPanelErrors($event, secondProduct?.value)"
      >
      </app-basic-product-selector>
    </div>

    <div class="d-flex-column" *ngIf="!isValuePack" [ngClass]="{ disabled: !firstProduct }">
      <h3 class="additional-products">{{ 'selectProductPage.additionalProducts' | cxTranslate }}</h3>
      <div #solutiontemplate></div>
      <div
        *ngIf="selectedSolutionsValue[0].length > 0 && SolutionsPanel.length < 2"
        class="additional-products__add-another"
      >
        <cx-icon (click)="createPanel()" class="icon-style" type="AddOrderOption">{{
          'selectProductPage.addAnotherproduct' | cxTranslate
        }}</cx-icon>
      </div>
    </div>

    <div class="additional-products__mandatory d-none d-lg-block">
      {{ 'selectProductPage.mandatory' | cxTranslate }}
    </div>
  </div>
</div>
