import { Component, OnDestroy, OnInit } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { Subscription } from 'rxjs';
import { AlconUserPermissionService } from 'src/app/shared-modules/alcon-user-permission-provider/service/alcon-user-permission.service';

@Component({
  selector: 'app-sales-rep',
  templateUrl: './sales-rep.component.html',
  styleUrls: ['./sales-rep.component.scss'],
})
export class SalesRepComponent implements OnInit, OnDestroy {
  $salesRepData = this.userProfileService.get();
  customerServiceTitle;
  customerServiceMessage;
  subscription: Subscription[] = [];
  isReturnsEnabled: boolean;

  open = false;

  constructor(
    private userProfileService: UserProfileFacade,
    public cmsService: CmsService,
    private userPermissionService: AlconUserPermissionService
  ) {}

  toggle(): void {
    this.open = !this.open;
  }

  ngOnInit(): void {
    this.isReturnsEnabled = this.userPermissionService.getReturnsEnabledPermission();
    this.subscription.push(
      this.cmsService.getComponentData('ContactUsCustomerServiceHeadingComponent').subscribe((val) => {
        this.customerServiceTitle = val;
      })
    );
    this.subscription.push(
      this.cmsService.getComponentData('ContactUsCustomerServiceMessageComponent').subscribe((val) => {
        this.customerServiceMessage = val;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.forEach((subs) => {
      subs.unsubscribe();
    });
  }
}
