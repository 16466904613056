import { Component, OnInit } from '@angular/core';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
})
export class AccountDetailsComponent implements OnInit {
  accountDetails$: Observable<any>;
  data$: Observable<any>;
  constructor(private userProfileService: UserProfileFacade) {}

  ngOnInit(): void {
    this.accountDetails$ = this.userProfileService.get();
  }
}
