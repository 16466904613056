<h4 class="cart-product-details__productName">{{ cartEntry?.productName }}</h4>
<div class="cart-product-details__cartspecification">
  <span *ngIf="cartEntry?.eyeSight && cartEntry?.eyeSight" class="cart-product-details__cartspecification--eyesight">
    {{ 'miniCartPage.' + cartEntry?.eyeSight | cxTranslate }}
  </span>
  <span *ngIf="cartEntry?.product?.isLensCareSolution">
    {{ cartEntry.salesUnitCount }} {{ cartEntry.salesUnitName }}
  </span>
  <span *ngFor="let specification of updatedSpecificationValues">
    {{ 'checkoutPage.' + specification?.key | cxTranslate }}: {{ specification?.value }}
  </span>

  <div
    class="cart-product-details__powersblock"
    *ngIf="cartEntry?.showpower && shippedPowers?.length > 0 && cartEntry?.orderEntryFlowType !== 'REGULAR'"
  >
    <div class="cart-product-details__message-block">
      <span class="cart-product-details__message" *ngIf="status === statusList.SHIPPED && cartEntry.shipped > 0"
        >{{ shippedPowers.length }} {{ 'miniCartPage.PowerShipped' | cxTranslate }}</span
      >
      <a class="cart-product-details__cancel" (click)="cartEntry.showpower = false"> x </a>
    </div>

    <div>
      <span *ngFor="let power of shippedPowers">{{ power }}, </span>
    </div>
  </div>

  <div class="cart-product-details__powersblock" *ngIf="cartEntry.showpower && unShippedPowers.length > 0">
    <div class="cart-product-details__message-block">
      <span class="cart-product-details__message" *ngIf="status === statusList.UNSHIPPED"
        >{{ unShippedPowers.length }} {{ 'miniCartPage.PowersInProgress' | cxTranslate }}
      </span>
      <a class="cart-product-details__cancel" (click)="cartEntry.showpower = false"> x </a>
    </div>

    <div>
      <span *ngFor="let power of unShippedPowers">{{ power }}, </span>
    </div>
  </div>
</div>
<div class="cart-product-details__message" *ngIf="!cartEntry?.showpower && cartEntry?.orderEntryFlowType !== 'REGULAR'">
  <a
    class="cart-product-details__warning"
    (click)="cartEntry.showpower = true"
    *ngIf="status === 'shipped' && cartEntry.shipped > 0 && shippedPowers.length > 0"
    >{{ shippedPowers.length }} {{ 'miniCartPage.PowerShipped' | cxTranslate }}</a
  >
  <a
    class="cart-product-details__warning"
    (click)="cartEntry.showpower = true"
    *ngIf="status === 'unshipped' && unShippedPowers.length > 0"
    >{{ unShippedPowers.length }} {{ 'miniCartPage.PowersInProgress' | cxTranslate }}
  </a>
</div>
