import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule, UrlModule } from '@spartacus/core';
import { IconModule, OutletPosition, PageComponentModule, PageSlotModule, provideOutlet } from '@spartacus/storefront';
import { AlconCommonModule } from '../cms-components/common/alcon-common.module';
import { ReturnFormTemplateComponent } from './return-form-template/return-form-template.component';
import { UserManagementPageComponent } from './user-management-page/user-management-page.component';

@NgModule({
  declarations: [UserManagementPageComponent, ReturnFormTemplateComponent],

  imports: [
    CommonModule,
    PageSlotModule,
    AlconCommonModule,
    IconModule,
    RouterModule,
    UrlModule,
    I18nModule,
    PageComponentModule,
  ],
  providers: [
    provideOutlet({
      id: 'ManageUserPageTemplate',
      position: OutletPosition.REPLACE,
      component: UserManagementPageComponent,
    }),
    provideOutlet({
      id: 'VCReturnFormPageTemplate',
      position: OutletPosition.REPLACE,
      component: ReturnFormTemplateComponent,
    }),
  ],
})
export class TemplatesModule {}
