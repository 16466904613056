import { Action, createReducer, on } from '@ngrx/store';
import { ClearB2BUnitStore, GetB2BUnitsList } from '../Actions/b2bUnits.action';
import { B2BUnits } from '../States/b2bUnits.state';

export const initialState: Partial<B2BUnits> = {
  currentShipTo: '',
  currentUnit: '',
  units: [],
};

const b2bUnitReducer = createReducer(
  initialState,
  on(GetB2BUnitsList, (state, { b2bUnits }) => {
    return { ...b2bUnits };
  }),
  on(ClearB2BUnitStore, () => {
    const storeobj = initialState;
    return { ...storeobj };
  })
);

export function b2bUnitsReducer(state = initialState, action: Action): Partial<B2BUnits> {
  return b2bUnitReducer(state, action);
}
