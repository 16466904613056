import { Component, OnInit } from '@angular/core';
import { Product } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { PDP_SECTION } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-product-benefit',
  templateUrl: './product-benefit.component.html',
  styleUrls: ['./product-benefit.component.scss'],
})
export class ProductBenefitComponent implements OnInit {
  mediaImage: any;
  uid: string;

  product$: Observable<Product> = this.currentProductService.getProduct('all');

  constructor(protected currentProductService: CurrentProductService) {}

  ngOnInit(): void {}

  /**
   * Filters feauters list from array
   */
  getFeaturesList(productList): any {
    return productList.classifications?.filter((classification) =>
      classification.code.includes(PDP_SECTION.FEATURES)
    )[0]?.features;
  }
}
