<form class="register-form" [formGroup]="registerForm" (ngSubmit)="next()">
  <h1 class="register-form__title">
    {{ 'registerPage.registrationFormTitle' | cxTranslate }}
  </h1>
  <div class="register-form__section">
    <div class="register-form__section__main">
      <h3 class="register-form__section__main__title">
        {{ 'registerPage.imAlconCustomer' | cxTranslate }}
      </h3>
      <div class="register-form__section__main__form">
        <ng-container *ngIf="manualRegistration">
          <label class="register-form__label">{{ 'registerPage.TypeOfProducts' | cxTranslate }}</label>
          <app-dropdown-selector-v2
            class="user-group-field"
            [config]="dropdownConfig | async"
            [options]="franchiseOptions | async"
            (dropDownSelected)="setProductType($event)"
          >
          </app-dropdown-selector-v2>
          <div *ngIf="productTypeError" class="Alcon-form__error-message">
            {{ 'registerPage.errorMessages.mandatoryField' | cxTranslate }}
            {{ 'registerPage.errorMessages.TypeOfProducts' | cxTranslate }}
          </div>
        </ng-container>

        <!-- firstName and Title -->
        <div class="register-form__row">
          <div class="register-form__col flex-grow-1 mr-4">
            <label class="register-form__label">{{ 'registerPage.title' | cxTranslate }}</label>
            <app-dropdown-selector
              class="title-field"
              [options]="titles$ | async"
              (dropDownSelected)="registerForm.patchValue({ titleCode: $event.value })"
            >
            </app-dropdown-selector>
          </div>
          <div class="register-form__col flex-fill">
            <label class="register-form__label">{{ 'registerPage.firstName' | cxTranslate }}</label>
            <input
              type="text"
              [ngClass]="{
                'Alcon-form__invalid-input-field': firstName?.invalid && (firstName?.dirty || firstName?.touched),
                'Alcon-form__valid-input-field': firstName?.valid && firstName?.dirty && firstName?.touched
              }"
              class="form-control register-form__input-text"
              formControlName="firstName"
            />
            <div
              *ngIf="firstName?.invalid && (firstName?.dirty || firstName?.touched)"
              class="Alcon-form__error-message"
            >
              <div *ngIf="firstName?.errors?.required">
                {{ 'registerPage.errorMessages.mandatoryField' | cxTranslate }}
                {{ 'registerPage.errorMessages.firstName' | cxTranslate }}
              </div>

              <div *ngIf="firstName?.errors?.maxlength">
                {{
                  'registerPage.errorMessages.maxLengthReached'
                    | cxTranslate: { characterNumber: firstName?.errors?.maxlength.requiredLength }
                }}
              </div>
            </div>
          </div>
        </div>

        <!-- lastName -->
        <label class="register-form__label">{{ 'registerPage.lastName' | cxTranslate }}</label>
        <input
          type="text"
          [ngClass]="{
            'Alcon-form__invalid-input-field': lastName?.invalid && (lastName?.dirty || lastName?.touched),
            'Alcon-form__valid-input-field': lastName?.valid && lastName?.dirty && lastName?.touched
          }"
          class="form-control register-form__input-text"
          formControlName="lastName"
        />
        <div *ngIf="lastName?.invalid && (lastName?.dirty || lastName?.touched)" class="Alcon-form__error-message">
          <div *ngIf="lastName?.errors?.required">
            {{ 'registerPage.errorMessages.mandatoryField' | cxTranslate }}
            {{ 'registerPage.errorMessages.lastName' | cxTranslate }}
          </div>

          <div *ngIf="lastName?.errors?.maxlength">
            {{
              'registerPage.errorMessages.maxLengthReached'
                | cxTranslate: { characterNumber: lastName?.errors?.maxlength.requiredLength }
            }}
          </div>
        </div>

        <!-- companyName -->
        <label class="register-form__label">{{ 'registerPage.fullCompanyName' | cxTranslate }}</label>
        <input
          type="text"
          [ngClass]="{
            'Alcon-form__invalid-input-field': companyName?.invalid && (companyName?.dirty || companyName?.touched),
            'Alcon-form__valid-input-field': companyName?.valid && companyName?.dirty && companyName?.touched
          }"
          class="form-control register-form__input-text"
          formControlName="companyName"
        />
        <div
          *ngIf="companyName?.invalid && (companyName?.dirty || companyName?.touched)"
          class="Alcon-form__error-message"
        >
          <div *ngIf="companyName?.errors?.required">
            {{ 'registerPage.errorMessages.mandatoryField' | cxTranslate }}
            {{ 'registerPage.errorMessages.fullCompanyName' | cxTranslate }}
          </div>
          <div *ngIf="companyName?.errors?.maxlength">
            {{
              'registerPage.errorMessages.maxLengthReached'
                | cxTranslate: { characterNumber: companyName?.errors?.maxlength.requiredLength }
            }}
          </div>
        </div>

        <!-- email -->
        <label class="register-form__label">{{ 'registerPage.email' | cxTranslate }}</label>
        <input
          type="text"
          [ngClass]="{
            'Alcon-form__invalid-input-field': email?.invalid && (email?.dirty || email?.touched),
            'Alcon-form__valid-input-field': email?.valid && email?.dirty && email?.touched
          }"
          class="form-control register-form__input-text"
          formControlName="email"
        />
        <div *ngIf="email?.invalid && (email?.dirty || email?.touched)" class="Alcon-form__error-message">
          <div *ngIf="email?.errors?.required">
            {{ 'registerPage.errorMessages.mandatoryField' | cxTranslate }}
            {{ 'registerPage.errorMessages.email' | cxTranslate }}
          </div>
          <div *ngIf="email?.errors?.email">
            {{ 'registerPage.errorMessages.emailPattern' | cxTranslate }}
          </div>
          <div *ngIf="email?.errors?.maxlength">
            {{
              'registerPage.errorMessages.maxLengthReached'
                | cxTranslate: { characterNumber: email?.errors?.maxlength.requiredLength }
            }}
          </div>
        </div>

        <!-- accountNumber -->
        <label class="register-form__label register-form__label--account-number"
          >{{ 'registerPage.accountNumber' | cxTranslate }}
          <cx-icon class="register-form__label__info" type="InformationCircleIcon"> </cx-icon>
        </label>
        <input
          type="text"
          pattern="[0-9]*"
          [ngClass]="{
            'Alcon-form__invalid-input-field':
              accountNumber?.invalid && (accountNumber?.dirty || accountNumber?.touched),
            'Alcon-form__valid-input-field': accountNumber?.valid && accountNumber?.dirty && accountNumber?.touched
          }"
          class="form-control register-form__input-text"
          formControlName="accountNumber"
        />
        <div
          *ngIf="accountNumber?.invalid && (accountNumber?.dirty || accountNumber?.touched)"
          class="Alcon-form__error-message"
        >
          <div *ngIf="accountNumber?.errors?.required">
            {{ 'registerPage.errorMessages.mandatoryField' | cxTranslate }}
            {{ 'registerPage.errorMessages.accountNumber' | cxTranslate }}
          </div>
          <div *ngIf="accountNumber?.errors?.maxlength">
            {{
              'registerPage.errorMessages.maxLengthReached'
                | cxTranslate: { characterNumber: accountNumber?.errors?.maxlength.requiredLength }
            }}
          </div>
          <div *ngIf="accountNumber?.errors?.pattern">
            {{ 'registerPage.errorMessages.couldContainsOnlyNumbers' | cxTranslate }}
          </div>
        </div>
      </div>
      <div class="register-form__section__main__buttons">
        <div class="register-form__section__main__buttons__mandatory_field_text">
          {{ 'registerPage.mandatory' | cxTranslate }}
        </div>

        <button
          class="btn btn-primary text-uppercase register-form__section__main__buttons__next"
          type="submit"
          [attr.disabled]="!registerForm.valid ? true : null"
        >
          {{ 'registerPage.next' | cxTranslate }}
        </button>
      </div>
    </div>
    <div class="register-form__section__side">
      <div class="register-form__section__side__title p-lg-4 pb-0">
        {{ 'registerPage.iDoNotHaveAnAccountTitle' | cxTranslate }}
      </div>
      <div class="register-form__section__side__description p-lg-4">
        {{ 'registerPage.iDoNotHaveAnAccountDescription' | cxTranslate }}
      </div>
      <button
        class="btn btn-primary text-uppercase register-form__section__side__btnSign-in ml-4"
        (click)="openContactForm($event)"
      >
        {{ 'registerPage.setUpAnAccount' | cxTranslate }}
      </button>
    </div>
  </div>
</form>

<app-contact-us-modal
  [configs]="modalConfigs"
  [thankYouModalOptions]="thankYouModalOptions"
  [formConfig]="contactFormConfig"
  [options]="modalOptions"
></app-contact-us-modal>
