<div class="dropdown__container" [ngClass]="[openDropDown ? 'dropdown__options--' + dropDownSize : '']">
  <div
    class="dropdown__select"
    [ngClass]="{
      dropdown__disable: disableDropDown,
      dropdown__textColorBlack: textColorBlack,
      'dropdown__form-drop-down': formDropDown,
      'dropdown__valid-icon': formDropDown && optionSelected && selectedValue !== defaultFormSelectedValue,
      'dropdown__invalid-icon': formDropDown && showInValidIcon && selectedValue === defaultFormSelectedValue
    }"
    (click)="showDropDown()"
  >
    {{ selectedValue }}
    <cx-icon *ngIf="selectedValueIconType" type="{{ selectedValueIconType }}"></cx-icon>
  </div>
  <cx-icon
    *ngIf="!disableDropDown"
    (click)="showDropDown()"
    class="dropdown__icon dropdownExpand"
    [ngClass]="{
      'dropdown__icon--rotate': openDropDown,
      'dropdown__form-down-arrow': formDropDown,
      'dropdown__form-validity-down-arrow': formDropDown && (optionSelected || showInValidIcon)
    }"
    type="DropdownExpand"
  ></cx-icon>
  <div
    class="dropdown__options"
    [class]="'dropdown__options--' + dropDownSize"
    [ngClass]="{ 'dropdown__options--open': openDropDown }"
  >
    <div class="dropdown__options--header">{{ 'productListingPage.select' | cxTranslate }}</div>
    <ul #dropdownOptionContainer class="dropdown__options--list">
      <li
        class="dropdown__options--item"
        [ngClass]="{ 'dropdown__options--selected-value': option.selected }"
        *ngFor="let option of options"
        (click)="selectOption(option)"
      >
        {{ option.text }}
        <cx-icon *ngIf="option.iconType" class="sortbyHigh" type="{{ option.iconType }}"></cx-icon>
      </li>
    </ul>
  </div>
</div>
