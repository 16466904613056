import { Component, OnInit } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-return-form-confirmation-steps',
  templateUrl: './return-form-confirmation-steps.component.html',
  styleUrls: ['./return-form-confirmation-steps.component.scss'],
})
export class ReturnConfirmationStepsComponent implements OnInit {
  constructor(private componentData: CmsComponentData<any>) {}
  panelContent: Observable<any>;
  ngOnInit(): void {
    this.panelContent = this.componentData.data$;
  }
}
