<div class="contactus-from justify-content-center">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
    <div class="contactus-from__topic" *ngIf="topic">
      <div class="contactus-form__row--spacing-md">
        <label class="contactus-form__label">{{ 'contactusPage.pleaseSelectATopic' | cxTranslate }}</label>
        <app-dropdown-selector
          class="topic-selector"
          [options]="topicList"
          [showInValidIcon]="showInValidIcon"
          [resetDropDown]="resetDropDown"
          [selectedLabel]="topicLabel | async"
          (dropDownSelected)="TopicSelected($event)"
        >
        </app-dropdown-selector>
      </div>
    </div>

    <div class="contactus-from__form-block" *ngIf="topic?.value || !topic">
      <div class="contactus-form__row--spacing-lg position-relative" *ngIf="message">
        <label class="contactus-form__label">{{ 'contactusPage.typeYourMessageHere' | cxTranslate }}</label>
        <textarea
          class="form-control contactus-form__textarea"
          [ngClass]="{
            'Alcon-form__invalid-input-field': message?.invalid && (message?.dirty || message?.touched),
            'Alcon-form__valid-input-field': message?.valid && message?.dirty && message?.touched
          }"
          maxlength="1000"
          formControlName="message"
        >
        </textarea>
      </div>
      <div class="contactus-form__character-count" *ngIf="message">
        {{ message?.value?.length }} / {{ 'contactusPage.characters' | cxTranslate }}
      </div>
      <div
        *ngIf="message?.invalid && (message?.dirty || message?.touched) && message"
        class="Alcon-form__error-message"
      >
        <div *ngIf="message?.errors?.required">
          {{ 'contactusPage.errorMessages.mandatoryField' | cxTranslate }}
          {{ 'contactusPage.errorMessages.message' | cxTranslate }}
        </div>
      </div>

      <!--<div class="contactus-from__form-block">
        <div class="form-control contactus-form__input-file">
          <div>{{ 'contactusPage.selectFile' | cxTranslate }}</div>
          <input type="file" id="upload-file" class="alconInput__input-file" formControlName="uploadedFile" />
          <cx-icon class="input-file__icon" type="DownloadIcon"></cx-icon>
        </div>
      </div>-->

      <div class="contactus-form__row--margin-md">
        <label class="contactus-form__heading">{{ 'contactusPage.contactMethod' | cxTranslate }}</label>
        <div class="row">
          <div
            class="row align-items-center h-100"
            [ngClass]="configs?.isInModal ? 'mt-2 col-10 col-lg-10' : 'col-10 col-lg-auto mb-2'"
          >
            <div
              class="contactus-form__radio-button-margin col-3 col-lg-auto pl-4 mr-0"
              [ngClass]="configs?.isInModal ? 'mt-0 input-field-width' : ''"
            >
              <input
                type="checkbox"
                value="email"
                id="contact-method-1"
                class="alconInput__checkbox"
                (change)="selectContactMethod('email', $event.target.checked)"
              />
              <label for="contact-method-1" class="contactus__form--radio-label m-1">{{
                'contactusPage.contactMethodLabel1' | cxTranslate
              }}</label>
            </div>
            <div class="d-block col-7 pl-0 pr-0" [ngClass]="configs?.isInModal ? '' : 'd-lg-none'">
              <ng-container *ngTemplateOutlet="email; context: { form }"></ng-container>
            </div>
          </div>
          <div
            class="row align-items-center h-100"
            [ngClass]="configs?.isInModal ? 'col-10 col-lg-10 mt-2' : 'col-10 col-lg-auto'"
          >
            <div
              class="contactus-form__radio-button-margin col-3 col-lg-auto pl-4 mr-0"
              [ngClass]="configs?.isInModal ? 'input-field-width' : ''"
            >
              <input
                type="checkbox"
                value="phoneNumber"
                id="contact-method-2"
                class="alconInput__checkbox"
                (change)="selectContactMethod('phoneNumber', $event.target.checked)"
              />
              <label for="contact-method-2" class="contactus__form--radio-label m-1">
                {{ 'contactusPage.contactMethodLabel2' | cxTranslate }}
              </label>
            </div>
            <div class="d-block col-7 pl-0 pr-0" [ngClass]="configs?.isInModal ? '' : 'd-lg-none '">
              <ng-container *ngTemplateOutlet="phone; context: { form }"></ng-container>
            </div>
          </div>
        </div>
        <div
          *ngIf="contactMethod?.invalid && (contactMethod?.dirty || contactMethod?.touched)"
          class="Alcon-form__error-message Alcon-form__error-message--left"
        >
          <div *ngIf="contactMethod?.errors?.required">
            {{ 'contactusPage.errorMessages.mandatoryField' | cxTranslate }}
            {{ 'contactusPage.errorMessages.contactmethod' | cxTranslate }}
          </div>
        </div>
      </div>

      <div class="d-none" [ngClass]="configs?.isInModal ? '' : 'd-lg-block'">
        <ng-container *ngTemplateOutlet="email; context: { form }"></ng-container>
        <ng-container *ngTemplateOutlet="phone; context: { form }"></ng-container>
      </div>

      <div class="contactus-form__heading--margin-lg">
        <label class="contactus-form__heading">{{ 'contactusPage.preferredTime' | cxTranslate }}</label>
        <div class="contactus-form__radio-button-margin" [ngClass]="configs?.isInModal ? '' : ''">
          <input
            type="radio"
            value="Morning"
            formControlName="preferredTime"
            name="preferredTime"
            id="preferred-time-morning"
            class="alconInput__radio contactus__form--radio"
          />
          <label for="preferred-time-morning" class="contactus-form__radio-label">{{
            'contactusPage.preferredTimeLabel1' | cxTranslate
          }}</label>
        </div>
        <div class="contactus-form__radio-button-margin ml-4" [ngClass]="configs?.isInModal ? '' : ''">
          <input
            type="radio"
            value="Afternoon"
            formControlName="preferredTime"
            name="preferredTime"
            id="preferred-time-afternoon"
            class="alconInput__radio"
          />
          <label
            for="preferred-time-afternoon"
            class="contactus-form__radio-label"
            (click)="changePreferredTime('Afternoon')"
            >{{ 'contactusPage.preferredTimeLabel2' | cxTranslate }}</label
          >
        </div>
      </div>
      <div class="contactus-form__row--margin-lg">
        <label class="contactus-form__heading">{{ 'contactusPage.provideContactName' | cxTranslate }}</label>

        <label class="contactus-form__label contactus-form__label--spacing-xs" *ngIf="companyName">{{
          'contactusPage.fullCompanyName' | cxTranslate
        }}</label>
        <input
          *ngIf="companyName"
          type="text"
          [ngClass]="{
            'Alcon-form__invalid-input-field': companyName?.invalid && (companyName?.dirty || companyName?.touched),
            'Alcon-form__valid-input-field': companyName?.valid && companyName?.dirty && companyName?.touched
          }"
          class="form-control contactus-form__input-text mb-4"
          formControlName="companyName"
        />
        <div
          *ngIf="companyName?.invalid && (companyName?.dirty || companyName?.touched) && companyName"
          class="Alcon-form__error-message"
        >
          <div *ngIf="companyName?.errors?.required">
            {{ 'contactusPage.errorMessages.mandatoryField' | cxTranslate }}
            {{ 'contactusPage.errorMessages.contactName' | cxTranslate }}
          </div>
          <div *ngIf="companyName?.errors?.maxlength">
            {{ 'contactusPage.errorMessages.contactNamePattern' | cxTranslate }}
          </div>
        </div>

        <label class="contactus-form__label contactus-form__label--spacing-xs">{{
          'contactusPage.contactNameLabel' | cxTranslate
        }}</label>
        <input
          type="text"
          [ngClass]="{
            'Alcon-form__invalid-input-field': contactname?.invalid && (contactname?.dirty || contactname?.touched),
            'Alcon-form__valid-input-field': contactname?.valid && contactname?.dirty && contactname?.touched
          }"
          class="form-control contactus-form__input-text"
          formControlName="contactname"
        />
        <div
          *ngIf="contactname?.invalid && (contactname?.dirty || contactname?.touched)"
          class="Alcon-form__error-message"
        >
          <div *ngIf="contactname?.errors?.required">
            {{ 'contactusPage.errorMessages.mandatoryField' | cxTranslate }}
            {{ 'contactusPage.errorMessages.contactName' | cxTranslate }}
          </div>
          <div *ngIf="contactname?.errors?.maxlength">
            {{ 'contactusPage.errorMessages.contactNamePattern' | cxTranslate }}
          </div>
        </div>
      </div>
      <div class="contactus-form__terms" *ngIf="emailChanged || phoneNumberChanged">
        <input
          type="checkbox"
          id="terms-conditions"
          class="alconInput__checkbox"
          [checked]="termsConditionsFlag"
          (click)="selectTermsConditions(!termsConditionsFlag)"
        />
        <label for="terms-conditions" class="contactus-form__radio-label align-items-start">{{
          'contactusPage.termsConditions' | cxTranslate
        }}</label>
        <div
          *ngIf="termsConditions?.invalid && (termsConditions?.dirty || termsConditions?.touched)"
          class="Alcon-form__error-message Alcon-form__error-message--left"
        >
          <div *ngIf="termsConditions?.errors?.pattern?.requiredPattern">
            {{ 'contactusPage.errorMessages.termsConditions' | cxTranslate }}
          </div>
        </div>
      </div>
    </div>
    <div class="contactus-from__submit">
      <div class="contactus-form__button-block d-flex" [ngClass]="'justify-content-' + configs?.submitButtonPosition">
        <div class="contactus-form__submit-block">
          <label class="contactus-form__heading contactus-form__heading-name" *ngIf="configs?.showMandatoryFieldText">{{
            'contactusPage.mandatoryFields' | cxTranslate
          }}</label>
        </div>
        <div class="contactus-form__submit-block contactus-form__button-submit">
          <button
            type="submit"
            [disabled]="form?.invalid"
            class="btn btn-primary text-uppercase contactus-form__button-submit"
          >
            {{ 'contactusPage.contactusSubmit' | cxTranslate }}
          </button>
        </div>
      </div>
    </div>
  </form>
  <app-modal [modalOptions]="thankYouModalOptions" [showBigPopup]="true">
    <div
      *ngIf="thankYouModalOptions?.closeIcon !== undefined ? thankYouModalOptions?.closeIcon : true"
      class="contactus-form__modal--close"
    >
      <cx-icon type="Close" class="close-icon cx-icon close" (click)="closeModal()"> </cx-icon>
    </div>
    <div class="contactus-form__modal">
      <div>
        <cx-icon type="ContactusThankyou"> </cx-icon>
      </div>
      <div class="contactus-form__modal--thankyou-msg">
        {{ 'contactusPage.thankyoumsg' | cxTranslate }}
      </div>
      <div class="contactus-form__modal--additional-msg">
        {{ 'contactusPage.additionalmsg' | cxTranslate }}
      </div>
      <div class="modal--button">
        <button
          class="btn btn-primary text-uppercase"
          [ngClass]="thankYouModalOptions?.ctaClass"
          (click)="closeModal()"
          [routerLink]="{ cxRoute: 'home' } | cxUrl"
        >
          {{
            (thankYouModalOptions.ctaText ? thankYouModalOptions.ctaText : 'contactusPage.returntoHome') | cxTranslate
          }}
        </button>
      </div>
    </div>
  </app-modal>
</div>

<ng-template #email let-form="form">
  <div [formGroup]="form" *ngIf="contactMethod?.value?.includes('email')" class="w-100">
    <label
      class="contactus-form__label contactus-form__label--spacing-sm d-none"
      [ngClass]="configs?.isInModal ? '' : 'd-lg-block'"
      >{{ 'contactusPage.emailAddress' | cxTranslate }}</label
    >
    <input
      type="text"
      [ngClass]="{
        'Alcon-form__invalid-input-field': emailAddress?.invalid && (emailAddress?.dirty || emailAddress?.touched),
        'Alcon-form__valid-input-field': emailAddress?.valid && emailAddress?.dirty && emailAddress?.touched
      }"
      class="form-control contactus-form__input-text"
      placeholder="xxxxx@xxxxxxx.com"
      formControlName="email"
    />
    <div
      *ngIf="emailAddress?.invalid && (emailAddress?.dirty || emailAddress?.touched)"
      class="Alcon-form__error-message"
    >
      <div *ngIf="emailAddress?.errors?.required">
        {{ 'contactusPage.errorMessages.mandatoryField' | cxTranslate }}
        {{ 'contactusPage.errorMessages.email' | cxTranslate }}
      </div>
      <div *ngIf="emailAddress?.errors?.pattern">
        {{ 'contactusPage.errorMessages.emailPattren' | cxTranslate }}
      </div>
    </div>
    <label
      class="contactus-form__email-info"
      *ngIf="emailAddress?.valid && emailChanged && configs.type == ContactUs"
      >{{ 'contactusPage.emailInfo' | cxTranslate }}</label
    >
  </div>
</ng-template>

<ng-template #phone let-form="form">
  <div [formGroup]="form" *ngIf="contactMethod?.value?.includes('phoneNumber')" class="w-100">
    <label
      class="contactus-form__label contactus-form__label--spacing-sm d-none"
      [ngClass]="configs?.isInModal ? '' : 'd-lg-block'"
      >{{ 'contactusPage.phoneNumber' | cxTranslate }}</label
    >
    <input
      type="text"
      class="form-control contactus-form__input-text"
      [ngClass]="{
        'Alcon-form__invalid-input-field': phoneNumber?.invalid && (phoneNumber?.dirty || phoneNumber?.touched),
        'Alcon-form__valid-input-field': phoneNumber?.valid && phoneNumber?.dirty && phoneNumber?.touched
      }"
      maxlength="15"
      placeholder="XXXXXXXXXXXXXXX"
      formControlName="phoneNumber"
    />
    <div *ngIf="phoneNumber?.invalid && (phoneNumber?.dirty || phoneNumber?.touched)" class="Alcon-form__error-message">
      <div *ngIf="phoneNumber?.errors?.required">
        {{ 'contactusPage.errorMessages.phoneNumber' | cxTranslate }}
      </div>
      <div *ngIf="phoneNumber?.errors?.charactersNotAllowed">
        {{ 'contactusPage.errorMessages.phoneNumberCharecters' | cxTranslate }}
      </div>
      <div *ngIf="phoneNumber?.errors?.minLength">
        {{ 'contactusPage.errorMessages.phoneNumberMinLength' | cxTranslate }}
      </div>
    </div>
    <label
      class="contactus-form__email-info"
      *ngIf="phoneNumber?.valid && phoneNumberChanged && configs.type == ContactUs"
      >{{ 'contactusPage.emailInfo' | cxTranslate }}</label
    >
  </div>
</ng-template>
