import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SOLensValidationService {
  constructor() {}

  individualMaxQuantity(variantInfo): boolean {
    if (
      variantInfo.quantity &&
      variantInfo.maxQuantity &&
      Number(variantInfo.quantity) > Number(variantInfo.maxQuantity)
    ) {
      variantInfo.displayError = true;
    } else {
      variantInfo.displayError = false;
    }
    return variantInfo.displayError;
  }

  validatePower(updatedLensPower): boolean {
    if (!updatedLensPower.value) {
      return true;
    }
    if (this.individualMaxQuantity(updatedLensPower)) {
      return true;
    }

    // this is for edit
    // if (
    //   (updatedLensPower.entryNumber && updatedLensPower.quantity && updatedLensPower.quantity.match(/[^0-9]/g)) ||
    //   (!updatedLensPower.entryNumber &&
    //     (updatedLensPower.quantity || updatedLensPower.quantity.toString() === '0') &&
    //     Number(updatedLensPower.quantity) <= 0) ||
    //   updatedLensPower.quantity.match(/[^0-9]/g)
    // ) {
    //   return true;
    // }
    return false;
  }

  validateNonZero(lensPowers): boolean {
    let error = false;
    lensPowers.forEach((power) => {
      if (
        (power.quantity || power.quantity.toString() === '0') &&
        (Number(power.quantity) <= 0 || power.quantity.match(/[^0-9]/g))
      ) {
        error = true;
      }
    });
    return error;
  }
}
