import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthActions, AuthRedirectService, TranslationService, WindowRef } from '@spartacus/core';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { OktaService } from 'src/app/services/okta/okta.service';
import AxonSignIn from 'src/axon-signin-widget/dist/js/okta-sign-in.min.js';
import { AccountSelectorService } from '../../multi-account-selector/services/account-selector.service';

@Component({
  selector: 'app-axon-okta-login',
  templateUrl: './axon-okta-login.component.html',
  styleUrls: ['./axon-okta-login.component.scss'],
})
export class AxonOktaLoginComponent implements AfterViewInit, OnDestroy {
  private destroy$ = new Subject<void>();
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  signIn;
  constructor(
    private authRedirectService: AuthRedirectService,
    private oktaService: OktaService,
    private store: Store,
    private cd: ChangeDetectorRef,
    protected winRef: WindowRef,
    private translation: TranslationService,
    private accountselectorService: AccountSelectorService
  ) {}

  ngAfterViewInit(): void {
    this.getTranslatedValues();
  }
  getTranslatedValues(): void {
    forkJoin([
      this.translation.translate('loginPage.email').pipe(take(1)),
      this.translation.translate('loginPage.cancel').pipe(take(1)),
      this.translation.translate('loginPage.emailVal').pipe(take(1)),
      this.translation.translate('loginPage.passwordVal').pipe(take(1)),
      this.translation.translate('loginPage.login').pipe(take(1)),
    ])
      .pipe(take(1))
      .subscribe((data) => {
        this.loadWidget(data);
      });
  }
  loadWidget(labelsData): void {
    const config = this.oktaService.getOktaConfig();
    if (labelsData) {
      config.i18n.en = {
        'primaryauth.title': ' ',
        'primaryauth.username.placeholder': labelsData[0],
        'error.username.required': labelsData[2],
        'error.password.required': labelsData[3],
        'password.forgot.email.or.username.placeholder': labelsData[0],
        'password.forgot.email.or.username.tooltip': labelsData[0],
        goback: labelsData[1],
        'primaryauth.submit': labelsData[4],
      };
    }
    this.signIn = new AxonSignIn(config);
    this.addWidgetToDOM();
  }

  addWidgetToDOM(): void {
    const loginWrapper = this.winRef.document.getElementById('loginWrapper');
    this.signIn.remove();
    this.signIn
      .showSignInToGetTokens({
        el: loginWrapper,
      })
      .then((response) => {
        if (response) {
          this.isLoading$.next(true);
          const token = response.accessToken.accessToken;
          this.oktaService
            .sendTokenToHybris(token)
            .pipe(takeUntil(this.destroy$))
            .subscribe(
              (res) => {
                this.accountselectorService.updateTokenInAuthStorage(res);
                this.store.dispatch(new AuthActions.Login());
                this.authRedirectService.redirect();
                this.signIn.authClient.tokenManager.add('idToken', response.idToken);
                this.signIn.authClient.tokenManager.add('accessToken', response.accessToken);
                this.signIn.remove();
              },
              (err) => {
                this.addWidgetToDOM();
              }
            );

          this.cd.detectChanges();
        }
      })
      .catch((err) => {
        throw err;
      });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
