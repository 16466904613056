<div *ngFor="let category of (groupedProducts$ | async)?.products; index as i" class="pdp_category__container">
  <h3 class="pdp_category__category-header" [ngClass]="{ 'mt-0 pb-4': i == 0 && isLandingPage }">
    {{ category.CategoryCode | removeWhitespace | lowercase | cxTranslate }} ({{ category.count }})
    <a
      class="pdp_category__see-all-link"
      *ngIf="category.products && category.count > 5"
      [routerLink]="{ cxRoute: route.snapshot.data?.cxRoute, params: { code: category.CategoryCode } } | cxUrl"
      >({{ 'productListingPage.showAll' | cxTranslate }})</a
    >
  </h3>
  <div *ngFor="let child of category.children">
    <h5 *ngIf="child.CategoryCode" class="pdp_category__subcategory-header">
      {{ child.CategoryCode | removeWhitespace | lowercase | cxTranslate }} ({{ child.count }})
      <a
        *ngIf="isLandingPage"
        class="pdp_category__see-all-link"
        [routerLink]="{ cxRoute: route.snapshot.data?.cxRoute, params: { code: child.CategoryCode } } | cxUrl"
        >({{ 'productListingPage.showAll' | cxTranslate }})</a
      >
    </h5>
    <div class="pdp_product__list">
      <app-single-poduct-tile
        class="pdp_product__product"
        *ngFor="let product of child.products | slice: 0:(child.showMore ? defaultProducts : child.products.length)"
        [isStockOrder]="isStockOrder"
        [product]="product"
      ></app-single-poduct-tile>
    </div>
    <div class="d-flex justify-content-end" *ngIf="!isLandingPage && child.products?.length > 10">
      <div class="pdp_product__showmore" *ngIf="child.showMore">
        <a
          class="pdp_product__showmore--link pdp_product__showmore--link--margin-top-13"
          (click)="child.showMore = !child.showMore"
        >
          {{ 'productListingPage.showMore' | cxTranslate }}
          <cx-icon type="PLPShowMore" class="pdp_product__showmore--icon"></cx-icon>
        </a>
      </div>
      <div class="pdp_product__showmore" *ngIf="!child.showMore">
        <a
          class="pdp_product__showmore--link pdp_product__showmore--link--margin-top-13"
          (click)="child.showMore = !child.showMore"
        >
          {{ 'productListingPage.showLess' | cxTranslate }}
          <cx-icon type="PLPShowLess" class="pdp_product__showmore--icon"></cx-icon>
        </a>
      </div>
    </div>
  </div>
  <div class="pdp_product__list" *ngIf="category.products">
    <app-single-poduct-tile
      class="pdp_product__product"
      *ngFor="
        let product of category?.products | slice: 0:(isLandingPage ? defaultProducts : category?.products.length)
      "
      [product]="product"
      [isStockOrder]="isStockOrder"
    ></app-single-poduct-tile>
  </div>
</div>
