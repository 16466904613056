import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { BaseSiteService, SemanticPathService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AlconBaseSite } from '../model/alcon-basesite.model';

@Injectable({
  providedIn: 'root',
})
export class FranchiseSelectorGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.baseSite.getActive().pipe(
      switchMap((currentSite) => {
        return this.baseSite.get(currentSite).pipe(
          map((siteDetails: AlconBaseSite) => {
            const { manualRegistration, isSxStorefront, isVCStorefront } = siteDetails;
            if (manualRegistration) {
              this.router.navigate(
                this.semanticPathService.transform({
                  cxRoute: 'register',
                })
              );
              return false;
            } else if (isSxStorefront && isVCStorefront) {
              return true;
            } else if (isVCStorefront) {
              this.router.navigate(
                this.semanticPathService.transform({
                  cxRoute: 'register',
                })
              );
              return false;
            } else if (isSxStorefront) {
              this.router.navigate(
                this.semanticPathService.transform({
                  cxRoute: 'registration',
                })
              );
              return false;
            } else {
              this.router.navigate(
                this.semanticPathService.transform({
                  cxRoute: 'login',
                })
              );
              return false;
            }
          })
        );
      })
    );
  }
  constructor(
    private baseSite: BaseSiteService,
    private router: Router,
    private semanticPathService: SemanticPathService
  ) {}
}
