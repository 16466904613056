import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActivatedRoutesService, CurrencyService, OccConfig } from '@spartacus/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OrderEntryFlowType } from 'src/app/store/States/cartItem.state';

@Component({
  selector: 'app-value-pack-items',
  templateUrl: './value-pack-items.component.html',
  styleUrls: ['./value-pack-items.component.scss'],
})
export class ValuePackItemsComponent implements OnInit, OnDestroy {
  constructor(
    private occConfig: OccConfig,
    private activatedRoutesService: ActivatedRoutesService,
    private currencyService: CurrencyService,
    private route: ActivatedRoute
  ) {}
  @Input() valuePack;
  @Input() isCheckoutPage = false;
  isOrderConfirmationPage = false;
  orderDetailsPage = false;
  cartPage = false;
  entryList = [];
  currencyIso$;
  @Input() finalPriceCalculated: boolean;
  externalOrder = false;
  valuePackPrice = 0;
  @Input() status?: string; // R1 temp fix for DTP

  get orderEntryFlowType(): {} {
    return OrderEntryFlowType;
  }
  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.externalOrder = !this.route.snapshot.data.vcCart?.webReferenceNumber;
    this.currencyIso$ = this.currencyService.getActive();
    this.activatedRoutesService.routes$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      const type = data[data.length - 1].data.Type;
      switch (type) {
        case 'Cart':
          this.cartPage = true;
          break;
        case 'Checkout':
          this.isCheckoutPage = true;
          break;
        case 'OrderConfirmation':
          this.isOrderConfirmationPage = true;
          break;
        case 'OrderDetails':
          this.orderDetailsPage = true;
          break;
      }
    });
    this.getEntryNumberList();
  }
  getEntryNumberList(): any {
    const entries = [];
    this.valuePack.forEach((item) => {
      entries.push(item.entryNumber);
      this.valuePackPrice = this.valuePackPrice + item.totalPrice.value;
    });
    this.entryList = entries;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
