<section class="p-3 payment-method-container">
  <div class="d-flex flex-wrap align-items-start flex-xl-row payment-method-container__payment-type">
    <cx-icon type="PaymentIcon">
      <label class="payment pl-2 mb-3">{{ 'checkoutPage.paymentMethod' | cxTranslate }}:</label></cx-icon
    >
    <div class="pl-2">
      <app-switch-toggle [options]="options" (valueChange)="switchValue($event)"></app-switch-toggle>
    </div>
  </div>
  <ng-container *ngIf="selectedPaymentMethod === 'CARD'">
    <ng-template #addNewCardTemplate class="payment-method-container__add-new-card">
      <div class="payment-method-container__add-new-card">
        <span class="payment-method-container__nocard">{{
          'orderDetailsPage.paymentMethodPanel.noCreditCard' | cxTranslate
        }}</span>
        <cx-icon class="cursor-pointer payment-method-container__switch" type="AddOrderOption" (click)="addNewCard()">
          <u>{{ 'orderDetailsPage.paymentMethodPanel.addNewCard' | cxTranslate }}</u></cx-icon
        >
      </div>
    </ng-template>
    <div *ngIf="isCardDetailsAvailable$ | async as isCardDetailsAvailable; else addNewCardTemplate">
      <ng-container *ngIf="isCardDetailsAvailable.length; else cardTemplate">
        <span *ngFor="let cardList of isCardDetailsAvailable">
          <ng-container *ngIf="cardList.defaultPayment">
            <cx-icon type="{{ cardList?.cardType?.code | uppercase }}"></cx-icon>
            <span class="payment-method-container__card-details">
              {{ 'orderDetailsPage.paymentMethodPanel.cardEndingWith' | cxTranslate }}{{ cardList?.cardNumber }}</span
            >
          </ng-container>
        </span>
      </ng-container>
      <ng-template #cardTemplate>
        <cx-icon type="{{ isCardDetailsAvailable?.cardType?.code | uppercase }}"></cx-icon>
        <span class="payment-method-container__card-details">
          {{ 'orderDetailsPage.paymentMethodPanel.cardEndingWith' | cxTranslate
          }}{{ isCardDetailsAvailable?.cardNumber }}</span
        >
      </ng-template>
      <cx-icon class="cursor-pointer payment-method-container__change" type="Change" (click)="addNewCard()"
        ><span class="payment-method-container__change-text">{{
          'orderDetailsPage.paymentMethodPanel.Change' | cxTranslate
        }}</span></cx-icon
      >
    </div>
  </ng-container>
</section>
