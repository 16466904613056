import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DropdownOptions } from 'src/app/shared/model/common.mode';

@Component({
  selector: 'app-dropdown-selector',
  templateUrl: './dropdown-selector.component.html',
  styleUrls: ['./dropdown-selector.component.scss'],
})
export class DropdownSelectorComponent implements OnInit, OnChanges {
  @ViewChild('dropdownOptionContainer', { read: ElementRef })
  public dropdownOptionContainer: ElementRef<any>;
  @HostBinding('class') wrapperClass = 'dropdownHold';
  openDropDown = false;
  disableDropDown = false;
  @Input() defaultFormSelectedValue = 'Select';
  @Input() showInValidIcon = false;
  @Input() formDropDown = false;
  @Input() textColorBlack = false;
  selectedValue = '';
  @Input() selectedLabel: any;
  @Input() options!: Array<DropdownOptions>;
  @Input() disableSingleOptions = true;
  @Input() selectedIndex;
  @Input() dropDownSize: string;
  @Output() dropDownSelected: EventEmitter<DropdownOptions> = new EventEmitter();
  selectedValueIconType: string;
  maxItemsShown = 7;
  optionSelected: boolean;
  previouslySelectIconValue;
  @Input() resetDropDown = false;

  @HostListener('document:click', ['$event'])
  clickout(event): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.openDropDown = false;
      if (this.previouslySelectIconValue && !this.selectedValueIconType) {
        this.selectedValueIconType = this.previouslySelectIconValue;
      }
    }
  }
  constructor(private eRef: ElementRef) {}

  ngOnInit(): void {
    this.setSelectValue();
  }
  setSelectValue(): void {
    if (this.selectedIndex && this.selectedIndex.toString()) {
      this.options = this.options.map((dropdownVal: DropdownOptions) => {
        dropdownVal.selected = false;
        return dropdownVal;
      });
      this.options[this.selectedIndex].selected = true;
    }
    if (this.options?.length > 0) {
      const slectedOption = this.options.find((dropdownVal: DropdownOptions) => dropdownVal.selected);
      if (slectedOption) {
        this.selectedValue = slectedOption?.text;
        this.selectedValueIconType = slectedOption?.iconType;
      }
    }
  }
  showDropDown(): void {
    if (this.disableDropDown) {
      this.openDropDown = false;
      return;
    }
    this.openDropDown = !this.openDropDown;
    this.previouslySelectIconValue = this.selectedValueIconType;
    this.selectedValueIconType = '';
  }
  selectOption(option: DropdownOptions): void {
    this.optionSelected = true;
    this.selectedValue = option.text;
    if (this.openDropDown) {
      this.selectedValueIconType = option.iconType;
    }
    this.openDropDown = false;
    this.options.forEach((val) => {
      val.selected = val.text === option.text && val.value === option.value ? true : false;
    });
    this.dropDownSelected.emit(option);
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.selectedValue = this.selectedValue
      ? this.resetDropDown
        ? this.defaultFormSelectedValue
        : this.selectedValue
      : this.formDropDown
      ? this.defaultFormSelectedValue
      : '— —';
    const currentValue = changes?.options?.currentValue;
    if (currentValue && currentValue.length === 1) {
      this.selectOption(currentValue[0] as DropdownOptions);
      this.setSelectValue();
      this.disableDropDown = currentValue.length === 1;
    } else if (currentValue && currentValue.length > 1) {
      const selectedRecord = changes?.options?.currentValue.find((node) => node.selected === true);
      if (selectedRecord?.text) {
        this.selectedValue = selectedRecord?.text;
      } else if (!this.formDropDown && !this.resetDropDown) {
        this.selectedValue = '— —';
      }
      this.disableDropDown = false;
    } else {
      this.selectedValue = this.selectedValue
        ? this.selectedValue
        : this.formDropDown
        ? this.defaultFormSelectedValue
        : '— —';
    }
    if (changes.selectedLabel?.currentValue?.label) {
      this.selectedValue = changes.selectedLabel?.currentValue.label;
    }
  }
}
