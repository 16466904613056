<h6 class="address__heading">{{ 'patientAddressPage.heading' | cxTranslate }}</h6>
<p *ngIf="!patientAddress" class="address__no-address errorDisplay text-left mt-0">
  {{ 'productDetailsPage.addressError' | cxTranslate }}
</p>
<address class="address__block">
  <ng-container *ngIf="patientAddress?.line1">{{ patientAddress.line1 }}<br /></ng-container>
  <ng-container *ngIf="patientAddress?.line2">{{ patientAddress.line2 }}<br /></ng-container>
  <ng-container *ngIf="patientAddress?.town">{{ patientAddress.town }} </ng-container>
  <ng-container *ngIf="patientAddress?.postalCode">{{ patientAddress.postalCode }}<br /></ng-container>
  <ng-container *ngIf="patientAddress?.region?.name">{{ patientAddress.region.name }}, </ng-container>
  <ng-container *ngIf="patientAddress?.country?.name">{{ patientAddress.country.name }}</ng-container>
</address>
