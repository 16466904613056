import { internal, registerInput } from 'okta'
import Util from "../../util/Util";

const TextBox = internal.views.forms.inputs.TextBox;
const TYPE = 'sapId';
const ERROR_SAPID_MAP = [
  'model.validation.field.sapId.invalidNumber',
  'model.validation.field.sapId.short',
  'model.validation.field.sapId.long',
];

export const SapInput = TextBox.extend({
  constructor() {
    TextBox.apply(this, arguments);
    const props = this.model.__schema__.props[this.options.name] || {};
    props.validate = this.validationDelegate.bind(this);
  },

  // /**
  //  * @Override
  //  */
  // events: {
  //   'keyup input': function keyupInput(e) {
  //     if (internal.util.Keys.isEsc(e)) {
  //       this.model.trigger('form:cancel');
  //     }
  //   },
  //   'keypress input': function keydownInput(evt) {
  //     var theEvent = evt || window.event;

  // // Handle paste
  // if (theEvent.type === 'paste') {
  //     key = theEvent.clipboardData.getData('text/plain');
  // } else {
  // // Handle key press
  //     var key = theEvent.keyCode || theEvent.which;
  //     key = String.fromCharCode(key);
  // }
  // var regex = /[0-9]|\./;
  // if( !regex.test(key) ) {
  //   theEvent.returnValue = false;
  //   if(theEvent.preventDefault) theEvent.preventDefault();
  // }
  //   }
  // },

  /**
   * @Override
   */
  postRender() {
    TextBox.prototype.postRender.call(this);
    const params = this.options.params;
    const inputElement = this.$(`input[name="${this.options.name}"]`);
    inputElement.attr('style', 'width:100%;');

  },

  /**
   * @Override
   */
  val() {
    return this.$('input').val();
  },

  /**
   * 
   * @param {string} value 
   * @returns {string | boolean} 
   */
  validationDelegate(value1) {
    var value=document.getElementsByName("sapId")[0].value;
    Util.logConsoleError("aaa " + value);
    //start with 0 length 10 or start with 1 then lenght will be 9 ,only numeric
    var sapIdRegex = /^0\d{9}$|^[1-9]\d{8}$$/;
    if(!sapIdRegex.test(value)){ 
      if(value.length<9)
        return ERROR_SAPID_MAP[1];
      else if(value.length>10)
        return ERROR_SAPID_MAP[2];
      else
        return ERROR_SAPID_MAP[0];
    }
    return true;
  }
});

registerInput(TYPE, SapInput);
