import { Component, OnInit } from '@angular/core';
import { CmsParagraphComponent, CmsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-faq-page',
  templateUrl: './faq-page.component.html',
  styleUrls: ['./faq-page.component.scss'],
})
export class FaqPageComponent implements OnInit {
  heading$: Observable<string>;
  subHeading$: Observable<string>;
  constructor(private cmsService: CmsService) {}

  ngOnInit(): void {
    this.heading$ = this.cmsService
      .getComponentData<CmsParagraphComponent>('VisioncareFAQTittleParagraphComponent')
      .pipe(map((resp: CmsParagraphComponent) => resp.content));
    this.subHeading$ = this.cmsService
      .getComponentData<CmsParagraphComponent>('VisioncareFAQSubTittleParagraphComponent')
      .pipe(map((resp: CmsParagraphComponent) => resp.content));
  }
}
