<div class="payment-method-popup">
  <div *ngIf="!savedCardList.length" class="add-new-card">
    {{ 'checkoutPage.addNewCardHeading' | cxTranslate }}
    <cx-icon role="button" type="Close" class="credit-card__closebtn" (click)="closePopup()"></cx-icon>
  </div>
  <div *ngIf="savedCardList.length" class="add-new-card">
    {{ 'checkoutPage.selectPaymentCard' | cxTranslate }}
    <div class="add-new-card__title-text d-flex align-items-center">
      {{ 'checkoutPage.selectCardtitle' | cxTranslate }}
    </div>
    <div *ngFor="let list of savedCardList">
      <input
        #radiobtn
        type="radio"
        class="alconInput__radio"
        id="{{ list.id }}"
        [value]="list.id"
        name="cardList"
        (change)="change($event)"
        [checked]="list.defaultPayment"
      />
      <label class="add-new-card__add-new-btn" for="{{ list.id }}"
        >{{ 'orderDetailsPage.paymentMethodPanel.cardEndingWith' | cxTranslate }}{{ list.cardNumber }}
        <span class="add-new-card__add-new-btn__logo-position"
          ><cx-icon type="{{ list?.cardType?.code | uppercase }}"></cx-icon
        ></span>
      </label>
    </div>
    <div>
      <cx-icon
        *ngIf="!showIframe"
        class="cursor-pointer add-new-card__add-new-btn"
        type="AddOrderOption"
        (click)="addNewCard()"
      >
        <u>{{ 'orderDetailsPage.paymentMethodPanel.addNewCard' | cxTranslate }}</u></cx-icon
      >
      <span *ngIf="showIframe" class="add-new-card__title-text">{{
        'checkoutPage.addNewCardHeading' | cxTranslate
      }}</span>
    </div>
    <div *ngIf="!showIframe" class="btn-alignment">
      <input
        class="btn-alignment__save-later"
        name="SUBMIT"
        type="button"
        value="{{ 'orderDetailsPage.paymentMethodPanel.useAndSaveDefault' | cxTranslate }}"
        (click)="submitPayment(true)"
      />
      <input
        class="btn-alignment__one-time"
        name="SUBMIT"
        type="submit"
        value="{{ 'orderDetailsPage.paymentMethodPanel.useSelectedCard' | cxTranslate }}"
        (click)="useSelectedCard()"
      />
    </div>
  </div>
  <div *ngIf="showIframe || !savedCardList.length">
    <iframe
      #iframe
      id="paymetricIFrame"
      (load)="loadPayment()"
      [src]="iframeurl"
      sandbox="allow-scripts allow-popups allow-modals allow-forms allow-same-origin"
      style="border: 0px #ffffff none; overflow: visible"
      name="dieCommFrame"
      scrolling="no"
      SameSite="None"
      frameborder="1"
      height="330px"
      class="my-iframe"
      allowfullscreen
    ></iframe>
    <div class="payment-method-popup__checkbox-position">
      <input
        type="checkbox"
        name="default"
        class="alconInput__checkbox"
        (change)="checkBoxChangeHandler($event)"
        value="default"
        [checked]="isDefaultCard"
        id="default-credit-card"
      />
      <label
        for="default-credit-card"
        class="editProduct__details__label d-flex editProduct__details__label--checkbox"
        >{{ 'checkoutPage.setDefaultCard' | cxTranslate }}</label
      >
    </div>
    <div class="btn-alignment">
      <input
        class="btn-alignment__save-later"
        name="SUBMIT"
        type="button"
        value="{{ 'orderDetailsPage.paymentMethodPanel.useAndSaveLater' | cxTranslate }}"
        (click)="submitPayment(true)"
      />
      <input
        class="btn-alignment__one-time"
        name="SUBMIT"
        type="submit"
        value="{{
          (showIframe
            ? 'orderDetailsPage.paymentMethodPanel.useNewCard'
            : 'orderDetailsPage.paymentMethodPanel.useOneTime'
          ) | cxTranslate
        }}"
        (click)="submitPayment(false)"
      />
    </div>
  </div>
</div>
