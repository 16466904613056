import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { CmsComponent, CmsService, ContentSlotData } from '@spartacus/core';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { IRegisterFormData } from '../i-register-form-data';

@Component({
  selector: 'app-register-terms-step',
  templateUrl: './register-terms-step.component.html',
  styleUrls: ['./register-terms-step.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterTermsStepComponent {
  @Input() public disabledSubmit = false;
  @Output() public termsAccepted = new EventEmitter<IRegisterFormData>();
  @Output() public goBack = new EventEmitter<void>();

  public termsForm: UntypedFormGroup = this.formBuilder.group({
    termsAndConditions: new UntypedFormArray([], Validators.required),
  });

  public termsCheckBoxes$: Observable<Array<string>> = this.cmsService.getContentSlot('RegisterFormTCPosition').pipe(
    map((contentSlot: ContentSlotData) => [...contentSlot.components]),
    switchMap((components) => {
      return combineLatest(components.map((component) => this.cmsService.getComponentData(component.uid)));
    }),
    tap((components: CmsComponent[]) => {
      const formArray: UntypedFormArray = this.termsForm.get('termsAndConditions') as UntypedFormArray;

      components.forEach((_) => {
        formArray.push(new UntypedFormControl(null, [Validators.required]));
      });

      this.termsForm = this.formBuilder.group({
        termsAndConditions: formArray,
      });
    }),
    map((components: CmsComponent[]) => {
      return components.map((component) => {
        return component.content;
      });
    })
  );
  constructor(public formBuilder: UntypedFormBuilder, public router: Router, public cmsService: CmsService) {}

  submit(): void {
    if (this.termsAndConditions.valid) {
      this.termsAccepted.emit();
    }
  }

  cancel(): void {
    this.router.navigate(['/']);
  }

  back(): void {
    this.goBack.emit();
  }

  checkTermsAndConditions(index): void {
    const formArray: UntypedFormArray = this.termsForm.get('termsAndConditions') as UntypedFormArray;
    formArray.controls[index].setValue(formArray.controls[index].value === 'on' ? null : 'on');
  }

  get termsAndConditions(): AbstractControl {
    return this.termsForm.get('termsAndConditions');
  }
}
