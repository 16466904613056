import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OrderDetailsService } from 'src/app/services/order-details/order-details.service';
import { EntryType, OrderDetailsObj, VisionCareEntries } from 'src/app/store/States/cartItem.state';
@Component({
  selector: 'app-alcon-confirmation',
  templateUrl: './alcon-confirmation.component.html',
  styleUrls: ['./alcon-confirmation.component.scss'],
})
export class AlconConfirmationComponent implements OnInit, OnDestroy {
  cartvalue: boolean;
  practiceOrderEntries: VisionCareEntries[];
  deliveryMethod;
  ponumber;
  orderNumber;
  finalPriceCalculated;
  directToPracticeEntries: VisionCareEntries[];
  private destroy$ = new Subject<void>();
  constructor(private cd: ChangeDetectorRef, private orderDetailsService: OrderDetailsService) {}
  ngOnInit(): void {
    this.orderDetailsService.orderInfo.pipe(takeUntil(this.destroy$)).subscribe((data: OrderDetailsObj) => {
      const orderData = data;
      if (orderData) {
        this.cartvalue = true;
        this.orderNumber = data.code;
        this.finalPriceCalculated = data.calculated;
        if (orderData.deliveryMode) {
          this.deliveryMethod = orderData.deliveryMode.name;
        }
        this.ponumber = orderData.purchaseOrderNumber;
        this.practiceOrderEntries = orderData.rootGroups.filter((items) => items.label === EntryType.PRACTICE_ORDER);
        this.directToPracticeEntries = orderData.rootGroups.filter(
          (items) => items.label === EntryType.DIRECT_TO_PATIENT
        );
        this.cd.detectChanges();
      }
    });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
