import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { LoadAllFavourite } from 'src/app/store/Actions/favouriteCount.action';
import { getFavouriteCount } from 'src/app/store/Selectors/favouriteCount.selector';

@Component({
  selector: 'app-favourite-link',
  templateUrl: './favourite-link.component.html',
  styleUrls: ['./favourite-link.component.scss'],
})
export class FavouriteLinkComponent implements OnInit {
  favouriteCount: Observable<number> = this.favStore.select(getFavouriteCount);
  constructor(private favStore: Store) {}

  ngOnInit(): void {
    this.favStore.dispatch(LoadAllFavourite());
  }
}
