import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CmsService, GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { LocalStorageService } from 'src/app/services/localstorage/local-storage.service';
import { RegisterService } from 'src/app/services/register/register.service';
import { TealiumService } from 'src/app/services/tealium/tealium.service';
import { LOGIN_ERROR_TYPE, LOGIN_PAGE } from 'src/app/shared/constants/constants';
import { LoginError } from 'src/app/shared/model/login-error.model';
import { UserDetails } from 'src/app/shared/model/user.model';
import { ClearStore } from 'src/app/store/Actions/cartItem.action';
import { ContactusConfig } from '../../../shared/config/contactus-config';
import { EContactUsSubmitButtonPosition } from '../../../shared/config/contactus-submitButtonPosition.enum';
import { ContactUsType } from '../../../shared/config/contactus-type.enum';
import { ModalOptions } from '../../../shared/model/common.mode';

@Component({
  selector: 'app-base-login',
  templateUrl: './base-login.component.html',
  styleUrls: ['./base-login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BaseLoginComponent implements OnInit, OnDestroy {
  isForgotPwdRoute = false;
  isEmailSent = true;
  userLocked = false;
  UKMarketingPageTopSection2BPara1;
  UKMarketingPageTopSection2BPara2;
  UKMarketingPageTopSection2BPara3;
  UKMarketingPageTopSection2CPara;
  MarketingRequestDemoLink;
  MarketingBanner1;
  UKMarketingPageTopSection3APara;
  UKMarketingPageTopSection3AParaItems;
  UKMarketingPageTopSection4APara;
  UKMarketingPageTopSection4AParaItems;
  MarketingBanner2;
  authClient;
  accessToken;
  errorInfo = {
    errorType: '',
    errorMessage: LOGIN_PAGE.DEFAULT_MESSAGE,
    errorDescription: '',
  } as LoginError;
  userActivity: UserDetails;
  private destroy$ = new Subject<void>();
  public modalOptions: ModalOptions = {
    open: false,
    header: false,
    modalTitle: '',
    borderRadius: '15px',
    backgroundColor: '#fff',
    class: 'modal-size',
  } as ModalOptions;

  public thankYouModalOptions: ModalOptions = {
    open: false,
    header: false,
    modalTitle: '',
    borderRadius: '20px',
    closeIcon: false,
    ctaText: 'contactusPage.returnToLastVisitedPage',
    ctaClass: 'thankyou-cta',
  };

  public modalConfigs: ContactusConfig = {
    showMandatoryFieldText: false,
    isInModal: true,
    type: ContactUsType.requestDemo,
    submitButtonPosition: EContactUsSubmitButtonPosition.center,
  };

  public contactFormConfig = {
    contactname: ['', [Validators.required, Validators.maxLength(30)]],
    contactMethod: this.formBuilder.array([], Validators.required),
    preferredTime: ['', [Validators.required]],
    companyName: ['', [Validators.required]],
    email: [''],
    phoneNumber: [''],
    termsConditions: [false],
  };

  constructor(
    private cmsService: CmsService,
    private activatedRoute: ActivatedRoute,
    private tealium: TealiumService,
    private localStorageService: LocalStorageService,
    private globalMessageService: GlobalMessageService,
    private cd: ChangeDetectorRef,
    private store: Store,
    public router: Router,
    public registerService: RegisterService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.isForgotPwdRoute = this.activatedRoute.snapshot.routeConfig.path === 'forgot-password' ? true : false;
    this.localStorageService.clearStorage('stockorder_info');
  }

  ngOnInit(): void {
    const token = this.activatedRoute.snapshot.queryParams.token;

    if (token) {
      this.registerService.activateUser(token).pipe(take(1)).subscribe();
    }

    this.tealium.view();
    /* This can be removed after merging with R2(it is implemented in clear store gaurd) */
    this.store.dispatch(ClearStore());
    this.cmsService
      .getComponentData('UKMarketingPageTopSection2BPara1')
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.UKMarketingPageTopSection2BPara1 = JSON.parse(JSON.stringify(val));
      });
    this.cmsService
      .getComponentData('UKMarketingPageTopSection2BPara2')
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.UKMarketingPageTopSection2BPara2 = val;
      });
    this.cmsService
      .getComponentData('UKMarketingPageTopSection2BPara3')
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.UKMarketingPageTopSection2BPara3 = JSON.parse(JSON.stringify(val));
      });
    this.cmsService
      .getComponentData('MarketingBanner1')
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.MarketingBanner1 = val;
      });
    this.cmsService
      .getComponentData('UKMarketingPageTopSection3APara')
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.UKMarketingPageTopSection3APara = val;
        this.UKMarketingPageTopSection3AParaItems = this.UKMarketingPageTopSection3APara?.content.split('</br>');
      });
    this.cmsService
      .getComponentData('UKMarketingPageTopSection4APara')
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.UKMarketingPageTopSection4APara = val;
        this.UKMarketingPageTopSection4AParaItems = this.UKMarketingPageTopSection4APara?.content.split('</br>');
      });
    this.cmsService
      .getComponentData('UKMarketingPageTopSection2CPara')
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.UKMarketingPageTopSection2CPara = val;
      });
    this.cmsService
      .getComponentData('MarketingRequestDemoLink')
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.MarketingRequestDemoLink = val;
      });
    this.cmsService
      .getComponentData('MarketingBanner2')
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.MarketingBanner2 = val;
      });
    this.globalMessageService
      .get()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data.hasOwnProperty(GlobalMessageType.MSG_TYPE_ERROR) && data[GlobalMessageType.MSG_TYPE_ERROR].length) {
          const errorMessage = data[GlobalMessageType.MSG_TYPE_ERROR][0].params?.errorMessage;
          if (errorMessage) {
            this.errorInfo = JSON.parse(data[GlobalMessageType.MSG_TYPE_ERROR][0].params?.errorMessage);
            this.userLocked = this.errorInfo.errorType === LOGIN_ERROR_TYPE.LOCKED_USER;
          }
        }
        this.cd.detectChanges();
      });
  }
  emailSent(): void {
    this.isEmailSent = false;
  }

  openDemoForm(): void {
    this.modalOptions.open = true;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
