import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseSiteService, User } from '@spartacus/core';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ADDRESS_TYPES, MODAL_POPUP_CONFIG } from 'src/app/shared/constants/constants';
import { AlconBaseSite } from 'src/app/shared/model/alcon-basesite.model';
import { B2BUnits, UnitsData } from 'src/app/store/States/b2bUnits.state';
import { AccountSelectorService } from '../../services/account-selector.service';
import { RequestAccessAdditionalAccountComponent } from '../request-access-additional-account/request-access-additional-account.component';

@Component({
  selector: 'app-sold-to-selector',
  templateUrl: './sold-to-selector.component.html',
  styleUrls: ['./sold-to-selector.component.scss'],
})
export class SoldToSelectorComponent {
  @Output() redirect = new EventEmitter();
  @Input() isFromPage = true;
  useraccountInfo$: Observable<User> = this.userProfileService.get();
  accounts$ = this.accountSelectorService.getUnitStoreData().pipe(
    map((data: Partial<B2BUnits>) => {
      return data?.units?.map((unit: Partial<UnitsData>) => {
        const address = unit?.addresses?.find(
          (addresses) => addresses?.title?.toLowerCase() === ADDRESS_TYPES.SOLD_TO_ADDRESS
        );
        return {
          ...address,
          name: unit.name,
          uid: unit.uid,
        };
      });
    })
  );
  basesites$: Observable<any> = this.baseSite.getActive().pipe(
    switchMap((currentSite) => {
      return this.baseSite.get(currentSite).pipe(
        map((siteDetails: AlconBaseSite) => {
          return {
            isSxStorefront: siteDetails?.isSxStorefront,
            additionalAccountEnabledForSx: siteDetails?.additionalAccountEnabledForSx,
          } as AlconBaseSite;
        })
      );
    })
  );
  constructor(
    private accountSelectorService: AccountSelectorService,
    private modalService: NgbModal,
    private baseSite: BaseSiteService,
    private userProfileService: UserProfileFacade
  ) {}

  setUnitData(unitData): void {
    this.accountSelectorService.updateAuthStorage(unitData).subscribe((data) => {
      this.accountSelectorService.updateTokenInAuthStorage(data);
      this.accountSelectorService.setLocalStorageSoldTo(unitData);
      this.accountSelectorService.setLocalStorageSoldToCount(true);
      this.redirect.emit();
    });
  }

  openRequestPopup(): void {
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(RequestAccessAdditionalAccountComponent, {
      ...MODAL_POPUP_CONFIG,
      windowClass: 'additional-account_accessPopup',
      backdropClass: 'true-blue-backdrop',
      backdrop: false,
    });
  }
}
