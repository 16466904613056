<div class="container-fluid legal-announcement">
  <div class="row justify-content-center">
    <div class="col-12 col-xl-8">
      <div class="d-flex flex-column">
        <div class="legal-announcement__header-section">
          <h1 class="legal-announcement__header-section--text">{{ pageTitle }}</h1>
        </div>
        <div class="legal-announcement__legal-body-section">
          <p [innerHTML]="legalContent$ | async"></p>
        </div>
      </div>
      <div class="d-flex justify-content-between legal-announcement__btn-container">
        <div>
          <button
            type="button"
            class="btn text-uppercase legal-announcement__btn-container--decline-btn"
            (click)="logout()"
          >
            {{ 'legalAnnouncement.decline' | cxTranslate }}
          </button>
        </div>
        <div>
          <button
            type="submit"
            class="btn text-uppercase legal-announcement__btn-container--accept-btn"
            (click)="redirectToDashBoard()"
          >
            {{ 'legalAnnouncement.accept' | cxTranslate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-loader-icon *ngIf="loading"></app-loader-icon>
