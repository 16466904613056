import { Component, OnInit } from '@angular/core';
import { CmsService, PageMeta, PageMetaService } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-contactus-page',
  templateUrl: './contactus-page.component.html',
  styleUrls: ['./contactus-page.component.scss'],
})
export class ContactusPageComponent implements OnInit {
  title$: Observable<string>;
  constructor(private pageMetaService: PageMetaService) {}
  ngOnInit(): void {
    this.title$ = this.pageMetaService.getMeta().pipe(
      filter(Boolean),
      map((meta: PageMeta) => meta.heading || meta.title)
    );
  }
}
