<div class="account_consolidation">
  <div class="row">
    <div class="col-7">
      <span class="text-left account_consolidation__heading">{{
        'accountDetailsPage.orderconsolidationenabled' | cxTranslate
      }}</span>
    </div>
    <div class="col-3"><cx-icon type="AccountDetailsConsolidation"></cx-icon></div>
  </div>
  <section class="account_consolidation__content">
    <cx-page-slot position="AccountDetailsConsolidationPosition"></cx-page-slot>
  </section>
</div>
