<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="favorite__header col-10 col-xl-10">
      <h1 [ngClass]="favoriteList.length ? '' : 'text-center'">{{ title$ | async }} ({{ favoriteList.length }})</h1>
    </div>
  </div>

  <div *ngIf="favoriteList.length" class="row justify-content-center">
    <div class="d-flex col-lg-10 favorite__list">
      <ng-container *ngFor="let product of favoriteList; let i = index">
        <app-single-poduct-tile
          [product]="product"
          [isWishList]="true"
          class="product__tile"
          (productSelected)="productSelected($event)"
        >
        </app-single-poduct-tile>
        <div class="d-none d-lg-block col-10 col-xl-10 mt-4 mb-2" *ngIf="(i + 1) % 5 == 0">
          <hr />
        </div>
      </ng-container>
    </div>
  </div>

  <div *ngIf="!favoriteList.length" class="row justify-content-center">
    <div class="favorite__msg col-10 col-xl-10">
      <cx-page-slot class="favorite__msg__slot" position="FavouritesMessagePosition"></cx-page-slot>
    </div>
    <div class="favorite__img col-10 col-xl-10">
      <cx-media [container]="noFavouriteImgContent.media" alt="No Favourite Image"></cx-media>
    </div>
  </div>
</div>
