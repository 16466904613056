import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertRedirectConfig } from 'src/app/shared/model/alert.model';

@Component({
  selector: 'app-alcon-redirect-popup',
  templateUrl: './alcon-redirect-popup.component.html',
  styleUrls: ['./alcon-redirect-popup.component.scss'],
})
export class AlconRedirectPopupComponent implements OnInit {
  @Input() alertRedirectConfig: AlertRedirectConfig;
  constructor(public activeModal: NgbActiveModal) {}
  ngOnInit(): void {}
}
