import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AlconUserPermissionService } from '../alcon-user-permission-provider/service/alcon-user-permission.service';

@Component({
  selector: 'app-show-price',
  templateUrl: './show-price.component.html',
  styleUrls: ['./show-price.component.scss'],
})
export class ShowPriceComponent implements OnInit, OnChanges {
  @Input() productDetails: any;
  @Input() basePrice = false;
  @Input() set showPrice(showPrice: boolean) {
    this.pShowPrice = showPrice ?? true;
  }

  get showPrice(): boolean {
    return this.pShowPrice;
  }

  price: any;
  private pShowPrice: boolean;

  constructor(private userPermissionService: AlconUserPermissionService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.productDetails.currentValue) {
      this.productDetails = changes.productDetails.currentValue;
      this.calculatePrice();
    }
  }

  ngOnInit(): void {
    this.calculatePrice();
  }

  private calculatePrice(): void {
    this.price = this.basePrice ? this.productDetails?.basePrice : this.productDetails?.totalPrice;
    const isPriceVisible = this.userPermissionService.getPriceStatementPermission();
    const isTrial = this.productDetails?.product?.packSize?.toLowerCase().includes('trial');
    this.showPrice =
      (isTrial || (!isTrial && this.price?.value !== 0)) &&
      isPriceVisible &&
      this.userPermissionService.getPricesVisibilityPermission() &&
      this.showPrice;
  }
}
