<div class="modal fade" *ngIf="modalOptions && modalOptions.open" [ngClass]="{ show: modalOptions.open }" tabindex="-1">
  <div
    class="modal-dialog {{ modalOptions.class }}"
    [ngClass]="{ 'show-big-popup': showBigPopup, 'modal-lg': !modalOptions.class }"
  >
    <div
      class="modal-content"
      [ngStyle]="{ 'border-radius': modalOptions.borderRadius, 'background-color': modalOptions.backgroundColor }"
    >
      <div class="modal-header" *ngIf="modalOptions.modalTitle">
        <h5 class="modal-title" *ngIf="modalOptions.modalTitle">{{ modalOptions.modalTitle }}</h5>
        <button type="button" class="close" (click)="closePopup()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" [ngClass]="{ 'modal-body-nopadding': !pdp }">
        <ng-content> </ng-content>
      </div>
    </div>
  </div>
</div>
<div class="app-modal-background" *ngIf="modalOptions && modalOptions.open"></div>
