import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CartItemsService } from 'src/app/services/cart-items/cart-items.service';

@Component({
  selector: 'app-mini-cart-value-pack-item-list',
  templateUrl: './mini-cart-value-pack-item-list.component.html',
  styleUrls: ['./mini-cart-value-pack-item-list.component.scss'],
})
export class MiniCartValuePackItemListComponent implements OnInit {
  @Input() valuePackComponentData;
  onCartPage: Observable<any>;
  isPriceVisible: boolean;

  constructor(private cartService: CartItemsService) {}

  ngOnInit(): void {
    this.onCartPage = this.cartService.getActivatedRouteData();
  }
}
