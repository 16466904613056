import { Product } from '@spartacus/core';
import { PACK_SIZE } from '../constants/constants';
import { PackSize } from '../model/product.model';

declare module '@spartacus/core' {
  interface Product {
    code?: string;
    configurable?: false;
    contactLensType?: string;
    favourite?: boolean;
    isDryEyeSolution?: false;
    isLensCareSolution?: boolean;
    maxPurchaseableQty?: null;
    name?: string;
    packSizeList?: Array<PackSize>;
    qtyMultiplier?: number;
    summary?: string;
    techBenefitUrl?: string;
    url?: string;
    variantTree?: any;
    packSize?: string;
  }
}

export const Utilities = {
  getSortedValuePack(valuePacks: Array<any>): Array<any> {
    const sortedValuePacks = [];
    let sortedValuePack = [];
    valuePacks?.forEach((valuePack) => {
      sortedValuePack = [];
      sortedValuePack.push(
        ...valuePack.entries.filter((valuePackItem) => valuePackItem.eyeSight?.toLowerCase() === 'right')
      );
      sortedValuePack.push(
        ...valuePack.entries.filter((valuePackItem) => valuePackItem.eyeSight?.toLowerCase() === 'left')
      );
      sortedValuePack.push(
        ...valuePack.entries.filter((valuePackItem) => valuePackItem.product.isLensCareSolution === true)
      );

      sortedValuePacks.push(sortedValuePack);
    });
    return sortedValuePacks;
  },
  getSortMiniCart(valuePacks: Array<any>): Array<any> {
    const sortedValuePacks = [];
    sortedValuePacks.push(valuePacks.find((valuePackItem) => valuePackItem.product.isLensCareSolution === true));
    sortedValuePacks.push(valuePacks.find((valuePackItem) => valuePackItem.eyeSight?.toLowerCase() === 'right'));
    sortedValuePacks.push(valuePacks.find((valuePackItem) => valuePackItem.eyeSight?.toLowerCase() === 'left'));
    return sortedValuePacks;
  },
  setSelectedPackSize(data: Product, selectedPackSize: string): Array<PackSize> {
    return data.packSizeList.map((packsize) => ({
      ...packsize,
      selected: this.getSelectedPackSize(selectedPackSize, packsize),
    }));
  },
  getSelectedPackSize(selectedPackSize: string, packSize: PackSize): boolean {
    return packSize.value.toLowerCase().includes(PACK_SIZE.TRIAL.toLowerCase())
      ? selectedPackSize?.toLowerCase().split(' ').join('') ===
          packSize.value.toLowerCase() + packSize?.trialCount?.toLowerCase()
      : selectedPackSize?.toLowerCase() === packSize?.value.toLowerCase();
  },
  isoDateWithoutTimeZone(date): string {
    if (!date) {
      return '';
    }
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString();
    const dayNumber = date.getDate().toString();

    return (
      year +
      '-' +
      (month[1] ? month : '0' + month[0]) +
      '-' +
      (dayNumber[1] ? dayNumber : '0' + dayNumber[0]) +
      'T00:00:00.000Z'
    );
  },
  isTablet(): boolean {
    return document.body.clientWidth > 768 && document.body.clientWidth <= 1024;
  },
  isMobile(): boolean {
    return document.body.clientWidth <= 768;
  },
  isDesktop(): boolean {
    return document.body.clientWidth > 1024;
  },
};
