<div class="loader-dailog">
  <div class="loader-dailog__container">
    <div class="loader-dailog__content">
      <div class="loader-dailog__icon">
        <img src="./assets/Icons/LoadingIcon.webp" alt="LoadingIcon" />
      </div>
      <h3 class="loader-dailog__message">{{ 'checkoutPage.finalPrice.loaderMessage' | cxTranslate }}</h3>
    </div>
  </div>
</div>
