<div class="d-flex flex-column toolsPanel">
  <h4 class="d-flex toolsPanel__title">{{ (panelContent | async)?.title }}</h4>
  <p
    class="toolsPanel__desc"
    *ngIf="(panelContent | async).description"
    [innerHTML]="(panelContent | async)?.description.content"
  ></p>
  <ng-container
    *ngIf="(panelContent | async)?.button">
  <a
    class="d-flex mt-auto btn btn-primary toolsPanel__btn justify-content-center align-items-center"
    [target]="(panelContent | async)?.button?.target == 'true' ? '_blank' : '_self'"
    role="button"
    href="{{ (panelContent | async)?.button?.url }}"
    *ngIf="(panelContent | async)?.button?.external == 'true'; else internalLink"
    >{{ (panelContent | async)?.button?.linkName }}
  </a>
  <ng-template #internalLink>
    <a
      class="d-flex mt-auto btn btn-primary toolsPanel__btn justify-content-center align-items-center"
      [target]="(panelContent | async)?.button?.target == 'true' ? '_blank' : '_self'"
      role="button"
      routerLink="{{ (panelContent | async)?.button?.url }}"
    >
      {{ (panelContent | async)?.button?.linkName }}
    </a>
  </ng-template>
  </ng-container>
</div>
