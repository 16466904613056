import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConverterService, OccEndpointsService, OccProductSearchAdapter, SearchConfig } from '@spartacus/core';
import { CurrentUserIdService } from 'src/app/shared-modules/user-id-provider/service/current-user-id.service';

@Injectable()
export class VcProductSearchAdapter extends OccProductSearchAdapter {
  constructor(
    http: HttpClient,
    occEndpoints: OccEndpointsService,
    converter: ConverterService,
    private userIdService: CurrentUserIdService
  ) {
    super(http, occEndpoints, converter);
  }

  protected getSearchEndpoint(query: string, searchConfig: SearchConfig): string {
    return this.occEndpoints.buildUrl('productSearch', {
      queryParams: { query, ...searchConfig },
      urlParams: { userId: this.userIdService.getUserId() },
    });
  }
}
