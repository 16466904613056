import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-marketting-banner',
  templateUrl: './marketting-banner.component.html',
  styleUrls: ['./marketting-banner.component.scss'],
})
export class MarkettingBannerComponent {
  items$: Observable<any>[];
  constructor(public activeModal: NgbActiveModal) {}
}
