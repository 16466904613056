<div class="register-success">
  <div class="container-fluid">
    <div class="register-success-wrapper">
      <div class="d-flex register-success-container justify-content-center">
        <div class="col-5">
          <div class="left-section-wrapper">
            <cx-page-slot position="LeftPane1A"></cx-page-slot>
          </div>
        </div>
        <div class="col-5">
          <div class="right-section-wrapper">
            <cx-media class="" [container]="(backgroundImage$ | async)?.media"></cx-media>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
