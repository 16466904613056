<div class="address">
  <h5 class="address__header-label" *ngIf="addressTitle">
    {{ addressTitle }}<ng-container *ngIf="showColon">:</ng-container>
  </h5>
  <app-address-block [textSize]="textSize" [itemGap]="itemGap" [address]="userAddress"></app-address-block>
  <div *ngIf="showEdit" class="address__edit" (click)="editAddress()">
    <cx-icon title="{{ 'commonTitles.edit' | cxTranslate }}" role="button" type="EditBlue"></cx-icon>
    <span class="address__edit__label">{{ 'commonTitles.edit' | cxTranslate }}</span>
  </div>
</div>
