import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
})
export class TimepickerComponent {
  displayTime;
  meridianValue = 'PM';
  timeError = false;
  disabled = false;
  resetErrors = false;
  @Input() set meridian(value) {
    this.meridianFlag = value;
  }
  meridianFlag;
  @Input() set disable(value) {
    this.disabled = value;
  }
  @Input() set resetError(value) {
    this.setError(false);
  }
  @Input() set time(value) {
    this.displayTime = value;
    this.setTime();
  }
  @Output() emitMeridianChanged = new EventEmitter();
  @Output() emitTimeError: EventEmitter<boolean> = new EventEmitter();

  constructor(private cd: ChangeDetectorRef) {}

  formatTime(event): void {
    const value = event.target.value;
    const isValid = /^\d+$/.test(value);
    if (!isValid) {
      event.target.value = value.slice(0, -1);
    }
    if (this.meridianFlag) {
      this.checkHours(true);
    } else {
      this.checkHours(false);
    }
  }
  formatHours(): void {
    if (Number(this.displayTime.hour) < 10 && Number(this.displayTime.hour) !== 0) {
      this.displayTime.hour = this.formatSingleDigitValue(this.displayTime.hour);
    }
  }
  formatMinutes(): void {
    if (Number(this.displayTime.minute) < 10) {
      this.displayTime.minute = this.formatSingleDigitValue(this.displayTime.minute);
    }
  }

  formatDigits(): void {
    this.formatHours();
    this.formatMinutes();
  }

  setTime(): void {
    if (this.meridianFlag) {
      this.setCorrectMeridian();
      this.meridianHourFormat();
    } else {
      this.nonMeridianHourFormat();
    }
    this.formatDigits();
  }
  checkHours(meridian: boolean): void {
    if (meridian) {
      if (Number(this.displayTime.hour) > 12 || Number(this.displayTime.hour) === 0) {
        this.setError(true);
      } else {
        this.setError(false);
        this.checkMinute();
      }
    } else {
      if (Number(this.displayTime.hour) > 23) {
        this.setError(true);
      } else {
        this.setError(false);
        this.checkMinute();
      }
    }
  }

  setError(value): void {
    this.timeError = value;
    this.emitTimeError.emit(value);
  }

  checkMinute(): void {
    if (Number(this.displayTime.minute) > 59) {
      this.setError(true);
    } else {
      this.setError(false);
    }
  }

  meridianHourFormat(): void {
    if (Number(this.displayTime.hour) < 12 && Number(this.displayTime.hour) !== 0) {
      this.displayTime.hour = this.displayTime.hour;
    } else if (Number(this.displayTime.hour) === 12) {
      this.displayTime.hour = 12;
    } else if (Number(this.displayTime.hour) === 0) {
      this.meridianValue = 'AM';
      this.emitMeridianChanged.emit(this.meridianValue);
      this.displayTime.hour = 12;
    } else if (Number(this.displayTime.hour) === 24) {
      this.displayTime.hour = 12;
    } else {
      this.displayTime.hour = Number(this.displayTime.hour) - 12;
      this.meridianHourFormat();
    }
  }

  setCorrectMeridian(): void {
    if (Number(this.displayTime.hour) > 12) {
      this.meridianValue = 'PM';
      this.emitMeridianChanged.emit(this.meridianValue);
    } else if (Number(this.displayTime.hour) <= 12) {
      this.meridianValue = 'AM';
      this.emitMeridianChanged.emit(this.meridianValue);
    }
  }
  nonMeridianHourFormat(): void {
    if (Number(this.displayTime.hour) === 24 || Number(this.displayTime.hour) === 0) {
      this.displayTime.hour = '00';
    } else if (Number(this.displayTime.hour > 24)) {
      this.displayTime.hour = Number(this.displayTime.hour) - 24;
      this.nonMeridianHourFormat();
    }
  }

  formatSingleDigitValue(value: number): string {
    return `0${value}`.slice(-2);
  }

  toggleMeridian(): any {
    this.meridianValue = this.meridianValue === 'PM' ? 'AM' : 'PM';
    this.meridianChanged(this.meridianValue);
    this.cd.detectChanges();
  }
  meridianChanged(value): any {
    this.emitMeridianChanged.emit(value);
  }
}
