/**
 * AutoEnrollEmailController
 * 
 * Automatically sends MFA enrollment email to user without need for button click
 * 
 **/


import { _ } from 'okta';
import FormController from 'util/FormController';
import Util from 'util/Util';

const Model = {
  local: {
    factorType: 'string',
    provider: 'string',
  },
  save: function() {
    Util.logConsoleError('MFA Verification Email Sent');
    this.trigger('save');
    const factorOpt = this.pick('factorType', 'provider');
    return this.doTransaction(function(transaction) {
      const factor = _.findWhere(transaction.factors, factorOpt);

      return factor.enroll();
    });
  },
};

export default FormController.extend({
  className: 'auto-enroll-email',
  Form: function() {return {noButtonBar: true}},
  Model: Model,

  initialize: function() {
    this.model.set(_.pick(this.options, 'factorType', 'provider'));
    this.model.save();
  },
});
