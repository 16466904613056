import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CmsService, ContentSlotData, GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReturnFormService } from 'src/app/services/returnform/return-form.service';
import { GlobalMessage, RETURN_CONFIRMATION, VALIDATOR_CONSTANT } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-return-form-confirmation',
  templateUrl: './return-form-confirmation.component.html',
  styleUrls: ['./return-form-confirmation.component.scss'],
})
export class ReturnFormConfirmationComponent implements OnInit, OnDestroy {
  returnConfirmationForm: UntypedFormGroup;
  returnFormImgContent = this.cmsService.getComponentData('ReturnformPageImageComponent');
  returnEmail: string;
  returnCode: string;
  private destroy$ = new Subject<void>();
  componentList: Observable<ContentSlotData>;
  isAdditionalEmailSelected = false;
  isEmailSent = false;
  constructor(
    private cmsService: CmsService,
    private activatedRoute: ActivatedRoute,
    private returnFormService: ReturnFormService,
    private returnFormBuilder: UntypedFormBuilder,
    private globalMessageService: GlobalMessageService
  ) {}

  ngOnInit(): void {
    this.returnEmail = this.activatedRoute?.snapshot?.params?.email;
    this.returnCode = this.activatedRoute?.snapshot?.params?.returnCode;
    this.componentList = this.cmsService.getContentSlot('ReturnConfirmMsgPosition');
    this.returnConfirmationForm = this.returnFormBuilder.group({
      email: ['', [Validators.email, Validators.pattern(VALIDATOR_CONSTANT.EMAIL_PATTREN)]],
    });
  }
  sendReturnConfirmationEmail(): void {
    const formData = this.returnConfirmationForm.getRawValue();
    const returnData = {
      returnCode: this.returnCode,
      email: formData?.email,
    };
    this.returnFormService
      .sendReturnConfirmationToAdditionalEmail(returnData)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          this.isEmailSent = response;
          setTimeout(() => {
            this.returnConfirmationForm.reset();
            this.isEmailSent = false;
          }, RETURN_CONFIRMATION.Timeout);
        },
        (error) => {
          const errorMessage = error?.error?.errors[0].message;
          this.globalMessageService.add(errorMessage, GlobalMessageType.MSG_TYPE_ERROR, GlobalMessage.TIMEOUT);
        }
      );
  }
  selectAdditionalEmail(): void {
    this.isAdditionalEmailSelected = !this.isAdditionalEmailSelected;
    this.returnConfirmationForm.reset();
  }
  get email(): AbstractControl {
    return this.returnConfirmationForm.get('email');
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
