import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageMeta, PageMetaService, SemanticPathService, TranslationService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

@Component({
  selector: 'app-new-order',
  templateUrl: './new-order.component.html',
  styleUrls: ['./new-order.component.scss'],
})
export class NewOrderComponent implements OnInit {
  title$: Observable<string>;
  constructor(
    private pageMetaService: PageMetaService,
    private translation: TranslationService,
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private semanticPathService: SemanticPathService
  ) {}
  ngOnInit(): void {
    this.title$ = this.pageMetaService.getMeta().pipe(
      filter(Boolean),
      map((meta: PageMeta) => meta.heading || meta.title)
    );
    if (this.activatedRoute.snapshot.data.editScheduledOrdersInfo) {
      this.title$ = this.title$.pipe(
        map((title) => {
          this.translation
            .translate('miniCartPage.editOrder')
            .pipe(take(1))
            .subscribe((text) => (title = text));
          return title;
        })
      );
    }
  }
  backToProfilePage(): void {
    this.route.navigate(
      this.semanticPathService.transform({
        cxRoute: 'patientProfile',
        params: { profileId: this.activatedRoute.snapshot.params.patientID },
      })
    );
  }
}
