import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService, GlobalMessageService, GlobalMessageType, TranslationService } from '@spartacus/core';
import { finalize, take, tap } from 'rxjs/operators';
import { AlconSiteService } from 'src/app/services/service/alcon-site-provider.service';
import { GlobalMessage } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-site-not-found',
  templateUrl: './site-not-found.component.html',
  styleUrls: ['./site-not-found.component.scss'],
})
export class SiteNotFoundComponent implements OnInit {
  siteData$ = this.alconSiteService.fetchSiteData().pipe(finalize(() => (this.loading = false)));
  loading = true;
  constructor(
    private auth: AuthService,
    private translation: TranslationService,
    private route: ActivatedRoute,
    private alconSiteService: AlconSiteService,
    private globalMessageService: GlobalMessageService
  ) {}

  ngOnInit(): void {
    this.displayAccountNotSetupNotification();
  }

  private displayAccountNotSetupNotification(): void {
    this.translation
      .translate('siteDirectory.errorMessage.accountNotSetup')
      .pipe(
        take(1),
        tap((msg) => {
          const logout = this.route.snapshot.queryParamMap.get('logout');
          if (logout === 'true') {
            this.auth.coreLogout();
            this.globalMessageService.add(msg, GlobalMessageType.MSG_TYPE_ERROR, GlobalMessage.TIMEOUT);
          }
        })
      )
      .subscribe();
  }
}
