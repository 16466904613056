import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbAccordionModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CmsConfig, ConfigModule, I18nModule, RoutingConfig, provideDefaultConfig } from '@spartacus/core';
import {
  CarouselModule,
  CmsPageGuard,
  IconModule,
  MediaModule,
  PRODUCT_DETAILS_URL_MATCHER,
  PageLayoutComponent,
  PageSlotModule,
} from '@spartacus/storefront';
import { ProductDescriptionPageComponent } from 'src/app/cms-pages/product-description-page/product-description-page.component';
import { AlconCommonModule } from '../common/alcon-common.module';
import { ProductBenefitComponent } from './product-benefit/product-benefit.component';
import { ProductDescriptionComponent } from './product-description/product-description.component';
import { ProductImageComponent } from './product-image/product-image.component';
@NgModule({
  declarations: [
    ProductBenefitComponent,
    ProductImageComponent,
    ProductDescriptionComponent,
    ProductDescriptionPageComponent,
  ],
  imports: [
    NgbAccordionModule,
    CommonModule,
    AlconCommonModule,
    CarouselModule,
    I18nModule,
    IconModule,
    MediaModule,
    RouterModule,
    FormsModule,
    PageSlotModule,
    NgbModule,
    RouterModule.forChild([
      {
        path: null,
        canActivate: [CmsPageGuard],
        component: PageLayoutComponent,
        data: { cxRoute: 'product' },
      },
    ]),
    ConfigModule.withConfig({
      cmsComponents: {
        ProductBenefitsFlexComponent: {
          component: ProductBenefitComponent,
        },
        ProductImagesFlexComponent: {
          component: ProductImageComponent,
        },
        ProductDescriptionFlexComponent: {
          component: ProductDescriptionComponent,
        },
      },
    } as CmsConfig),
  ],
  exports: [
    ProductBenefitComponent,
    ProductImageComponent,
    ProductDescriptionComponent,
    ProductDescriptionPageComponent,
  ],
  providers: [
    provideDefaultConfig({
      routing: {
        routes: {
          product: {
            matchers: [PRODUCT_DETAILS_URL_MATCHER],
          },
        },
      },
    } as RoutingConfig),
  ],
})
export class AlconProductDetailsPageModule {}
