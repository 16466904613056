import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '@spartacus/core';
import { IconModule, MediaModule, PageComponentModule } from '@spartacus/storefront';
import { AlconCommonModule } from '../common/alcon-common.module';
import { ReturnConfirmationStepsComponent } from './return-form-confirmation-steps/return-form-confirmation-steps.component';
import { ReturnFormConfirmationComponent } from './return-form-confirmation/return-form-confirmation.component';
import { ReturnFormCreatePageComponent } from './return-form-create-page/return-form-create-page.component';
import { ReturnFormProductConfiguratorComponent } from './return-form-product-configurator/return-form-product-configurator.component';
import { ReturnFormProductSelectorComponent } from './return-form-product-selector/return-form-product-selector.component';
import { ReturnFormVariantSelectorComponent } from './return-form-variant-selector/return-form-variant-selector.component';

@NgModule({
  declarations: [
    ReturnFormCreatePageComponent,
    ReturnFormProductSelectorComponent,
    ReturnFormProductConfiguratorComponent,
    ReturnFormVariantSelectorComponent,
    ReturnFormConfirmationComponent,
    ReturnConfirmationStepsComponent,
  ],
  imports: [
    CommonModule,
    MediaModule,
    AlconCommonModule,
    I18nModule,
    IconModule,
    PageComponentModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [ReturnFormCreatePageComponent, ReturnFormConfirmationComponent],
})
export class ReturnFormModule {}
