import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ProductDetailsService } from 'src/app/services/alcon-pdp/product-details.service';
import { AlconUserPermissionService } from 'src/app/shared-modules/alcon-user-permission-provider/service/alcon-user-permission.service';
import { DIRECT_TO_PRACTICE } from 'src/app/shared/constants/constants';
import { FavouriteIconSize, ModalOptions } from 'src/app/shared/model/common.mode';

@Component({
  selector: 'app-single-poduct-tile',
  templateUrl: './single-poduct-tile.component.html',
  styleUrls: ['./single-poduct-tile.component.scss'],
})
export class SinglePoductTileComponent implements OnInit, OnDestroy {
  // tslint:disable-next-line:no-input-rename
  @Input() product: any;
  @Input() isWishList = false;
  @Input() isStockOrder = false;
  @HostBinding('class') hostClass = 'd-flex';
  @Output() productSelected: EventEmitter<any> = new EventEmitter();
  iconSize = FavouriteIconSize.SMALL;
  productCode: string;
  openModal = false;
  destroySubscription$ = new Subject<void>();
  showLoader = false;
  productData;
  public modalOptions = {
    open: false,
    header: false,
    modalTitle: '',
    width: '1180px',
    borderRadius: '14px',
    backgroundColor: '#e5f5ff',
  } as ModalOptions;

  showPdpDetailsButton: boolean;
  constructor(
    private cd: ChangeDetectorRef,
    private productDetailsService: ProductDetailsService,
    private userPermissionService: AlconUserPermissionService
  ) {}

  ngOnInit(): void {
    this.showPdpDetailsButton = this.userPermissionService.getPDPButtonpermission();
  }

  openProductModal(ProductCode: string): void {
    if (this.isStockOrder) {
      this.getProduct(ProductCode);
    } else {
      this.productCode = ProductCode;
      this.openModal = true;
    }
  }

  emitCloseModal(): void {
    this.openModal = false;
  }

  getProduct(productCode): void {
    this.showLoader = true;
    this.productDetailsService
      .getProductInfo(productCode, DIRECT_TO_PRACTICE.STOCK_ORDER_PAGE)
      .pipe(take(1))
      .subscribe((data) => this.showProduct(data));
  }

  private showProduct(data): void {
    this.showLoader = false;
    this.modalOptions.open = true;
    this.productData = data;
    this.cd.detectChanges();
  }

  closeStockOrderModal(): void {
    this.productData = undefined;
    this.modalOptions.open = false;
  }

  ngOnDestroy(): void {
    this.destroySubscription$.next();
    this.destroySubscription$.complete();
  }
}
