import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { User } from '@spartacus/user/account/root';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AlconUserPermissionService } from 'src/app/shared-modules/alcon-user-permission-provider/service/alcon-user-permission.service';
import { DELIVERY_TYPE } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-delivery-type',
  templateUrl: './delivery-type.component.html',
  styleUrls: ['./delivery-type.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DeliveryTypeComponent implements OnInit {
  readonly deliveryTypes = DELIVERY_TYPE;
  @Input() deliveryType = DELIVERY_TYPE.REGULAR;
  @Input() disable = false;
  @Output() changeDeliveryType: EventEmitter<string> = new EventEmitter();
  showRegular$: Observable<boolean>;
  showDTP: boolean;
  constructor(
    private userProfileService: UserProfileFacade,
    private alconUserPermissionService: AlconUserPermissionService
  ) {}

  ngOnInit(): void {
    this.showRegular$ = this.showDeliveryType(DELIVERY_TYPE.REGULAR);
    this.showDTP = this.alconUserPermissionService.getDTPDeliveryPermission();
  }

  private showDeliveryType(deliveryType: string): Observable<boolean> {
    return this.userProfileService.get().pipe(
      filter((data) => !!data),
      map((userData: User) => {
        return userData.eligibleOrderFlowTypes.some((type) => type === deliveryType);
      })
    );
  }

  deliveryTypeChanged(): void {
    this.changeDeliveryType.emit(this.deliveryType);
  }
}
