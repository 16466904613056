import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header-account-selector',
  templateUrl: './header-account-selector.component.html',
  styleUrls: ['./header-account-selector.component.scss'],
})
export class HeaderAccountSelectorComponent {
  @Input() accountDetails: any;
  @Input() addressTitle?: string;
  @Input() isSoldto: boolean;
}
