<app-modal [modalOptions]="options" [showBigPopup]="false" (closeHandeller)="options.open = false">
  <ng-container>
    <div class="contactus-modal">
      <div class="contactus-modal__close">
        <cx-icon type="Close" class="close-icon cx-icon close" (click)="options.open = false"> </cx-icon>
      </div>
      <div class="row justify-content-center">
        <div class="col-10">
          <div class="d-block">
            <ng-container *ngTemplateOutlet="title"></ng-container>
          </div>
          <app-contact-us
            *ngIf="options?.open"
            class="contactus-modal__form"
            [configs]="configs"
            [formConfig]="formConfig"
            [thankYouModalOptions]="thankYouModalOptions"
            (closed)="options.open = false"
          >
          </app-contact-us>
        </div>
      </div>
    </div>

    <ng-template #title>
      <h2 class="contactus-modal__heading">
        <cx-page-slot position="ContactUsHeadingPosition"></cx-page-slot>
      </h2>
      <div class="contactus-modal__message-block">
        <cx-page-slot position="ContactUsMessagePosition"></cx-page-slot>
      </div>
      <label class="contactus-modal__mandatory-field">{{ 'contactusPage.mandatoryFields' | cxTranslate }}</label>
    </ng-template>
  </ng-container>
</app-modal>
