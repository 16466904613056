import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ProductSearchService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { ProductListService } from 'src/app/services/product-list-page/product-list.service';
import { CategoryCode, CategoryPage, FilterDefaultSelection } from 'src/app/shared/model/product.model';

@Injectable({
  providedIn: 'root',
})
export class IndividualOrderResolver implements Resolve<CategoryPage> {
  constructor(private productListService: ProductListService, private productSerachService: ProductSearchService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CategoryPage> {
    const {
      catid = route.data.cxRoute === 'IndividualOrder' ? CategoryCode.SERGICAL_BUSINESS : CategoryCode.CONTACT_LENSES,
    } = route.params;
    const { query = FilterDefaultSelection.ALL_CATEGORY + catid } = route.queryParams;
    this.productListService.searchProductsList(query, route.data.Type);
    return this.productListService.getGroupedProductsList().pipe(
      take(1),
      tap(() => {
        this.productSerachService.clearResults();
      })
    );
  }
}
