import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { NgbAccordionDirective } from '@ng-bootstrap/ng-bootstrap';
import { CmsService, ContentSlotComponentData } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable, Subject, forkJoin } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
declare module '@spartacus/core' {
  interface ContentSlotComponentData {
    flexType?: string;
  }
}

@Component({
  selector: 'app-faq-topic',
  templateUrl: './faq-topic.component.html',
  styleUrls: ['./faq-topic.component.scss'],
})
export class FaqTopicComponent implements AfterViewInit {
  private destroy$ = new Subject<void>();
  topic: string;
  pageId = 'faq' + new Date().getMilliseconds();
  @ViewChild('accordian') accordian: NgbAccordionDirective;
  items$: Observable<ContentSlotComponentData[]> = this.componentData.data$.pipe(
    filter((data) => {
      this.topic = data.topicName;
      return !!data.QuestionAnswers;
    }),
    map((data) => data?.QuestionAnswers.trim().split(' ')),
    switchMap((codes) => forkJoin([...codes.map((code) => this.cmsservice.getComponentData(code).pipe(take(1)))])),
    map((data) =>
      data.map((result: ContentSlotComponentData) => ({
        ...result,
        flexType: result.typeCode,
      }))
    )
  );
  constructor(private componentData: CmsComponentData<any>, private cmsservice: CmsService) {}
  ngAfterViewInit(): void {
    this.accordian.collapseAll();
  }
}
