<div class="cartData">
  <h4 class="cartData__itemName">{{ item?.patientName }}</h4>
  <app-minicart-items
    *ngFor="let cartEntry of item?.entries && item.entries.slice(0, 2)"
    [item]="cartEntry"
  ></app-minicart-items>
  <div *ngIf="item?.entries?.length > 2">
    <a href="javascript:void(0)" (click)="isCartDisplay = false" routerLink="/cart">
      <label class="cartData--itemclass"
        >({{ item?.entries?.length - 2 }} {{ 'miniCartPage.moreitem' | cxTranslate }} )</label
      >
    </a>
  </div>
</div>
