import { _, loc } from 'okta';
import Enums from 'util/Enums';
import FormController from 'util/FormController';
import FormType from 'util/FormType';
import LoginModel from 'models/LoginModel';
import Util from 'util/Util';
import axios from 'axios';

export default FormController.extend({
  className: 'sap-approval-email-sent',
  Model: {
    save: function(context) { //try save within model, but with context param
      const self = context;

      const chosen_contact_idx = self.model.get('approver-email');
      //const approver_email = self.options.appState.get('contacts')[chosen_contact_idx];
      const approver_email_masked = self.options.appState.get('masked_contacts')[chosen_contact_idx];

      //call mulesoft api to send approval email to approver_email
      //result = await this.sap_api_client.post('/send-email....', {approver_email});

      self.options.appState.set('approver_email', approver_email_masked);
      self.options.appState.trigger('navigate', 'signin/sap-approval-email-sent');
    }
  },

  Form: {
    title: _.partial(loc, 'sap.approval.select.approver.title', 'login'),
    noButtonBar: true,
    attributes: { 'data-se': 'sap-send-approval-email' },
    formChildren: function() {
      const self = this;

      //In future the contact type can be an input from the user
      //self.model.getContacts('email', self);

      return [
        FormType.Input({
          label: loc('sap.approval.select.approver.placeholder', 'login'),
          'label-top': true,
          explain: Util.createInputExplain(
            'sap.approval.select.approver.tooltip',
            'sap.approval.select.approver.placeholder',
            'login'
          ),
          'explain-top': true,
          name: 'approver-email',
          type: 'select',
          wide: true,
          options: self.options.appState.get('masked_contacts')
        }),

        FormType.Button({
          title: loc('sap.approval.send', 'login'),
          className: 'button button-primary button-wide',
          attributes: { 'data-se': 'send-button' },
          click: function() {
            self.trigger('save');
          }
        })
      ];
    },

    initialize: function() {
      /**
       * if no username/sapId/contacts/maskedContacts present in app state, then this page was rendered manually,
       * not by registration flow, so redirect back to login page.
       * 
       * In the future, we could get rid of this and allow this page
       * to be reached independently of registration if we want/need
       * to enable some kind of two-part registration system
       * 
       **/
      if (!this.options.appState.get('username') || !this.options.appState.get('sapId') || 
        this.options.appState.get('contacts').length == 0 || this.options.appState.get('masked_contacts').length == 0){
        
        this.settings.callGlobalError(Enums.SAP_APPROVAL_STATE_INVALID);
        this.state.set('navigateDir', Enums.DIRECTION_BACK);
        this.options.appState.trigger('navigate', '');
      }

      // this.model.getContacts('email', this).then(function(contacts){
      //   this.model.set('contacts', contacts);
      // });


      this.listenTo(this, 'save', function() {
        this.model.save(this);
      });
    }
  }
});