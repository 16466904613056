import { ChangeDetectorRef, Component, ElementRef, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AlconUserPermissionService } from 'src/app/shared-modules/alcon-user-permission-provider/service/alcon-user-permission.service';
import { CART_GROUP, ORDER_LABELS } from 'src/app/shared/constants/constants';
import { LoadItems } from 'src/app/store/Actions/cartItem.action';
import { getCartRootGroups, getTotalPriceVaules, getTotalUnitCount } from 'src/app/store/Selectors/cartItem.selector';
import { VisionCareEntries } from 'src/app/store/States/cartItem.state';

@Component({
  selector: 'app-alconminicart-v2',
  templateUrl: './alconminicart-v2.component.html',
  styleUrls: ['./alconminicart-v2.component.scss'],
})
export class AlconminicartV2Component implements OnInit, OnDestroy {
  isPriceVisible: boolean;
  orderLabels = ORDER_LABELS;
  entries = [];
  cartvalue = false;
  isCartDisplay = false;
  totalItems: Observable<number> = this.store.select(getTotalUnitCount);
  regularEntries = [];
  valuePackEntries = [];
  stockEntries = [];
  dtpEntries = [];
  dtpLineItemCount: number;
  showTotalPrice$ = new BehaviorSubject(true);
  showPrices: boolean;
  totalMiniCartPrice$: Observable<string> = this.store.pipe(
    select(getTotalPriceVaules),
    map((value) => value.subTotal.formattedValue)
  );
  private miniCartStoreSubscription = new Subject<void>();
  @HostBinding('class') hostClass = 'position-relative';
  @HostListener('document:click', ['$event'])
  hideDropdown(event): void {
    if (!this.eleRef.nativeElement.contains(event.target)) {
      this.isCartDisplay = false;
    }
  }
  constructor(
    private store: Store,
    private cd: ChangeDetectorRef,
    private eleRef: ElementRef,
    private userPermissionService: AlconUserPermissionService
  ) {}

  ngOnInit(): void {
    this.showPrices = this.userPermissionService.getPricesVisibilityPermission();
    this.isPriceVisible = this.userPermissionService.getPriceStatementPermission();
    this.store
      .pipe(select(getCartRootGroups), takeUntil(this.miniCartStoreSubscription))
      .subscribe((rootGroups: Array<VisionCareEntries>) => {
        if (rootGroups.length > 0) {
          this.cartvalue = true;
          this.entries = JSON.parse(JSON.stringify(rootGroups));
          this.createCartData(this.entries);
          this.cd.detectChanges();
        } else {
          this.cartvalue = false;
          this.cd.detectChanges();
        }
      });
    this.store.dispatch(LoadItems());
  }

  showCart(event: any): void {
    event.stopPropagation();
    this.isCartDisplay = !this.isCartDisplay;
  }

  /**
   * From rootgroups fetches individual entries [regularEntries,
   * valuePackEntries,stockEntries,dtpEntries]
   */
  createCartData(list: Array<VisionCareEntries>): void {
    const practiceOrderEntries = list.find((items) => items.label === CART_GROUP.PRACTICE_ORDER);

    const individualOrder = practiceOrderEntries?.children?.find(
      (order) => order.label === CART_GROUP.INDIVIDUAL_ORDER
    );

    if (individualOrder && !!individualOrder.entries) {
      const { entries: individualOdrerEntries = [] } = individualOrder;
      this.regularEntries = individualOdrerEntries;
      this.setPrice(individualOdrerEntries);
    } else {
      this.regularEntries = [];
    }
    if (individualOrder && !!individualOrder.children) {
      const [{ entries: ValuePacks = [] }] = individualOrder.children;
      this.valuePackEntries = ValuePacks;
      this.setPrice(ValuePacks);
    } else {
      this.valuePackEntries = [];
    }

    const stockOrder = practiceOrderEntries?.children?.find((order) => order.label === CART_GROUP.STOCK_ORDER);

    if (stockOrder && !!stockOrder.entries) {
      const { entries: stockOrderEntries = [] } = stockOrder;
      this.stockEntries = stockOrderEntries;
      this.setPrice(stockOrderEntries);
    } else {
      this.stockEntries = [];
    }
    this.dtpLineItemCount = 0;
    const dtp = this.entries.find((items) => items.label === CART_GROUP.DIRECT_TO_PATIENT);
    if (dtp) {
      const { entries: dtpEntries = [] } = dtp;
      this.dtpEntries = dtpEntries;
      const tempOrder = [];
      dtpEntries?.forEach((element) => {
        element.entries.forEach((entry) => {
          tempOrder.push(entry);
        });
      });
      this.setPrice(tempOrder);
      dtpEntries.forEach((eachEntry) => {
        this.dtpLineItemCount += eachEntry.entries?.length;
      });
    } else {
      this.dtpEntries = [];
    }
  }

  setPrice(orders): boolean {
    if (orders?.length > 0) {
      let counter = 0;
      orders?.map((element) => {
        if (element?.totalPrice?.value === 0 && !element?.product?.packSize?.toLowerCase().includes('trial')) {
          counter++;
          this.showTotalPrice$.next(false);
        }
      });
      return counter > 0 ? false : true;
    }
  }

  ngOnDestroy(): void {
    this.miniCartStoreSubscription.next();
    this.miniCartStoreSubscription.complete();
  }
}
