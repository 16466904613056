<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-10 col-xl-10">
      <h1 class="mb-0 pb-4">
        <cx-icon type="BackButton" (click)="backToProfilePage()"></cx-icon>
        {{ title$ | async }}
      </h1>
    </div>
    <div class="col-10 col-xl-10">
      <cx-page-slot position="AddNewOrderHeaderDescriptionPosition"></cx-page-slot>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-10 col-xl-10">
      <cx-page-slot position="AddNewOrderSelectProductPosition"></cx-page-slot>
    </div>
  </div>
</div>
