import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CmsService, PageMeta, PageMetaService } from '@spartacus/core';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { PatientService } from 'src/app/services/patient/patient.service';
import { PatientListService } from 'src/app/services/patients-list/patient-list.service';
import { getPatientCount } from 'src/app/store/Selectors/patient.selector';

@Component({
  selector: 'app-my-patient',
  templateUrl: './my-patient.component.html',
  styleUrls: ['./my-patient.component.scss'],
})
export class MyPatientComponent implements OnInit, OnDestroy {
  noPatientsYet = false;
  title$: Observable<string>;
  patientMessage: Observable<{}>;
  patientSuccessMessage = '';
  productOpen: boolean;
  patientsCount: number;

  private destroy$ = new Subject<void>();
  constructor(
    private cmsService: CmsService,
    private pageMetaService: PageMetaService,
    private patientListService: PatientListService,
    private patientservice: PatientService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.title$ = this.pageMetaService.getMeta().pipe(
      filter(Boolean),
      map((meta: PageMeta) => meta.heading || meta.title)
    );

    this.patientMessage = this.cmsService.getComponentData('MyPatientsMessageComponent');
    this.store
      .select(getPatientCount)
      .pipe(takeUntil(this.destroy$))
      .subscribe((patientsCount) => {
        this.patientsCount = patientsCount;
      });
    this.patientservice.getPatientStoreData(true).pipe(takeUntil(this.destroy$)).subscribe();
  }

  searchPatient(event): void {
    let searchValue = event.target.value.trim();
    searchValue = searchValue.length > 2 ? searchValue : '';
    this.patientListService.filterPatientList(searchValue.toLowerCase());
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
