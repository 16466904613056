import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { CalendarConfiguration, SelectionMode } from 'src/app/shared/model/calendar.model';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
  event: MouseEvent;
  today = new Date();
  positions = {
    left: '',
    right: '',
    top: '',
  };
  defaultConfiguration = {
    header: {
      year: this.today.getFullYear(),
      dayAndDate: this.today.toLocaleDateString('en-us', { weekday: 'short', month: 'short', day: 'numeric' }),
    },
    defaultDate: this.today,
    footer: {
      option1: 'Cancel',
      option2: 'Ok',
    },
    styles: {
      left: null,
      right: null,
      top: null,
    },
    minDate: null,
    maxDate: null,
    daysName: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    showOtherMonths: false,
    selectionMode: SelectionMode.SINGLE,
  } as CalendarConfiguration;
  calendarConfig = this.defaultConfiguration;
  configuration;
  @Output() dateSelected: EventEmitter<Date[]> = new EventEmitter();
  @Output() outsideClicked: EventEmitter<Date> = new EventEmitter();
  rangeDates;
  @HostListener('document:click', ['$event', '$event.target'])
  clickout(event: MouseEvent, targetElement: HTMLElement): void {
    this.event = event;
    if (!targetElement) {
      return;
    }
    const clickedInside = this.eRef.nativeElement.contains(targetElement);
    if (!clickedInside && !targetElement.className.includes('datepicker')) {
      this.outsideClicked.emit();
    }
  }

  constructor(private config: PrimeNGConfig, private eRef: ElementRef) {}

  ngOnInit(): void {
    this.config.setTranslation({ dayNamesMin: this.defaultConfiguration.daysName });
    this.calendarConfig = {
      ...this.defaultConfiguration,
      ...this.calendarConfig,
    };
    this.positions.right = this.calendarConfig.styles.right;
    this.positions.top = this.calendarConfig.styles.top;
    this.positions.left = this.calendarConfig.styles.left;
    this.rangeDates = this.calendarConfig.rangeDates ? this.calendarConfig.rangeDates : this.calendarConfig.defaultDate;
  }

  option1Clicked(): void {
    this.dateSelected.emit();
  }

  option2Clicked(): void {
    this.dateSelected.emit(this.rangeDates);
  }
}
