import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { OrderHistoryService } from 'src/app/services/order-history/order-history.service';
import { ModalOptions } from 'src/app/shared/model/common.mode';
import { ReOrderResponse } from 'src/app/shared/model/orderHistory.model';
import { LoadItems } from 'src/app/store/Actions/cartItem.action';

@Component({
  selector: 'app-re-order',
  templateUrl: './re-order.component.html',
  styleUrls: ['./re-order.component.scss'],
})
export class ReOrderComponent {
  constructor(private orderHistoryservice: OrderHistoryService, private store: Store, private cd: ChangeDetectorRef) {}
  @Input() disableReOrder = false;
  @Input() orderID: string;
  @Input() buttonType?: string;
  @Input() productDetails?: any;
  // @Output() reOrderResponse: EventEmitter<ReOrderResponse | string> = new EventEmitter();
  @Output() reOrderResponse: EventEmitter<boolean> = new EventEmitter();
  showLoader = false;
  reOrderResponseObj: ReOrderResponse;
  public modalOptions = {
    open: false,
    header: false,
    modalTitle: '',
    borderRadius: '20px',
  } as ModalOptions;
  reOrder(event): void {
    event.stopPropagation();
    this.showLoader = true;
    this.orderHistoryservice
      .reOrder(this.orderID, this.buttonType, this.filterEntriesForPatientReorder())
      .pipe(take(1))
      .subscribe(
        (response) => {
          this.showLoader = false;
          this.store.dispatch(LoadItems());
          if (response.validationErrors) {
            this.reOrderResponseObj = response;
            this.openModal();
            this.cd.detectChanges();
          } else {
            this.reOrderResponse.emit(false);
          }
        },
        (error) => {
          this.showLoader = false;
          this.reOrderResponse.emit(true);
        }
      );
  }
  openModal(): void {
    this.modalOptions.open = true;
    document.body.classList.add('modal-open');
  }
  closeModal(): void {
    this.modalOptions.open = false;
    document.body.classList.remove('modal-open');
  }

  filterEntriesForPatientReorder(): string {
    let entryString = '';
    if (this.buttonType === 'patientReorder') {
      const entryStringArray = [];
      this.productDetails.forEach((element) => {
        entryStringArray.push(element.entryNumber);
      });
      entryString = entryStringArray.toString();
    }
    return entryString;
  }
}
