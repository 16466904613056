import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Product, TranslationService } from '@spartacus/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ProductDetailsService } from 'src/app/services/alcon-pdp/product-details.service';
import { ProductErrorsService } from 'src/app/services/product-errors.service';
import { AlconUserPermissionService } from 'src/app/shared-modules/alcon-user-permission-provider/service/alcon-user-permission.service';
import { OrderType, VisionCareAddtocartProductRequest } from 'src/app/store/States/cartItem.state';

@Component({
  selector: 'app-alcon-product-add-to-cart',
  templateUrl: './alcon-product-add-to-cart.component.html',
  styleUrls: ['./alcon-product-add-to-cart.component.scss'],
})
export class AlconProductAddToCartComponent implements OnInit, AfterViewInit, OnDestroy {
  isPriceVisible: boolean;
  isAddToCartEnabled = true;
  errormessage = '';
  error = false;
  private destroy$ = new Subject<void>();
  patientOrders: Array<VisionCareAddtocartProductRequest> = [];
  orderType = OrderType.Individual;
  addToCartTextLoading = false;
  addToCartText: string;
  addToCartButtonText: string;
  addingtoCartSuccessText: string;
  addingtocartTick = false;
  productCLCdryeyeType = false;
  product: Observable<Product> = this.pdpService.getProduct('alconDetails').pipe(takeUntil(this.destroy$));
  price$: Observable<string>;
  productCode: string;
  showPrices: boolean;

  constructor(
    private pdpService: ProductDetailsService,
    private translation: TranslationService,
    private productErrorService: ProductErrorsService,
    private cd: ChangeDetectorRef,
    private userPermissionService: AlconUserPermissionService
  ) {
    this.getTranslatedValues();
  }

  ngOnInit(): void {
    this.showPrices = this.userPermissionService.getPricesVisibilityPermission();
    this.isPriceVisible = this.userPermissionService.getPriceStatementPermission();
    this.product.pipe(takeUntil(this.destroy$)).subscribe((product) => {
      this.productCode = product.code;
    });
    if (this.isPriceVisible) {
      this.price$ = this.pdpService.packSizePriceChanged$$.pipe(
        map((array) => array.find((prd) => prd.code === this.productCode))
      );
    }
  }
  ngAfterViewInit(): void {
    this.pdpService.checkAddToCartButton.pipe(takeUntil(this.destroy$)).subscribe((products) => {
      if (products) {
        this.patientOrders = products?.map((product: VisionCareAddtocartProductRequest) => {
          return {
            ...product,
          } as VisionCareAddtocartProductRequest;
        });
        this.patientOrders = [...this.patientOrders];
      } else {
        this.patientOrders = [];
      }
    });
  }

  getTranslatedValues(): void {
    combineLatest([
      this.translation.translate('selectProductPage.patientOrderPanel.addToCart'),
      this.translation.translate('selectProductPage.patientOrderPanel.addToCartSuccess'),
    ])
      .pipe(
        map(([addToCartText, addToCartSuccessText]) => {
          return {
            addToCartText,
            addToCartSuccessText,
          };
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        this.addToCartText = data.addToCartText;
        this.addToCartButtonText = this.addToCartText;
        this.addingtoCartSuccessText = data.addToCartSuccessText;
      });
  }
  addingtoCartSuccess(tickValue): void {
    this.addToCartTextLoading = false;
    this.addToCartButtonText = this.addingtoCartSuccessText;
    this.addingtocartTick = tickValue;
    setTimeout(() => {
      this.addToCartButtonText = this.addToCartText;
      this.addingtocartTick = false;
      this.pdpService.refreshPanelSubject.next(true);
      this.cd.detectChanges();
    }, 3000);
  }

  addingtocartLoad(loading): void {
    this.addToCartTextLoading = loading;
  }

  emitErrorMessage(errorMsg): void {
    this.pdpService.errorSubject.next('');
    this.cd.markForCheck();
    this.pdpService.refreshPanelSubject.next(false);
    errorMsg?.error?.errors?.forEach((err) => {
      this.productErrorService.addProductError(err);
    });
    this.pdpService.errorSubject.next(errorMsg);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.pdpService.removePrice(this.productCode);
  }
}
