import { Component, Input, OnInit } from '@angular/core';
import { ORDER_LABELS } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-minicart-order-type-list',
  templateUrl: './minicart-order-type-list.component.html',
  styleUrls: ['./minicart-order-type-list.component.scss'],
})
export class MinicartOrderTypeListComponent implements OnInit {
  @Input() cartData;
  @Input() orderType: string;
  @Input() orderCount: number;
  displayCount: number;
  get orderLabel(): typeof ORDER_LABELS {
    return ORDER_LABELS;
  }
  constructor() {}

  ngOnInit(): void {
    this.displayCount = this.orderType === this.orderLabel.VALUE_PACK ? 1 : this.cartData.length;
  }
}
