<div class="d-flex flex-column flex-grow-1">
  <div class="select-product__lensdrpdn">
    <app-dropdown-selector-v2
      [disableDropDown]="$disable | async"
      [options]="firstproductsList | sortPipe: 'asc'"
      [config]="lensesLabel"
      (dropDownSelected)="firstProductDrpdwnSelected($event)"
    >
    </app-dropdown-selector-v2>
  </div>
  <div *ngIf="firstProduct" class="select-product__productsSelector">
    <app-basic-product-selector
      [dtpError]="dtpError"
      [disable]="$disable | async"
      (changeDtpError)="onDtpErrorChange($event)"
      [selectedProduct]="firstProduct?.value"
      (selectedProductLensValue)="selectedProductLensValue($event, 0)"
      (checkboxStatus)="checkboxStatusChange($event)"
      [disableCheckbox]="disableCheckbox"
      [isDTP]="isDTP"
      [panelConfiguration]="refreshProductPanel ? resetConfig : getBasicPanelConfiguration()"
      (Errors)="setPanelErrors($event, firstProduct?.value)"
      [refreshProductPanel]="refreshProductPanel"
    >
    </app-basic-product-selector>
  </div>
  <span
    *ngIf="firstProduct"
    class="select-product__button"
    [disablePopover]="!disableAddLens"
    popoverClass="popover--lg"
    placement="bottom"
    [ngbPopover]="otherLensPopup"
    triggers="mouseenter:mouseleave:touched"
  >
    <button
      (click)="addotherLens()"
      class="btn addOtherLens__button_background"
      [ngClass]="{ 'd-none': addLens }"
      [disabled]="disableAddLens"
    >
      {{ 'selectProductPage.addOtherLenses' | cxTranslate }}
    </button>
  </span>
  <ng-template #otherLensPopup>
    {{ 'selectProductPage.tooltipText' | cxTranslate }}
  </ng-template>

  <div *ngIf="addLens" class="d-flex">
    <div class="select-product__lensdrpdn" style="width: 50%">
      <app-dropdown-selector-v2
        [options]="secondProductsList | sortPipe: 'asc'"
        [config]="lensesLabel"
        (dropDownSelected)="secondProductDrpdwnSelected($event)"
      >
      </app-dropdown-selector-v2>
    </div>
    <div (click)="deleteAddedItem()" class="deleteSecondProduct__deleteItem--icon-remove">
      <cx-icon class="Remove" type="Remove"> </cx-icon
      ><a class="deleteSecondProduct__deleteItem--icon-remove--deleteLink" href="javascript:void(0)">{{
        'miniCartPage.delete' | cxTranslate
      }}</a>
    </div>
  </div>
  <div *ngIf="secondProduct" class="select-product__productsSelector">
    <app-basic-product-selector
      [disable]="$disable | async"
      [selectedProduct]="secondProduct?.value"
      (selectedProductLensValue)="selectedProductLensValue($event, 1)"
      (checkboxStatus)="checkboxStatusChange($event)"
      [panelConfiguration]="panelConfiguration"
      [disableCheckbox]="true"
      [isDTP]="isDTP"
      (Errors)="setPanelErrors($event, secondProduct?.value)"
      [refreshProductPanel]="refreshProductPanel"
    >
    </app-basic-product-selector>
  </div>
</div>
<app-loader-icon *ngIf="showLoader"></app-loader-icon>
