<div class="returnform-variant d-flex" *ngIf="contactLensType">
  <div class="d-flex flex-column justify-content-around">
    <label class="returnform-variant__label">{{ 'returnForm.baseCurve' | cxTranslate }}</label>
    <label class="returnform-variant__label">{{ 'returnForm.diameter' | cxTranslate }}</label>
    <label class="returnform-variant__label">{{ 'returnForm.power' | cxTranslate }}</label>
    <label *ngIf="contactLensType === color" class="returnform-variant__label">{{
      'returnForm.colour' | cxTranslate
    }}</label>
    <label *ngIf="contactLensType === multifocal" class="returnform-variant__label">{{
      'returnForm.add' | cxTranslate
    }}</label>
    <label *ngIf="contactLensType === toric" class="returnform-variant__label">{{
      'returnForm.cylinder' | cxTranslate
    }}</label>
    <label *ngIf="contactLensType === toric" class="returnform-variant__label">{{
      'returnForm.axis' | cxTranslate
    }}</label>
  </div>
  <div class="returnform-variant__width">
    <div class="mb-20">
      <app-dropdown-selector-v2 [options]="BaseCurveSelector" (dropDownSelected)="baseCurveSelected($event)">
      </app-dropdown-selector-v2>
    </div>
    <div class="mb-20">
      <app-dropdown-selector-v2 [options]="DiameterSelector" (dropDownSelected)="diameterSelected($event)">
      </app-dropdown-selector-v2>
    </div>
    <div class="mb-20">
      <app-dropdown-selector-v2 [options]="LensPowerSelector" (dropDownSelected)="lensPowerSelected($event)">
      </app-dropdown-selector-v2>
    </div>
    <div *ngIf="contactLensType === 'COLOR'" class="mb-20">
      <app-dropdown-selector-v2 [options]="ColorSelector" (dropDownSelected)="colorSelected($event)">
      </app-dropdown-selector-v2>
    </div>
    <div *ngIf="contactLensType === 'MULTIFOCAL'" class="mb-20">
      <app-dropdown-selector-v2 [options]="AddSelector" (dropDownSelected)="addSelected($event)">
      </app-dropdown-selector-v2>
    </div>
    <div *ngIf="contactLensType === 'TORIC'" class="mb-20">
      <app-dropdown-selector-v2 [options]="CylinderSelector" (dropDownSelected)="cylinderSelected($event)">
      </app-dropdown-selector-v2>
    </div>
    <div *ngIf="contactLensType === 'TORIC'" class="mb-20">
      <app-dropdown-selector-v2 [options]="AxisSelector" (dropDownSelected)="axisSelected($event)">
      </app-dropdown-selector-v2>
    </div>
  </div>
</div>
<div class="d-flex align-items-baseline returnform-order">
  <label class="returnform-variant__label">{{ 'returnForm.ordernumber' | cxTranslate }}</label>
  <div *ngIf="OrderNumbers" class="orderNumber">
    <app-dropdown-selector-v2
      [options]="OrderNumbers"
      [config]="orderdropdownConfig"
      (dropDownSelected)="orderNumberSelected($event)"
    >
    </app-dropdown-selector-v2>
  </div>
</div>
