import { createSelector } from '@ngrx/store';
import { ValuePackVarientsState } from '../States/valuePack.state';

export const getValuePackItems = createSelector(
  (state: ValuePackVarientsState) => state.valuePackVarients,
  (valuePackVarients: any) => valuePackVarients
);

export const getSelectedValuePack = (packid: string) =>
  createSelector(
    (state: ValuePackVarientsState) => state.valuePackVarients,
    (valuePackVarients) => {
      if (Object.keys(valuePackVarients) && Object.keys(valuePackVarients).length) {
        return valuePackVarients?.childNodes?.find((valuepack) => valuepack.nodeValue === packid);
      }
    }
  );
