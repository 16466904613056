import { TranslationChunksConfig } from '@spartacus/core';

export const alconTranslationChunksConfig: TranslationChunksConfig = {
  common: ['navigation', 'formErrors', 'errorHandlers', 'skipLink', 'pageMetaResolver'],
  cms: [
    'dashboard',
    'login',
    'productListingPage',
    'multiAccountSelector',
    'productPage',
    'productDetailsPage',
    'favoriteListPage',
    'legalAnnouncement',
    'practicePage',
    'stockOrderPage',
    'practiceOrderPage',
    'stockToricPage',
    'miniCartPage',
    'cartPage',
    'checkoutPage',
    'myPatientsPage',
    'patientProfilePage',
    'baseLoginPage',
    'orderHistoryPage',
    'invoicesListPage',
    'orderStatus',
    'contactusPage',
    'selectProductPage',
    'siteDirectory',
    'safeOrderingPage',
    'configureProducts',
    'stockOrderProductListPage',
    'orderDetailsPage',
    'selectDeliveryAddressPage',
    'orderItemPage',
    'patientAddressPage',
    'recentOrders',
    'registerPage',
    'confirmEmail',
    'myProfilePage',
    'changePassword',
    'orderConfirmation',
    'patientOrderHistory',
    'cartPagev2',
    'commonTitles',
    'contactInformation',
    'subscriptions',
    'visioncareSearch',
    'accountDetailsPage',
    'returnForm',
    'statements',
    'reOrderValidation',
    'autoOrderingTranslations',
    'patientListFilters',
    'faqSearch',
    'sxRegistration',
    'franchisePage',
    'loginPage',
    'toggleValues',
    'userManagementPage',
    'ManageuserPage',
    'errorHandler',
  ],
};
