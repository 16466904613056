import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationStart, Router } from '@angular/router';
import { CmsService, PageMeta, PageMetaService } from '@spartacus/core';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-return-form-template',
  templateUrl: './return-form-template.component.html',
  styleUrls: ['./return-form-template.component.scss'],
})
export class ReturnFormTemplateComponent implements OnInit, OnDestroy {
  title$: Observable<string>;
  constructor(
    private location: Location,
    private pageMetaService: PageMetaService,
    private cmsService: CmsService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}
  returnFormImgContent;
  private destroy$ = new Subject<void>();
  showBackButton = true;

  ngOnInit(): void {
    this.title$ = this.pageMetaService.getMeta().pipe(
      filter(Boolean),
      map((meta: PageMeta) => meta.heading || meta.title)
    );
    this.cmsService
      .getComponentData('ReturnformPageImageComponent')
      .pipe(takeUntil(this.destroy$))
      .subscribe((imageContent) => {
        this.returnFormImgContent = imageContent;
      });
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof ActivationStart) {
        this.showBackButton = event.snapshot.data.cxRoute === 'returnFormConfirmationPage' ? false : true;
      }
    });
  }

  backHistoryUrl(): void {
    this.location.back();
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
