import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToggleConfiguration } from 'src/app/store/States/orderHistory';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
})
export class ToggleButtonComponent implements OnInit {
  @Input() patientData;
  @Input() disabled = false;
  @Input() toggleConfig: ToggleConfiguration;
  @Output() toggled: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onToggled(event): void {
    this.toggled.emit(event);
  }
}
