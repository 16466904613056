<div class="counter__watp">
  <cx-icon
    *ngIf="countervalue <= 1 && !disabled"
    [style.pointerEvents]="countervalue <= 1 && !disabled ? 'none' : 'auto'"
    type="MinusEndIcon"
    (click)="decrement()"
  >
  </cx-icon>
  <cx-icon *ngIf="countervalue > 1 && !disabled" type="MinusIcon" (click)="decrement()"> </cx-icon>
  <cx-icon *ngIf="disabled" type="DisabledMinusCounter"> </cx-icon>
  <input
    class="counter__value"
    type="number"
    [(ngModel)]="countervalue"
    name="countervalue"
    (keydown)="enterCounterVaue($event)"
    (keyup)="emitValue()"
    [disabled]="disabled || qtyFactor > 1 || productType"
    [ngClass]="disabled ? 'counter__disable-value' : ''"
  />
  <cx-icon *ngIf="!disabled" type="PlusIcon" (click)="increment()"> </cx-icon>
  <cx-icon *ngIf="disabled" type="DisabledPlusCounter"> </cx-icon>
</div>
