import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { CurrentUserIdService } from 'src/app/shared-modules/user-id-provider/service/current-user-id.service';
import { RetrievedValuePackVarients } from 'src/app/store/Actions/valuePack.action';
import { getSelectedValuePack } from 'src/app/store/Selectors/valuePack.selector';

@Injectable({
  providedIn: 'root',
})
export class ValuePackService {
  valuePackPriceChanged = new EventEmitter();
  constructor(
    private httpClient: HttpClient,
    private occEndPoints: OccEndpointsService,
    private store: Store,
    private currentUID: CurrentUserIdService
  ) {}

  getValuePackVarients(): Observable<any> {
    const url = this.occEndPoints.buildUrl(`products/valuePacks`);
    return this.httpClient
      .get<any>(url)
      .pipe(tap((valuepack) => this.store.dispatch(RetrievedValuePackVarients({ valuePackVarients: valuepack }))));
  }
  getValuePackSelectedVarient(packtype): Observable<any> {
    return this.store.select(getSelectedValuePack(packtype)).pipe(
      tap((valuePack: any) => {
        if (!valuePack) {
          this.getValuePackVarients().subscribe();
        }
      }),
      filter((valuepack) => !!valuepack),
      take(1)
    );
  }

  getPriceForValuePack(bomid: number): void {
    const userId = this.currentUID.getUserId();
    const url = this.occEndPoints.buildUrl(`users/${userId}/products/price/valuepack/${bomid}`);
    this.httpClient
      .get(url)
      .pipe(take(1))
      .subscribe((responseData) => {
        const data = Object.assign(responseData);
        this.changeInValuePackPrice(data?.formattedValue);
      });
  }

  changeInValuePackPrice(price): void {
    price = price === '0' ? '£00.00' : price;
    this.valuePackPriceChanged.emit(price);
  }
}
