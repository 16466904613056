import { Component } from '@angular/core';
import { CART_GROUP } from 'src/app/shared/constants/constants';
import { DtpPraticeExist, OrderTypeSummary } from 'src/app/store/States/cartItem.state';
@Component({
  template: '',
})
export abstract class LineItemCountComponent {
  value: string;
  public static PracticeOrderCount(rootGroups, lineItemType): OrderTypeSummary {
    const practiceOrderEntries = rootGroups.find((items) => items.label === CART_GROUP.PRACTICE_ORDER);
    const {
      count: orderCount = 0,
      totalPrice: totalPriceinfo = {},
      entries: orders = [],
    } = practiceOrderEntries?.children?.find((order) => order.label === lineItemType)
      ? practiceOrderEntries?.children?.find((order) => order.label === lineItemType)
      : [{ count: 0, totalPrice: {}, entries: [] }];
    return {
      orderCount,
      totalPriceinfo,
      orders,
    } as OrderTypeSummary;
  }
  public static valuePackCount(rootGroups): OrderTypeSummary {
    const practiceOrderEntries = rootGroups.find((items) => items.label === CART_GROUP.PRACTICE_ORDER);
    const indvidualOrder = practiceOrderEntries?.children?.find((child) => child.label === CART_GROUP.INDIVIDUAL_ORDER);

    if (indvidualOrder) {
      indvidualOrder.children =
        indvidualOrder?.children?.length > 0 ? indvidualOrder.children : [{ count: 0, totalPrice: {}, entries: [] }];
      const [
        { count: orderCount = 0, totalPrice: totalPriceinfo = {}, entries: orders = [] },
      ] = indvidualOrder.children;
      const orderSummary = {
        orderCount,
        totalPriceinfo,
        orders,
      } as OrderTypeSummary;
      return orderSummary;
    } else {
      return { orderCount: 0, totalPriceinfo: {}, orders: [] } as OrderTypeSummary;
    }
  }

  public static DTPCount(rootGroups): OrderTypeSummary {
    const dtpEntries = rootGroups.find((items) => items.label === CART_GROUP.DIRECT_TO_PATIENT);
    let productsCount = 0;
    dtpEntries
      ? dtpEntries.entries.forEach((eachEntry) => {
          productsCount += eachEntry.entries?.length;
        })
      : (productsCount = 0);
    const orderSummary = {
      patientCount: dtpEntries?.count ? dtpEntries?.count : 0,
      orderCount: productsCount,
      totalPriceinfo: dtpEntries?.totalPrice ? dtpEntries?.totalPrice : {},
      orders: dtpEntries?.entries,
    } as OrderTypeSummary;
    return orderSummary;
  }
  public static DTPPatientRecords(rootGroups, patientID): number {
    const dtpEntries = rootGroups.find((items) => items.label === CART_GROUP.DIRECT_TO_PATIENT)?.entries;
    const patiendOrders = dtpEntries?.filter((items) => items.patientId === patientID)?.length;
    return patiendOrders;
  }
  public static PracticeItemExist(rootGroups): DtpPraticeExist {
    const practiceEntires = rootGroups.find((items) => items.label === CART_GROUP.PRACTICE_ORDER);
    return {
      practiceExist: practiceEntires?.label === CART_GROUP.PRACTICE_ORDER,
    };
  }
}
