import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { ShowPriceComponent } from './show-price.component';

@NgModule({
  declarations: [ShowPriceComponent],
  imports: [CommonModule, I18nModule],
  exports: [ShowPriceComponent],
})
export class ShowPriceModule {}
