import { Component, OnDestroy, OnInit } from '@angular/core';
import { CmsService, PageMeta, PageMetaService } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit, OnDestroy {
  constructor(private cmsService: CmsService, private pageMetaService: PageMetaService) {}
  pageNotFoundImgContent;
  title$: Observable<string>;
  subscriptions: Subscription[] = [];
  ngOnInit(): void {
    this.title$ = this.pageMetaService.getMeta().pipe(
      filter(Boolean),
      map((meta: PageMeta) => meta.heading || meta.title)
    );
    this.subscriptions.push(
      this.cmsService.getComponentData('ErrorPageImageComponent').subscribe((imageContent) => {
        this.pageNotFoundImgContent = imageContent;
      })
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subs) => {
      subs.unsubscribe();
    });
  }
}
