import { Action, createReducer, on } from '@ngrx/store';
import { DecrementCount, IncrementCount, SetFavouriteCount } from '../Actions/favouriteCount.action';
const initialState = 0;
const FavCountReducer = createReducer(
  initialState,
  on(SetFavouriteCount, (state, { count }) => {
    return count;
  }),
  on(IncrementCount, (state, action) => {
    return state + 1;
  }),
  on(DecrementCount, (state, action) => {
    return state - 1;
  })
);
export function favouriteCountReducer(state: number, action: Action): number {
  return FavCountReducer(state, action);
}
