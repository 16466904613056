import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { OrderHistoryService } from 'src/app/services/order-history/order-history.service';
import { CalendarConfiguration, SelectionMode } from 'src/app/shared/model/calendar.model';
import { PatientOrderData } from 'src/app/store/States/orderHistory';
import { AlconUserPermissionService } from '../../../shared-modules/alcon-user-permission-provider/service/alcon-user-permission.service';

@Component({
  selector: 'app-patient-orders',
  templateUrl: './patient-orders.component.html',
  styleUrls: ['./patient-orders.component.scss'],
})
export class PatientOrdersComponent implements OnInit, OnDestroy {
  patientID: string;
  patientUID: string;
  orderCount: number;
  scheduleOrderCount: number;
  orderList = [];
  scheduledOrderList = [];
  orderData = {} as PatientOrderData;
  scheduledOrderData = {} as PatientOrderData;
  pagination = 10;
  selectedPeriod;
  patientOrderhistory;
  calenderConfigration: CalendarConfiguration;
  NoOrders = false;
  activeIndex = 1; // to show/hide 'show more toggle btn' w.r.t order type
  count: number;
  private destroy$ = new Subject<void>();
  subscriptionsVisibility: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private orderService: OrderHistoryService,
    private datePipe: DatePipe,
    private cd: ChangeDetectorRef,
    private router: Router,
    private userPermissionService: AlconUserPermissionService
  ) {
    const displayDate = new Date();
    this.calenderConfigration = {
      header: {
        year: displayDate.getFullYear(),
        dayAndDate: displayDate.toLocaleDateString('en-us', { weekday: 'short', month: 'short', day: 'numeric' }),
      },
      minDate: new Date(new Date().setFullYear(new Date().getFullYear() - 2)),
      maxDate: new Date(),
      styles: {
        right: '0px',
        top: '50px',
      },
      startDate: '',
      endDate: '',
      selectionMode: SelectionMode.RANGE,
    };
  }

  ngOnInit(): void {
    this.patientID = this.activatedRoute.snapshot.data.patientProfileInfo.patientId;
    this.patientUID = this.activatedRoute.snapshot.data.patientProfileInfo.uid;
    this.subscriptionsVisibility = this.userPermissionService.getSubscriptionsPermission();

    this.orderService.patientOrderHistoryList
      .pipe(startWith(this.activatedRoute.snapshot.data.patientOrderhistory), takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.populatePatientOrderHistory(data);
      });
  }

  populatePatientOrderHistory(data): void {
    if (data.orders.length > 0 || data.replenishmentOrders.length > 0) {
      this.NoOrders = false;
      this.activeIndex = data.orders.length > 0 || data.replenishmentOrders.length === 0 ? 1 : 2;
      this.createOrderData(data);
      this.createScheduleOrderData(data);
    } else {
      this.resetIndividualList();
      this.resetScheduleList();
      this.count = 0;
      this.NoOrders = true;
      this.cd.detectChanges();
    }
  }
  /**
   * Individual order object creation
   * @param data contains API response data
   */
  createOrderData(data: any): any {
    this.resetIndividualList();
    if (data && data.orders.length > 0) {
      this.orderCount = data.orders.length;
      this.count = this.orderCount;
      this.orderList = this.orderService.createComponentData(
        data.orders,
        this.orderData,
        this.orderList,
        this.patientID
      );
    }
    this.cd.detectChanges();
  }

  resetIndividualList(): void {
    this.orderData = {} as PatientOrderData;
    this.orderCount = 0;
    this.orderList = [];
  }

  /**
   * Scheduled order data creation
   * @param data contains response data
   */
  createScheduleOrderData(data: any): any {
    this.resetScheduleList();
    if (data && data.replenishmentOrders.length > 0) {
      this.scheduleOrderCount = data.replenishmentOrders.length;
      this.scheduledOrderList = this.orderService.createComponentData(
        data.replenishmentOrders,
        this.scheduledOrderData,
        this.scheduledOrderList,
        this.patientID
      );
    }
    this.cd.detectChanges();
  }

  resetScheduleList(): void {
    this.scheduledOrderData = {} as PatientOrderData;
    this.scheduleOrderCount = 0;
    this.scheduledOrderList = [];
  }
  /**
   * count holds orderCount / scheduleOrderCount
   * used to show/hide pagination
   * @param changeEvent tab event
   */
  onNavChange(changeEvent: NgbNavChangeEvent): void {
    this.count = changeEvent.nextId === 1 ? this.orderCount : this.scheduleOrderCount;
  }

  loadPagination(): void {
    this.pagination = this.pagination + 10;
  }
  getSelectedDate(date): void {
    this.calenderConfigration.startDate = date[0] ? this.datePipe.transform(new Date(date[0]), 'dd/MM/yyyy') : '';
    this.calenderConfigration.endDate = date[1] ? this.datePipe.transform(new Date(date[1]), 'dd/MM/yyyy') : '';
    this.selectedPeriod = date[1]
      ? this.calenderConfigration.startDate + ' - ' + this.calenderConfigration.endDate
      : this.calenderConfigration.startDate;
    this.calenderConfigration.rangeDates = date;
  }

  /**
   * Invokes on date filter search as well as on delete
   * @param isDelete  // specifies delete operation
   */
  getPatientOrderList(isDelete = false): void {
    this.orderService.fetchPatientOrderHistoryList(
      this.patientID,
      this.calenderConfigration.startDate,
      this.calenderConfigration.endDate,
      isDelete
    );
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
