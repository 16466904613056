<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-10 col-xl-10">
      <cx-page-slot position="MyPatientsNotePosition"></cx-page-slot>
    </div>
    <div [ngClass]="patientsCount > 0 ? 'col-5' : 'col-12 col-xl-10'">
      <h1 class="mb-0 pb-2">{{ title$ | async }} ({{ patientsCount }})</h1>
    </div>
    <div class="col-5 search" *ngIf="patientsCount > 0">
      <div class="search__container">
        <label class="search__label d-none d-lg-block">{{ 'myPatientsPage.search' | cxTranslate }}</label>
        <input
          (keyup)="searchPatient($event)"
          class="search__select"
          type="text"
          placeholder="{{ 'myPatientsPage.search' | cxTranslate }}"
        />
        <cx-icon class="search__icon" type="SearchIcon"></cx-icon>
      </div>
    </div>
  </div>
  <div *ngIf="patientsCount > 0" class="row justify-content-center">
    <div class="col-10 col-xl-10">
      <cx-page-slot position="MyPatientsFilterSlotPosition"></cx-page-slot>
    </div>
  </div>
  <div *ngIf="patientsCount > 0" class="add-patient-success">
    {{ patientSuccessMessage }}
  </div>
  <div class="row justify-content-center" [ngClass]="patientsCount > 0 ? '' : 'd-none'">
    <div class="col-10 col-xl-10">
      <cx-page-slot position="MyPatientsListPosition"></cx-page-slot>
    </div>
  </div>
  <div class="row justify-content-center" *ngIf="patientsCount === 0">
    <div class="col-10 col-xl-10">
      <div class="myPatients-emptyPatients">
        <p class="myPatients-emptyPatients__patientsText">{{ (patientMessage | async)?.content }}</p>
      </div>
      <div class="myPatients-button d-flex justify-content-center">
        <app-add-patient-button-popup></app-add-patient-button-popup>
      </div>
    </div>
  </div>
</div>
