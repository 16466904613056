import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule, UrlModule } from '@spartacus/core';
import {
  IconModule,
  MediaModule,
  OutletPosition,
  PageComponentModule,
  PageSlotModule,
  provideOutlet,
} from '@spartacus/storefront';
import { AlconCmsComponentsModule } from 'src/app/cms-components/alcon-cms-components.module';
import { AlconCommonModule } from 'src/app/cms-components/common/alcon-common.module';
import { InvoiceListSearchComponent } from './components/invoice-list-search/invoice-list-search.component';
import { InvoiceOrderNumberAccordionComponent } from './components/invoice-order-number-accordion/invoice-order-number-accordion.component';
import { InvoicesListFilterComponent } from './components/invoices-list-filter/invoices-list-filter.component';
import { InvoicesListComponent } from './components/invoices-list/invoices-list.component';
import { InvoicesPageRoutingModule } from './invoices-page-routing.module';
import { InvoicesPageComponent } from './invoices-page.component';

@NgModule({
  declarations: [
    InvoicesPageComponent,
    InvoicesListFilterComponent,
    InvoicesListComponent,
    InvoiceListSearchComponent,
    InvoiceOrderNumberAccordionComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    IconModule,
    NgbModule,
    MediaModule,
    PageSlotModule,
    PageComponentModule,
    InvoicesPageRoutingModule,
    I18nModule,
    UrlModule,
    AlconCommonModule,
    FormsModule,
    AlconCmsComponentsModule,
  ],
  providers: [
    provideOutlet({
      id: 'VCInvoiceListPageTemplate',
      position: OutletPosition.REPLACE,
      component: InvoicesPageComponent,
    }),
  ],
})
export class InvoicesPageModule {}
