import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { CheckoutPaymentTypeFacade } from '@spartacus/checkout/b2b/root';
import { PaymentDetails, UserIdService, UserPaymentService } from '@spartacus/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { CHECKOUT, MODAL_POPUP_CONFIG } from 'src/app/shared/constants/constants';
import { PaymentMode } from 'src/app/shared/model/common.mode';
import { enGB } from 'src/app/shared/translations/en_GB/index';
import { getCartItems, getPaymentTypes } from 'src/app/store/Selectors/cartItem.selector';
import { VisionCareCartItems } from 'src/app/store/States/cartItem.state';
import { CreditCardPaymentComponent } from '../creditcard-payment/creditcard-payment.component';
import { CreditCardListService } from 'src/app/services/payment-type/credit-card-list.service';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss'],
})
export class PaymentMethodComponent implements OnInit, OnDestroy {
  isCardDetailsAvailable$: Observable<PaymentDetails | PaymentDetails[]>;
  constructor(
    protected checkoutPaymentTypeFacade: CheckoutPaymentTypeFacade,
    private cd: ChangeDetectorRef,
    private store: Store,
    private userIdService: UserIdService,
    private modalService: NgbModal,
    private userPaymentService: UserPaymentService,
    private creditCardListService: CreditCardListService
  ) {}
  options: PaymentMode[];
  selectedPaymentMethod = CHECKOUT.PAYMENT_TYPE_ACCOUNT;
  userId: string;
  cartId: string;
  private destroy$ = new Subject<void>();
  visionCareCartData: VisionCareCartItems;
  savedCardList: PaymentDetails | PaymentDetails[];

  ngOnInit(): void {
    this.userPaymentService.loadPaymentMethods();
    this.checkoutPaymentTypeFacade
      .getPaymentTypes()
      .pipe(takeUntil(this.destroy$), withLatestFrom(this.store.select(getPaymentTypes)))
      .subscribe(([data, { code }]) => {
        const allPaymentTypes = [];
        this.selectedPaymentMethod = code;
        data.forEach((paymentType: PaymentMode) => {
          const type = { ...paymentType };
          switch (type.code) {
            case CHECKOUT.PAYMENT_TYPE_ACCOUNT:
              type.value = enGB.cms.checkoutPage.paymentType2;
              type.isSelected = CHECKOUT.PAYMENT_TYPE_ACCOUNT === code;
              allPaymentTypes.splice(0, 0, type);
              break;
            case CHECKOUT.PAYMENT_TYPE_CARD:
              type.value = enGB.cms.checkoutPage.paymentType1;
              type.isSelected = CHECKOUT.PAYMENT_TYPE_CARD === code;
              allPaymentTypes.push(type);
              break;
          }
        });
        this.options = allPaymentTypes;
        this.updatePaymentTypeStore();
        this.cd.detectChanges();
      });
  }

  switchValue(option): void {
    this.selectedPaymentMethod = option.code;
    this.updatePaymentTypeStore();
  }

  addNewCard(): void {
    if (this.selectedPaymentMethod === CHECKOUT.PAYMENT_TYPE_CARD) {
      const modalRef = this.modalService.open(CreditCardPaymentComponent, {
        ...MODAL_POPUP_CONFIG,
        windowClass: 'creditCard_alertPopup',
      });
      modalRef.componentInstance.cartdata = this.visionCareCartData;
      this.isCardDetailsAvailable$ = modalRef.componentInstance.emitCardDetails;
      this.isCardDetailsAvailable$.pipe(take(1)).subscribe((res) => {
        modalRef.close();
      });
    }
  }

  updatePaymentTypeStore(): void {
    combineLatest([this.userIdService.getUserId(), this.store.select(getCartItems)])
      .pipe(
        filter(([uid, cartId]) => cartId != null),
        take(1)
      )
      .subscribe(([userId, cart]) => {
        const { code: cartId } = cart;
        this.visionCareCartData = cart;
        this.checkoutPaymentTypeFacade.setPaymentType(this.selectedPaymentMethod);
        this.creditCardListService
          .getSetCartPaymentTypeEndpoint(userId, cartId, this.selectedPaymentMethod)
          .subscribe();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
