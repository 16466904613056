import { NgModule } from '@angular/core';
import { asmTranslationChunksConfig, asmTranslations } from '@spartacus/asm/assets';
import { ASM_FEATURE, AsmConfig, AsmRootModule } from '@spartacus/asm/root';
import { CmsConfig, I18nConfig, provideConfig } from '@spartacus/core';

@NgModule({
  declarations: [],
  imports: [AsmRootModule],
  providers: [
    provideConfig({
      featureModules: {
        [ASM_FEATURE]: {
          module: () => import('@spartacus/asm').then((m) => m.AsmModule),
        },
      },
    } as CmsConfig),
    provideConfig({
      i18n: {
        resources: asmTranslations,
        chunks: asmTranslationChunksConfig,
      },
    } as I18nConfig),
    provideConfig({
          asm: {
            userIdHttpHeader: {
              enable: true,
            },
          },
        } as AsmConfig),
  ],
})
export class AsmFeatureModule {}
