import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalMessageService, GlobalMessageType, OccEndpointsService } from '@spartacus/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalMessage } from 'src/app/shared/constants/constants';
@Injectable({
  providedIn: 'root',
})
export class AlconSiteService {
  private currentSite = '';

  constructor(
    private httpClient: HttpClient,
    private occEndPoints: OccEndpointsService,
    private globalMessageService: GlobalMessageService
  ) {}

  /**
   *
   * @returns Observable of site Data change any to specific type
   */
  fetchSiteData(): Observable<any> {
    const url = this.occEndPoints.buildUrl('/b2bintlsites', {}, { baseSite: false });
    return this.httpClient.get(url).pipe(
      catchError((error) => {
        this.globalMessageService.add(
          error.error.errors[0].message,
          GlobalMessageType.MSG_TYPE_ERROR,
          GlobalMessage.TIMEOUT
        );
        return EMPTY;
      })
    );
  }

  /**
   *
   * @param siteUrl return URL for site
   */
  setCurrentSite(siteUrl): void {
    this.currentSite = siteUrl;
  }

  /**
   *
   * @returns current site URL
   */
  getCurrentSite(): string {
    return this.currentSite;
  }
}
