<div class="d-flex flex-wrap">
  <ul class="categories__ul-list">
    <li class="d-flex categories__list" *ngFor="let items of categories">
      <a
        class="categories__facet--button isActive"
        *ngIf="items.isClicked"
        [routerLink]="{ cxRoute: cxRoute | async } | cxUrl"
      >
        {{ items.code | removeWhitespace | lowercase | cxTranslate }}
        <span *ngIf="items.count">({{ items.count }})</span>
      </a>
      <a
        class="categories__facet--button"
        *ngIf="!items.isClicked"
        [routerLink]="{ cxRoute: cxRoute | async, params: { code: items.code } } | cxUrl"
      >
        {{ items.code | removeWhitespace | lowercase | cxTranslate }}
        <span *ngIf="items.count">({{ items.count }})</span>
      </a>
    </li>
  </ul>
</div>
