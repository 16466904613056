<div class="alcon-filter">
  <a class="alcon-filter__filter-label" *ngIf="!showFilter" (click)="showFilter = true">{{
    'invoicesListPage.filter.filterPlus' | cxTranslate
  }}</a>
  <a class="alcon-filter__filter-label" *ngIf="showFilter" (click)="showFilter = false">{{
    'invoicesListPage.filter.filterMinus' | cxTranslate
  }}</a>
</div>

<div *ngIf="showFilter" class="alcon-filter-list">
  <ul class="alcon-filter-list__listitem">
    <li class="alcon-filter-list__listitem-p" *ngFor="let key of radioInputOptions; let i = index">
      <input
        id="key_{{ key.name }}"
        [value]="key.name"
        type="radio"
        [name]="key.name"
        [(ngModel)]="radioSelected"
        class="alconInput__radio"
        (change)="change()"
      />
      <label for="key_{{ key.name }}">
        {{ 'invoicesListPage.filter.' + key.label | cxTranslate }}
      </label>
    </li>
  </ul>
</div>
