import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule } from '@spartacus/core';
import { PageSlotModule, MediaModule, IconModule } from '@spartacus/storefront';
import { AlconCommonModule } from '../common/alcon-common.module';
import { AlconCheckoutComponent } from './alcon-checkout/alcon-checkout.component';
import { CheckoutDeliverymodeComponent } from './checkout-deliverymode/checkout-deliverymode-panel.component';
import { PoNumberComponent } from './po-number/po-number.component';
import { PracticeorderDeliverymodeComponent } from './practiceorder-deliverymode/practiceorder-deliverymode.component';
import { PaymentMethodComponent } from './payment-method/payment-method.component';
import { CheckoutShippingAddressComponent } from './checkout-shipping-address/checkout-shipping-address.component';
import { FormsModule } from '@angular/forms';
import { CreditCardPaymentComponent } from './creditcard-payment/creditcard-payment.component';
import { CheckoutB2BModule } from '@spartacus/checkout/b2b';

@NgModule({
  declarations: [
    AlconCheckoutComponent,
    CheckoutDeliverymodeComponent,
    PaymentMethodComponent,
    PoNumberComponent,
    PracticeorderDeliverymodeComponent,
    CheckoutShippingAddressComponent,
    CreditCardPaymentComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    I18nModule,
    PageSlotModule,
    MediaModule,
    IconModule,
    RouterModule,
    AlconCommonModule,
    FormsModule,
    CheckoutB2BModule
  ],
  exports: [
    AlconCheckoutComponent,
    CheckoutDeliverymodeComponent,
    PaymentMethodComponent,
    PoNumberComponent,
    PracticeorderDeliverymodeComponent,
  ],
})
export class CheckoutModule {}
