import { _, loc } from 'okta';
import Enums from 'util/Enums';
import FormController from 'util/FormController';
import FormType from 'util/FormType';
import LoginModel from 'models/LoginModel';

export default FormController.extend({
  className: 'sap-approval-email-sent',
  Model: {
    props: {
      approver_email: ['string', true],
    },

    save: function(context) { //try save within model, but with context param
      const self = context;
      const activationToken = self.options.appState.get('activationToken');

      if (activationToken) {
        // register via activation token
        self.settings.callGlobalSuccess(Enums.SAP_APPROVAL_EMAIL_SENT, {
          username: self.options.appState.get('username'),
          sapId: self.options.appState.get('sap_id'),
          activationToken: activationToken
        });

        const loginModel = new LoginModel({
          settings: self.options.appState.settings
        });

        loginModel.loginWithActivationToken(activationToken).then(function(transaction) {
          self.model.trigger('setTransaction', transaction);
        });
      } 
      else {
        // register via activation email
        self.options.appState.trigger('navigate', 'signin/register-complete');
      }
    }
  },

  Form: {
    title: _.partial(loc, 'sap.approval.emailSent.title', 'login'),
    subtitle: function() {
      const sap_id = this.options.appState.get('sapId');
      const approver_email = this.options.appState.get('approver_email');

      return loc('sap.approval.emailSent.desc', 'login', [sap_id, approver_email]);
    },
    noButtonBar: true,
    attributes: { 'data-se': 'sap-approval-email-sent' },
    formChildren: function() {
      const self = this;
      return [
        FormType.Button({
          title: loc('sap.approval.finish', 'login'),
          className: 'button button-primary button-wide',
          attributes: { 'data-se': 'finish-button' },
          click: function() {
            self.trigger('save');
          }
        }),
      ];
    },

    initialize: function() {
      /**
       * If no username present in app state, then this page was rendered manually,
       * not by registration flow, so redirect back to login page.
       * 
       * In the future, we could get rid of this and allow this page
       * to be reached independently of registration if we want/need
       * to enable some kind of two-part registration system
       **/
      if (!this.options.appState.get('username') || !this.options.appState.get('sapId')){
        this.settings.callGlobalError(Enums.SAP_APPROVAL_STATE_INVALID);
        this.state.set('navigateDir', Enums.DIRECTION_BACK);
        this.options.appState.trigger('navigate', '');
      }

      this.listenTo(this, 'save', function() {
        this.model.save(this);
      });
    }
  }
});