import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbAccordionDirective } from '@ng-bootstrap/ng-bootstrap';
import { Product } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { PDP_SECTION } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-product-description',
  templateUrl: './product-description.component.html',
  styleUrls: ['./product-description.component.scss'],
})
export class ProductDescriptionComponent implements OnInit {
  readonly downloads = PDP_SECTION.DOWNLOADS;

  descriptionId = 'product' + new Date().getMilliseconds();
  specififcationId = 'product' + new Date().getMilliseconds();
  @ViewChild('accordian') accordian: NgbAccordionDirective;

  product: Product = {
    isLensCareSolution: false,
    name: '',
    price: {
      formattedValue: '',
    },
    variantTree: null,
    packSizeList: [],
    privateLabel: false,
  };
  downloadsObject = [];
  featuresObject = [];
  product$: Observable<Product> = this.currentProductService.getProduct('all');
  constructor(protected currentProductService: CurrentProductService) {}

  prodDescription = true;
  ngOnInit(): void {
    this.product$.pipe(take(1)).subscribe((productData) => {
      this.featuresObject = productData?.classifications?.filter(
        (classification) => !classification.code.includes(PDP_SECTION.FEATURES)
      );
      if (this.featuresObject) {
        this.downloadsObject = this.featuresObject[0].features.filter(
          (feature) => feature.externalID === PDP_SECTION.DOWNLOADS
        );
      }
    });
  }

  toggleDescFunction(): void {
    this.prodDescription = true;
  }
  toggleSpecFunction(): void {
    this.prodDescription = false;
  }
}
