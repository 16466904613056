import { Component, Input, OnDestroy } from '@angular/core';
import { GlobalMessageService, GlobalMessageType, TranslationService } from '@spartacus/core';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PatientService } from 'src/app/services/patient/patient.service';
import { PatientListService } from 'src/app/services/patients-list/patient-list.service';
import { GlobalMessage } from 'src/app/shared/constants/constants';
import { ModalOptions } from 'src/app/shared/model/common.mode';

@Component({
  selector: 'app-add-patient-button-popup',
  templateUrl: './add-patient-button-popup.component.html',
  styleUrls: ['./add-patient-button-popup.component.scss'],
})
export class AddPatientButtonPopupComponent implements OnDestroy {
  public modalOptions = {
    open: false,
    header: false,
    modalTitle: '',
    borderRadius: '20px',
  } as ModalOptions;

  productOpen: boolean;
  @Input() btnWidth?: string;
  private destroy$ = new Subject<void>();
  successMessage: string;
  constructor(
    private patientService: PatientService,
    private patientListService: PatientListService,
    private globalMessageService: GlobalMessageService,
    private translation: TranslationService
  ) {
    combineLatest([this.translation.translate('myPatientsPage.addNewPatient.patientAddedSuccessfully')])
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.successMessage = data[0];
      });
  }

  openModal(): void {
    this.productOpen = true;
    this.modalOptions.open = true;
    document.body.classList.add('modal-open');
  }
  closeModal(): void {
    this.productOpen = false;
    this.modalOptions.open = false;
    document.body.classList.remove('modal-open');
  }
  patientAddedSuccessfully(): void {
    this.globalMessageService.add(this.successMessage, GlobalMessageType.MSG_TYPE_CONFIRMATION, GlobalMessage.TIMEOUT);
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.closeModal();
  }
}
