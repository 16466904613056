import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AddressService } from 'src/app/services/addresses/address.service';
import { PatientService } from 'src/app/services/patient/patient.service';
import { ValidatorService } from 'src/app/services/validators/validator.service';
import { AddressInfo } from 'src/app/shared/model/patient.model';
@Component({
  selector: 'app-select-delivery-address',
  templateUrl: './select-delivery-address.component.html',
  styleUrls: ['./select-delivery-address.component.scss'],
})
export class SelectDeliveryAddressComponent implements OnInit, OnDestroy {
  addressType;
  @Input() patientInfo;
  mainAddress: AddressInfo;
  additionalAddress: AddressInfo;
  @Output() cancel: EventEmitter<string> = new EventEmitter();
  @Output() saveAddressInfo: EventEmitter<string> = new EventEmitter();
  addressInformation;
  showAdditionalAdd = false;
  updatePatientForm: UntypedFormGroup;
  disableMainAdd;
  selectedAddressIndex = 0;
  editMainAddress = false;
  editAdditionalAddress = false;
  addMainAddress = false;
  private destroy$ = new Subject<void>();
  addressError = '';

  constructor(
    private patientService: PatientService,
    private patientFormBuilder: UntypedFormBuilder,
    private cd: ChangeDetectorRef,
    private store: Store,
    public validatorService: ValidatorService,
    private addressService: AddressService
  ) {}

  ngOnInit(): void {
    this.addressType = this.patientInfo.addressType;
    this.addressInformation = this.patientInfo.addresses;
    if (!this.patientInfo.addresses) {
      this.patientInfo.addresses = [{ defaultAddress: true }];
      this.editMainAddress = true;
      this.addMainAddress = true;
    }
    this.mainAddress = this.addressInformation?.find((address) => address.defaultAddress);
    this.additionalAddress = this.addressInformation?.find((address) => !address.defaultAddress);
    if (this.mainAddress) {
      this.patientInfo.addresses = [{ ...this.mainAddress }];
    }
    if (this.additionalAddress) {
      this.patientInfo.addresses.push({ ...this.additionalAddress });
    }

    this.updatePatientForm = this.patientFormBuilder.group({
      userPhoneNumberDataList: this.patientFormBuilder.array([
        this.patientFormBuilder.group({
          phoneNumber: [''],
          country: this.patientFormBuilder.group({
            isocode: ['GB'],
          }),
        }),
      ]),
      addresses: this.patientFormBuilder.array(
        this.patientInfo.addresses?.map(() => this.addressService.getAddressFields())
      ),
    });
    this.updatePatientForm.patchValue(this.patientInfo);
  }
  cancelForm(): void {
    if ((this.editMainAddress && !this.addMainAddress) || this.editAdditionalAddress) {
      this.resetForm();
      if (this.patientInfo.addresses.length === 1 && this.addresses.length > 1) {
        this.addressType = 'MainAddress';
      }
    } else {
      if (this.addMainAddress) {
        this.patientInfo.addresses = undefined;
      }
      this.cancel.emit('cancelled');
    }
    this.showAdditionalAdd = false;
    this.addressError = '';
  }
  resetForm(): void {
    this.updatePatientForm.reset();
    this.updatePatientForm.patchValue(this.patientInfo);
    if (this.patientInfo.addresses.length === 1 && this.addresses.length > 1) {
      this.addresses.pop();
    }
    this.editMainAddress = false;
    this.editAdditionalAddress = false;
  }
  addAdditionalAddress(): void {
    this.selectedAddressIndex = 1;
    if (this.addresses.length < 2) {
      const address: UntypedFormGroup = this.addressService.getAddressFields();
      this.addresses.push(address);
    }
    this.disableMainAdd = true;
    this.addressType = 'AdditionalAddress';
    this.editAdditionalAddress = true;
  }
  get addresses(): Array<AbstractControl> {
    /* tslint:disable-next-line */
    return this.updatePatientForm.get('addresses')['controls'];
  }
  onSubmit(): void {
    this.addresses.forEach((control: UntypedFormControl) => {
      control.markAsDirty();
      control.updateValueAndValidity();
    });
    if (this.editMainAddress || this.editAdditionalAddress) {
      if (this.updatePatientForm.dirty && this.updatePatientForm.valid) {
        this.patientService
          .updateUser({
            ...this.patientInfo,
            addresses: [...this.updatePatientForm.getRawValue().addresses],
            userPhoneNumberDataList: [...this.updatePatientForm.getRawValue().userPhoneNumberDataList],
          })
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.patientInfo.addresses = [...this.updatePatientForm.getRawValue().addresses];
            this.emitAddress();
          });
      } else {
        this.updatePatientForm.markAllAsTouched();
      }
    } else {
      this.emitAddress();
    }
  }
  emitAddress(): void {
    this.addressType === 'AdditionalAddress'
      ? this.saveAddressInfo.emit(this.patientInfo.addresses[1])
      : this.saveAddressInfo.emit(this.patientInfo.addresses[0]);
  }

  changeAddressType(event): void {
    this.resetForm();
    this.addressType = event.target.value;
  }
  deleteAdditionalAddress(): void {
    const addressId = this.updatePatientForm.getRawValue().addresses[1].id;
    if (this.editAdditionalAddress && addressId) {
      this.patientService
        .deleteAdditionalAddress({
          ...this.patientInfo,
          addresses: [...this.updatePatientForm.getRawValue().addresses],
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (val) => {
            this.additionalAddress = {};
            if (this.patientInfo.addresses.length > 1) {
              this.patientInfo.addresses.pop();
              this.addressType = 'MainAddress';
              this.cancelForm();
              this.cd.detectChanges();
            }
          },
          (error) => {
            this.addressError = error.error.errors[0].message;
            this.cd.detectChanges();
          }
        );
    } else {
      this.cancelForm();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
