import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalMessageService, GlobalMessageType, SemanticPathService, TranslationService } from '@spartacus/core';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { OrderHistoryService } from 'src/app/services/order-history/order-history.service';
import { PatientService } from 'src/app/services/patient/patient.service';
import { AlconUserPermissionService } from 'src/app/shared-modules/alcon-user-permission-provider/service/alcon-user-permission.service';
import { DELIVERY_TYPE, GlobalMessage, MODAL_POPUP_CONFIG, ORDER_TYPE } from 'src/app/shared/constants/constants';
import { PatientOrderData } from 'src/app/store/States/orderHistory';
import { AlconAlertPopupComponent } from '../alcon-alert-popup/alcon-alert-popup.component';

@Component({
  selector: 'app-generic-order-list',
  templateUrl: './generic-order-list.component.html',
  styleUrls: ['./generic-order-list.component.scss'],
})
export class GenericOrderListComponent implements OnDestroy {
  @Input() listType: string; // for individual vs scheduled order
  @Input() componentData: PatientOrderData; // contains individual/scheduled order data
  @Input() patientUID;
  @Output() statusChanged: EventEmitter<any> = new EventEmitter();
  @Output() refreshList: EventEmitter<any> = new EventEmitter();
  public isCollapsed = []; // for main address dropdown area
  modalSubscription: Subscription;
  translationArray = [];
  orderTypeIndividual = ORDER_TYPE.Individual;

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private orderService: OrderHistoryService,
    private globalMessageService: GlobalMessageService,
    private patientService: PatientService,
    private translation: TranslationService,
    private semanticPathService: SemanticPathService,
    public alconUserPermissionService: AlconUserPermissionService
  ) {
    this.getTranslatedValues();
  }

  showMoreToggle(data: { showMore?: boolean }): void {
    data.showMore = !data.showMore || false;
  }

  getTranslatedValues(): void {
    combineLatest([
      this.translation.translate('reOrderValidation.GENERIC_API_ERRORMESSAGE'),
      this.translation.translate('reOrderValidation.DIRECT_TO_PATIENT_MESSAGE'),
      this.translation.translate('reOrderValidation.GENERIC_MESSAGE'),
      this.translation.translate('patientOrderHistory.deleteSub'),
      this.translation.translate('patientOrderHistory.scheduleOrderYes'),
      this.translation.translate('patientOrderHistory.scheduleOrderNo'),
      this.translation.translate('patientOrderHistory.deletedSuccessfully'),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.translationArray = data;
      });
  }
  editScheduledOrder(Code): void {
    this.router.navigate(
      this.semanticPathService.transform({
        cxRoute: 'editScheduledOrder',
        params: { patientID: this.patientUID, code: Code },
      })
    );
  }
  onToggleChange(event: any, orderCode: string): void {
    this.statusChanged.emit({ event, orderCode });
  }
  /**
   * triggers on subscription status change
   * updates component data
   * @param data scheduled order data
   * @param index row index
   */
  updateComponentData(data, index): void {
    this.componentData[index] = data;
    this.updatePatientDatainList();
  }

  /**
   * Updates patient list page with recent changes
   */
  updatePatientDatainList(): void {
    this.patientService.getPatientProfileInformation(this.patientUID).pipe(take(1)).subscribe();
  }
  /**
   * Triggers alert popup with user confirmation for delete operation
   * on yes-triggers API call
   * on no- No action
   * @param orderCode // unique order number
   */
  deleteScheduleOrder(orderCode): void {
    const modalRef = this.modalService.open(AlconAlertPopupComponent, {
      ...MODAL_POPUP_CONFIG,
      windowClass: 'patientOrderList_alertPopup',
    });
    modalRef.componentInstance.alertConfig = {
      msg: this.translationArray[3],
      btnOneTxt: this.translationArray[4],
      btnTwoTxt: this.translationArray[5],
    };
    this.modalSubscription = modalRef.componentInstance.btnClick.subscribe((value: string) => {
      if (value === 'Yes') {
        this.deleteOrderFromList(orderCode);
        modalRef.close();
      } else {
        modalRef.close();
      }
    });
  }

  /**
   * Makes API call to delete  scheduled order
   * upon response updates UI
   * @param code contains unique order code
   */
  deleteOrderFromList(orderCode: string): void {
    this.orderService
      .deleteOrder(orderCode)
      .pipe(take(1))
      .subscribe(
        (res) => {
          if (res) {
            this.updatePatientDatainList();
            this.refreshList.emit();
            this.globalMessageService.add(this.translationArray[6], GlobalMessageType.MSG_TYPE_CONFIRMATION, 3000);
          }
        },
        (error) => {
          this.globalMessageService.add(error, GlobalMessageType.MSG_TYPE_ERROR, 3000);
        }
      );
  }

  reOrderResponse(isSuccess, orderType): void {
    if (isSuccess) {
      this.globalMessageService.add(this.translationArray[0], GlobalMessageType.MSG_TYPE_ERROR, GlobalMessage.TIMEOUT);
    } else {
      const successMessage =
        orderType.trim() === DELIVERY_TYPE.DIRECT_TO_PATIENT_ORDER_TYPE.trim()
          ? this.translationArray[1]
          : this.translationArray[2];
      this.globalMessageService.add(successMessage, GlobalMessageType.MSG_TYPE_CONFIRMATION, GlobalMessage.TIMEOUT);
    }
  }

  ngOnDestroy(): void {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
}
