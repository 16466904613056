<div class="cart-valuepack" *ngIf="cartEntry?.entries">
  <ng-container *ngTemplateOutlet="valuePackQuantity; context: { $implicit: { view: 'tablet', cartEntry: cartEntry } }">
  </ng-container>
  <div class="flex-grow-1">
    <p *ngIf="cartEntry?.errorMessage" class="errorDisplay errorDisplay--orange pt-2 m-0">
      {{ cartEntry?.errorMessage }}
    </p>
    <app-cart-product-information
      [cartEntry]="entry"
      *ngFor="let entry of cartEntry.entries"
      [showPatientReference]="false"
      [isFromValuePack]="true"
    ></app-cart-product-information>
  </div>
  <div class="d-flex justify-content-between flex-column pt--41">
    <ng-container
      *ngTemplateOutlet="valuePackQuantity; context: { $implicit: { view: 'desktop', cartEntry: cartEntry } }"
    >
    </ng-container>
    <app-cart-actions
      *ngIf="showActions"
      [cartEntry]="cartEntry"
      (emitDeleteItem)="deleteItem($event)"
      [disableEditButton]="true"
    ></app-cart-actions>
  </div>
</div>

<ng-template #valuePackQuantity let-item>
  <div
    [ngClass]="['cart-valuepack__quntity--' + item?.view]"
    class="cart-valuepack__quntity justify-content-between d-flex flex-column"
  >
    <div class="cart-valuepack__price-wrap">
      <label class="cart-valuepack__item-price">
        <app-show-price [productDetails]="item?.cartEntry"></app-show-price>
      </label>
    </div>
    <div class="cart-valuepack__duration">
      <p
        *ngIf="valuePackduration"
        class="cart-valuepack__patient-reference cart-valuepack__patient-reference--duration"
      >
        <b>{{ valuePackduration }}</b>
      </p>
      <p *ngIf="showPatientReference && item?.cartEntry?.patientName" class="cart-valuepack__patient-reference">
        {{ 'miniCartPage.patientReference' | cxTranslate }}: {{ item?.cartEntry?.patientName }}
      </p>
    </div>
  </div>
</ng-template>
