import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { User } from '@spartacus/user/account/root';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { CartItemsService } from 'src/app/services/cart-items/cart-items.service';
import { CART_GROUP } from 'src/app/shared/constants/constants';
import { getCalculatedFlag, isPONumberRequired } from 'src/app/store/Selectors/cartItem.selector';

@Component({
  selector: 'app-alcon-checkout',
  templateUrl: './alcon-checkout.component.html',
  styleUrls: ['./alcon-checkout.component.scss'],
})
export class AlconCheckoutComponent implements OnInit, OnDestroy {
  practiceOrderEntries = [];
  dtporders = [];
  poNumber: string;
  showAllAddress = false;
  finalPriceCalculated: Observable<boolean> = this.store.pipe(select(getCalculatedFlag));
  isPONumberRequired: Observable<boolean> = this.store.pipe(select(isPONumberRequired));
  isError = false;
  defaultDeliveryMethod: string;
  deliveryAddress: any;
  isValuePackEnabled$: Observable<boolean> = this.userProfileService.get().pipe(
    filter((response: User) => !!response),
    map((response: User) => response.isValuePackEnabled)
  );
  private destroy$ = new Subject<void>();
  constructor(
    private store: Store,
    private cd: ChangeDetectorRef,
    private cartItemsService: CartItemsService,
    private route: ActivatedRoute,
    private userProfileService: UserProfileFacade
  ) {}

  ngOnInit(): void {
    const { rootGroups } = this.route.snapshot.data.orderSimulate;
    this.deliveryAddress = this.route.snapshot.data.orderSimulate.deliveryAddress;
    this.defaultDeliveryMethod = this.route.snapshot.data.orderSimulate.deliveryMode.name;
    const cartrootGroups = JSON.parse(JSON.stringify(rootGroups));
    this.practiceOrderEntries = cartrootGroups.find((items) => items.label === CART_GROUP.PRACTICE_ORDER);
    const { entries: dtpEntries = [] } = cartrootGroups.find((items) => items.label === CART_GROUP.DIRECT_TO_PATIENT)
      ? cartrootGroups.find((items) => items.label === CART_GROUP.DIRECT_TO_PATIENT)
      : [{ entries: [] }];
    this.dtporders = dtpEntries;
    this.cartItemsService.poNumberValidation.pipe(takeUntil(this.destroy$)).subscribe((data: boolean) => {
      this.isError = data;
      this.cd.detectChanges();
    });
  }
  enteredPONumber(value): any {
    this.poNumber = value;
    if ((this.isPONumberRequired && this.poNumber) || this.poNumber.length < 30) {
      this.cartItemsService.ValidatePonumber(false);
    }
    this.cartItemsService.setPOnumber(this.poNumber);
  }
  showAll(): void {
    this.showAllAddress = !this.showAllAddress;
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
