import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Address } from 'src/app/shared/model/b2b-unit.model';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
})
export class AccountDetailsComponent {
  @Input() address: Partial<Address>;
  @Output() setUnitData = new EventEmitter();
  @Input() ifShipToSelector;
  setData(): void {
    this.setUnitData.emit(this.address?.uid ? this.address?.uid : this.address?.sapCustomerId);
  }
}
