<div
  *ngIf="showStatement"
  class="dropdown__container"
  [ngbPopover]="popoverTmpl"
  popoverClass="popover--sm"
  placement="bottom"
  triggers="mouseenter:mouseleave"
>
  <div
    class="dropdown__select"
    (click)="showDropDown()"
    [ngClass]="{ dropdown__select: options, 'dropdown__select-disable': !options }"
  >
    {{ 'statements.statementForDownload' | cxTranslate }}
    <cx-icon *ngIf="!openDropDown" class="dropdown__icon" type="DropdownExpand"></cx-icon>
  </div>
  <div class="dropdown__options" [ngClass]="{ 'dropdown__options--open': openDropDown }">
    <div class="dropdown__options--header" (click)="showDropDown()">
      {{ 'statements.statementForDownload' | cxTranslate }}
      <cx-icon *ngIf="!openDropDown" class="dropdown__icon--expand" type="DropdownExpand"></cx-icon>
      <cx-icon *ngIf="openDropDown" class="dropdown__icon--expand" type="DropdownCollapse"></cx-icon>
    </div>
    <ul class="dropdown__options--list">
      <li class="dropdown__options--item" *ngFor="let option of options">
        <span class="font-weight-bold">{{ option.value }}</span>
        <cx-icon
          *ngIf="option.openDropDown"
          (click)="option.openDropDown = false"
          class="dropdown__icon--expand"
          type="DropdownExpandSmall"
        ></cx-icon>
        <cx-icon
          *ngIf="!option.openDropDown"
          (click)="option.openDropDown = true"
          class="dropdown__icon--expand"
          type="DropdownCollapseSmall"
        ></cx-icon>
        <ul class="dropdown__options--sublist" *ngIf="option.openDropDown">
          <li
            class="dropdown__options--subitem"
            [ngbPopover]="popoverMonthTmpl"
            *ngFor="let month of option.months; let i = index"
            popoverClass="popover--md"
            placement="bottom"
            triggers="mouseenter:mouseleave"
            (click)="download(month, option.value)"
          >
            <cx-icon type="DownloadIcon"></cx-icon>
            <span class="dropdown__options--margin-15">{{ month.date }} </span>
            <ng-template #popoverMonthTmpl>
              {{ 'statements.downloadText' | cxTranslate }}
            </ng-template>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
<ng-template #popoverTmpl>
  {{ 'statements.tooltipText' | cxTranslate }}
</ng-template>
