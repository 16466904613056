import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { FacetValue } from '@spartacus/core';
import { FacetService } from '@spartacus/storefront';
import { take } from 'rxjs/operators';
import { ProductListService } from 'src/app/services/product-list-page/product-list.service';

@Component({
  selector: 'app-filter-dropdown-selector',
  templateUrl: './filter-dropdown-selector.component.html',
  styleUrls: ['./filter-dropdown-selector.component.scss'],
})
export class FilterDropdownSelectorComponent implements OnInit, OnChanges {
  @HostBinding('class') wrapperClass = 'dropdownHold';
  @Input() list: boolean;
  @Input() dropDownValues: Array<FacetValue>;
  @Input() dropDownHeader = 'Select';
  @Input() dropDownCode = 'noCode';
  @Input() individualOrderDD = false;
  @Output() dropDownSelected = new EventEmitter();
  openDropDown = false;
  disableDropDown = false;
  private query = '';
  private FacetValueMap: Map<string, boolean> = new Map();
  @HostListener('document:click', ['$event'])
  clickout(event): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.openDropDown = false;
    }
  }
  constructor(
    private eRef: ElementRef,
    private route: Router,
    private facetService: FacetService,
    private prdlistService: ProductListService
  ) {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
    const facet: FacetValue = this.dropDownValues && this.dropDownValues.length > 0 ? this.dropDownValues[0] : null;
    if (facet !== null) {
      const allFacets = facet.query.query.value.split(':');
      let index = allFacets.indexOf(this.dropDownCode);
      while (index !== -1) {
        allFacets.splice(index, 2);
        index = allFacets.indexOf(this.dropDownCode);
      }
      this.query = allFacets.join(':');
      this.dropDownValues.forEach((facets: FacetValue) => {
        this.FacetValueMap.set(facets.name, facets.selected);
      });
    }
  }
  showDropDown(): void {
    if (this.disableDropDown) {
      this.openDropDown = false;
      return;
    }
    this.openDropDown = !this.openDropDown;
  }
  displayFilteredValues(option): void {
    this.dropDownSelected.emit(option);
  }
  clear(): void {
    this.navigate();
  }
  save(): void {
    this.FacetValueMap.forEach((value: boolean, key: string) => {
      if (value) {
        this.query = `${this.query}:${this.dropDownCode}:${key}`;
      }
    });
    this.navigate();
  }
  private navigate(): void {
    this.openDropDown = false;
    this.prdlistService
      .getFacetLink()
      .pipe(take(1))
      .subscribe((route: any[]) => {
        this.route.navigate(route, {
          queryParams: this.facetService.getLinkParams(this.query),
        });
      });
  }
  setCheckboxValue(mapValue: { [key: string]: boolean }): void {
    const [[key, value]] = Object.entries(mapValue);
    this.FacetValueMap.set(key, value);
  }
}
