<div class="cart-list-items">
  <container-element [ngSwitch]="orderType">
    <ng-container *ngSwitchCase="cartGroup.VALUE_PACK">
      <app-cart-valuepack-lineitem
        *ngFor="let cartEntry of entries"
        [cartEntry]="cartEntry"
        [showActions]="showActions"
      ></app-cart-valuepack-lineitem>
    </ng-container>
    <ng-container *ngSwitchCase="cartGroup.DIRECT_TO_PATIENT">
      <app-cart-dtp-orders
        *ngFor="let cartEntry of entries"
        [cartEntry]="cartEntry"
        [orderType]="cartGroup.DIRECT_TO_PATIENT"
        [showAddress]="showAddress"
      >
      </app-cart-dtp-orders>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <app-cart-single-line-item
        *ngFor="let cartEntry of entries"
        [enableCounter]="enableCounter"
        [showActions]="showActions"
        [orderType]="orderType"
        [showPatientReference]="showPatientReference"
        [cartEntry]="cartEntry"
      >
      </app-cart-single-line-item>
    </ng-container>
  </container-element>
</div>
