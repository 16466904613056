import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { PageMeta, PageMetaService } from '@spartacus/core';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { getTotalUnitCount } from 'src/app/store/Selectors/cartItem.selector';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit, OnDestroy {
  title$: Observable<string>;
  emptyCartvalue = false;
  private destroy$ = new Subject<void>();
  userInfo;

  constructor(
    private pageMetaService: PageMetaService,
    private store: Store,
    private route: ActivatedRoute,
    private userProfileService: UserProfileFacade
  ) {}
  ngOnInit(): void {
    const { totalUnitCount } = this.route.snapshot.data.vcCart;
    this.emptyCartvalue = totalUnitCount < 0;
    this.title$ = this.pageMetaService.getMeta().pipe(
      filter(Boolean),
      map((meta: PageMeta) => meta.heading || meta.title)
    );
    this.store.pipe(select(getTotalUnitCount), takeUntil(this.destroy$)).subscribe((cartUnitCount: number) => {
      this.emptyCartvalue = cartUnitCount > 0 ? false : true;
    });
    this.userInfo = this.userProfileService.get();
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
