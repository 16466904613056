import { createAction, props } from '@ngrx/store';
import { PatientProfileData } from 'src/app/shared/model/patient.model';
export enum PatientActionTypes {
  loadPatient = '[Patient] Load patient into store',
  updatePatient = '[Patient] Update patient into store',
  deletePatient = '[Patient] Delete patient from store',
  addPatient = '[Patient] Add Patient newly created patient to store',
  clearPatientStore = '[Store] Patient Data Cleared',
  loadFetchOrdersFlag = '[Patient] Load the fetch order flag to store',
}

export const LoadPatient = createAction(
  PatientActionTypes.loadPatient,
  props<{ patients: Array<PatientProfileData> }>()
);

export const UpdatePatient = createAction(
  PatientActionTypes.updatePatient,
  props<{ updatedPatient: PatientProfileData }>()
);
export const AddPatient = createAction(PatientActionTypes.addPatient, props<{ patient: PatientProfileData }>());
export const DeletePatient = createAction(PatientActionTypes.deletePatient, props<{ patientId: string }>());
export const ClearPatientStore = createAction(PatientActionTypes.clearPatientStore);
export const LoadOrderFlag = createAction(
  PatientActionTypes.loadFetchOrdersFlag,
  props<{ fetchPatientOrders: boolean }>()
);
