import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AsyncSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ProductDetailsService } from 'src/app/services/alcon-pdp/product-details.service';
import { ProductErrorsService } from 'src/app/services/product-errors.service';
import { ProductListService } from 'src/app/services/product-list-page/product-list.service';
import { AlconUserPermissionService } from 'src/app/shared-modules/alcon-user-permission-provider/service/alcon-user-permission.service';
import { DIRECT_TO_PRACTICE, EYESIDE, EYESIDE_SHORT_FORM, PACK_SIZE } from 'src/app/shared/constants/constants';
import {
  BasicProductSelectedCheckboxStatus,
  BasicProductSelectedVariants,
  BasicProductSelectorPanelConfig,
  QuantityErrorCodes,
  SelectedVariantDetails,
  SelectedVariantValue,
  VariantTree,
} from 'src/app/shared/model/product.model';
import { Utilities } from 'src/app/shared/utility/utility';
import {
  VisionCareAddtocartProductRequest,
  VisionCareAddtocartProductRequestInfo,
} from 'src/app/store/States/cartItem.state';
import { ProductDetails } from 'src/app/store/States/orderHistory';

@Component({
  selector: 'app-product-selector',
  templateUrl: './product-selector.component.html',
  styleUrls: ['./product-selector.component.scss'],
})
export class ProductSelectorComponent implements OnInit, OnChanges, OnDestroy {
  packSize = [];
  contactLensType = '';
  variantTree!: VariantTree;
  rightVariantTree!: VariantTree;
  leftVariantTree!: VariantTree;
  botheye = false;
  leftEye = false;
  rightEye = false;
  multilinePacksize = false;
  maxQuantity: number;
  product: any;
  selectedPackSize = '';
  varients = {
    basecurve: null,
    diameter: null,
    lenspower: null,
    powerAdd: null,
    color: null,
    cylinder: null,
    axis: null,
    packSize: null,
  } as BasicProductSelectedVariants;
  packStyles = {
    width: '14.0625rem',
    'flex-wrap': 'wrap',
    'row-gap': '0.625rem',
    'justify-content': 'flex-start',
  };
  @Input() isDTP = false;
  @Input() selectedProduct;
  @Input() refreshProductPanel;
  @Input() dtpError: boolean;
  @Input() isValuePack: boolean;
  @Output() selectedProductLensValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() checkboxStatus: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeProductQuantity: EventEmitter<any> = new EventEmitter<any>();
  @Output() Errors: EventEmitter<QuantityErrorCodes | boolean> = new EventEmitter<QuantityErrorCodes | boolean>();
  @Output() changeDtpError: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() disableCheckbox = false;
  @Input() isValuePackSolution = false;
  @Input() editproductData;
  @Input() resetMaxQuantityError?: string;
  @Input() set orderTo(orderTo) {
    if (orderTo === DIRECT_TO_PRACTICE.REGULAR) {
      this.quantity = 1;
      this.maxQuantityError = false;
    }
  }
  disabledPackSizeList: string[];
  disableCounter: boolean;
  disableVarientSelector: boolean;
  disablePackSize: boolean;
  @Input() set disable(value) {
    this.disableCheckbox = value;
    this.disableCounter = value;
    this.disableVarientSelector = value;
    this.disablePackSize = value;
  }
  @Input() set panelConfiguration(config: BasicProductSelectorPanelConfig) {
    this.defaultPanelConfiguration = {
      ...this.defaultPanelConfiguration,
      ...config,
    } as BasicProductSelectorPanelConfig;
  }
  @Input() set errors(flag) {
    this.errMsg = flag;
  }
  private defaultPanelConfiguration: BasicProductSelectorPanelConfig = {
    checkboxStatus: {
      leftEye: true,
      rightEye: true,
      bothEye: false,
    } as BasicProductSelectedCheckboxStatus,
    solutions: {
      multiLine: false,
    },
  };

  private checkboxPanelStatus: BasicProductSelectedCheckboxStatus = {
    leftEye: true,
    rightEye: true,
    bothEye: false,
  };

  quantity = 1;
  selectedVariantDetails;
  public selectedVariantCode = {
    left: {
      quantity: 0,
      isSelected: false,
      details: {
        code: null,
        maxQuantity: undefined,
      } as SelectedVariantDetails,
      eyeSide: 'left',
    } as SelectedVariantValue,
    right: {
      quantity: 0,
      isSelected: false,
      details: {
        code: null,
        maxQuantity: undefined,
      } as SelectedVariantDetails,
      eyeSide: 'right',
    } as SelectedVariantValue,
    solution: {
      quantity: 0,
      isSelected: false,
      details: {
        code: null,
        maxQuantity: undefined,
      } as SelectedVariantDetails,
    } as SelectedVariantValue,
  };

  patientName: string;
  patientId: string;
  solutionProducts = false;
  eyeSide: string;
  dtpLimit: number;
  dtpLeftLimit: number;
  maxQuantityLeftLimit: number;
  maxQuantityRightLimit: number;
  maxQuantityError = false;
  errMsg = '';
  private destroySubscriptions$ = new Subject<void>();
  editrightPanelData: ProductDetails;
  editleftPanelData: ProductDetails;
  isEditPanel = false;
  isFavouritePage$: Observable<boolean> = this.activatedRoute.data.pipe(
    map(({ cxRoute = 'undefined' }) => cxRoute !== 'favouritePage')
  );
  private enableEdit: AsyncSubject<boolean> = new AsyncSubject();
  showHideImgPanel = true;
  trialErrorMsg: string;
  bothEyeErrorMsg = '';
  qtyFactor = 1;
  readonly color = DIRECT_TO_PRACTICE.PRODUCT_COLOR;
  readonly toric = DIRECT_TO_PRACTICE.PRODUCT_TORIC;
  readonly multifocal = DIRECT_TO_PRACTICE.PRODUCT_MULTIFOCAL;
  private destroy$ = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private productService: ProductDetailsService,
    private productListService: ProductListService,
    private cd: ChangeDetectorRef,
    private productErrorsService: ProductErrorsService,
    private userPermissionService: AlconUserPermissionService
  ) {}

  ngOnInit(): void {
    this.enableEdit.pipe(takeUntil(this.destroySubscriptions$)).subscribe((processData) => {
      if (!processData) {
        return;
      }
      if (this.editproductData) {
        this.isEditPanel = true;
        this.leftEye = false;
        this.rightEye = false;
        this.botheye = false;

        if (this.editproductData.length > 1) {
          this.botheye = this.editproductData[0].code === this.editproductData[1].code;
          this.editproductData.forEach((element) => {
            this.editPanelOrder(element);
          });
        } else {
          this.editPanelOrder(this.editproductData);
        }
        this.setEyeSelected();
        this.emitCheckboxStatus();
      }
    });
    this.productErrorsService.productErros.pipe(takeUntil(this.destroy$)).subscribe((err) => {
      err.forEach((er) => {
        if (er.subject) {
          this.setLeftEyeError(er);
        }
      });
      this.productErrorsService.removeProductError();
    });
    this.productService
      .receiveDTPFlag()
      .pipe(takeUntil(this.destroy$))
      .subscribe((isDtp) => {
        this.isDTP = isDtp;
      });
  }

  setLeftEyeError(er): void {
    if (this.selectedVariantCode.left.details.code === er.subject && this.leftEye) {
      if (
        this.botheye ||
        (this.selectedVariantCode.left.details.code === this.selectedVariantCode.right.details.code && this.rightEye)
      ) {
        this.bothEyeErrorMsg = er.message;
      } else {
        this.selectedVariantCode.left.maxQuantityError = er.message;
      }
    } else {
      this.setErrorMessage(er);
    }
  }
  setErrorMessage(er): void {
    switch (er.subject) {
      case this.selectedVariantCode.right.details.code: {
        this.selectedVariantCode.right.maxQuantityError = er.message;
        break;
      }
      case this.selectedVariantCode.solution.details.code: {
        this.selectedVariantCode.solution.maxQuantityError = er.message;
        break;
      }
      case this.selectedProduct: {
        this.trialErrorMsg = er.message;
        break;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedProduct || changes.refreshProductPanel) {
      this.leftEye = this.defaultPanelConfiguration.checkboxStatus.leftEye;
      this.rightEye = this.defaultPanelConfiguration.checkboxStatus.rightEye;
      this.botheye = this.defaultPanelConfiguration.checkboxStatus.bothEye;
      this.multilinePacksize = this.defaultPanelConfiguration.solutions.multiLine;
      this.quantity = 1;
      this.setDtpError(false);
      this.afterViewInit();
    }

    if (changes.isDTP && !changes.isDTP.firstChange) {
      this.validateAddToCart();
    }

    if (this.selectedVariantCode.solution.isSelected && !this.isDTP) {
      this.qtyFactor = this.productService.generateVarientCodes(this.variantTree.childrenNodes, {
        packSize: this.selectedPackSize,
      })?.unitFactor;
      this.quantity = this.editproductData ? this.editproductData.quantity : this.qtyFactor;
      this.validateAddToCart();
    }
  }

  afterViewInit(): void {
    if (this.selectedProduct) {
      this.variantTree = JSON.parse(this.selectedProduct.variantTree);
      this.rightVariantTree = { ...this.variantTree };
      this.leftVariantTree = { ...this.variantTree };
      this.contactLensType = this.selectedProduct.contactLensType;
      this.solutionProducts = this.selectedProduct.isLensCareSolution || this.selectedProduct.isDryEyeSolution;
      const selectedPackSize = Array.isArray(this.editproductData)
        ? this.editproductData[0].packsize
        : this.editproductData
        ? this.editproductData.packsize
        : '';
      this.packSize = Utilities.setSelectedPackSize(this.selectedProduct, selectedPackSize);
      this.maxQuantity = this.selectedProduct.maxPurchaseableQty;
      this.markEmptyErrors();
      this.product = this.selectedProduct;
      this.cd.detectChanges();
    }
  }

  markEmptyErrors(): void {
    this.dtpLimit = undefined;
    this.dtpLeftLimit = undefined;
    this.maxQuantityRightLimit = undefined;
    this.maxQuantityLeftLimit = undefined;
  }

  editPanelOrder(el: ProductDetails): void {
    this.quantity = el.quantity;
    if (el.eyeSight?.toUpperCase() === EYESIDE_SHORT_FORM.RIGHT) {
      this.rightEye = true;
      this.editrightPanelData = el;
      this.setCode(el.code, 'right');
    } else if (el.eyeSight?.toUpperCase() === EYESIDE_SHORT_FORM.LEFT) {
      this.leftEye = true;
      this.editleftPanelData = el;
      this.setCode(el.code, 'left');
    }
  }
  markSelectorsEmpty(): void {
    this.selectedVariantCode.left.maxQuantityError = '';
    this.selectedVariantCode.right.maxQuantityError = '';
    this.selectedVariantCode.solution.maxQuantityError = '';
    this.trialErrorMsg = '';
    this.bothEyeErrorMsg = '';
    this.cd.detectChanges();
  }
  changePackSize(value): void {
    this.selectedPackSize = value;
    if (value !== PACK_SIZE.NINETY) {
      this.productService.getMaterialGroupPrice(
        value,
        this.selectedProduct?.packSizePricingMap,
        this.selectedProduct.code
      );
    }
    this.varients.packSize = value;
    const isStoped = this.editproductData ? this.enableEdit.isStopped : true;
    this.enableEdit.next(!!this.editPanelOrder);
    this.enableEdit.complete();
    this.markEmptyErrors();
    if (this.solutionProducts) {
      let selectedPack;
      selectedPack = this.packSize.filter((data) => {
        if (value === data.value) {
          return data;
        }
      });
      const selectedVariants = { packSize: selectedPack[0].value };
      const skuDetails = this.productService.generateVarientCodes(this.variantTree.childrenNodes, selectedVariants);
      this.dtpLimit = skuDetails.dtpMaxLimit;
      this.qtyFactor = skuDetails.unitFactor ?? 1;
      this.quantity = this.editproductData ? this.editproductData.quantity : this.qtyFactor;
      this.maxQuantity = Number(skuDetails?.maxQuantity);
      this.maxQuantityRightLimit = Number(skuDetails?.maxQuantity);
      this.selectedVariantCode.solution.details.code = skuDetails.code?.toString();
      this.selectedVariantCode.solution.isSelected = true;
      if (isStoped) {
        this.validateAddToCart();
      }
    } else if (isStoped) {
      this.selectedProductLensValue.emit([]);
    }
    this.emitProductCalculatedQuantity();
    this.cd.detectChanges();
  }
  setBothEye(): void {
    if (this.botheye) {
      this.leftEye = true;
      this.rightEye = true;
      this.emitCheckboxStatus();
      this.setEyeSelected();
      this.selectedVariantCode.left.details = { ...this.selectedVariantCode.right.details } as SelectedVariantDetails;
      this.validateAddToCart();
    } else {
      this.emitCheckboxStatus();
      this.setEyeSelected();
      this.selectedVariantCode.left.details = {
        code: null,
        maxQuantity: undefined,
      } as SelectedVariantDetails;
      this.leftVariantTree = { ...this.rightVariantTree };
      this.validateAddToCart();
    }
  }
  setIndividualEye(): void {
    if (!this.rightEye && !this.leftEye) {
      this.maxQuantity = 0;
      this.maxQuantityError = false;
      this.markEmptyErrors();
    }
    this.maxQuantityRightLimit = this.selectedVariantCode?.right?.details?.maxQuantity;
    this.maxQuantityLeftLimit = this.selectedVariantCode?.left?.details?.maxQuantity;

    if (this.rightEye) {
      this.maxQuantity = this.maxQuantityRightLimit;
    } else if (this.leftEye) {
      this.maxQuantity = this.maxQuantityLeftLimit;
    }
    this.emitCheckboxStatus();
    this.setEyeSelected();
    this.validateAddToCart();
  }

  emitCheckboxStatus(): void {
    this.checkboxPanelStatus.leftEye = this.leftEye;
    this.checkboxPanelStatus.rightEye = this.rightEye;
    this.checkboxPanelStatus.bothEye = this.botheye;
    this.checkboxStatus.emit(this.checkboxPanelStatus);
  }

  setProductQuantity(value): void {
    this.quantity = value;
    this.emitProductCalculatedQuantity();
    value > 0 ? this.validateAddToCart() : this.selectedProductLensValue.emit([]);
  }

  emitProductCalculatedQuantity(): void {
    if (!this.isValuePack) {
      let quantity = this.quantity;
      if (this.leftEye && this.rightEye) {
        quantity = this.quantity * 2;
      }
      this.changeProductQuantity.emit(quantity);
    }
  }

  private setCode(productcode, eyeSide): void {
    this.selectedVariantCode[eyeSide].details.code = productcode;
    this.selectedVariantCode[eyeSide].eyeSide = eyeSide;
  }

  selectedCode(skuDetails, eyeSide): void {
    if (skuDetails) {
      this.maxQuantity = Number(skuDetails?.maxQuantity);
      this.setEyeSelected();
      this.selectedVariantCode[eyeSide].details.code = skuDetails?.code;
      if (this.botheye) {
        this.selectedVariantCode.left.details = { ...this.selectedVariantCode.right.details } as SelectedVariantDetails;
      }
      this.assignQuantitylimits(skuDetails, eyeSide);
      this.getPriceInfoForNinetyPack();
      this.validateAddToCart();
    } else {
      if (this.selectedPackSize === PACK_SIZE.NINETY) {
        this.emitProductCalculatedQuantity();
        this.productService.emitPrice(this.selectedProduct.code, null);
      }
      this.maxQuantityError = false;
      this.markSelectorsEmpty();
    }
  }
  getPriceInfoForNinetyPack(): void {
    if (
      this.selectedPackSize === PACK_SIZE.NINETY &&
      (this.selectedVariantCode.right.isSelected || this.selectedVariantCode.left.isSelected)
    ) {
      const variantCode =
        this.selectedVariantCode.right.isSelected && this.selectedVariantCode.right.details?.code
          ? this.selectedVariantCode.right.details?.code
          : this.selectedVariantCode.left.details?.code;
      this.productService
        .getMaterialPrice(String(variantCode))
        .pipe(takeUntil(this.destroy$))
        .subscribe((response) => {
          if (response?.formattedValue) {
            this.productService.emitPrice(this.selectedProduct.code, response);
            this.emitProductCalculatedQuantity();
          } else {
            this.productService.emitPrice(this.selectedProduct.code, null);
          }
        });
    }
  }

  checkDTPmaxCount(skuDetails, eyeSide): void {
    if (skuDetails) {
      this.maxQuantity = Number(skuDetails?.maxQuantity);
    }
    this.assignQuantitylimits(skuDetails, eyeSide);
  }
  assignQuantitylimits(skuDetails, eyeSide): void {
    if (eyeSide.toLowerCase() === 'right' && skuDetails) {
      this.maxQuantityRightLimit = Number(skuDetails?.maxQuantity);
      this.selectedVariantCode.right.details.maxQuantity = Number(skuDetails?.maxQuantity);
      this.dtpLimit = Number(skuDetails.dtpMaxLimit);
    }
    if (eyeSide.toLowerCase() === 'left' && skuDetails) {
      this.maxQuantityLeftLimit = Number(skuDetails?.maxQuantity);
      this.selectedVariantCode.left.details.maxQuantity = Number(skuDetails?.maxQuantity);
      this.dtpLeftLimit = Number(skuDetails.dtpMaxLimit);
    }
  }
  private setEyeSelected(): void {
    this.selectedVariantCode.left.isSelected = this.leftEye;
    this.selectedVariantCode.right.isSelected = this.rightEye;
    this.emitProductCalculatedQuantity();
  }

  private disableProduct(): void {
    this.setDtpError(true);
    this.maxQuantityError = false;
    this.Errors.emit(QuantityErrorCodes.DTP_MAX_QUANTITY);
    this.selectedProductLensValue.emit([...[]]);
  }

  private quantityValidate(rightLimit, leftLimit): number {
    let limit = 0;
    if (rightLimit >= 0 || leftLimit >= 0) {
      if (this.solutionProducts) {
        limit = Math.round(rightLimit);
      } else if (this.botheye && !isNaN(rightLimit)) {
        limit = Math.round(rightLimit);
      } else if (this.leftEye && this.rightEye && !isNaN(rightLimit) && !isNaN(leftLimit)) {
        limit = Math.min(rightLimit, leftLimit);
      } else if (this.rightEye && !isNaN(rightLimit)) {
        limit = Math.round(rightLimit);
      } else if (this.leftEye && !isNaN(leftLimit)) {
        limit = Math.round(leftLimit);
      }
      return limit;
    }
  }
  private validateAddToCart(): void {
    this.markSelectorsEmpty();
    let limit = 0;
    const updateVariantsDetails: Array<SelectedVariantValue> = [];

    if (this.selectedVariantCode.left.isSelected) {
      updateVariantsDetails.push({ ...this.selectedVariantCode.left });
    }
    if (this.selectedVariantCode.right.isSelected) {
      updateVariantsDetails.push({ ...this.selectedVariantCode.right });
    }
    if (this.selectedVariantCode.solution.isSelected) {
      updateVariantsDetails.push({ ...this.selectedVariantCode.solution });
    }

    if (this.isDTP && this.selectedVariantCode.solution.isSelected) {
      this.qtyFactor = 1;
    } else if (this.selectedVariantCode.solution.isSelected) {
      this.qtyFactor = this.productService.generateVarientCodes(this.variantTree.childrenNodes, {
        packSize: this.selectedPackSize,
      })?.unitFactor;
    }
    const tempSelectedVariants = updateVariantsDetails.filter(
      (variantDetailsType) => variantDetailsType.details.code && variantDetailsType.isSelected
    );
    if (tempSelectedVariants.length) {
      if (this.isDTP) {
        limit = this.quantityValidate(this.dtpLimit, this.dtpLeftLimit);
      } else {
        limit = this.quantityValidate(this.maxQuantityRightLimit, this.maxQuantityLeftLimit);
      }

      if (this.isDTP && limit >= 0 && this.quantity > limit) {
        this.disableProduct();
        return;
      }
      if (!this.isDTP && limit >= 0 && this.quantity > limit) {
        this.Errors.emit(QuantityErrorCodes.MAX_QUANTITY);
        this.selectedProductLensValue.emit([...[]]);
        this.maxQuantityError = true;
        this.cd.detectChanges();
        this.setDtpError(false);
        return;
      }
    } else {
      this.maxQuantityError = false;
    }

    let lensSelectedValues = updateVariantsDetails
      .filter((power) => {
        return power.isSelected && power.details.code !== null && power.details.code !== undefined;
      })
      .map((lensPower) => {
        const productData: Array<VisionCareAddtocartProductRequest> | VisionCareAddtocartProductRequest = {
          product: { code: lensPower.details.code } as VisionCareAddtocartProductRequestInfo,
          quantity: this.quantity?.toString(),
          orderEntryFlowType: this.isValuePack ? DIRECT_TO_PRACTICE.VALUE_PACK : DIRECT_TO_PRACTICE.REGULAR,
          eyeSight: lensPower.eyeSide ? (lensPower.eyeSide === EYESIDE.RIGHT ? 'R' : 'L') : '',
        } as VisionCareAddtocartProductRequest;
        return productData;
      });
    if (updateVariantsDetails.filter((power) => power.isSelected).length !== lensSelectedValues.length) {
      lensSelectedValues = [];
    }
    this.Errors.emit(false);
    this.selectedProductLensValue.emit([...lensSelectedValues]);
    this.setDtpError(false);
    this.maxQuantityError = false;
    this.errMsg = '';
  }

  setDtpError(error: boolean): void {
    this.dtpError = error;
    this.changeDtpError.emit(error);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
