import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-order-nav',
  templateUrl: './new-order-nav.component.html',
  styleUrls: ['./new-order-nav.component.scss'],
})
export class NewOrderNavComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
