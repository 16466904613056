import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthService, ConsentTemplate } from '@spartacus/core';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { LegalAnnouncementService } from './legal-announcement.service';

@Injectable({
  providedIn: 'root',
})
export class LegalGuard implements CanActivate {
  constructor(
    private legalAnnouncementService: LegalAnnouncementService,
    private router: Router,
    private authService: AuthService
  ) {}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean> | boolean | UrlTree {
    return this.authService.isUserLoggedIn().pipe(
      filter((isLoggedIn) => isLoggedIn),
      switchMap((isLoggedIn) =>
        this.legalAnnouncementService.getTemplates().pipe(
          take(1),
          map((templates: Array<ConsentTemplate>) => {
            if (templates?.length) {
              if (templates.every((template) => template.hasOwnProperty('currentConsent'))) {
                return true;
              } else {
                return this.router.parseUrl('/legalannouncement');
              }
            } else {
              // this is the case when legal announcement are not applicable for site
              return true;
            }
          })
        )
      )
    );
  }
}
