<div class="alcon-cart-summary">
  <div class="alcon-cart-summary__heading">
    <h4 class="alcon-cart-summary__heading--text">{{ 'cartPage.summary' | cxTranslate }}</h4>
  </div>
  <div class="alcon-cart-summary__content">
    <div
      *ngIf="(individualOrderInfo$ | async)?.orderCount"
      class="alcon-cart-summary__item"
      [ngClass]="{ borderbottom: !isCartPage }"
    >
      <div class="alcon-cart-summary__item--text">
        {{ 'cartPagev2.INDIVIDUAL_ORDER' | cxTranslate }}
        <span>({{ (individualOrderInfo$ | async)?.orderCount }})</span>
      </div>
      <div
        *ngIf="isPriceVisible && isIndividualOrderPrice && (summaryPrices$ | async)?.calculated; else hyphen"
        class="alcon-cart-summary__item--value"
      >
        {{ (individualOrderInfo$ | async)?.totalPriceinfo.formattedValue }}
      </div>
      <ng-template #hyphen>-</ng-template>
    </div>
    <div
      *ngIf="(valuePacksInfo$ | async)?.orderCount"
      class="alcon-cart-summary__item"
      [ngClass]="{ borderbottom: !isCartPage }"
    >
      <div class="alcon-cart-summary__item--text">
        {{ 'cartPagev2.VALUE_PACK' | cxTranslate }}
        <span>({{ (valuePacksInfo$ | async)?.orderCount }})</span>
      </div>
      <div
        *ngIf="isPriceVisible && isValuePackPrice && (summaryPrices$ | async)?.calculated; else hyphen"
        class="alcon-cart-summary__item--value"
      >
        {{ (valuePacksInfo$ | async)?.totalPriceinfo.formattedValue }}
      </div>
      <ng-template #hyphen>-</ng-template>
    </div>
    <div
      *ngIf="(stockOrderInfo$ | async)?.orderCount"
      class="alcon-cart-summary__item"
      [ngClass]="{ borderbottom: !isCartPage }"
    >
      <div class="alcon-cart-summary__item--text">
        {{ 'cartPagev2.STOCK_ORDER' | cxTranslate }}
        <span>({{ (stockOrderInfo$ | async)?.orderCount }})</span>
      </div>
      <div
        *ngIf="isPriceVisible && isStockOrderPrice && (summaryPrices$ | async)?.calculated; else hyphen"
        class="alcon-cart-summary__item--value"
      >
        {{ (stockOrderInfo$ | async)?.totalPriceinfo.formattedValue }}
      </div>
      <ng-template #hyphen>-</ng-template>
    </div>
    <ng-container *ngIf="isCartPage">
      <ng-container *ngTemplateOutlet="dtp"></ng-container>
    </ng-container>
  </div>

  <div class="alcon-cart-summary__content">
    <div *ngIf="isCheckoutPage && (isOnlyDTPOrder | async) === false" class="alcon-cart-summary__item">
      <div class="alcon-cart-summary__item--text font-weight-bold">{{ 'cartPagev2.deliveryCost' | cxTranslate }}</div>
      <div class="alcon-cart-summary__item--value">
        {{
          (summaryPrices$ | async)?.calculated && !isSimulationException && isPriceVisible
            ? (summaryPrices$ | async)?.deliveryCost.formattedValue
            : '-'
        }}
      </div>
    </div>
    <div *ngIf="isCheckoutPage && (isOnlyDTPOrder | async) === false" class="alcon-cart-summary__item borderbottom">
      <div class="alcon-cart-summary__item--text font-weight-bold">{{ 'cartPagev2.taxes' | cxTranslate }}</div>
      <div class="alcon-cart-summary__item--value">
        {{
          (summaryPrices$ | async)?.calculated && !isSimulationException && isPriceVisible
            ? (summaryPrices$ | async)?.totalTax.formattedValue
            : '-'
        }}
      </div>
    </div>
    <hr *ngIf="isCartPage" class="alcon-cart-summary__hor-line" />

    <div *ngIf="isCheckoutPage && (isOnlyDTPOrder | async) === false" class="alcon-cart-summary__item d-flex">
      <div class="alcon-cart-summary__item--text font-weight-bold">{{ 'cartPagev2.total' | cxTranslate }}:</div>
      <div class="alcon-cart-summary__item--value">
        {{
          (summaryPrices$ | async)?.calculated && !isSimulationException && isPriceVisible && (showTotalPrice | async)
            ? (summaryPrices$ | async)?.totalPrice.formattedValue
            : '-'
        }}
      </div>
    </div>
    <div *ngIf="isCartPage" class="alcon-cart-summary__item">
      <div class="alcon-cart-summary__item--text font-weight-bold">
        {{ 'cartPagev2.estimatedTotalPrice' | cxTranslate }}
      </div>
      <div class="alcon-cart-summary__item--value">
        <span *ngIf="isPriceVisible && (showTotalPrice | async); else hyphen">{{
          (summaryPrices$ | async)?.subTotal.formattedValue
        }}</span>
        <ng-template #hyphen>-</ng-template>
      </div>
    </div>

    <ng-container *ngIf="!isCartPage">
      <ng-container *ngTemplateOutlet="dtp"></ng-container>
    </ng-container>

    <ng-template #dtp>
      <div
        *ngIf="(dtpInfo$ | async)?.orderCount"
        class="alcon-cart-summary__item"
        [ngClass]="{ borderbottom: !isCartPage }"
      >
        <div class="alcon-cart-summary__item--text">
          {{ 'cartPagev2.DIRECT_TO_PATIENT' | cxTranslate }}
          <span>({{ (dtpInfo$ | async)?.orderCount }})</span><span *ngIf="!isCartPage">*</span>
        </div>
        <div
          *ngIf="isPriceVisible && isDirectPatientOrderPrice && (summaryPrices$ | async)?.calculated; else hyphen"
          class="alcon-cart-summary__item--value"
        >
          {{ (dtpInfo$ | async)?.totalPriceinfo.formattedValue }}
        </div>
        <ng-template #hyphen>-</ng-template>
      </div>
    </ng-template>

    <div *ngIf="!isCartPage">
      <p class="alcon-cart-summary__suggestion">{{ 'cartPagev2.warningOnCheckout' | cxTranslate }}</p>
    </div>
  </div>

  <div *ngIf="!isCartPage" class="d-flex flex-column align-items-center">
    <app-alcon-terms-conditions (emitCheckBox)="getSelectedCheckBox($event)"></app-alcon-terms-conditions>
    <button
      *ngIf="!isConfirmationPage"
      class="btn btn-primary alcon-cart-summary__button"
      (click)="placeOrder()"
      [disabled]="!enableOrderPay"
    >
      {{ 'cartPagev2.orderAndPay' | cxTranslate }}
    </button>
  </div>

  <div *ngIf="isCartPage" class="d-flex flex-column align-items-center">
    <button
      class="btn btn-primary alcon-cart-summary__button"
      [disabled]="(cartError$ | async) || isMaxPurchaseError"
      [routerLink]="{ cxRoute: 'checkout' } | cxUrl"
    >
      {{ 'cartPage.goToCheckout' | cxTranslate }}
    </button>
    <p class="alcon-cart-summary__suggestion text-center">
      {{ 'cartPage.shippingsuggestion' | cxTranslate }}
    </p>
  </div>
</div>
<app-loader-icon *ngIf="showLoader"></app-loader-icon>
