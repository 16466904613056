import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductSearchService } from '@spartacus/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ProductDetailsService } from 'src/app/services/alcon-pdp/product-details.service';
import { OrderHistoryService } from 'src/app/services/order-history/order-history.service';
import { Category } from 'src/app/shared/constants/constants';
import { DropdownConfig, DropdownOptions } from 'src/app/shared/model/common.mode';
import {
  BasicProductSelectedCheckboxStatus,
  BasicProductSelectorPanelConfig,
  QuantityErrorCodes,
} from 'src/app/shared/model/product.model';
import {
  ContactLensSolutionType,
  VisionCareAddtocartProductRequest,
  VisionCareAddtocartProductRequestInfo,
} from 'src/app/store/States/cartItem.state';
import { PatientOrderData, ProductDetails } from 'src/app/store/States/orderHistory';
import { SolutionProductsComponent } from '../../solution-products/solution-products.component';

declare module 'src/app/shared/model/common.mode' {
  interface DropdownOptions {
    valuePackNodeId?: number;
    isDTPEnabled?: boolean;
  }
}
@Component({
  selector: 'app-neworder-regular-pack',
  templateUrl: './neworder-regular-pack.component.html',
  styleUrls: ['./neworder-regular-pack.component.scss'],
})
export class NeworderRegularPackComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  patientID;
  radioPacktype = true;
  selectedValuePack: string;
  productsList: DropdownOptions[];
  clcList: DropdownOptions[] = [];
  dryeyeList: DropdownOptions[] = [];
  valuePackInfo;
  secondProductsList: DropdownOptions[] = [];
  firstproductsList: DropdownOptions[] = [];

  lensesLabel: DropdownConfig = {
    defaultSelectText: 'Select',
  };
  resetDropDown: boolean;
  private destroySubscriptions$ = new Subject<void>();
  firstProduct: DropdownOptions;
  secondProduct: DropdownOptions;
  selectedProduct: DropdownOptions;
  selectedProductValue: Array<VisionCareAddtocartProductRequest> = [];
  selectedSolutionsValue: Array<Array<VisionCareAddtocartProductRequest>> = [[]];
  selectedContactLensValue: Array<Array<VisionCareAddtocartProductRequest>> = [[]];
  checkBoxStatus: BasicProductSelectedCheckboxStatus;
  isValuePack: boolean;
  firstValuePackProduct: DropdownOptions;
  valuePackVariantTree: any;
  valuePackGroupOneChildProducts: any;
  selectedSolution: DropdownOptions;
  disableAddLens = true;
  addLens = false;
  @Input() isDTP = false;
  private panelErrors: Array<{ baseProductCode: string; isDTPError: boolean; isMaxQuantityError: boolean }> = [];
  panelConfiguration: BasicProductSelectorPanelConfig = {
    checkboxStatus: {
      leftEye: false,
      rightEye: false,
      bothEye: false,
    } as BasicProductSelectedCheckboxStatus,
    solutions: {
      multiLine: false,
    },
  };

  disableCheckbox = false;

  private SolutionsPanel: Array<SolutionProductsComponent> = [];
  private displaySolutionPanel: BehaviorSubject<boolean> = new BehaviorSubject(false);

  valuePackpPanelConfiguration: BasicProductSelectorPanelConfig;
  searchResultCategories = [Category.CONTACT_LENSES, Category.CONTACT_LENS_SOLUTION, Category.DRY_EYE_PRODUCTS];

  @ViewChild('solutiontemplate', { read: ViewContainerRef, static: false }) solutiontemplate: ViewContainerRef;

  editSolutions = [];
  editlenses = [];
  replenishmentOrderCode: string;
  @Input() scheduledOrderList = [];
  scheduledOrderData = {} as PatientOrderData;
  selectedOrderType: string;
  solutionsData = {} as { key: DropdownOptions[] };

  packValue;
  isEditSubscriprionPanel = false;

  @Output() selectedProductLensValues: EventEmitter<any> = new EventEmitter();
  @Output() isDTPErrorFlag: EventEmitter<boolean> = new EventEmitter();

  get isDTPError(): boolean {
    const isError = !!this.panelErrors.find((errorPanel) => errorPanel.isDTPError);
    return isError;
  }
  get isMaxQuantityError(): boolean {
    const isError = !!this.panelErrors.find((errorPanel) => errorPanel.isMaxQuantityError);
    return isError;
  }
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private activatedRoute: ActivatedRoute,
    protected productSearchService: ProductSearchService,
    private cd: ChangeDetectorRef,
    private orderService: OrderHistoryService,
    private productService: ProductDetailsService
  ) {}

  ngOnInit(): void {
    this.patientID = this.activatedRoute.snapshot.data.newOrderPatientInfo.patientId;
    const editPaneldata = this.activatedRoute.snapshot.data.editScheduledOrdersInfo;
    if (editPaneldata) {
      this.isEditSubscriprionPanel = true;
      this.radioPacktype = !(this.scheduledOrderList[0].packType === 'Value Pack');
      this.isValuePack = this.scheduledOrderList[0].packType === 'Value Pack';
      this.scheduledOrderList[0].productDetails.forEach((product) => {
        if (product.isDryEye || product.isClc) {
          this.editSolutions.push(product);
        } else {
          this.editlenses.push(product);
        }
      });
      this.editlenses.sort((a, b) => {
        return a.entryNumber - b.entryNumber;
      });
    }

    this.selectedContactLensValue[0] = [];
    this.productSearchResult(this.searchResultCategories[0]);

    if (this.radioPacktype) {
      this.productSearchService
        .getResults()
        .pipe(
          filter((results) => !!results.products),
          takeUntil(this.destroySubscriptions$)
        )
        .subscribe((results) => {
          const selectedOptions = results.products?.map((productsdata: any) => {
            return {
              text: productsdata.name,
              value: productsdata.code,
              selected: false,
            } as DropdownOptions;
          });
          switch (results.categoryCode) {
            case Category.CONTACT_LENSES: {
              this.productsList = selectedOptions;

              this.firstproductsList = this.productsList.map((prd) => {
                const product = { ...prd };
                product.selected = false;

                if (this.scheduledOrderList.length > 0 && this.editlenses.length > 0) {
                  product.selected = this.editlenses && prd.value === this.editlenses[0].productCode;
                }
                return product as DropdownOptions;
              });
              if (this.scheduledOrderList.length > 0) {
                if (this.editlenses.length > 1 && this.editlenses[0].productCode === this.editlenses[1].productCode) {
                  this.editlenses[0] = [this.editlenses[0], this.editlenses[1]];
                  this.editlenses.pop();
                }
                this.firstProductDrpdwnSelected(
                  this.firstproductsList.find((prd) => prd.selected),
                  false
                );
                if (this.editlenses.length > 0) {
                  this.setSelectedProductLensValue(this.editlenses[0], 0);
                }
                if (this.editlenses.length > 1) {
                  this.addLens = true;
                  this.secondProductsList.forEach((prd) => {
                    prd.selected = this.editlenses && prd.value === this.editlenses[1].productCode;
                  });
                  this.secondProductDrpdwnSelected(
                    this.secondProductsList.find((prd) => prd.selected),
                    false
                  );
                  this.setSelectedProductLensValue(this.editlenses[1], 1);
                }
              }
              break;
            }
            case Category.DRY_EYE_PRODUCTS: {
              this.dryeyeList = [...selectedOptions];
              this.productSearchService.clearResults();
              this.displaySolutionPanel.next(true);
              break;
            }
            case Category.CONTACT_LENS_SOLUTION: {
              this.clcList = [...selectedOptions];
              break;
            }
          }
          const index = this.searchResultCategories.indexOf(results.categoryCode);
          if (index < this.searchResultCategories.length - 1) {
            this.productSearchResult(this.searchResultCategories[index + 1]);
          }
        });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isDTP && !changes.isDTP.firstChange) {
      if (this.SolutionsPanel.length) {
        this.SolutionsPanel.forEach((panel) => {
          panel.isDTP = this.isDTP;
        });
      }
    }
  }

  ngAfterViewInit(): void {
    this.displaySolutionPanel.pipe(takeUntil(this.destroySubscriptions$)).subscribe((displayValue) => {
      if (displayValue) {
        this.editSolutions.length > 0 ? this.addSolutionPanel() : this.createPanel();
        this.cd.detectChanges();
      }
    });
  }

  productSearchResult(val?): void {
    const query = ':relevance:allCategories:' + val;
    this.productSearchService.search(query, { pageSize: 10000 });
  }

  firstProductDrpdwnSelected(product: DropdownOptions, resetDropDown: boolean = true): void {
    this.disableAddLens = true;
    this.disableCheckbox = false;
    if (resetDropDown) {
      this.editlenses[0] = null;
    }
    this.removePanelErrors(this.firstProduct?.value);
    this.productService.removeAddedItem(this.firstProduct?.value);
    this.firstProduct = null;
    this.cd.detectChanges();
    this.firstProduct = product;
    this.secondProductsList = this.productsList.filter((val) => val.text !== this.firstProduct?.text);
    this.secondProductsList.forEach((prd) => {
      prd.selected = this.secondProduct && prd.value === this.secondProduct.value;
    });
    if (resetDropDown) {
      this.selectedContactLensValue[0] = [];
      this.updateSelectedProductValue();
    }
  }
  secondProductDrpdwnSelected(product: DropdownOptions, resetDropDown: boolean = true): void {
    this.removePanelErrors(this.secondProduct?.value);
    this.productService.removeAddedItem(this.secondProduct?.value);
    this.secondProduct = null;
    this.cd.detectChanges();
    this.secondProduct = product;
    this.firstproductsList = this.productsList.filter((val) => val.text !== this.secondProduct.text);
    this.firstproductsList.forEach((prd) => {
      prd.selected = this.firstProduct && prd.value === this.firstProduct.value;
    });
    if (resetDropDown) {
      this.selectedContactLensValue[1] = [];
      this.updateSelectedProductValue();
    }
  }

  selectedProductLensValue(val, index): void {
    this.selectedContactLensValue[index] = val;
    this.updateSelectedProductValue();
  }
  private setSelectedProductLensValue(val, index): void {
    if (val.length > 1) {
      this.selectedContactLensValue[index] = val.map((prd) => this.createProductRequest(prd));
    } else {
      this.selectedContactLensValue[index] = [this.createProductRequest(val)];
    }
  }

  private createProductRequest(prd: ProductDetails): VisionCareAddtocartProductRequest {
    return {
      product: { code: prd.code } as VisionCareAddtocartProductRequestInfo,
      quantity: prd.quantity.toString(),
      orderEntryFlowType: prd.orderEntryFlowType,
      eyeSight: prd.eyeSight,
    } as VisionCareAddtocartProductRequest;
  }

  checkboxStatusChange(val: BasicProductSelectedCheckboxStatus, updateCheckboxStatus): void {
    if (updateCheckboxStatus) {
      this.checkBoxStatus = val;
      this.disableAddLens = !((val.leftEye && !val.rightEye) || (val.rightEye && !val.leftEye));
    } else {
      this.panelConfiguration.checkboxStatus = { ...val };
    }
  }

  updateSelectedProductValue(): void {
    const selectedValues = this.selectedContactLensValue.reduce((a, b) => [...a, ...b], []);
    if (selectedValues.length < this.selectedContactLensValue.length) {
      if (this.isEditSubscriprionPanel) {
        this.selectedProductValue =
          this.editlenses[0] === null && selectedValues.length === 1 ? [...selectedValues] : [];
      } else {
        this.selectedProductValue = [];
      }
      this.selectedProductLensValues.emit(this.selectedProductValue);
      return;
    }
    const solutionValues = this.selectedSolutionsValue.reduce((a, b) => [...a, ...b], []);
    if (!this.isDTPError && !this.isMaxQuantityError) {
      this.selectedProductValue = [...selectedValues, ...solutionValues];
    } else {
      this.selectedProductValue = [];
      this.selectedProductLensValues.emit(this.selectedProductValue);
      return;
    }
    this.selectedProductValue = [...this.selectedProductValue];
    this.selectedProductLensValues.emit(this.selectedProductValue);
  }

  addotherLens(): void {
    this.selectedContactLensValue[1] = [];
    this.addLens = true;
    this.panelConfiguration.checkboxStatus.bothEye = this.checkBoxStatus.bothEye;
    this.panelConfiguration.checkboxStatus.leftEye = !this.checkBoxStatus.leftEye;
    this.panelConfiguration.checkboxStatus.rightEye = !this.checkBoxStatus.rightEye;
    this.disableCheckbox = true;
    this.updateSelectedProductValue();
  }

  deleteAddedItem(): void {
    this.firstproductsList = [...this.productsList];
    this.firstproductsList.forEach((prd) => {
      prd.selected = this.firstProduct && prd.value === this.firstProduct.value;
    });
    this.addLens = false;
    this.removePanelErrors(this.secondProduct?.value);
    this.secondProduct = null;
    this.disableCheckbox = false;
    if (this.selectedContactLensValue.length > 1) {
      this.selectedContactLensValue.pop();
    }
    this.updateSelectedProductValue();
  }

  ngOnDestroy(): void {
    this.destroySubscriptions$.next();
    this.destroySubscriptions$.complete();
  }
  setToDefaults(): void {
    this.resetDropDown = true;
    this.firstproductsList = [...this.productsList];
    this.firstproductsList?.forEach((option) => {
      if (option.selected) {
        option.selected = false;
      }
    });
    this.firstProduct = null;
    this.secondProduct = null;
    this.addLens = false;
    this.isValuePack = false;
    this.radioPacktype = true;
    this.resetAdditionalControls();
  }

  private resetAdditionalControls(): void {
    this.selectedProductValue = [];
    this.cd.detectChanges();
    if (!this.isValuePack) {
      this.solutiontemplate.clear();
      this.SolutionsPanel = [];
      this.selectedSolutionsValue = [[]];
      this.displaySolutionPanel.next(true);
    }
  }

  packTypeChanged(value): void {
    this.isValuePack = !value;
    this.firstProduct = undefined;
    this.secondProduct = undefined;
    this.addLens = false;
    // this.resetAdditionalControls();
  }

  addSolutionPanel(): void {
    this.editSolutions.forEach((solutions, index) => {
      this.createPanel();
      this.SolutionsPanel[index].clcList = [...this.clcList].map(
        (prd) =>
          ({
            ...prd,
            selected: prd.value === solutions.productCode,
          } as DropdownOptions)
      );
      this.SolutionsPanel[index].dryeyeList = [...this.dryeyeList].map(
        (prd) =>
          ({
            ...prd,
            selected: prd.value === solutions.productCode,
          } as DropdownOptions)
      );
      this.SolutionsPanel[index].editSolutionData = solutions;
      this.SolutionsPanel[index].radioAdditionalProducts = solutions.isClc
        ? ContactLensSolutionType.ContactLensSolution
        : ContactLensSolutionType.DryEyeProducts;
      this.SolutionsPanel[index].solProducts = {
        selected: true,
        value: solutions.productCode,
        text: solutions.productCode,
      } as DropdownOptions;
      this.selectedSolutionsValue[index] = [this.createProductRequest(solutions)];
    });
  }

  private createPanel(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SolutionProductsComponent);
    const componentRef = this.solutiontemplate.createComponent<SolutionProductsComponent>(componentFactory);
    componentRef.instance.clcList = [...this.clcList];
    componentRef.instance.dryeyeList = [...this.dryeyeList];
    componentRef.instance.index = this.SolutionsPanel.length;
    componentRef.instance.isDTP = this.isDTP;
    componentRef.instance.selectedProductCode
      .pipe(takeUntil(this.destroySubscriptions$))
      .subscribe(({ value, index }) => {
        this.selectedSolutionLensValue(value, index);
      });
    componentRef.instance.selectedSolutionErrorStatus
      .pipe(takeUntil(this.destroySubscriptions$))
      .subscribe(([value, productCode]) => {
        this.setPanelErrors(value, productCode);
      });
    componentRef.instance.productChanged.pipe(takeUntil(this.destroySubscriptions$)).subscribe((productcode) => {
      this.removePanelErrors(productcode);
    });
    componentRef.instance.deleteSolutions.pipe(takeUntil(this.destroySubscriptions$)).subscribe((index) => {
      const selectedSols = [...this.selectedSolutionsValue];
      selectedSols.splice(index, 1);
      if (selectedSols.length === 0) {
        selectedSols.push([]);
      }
      this.selectedSolutionsValue = [...selectedSols];
      this.updateSelectedProductValue();
      this.solutiontemplate.remove(index);
      this.SolutionsPanel.splice(index, 1);
      this.SolutionsPanel.forEach((component, i) => {
        component.index = i;
      });
      if (this.SolutionsPanel.length === 0) {
        this.createPanel();
      }
      this.panelSelections();
    });
    this.SolutionsPanel.push(componentRef.instance);
    this.panelSelections();
  }

  panelSelections(): void {
    let productSel = '';
    this.SolutionsPanel.forEach((panel, i) => {
      panel.disableRadio = false;
      if (this.SolutionsPanel.length > 1) {
        if (i === 0) {
          productSel = panel.radioAdditionalProducts;
        } else {
          panel.radioAdditionalProducts =
            productSel === ContactLensSolutionType.ContactLensSolution
              ? ContactLensSolutionType.DryEyeProducts
              : ContactLensSolutionType.ContactLensSolution;
        }
        panel.disableRadio = true;
      }
    });
  }

  selectedSolutionLensValue(val, index): void {
    this.selectedSolutionsValue[index] = val;
    this.updateSelectedProductValue();
  }

  /**
   * In case of multiple lense dropdown
   * Returns firstLense dropdown's selected configuration of checkboxes (same,R and L)
   * Empty in case of single lense dropdown
   *
   */
  get firstPanelConfiguration(): BasicProductSelectorPanelConfig {
    if (this.secondProduct) {
      this.disableCheckbox = true;
      const checkboxStatus = { ...this.panelConfiguration.checkboxStatus };
      checkboxStatus.leftEye = !checkboxStatus.leftEye;
      checkboxStatus.rightEye = !checkboxStatus.rightEye;
      return { checkboxStatus } as BasicProductSelectorPanelConfig;
    }
    this.disableCheckbox = false;
    return {} as BasicProductSelectorPanelConfig;
  }

  isEditRightPanel(): void {
    this.updateSelectedProductValue();
  }

  setPanelErrors(value: boolean | QuantityErrorCodes, productCode: string): void {
    this.pushPanelErrors(
      productCode,
      value === QuantityErrorCodes.DTP_MAX_QUANTITY,
      value === QuantityErrorCodes.MAX_QUANTITY
    );
  }

  pushPanelErrors(baseProductCode: string, isDTPError: boolean, isMaxQuantityError: boolean): void {
    const panel = this.panelErrors.find((panelError) => panelError.baseProductCode === baseProductCode);
    if (!panel) {
      this.panelErrors.push({
        baseProductCode,
        isDTPError,
        isMaxQuantityError,
      });
    } else {
      panel.isDTPError = isDTPError;
      panel.isMaxQuantityError = isMaxQuantityError;
    }
    this.isDTPErrorFlag.emit(this.isDTPError);
  }
  removePanelErrors(baseProductCode: string): void {
    this.panelErrors.splice(
      this.panelErrors.findIndex((panel) => panel.baseProductCode === baseProductCode),
      1
    );
  }
}
