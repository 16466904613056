import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ProductSearchService } from '@spartacus/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ProductListService } from 'src/app/services/product-list-page/product-list.service';
import { WrapperProductComponent } from 'src/app/shared/classes/wrapper-product';
import { Category } from 'src/app/shared/constants/constants';
import { DropdownOptions } from 'src/app/shared/model/common.mode';

@Component({
  selector: 'app-product-wrapper',
  templateUrl: './product-wrapper.component.html',
  styleUrls: ['./product-wrapper.component.scss'],
})
export class ProductWrapperComponent extends WrapperProductComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isDTP = false;
  @Input() dtpError: boolean;
  @Output() changeDtpError: EventEmitter<boolean> = new EventEmitter<boolean>();
  $disable = this.productListService.disablePanel;
  private destroy$ = new Subject<void>();
  constructor(
    private productListService: ProductListService,
    public productSearchService: ProductSearchService,
    public cd: ChangeDetectorRef
  ) {
    super(productSearchService, cd);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.refreshProductPanel?.currentValue) {
      this.secondProduct = null;
      this.addLens = false;
      this.disableAddLens = true;
      this.selectedProductValue = [];
      this.disableCheckbox = false;
    }
  }

  ngOnInit(): void {
    this.showLoader = true;
    this.selectedContactLensValue[0] = [];
    this.productSearchResult(Category.CONTACT_LENSES);

    this.productSearchService
      .getResults()
      .pipe(
        filter((results) => !!results.products),
        takeUntil(this.destroy$)
      )
      .subscribe((results) => {
        const selectedOptions = results.products?.map((productsdata: any) => {
          return {
            text: productsdata.name,
            value: productsdata.code,
            selected: false,
          } as DropdownOptions;
        });
        this.productsList = selectedOptions;
        this.firstproductsList = this.productsList.map((prd) => {
          const product = { ...prd };
          product.selected = false;
          product.selected = prd.value === this.selectedProduct;
          return product as DropdownOptions;
        });
        this.firstProductDrpdwnSelected(
          this.firstproductsList.find((prd) => prd.selected),
          false
        );
        this.showLoader = false;
        this.cd.detectChanges();
      });
  }

  onDtpErrorChange(error: boolean): void {
    this.dtpError = error;
    this.changeDtpError.emit(this.dtpError);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
