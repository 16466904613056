import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ProductDetailsService } from 'src/app/services/alcon-pdp/product-details.service';
import { CartItemsService } from 'src/app/services/cart-items/cart-items.service';
import {
  CART_GROUP,
  CART_TYPE,
  DIRECT_TO_PATIENT,
  DIRECT_TO_PRACTICE,
  EYESIDE,
  EYESIDE_SHORT_FORM,
} from 'src/app/shared/constants/constants';
import { ModalOptions, PageType } from 'src/app/shared/model/common.mode';
import { ProductEntries, ProductItems } from 'src/app/shared/model/product.model';
import { getDTPOrdersummary, getIndividualOrdersummary } from 'src/app/store/Selectors/cartItem.selector';
import {
  EntryType,
  VisionCareAddToCart,
  VisionCareAddtocartProductRequest,
  VisionCareGroupEntries,
} from 'src/app/store/States/cartItem.state';

@Component({
  selector: 'app-cart-single-line-item',
  templateUrl: './cart-single-line-item.component.html',
  styleUrls: ['./cart-single-line-item.component.scss'],
})
export class CartSingleLineItemComponent implements OnInit, OnDestroy {
  showLoader: boolean;
  cartEditData: ProductEntries;
  isPriceVisible: boolean;
  constructor(
    private store: Store,
    private cartItemsService: CartItemsService,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private productDetailsService: ProductDetailsService
  ) {}
  @Input() cartEntry: any;
  @Input() enableCounter: boolean;
  @Input() showActions: boolean;
  @Input() showPatientReference = true;
  @Input() entryLabel: string;
  @Input() orderType;
  @Input() cartType = CART_TYPE.UPDATE;
  productData: ProductItems;
  calculated: boolean;
  errorMessage = '';
  maxQtyErrorMessage = '';
  pageType = this.route?.snapshot?.data?.cxRoute?.toLowerCase();
  isCartPage = this.pageType === PageType.CART.toLowerCase();
  public modalOptions = {
    open: false,
    header: false,
    modalTitle: '',
    backgroundColor: '#e5f5ff',
  } as ModalOptions;
  private destroy$ = new Subject<void>();

  get cartGroup(): typeof CART_GROUP {
    return CART_GROUP;
  }

  ngOnInit(): void {
    this.store.select(getIndividualOrdersummary).subscribe(({ orders }) => {
      if (Object.keys(orders).length > 0) {
        const errorOrder = orders.find((order) => {
          return order.entryNumber === this.cartEntry.entryNumber && order.errorMessage;
        });
        if (errorOrder) {
          this.errorMessage = errorOrder.errorMessage;
        }
        const noErrorOrder = orders.find((order) => {
          return order.entryNumber === this.cartEntry.entryNumber && !order.errorMessage;
        });
        if (noErrorOrder) {
          this.errorMessage = '';
        }
      }
    });
    this.store.select(getDTPOrdersummary).subscribe(({ orders }) => {
      if (orders) {
        orders.forEach((order) => {
          order.entries.forEach((entry) => {
            if (entry.entryNumber === this.cartEntry.entryNumber) {
              this.errorMessage = entry.errorMessage ? entry.errorMessage : '';
            }
            this.cd.detectChanges();
          });
        });
      }
    });

    this.checkForErrorMessage();
    this.calculated = this.route.snapshot.data.vcCart?.calculated;
    this.modalOptions.width = this.orderType === this.cartGroup.STOCK_ORDER ? '940px' : '647px';
    this.cartItemsService.cartUpdated
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ groupingLabel, positionId, productCode, eyeSight }) => {
        this.cartEntry = { ...this.cartEntry };
        if (this.cartEntry.orderEntryFlowType === EntryType.DIRECT_TO_PATIENT) {
          if (
            this.cartEntry.positionId !== positionId &&
            this.cartEntry.product.code === productCode &&
            this.cartEntry.groupingLabel === groupingLabel &&
            this.cartEntry.eyeSight === eyeSight
          ) {
            this.deleteItem();
          }
        } else if (this.cartEntry.positionId !== positionId && this.cartEntry.groupingLabel === groupingLabel) {
          this.deleteItem();
        }
      });
  }

  setUpdatedData(updatedData): void {
    this.modalOptions.open = false;
    this.cartEntry = {
      ...updatedData,
      positionId: this.cartEntry.positionId,
    };
    this.cartItemsService.cartUpdated.next({
      groupingLabel: this.cartEntry.groupingLabel,
      positionId: this.cartEntry.positionId,
      productCode: this.cartEntry.product.code,
      eyeSight: this.cartEntry.eyeSight,
    });
  }

  checkForErrorMessage(): void {
    this.errorMessage = this.cartEntry?.errorMessage ? this.cartEntry?.errorMessage : '';
    if (this.cartEntry?.orderEntryFlowType === DIRECT_TO_PRACTICE.STOCK_ORDER) {
      this.cartEntry?.entries.forEach((entry) => {
        if (entry?.errorMessage) {
          this.errorMessage = entry.errorMessage;
          return;
        }
      });
    }
  }

  deleteItem(): void {
    /* to remove the item from error data array if the error exists on line item before deleting */
    this.cartItemsService.setCartErrorData(this.cartEntry.positionId, false);
    this.cartEntry = { ...{} };
  }

  updateItem(value): void {
    if (this.orderType === CART_GROUP.STOCK_ORDER) {
      this.showLoader = true;
      this.productDetailsService
        .getProductInfo(this.cartEntry.productCode, DIRECT_TO_PRACTICE.STOCK_ORDER_PAGE)
        .pipe(take(1))
        .subscribe((data) => {
          this.productData = { ...data };
          this.cartEditData = {
            ...this.cartEntry,
            entries: [...this.cartEntry.entries.sort((a, b) => a.entryNumber - b.entryNumber)],
          };
          this.modalOptions = {
            ...this.modalOptions,
            open: true,
            width: '1180px',
            borderRadius: '14px',
          } as ModalOptions;
          this.showLoader = false;
          this.cd.detectChanges();
        });
    } else {
      this.modalOptions = {
        ...this.modalOptions,
        open: true,
        width: '820px',
        borderRadius: '15px',
      } as ModalOptions;
    }
  }

  closeModal(): void {
    this.modalOptions.open = false;
  }

  updateCartWithNewQuantity(quantity: number): void {
    if (quantity) {
      const Product = [
        {
          product: this.cartEntry.product,
          eyeSight: this.cartEntry.eyeSight
            ? this.cartEntry?.eyeSight?.toLowerCase() === EYESIDE.RIGHT
              ? EYESIDE_SHORT_FORM.RIGHT
              : EYESIDE_SHORT_FORM.LEFT
            : '',
          quantity: String(quantity),
          entryNumber: this.cartEntry.entryNumber,
          orderEntryFlowType:
            this.cartEntry.orderEntryFlowType === DIRECT_TO_PATIENT.DIRECT_TO_PATIENT_ORDER
              ? DIRECT_TO_PATIENT.DIRECT_TO_PATIENT_ORDER
              : DIRECT_TO_PRACTICE.REGULAR,
          patientName: this.cartEntry?.patientName,
          patientShippingAddressMain:
            this.cartEntry.orderEntryFlowType === DIRECT_TO_PATIENT.DIRECT_TO_PATIENT_ORDER
              ? this.cartEntry?.patientShippingAddressMain
              : '',
          patientId:
            this.cartEntry.orderEntryFlowType === DIRECT_TO_PATIENT.DIRECT_TO_PATIENT_ORDER
              ? this.cartEntry.patientId
              : '',
        } as VisionCareAddtocartProductRequest,
      ];
      const VCAddtocartItems: VisionCareAddToCart = {
        entries: Product,
        orderFlowType:
          this.cartEntry.orderEntryFlowType === DIRECT_TO_PATIENT.DIRECT_TO_PATIENT_ORDER
            ? DIRECT_TO_PATIENT.DIRECT_TO_PATIENT_ORDER
            : DIRECT_TO_PRACTICE.REGULAR,
        trialProduct: false,
        positionId: this.cartEntry.positionId,
      };
      this.cartItemsService
        .updateCart({ VCAddtocartItems })
        .pipe(take(1))
        .subscribe(
          (updatedLineItem: { entries: VisionCareGroupEntries; positionId: string }) => {
            this.maxQtyErrorMessage = '';
            this.cartEntry = { ...updatedLineItem.entries, positionId: this.cartEntry.positionId };
            this.cartItemsService.setCartErrorData(this.cartEntry.positionId, false);
            this.cd.detectChanges();
          },
          (error) => {
            this.maxQtyErrorMessage = error.error.errors[0].message;
            this.cartItemsService.setCartErrorData(this.cartEntry.positionId, true);
            this.cd.detectChanges();
          }
        );
    } else {
      this.cartItemsService.setCartErrorData(this.cartEntry.positionId, true);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
