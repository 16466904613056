<div class="cart-single-line-item" *ngIf="cartEntry?.product" [ngClass]="errorMessage.length ? 'pt-0' : ''">
  <ng-container *ngIf="maxQtyErrorMessage; else errMsg">
    <p class="cart-single-line-item__errormsg">
      {{ maxQtyErrorMessage }}
    </p>
  </ng-container>
  <ng-template #errMsg>
    <p class="cart-single-line-item__errormsg" *ngIf="errorMessage">
      {{ errorMessage }}
    </p>
  </ng-template>

  <div class="d-flex justify-content-between" [ngClass]="pageType === 'cart' ? 'cart-single-line-item__item-wrap' : ''">
    <app-cart-product-information [cartEntry]="cartEntry" [showPatientReference]="showPatientReference">
    </app-cart-product-information>
    <div class="d-flex justify-content-between pt--11" *ngIf="!showActions">
      <label class="cart-single-line-item__item-quantity"
        >{{ cartEntry.quantity }} {{ 'orderConfirmation.item' | cxTranslate }}</label
      >
      <label class="cart-single-line-item__item-price">
        <app-show-price [productDetails]="cartEntry"></app-show-price>
      </label>
    </div>

    <div *ngIf="showActions" class="cart-single-line-item__actions">
      <div class="cart-single-line-item__price-counter">
        <app-alcon-item-counter
          class="cart-single-line-item__counter"
          [minimum]="1"
          *ngIf="enableCounter"
          [defaultValue]="cartEntry.quantity"
          [cartEntry]="cartEntry"
          (ValueChanged)="updateCartWithNewQuantity($event)"
          [qtyFactor]="orderType === 'DIRECT_TO_PATIENT' ? '1' : cartEntry.qtyMultipler"
        ></app-alcon-item-counter>
        <label class="cart-single-line-item__items" *ngIf="!enableCounter"
          >{{ cartEntry.quantity }} {{ 'orderConfirmation.item' | cxTranslate }}</label
        >

        <div
          class="flexbox cart-single-line-item__cart-price mb-15"
          [ngClass]="
            !enableCounter ? 'cart-single-line-item__price-top-stock' : 'cart-single-line-item__price-top-other'
          "
        >
          <p class="cart-single-line-item__total-price mb-0">
            <app-show-price [productDetails]="cartEntry"></app-show-price>
          </p>
          <p class="cart-single-line-item__each-price" *ngIf="enableCounter">
            <app-show-price [productDetails]="cartEntry" [basePrice]="true"></app-show-price>
          </p>
        </div>
      </div>
      <app-cart-actions [cartEntry]="cartEntry" (emitUpdateItem)="updateItem($event)" (emitDeleteItem)="deleteItem()">
      </app-cart-actions>
    </div>
  </div>
</div>
<app-modal class="stock-order-tablet" [modalOptions]="modalOptions" (closeHandeller)="closeModal()">
  <div class="modal-block">
    <div class="editProduct__close">
      <cx-icon role="button" type="Close" class="close-icon cx-icon close" (click)="closeModal()"> </cx-icon>
    </div>
    <ng-container>
      <app-basic-product-edit-selector
        *ngIf="
          (orderType === cartGroup.INDIVIDUAL_ORDER || orderType === cartGroup.DIRECT_TO_PATIENT) && modalOptions?.open
        "
        [cartType]="cartType"
        [editProductData]="cartEntry"
        (closeHandeller)="closeModal()"
        (emitUpdatedData)="setUpdatedData($event)"
      >
      </app-basic-product-edit-selector>
      <app-stockorder-configurator
        *ngIf="orderType === cartGroup.STOCK_ORDER && modalOptions?.open"
        [product]="productData"
        [cartData]="cartEditData"
        [cartType]="cartType"
        (emitUpdatedData)="setUpdatedData($event)"
        (closePopup)="closeModal()"
      >
      </app-stockorder-configurator>
    </ng-container>
  </div>
</app-modal>

<app-loader-icon *ngIf="showLoader"></app-loader-icon>
