import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  GlobalMessageService,
  GlobalMessageType,
  OccEndpointsService,
  TranslationService,
  UserIdService,
} from '@spartacus/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { IRegisterFormData } from 'src/app/cms-components/register/i-register-form-data';
import { GlobalMessage } from 'src/app/shared/constants/constants';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  constructor(
    private http: HttpClient,
    private userIdService: UserIdService,
    private globalMessageService: GlobalMessageService,
    private occEndPoints: OccEndpointsService,
    private translationService: TranslationService
  ) {}

  registerExistingUser(registerInformation: IRegisterFormData): Observable<any> {
    return this.userIdService.getUserId().pipe(
      take(1),
      mergeMap((userId) => {
        const url = this.occEndPoints.buildUrl(`users/${userId}/registerExistingUser`);
        return this.http.post<any>(url, registerInformation).pipe(
          take(1),
          catchError((error) => {
            this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
            this.globalMessageService.add(error?.error?.errors[0]?.message, GlobalMessageType.MSG_TYPE_ERROR);
            return throwError(EMPTY);
          })
        );
      })
    );
  }

  activateUser(token: string): Observable<null | never> {
    return this.userIdService.getUserId().pipe(
      take(1),
      withLatestFrom(this.translationService.translate('confirmEmail.success').pipe(take(1))),
      switchMap(([userId, success]) => {
        const url = this.occEndPoints.buildUrl(`users/${userId}/activateUser?token=${encodeURIComponent(token)}`);
        return this.http.post<null>(url, null).pipe(
          catchError((error) => {
            this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
            this.globalMessageService.add(
              error?.error?.errors[0]?.message,
              GlobalMessageType.MSG_TYPE_ERROR,
              GlobalMessage.TIMEOUT
            );
            return throwError(EMPTY);
          }),
          tap((res) => {
            this.globalMessageService.add(success, GlobalMessageType.MSG_TYPE_CONFIRMATION, GlobalMessage.TIMEOUT);
          })
        );
      })
    );
  }
}
