<button class="add-patient-button" (click)="openModal()" [ngStyle]="{ width: btnWidth ? btnWidth + 'px' : null }">
  {{ 'myPatientsPage.addItems' | cxTranslate }}
</button>

<app-modal [modalOptions]="modalOptions" [showBigPopup]="true" (closeHandeller)="closeModal()">
  <div class="modal-block">
    <ng-container *ngIf="productOpen">
      <app-add-new-patient
        (cancel)="closeModal()"
        [navigateNewOrderPage]="true"
        (patientAddedSuccessfully)="patientAddedSuccessfully()"
      ></app-add-new-patient>
    </ng-container>
  </div>
</app-modal>
