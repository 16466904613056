export const deDE = {
  contactlenses: {
    contactlenses: 'Kontaktlinsen',
  },
  dailycontactlenses: {
    dailycontactlenses: 'Ein-Tages-Kontaktlinsen',
  },
  fortnightlycontactlenses: {
    fortnightlycontactlenses: 'Fortnightly contact lenses',
  },
  monthlycontactlenses: {
    monthlycontactlenses: 'Monatslinsen',
  },
  dryeyeproducts: {
    dryeyeproducts: 'Augenpflege',
  },
  contactlenssolutions: {
    contactlenssolutions: 'Pflegemittel',
  },
  contactlenssolution: {
    contactlenssolution: 'Pflegemittel',
  },
  multifocal: {
    multifocal: 'Multifokal',
  },
  colour: {
    colour: 'Farbe',
  },
  color: {
    color: 'Farbe',
  },
  spherical: {
    spherical: 'Sphärisch',
  },
  toric: {
    toric: 'Torisch',
  },
  directtopatient: {
    directtopatient: 'Direkt an Kunden',
  },
  valuepack: {
    valuepack: 'Vorteilspackungen',
  },
  practiceorder: {
    practiceorder: 'Lieferung ins Geschäft',
  },
  practice: {
    practice: 'Praxis',
  },
  stockorder: {
    stockorder: 'Lagerbestellungen',
  },
  regular: {
    regular: 'Standard',
  },
  individualorders: {
    individualorders: 'Individuelle Bestellung',
  },
  valuepacks: {
    valuepacks: 'Vorteils packungen',
  },
  stockorders: {
    stockorders: 'Lagerbestellungen',
  },
  directtopatients: {
    directtopatients: 'Direkt an Kunden',
  },
  directpurchase: {
    directpurchase: 'Direkt Erwerben',
  },
  packsize: {
    packsize: 'Verpackungsgröße',
  },
  diameter: {
    diameter: 'Durchmesser',
  },
  lenscylinder: {
    lenscylinder: 'Linsenzylinder',
  },
  lensaxis: {
    lensaxis: 'Linsen-Achse',
  },

  common: {
    formErrors: {
      required: 'Dieses Feld ist erforderlich',
    },
    common: {
      home: 'Startseite',
    },
    skipLink: {
      skipTo: 'Überspringen zu',
      labels: {
        header: 'Kopfzeile',
        main: 'Hauptinhalt',
        footer: 'Fußzeile',
        productFacets: 'Produkt-Facetten',
        productList: 'Produktliste',
      },
    },
    pageMetaResolver: {
      category: {
        title: '{{count}} result for {{query}}',
        title_plural: '{{count}} results for {{query}}',
      },
      checkout: {
        title: 'KASSE',
      },
      search: {
        title: '{{count}} result for "{{query}}"',
        title_plural: '{{count}} results for "{{query}}"',
        findProductTitle: '{{count}} result for coupon "{{coupon}}"',
        findProductTitle_plural: '{{count}} results for coupon "{{coupon}}"',
        default_title: 'All products',
      },
      product: {
        description: '{{description}}',
        heading: '{{heading}}',
        title: '{{title}}',
      },
    },
  },

  cms: {
    login: {
      backToLogin: 'Zurück zur Anmeldung',
      backToLoginPage: 'Zurück zur Anmeldeseite',
      emailAddress: 'E-Mail-Adresse',
      emailPlaceholer: 'Ihre E-Mail-Adresse',
      emailSentConfirmText: 'Eine E-Mail mit den Anweisungen zum Zurücksetzen Ihres Passworts wurde versandt.',
      forgotLink: 'Passwort vergessen?',
      forgotPwdText: 'Geben Sie Ihre E-Mail-Adresse ein und wir senden Ihnen einen Link zum Ändern Ihres Passworts:',
      logIn: 'Anmeldung',
      passwordLbl: 'Passwort',
      requireEmail: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
      requirePassword: 'Bitte geben Sie Ihr Passwort ein.',
      sendEmail: 'Absenden',
      forgotYourPwd: 'Haben Sie Ihr Passwort vergessen?',
      applyNow: 'Apply Now',
    },
    multiAccountSelector: {
      message:
        'Wählen Sie das Konto aus, mit dem Sie fortfahren möchten. Die Verfügbarkeit der Produkte und das Einkaufserlebnis können variieren.',
      title: 'Wählen Sie ein Alcon-Konto',
      welcomeMessage: 'Willkommen ',
    },
    productListingPage: {
      moreFilter: 'Weitere Filter',
      lessFilter: 'Weniger Filter',
      product: 'Produkt',
      seeAll: 'Alle anzeigen',
      select: 'Auswählen',
      showMore: 'Mehr anzeigen',
      showLess: 'Weniger anzeigen',
      sortBy: 'Sortieren nach',
      results: 'Ergebnisse',
      products: 'Produkte',
      nameAZ: 'Name A–Z',
      price: 'Preis',
      oxygenPermeability: 'Sauerstoffdurchlässigkeit',
      brand: 'Marke',
      categories: 'Kategorien',
      showAll: 'Alle anzeigen',
      configureProducts: 'Produkte konfigurieren',
      valuepack: 'Vorteilspackungen',
      details: 'Einzelheiten',
    },
    productPage: {
      productDescription: 'Produktbeschreibung',
      productSpecification: 'Produktspezifikationen',
      features: 'Merkmale',
      benefits: 'Nutzen',
      downloads: 'Downloads:',
    },
    stockOrderProductListPage: {
      from: 'von',
    },
    productDetailsPage: {
      privateLabel: 'Eigenmarke',
      patientReference: 'Referenz des Kunden: ',
      parametersFor: 'Parameter für:',
      left: 'Links',
      right: 'Rechts',
      baseCurve: 'Basiskurve:*',
      power: 'Stärke:*',
      add: 'ADD-Wert*:',
      colour: 'Farbe*:',
      cylinder: 'Zylinder*:',
      axis: 'Achse*:',
      sameForBothEye: 'Für beide Augen gleich',
      packSize: 'Verpackungsgröße:',
      practiceOrder: 'Lieferung ins Geschäft',
      individualOrders: 'Individuelle Bestellung',
      dtpError: 'Dieses Produkt kann nur in das Geschäft geliefert werden.',
      dtpSummaryError: 'Diese Bestellung kann nur in das Geschäft geliefert werden.',
      addressError: 'Bitte geben Sie die richtige Adresse an',
      directToPatient: 'Lieferung direkt zum Kunden',
      errorMessage: 'Sie können nicht mehr als 18 Zeichen eingeben.',
      quantityErrorMsg: 'Die maximal bestellbare Menge für diesen Einheit beträgt',
      PurchaseStockOrder: 'Kauf auf Vorrat bestellen',
      perEye: 'pro Auge',
      each: 'je',
      finalPrice: '* Der Endpreis wird während des Bestellvorgangs berechnet',
    },
    practiceOrderPage: {
      baseCurve: 'Basiskurve:',
      cancel: 'ABBRECHEN',
      add: 'Addition:*',
      colour: 'Farbe:*',
      cylinder: 'Zylinder:*',
      axis: 'Achse:*',
      diameter: 'Durchmesser:',
      dtpLimitMessage: 'Die maximale Menge für die Direktlieferung an den Kunden beträgt',
      errorMessage: 'Zu viele Einheit ausgewählt. Bitte verringern Sie die Menge auf max.',
      individualOrders: 'Individuelle Bestellung',
      left: 'linkes Auge',
      LEFT: 'Linkes Auge',
      material: 'Material:',
      packSize: 'Verpackungsgröße:',
      parametersFor: 'Parameter für:',
      patientReference: 'Referenz des Kunden:',
      power: 'Stärke:*',
      practiceOrder: 'Lieferung ins Geschäft',
      prefer: 'Bevorzugen Sie einen Vorteilspack?',
      quantity: 'Menge',
      right: 'rechtes Auge',
      RIGHT: 'Rechtes Auge',
      sameForBothEye: 'Für beide Augen gleich',
      schedule: 'Wiederkehrende Bestellung planen',
      typeOfWear: 'Tragemodus',
      update: 'AKTUALISIERT',
      waterContent: 'Wassergehalt:',
      productColor: 'Farbe',
      productMultifocal: 'Multifocal',
      productSpherical: 'Sphärisch',
      productToric: 'Torisch',
      stockOrder: 'Lager_bestellung',
      stockOrderPAge: 'Lagerbestellung',
      practiceOrderPage: 'Praxisauftrag',
      regularInCap: 'Standard',
      regularInSmall: 'Standard',
      valuePacks: 'Vorteils_packungen',
      valuePack: 'Vorteilspackungen',
      directToPatient: 'Direkt an Kunden',
      dtpValuePacks: '',
      practice: 'Praxis',
      directToPatientBlockText: 'Direkt_an_Kunden',
    },
    favoriteListPage: {
      yourFavProduct: 'Ihre Lieblingsprodukte',
      products: 'Produkte',
      product: 'Produkt',
      noFavProduct:
        'Sie haben derzeit keine Lieblingsprodukte aufgelistet. Um Produkte zur Favoritenliste hinzuzufügen, klicken Sie auf das Herzsymbol neben dem Produkt.',
    },
    legalAnnouncement: {
      accept: 'AKZEPTIEREN',
      decline: 'ABLEHNEN',
    },
    practicePage: {
      mostFrequentOrders: 'Häufigste Bestellungen',
    },
    stockOrderPage: {
      configureProducts: 'Produkt konfigurieren',
      packSize: 'Verpackungsgröße:*',
      baseCurve: 'Basiskurve:',
      diameter: 'Durchmesser:',
      color: 'Farbe:',
      add: 'ADD-Wert:',
      cancel: 'Abbrechen',
      lenscylinder: 'Linsenzylinder:*',
      moreMinus: 'Mehr Minus (-)-Stärke',
      lessMinus: 'Weniger Minus (-)-Stärke',
      morePlus: 'Mehr Plus (+)-Stärke',
      lessPlus: 'Weniger Plus (+)-Stärke',
      clearAll: 'Alle löschen',
      addToCart: 'in den Warenkorb',
      addToCartLoading: 'WIRD ZUM WARENKORB HINZUGEFÜGT',
      addToCartSuccess: 'IM WARENKORB',
      updateToCartText: 'AKTUALISIERT',
      updatingCart: 'Warenkorb wird aktualisiert',
      updateToCartSuccess: 'Aktualisierter Warenkorb',
      maxQty: 'Max. Gesamtmenge für dieses Produkt beträgt',
      errorMessage: 'Zu viele Einheit ausgewählt. Bitte verringern Sie die Menge auf max.',
      inputMessageError: 'Die Menge muss größer als 0 sein',
      each: 'jeweils',
      forthisbrand: 'für diese Marke',
    },
    miniCartPage: {
      PRACTICE_ORDER: 'Lieferung ins Geschäft',
      INDIVIDUAL_ORDER: 'Individuelle Bestellung',
      VALUE_PACK: 'Vorteilspack',
      DTP_VALUE_PACK: 'Vorteilspack',
      DIRECT_TO_PATIENT: 'Lieferung direkt zum Kunden',
      STOCK_ORDER: 'Lagerbestellung',
      title: 'Ihr Warenkorb',
      RIGHT: 'Rechts',
      LEFT: 'Links',
      total: 'Gesamt',
      delete: 'Löschen',
      gotocart: 'Zum Warenkorb gehen',
      moreitem: 'mehr Einheit',
      totalprice: '00,00 €',
      yourcartIsEmpty: 'Ihr Warenkorb ist leer.',
      goTo: 'Gehen Sie zum',
      productCatalogue: 'Produktkatalog',
      orClick: 'oder klicken Sie auf',
      orClickthe: 'oder klicken Sie auf die/das',
      newOrder: 'Neuer Auftrag',
      editOrder: 'Bestellung bearbeiten',
      toAddItems: 'um Einheit hinzuzufügen',
      edit: 'Bearbeiten',
      patientReference: 'Referenz des Kunden',
      quantity: 'Anzahl',
      YouHaveNoProductsInYourCart: 'Sie haben keine Produkte in Ihrem Warenkorb.',
      seeProduct: 'Produkte ansehen',
      hideProduct: 'Produkte ausblenden',
      deliveryMethod: 'Liefermethode:',
      poNumber: 'Auftragsnummer:',
      orderNumber: 'Bestellnummer:',
      LP: 'SPH',
      AX: 'AX',
      CY: 'CYL',
      Colour: 'Farbe',
      DIA: 'Durchmesser',
      BC: 'BC',
      ADD: 'ADD-Wert',
      PatientReference: 'Kunden-Referenz',
      PowerShipped: 'Versendete Stärken',
      PowersInProgress: 'Stärken in Bearbeitung',
      cartMessage1: 'Sie haben keine Produkte zu Ihrem Warenkorb hinzugefügt.',
      cartMessage2: 'Verwenden Sie eine der folgenden Methoden, um Ihrer Bestellung Produkte hinzuzufügen.',
      cartMessage:
        'Sie haben keine Produkte zu Ihrem Warenkorb hinzugefügt. Verwenden Sie eine der folgenden Methoden, um Ihrer Bestellung Produkte hinzuzufügen.',
      valuePack: 'Vorteilspack:',
      valuePacks: 'Vorteilspackungen',
      valuePackDuration: 'Vorteilspack (',
      valuePackMonth: '-Monate)',
      moreitems: 'weitere Einheit',
    },
    cartPagev2: {
      INDIVIDUAL_ORDER: 'Individuelle Bestellung',
      VALUE_PACK: 'Vorteilspackungen',
      STOCK_ORDER: 'Lagerbestellungen',
      DIRECT_TO_PATIENT: 'Direkt an Kunden',
      orderAndPay: 'BESTELLEN UND BEZAHLEN',
      simulation: {
        errorMessage:
          'Der Endpreis kann derzeit nicht berechnet werden. Sobald dieses Problem behoben wurde, sehen Sie die Preise in der Bestellhistorie und in den Rechnungen.',
      },
      directToPatientWarning:
        'Der endgültige Preis für jede Direktbestellung an einen Patienten wird in Ihrer Rechnung angegeben',
      warningOnCheckout: '',
      practiceWarning: 'Der endgültige Preis der Praxisbestellung wird in Ihrer Rechnung ausgewiesen',
      disclaimer:
        'Indem Sie auf BESTELLEN UND BEZAHLEN klicken, bestätigen Sie und stimmen Sie 1) den Geschäftsbedingungen von MyAlcon zu, denen Sie zugestimmt haben, als Sie Ihre Daten zur Nutzung dieses Systems registriert haben, und 2) bestätigen Sie, dass, wenn es sich um eine Bestellung direkt an den Kunden handelt, a) der Kunde mindestens 16 Jahre alt ist und b) Sie den Kunden darüber informiert haben, dass seine Daten über MyAlcon weitergegeben werden, und alle erforderlichen Einwilligungen des Kunden eingeholt haben.',
      deliveryCost: 'Versandkosten',
      taxes: 'Steuern',
      estimatedTotalPrice: 'BESTELLWERT',
      total: 'Gesamt',
    },
    stockToricPage: {
      lensPower: 'Linsenstärke',
      lensAxis: 'Linsen-Achse',
      message: 'Wählen Sie die Stärke, um die angepasste Linsenachse anzuzeigen.',
    },
    cartPage: {
      PRACTICE_ORDER: 'Lieferung ins Geschäft',
      summary: 'Zusammenfassung',
      goToCheckout: 'ZUR KASSE GEHEN',
      ShippingAddress: 'Lieferadresse',
      DeliveryMethod: 'Liefermethode',
      calcFinalPrice: 'ENDPREIS BERECHNEN',
      DIRECT_TO_PATIENT: 'Lieferung direkt zum Kunden',
      deliveryMethod: 'Liefermethode:',
      deliveryMethodDetails: 'Standardlieferung (2–3 Arbeitstage)',
      IndividualOrders: 'Individuelle Bestellung',
      StockOrders: 'Lagerbestellungen',
      ValuePacks: 'Vorteilspackungen',
      DirecttoPatient: 'Direkt an Kunden',
      products: 'Produkte',
      shippingsuggestion: 'Die Versandkosten werden an der Kasse berechnet.',
      patients: 'Kunden',
      externalOrderMsg: 'Der endgültige Preis wird auf der Rechnung angegeben.',
      cancelOrder: 'BESTELLUNG STORNIEREN',
    },
    orderConfirmation: {
      orderNumber: 'Bestellnummer:',
      shippingAddress: 'Lieferadresse:',
      deliveryMethod: 'Liefermethode:',
      deliveryMethodDetails: 'Standardlieferung per Post',
      poNumber: 'Auftragsnummer:',
      valuePackQuantity: '1 Einheit',
      item: 'Artikel',
    },
    checkoutPage: {
      paymentMethod: 'Zahlungsmethode',
      paymentType1: 'Kreditkarte',
      paymentType2: 'Kauf auf Rechnung',
      valuePackQuantity: '1 Einheit',
      poNumber: 'Ihre Auftragsnummer:',
      LP: 'Stärke',
      AX: 'Achse',
      CY: 'Zylinder',
      Colour: 'Farbe',
      DIA: 'Durchmesser',
      BC: 'BC',
      ADD: 'ADD-Wert',
      print: 'Drucken',
      finalPrice: {
        products: 'Produkte',
        delivery: 'Lieferung',
        taxes: 'Steuern',
        total: 'GESAMT',
        placeOrder: 'Bestellung aufgeben',
        obligationText: 'mit Zahlungsverpflichtung/ zahlungspflichtig',
        estimatedDelivery: 'Geschätzte Lieferzeit:',
        loaderMessage: 'Bitte haben Sie einen Moment Geduld. Ihre Daten werden geladen.',
      },
      delivery: 'Lieferung:',
      practiceOrders: 'Lieferung ins Geschäft',
      orderTypes: {
        individual: 'Einzebestellung',
        valuePack: 'Vorteilspackungen',
        stock: 'Lageraufträge',
      },
      practiceOrdersPanel: 'Einzelaufträge, Vorteilspackungen, Lageraufträge',
      directtoPatients: 'Direkt an Kunden',
      seeAll: 'Alle anzeigen',
      status: 'Status',
      patients: 'Kunden',
      addNewCardHeading: 'Neuen Warenkorb hinzufügen',
      setDefaultCard: 'Diese Karte als Standard festlegen',
      selectPaymentCard: 'Zahlungskarte auswählen',
      selectCardtitle:
        'Wählen Sie eine Zahlungskarte aus Ihren gespeicherten Karten oder fügen Sie eine neue Karte hinzu.',
    },
    myPatientsPage: {
      addItems: ' + KUNDE HINZUFÜGEN',
      addNewPatient: {
        heading: 'Neuen Kunden hinzufügen',
        editHeading: 'Kundeninformationen bearbeiten',
        personalData: 'Personenbezogene Daten',
        mainAddress: 'Hauptadresse',
        additionalAddress: 'Zusätzliche Adresse',
        form: {
          title: 'Titel',
          firstName: 'Vorname *',
          surName: 'Nachname *',
          email: 'E-Mail-Adresse',
          phoneNumber: 'Telefonnummer',
          referenceNumber: 'Referenznummer',
          address: {
            street: 'Straße und Hausnummer *',
            building: 'Gebäude / Wohnung / Sonstiges',
            postcode: 'Postleitzahl *',
            city: 'Ort*',
            county: 'Bundesland',
            notMandatoryCounty: 'Bundesland',
            country: 'Land*',
          },
          mandatoryFields: '* Pflichtfelder',
          additionalAddress: 'Zusätzliche Adresse hinzufügen',
          deleteadditionaladdress: 'Zusätzliche Adresse löschen',
          clearAll: 'Alle löschen',
          save: 'speichern',
          cancel: 'Abbrechen',
          update: 'Aktualisiert',
          errorMessages: {
            title: 'Bitte Titel auswählen.',
            mandatoryField: 'Dieses Feld ist ein Pflichtfeld.',
            firstName: 'Bitte geben Sie den Vornamen des Kunden ein.',
            surName: 'Bitte geben Sie den Nachnamen des Kunden ein.',
            email: 'Die E-Mail hat das falsche Format. Bitte verwenden Sie das folgende Format: john@gmail.com',
            phoneNumber: 'Sie können in diesem Feld keine Buchstaben verwenden.',
            phoneNumberMinLength:
              'Die Telefonnummer ist zu kurz. Bitte überprüfen Sie, ob Sie alle Zeichen eingegeben haben.',
            moreThanThirtyCharacters: 'Sie können nicht mehr als 30 Zeichen in dieses Feld eingeben.',
            street: 'Bitte geben Sie die Adresse des Kunden ein.',
            buildingNumberName: 'Bitte tragen Sie die Gebäudenummer des Kunden ein.',
            postcode: 'Bitte geben Sie die Postleitzahl des Kunden ein.',
            city: 'Bitte geben Sie die Stadt des Kunden ein.',
            county: 'Bitte geben Sie den Landkreis des Kunden ein.',
            country: 'Bitte geben Sie das Land des Kunden ein.',
            invalidPattren: 'Ungültige Postleitzahl',
            selectCountry: 'Bitte wählen Sie ein Land',
          },
        },
        patientAddedSuccessfully: 'Der Kunde wurde erfolgreich hinzugefügt.',
      },
      address: 'Adresse',
      showMore: 'Mehr anzeigen',
      showLess: 'Weniger anzeigen',
      mostRecentOrders: 'Letzte Bestellungen',
      order: 'Order',
      orderNo: 'Bestell-Nr.',
      subscription: 'Dauerauftrag',
      delivery: 'Lieferung',
      shippingAddress: 'Lieferadresse',
      status: 'Status',
      packtype: 'Packungstyp',
      date: 'Datum',
      frequency: 'Häufigkeit',
      addOrder: 'hinzufügen',
      addOrderButton: '+ hinzufügen',
      options: 'Optionen',
      active: 'aktiv',
      inactive: 'inaktiv',
      switchtoSubscription: 'Zum Abo wechseln',
      sort: 'Sortieren:',
      orderHistory: 'Bestellverlauf',
      editPatient: 'Bearbeiten',
      deletePatient: 'Löschen',
      search: 'Kundenname, Referenz-Nr. suchen',
      noPatientFound:
        'Sie haben noch keine Kunden. Fügen Sie Ihren ersten Kunden hinzu, indem Sie auf die Schaltfläche unten klicken.',
      searchNoPatient: 'Kein Kunde gefunden. Prüfen Sie, ob Sie alles richtig eingegeben haben.',
      noPatientsFound: 'Keine Patienten gefunden',
      adjustFilters: 'Versuchen Sie, die angewandten Filter anzupassen oder ',
      addessRemovedMsg:
        'Die Lieferadresse für dieses Abonnement wurde in die Hauptadresse geändert, da die zusätzliche Adresse nicht mehr verfügbar ist',
      ok: 'Ok',
      top: 'Oben',
      fetchingOrders: 'Bestellungen abrufen ...',
      patientName: 'Kunden Name',
    },
    patientListFilters: {
      filterPlus: '+ Filter',
      filterMinus: '- Filter',
      ordertype: 'Auftragsart:',
      status: 'Status:',
      packType: 'Packungstyp:',
      delivery: 'Lieferung:',
      clearfilters: 'Filter löschen',
      clearallfilters: 'Alle löschen filters',
    },
    patientProfilePage: {
      personalData: 'Personenbezogene Daten',
      mainAddress: 'Hauptadresse',
      additionalAddress: 'Zusätzliche Adresse',
      edit: 'Bearbeiten',
      tel: 'Tel:',
      eMail: 'E-mail:',
      refNumber: 'Referenz-Nr.:',
      addOrder: '+ HINZUFÜGEN',
      noOrder: 'Es sind noch keine Bestellungen vorhanden.',
    },
    orderHistoryPage: {
      orderPlaced: 'Datum',
      orderDate: 'Bestell datum:',
      orderNo: 'Bestell-Nr.',
      type: 'Art',
      orderSource: 'Auftragsquelle',
      shippingLocation: 'Versandort',
      totalPrice: 'Gesamtpreis',
      status: 'Status',
      invoice: 'Rechnung',
      search: 'Bestellnummer oder Auftragsnummer suchen',
      searchButton: 'Suchen',
      timePeriod: 'Zeitraum:',
      dateRange: 'Datumsbereich:',
      selectDate: 'Auswählen',
      sort: 'Sortieren:',
      reorder: 'NACHBESTELLEN',
      viewOrderdetails: 'Bestelldetails anzeigen',
      noOrders: 'Sie haben noch keine Produkte bestellt.',
      noOrderText:
        'Wenn Sie eine Bestellung absenden, können Sie hier den Status und die Details Ihrer Bestellung sehen.',
      filter: {
        ALL: 'Alle',
        ACTIVE: 'Aktiv',
        COMPLETED: 'Abgeschlossen',
        STATUS: 'Status',
        filterPlus: '+ Filter',
        filterMinus: '- Filter',
        orderStatus: {
          NEW: 'Bestellt',
          IN_PROGRESS: 'In Bearbeitung',
          DELAYED: 'Verspätet',
          SHIPPED: 'Versendet',
          CANCELLED: 'Storniert',
          PARTIALLY_SHIPPED: 'Teilweise versandt',
          DELIVERED: 'Zugestellt',
          BILLED: 'In Rechnung gestellt',
          PARTIAL_BACKORDER: 'Teilweise verspätet',
          BACKORDER: 'Verspätet',
        },
      },
      showMore: '10 WEITERE VON',
      noResults:
        'Keine Bestellungen gefunden. Überprüfen Sie, ob Sie die richtige Zahl eingegeben haben, oder versuchen Sie es mit einer anderen Zahl.',
      noResultsFilter: 'Für die angegebenen Filterkriterien wurden keine Bestellungen gefunden.',
      cancel: 'ABBRECHEN',
      Order: '1 Bestellung gefunden',
      Orders: '{{count}} Bestellungen gefunden',
      poNumber: 'Auftragsnummer',
      cancelOrderConfirmation: 'Wollen Sie diesen Auftrag wirklich stornieren?',
      cancelOrderInfoMsg: 'Dieser Vorgang kann nicht mehr rückgängig gemacht werden.',
      cancelOrder: 'Auftrag stornieren',
      keepOrder: 'Auftrag behalten',
      orderCancelSuccessfull: 'Der Auftrag wurde erfolgreich storniert',
    },
    invoicesListPage: {
      invoiceNo: 'Rechnungs-Nr.',
      dueDate: 'Due Date',
      orderNo: 'Bestell-Nr.',
      totalPrice: 'Gesamtpreis',
      dateRange: 'Datumsbereich',
      sort: 'Sortieren:',
      newest: 'Vom neuesten',
      oldest: 'Vom ältesten',
      invoicesHeading: 'Rechnungen',
      orders: 'Aufträge',
      orderNumber: 'Bestell-Nr.',

      filter: {
        ALL: 'Alle',
        PAID: 'Paid',
        UNPAID: 'Unpaid',
        OVERDUE: 'Overdue',
        filterPlus: '+ Filter',
        filterMinus: '- Filter',
      },
      search: {
        DAILYINVOICE: 'Suche Rechnungsnummer oder Bestellnummer',
        INVOICELIST: 'Rechnungsnummer suchen',
        searchButton: 'Suche',
        noResults:
          'Keine Rechnungen gefunden. Prüfen Sie, ob Sie die richtige Nummer eingegeben haben oder versuchen Sie es mit einer anderen.',
        invoice: '1 Rechnung gefunden',
        invoices: '{{count}} Rechnungen gefunden',
      },
    },
    baseLoginPage: {
      youWereLoggedOut: 'Sie sind abgemeldet.',
      logoutMsg:
        'Ihre Sitzung ist abgelaufen. Zu Ihrer Sicherheit und der Sicherheit Ihrer Kunden wurden Sie automatisch abgemeldet.',
      welcome: 'Willkommen',
      backToLogin: 'Zurück zur Anmeldeseite',
    },
    orderStatus: {
      NEW: 'Bestellt',
      IN_PROGRESS: 'In Bearbeitung',
      DELAYED: 'Verspätet',
      SHIPPED: 'Versendet',
      CANCELLED: 'Storniert',
      PARTIALLY_SHIPPED: 'Teilweise versandt',
      PENDING_CONSOLIDATION: 'Warten auf Auftragskonsolidierung',
      DELIVERED: 'Zugestellt',
      NOT_SHOWN: 'Nicht_angezeigt',
      BILLED: 'In Rechnung gestellt',
      PARTIAL_BACKORDER: 'Teilweise verspätet',
      BACKORDER: 'Verspätet',
    },
    confirmEmail: {
      success: 'E-Mail-Adresse bestätigt. Unser Vertriebsmitarbeiter wird Sie in Kürze kontaktieren.',
      failure: 'Der Link ist abgelaufen. Bitte füllen Sie das Anmeldeformular erneut aus.',
    },
    registerPage: {
      registrationFormTitle:
        'Eröffnen Sie ein Konto, um rund um die Uhr Zugriff auf Online-Bestellungen, Rechnungen, Kontoauszüge, Auftragsverfolgung und andere Funktionen zur Kontoverwaltung zu erhalten.',
      imAlconCustomer: 'Ich bin ein Alcon-Kunde',
      iDoNotHaveAnAccountTitle: 'Ich habe noch kein Alcon-Konto',
      iDoNotHaveAnAccountDescription:
        'Das ist überhaupt kein Problem! Sie können Ihre Kontaktdaten hinterlassen und ein Alcon-Vertriebsmitarbeiter wird Sie in Kürze kontaktieren!',
      termsAndConditions: 'Anmeldung zum Alcon-E-Commerce',
      consent: 'Einverständnis',
      termsAndConditionTitle: 'Anmeldung zum Alcon-E-Commerce',
      termsAndConditionLabel:
        'Ich habe die Allgemeinen Geschäftsbedingungen von <a src="#">Alcon Eye Care UK Limited gelesen und stimme ihnen zu.</a>*',
      termsAndConditionDisclaimer:
        'Alcon wird Ihre persönlichen Daten stets in Übereinstimmung mit allen geltenden Gesetzen behandeln. </br></br>Alcon ist Teil der Alcon-Gruppe, die eine globale Unternehmensgruppe ist und über Datenbanken in verschiedenen Ländern verfügt, von denen einige von der lokalen Alcon-Tochtergesellschaft und einige von Dritten im Auftrag der lokalen Alcon-Tochtergesellschaft betrieben werden. Alcon kann Ihre Daten an eine oder mehrere Datenbanken seiner Tochtergesellschaften außerhalb des Landes, in dem Sie Ihren Wohnsitz haben, weitergeben, auch an Länder, die möglicherweise kein angemessenes Schutzniveau für Ihre personenbezogenen Daten im Vergleich zu Großbritannien oder der EU verlangen.</br></br>Alcon verkauft Ihre personenbezogenen Daten zu keinem Zweck an Dritte.</br></br>Wenn Sie uns bezüglich der Verwendung Ihrer personenbezogenen Daten kontaktieren, Ihre Präferenzen ändern oder der Verarbeitung Ihrer personenbezogenen Daten widersprechen möchten, senden Sie uns bitte eine E-Mail an privacy.uk@alcon.com.',
      setUpAnAccount: 'Ein Konto einrichten',
      title: 'Titel',
      firstName: 'Vorname*',
      lastName: 'Nachname*',
      fullCompanyName: 'Vollständiger Firmenname*',
      email: 'E-Mail-Adresse for MyAlcon Store account*',
      accountNumber: 'Alcon Kundennummer*',
      next: 'Weiter',
      back: 'Zurück',
      cancel: 'ABBRECHEN',
      submit: 'Senden',
      mandatory: '* Pflichtfelder',
      successText:
        '</br></br>Bitte beachten Sie, dass die Einrichtung eines neuen Kontos ab dem Tag, an dem wir das ausgefüllte Formular mit einer gültigen GOC/GMC-Nummer erhalten, bis zu drei Arbeitstage dauern kann',
      successTitle:
        'Vielen Dank, dass Sie Ihr Antragsformular zur Eröffnung eines Kontos bei Alcon Eye Care UK Limited ausgefüllt haben.',
      goToLoginPage: 'Gehe zur Anmeldeseite',
      TypeOfProducts: 'Geschäftsbereich*',
      selectProductType: 'auswählen',
      productTypeSurgical: 'Opthalmochirurgie',
      productTypeVisionCare: 'Vision care',
      errorMessages: {
        mandatoryField: 'Dieses Feld ist ein Pflichtfeld.',
        maxLengthReached: 'Sie können in diesem Feld nicht mehr als {{ZeichenNummer}} Zeichen verwenden.',
        couldContainsOnlyNumbers: 'Sie können in diesem Feld keine Buchstaben verwenden.',
        firstName: 'Bitte geben Sie den Vornamen ein.',
        lastName: 'Bitte geben Sie den Vornamen ein.',
        fullCompanyName: 'Bitte füllen Sie den vollständigen Firmennamen aus',
        email: 'Bitte E-Mail-Adresse eintragen',
        emailPattern: 'Die E-Mail hat das falsche Format. Bitte verwenden Sie das folgende Format: john@gmail.com',
        accountNumber: 'Bitte geben Sie die Alcon Kontonummer ein',
        termsAndConditions: 'Bitte akzeptieren Sie die Pflichtfelder',
        responseError:
          'Es gab ein Problem mit Ihrer Anfrage. Bitte versuchen Sie es erneut oder wenden Sie sich an den Kundendienst',
      },
    },
    franchisePage: {
      surgicalProduct: 'Chirurgische Produkte (Custom Packs, IOLs, etc.)',
      visionCareProduct: 'Sehhilfen (Kontaktlinsen, Augenpflegemittel, usw.)',
      next: 'Weiter',
    },
    contactusPage: {
      pleaseSelectATopic: 'Bitte wählen Sie ein Thema aus:*',
      typeYourMessageHere: 'Geben Sie hier Ihre Nachricht ein:*',
      contactMethod: 'Bitte wählen Sie Ihre bevorzugte Kontaktmethode für diese Anfrage aus:*',
      contactMethodLabel1: 'E-mail',
      contactMethodLabel2: 'Telefon',
      emailAddress: 'E-Mail-Adresse',
      emailInfo:
        'IDie oben eingegebenen Informationen ändern nicht die mit Ihrem Konto verbundenen Kontaktinformationen. Sie können Ihre Telefonnummer in „Mein Profil“ ändern oder sich an den Kundenservice wenden, um Ihre E-Mail-Adresse zu ändern.',
      termsConditions:
        'Alcon verwendet diese Informationen ausschließlich zur Beantwortung Ihrer Anfrage. Ich habe die Datenschutzrichtlinie von Alcon gelesen und stimme ihren Geschäftsbedingungen zu.*',
      preferredTime: 'Wählen Sie die gewünschte Zeit aus.',
      preferredTimeLabel1: 'Morgens',
      preferredTimeLabel2: 'Nachmittags',
      contactName: 'Bitte geben Sie einen Kontaktnamen an:*',
      contactNameLabel: 'Ansprechpartner',
      fullCompanyName: 'Vollständiger Firmenname',
      returnToLastVisitedPage: 'Rückkehr zur zuletzt besuchten Seite',
      mandatoryFields: '* Pflichtfelder',
      provideContactName: 'Bitte geben Sie einen Kontaktnamen an:*',
      contactusSubmit: 'Senden',
      phoneNumber: 'Telefonnummer',
      characters: '1000 Zeichen',
      thankyoumsg: 'Vielen Dank für Ihre Einreichung.',
      additionalmsg:
        'Ein Alcon-Vertreter wird sich innerhalb von 1 Werktag mit Ihnen in Verbindung setzen, um diese Angelegenheit zu besprechen.',
      returntoHome: 'Zurück zur Startseite',
      returnProdct: 'Produkt zurücksenden',
      gotoform: 'Zum Formular gehen',
      selectFile: 'Select file',
      errorMessages: {
        topic: 'Bitte wählen Sie das Thema aus.',
        mandatoryField: 'Dieses Feld ist ein Pflichtfeld.',
        message: 'Bitte füllen Sie die Nachricht aus.',
        contactmethod: 'Bitte wählen Sie mindestens eine Kontaktmethode aus.',
        email: 'Bitte füllen Sie die E-Mail aus.',
        emailPattren: 'Die E-Mail hat das falsche Format. Bitte verwenden Sie das folgende Format: john@gmail.com',
        phoneNumber: 'Bitte füllen Sie das Telefon aus',
        phoneNumberCharecters: 'Sie können in diesem Feld keine Buchstaben verwenden.',
        phoneNumberMinLength:
          'Die Telefonnummer ist zu kurz. Bitte überprüfen Sie, ob Sie alle Zeichen eingegeben haben.',
        preferredTime: 'Bitte wählen Sie die bevorzugte Kontaktzeit aus.',
        contactName: 'Bitte geben Sie den Kontaktnamen ein.',
        termsConditions: 'Bitte wählen Sie die allgemeinen Geschäftsbedingungen aus.',
        contactNamePattern: 'Sie können nicht mehr als 30 Zeichen in dieses Feld eingeben.',
      },
      salesRep: {
        title: 'Mein Vertriebsmitarbeiter',
        phone: 'Telefon:',
        email: 'Per E-Mail:',
      },
      selectone: 'Auswählen',
    },
    selectProductPage: {
      selectPackType: '1. Packungstyp auswählen',
      selectProducts: '2. Produkte auswählen',
      regular: 'Standard',
      valuePack: 'Vorteilspack',
      chooseLenses: 'Linsen auswählen:*',
      patientName: 'Kunde: ',
      valuePacktooltip: 'Bestimmte Kontaktlinsen und Lösungen werden in einem Komplettpacket angeboten.',
      additionalProducts: 'Wählen Sie weitere Produkte:',
      contactLens: 'Pflegemittel',
      dryEye: 'Augenpflege',
      addAnotherproduct: 'Ein weiteres Produkt hinzufügen',
      mandatory: '* Pflichtfelder',
      addOtherLenses: 'KL hinzufügen',
      valuePackAttributes: {
        threeMonthValuePack: '3-Monats-Packung',
        sixMonthValuePack: '6-Monats-Packung',
        chooseSolution: 'Lösung auswählen:*',
      },
      patientOrderPanel: {
        delivery: '3. Lieferung',
        practiceOrder: 'Lieferung ins Geschäft',
        directToPatient: 'Lieferung direkt zum Kunden',
        selectOrderType: '4. Auftragsart auswählen',
        individual: 'einmalige Bestellung',
        total: 'Gesamt:',
        addToCart: 'in den Warenkorb',
        addToCartSuccess: 'IM WARENKORB',
        cancel: 'ABBRECHEN',
        address: 'Adresse:',
        delivertodiffAddress: 'An eine andere Adresse liefern',
        selectdeliveryAddress: 'Lieferadresse auswählen:',
        dropdown: {
          buttonText: 'Kunde hinzufügen',
        },
        scheduled: {
          orderType: 'Abo Auftrag',
          repeatEveryText: 'Lieferung alle:',
          week: 'Wochen',
          month: 'Monate',
          startDate: 'Anfangsdatum:',
          save: 'Speichern',
          startSubscriptions: 'ABO STARTEN',
          addToCartNow: 'IN DEN WARENKORB',
        },
        title: 'Hinzufügen zum Warenkorb fehlgeschlagen',
        description:
          '"Einheit wurden nicht zum Warenkorb hinzugefügt. Bitte beheben Sie die Probleme, und versuchen Sie es erneut."',
        redirectButton: 'OK',
      },
      tooltipText:
        'Sie können kein zweites Produkt auswählen, wenn zwei Augen ausgewählt sind. Wenn Sie ein weiteres Produkt für denselben Kunden hinzufügen möchten, entfernen Sie die Markierung für ein Auge.',
    },
    siteDirectory: {
      errorMessage: {
        accountNotSetup: 'Ihr Konto ist nicht für dieses Land eingerichtet. Bitte wählen Sie den richtigen Standort.',
      },
      heading: 'MyAlcon Store Global Directory',
    },
    safeOrderingPage: {
      safeOrdering: 'Sichere Bestellung',
    },
    configureProducts: {
      deliveryPanel: {
        delivery: 'Lieferung',
        selectDelivery: 'Versand auswählen',
        practiceOrder: 'Lieferung ins Geschäft',
        directToPatient: 'Lieferung direkt zum Kunden',
        patientReferencePlaceholder: 'Typ-Referenz',
        patientReference: 'Referenz des Kunden: ',
        patientReferenceMandatory: 'Patient reference:*',
        needStockOrder: 'Benötigen Sie eine Lagerbestellung?',
        practiceErrorMessage: 'Sie können nicht mehr als 30 Zeichen eingeben.',
        noResultsFound: 'Keine Ergebnisse gefunden',
        selectFromList: 'Aus der Liste auswählen',
        deliveryToDifferentAddress: 'Lieferung an andere Adresse',
      },
    },
    orderDetailsPage: {
      orderNo: 'Bestell-Nr.',
      shipped: 'Versendet',
      inProgess: 'In Bearbeitung',
      delivered: 'Zugestellt',
      billOnly: 'Nur Rechnung',
      products: 'Produkte',
      ACCOUNT: 'Kauf auf Rechnung',
      CASH: 'Cash on Delivery',
      CARD: 'Kreditkarte',
      statusPanel: {
        partiallyShipped: 'Teilweise versandt',
        trackingno: 'Tracking-Nr.:',
      },
      paymentMethodPanel: {
        orderPlaced: 'Bestellung aufgegeben:',
        orderDate: 'Bestell datum:',
        orderSource: 'Auftragsquelle:',
        paymentMethod: 'Zahlungsmethode:',
        cardEndingWith: 'Karte endet mit ***',
        cardEndsWith: 'Karte endet mit',
        invoiceno: 'Rechnungs-Nr.',
        addNewCard: 'Neue Karte hinzufügen',
        noCreditCard: 'Sie haben keine Kreditkarten mit diesem Konto verknüpft.',
        Change: 'Ändern',
        useOneTime: 'KARTE EINMAL BENUTZEN',
        useAndSaveLater: 'VERWENDEN UND FÜR SPÄTER SPEICHERN',
        useSelectedCard: 'AUSGEWÄHLTE KARTE VERWENDEN',
        useAndSaveDefault: 'BENUTZEN UND SPEICHERN ALS DEFAULT',
        useNewCard: 'NEUE KARTE VERWENDEN',
        invoiceToAccount: 'Kauf auf Rechnung',
      },
      deliveryMethodPanel: {
        practiceOrders: 'Lieferung ins Geschäft',
        deliveryMethod: 'Lieferung:',
        shippingAddress: 'Lieferadresse:',
        poNumber: 'Ihre Auftragsnr:',
        DTPOrders: 'Lieferung direkt zum Kunden',
      },
      finalPrice: {
        reOrder: 'Nachbestellen',
      },
      orderItemPage: {
        INDIVIDUAL_ORDER: 'Individuelle Bestellung',
        STOCK_ORDER: 'Lagerbestellung',
        VALUE_PACK: 'Vorteilspack',
        total: 'Gesamt',
        item: '{{count}} Stück',
        item_plural: '{{count}} Stück',
        materialNumber: 'Einheit Nr.',
        serialNumber: 'Serien-Nr.',
        IOL_Bill: 'IOL-Rechnung',
        diopter: 'Dioptrien',
        estDeliveryDate: 'Voraussichtliches Lieferdatum',
        deliveryDate: 'Lieferdatum',
        'labelstatus_In transit': 'Im Transit:',
        'labelstatus_In delivery': 'In Lieferung:',
        labelstatus_delivered: 'Geliefert:',
        labelstatus_undefined: '',
        fromitem: '{{othercount}} von {{count}} Einheit',
        tooltip:
          '{{othercount}} von {{count}} Gegenständen haben den {{status}}. Der Rest der Einheit hat einen anderen Status',
        exptDeliveryDate: 'Voraussichtliches Lieferdatum',
        customerReference: 'Kundenreferenz',
        partialBODate_itemsShipped:
          '{{shippedQuantity}} Einheit wurden an Sie versandt. Die verbleibenden {{backOrderQuantity}} Einheit werden nachher versandt: {{backOrderDate}}',
        partialBODate_itemsNotShipped:
          'ie erhalten {{unshippedQuantity}} Einheit. Die verbleibenden {{backOrderQuantity}} Einheit werden nach versandt: {{BackOrderDate}}',
        partialBOWithoutDate_itemsShipped:
          '{{shippedQuantity}} Einheit wurden an Sie versandt. Die verbleibenden {{backOrderQuantity}} Einheit werden so schnell wie möglich an Sie verschickt.',
        partialBOWithoutDate_itemsNotShipped:
          'Sie erhalten {{unversendeteMenge}} Einheit. Die verbleibenden {{BackOrderQuantity}} Einheit werden so schnell wie möglich an Sie verschickt.',
        fullBO_withDate: 'Dieses Produkt ist vorübergehend nicht auf Lager. Es wird nachgeschickt: {{backOrderDate}}',
      },
    },
    selectDeliveryAddressPage: {
      mainaddress: 'Hauptadresse',
      additonaladdress: 'Zusätzliche Adresse',
      edit: 'Bearbeiten',
      cancel: 'ABBRECHEN',
      save: 'Speichern',
      mandatory: '* Pflichtfelder',
    },
    patientAddressPage: {
      heading: 'Adresse:',
      form: {
        address: {
          street: 'Straße und Hausnummer *',
          building: 'Gebäude / Wohnung / Sonstiges',
          postcode: 'Postleitzahl *',
          city: 'Ort*',
          county: 'Bundesland',
          notMandatoryCounty: 'Bundesland',
          country: 'Land *',
        },
        mandatoryFields: '* Pflichtfelder',
        additionalAddress: 'Zusätzliche Adresse hinzufügen',
        clearAll: 'Alle löschen',
        save: 'Speichern',
        cancel: 'ABBRECHEN',
        update: 'Aktualisiert',
        deleteadditionaladdress: 'Zusätzliche Adresse löschen',
        errorMessages: {
          mandatoryField: 'Dieses Feld ist ein Pflichtfeld.',
          street: 'Bitte geben Sie die Adresse des Kunden ein.',
          buildingNumberName: 'Bitte tragen Sie die Gebäudenummer des Kunden ein.',
          postcode: 'Bitte geben Sie die Postleitzahl des Kunden ein.',
          city: 'Bitte geben Sie die Stadt des Kunden ein.',
          county: 'Bitte geben Sie den Landkreis des Kunden ein.',
          country: 'Bitte geben Sie das Land des Kunden ein.',
          invalidPattren: 'Ungültige Postleitzahl',
          selectCountry: 'Bitte wählen Sie ein Land',
        },
      },
    },
    recentOrders: {
      lastOrders: 'Letzte Bestellungen',
      latestOrders: 'Jüngste Bestellungen',
      seeMore: 'Weitere Informationen zur Bestellhistorie',
      noOrders: 'Sie haben keine Bestellungen.',
      noOrderText: 'Wenn Sie eine Bestellung absenden, können Sie den Status Ihrer Bestellung hier einsehen.',
      noOrdersSX:
        'Sie haben noch keine Produkte bestellt. Wenn Sie die Bestellung abschicken, können Sie den Status Ihrer Bestellung hier einsehen',
    },
    myProfilePage: {
      emailAddress: 'E-Mail-Adresse:',
      phoneNumber: 'Telefonnummer:',
      password: 'Passwort',
      changepassword: 'Passwort ändern',
      edit: 'Bearbeiten',
      preferences: 'Einstellungen',
      toggleLabelOn: 'Ja',
      toggleLabelOff: 'Nein',
      receiveOrderConfirmation: 'ICH möchte Bestellbestätigungen und Versandbenachrichtigungen per E-Mail erhalten',
      updatePreferencesSuccess: 'Ihre Einstellungen wurden erfolgreich geändert',
      contactInformationUpdateSuccess: 'Ihre Kontaktinformationen wurden erfolgreich aktualisiert',
      updatePreferencesFail:
        'Es gab ein Problem mit der Aktualisierung Ihrer Einstellungen. Bitte versuchen Sie es erneut oder wenden Sie sich an den Kundendienst',
    },
    contactInformation: {
      title: 'Titel',
      firstName: 'Vorname*',
      lastName: 'Nachname*',
      email: 'E-Mail-Adresse',
      phonenumber: 'Telefonnummer',
      cancel: 'ABBRECHEN',
      save: 'Speichern',
      clear: 'Löschen',
      errorMessages: {
        requireFirstName: 'Bitte geben Sie den Vornamen ein.',
        moreThanThirtyCharacters: 'Sie können nicht mehr als 30 Zeichen in dieses Feld eingeben..',
        requireLastName: 'Bitte geben Sie den Vornamen ein.',
        phoneNumber: 'Sie können in diesem Feld keine Buchstaben verwenden.',
        phoneNumberMinLength:
          'Die Telefonnummer ist zu kurz. Bitte überprüfen Sie, ob Sie alle Zeichen eingegeben haben.',
      },
    },
    changePassword: {
      currentpassword: 'Ihr aktuelles Passwort*',
      newpassword: 'Neues Passwort*',
      reenterpassword: 'Neues Passwort erneut eingeben*',
      mandatory: '* Pflichtfelder',
      showpassword: 'Passwort anzeigen',
      cancel: 'ABBRECHEN',
      save: 'Speichern',
      resetPassword: 'E-Mail zum Zurücksetzen des Passworts senden',
      suggestion: 'Verwenden Sie 8 oder mehr Zeichen mit einer Mischung aus Buchstaben, Zahlen und Symbolen.',
      errorMessages: {
        requireCurrentpassword: 'Bitte geben Sie das aktuelle Passwort ein.',
        requireNewPassword: 'Bitte geben Sie das neue Passwort ein.',
        requireReEnterpassword: 'Bitte geben Sie das neue Passwort erneut ein.',
        match: 'Ihre Passwörter stimmen nicht überein. Versuchen Sie es erneut.',
        strong:
          'Bitte wählen Sie ein stärkeres Passwort. Versuchen Sie es mit einer Mischung aus Buchstaben, Zahlen und Symbolen.',
        maxCharcters: 'Verwenden Sie mindestens 8 Zeichen für Ihr Passwort.',
      },
      resetPasswordConfirmation: 'E-mail zum Passwort zurücksetzen wurde erfolgreich versendet',
      resetPasswordError:
        'Etwas ist schief gelaufen. Bitte versuchen Sie es erneut oder kontaktieren Sie den Kundenservice',
    },
    patientOrderHistory: {
      io: 'Einzelaufträge',
      subscription: 'Dauerauftrag',
      subscriptions: 'Abonnements',
      individualorders: 'Individuelle Bestellung',
      regular: 'Regular',
      valuepack: 'Value pack',
      practiceorder: 'Geschäftsauftrag',
      directtopatient: 'Direkt Zum Kunden',
      switchSub: 'Zum Abo wechseln',
      nof: 'Im angegebenen Zeitraum wurden keine Bestellungen gefunden.',
      orderNo: 'Bestell-Nr.',
      mainAddress: 'Hauptadresse',
      addAddress: 'Zusätzliche Adresse',
      orderPlaced: 'Bestellung aufgegeben:',
      orderSent: 'Bestellung versandt:',
      nextOrder: 'Nächste Bestellung:',
      subCreated: 'Abo erstellt:',
      every: 'Jede',
      active: 'Aktiv',
      inactive: 'inaktiv',
      noOrdersYet: 'Noch keine Bestellungen',
      oneTime: 'Einmal',
      reOrder: 'Nachbestellen',
      frequency: 'Häufigkeit',
      deleteSub: 'Sind Sie sich sicher, dass Sie diesen Dauerauftrag löschen wollen?',
      editSub: 'Möchten Sie diesen Dauerauftrag aktivieren?',
      scheduleOrderYes: 'Ja',
      scheduleOrderNo: 'Nein',
      canDelOnlyActive: 'Sie können lediglich inaktive Daueraufträge löschen.',
      deactivateScheduleOrder: 'Möchten Sie diesen Dauerauftrag wirklich deaktivieren?',
      popupYesText: 'Ja',
      popupNoText: 'Nein',
      activeToInactive: 'Aktives Abo. \n Bitte klicken um zu deaktivieren.',
      inactiveToActive: 'Inaktives Abo. \n Bitte klicken um zu deaktivieren.',
      delPatientConfirmation: 'Wollen Sie diesen Kunden wirklich löschen?',
      delPatient: 'Wirklich löschen?',
      delPatientSuceess: 'Kunde erfolgreich gelöscht',
      deletedSuccessfully: 'Erfolgreich gelöscht',
      delPatientFail: 'Wir können diesen Kunden nicht löschen',
      delFailMsg:
        'Es liegen weitere Artikel im Warenkorb  \n Bitte löschen Sie diese und verwenden Sie dann erneut die Löschfunktion.',
      goToCart: 'Zum Warenkorb gehen',
      cartLink: 'Warenkorb',
      hasActiveOrders: 'Dieser Kunde hat aktive Daueraufträge.',
      infoMsg:
        'Wenn Sie den Kunden jetzt <br> löschen, werden bereits übermittelte Aufträge noch bearbeitet <br> und alle bestehenden Daueraufträge werden gelöscht <br> Dies kann nicht mehr rückgängig gemacht werden.',
    },
    accountDetailsPage: {
      requestChangeAddress: 'Adressänderung anfordern',
      shippingAddress: 'Lieferadresse',
      billingAddress: 'Rechnungsanschrift',
      orderconsolidationenabled: 'Auftragskonsolidierung aktiviert',
      alconAccount: 'Alcon Kunden',
      shippingAccount: 'Versand Kunden',
    },
    commonTitles: {
      edit: 'Bearbeiten',
      delete: 'Löschen',
    },
    visioncareSearch: {
      searchFor: 'Suche nach:',
      searchProductPlaceholder: `Geben Sie den Namen des Produkts ein`,
      searchPatientPlaceholder: `Geben Sie den Namen des Patienten ein`,
      patient: 'Kunden',
      product: 'Produkt',
      invoicetoaccount: 'Kauf auf Rechnung',
    },
    subscriptions: {
      nosubscriptions: 'Sie haben keine Abos.',
      subscriptions: 'Abonnements',
      allPatients: 'Alle Kunden',
      subscriptionMsg:
        'Abonnements sind geplante Bestellungen, die direkt an Kunden oder in ein Geschäft geliefert werden.',
      subscriptionSuccessMessage: 'Das Abonnement ist aktiv. Die erste Bestellung wird am ausgewählten Tag versendet.',
      subscriptionInactiveMessage: 'Das Abonnement ist inaktiv. Änderungen gespeichert.',
    },
    returnForm: {
      noProducts: 'Es gibt keine Produkte, die zurückgegeben werden müssen.',
      searchProducts: 'Produkt suchen oder aus der Liste auswählen',
      selectProductHeader: 'Wählen Sie das Produkt aus, das Sie zurückgeben möchten:',
      addAnotherProduct: 'Fügen Sie ein weiteres Produkt zur Rückgabe hinzu:',
      baseCurve: 'Basiskurve:',
      power: 'Stärke:*',
      add: 'ADD-Wert:*',
      colour: 'Farbe*:',
      cylinder: 'Zylinder*:',
      axis: 'Achse*:',
      packSize: 'Verpackungsgröße:',
      diameter: 'Durchmesser:',
      ordernumber: 'Bestell-Nr.:*',
      quantity: 'Menge',
      mandatory: '* Pflichtfelder',
      delete: 'Produkt löschen',
      edit: 'Produkt Bearbeiten',
      save: 'PRODUKT SPEICHERN',
      returnReason: 'Grund für die Rückgabe',
      dateoforder: 'Datum der Bestellung:',
      reasonMandatory: 'Bitte geben Sie den Grund für die Rücksendung der Produkte an *',
      selectreason: 'Grund auswählen',
      quantityError:
        'Sie können nicht mehr Produkte eines bestimmten Typs auswählen, als bestellt wurden. Wenn Sie sich über die Produktmenge sicher sind, überprüfen Sie die Bestellnummer.',
      orderNumberError:
        'Diese Bestellnummer wurde bereits in Ihrem Rücksendeformular verwendet. Ändern Sie Ihre Bestellnummer oder löschen Sie diesen Einheit.',
      returnproduct: 'PRODUKT ZURÜCKSENDEN',
      completeFormError: 'Füllen Sie das Formular aus, um Produkte zurückzugeben',
      specifications: {
        baseCurve: 'Basiskurve:',
        power: 'Stärke:*',
        add: 'ADD-Wert:*',
        colour: 'Farbe:',
        cylinder: 'Zylinder:',
        axis: 'Achse:',
        packSize: 'Verpackungsgröße:',
        diameter: 'Durchmesser:',
        ordernumber: 'Bestell-Nr.:',
        quantity: 'Menge',
      },
      confirmation: {
        header: 'Ihre Rücksendung ist fertig',
        returnInfoMessage: 'Ihr Rücksendeformular wird an Ihre E-Mail-Adresse gesendet:',
        remember: '',
        sendcopy: 'Kopie an alternative E-Mail-Adresse senden',
        alternativeEmail: 'Geben Sie eine alternative E-Mail an',
        send: 'SENDEN',
        emailError: 'Die E-Mail hat das falsche Format. Bitte verwenden Sie das folgende Format: john@gmail.com',
      },
    },
    statements: {
      statementForDownload: 'Abrechnung zum Herunterladen',
      tooltipText: 'Die erste Abrechnung wird zu Beginn des nächsten Monats verfügbar sein.',
      downloadText: 'Abrechnung herunterladen (PDF)',
    },
    reOrderValidation: {
      DIRECT_TO_PATIENT_MESSAGE:
        'Einheit für die Hauptadresse des Kunden wurden zum Warenkorb hinzugefügt. Überprüfen Sie die Adresse im Warenkorb.',
      GENERIC_API_ERRORMESSAGE:
        'Einheit wurden nicht zum Warenkorb hinzugefügt. Bitte versuchen Sie es später erneut oder wenden Sie sich an den Kundenservice.',
      GENERIC_MESSAGE: 'Einheit wurden zum Warenkorb hinzugefügt.',
      heading: 'Es gibt ein Problem mit Ihrer Nachbestellung.',
      itemDiscontinued: 'Bitte wenden Sie sich an den Kundenservice.',
      maxLimit:
        'Bitte übermitteln Sie Ihren aktuellen Warenkorb, bevor Sie Produkte nachbestellen oder Ihre Bestellung neu konfigurieren.',
      ok: 'OK',
      patientDeleted: 'Kunde wurde gelöscht und kann nicht mehr bestellen.',
      valuPackexist: 'Bitte übermitteln Sie Ihren aktuellen Warenkorb, bevor Sie Produkte nachbestellen.',
      valuePackQauntity: '1 Einheit',
      vpSKUDiscontinued: 'Bitte konfigurieren Sie Ihre Bestellung neu oder wenden Sie sich an den Kundendienst.',
    },
    autoOrderingTranslations: {
      heading: 'Automatischer Einkaufswagen aktiviert',
      timeSet: 'Eingestellte Zeit',
      setTime: 'Eingestellte Zeit',
      save: 'Speichern',
      cancel: 'ABBRECHEN',
      ENABLE_SUCCESS_MSG: 'Automatische Warenkorbbestellung ist zum angegebenen Zeitpunkt aktiviert',
      DISABLE_SUCCESS_MSG: 'Die automatische Warenkorbbestellung ist deaktiviert',
      ERROR_MSG:
        'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut. Wenn das Problem wiederholt auftritt, wenden Sie sich bitte an den Kundendienst',
    },
    faqSearch: {
      searchLabel: 'Suchen Sie nach einer Antwort',
      searchSuggestion: 'Sie können auch die nachstehenden Themen durchsuchen, um das zu finden, wonach Sie suchen. ',
    },
    sxRegistration: {
      email: 'E-Mail-Adresse',
      cancel: 'ABBRECHEN',
      next: 'Weiter',
      emailVal: 'Bitte geben Sie eine gültige E-Mail ein',
      title: 'Registrierung bei MyAlcon',
      passwordVal: 'Bitte geben Sie Ihr Passwort ein',
      mandatory: '* Pflichtfelder',
      login: 'LOG IN',
      invalidEmail: 'Ungültige E-Mail Adresse',
      emailExist: 'Ein Benutzer mit dieser {0} existiert bereits',
      phonelong: 'Telefonnummer is too long',
      phoneshort: 'Telefonnummer is too short',
      select: 'Wählen',
      consentLinks: {
        termsCondition: 'https://www.alcon.com/terms-use?_ga=2.124201935.434608583.1555915818-1936277745.1549032292',
        privacyPolicy:
          'https://www.alcon.com/privacy-policy?_ga=2.124201935.434608583.1555915818-1936277745.1549032292',
        marketingPrivacyPolicy:
          'https://www.alcon.com/privacy-policy?_ga=2.124201935.434608583.1555915818-1936277745.1549032292',
      },
    },
    loginPage: {
      email: 'E-Mail-Adresse',
      cancel: 'Zurück zum Login',
      emailVal: 'Bitte geben Sie eine gültige E-Mail ein',
      passwordVal: 'Bitte geben Sie Ihr Passwort ein',
      login: 'LOG IN',
      sxTitle: 'Surgical Deutschland',
      vcTitle: 'Visioncare Deutschland',
      selectZone: 'Zone wählen',
      selectBranch: 'Niederlassung wählen',
    },
    toggleValues: {
      active: 'Aktiv',
      inactive: 'Inaktiv',
      yes: 'Ja',
      no: 'Nein',
    },
  },

  asm: {
    asm: {
      mainLogoLabel: 'ASM',
      mainTitle: 'Assisted-Service-Modus',
      logout: 'Abmelden',
      hideUi: 'ASM schließen',
      toggleUi: {
        collapse: 'ASM ausblenden',
        expand: 'ASM anzeigen',
      },
      loginForm: {
        submit: 'Anmelden',
        userId: {
          label: 'Vertreter-ID',
          required: 'Agent ID ist erforderlich',
        },
        password: {
          label: 'Passwort',
          required: 'Passwort ist erforderlich',
        },
      },
      customerSearch: {
        searchTerm: {
          label: 'Kundenname/E-Mail-Adresse',
        },
        submit: 'Sitzung starten',
        noMatch: 'Kein Kunde gefunden.',
      },
      endSession: 'Sitzung beenden',
      agentSessionTimer: {
        label: 'Sitzungszeitüberschreitung',
        minutes: 'min',
        reset: 'Zurücksetzen',
      },
    },
  },
  autoOrderingTranslations: {
    heading: 'Automatischer Einkaufswagen aktiviert',
    timeSet: 'Eingestellte Zeit',
    setTime: 'Eingestellte Zeit',
    save: 'Speichern',
    cancel: 'ABBRECHEN',
    ENABLE_SUCCESS_MSG: 'Automatische Warenkorbbestellung ist zum angegebenen Zeitpunkt aktiviert',
    DISABLE_SUCCESS_MSG: 'Die automatische Warenkorbbestellung ist deaktiviert.',
    ERROR_MSG:
      'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut. Wenn das Problem wiederholt auftritt, wenden Sie sich bitte an den Kundendienst',
  },
};
