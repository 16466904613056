<div class="container">
  <h2 *ngIf="!editPatient" class="add-patient-heading">{{ 'myPatientsPage.addNewPatient.heading' | cxTranslate }}</h2>
  <h2 *ngIf="editPatient" class="add-patient-heading">
    {{ 'myPatientsPage.addNewPatient.editHeading' | cxTranslate }}
  </h2>
  <form class="add-patient-form" [formGroup]="addPatientForm" novalidate>
    <div class="row">
      <div class="personal-data col-lg-5 col-sm">
        <h5 class="section-heading">{{ 'myPatientsPage.addNewPatient.personalData' | cxTranslate }}</h5>
        <div class="row padding-x row-min-height">
          <div class="col-lg-3 col-2 row-min-height">
            <label class="label">{{ 'myPatientsPage.addNewPatient.form.title' | cxTranslate }}</label>
            <app-dropdown-selector
              [formDropDown]="true"
              [resetDropDown]="resetTitleDropDown"
              [options]="titles$ | async"
              (dropDownSelected)="titleSelected($event)"
              defaultFormSelectedValue="{{ 'productListingPage.select' | cxTranslate }}"
            >
            </app-dropdown-selector>
          </div>
          <div class="col-lg-7 col-3 row-min-height">
            <label class="label">{{ 'myPatientsPage.addNewPatient.form.firstName' | cxTranslate }}</label>
            <input
              type="text"
              class="form-control input-styles"
              maxlength="30"
              formControlName="firstName"
              (blur)="trimString(firstName)"
              [ngClass]="{
                'invalid-field-border': firstName.errors && (firstName.dirty || firstName.touched),
                'valid-field-border': firstName.valid && firstName.dirty && firstName.touched
              }"
            />
            <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)" class="error-message-styles">
              <div *ngIf="firstName.errors?.required">
                {{ 'myPatientsPage.addNewPatient.form.errorMessages.mandatoryField' | cxTranslate }}
                {{ 'myPatientsPage.addNewPatient.form.errorMessages.firstName' | cxTranslate }}
              </div>
            </div>
          </div>
          <div class="col row-min-height">
            <label class="label">{{ 'myPatientsPage.addNewPatient.form.surName' | cxTranslate }}</label>
            <input
              type="text"
              class="form-control input-styles"
              maxlength="30"
              formControlName="lastName"
              (blur)="trimString(lastName)"
              [ngClass]="{
                'invalid-field-border': lastName.invalid && (lastName.dirty || lastName.touched),
                'valid-field-border': lastName.valid && lastName.dirty && lastName.touched
              }"
            />
            <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)" class="error-message-styles">
              <div *ngIf="lastName.errors?.required">
                {{ 'myPatientsPage.addNewPatient.form.errorMessages.mandatoryField' | cxTranslate }}
                {{ 'myPatientsPage.addNewPatient.form.errorMessages.surName' | cxTranslate }}
              </div>
            </div>
          </div>
        </div>

        <div class="col row-min-height">
          <label class="label">{{ 'myPatientsPage.addNewPatient.form.email' | cxTranslate }}</label>
          <input
            type="email"
            class="form-control input-styles"
            formControlName="email"
            (blur)="trimString(email)"
            [ngClass]="{
              'invalid-field-border': email.invalid && (email.dirty || email.touched),
              'valid-field-border': email.valid && email.dirty && email.touched
            }"
          />
          <div *ngIf="email.invalid && (email.dirty || email.touched)" class="error-message-styles">
            <div *ngIf="email.errors?.email || email.errors?.pattern">
              {{ 'myPatientsPage.addNewPatient.form.errorMessages.email' | cxTranslate }}
            </div>
          </div>
        </div>
        <div class="row row-min-height m-0">
          <div class="col-5 col-lg-10 row-min-height" formArrayName="userPhoneNumberDataList">
            <ng-container *ngFor="let item of userPhoneNumberDataList; index as i" [formGroupName]="i">
              <label class="label">{{ 'myPatientsPage.addNewPatient.form.phoneNumber' | cxTranslate }}</label>
              <input
                type="tel"
                class="form-control input-styles"
                formControlName="phoneNumber"
                maxlength="15"
                (blur)="trimString(phoneNumber)"
                [ngClass]="{
                  'invalid-field-border': phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched),
                  'valid-field-border': phoneNumber.valid && phoneNumber.dirty && phoneNumber.touched
                }"
              />
              <div
                *ngIf="phoneNumber.invalid && (phoneNumber.dirty || phoneNumber.touched)"
                class="error-message-styles"
              >
                <div *ngIf="phoneNumber.errors?.charactersNotAllowed">
                  {{ 'myPatientsPage.addNewPatient.form.errorMessages.phoneNumber' | cxTranslate }}
                </div>
                <div *ngIf="phoneNumber.errors?.minLength">
                  {{ 'myPatientsPage.addNewPatient.form.errorMessages.phoneNumberMinLength' | cxTranslate }}
                </div>
              </div>
            </ng-container>
          </div>
          <div class="col-5 col-lg-10 row-min-height">
            <label class="label">{{ 'myPatientsPage.addNewPatient.form.referenceNumber' | cxTranslate }}</label>
            <input
              type="text"
              class="form-control input-styles"
              formControlName="externalReferenceNumber"
              (blur)="trimString(externalReferenceNumber)"
              [ngClass]="{
                'invalid-field-border':
                  externalReferenceNumber.invalid && (externalReferenceNumber.dirty || externalReferenceNumber.touched),
                'valid-field-border':
                  externalReferenceNumber.valid && externalReferenceNumber.dirty && externalReferenceNumber.touched
              }"
            />
            <div
              *ngIf="
                externalReferenceNumber.invalid && (externalReferenceNumber.dirty || externalReferenceNumber.touched)
              "
              class="error-message-styles"
            >
              <div *ngIf="externalReferenceNumber.errors?.maxlength">
                {{ 'myPatientsPage.addNewPatient.form.errorMessages.moreThanThirtyCharacters' | cxTranslate }}
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <label class="mandatory-field">{{ 'myPatientsPage.addNewPatient.form.mandatoryFields' | cxTranslate }}</label>
        </div>
      </div>
      <div class="address-section col-lg-5">
        <ng-container formArrayName="addresses">
          <div class="personal-address" *ngFor="let address of addresses; index as i" [formGroupName]="i">
            <div class="d-flex">
              <span class="pl-3">
                <input
                  *ngIf="i == 0"
                  id="addressCheckbox"
                  type="checkbox"
                  [checked]="ischeckboxTicked"
                  (change)="mainAddressSelected()"
                  [disabled]="ischeckboxDisabled"
                  class="alconInput__checkbox"
                />
                <label for="addressCheckbox"></label>
              </span>
              <h5 *ngIf="i == 0" class="section-heading">
                {{ 'myPatientsPage.addNewPatient.mainAddress' | cxTranslate }}
              </h5>
              <h5 *ngIf="i == 1" class="section-heading">
                {{ 'myPatientsPage.addNewPatient.additionalAddress' | cxTranslate }}
              </h5>
            </div>
            <app-patient-address-form [AddressIndex]="i" [ShowClearAll]="false"></app-patient-address-form>
            <div *ngIf="i == 0 && ischeckboxTicked" class="row padding-x">
              <div class="col-7" *ngIf="showAdditionalAddress">
                <cx-icon
                  class="pointer"
                  *ngIf="!isAdditionalAddress"
                  type="AddOrderOption"
                  (click)="addAdditionalAddress(!isAdditionalAddress, i)"
                >
                  <p class="additional-address">
                    {{ 'myPatientsPage.addNewPatient.form.additionalAddress' | cxTranslate }}
                  </p>
                </cx-icon>
              </div>
            </div>
            <div *ngIf="i == 1 && ischeckboxTicked" class="row padding-x">
              <div class="col-7">
                <cx-icon class="Remove" type="Remove" (click)="deleteAdditionalAddress()">
                  <p class="delete-additional-address">
                    {{ 'myPatientsPage.addNewPatient.form.deleteadditionaladdress' | cxTranslate }}
                  </p>
                </cx-icon>
              </div>
              <p class="errorDisplay text-left mb-0 padding-x">{{ addressError }}</p>
            </div>
          </div>
        </ng-container>
        <div class="row padding-x">
          <app-legal-statement></app-legal-statement>
        </div>
      </div>
    </div>
  </form>

  <div class="row padding-x offset-5 justify-content-sm-end justify-content-md-end">
    <div class="col-2 col-lg-5 d-flex justify-content-center align-items-center cancel" (click)="cancelForm()">
      {{ 'myPatientsPage.addNewPatient.form.cancel' | cxTranslate }}
    </div>
    <div class="col-4 col-lg-5">
      <button *ngIf="!editPatient" class="btn btn-primary text-uppercase btn-save" (click)="onSubmit()">
        {{ 'myPatientsPage.addNewPatient.form.save' | cxTranslate }}
      </button>
      <button *ngIf="editPatient" class="btn btn-primary text-uppercase btn-save" (click)="onSubmit()">
        {{ 'myPatientsPage.addNewPatient.form.update' | cxTranslate }}
      </button>
    </div>
  </div>
</div>
