import { Action, createReducer, on } from '@ngrx/store';
import { LoadOrderFlag } from '../Actions/patient.action';
export const initialStateOrder = false;

const fetchOrderReducer = createReducer(
  initialStateOrder,
  on(LoadOrderFlag, (state, { fetchPatientOrders }) => {
    return fetchPatientOrders;
  })
);

export function fetchPatientOrderReducer(state = initialStateOrder, action: Action): boolean {
  return fetchOrderReducer(state, action);
}
