import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { PatientService } from '../services/patient/patient.service';

@Injectable({ providedIn: 'root' })
export class PatientProfileResolver implements Resolve<Observable<any>> {
  constructor(private patientService: PatientService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.patientService.getPatientProfileFromStore(route.paramMap.get('profileId'));
  }
}
