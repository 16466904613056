<div *ngIf="message" class="global-message global-message__success">
  <div class="global-message__text">
    <cx-icon class="global-message--close" type="SuccessClose" (click)="closeMessage()"></cx-icon>
    <div><cx-icon class="global-message--tick" type="SuccessTick"></cx-icon></div>
    <div>{{ message }}</div>
  </div>
</div>
<div *ngIf="errormessage" class="global-message alert alert-danger fade show">
  <i class="ico-times global-message--close" role="img" aria-label="Cancel" (click)="closeMessage()"></i>
  <div>{{ errormessage }}</div>
</div>
