import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CartItemsService } from 'src/app/services/cart-items/cart-items.service';
import { LineItemCountComponent } from 'src/app/shared/classes/lineitem.count';
import { CART_GROUP } from 'src/app/shared/constants/constants';
import { PageType } from 'src/app/shared/model/common.mode';
import {
  getDTPOrdersummary,
  getIndividualOrdersummary,
  getStockOrdersummary,
  getValuePackOrdersummary,
} from 'src/app/store/Selectors/cartItem.selector';
import { OrderTypeSummary } from 'src/app/store/States/cartItem.state';

@Component({
  selector: 'app-alconcart',
  templateUrl: './alconcart.component.html',
  styleUrls: ['./alconcart.component.scss'],
})
export class AlconcartComponent implements OnInit, OnDestroy {
  loading$ = this.cartItemsService.loading$;
  practiceOrderEntries = [];
  individualOrders = [];
  stockorders = [];
  valuePackOrders = [];
  directtoPatientOrderEntries = [];
  dtporders = [];
  showindividualOrders = true;
  showvaluePackOrders = true;
  showstockorders = true;
  showdtporders = true;
  get cartGroup(): typeof CART_GROUP {
    return CART_GROUP;
  }
  pageType: string;
  individualOrderInfo$: Observable<OrderTypeSummary>;
  stockOrderInfo$: Observable<OrderTypeSummary>;
  valuePacksInfo$: Observable<OrderTypeSummary>;
  dtpInfo$: Observable<OrderTypeSummary>;
  showAddress: boolean;
  deliveryInfo = {
    orderNumber: '',
    ponumber: '',
    deliveryMethod: '',
    deliveryAddress: null,
  };
  private destroySubscription$ = new Subject<void>();

  constructor(private route: ActivatedRoute, private store: Store, private cartItemsService: CartItemsService) {
    this.cartItemsService.reloadCart.pipe(takeUntil(this.destroySubscription$)).subscribe((cartData) => {
      const { rootGroups } = cartData;
      this.generateCartData(rootGroups);
    });
  }

  ngOnInit(): void {
    const { rootGroups } = this.route.snapshot.data.vcCart;
    this.generateCartData(rootGroups);
  }

  private generateCartData(rootGroups: any): void {
    this.pageType = this.route.snapshot.data.Type;
    if (this.pageType === PageType.ORDER_CONFIRMATION) {
      this.showAddress = true;
      this.valuePacksInfo$ = of(LineItemCountComponent.valuePackCount(rootGroups));
      this.individualOrderInfo$ = of(
        LineItemCountComponent.PracticeOrderCount(rootGroups, CART_GROUP.INDIVIDUAL_ORDER)
      );
      this.dtpInfo$ = of(LineItemCountComponent.DTPCount(rootGroups));
      this.stockOrderInfo$ = of(LineItemCountComponent.PracticeOrderCount(rootGroups, CART_GROUP.STOCK_ORDER));
      const orderInfo = this.route.snapshot.data.vcCart;
      if (orderInfo.rootGroups.length === 1) {
        this.deliveryInfo.orderNumber = orderInfo.code;
      }
      this.deliveryInfo.deliveryMethod = orderInfo?.deliveryMode?.name;
      this.deliveryInfo.ponumber = orderInfo?.purchaseOrderNumber;
      this.deliveryInfo.deliveryAddress = orderInfo.deliveryAddress;
    } else {
      this.individualOrderInfo$ = this.store.select(getIndividualOrdersummary);
      this.stockOrderInfo$ = this.store.select(getStockOrdersummary);
      this.valuePacksInfo$ = this.store.select(getValuePackOrdersummary);
      this.dtpInfo$ = this.store.select(getDTPOrdersummary);
      this.cartItemsService.clearCartErrorData();
    }
    const cartrootGroups = JSON.parse(JSON.stringify(rootGroups));
    const practiceOrderEntries = cartrootGroups.find((items) => items.label === CART_GROUP.PRACTICE_ORDER);
    const { entries: individualOdrerEntries = [] } = practiceOrderEntries?.children?.find(
      (order) => order.label === CART_GROUP.INDIVIDUAL_ORDER
    )
      ? practiceOrderEntries.children?.find((order) => order.label === CART_GROUP.INDIVIDUAL_ORDER)
      : { entries: [] };
    this.individualOrders = individualOdrerEntries?.map((value, index) => {
      return { ...value, positionId: value.orderEntryFlowType + '_' + index };
    });

    const indvidualOrder = practiceOrderEntries?.children?.find((child) => child.label === CART_GROUP.INDIVIDUAL_ORDER);
    if (indvidualOrder) {
      indvidualOrder.children = indvidualOrder?.children?.length ? indvidualOrder.children : [{ entries: [] }];
      const [{ entries: ValuePacks = [] }] = indvidualOrder.children;
      this.valuePackOrders = ValuePacks;
    } else {
      this.valuePackOrders = [];
    }
    const { entries: stockOrderEntries = [] } = practiceOrderEntries?.children?.find(
      (order) => order.label === CART_GROUP.STOCK_ORDER
    )
      ? practiceOrderEntries?.children?.find((order) => order.label === CART_GROUP.STOCK_ORDER)
      : { entries: [] };

    this.stockorders = stockOrderEntries?.map((value, index) => {
      return { ...value, positionId: value.orderEntryFlowType + '_' + index };
    });

    const { entries: dtpEntries = [] } = cartrootGroups.find((items) => items.label === CART_GROUP.DIRECT_TO_PATIENT)
      ? cartrootGroups.find((items) => items.label === CART_GROUP.DIRECT_TO_PATIENT)
      : { entries: [] };

    dtpEntries?.forEach(
      (patientData) =>
        (patientData.entries = patientData.entries.map((value, index) => {
          return {
            ...value,
            positionId: value.orderEntryFlowType + '_' + value.patientId + '_' + index,
          };
        }))
    );
    this.dtporders = dtpEntries;
    if (!this.deliveryInfo.orderNumber && this.pageType === PageType.ORDER_CONFIRMATION) {
      this.assignSplitOrderRef();
    }
    this.cartItemsService.setLoading(false);
  }
  assignSplitOrderRef(): void {
    if (this.individualOrders.length > 0) {
      this.deliveryInfo.orderNumber = this.individualOrders[0].splitOrderRef;
    } else if (this.stockorders.length > 0) {
      this.deliveryInfo.orderNumber = this.stockorders[0].splitOrderRef;
    } else if (this.valuePackOrders.length > 0 && this.valuePackOrders[0].entries) {
      this.deliveryInfo.orderNumber = this.valuePackOrders[0].entries[0].splitOrderRef;
    }
  }

  toggle(event, ordertype): void {
    switch (ordertype) {
      case CART_GROUP.INDIVIDUAL_ORDER:
        this.showindividualOrders = event;
        break;
      case CART_GROUP.VALUE_PACK:
        this.showvaluePackOrders = event;
        break;
      case CART_GROUP.STOCK_ORDER:
        this.showstockorders = event;
        break;
      case CART_GROUP.DIRECT_TO_PATIENT:
        this.showdtporders = event;
        break;
    }
  }

  ngOnDestroy(): void {
    this.destroySubscription$.next();
    this.destroySubscription$.complete();
  }
}
