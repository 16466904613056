import { Product } from '@spartacus/core';

export interface VariantTree {
  class?: string;
  leafNode?: boolean;
  nodeId?: string;
  nodeType?: string;
  nodeValue?: string;
  packingDescription?: string;
  parentNode?: string;
  quantity?: number;
  childrenNodes?: Array<VariantTree>;
}
export interface VariantChildNodeType {
  childNodeType?: string;
  childNodes?: Array<string>;
  leafNode?: boolean;
  maxQuantity?: number;
}
export interface SelectedVariantsType {
  basecurve?: string;
  diameter?: string;
  lenspower?: string;
  powerAdd?: string;
  color?: string;
  cylinder?: string;
  axis?: string;
}
export interface SelectedVariants {
  packSize?: string;
  baseCurve?: string;
  diameter?: string;
  lensPower?: string;
  powerAdd?: string;
  color?: string;
  cylinder?: string;
  axis?: string;
}
export interface BasicProductSelectedVariants {
  packSize?: string;
  basecurve?: string;
  diameter?: string;
  lenspower?: string;
  powerAdd?: string;
  color?: string;
  cylinder?: string;
  axis?: string;
  orders?: string;
  variant?: string;
}
export interface Specs {
  variantType: string;
  variants: BasicProductSelectedVariants;
}
export interface SelectedVariantValue {
  quantity?: number;
  isSelected?: boolean;
  details?: SelectedVariantDetails;
  eyeSide?: string;
  maxQuantityError?: string;
}
export interface SelectedVariantDetails {
  code?: string;
  maxQuantity?: number;
}
export interface PackSize {
  display?: string;
  factor?: string;
  trialCount?: string;
  isEnabled?: boolean;
  selected?: boolean;
  value?: string;
  unit?: string;
}
/** @deprecated */
export interface QuantityError {
  error?: boolean;
  msg?: string;
}
export interface VarientCode {
  quantity?: string;
  text?: string;
  value?: number;
  error?: string;
  entryNumber?: number;
  disabled?: boolean;
  power?: string;
  maxQuantity?: number;
  displayError?: boolean;
}
export interface ProductItems {
  code?: string;
  images?: ProductImages[];
  isOpen?: boolean;
  name?: string;
  groupingLabel?: string;
}
export interface ProductImages {
  altText?: string;
  format: string;
  imageType: string;
  url: string;
}
export interface ProductInfo {
  productCode?: string;
  specifications?: ProductSpecifications[];
  entries?: ProductEntries[];
  positionId?: string;
  product?: Product;
}
export interface ProductSpecifications {
  specificationKey: string;
  specificationValue: string;
}
export interface ProductEntries {
  entryNumber: number;
  quantity: number;
  product: EntryProduct;
  specifications?: ProductSpecifications[];
  name?: string;
  groupingLabel?: string;
}
export interface EntryProduct {
  code: number;
  categories: ProductCategories[];
}
export interface ProductCategories {
  name: string;
  code: string;
}
export interface ProductVarientTree {
  nodeValue?: string;
  nodeType?: string;
  nodeId?: string;
  leafNode?: boolean;
  childrenNodes?: ProductVarientTree[];
}
export interface ProductToricCombinations {
  text?: string;
  combinations?: AxisPowerCombinations[];
  show?: boolean;
}
export interface AxisPowerCombinations {
  axis?: string;
  power?: string;
  quantity?: string;
  disabled?: boolean;
  varientCode?: string;
  displayError?: boolean;
}
export interface EntryRoot {
  openPanel: boolean;
  entries: ProductInfo[];
  label: string;
}
export interface CategoryPage {
  products: Array<CategoryProducts>;
  isLandingPage: boolean;
}
export interface CategoryProducts {
  products?: Array<Product>;
  children?: Array<CategoryProducts>;
  CategoryName: string;
  count: number;
  CategoryCode: string;
  isOpen?: boolean;
}
export type BasicProductSelectorPanelConfig = {
  checkboxStatus: BasicProductSelectedCheckboxStatus;
  solutions: {
    multiLine: boolean;
  };
};

export type BasicProductSelectedCheckboxStatus = {
  leftEye: boolean;
  rightEye: boolean;
  bothEye: boolean;
};

export interface SKULevelDetails {
  code?: number;
  dtpMaxLimit?: number;
  maxQuantity?: number;
  unitFactor?: number;
  orderDate?: string;
  quantity?: number;
  entryNumber?: number;
}
export enum QuantityErrorCodes {
  MAX_QUANTITY = 'MAX_QUANTITY',
  DTP_MAX_QUANTITY = 'DTP_MAX_QUANTITY',
}

export enum CategoryCode {
  SERGICAL_BUSINESS = 'Surgical Business',
  CONTACT_LENSES = 'Contact Lenses',
}

export enum FilterDefaultSelection {
  ALL_CATEGORY = ':relevance:allCategories:',
}
