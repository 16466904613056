import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { OktaAuth } from '@okta/okta-auth-js';
import { OktaService } from 'src/app/services/okta/okta.service';
import { AlconSiteService } from '../../../../services/service/alcon-site-provider.service';

@Injectable({
  providedIn: 'root',
})
export class OktaLogoutGaurd {
  authClient;
  constructor(private oktaService: OktaService, private alconSiteService: AlconSiteService) {}
  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const { deafultRedirect = 'true' } = route.queryParams;
    const postLogoutRedirectUri =
      deafultRedirect === 'true'
        ? `/${this.alconSiteService.getCurrentSite()}/clear-session`
        : '/site-directory?logout=true';
    this.authClient = new OktaAuth(this.oktaService.getOktaConfig());
    const accessToken = await this.authClient.tokenManager.get('accessToken');
    this.authClient.tokenManager.clear();
    this.authClient.signOut({
      accessToken,
      postLogoutRedirectUri,
    });
    return true;
  }
}
