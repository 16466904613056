import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  AuthRedirectStorageService,
  AuthService,
  GlobalMessageService,
  GlobalMessageType,
  WindowRef,
} from '@spartacus/core';
import { from, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { LOGIN_ERROR_TYPE, VALIDATOR_CONSTANT } from 'src/app/shared/constants/constants';
import { LoginError } from 'src/app/shared/model/login-error.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: UntypedFormGroup;
  contentData: string;
  errorInfo: LoginError;
  private destroy$ = new Subject<void>();
  constructor(
    protected auth: AuthService,
    protected globalMessageService: GlobalMessageService,
    private authRedirectStorageService: AuthRedirectStorageService,
    protected fb: UntypedFormBuilder,
    protected winRef: WindowRef,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      loginEmail: ['', [Validators.required, Validators.email, Validators.pattern(VALIDATOR_CONSTANT.EMAIL_PATTREN)]],
      loginPassword: ['', Validators.required],
    });
    this.globalMessageService
      .get()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data.hasOwnProperty(GlobalMessageType.MSG_TYPE_ERROR) && data[GlobalMessageType.MSG_TYPE_ERROR].length) {
          this.errorInfo = JSON.parse(data[GlobalMessageType.MSG_TYPE_ERROR][0].params.errorMessage);
          if (this.errorInfo.errorType === LOGIN_ERROR_TYPE.INVALID_USER) {
            this.loginForm.controls.loginEmail.setErrors({ email: true });
            this.loginForm.controls.loginPassword.setErrors({ required: true });
          } else if (this.errorInfo.errorType === LOGIN_ERROR_TYPE.BAD_CREDENTIALS) {
            this.loginForm.controls.loginPassword.setErrors({ required: true });
          }
        }
        this.cd.detectChanges();
      });
  }
  navigateToForgotPwd(): void {
    this.router.navigate(['/forgot-password']);
  }
  submitForm(): void {
    if (this.loginForm.valid) {
      this.loginUser();
    } else {
      this.loginForm.markAllAsTouched();
    }
  }
  protected loginUser(): void {
    const { loginEmail, loginPassword } = this.loginForm.controls;
    from(
      this.auth.loginWithCredentials(
        loginEmail.value.toLowerCase(), // backend accepts lowercase emails only
        loginPassword.value
      )
    )
      .pipe(take(1))
      .subscribe((val) => {});
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
