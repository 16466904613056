<div class="select-product d-flex">
  <div class="select-product__container d-flex flex-column flex-grow-1">
    <div class="select-products">
      <h4 class="select-products__selectLabel">{{ 'selectProductPage.selectProducts' | cxTranslate }}</h4>
      <h3 class="select-products__select-Lenses">{{ 'selectProductPage.chooseLenses' | cxTranslate }}</h3>
    </div>
    <div class="select-product__lensdrpdn">
      <app-dropdown-selector-v2
        [options]="valuePackLensGroupOneProductsList | sortPipe: 'asc'"
        [config]="lensesLabel"
        (dropDownSelected)="valuePackGroupOneProductSelected($event)"
      >
      </app-dropdown-selector-v2>
    </div>
    <div *ngIf="firstProduct" class="select-product__productsSelector">
      <app-basic-product-selector
        [isValuePack]="isValuePack"
        [selectedProduct]="firstProduct?.value"
        (selectedProductLensValue)="selectedProductLensValue($event, 0)"
        (checkboxStatus)="checkboxStatusChange($event)"
        [disableCheckbox]="disableCheckbox"
        [editproductData]="editlenses[0]"
        [isDTP]="isDTP"
        [panelConfiguration]="firstProductConfiguration"
        (Errors)="setPanelErrors($event, firstProduct?.value)"
      >
      </app-basic-product-selector>
    </div>

    <div *ngIf="firstProduct" class="select-product__button">
      <button
        (click)="addotherLens()"
        class="btn addOtherLens__button_background"
        [ngClass]="{ 'd-none': addLens }"
        [disabled]="disableAddLens"
      >
        {{ 'selectProductPage.addOtherLenses' | cxTranslate }}
      </button>
    </div>

    <div *ngIf="addLens" class="d-flex">
      <div class="select-product__lensdrpdn">
        <app-dropdown-selector-v2
          *ngIf="isValuePack && !disableAddLens"
          [options]="valuePackLensGroupTwoProductsList | sortPipe: 'asc'"
          [config]="lensesLabel"
          (dropDownSelected)="valuePackGroupTwoProductSelected($event)"
        >
        </app-dropdown-selector-v2>
      </div>

      <div
        *ngIf="!disableAddLens"
        (click)="deleteValuePackAddedItem()"
        class="deleteSecondProduct__deleteItem--icon-remove"
      >
        <cx-icon class="Remove" type="Remove"> </cx-icon
        ><a class="deleteSecondProduct__deleteItem--icon-remove--deleteLink" href="javascript:void(0)">{{
          'miniCartPage.delete' | cxTranslate
        }}</a>
      </div>
    </div>

    <div *ngIf="secondProduct" class="select-product__productsSelector">
      <app-basic-product-selector
        [isValuePack]="isValuePack"
        [selectedValuePack]="selectedValuePack"
        [selectedProduct]="secondProduct?.value"
        (selectedProductLensValue)="selectedProductLensValue($event, 1)"
        (checkboxStatus)="checkboxStatusChange($event)"
        [panelConfiguration]="panelConfiguration"
        [disableCheckbox]="true"
        [editproductData]="editlenses[1]"
        [isDTP]="isDTP"
        (Errors)="setPanelErrors($event, secondProduct?.value)"
      >
      </app-basic-product-selector>
    </div>
    <div class="select-products">
      <h3
        class="select-products__select-Lenses"
        [ngClass]="{
          'select-products__disabled-heading':
            checkBoxStatus?.rightEye && checkBoxStatus?.leftEye ? false : !secondProduct ? true : false
        }"
      >
        {{ 'selectProductPage.valuePackAttributes.chooseSolution' | cxTranslate }}
      </h3>
    </div>
    <div class="select-product__lensdrpdn" [ngClass]="{ disabled: !firstProduct }">
      <app-dropdown-selector-v2
        *ngIf="isValuePack"
        [disableDropDown]="valuePackSolutionsList?.length > 0 ? false : true"
        [options]="valuePackSolutionsList | sortPipe: 'asc'"
        [config]="lensesLabel"
        (dropDownSelected)="valuePackSolutionSelected($event)"
      >
      </app-dropdown-selector-v2>
    </div>

    <div
      *ngIf="!!selectedValuePackSolution"
      [ngClass]="{ disabled: !firstProduct }"
      class="select-product__productsSelector"
    >
      <app-basic-product-selector
        [isValuePack]="isValuePack"
        [isValuePackSolution]="true"
        [selectedProduct]="selectedValuePackSolution?.value"
        [isDTP]="isDTP"
        (Errors)="setPanelErrors($event, selectedValuePackSolution?.value)"
      >
      </app-basic-product-selector>
    </div>

    <div class="additional-products__mandatory">{{ 'selectProductPage.mandatory' | cxTranslate }}</div>
  </div>
</div>
