import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CmsParagraphComponent, CmsService, SemanticPathService } from '@spartacus/core';
import { map } from 'rxjs/operators';
import { FAQ_PROPERTIES } from 'src/app/shared/constants/constants';
import { DropdownConfig, DropdownOptions } from 'src/app/shared/model/common.mode';
import { FaqService } from '../../services/faq.service';

@Component({
  selector: 'app-faq-search',
  templateUrl: './faq-search.component.html',
  styleUrls: ['./faq-search.component.scss'],
})
export class FaqSearchComponent {
  readonly defaultConfig: DropdownConfig = {
    showOptionHeader: false,
    defaultSelectText: '',
    typeAheadConfig: {
      typeAhead: true,
      typeAheadLength: FAQ_PROPERTIES.typeCount,
      internalSearch: false,
    },
    iconReference: 'SearchIcon',
  };
  filterOptions: DropdownOptions[] = [];
  subHeading$ = this.cmsService
    .getComponentData<CmsParagraphComponent>('VisioncareFAQSubTittleParagraphComponent')
    .pipe(map((resp: CmsParagraphComponent) => resp.content));

  constructor(
    private faqService: FaqService,
    private semanticPathService: SemanticPathService,
    private router: Router,
    private cmsService: CmsService
  ) {}

  selectSearch(event): void {
    this.router.navigate(
      this.semanticPathService.transform([
        {
          cxRoute: 'FAQPage',
        },
        {
          cxRoute: 'FAQAnswerPage',
          params: { id: event.uid },
        },
      ])
    );
  }

  getQuestions(event): void {
    if (event.length >= FAQ_PROPERTIES.typeCount) {
      this.filterOptions = this.faqService.getFaqQuestion().filter((option) => {
        option.selected = false;
        return (
          option.title.toLowerCase().includes(event.trim().toLowerCase()) ||
          option.text.trim().toLowerCase().includes(event.trim().toLowerCase())
        );
      });
    }
  }
}
