import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CmsNavigationComponent,
  CmsService,
  OccEndpointsService,
  SemanticPathService,
  UserIdService,
} from '@spartacus/core';
import { NavigationService } from '@spartacus/storefront';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { DashboardNavigationNode } from '../../shared/model/dashboard.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardPageService extends NavigationService {
  private showOrders = false;
  private orders: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.showOrders);

  public isShowOrders: Observable<boolean> = this.orders.asObservable();

  private isNewOrderDisplaySubject = new Subject<boolean>();
  isNewOrderDisplay = this.isNewOrderDisplaySubject.asObservable();

  constructor(
    cmsService: CmsService,
    semanticPathService: SemanticPathService,
    private httpClient: HttpClient,
    private occEndPoints: OccEndpointsService,
    private userIdService: UserIdService
  ) {
    super(cmsService, semanticPathService);
  }

  public getCategoryNode(data$: Observable<CmsNavigationComponent>): Observable<DashboardNavigationNode> {
    if (!data$) {
      return of();
    }
    return data$.pipe(
      filter((data) => !!data),
      switchMap((data) => {
        const navigation = data.navigationNode ? data.navigationNode : data;
        return this.cmsService.getNavigationEntryItems(navigation.uid).pipe(
          tap((items) => {
            if (items === undefined) {
              this.loadDashboardNavigationEntryItems(navigation, true);
            } else {
              // we should check whether the existing node items are what expected
              const expectedItems = [];
              this.loadDashboardNavigationEntryItems(navigation, false, expectedItems);
              const existingItems = Object.keys(items).map((key) => items[key].uid);
              const missingItems = expectedItems.filter((it) => !existingItems.includes(it.id));
              if (missingItems.length > 0) {
                this.cmsService.loadNavigationItems(navigation.uid, missingItems);
              }
            }
          }),
          filter(Boolean),
          map((items) => this.populateDashboardNavigationNode(navigation, items))
        );
      })
    );
  }

  private loadDashboardNavigationEntryItems(nodeData: any, root: boolean, itemsList = []): void {
    if (nodeData.entries && nodeData.entries.length > 0) {
      nodeData.entries.forEach((entry) => {
        itemsList.push({
          superType: entry.itemSuperType,
          id: entry.itemId,
        });
      });
    }

    if (nodeData.children && nodeData.children.length > 0) {
      nodeData.children.forEach((child) => this.loadDashboardNavigationEntryItems(child, false, itemsList));
    }

    if (root) {
      this.cmsService.loadNavigationItems(nodeData.uid, itemsList);
    }
  }

  private populateDashboardNavigationNode(nodeData: any, items: any): DashboardNavigationNode {
    let node: DashboardNavigationNode = {};
    if (nodeData.entries && nodeData.entries.length > 0) {
      const item = items[`${nodeData.entries[0].itemId}_${nodeData.entries[0].itemSuperType}`];
      const url = this.getLink(item);
      node = { ...node, ...item };
      node.url = url;
    }

    if (nodeData.children?.length > 0) {
      const children = nodeData.children
        .map((child) => this.populateDashboardNavigationNode(child, items))
        .filter(Boolean);
      if (children.length > 0) {
        node.children = children;
      }
    }
    // return null in case there are no children
    return Object.keys(node).length === 0 ? null : node;
  }

  public getNewOrderClick(): void {
    this.isNewOrderDisplaySubject.next(true);
  }
  public getRecentOrders(): Observable<any> {
    const url = this.occEndPoints.buildUrl('orders');
    return this.httpClient.get<any>(url);
  }
  public getRecentInvoices(): Observable<any> {
    const url = this.occEndPoints.buildUrl('invoices');
    return this.httpClient.get<any>(url);
  }
  public getSubscriptions(): Observable<any> {
    return this.userIdService.getUserId().pipe(
      switchMap((userId) => {
        const url = this.occEndPoints.buildUrl(`users/${userId}/replenishmentOrders`);
        return this.httpClient.get<any>(url);
      })
    );
  }
}
