<div class="minicart-value-pack">
  <div class="minicart-value-pack__cartinfo" *ngFor="let valuepackItem of valuePack">
    <div>
      <img class="minicart-value-pack__cartimage" [attr.src]="getImageUrl(valuepackItem?.image?.url)" alt="cart" />
    </div>
    <div class="minicart-value-pack__productdetails">
      <h4 class="minicart-value-pack__productdetails--cartProductName">{{ valuepackItem?.productName }}</h4>
      <div class="minicart-value-pack__cartspecification">
        <span
          *ngIf="valuepackItem?.eyeSight && valuepackItem?.eyeSight"
          class="minicart-value-pack__cartspecification--eyesight"
        >
          {{ 'miniCartPage.' + valuepackItem?.eyeSight | cxTranslate }}
        </span>
        <span *ngIf="valuepackItem?.product?.isLensCareSolution">
          {{ valuepackItem.salesUnitCount }} {{ valuepackItem.salesUnitName }}
        </span>
        <span *ngFor="let specification of valuepackItem?.specifications">
          {{ 'miniCartPage.' + specification?.specificationKey | cxTranslate }}:
          {{ specification?.specificationValue }}
        </span>
      </div>
    </div>
  </div>
  <div class="minicart-value-pack__valuePack-text">{{ 'miniCartPage.valuePack' | cxTranslate }}</div>
  <div class="minicart-value-pack__cartbottom">
    <div class="minicart-value-pack__cartbottom--icon-remove">
      <cx-icon class="Remove" type="Remove"> </cx-icon
      ><a href="javascript:void(0)" [entries]="getEntryNumberList()" appRemoveFromCart>{{
        'miniCartPage.delete' | cxTranslate
      }}</a>
    </div>
    <label class="minicart-value-pack__formatprice">{{ valuePack[0]?.basePrice?.formattedValue }} </label>
  </div>
</div>
