<div class="minicartList">
  <app-mini-cart-product-details [productData]="listData"></app-mini-cart-product-details>
  <div
    *ngIf="!((onCartPage | async)?.cxRoute === 'cart')"
    class="minicartList__removeBtn"
    appRemoveFromCart
    [entries]="listData.entryNumberList"
  >
    <cx-icon class="Remove" type="Remove"> </cx-icon>
  </div>
  <div class="minicartList__price">
    <app-show-price [productDetails]="listData"></app-show-price>
  </div>
</div>
