import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { POSTAL_CODE_VALIDATOR_CONST } from 'src/app/shared/constants/constants';
import { AlconSiteService } from '../service/alcon-site-provider.service';

@Injectable({
  providedIn: 'root',
})
export class ValidatorService {
  constructor(private alconSiteService: AlconSiteService) {}

  phoneNumberValidator(control: UntypedFormControl): { [key: string]: boolean } | null {
    const value = control.value?.trim();
    if (value) {
      if (/^\+\d+$/.test(value)) {
        if (value.length < 9) {
          return { minLength: true };
        }
      } else if (/^\d+$/.test(value)) {
        if (value.length < 9) {
          return { minLength: true };
        }
      } else if (/^\+$/.test(value)) {
        return { minLength: true };
      } else {
        return { charactersNotAllowed: true };
      }
    }
  }
  passwordValidator(control: UntypedFormControl): { [key: string]: boolean } | null {
    let count = 0;
    const password = control.value?.trim();
    if (!password) {
      return { required: true };
    } else if (password.length < 8) {
      return { minlength: true };
    } else if (password && password.length > 7) {
      count += /[a-z]+/.test(password) ? 1 : 0;
      count += /[A-Z]+/.test(password) ? 1 : 0;
      count += /[0-9]+/.test(password) ? 1 : 0;
      const regex = /[$-/:-?{-~!"^_@#,.`\[\]]/g;
      count += regex.test(password) ? 1 : 0;
      if (count > 2) {
        const newpwd = control.parent.get('newPassword').value;
        const confirmpwd = control.parent.get('reEnterNewPassword').value;
        const controlName = Object.keys(control.parent.controls).find(
          (key) => control.parent.controls[key] === control
        );
        if (newpwd && confirmpwd && controlName === 'reEnterNewPassword') {
          return newpwd === confirmpwd ? null : { notSame: true };
        }
      } else {
        return { pattern: true };
      }
    }
  }

  accountNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }
      const maxCharacters = value.charAt(0) === '0' ? value.length <= 10 : value.length <= 9;
      const isValid = /[0-9]+/.test(value) && maxCharacters;

      return !isValid
        ? {
            maxlength: {
              requiredLength: value.charAt(0) === '0' ? 10 : 9,
            },
          }
        : null;
    };
  }

  /**
   *
   * @returns current Pattern
   */
  postalCodePattern(): string {
    return POSTAL_CODE_VALIDATOR_CONST[this.alconSiteService.getCurrentSite()?.toUpperCase()]?.pattern;
  }

  /**
   *
   * @returns current MaxLength
   */
  postalCodeMaxLength(): number {
    return POSTAL_CODE_VALIDATOR_CONST[this.alconSiteService.getCurrentSite()?.toUpperCase()]?.Maxlength;
  }
}
