import { _, internal, Input, registerInput, loc } from 'okta'
import { template } from 'handlebars';
const TextBox = internal.views.forms.inputs.TextBox;

const TYPE = 'date';
const TOUCHED = 'touched';
const DIRTY = 'dirty';
const MIN_LENGTH = 'minlength';
const HOST_CLASS_NAME = 'okta-form-input-field input-fix';
const DAY = 'day';
const MONTH = 'month';
const YEAR = 'year';
const INPUT_ATTR_DELIMITER = '_';
const MONTH_LENGTH = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
const LEAP_YEAR_FEBRUARY_LENGTH = 29;

// this function is defined completely outside template and object.. it exists independently
// move if statement logic
function lookupPropertyDefaultFn(parent, propertyName) {
  if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
    return parent[propertyName];
  }
  return undefined;
};
const VALUE_DELIMITER = '-';
const ISO_SHORT_DATE_FORMAT = 'YYYY-MM-DD';
const ISO_8601_REGEX = /^\d{4}-\d{2}-\d{2}(?:T\d{2}:\d{2}:\d{2}(?:\.\d+)?(?:Z|(?:(?:\+|-)\d{2}:\d{2}))?)?$/;


export const DateInput = TextBox.extend({
  constructor() {
    TextBox.apply(this, arguments);
    const props = this.model.__schema__.props[this.options.name] || {};
    props.validate = this.validate.bind(this);
  },

  // this caused the login page not to load at all
  //dateFormat : _getPropByKey('dateFormat'),

  template: template({
    'compiler': [8, '>= 4.3.0'],
    'main': function main(_container, depth0, _helpers, _partials, _data) {
      const lookupProperty = _container.lookupProperty || lookupPropertyDefaultFn;
      const inputId = lookupProperty(depth0, 'inputId') || '';
      const name = lookupProperty(depth0, 'name') || '';
      const props = depth0.model.__schema__.props[depth0.name];
      const required = lookupProperty(depth0, 'required') || props && lookupProperty(props, 'required') || false;
      //const dateFormat = lookupProperty(depth0, 'dateFormat') || '';
      //const dateFormat = lookupProperty(depth0, 'dateFormat') || props && lookupProperty(props, 'dateFormat') || '';
      //const dateFormat = depth0._getPropByKey('dateFormat');
      //const dateFormat = depth0.dateFormat;
      //const dateFormat = depth0.model.__schema__.props[depth0.dateFormat];
      const dateFormat = depth0.params.dateFormat;

      // if the dob format is DD-MM-YYYY
      if(dateFormat === 'DD-MM-YYYY'){
        return `
          <div class="okta-form-input-field-group date-tab">
            <input
              id="${inputId}${INPUT_ATTR_DELIMITER}${DAY}"
              name="${name}${INPUT_ATTR_DELIMITER}${DAY}"
              type="text"
              maxlength="2"
              pattern="[0-9]*"
              inputmode="numeric"
              placeholder="${loc('date.placeholder.day')}${required ? ' *' : ''}"
            />
            <input
              id="${inputId}${INPUT_ATTR_DELIMITER}${MONTH}"
              name="${name}${INPUT_ATTR_DELIMITER}${MONTH}"
              type="text"
              maxlength="2"
              pattern="[0-9]*"
              inputmode="numeric"
              placeholder="${loc('date.placeholder.month')}${required ? ' *' : ''}"
            />
            <input
              id="${inputId}${INPUT_ATTR_DELIMITER}${YEAR}"
              name="${name}${INPUT_ATTR_DELIMITER}${YEAR}"
              type="text"
              maxlength="4"
              minlength="4"
              pattern="[0-9]*"
              inputmode="numeric"
              placeholder="${loc('date.placeholder.year')}${required ? ' *' : ''}"
            />
          </div> 
        `;
      }

      // If the dob format is YYYY-MM-DD
      else if(dateFormat === 'YYYY-MM-DD'){
        return `
          <div class="okta-form-input-field-group date-tab">
            <input
              id="${inputId}${INPUT_ATTR_DELIMITER}${YEAR}"
              name="${name}${INPUT_ATTR_DELIMITER}${YEAR}"
              type="text"
              maxlength="4"
              minlength="4"
              pattern="[0-9]*"
              inputmode="numeric"
              placeholder="${loc('date.placeholder.year')}${required ? ' *' : ''}"
            />
            <input
              id="${inputId}${INPUT_ATTR_DELIMITER}${MONTH}"
              name="${name}${INPUT_ATTR_DELIMITER}${MONTH}"
              type="text"
              maxlength="2"
              pattern="[0-9]*"
              inputmode="numeric"
              placeholder="${loc('date.placeholder.month')}${required ? ' *' : ''}"
            />
            <input
              id="${inputId}${INPUT_ATTR_DELIMITER}${DAY}"
              name="${name}${INPUT_ATTR_DELIMITER}${DAY}"
              type="text"
              maxlength="2"
              pattern="[0-9]*"
              inputmode="numeric"
              placeholder="${loc('date.placeholder.day')}${required ? ' *' : ''}"
          </div> 
        `;
      }

      // If the dob format is MM-DD-YYYY
      else if(dateFormat === 'MM-DD-YYYY'){
        return `
          <div class="okta-form-input-field-group date-tab">
            <input
              id="${inputId}${INPUT_ATTR_DELIMITER}${MONTH}"
              name="${name}${INPUT_ATTR_DELIMITER}${MONTH}"
              type="text"
              maxlength="2"
              pattern="[0-9]*"
              inputmode="numeric"
              placeholder="${loc('date.placeholder.month')}${required ? ' *' : ''}"
            />
            <input
              id="${inputId}${INPUT_ATTR_DELIMITER}${DAY}"
              name="${name}${INPUT_ATTR_DELIMITER}${DAY}"
              type="text"
              maxlength="2"
              pattern="[0-9]*"
              inputmode="numeric"
              placeholder="${loc('date.placeholder.day')}${required ? ' *' : ''}"
            />
            <input
              id="${inputId}${INPUT_ATTR_DELIMITER}${YEAR}"
              name="${name}${INPUT_ATTR_DELIMITER}${YEAR}"
              type="text"
              maxlength="4"
              minlength="4"
              pattern="[0-9]*"
              inputmode="numeric"
              placeholder="${loc('date.placeholder.year')}${required ? ' *' : ''}"
            />
          </div> 
        `;
      }

      else{
        return `
          <div class="okta-form-input-field-group date-tab">
            <input
              id="${inputId}${INPUT_ATTR_DELIMITER}${DAY}"
              name="${name}${INPUT_ATTR_DELIMITER}${DAY}"
              type="text"
              maxlength="2"
              pattern="[0-9]*"
              inputmode="numeric"
              placeholder="${loc('date.placeholder.day')}${required ? ' *' : ''}"
            />
            <input
              id="${inputId}${INPUT_ATTR_DELIMITER}${MONTH}"
              name="${name}${INPUT_ATTR_DELIMITER}${MONTH}"
              type="text"
              maxlength="2"
              pattern="[0-9]*"
              inputmode="numeric"
              placeholder="${loc('date.placeholder.month')}${required ? ' *' : ''}"
            />
            <input
              id="${inputId}${INPUT_ATTR_DELIMITER}${YEAR}"
              name="${name}${INPUT_ATTR_DELIMITER}${YEAR}"
              type="text"
              maxlength="4"
              minlength="4"
              pattern="[0-9]*"
              inputmode="numeric"
              placeholder="${loc('date.placeholder.year')}${required ? ' *' : ''}"
            />
          </div> 
        `;
      }
    },
    'useData': true
  }),


  /**
   * @Override
   */
  events: {
    'input input': 'update',
    'change input': 'update',
    'keydown input': 'update',
    'focus input': 'focus',
    'keyup input': function keyupInput(e) {
      //alert("a");
      if (internal.util.Keys.isEnter(e)) {
        this.model.trigger('form:save');  
      } else if (internal.util.Keys.isEsc(e)) {
        this.model.trigger('form:cancel');
      }
    },
    'keyup .date-tab': function(e) {
      var target = e.srcElement || e.target;
      var maxLength = parseInt(target.attributes["maxlength"].value, 10);
      var myLength = target.value.length;
      var code = parseInt(e.keyCode || e.which);
      
      if (code >= 48 && code<=57) {
      if (myLength >= maxLength) {
          var next = target;
          while (next = next.nextElementSibling) {
              if (next == null)
                  break;
              if (next.tagName.toLowerCase() === "input") {
                  next.focus();
                  break;
              }
          }
      }}
        
  }
  },


  /**
   * @Override
   */
  editMode() {
    this.$el.html(this.template(this.options));
    var value = this.getModelValue();
    if (value && this._validateDate(value)) {
      this._setViewValue(value);
    }
    this.$el.addClass(HOST_CLASS_NAME);
    return this;
  },

  /**
   * @Override
   */
  readMode() {
    Input.prototype.readMode.apply(this, arguments);
    this.$el.removeClass(HOST_CLASS_NAME);
  },

  /**
   * @Override
   */
  val() {
    return this._getValue();
  },

  /**
   * @Override
   */
  focus(e) {
    e.target.setAttribute(TOUCHED, true);
  },

  update(e) {
    this._clearError();

    if(e.target.hasAttribute(MIN_LENGTH)){
      if(e.target.value.length >= e.target.getAttribute(MIN_LENGTH)){
        e.target.setAttribute(DIRTY, true);
      }
    }
    else{
      e.target.setAttribute(DIRTY, true);
    }

    if (!(this._allAreDirty() || this._allAreTouched())) {
      return;
    }

    Input.prototype.update.call(this);
  },

  /**
   * @Override
   * @param {jQuery.Event} event 
   */
  validate(event) {
    if (event && this._isOutFocusWithinEl(event)) {
      this._clearError();
      return true;
    }
    if (!(this._allAreDirty() || this._allAreTouched())) {
      this._clearError();
      return true;
    }
    if (this._allAreEmpty() && !this._getPropByKey('required')) {
      this._clearError();
      return true;
    }
    if (this._allAreEmpty() && this._getPropByKey('required')) {
      const validationError = {
        [this.options.name]: this.model.constructor.ERROR_BLANK
      };
      this._setError(validationError);
      return 'model.validation.field.blank';
    }
    if (!this._validateDate(this._getViewValue())) {
      const validationError = {
        [this.options.name]: this.model.constructor.ERROR_INVALID_FORMAT_ISO_8601
      };
      this._setError(validationError);
      return 'model.validation.field.invalid.format.iso8601';
    }

    this._clearError();
    return true;
  },

  _getViewValue() {
    if (this._allAreEmpty()) {
      return '';
    }
    const inputs = this._getInputs();
    const year = inputs.year.val();
    const month = inputs.month.val();
    const day = inputs.day.val();
    const predicate = num => num > 9 ? num : `0${num}`;
    return `${Number.parseInt(year)}${VALUE_DELIMITER}${predicate(Number.parseInt(month))}${VALUE_DELIMITER}${predicate(Number.parseInt(day))}`;
  },

  /**
   * 
   * @returns {string}
   */
  _getValue() {
    const viewValue = this._getViewValue();
    return this._viewValueToModel(viewValue, this._getPropByKey('dateFormat'));
  },

  /**
   * 
   * @param {string} value 
   * @param {string} format 
   */
  _viewValueToModel(value, format) {
    switch (format) {
      case ISO_SHORT_DATE_FORMAT:
        return value;
      default:
        try{
          const date = new Date(value);
          return date.toISOString().split('T')[0];
        }
        catch(error){
          return this._getViewValue();
        }

    }
  },

  /**
   * 
   * @param {HTMLElement} el 
   * @returns {boolean}
   */
  _isDirty(el) {
    return !!el.attr(DIRTY);
  },

  /**
   * 
   * @returns {boolean}
   */
  _allAreDirty() {
    const inputs = this._getInputs();
    return this._isDirty(inputs.day) && this._isDirty(inputs.month) && this._isDirty(inputs.year);
  },

  /**
   * 
   * @param {JQuery} el 
   * @returns {boolean}
   */
  _isTouched(el) {
    return !!el.attr(TOUCHED);
  },

  /**
   * 
   * @returns {boolean}
   */
  _allAreTouched() {
    const inputs = this._getInputs();
    return this._isTouched(inputs.day) && this._isTouched(inputs.month) && this._isTouched(inputs.year);
  },

  /**
   * 
   * @returns {boolean}
   */
  _allAreEmpty() {
    const inputs = this._getInputs();
    return [inputs.day.val(), inputs.month.val(), inputs.year.val()].every(value => !value);
  },

  _getInputs() {
    return {
      day: this.$el.find(this._getInputPattern(this.options.name, INPUT_ATTR_DELIMITER, DAY)),
      month: this.$el.find(this._getInputPattern(this.options.name, INPUT_ATTR_DELIMITER, MONTH)),
      year: this.$el.find(this._getInputPattern(this.options.name, INPUT_ATTR_DELIMITER, YEAR))
    }
  },

  /**
   * 
   * @param {JQuery.Event} event 
   */
  _isOutFocusWithinEl(event) {
    const currentEl = event.currentTarget;
    const relatedEl = event.relatedTarget;
    return currentEl && relatedEl && currentEl.parentElement === relatedEl.parentElement;
  },

  /**
   * 
   * @param {string} validationError 
   */
  _setError(validationError) {
    if (!this.model.get('__pending__') && this.isEditMode()) {
      _.delay(function () {
        this.model.trigger('form:clear-error:' + this.options.name);
        this.model.trigger('invalid', this.model, validationError, false);
      }.bind(this), 100);
    }
  },

  _clearError() {
    _.delay(function () {
      this.model.trigger('form:clear-error:' + this.options.name);
    }.bind(this), 100);
  },

  /**
   * 
   * @param {string} dateString 
   * @returns {boolean}
   */
  // this is not checking if it is empty... it is only checking the formatting criteria
  _validateDate(dateString) {
    if (!this._validateDatePattern(dateString)) {
      return false;
    }
    if(!this._validateMinDate(dateString)){
      return false;
    }
    if(!this._validateMaxDate(dateString)){
      return false;
    }

    const { day, month, year } = this._parseDateString(dateString);
    if (!this._validateMonthRange(month)) {
      return false;
    }
    if (this._isLeapYear(year) && month === 2) {
      return this._validateDayRange(day, LEAP_YEAR_FEBRUARY_LENGTH);
    }
    return this._validateDayRange(day, MONTH_LENGTH[month - 1]);
  },

  /**
   * Check if target date is equal to, or after min date
   * @param {string} targetDateString
   * @returns {boolean}
   */
  _validateMinDate(targetDateString) {
    const minDate = new Date(this._getPropByKey('minDate'));
    const targetDate = new Date(targetDateString);

    if(!isNaN(minDate) && (targetDate - minDate >= 0)) {
      return true;
    } else {
      return false;
    }
  },

  /**
   * Check if target date is equal to, or before max date
   * @param {string} targetDateString
   * @returns {boolean}
   */
  _validateMaxDate(targetDateString) {
    const maxDate = new Date(this._getPropByKey('maxDate'));
    const targetDate = new Date(targetDateString);

    if(!isNaN(maxDate) && (targetDate - maxDate <= 0)) {
      return true;
    } else {
      return false;
    }
  },

  /**
   * 
   * @param {string} dateString 
   * @returns {boolean}
   */
  _validateDatePattern(dateString) {
    return ISO_8601_REGEX.test(dateString);
  },

  /**
   * 
   * @param {number} month 
   * @returns {boolean}
   */
  _validateMonthRange(month) {
    return month > 0 && month <= 12;
  },

  /**
   * 
   * @param {number} day 
   * @param {number} monthLength 
   * @returns {boolean}
   */
  _validateDayRange(day, monthLength) {
    return day > 0 && day <= monthLength;
  },

  /**
   * 
   * @param {string} dateString 
   * @returns 
   */
  _parseDateString(dateString) {
    const parts = dateString.split(VALUE_DELIMITER);
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const day = parseInt(parts[2], 10);
    return {
      day,
      month,
      year
    }
  },

  /**
   * 
   * @param {number} year 
   * @returns {boolean}
   */
  _isLeapYear(year) {
    return year % 400 == 0 || (year % 100 != 0 && year % 4 == 0);
  },

  /**
   * 
   * @param {string} name 
   * @param {string} delimeter 
   * @param {string} dateFiled 
   * @returns {string}
   */
  _getInputPattern(name, delimeter, dateFiled) {
    return `input[name="${name}${delimeter}${dateFiled}"]`;
  },

  /**
   * 
   * @param {string} value 
   */
  _setViewValue(value) {
    const date = new Date(value);
    const jqueryInputCollection = this._getInputs();
    jqueryInputCollection.day.val(date.getDate());
    jqueryInputCollection.month.val(date.getMonth() + 1);
    jqueryInputCollection.year.val(date.getFullYear());
  },

  /**
   * 
   * @param {string} key 
   * @returns 
   */
  _getPropByKey(key) {
    const props = this.model.__schema__.props[this.options.name];
    return this.options[key] || this.options.params && this.options.params[key] || props && props.required || null;
  }
});

registerInput(TYPE, DateInput);

