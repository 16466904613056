import { Component } from '@angular/core';

@Component({
  selector: 'app-account-details-consolidation',
  templateUrl: './account-details-consolidation.component.html',
  styleUrls: ['./account-details-consolidation.component.scss'],
})
export class AccountDetailsConsolidationComponent {
  constructor() {}
}
