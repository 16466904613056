<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="d-flex col-10 col-xl-10 patient-profile-padding">
      <cx-icon class="mt-5px" type="BackButton" [routerLink]="{ cxRoute: 'myPatients' } | cxUrl"></cx-icon>
      <cx-page-slot class="patient-profile-header" position="PatientProfileDescriptionPosition"></cx-page-slot>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-10 col-xl-10 my-patients-container">
      <div class="col-10 patient-profile-padding">
        <cx-page-slot position="PatientProfileDetailsPosition"></cx-page-slot>
      </div>
      <!--Patient Order Details Section -->
      <div class="col-10 patient-profile-padding" *ngIf="orderCount | async">
        <cx-page-slot position="PatientOrdersPosition"></cx-page-slot>
      </div>
      <!--Patient Order Details Section -->
    </div>
  </div>
</div>
