import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EntryRoot } from 'src/app/shared/model/product.model';
import { Utilities } from 'src/app/shared/utility/utility';

@Component({
  selector: 'app-direct-to-patient-orders',
  templateUrl: './direct-to-patient-orders.component.html',
  styleUrls: ['./direct-to-patient-orders.component.scss'],
})
export class DirectToPatientOrdersComponent implements OnInit, OnChanges {
  isAccordionChange = true;
  quantity: number;
  openPanel = false;
  @Input() entry: any = {};
  @Input() finalPriceCalculated: boolean;
  @Input() isCheckoutPage = false;
  entryelement: EntryRoot;
  sortedValuePacks: any[];

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.entry) {
      this.entry = changes.entry.currentValue;
      if (this.entry?.children) {
        this.sortedValuePacks = Utilities.getSortedValuePack(this.entry?.children[0]?.entries);
      } else {
        this.sortedValuePacks = [];
      }
      this.quantity = this.getTotalQuantity();
    }
  }

  ngOnInit(): void {
    this.entryelement = JSON.parse(JSON.stringify(this.entry));
    if (this.entry?.children) {
      this.sortedValuePacks = Utilities.getSortedValuePack(this.entry?.children[0]?.entries);
    } else {
      this.sortedValuePacks = [];
    }
    this.quantity = this.getTotalQuantity();
  }

  getTotalQuantity(): number {
    let totalQuantity = this.entry?.entries
      ? this.entry?.entries.reduce((sum, item) => sum + Number(item.quantity), 0)
      : 0;
    if (this.entry.children?.length) {
      totalQuantity += this.entry.children[0]?.entries.length;
    }
    return totalQuantity;
  }

  open(): void {
    this.isAccordionChange = !this.isAccordionChange;
  }
}
