import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CartItemsService } from 'src/app/services/cart-items/cart-items.service';

@Component({
  selector: 'app-po-number',
  templateUrl: './po-number.component.html',
  styleUrls: ['./po-number.component.scss'],
})
export class PoNumberComponent implements OnInit {
  @Input() isMandatory;
  @Input() hasError;
  @Output() enteredPONumber: EventEmitter<any> = new EventEmitter();

  constructor(private cartItemsService: CartItemsService) {}

  ngOnInit(): void {
    this.cartItemsService.setPOnumber('');
  }

  changePONumber(event): void {
    this.enteredPONumber.emit(event.target.value);
  }
}
