<ng-container *ngIf="items$?.[0] | async as data">
  <div class="mktPopup">
    <div class="modal-header border-0">
      <cx-icon role="button" type="Close" class="mktPopup__closeBtn" (click)="activeModal.dismiss()"></cx-icon>
    </div>
    <div class="d-flex justify-content-center mktBanner">
      <cx-media class="d-none d-lg-block img-fluid text-center h-100" [container]="data?.media?.desktop"></cx-media>
      <cx-media class="d-none d-md-block d-lg-none img-fluid h-100" [container]="data?.media?.tablet"></cx-media>
      <cx-media class="d-sm-block d-md-none img-fluid h-100" [container]="data?.media?.mobile"></cx-media>
    </div>
  </div>
</ng-container>
