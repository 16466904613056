import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { REORDER_VALIDATION } from 'src/app/shared/constants/constants';
import { ReOrderResponse } from 'src/app/shared/model/orderHistory.model';

@Component({
  selector: 'app-re-order-validation',
  templateUrl: './re-order-validation.component.html',
  styleUrls: ['./re-order-validation.component.scss'],
})
export class ReOrderValidationComponent {
  @Input() reOrderResponse: ReOrderResponse;
  @Output() closePopup: EventEmitter<void> = new EventEmitter();
  get reOrderValidationReason(): typeof REORDER_VALIDATION {
    return REORDER_VALIDATION;
  }
  /* Added this method to restrict the redirection to order details page when clicking on reorder button*/
  @HostListener('click', ['$event']) onClick(event: any): void {
    event.stopPropagation();
  }
  closeModal(): void {
    this.closePopup.emit();
  }
}
