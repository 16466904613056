<div class="contact-page">
  <div class="container-fluid mobile-container-fluid">
    <div class="row justify-content-center">
      <div class="col-10 col-lg-6 col-xl-7 pl-4 pr-4">
        <div class="contact-page__form">
          <div class="d-none d-lg-block">
            <ng-container *ngTemplateOutlet="title"></ng-container>
          </div>
          <cx-page-slot position="ContactUsFormPosition"></cx-page-slot>
        </div>
      </div>
      <div class="col-10 order-first col-lg-4 order-lg-last col-xl-3">
        <div class="contact-page__information row">
          <div class="d-block d-lg-none col-7 col-lg-10">
            <ng-container *ngTemplateOutlet="title"></ng-container>
          </div>
          <cx-page-slot class="col-3 col-lg-10" position="SalesRepresentativePosition"></cx-page-slot>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #title>
  <h2 class="contact-page__heading">{{ title$ | async }}</h2>
  <div class="contact-page__message-block">
    <cx-page-slot position="ContactUsMessagePosition"></cx-page-slot>
  </div>
</ng-template>
