import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ActivatedRoutesService, OccConfig } from '@spartacus/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProductDetailsService } from 'src/app/services/alcon-pdp/product-details.service';
import { DIRECT_TO_PATIENT, DIRECT_TO_PRACTICE } from 'src/app/shared/constants/constants';
import { ModalOptions } from 'src/app/shared/model/common.mode';
import { UpdateToCart } from 'src/app/store/Actions/cartItem.action';
import { getCartItems } from 'src/app/store/Selectors/cartItem.selector';
import {
  VisionCareAddToCart,
  VisionCareAddtocartProductRequest,
  VisionCareCartItems,
} from 'src/app/store/States/cartItem.state';

@Component({
  selector: 'app-alcon-cart-data',
  templateUrl: './alcon-cart-data.component.html',
  styleUrls: ['./alcon-cart-data.component.scss'],
})
export class AlconCartDataComponent implements OnInit, OnDestroy {
  @Input() cartEntry: any;
  @Input() entryLabel: any;
  @Input() quantityEdit: boolean;
  @Input() status: string;
  @Input() finalPriceCalculated: boolean;
  @Input() externalOrder: boolean;

  public isDTP$: Observable<boolean> = this.productDetailsService.receiveDTPFlag();

  unShippedPowers = [];
  shippedPowers = [];
  cartType = 'update';
  errorMessage: string;
  productOpen = false;
  isCheckoutPage: boolean;
  orderDetailsPage: boolean;
  editData;
  isOrderConfirmationPage: boolean;
  public modalOptions = {
    open: false,
    header: false,
    modalTitle: '',
    width: '647px',
  } as ModalOptions;
  specificationValues = ['BC', 'DIA', 'LP', 'ADD', 'CY', 'AX', 'Colour'];
  updatedSpecificationValues = [];
  private destroy$ = new Subject<void>();

  constructor(
    private occConfig: OccConfig,
    private activatedRoutesService: ActivatedRoutesService,
    private store: Store,
    private cd: ChangeDetectorRef,
    private productDetailsService: ProductDetailsService
  ) {}

  ngOnInit(): void {
    this.activatedRoutesService.routes$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      const type = data[data.length - 1].data.Type;
      switch (type) {
        case 'Cart':
          this.isCheckoutPage = false;
          break;
        case 'Checkout':
          this.isCheckoutPage = true;
          break;
        case 'OrderConfirmation':
          this.isCheckoutPage = true;
          this.isOrderConfirmationPage = true;
          break;
        case 'OrderDetails':
          this.orderDetailsPage = true;
          break;
      }
    });
    this.modalOptions = {
      open: false,
      header: false,
      modalTitle: '',
      width: this.entryLabel === 'STOCK_ORDER' ? '930px' : '647px',
    } as ModalOptions;
  }
  setProductQuantity(quantity: number): void {
    this.updateCartWithNewQuantity(quantity);
  }

  editItem(): void {
    this.editData = this.cartEntry;
    this.modalOptions.open = true;
    document.body.classList.add('modal-open');
  }
  closeModal(): void {
    this.modalOptions.open = false;
    this.editData = '';
  }

  updateCartWithNewQuantity(quantity: number): void {
    const Product = [
      {
        product: this.cartEntry.product,
        eyeSight: this.cartEntry.eyeSight ? (this.cartEntry.eyeSight === 'RIGHT' ? 'R' : 'L') : '',
        quantity: String(quantity),
        entryNumber: this.cartEntry.entryNumber,
        orderEntryFlowType:
          this.cartEntry.orderEntryFlowType === DIRECT_TO_PATIENT.DIRECT_TO_PATIENT_ORDER
            ? DIRECT_TO_PATIENT.DIRECT_TO_PATIENT_ORDER
            : DIRECT_TO_PRACTICE.REGULAR,
        patientName: this.cartEntry?.patientName,
        patientShippingAddressMain:
          this.cartEntry.orderEntryFlowType === DIRECT_TO_PATIENT.DIRECT_TO_PATIENT_ORDER
            ? this.cartEntry?.patientShippingAddressMain
            : '',
        patientId:
          this.cartEntry.orderEntryFlowType === DIRECT_TO_PATIENT.DIRECT_TO_PATIENT_ORDER
            ? this.cartEntry.patientId
            : '',
      } as VisionCareAddtocartProductRequest,
    ];
    const VCAddtocartItems: VisionCareAddToCart = {
      entries: Product,
      orderFlowType:
        this.cartEntry.orderEntryFlowType === DIRECT_TO_PATIENT.DIRECT_TO_PATIENT_ORDER
          ? DIRECT_TO_PATIENT.DIRECT_TO_PATIENT_ORDER
          : DIRECT_TO_PRACTICE.REGULAR,
      trialProduct: false,
    };
    this.store.dispatch(UpdateToCart({ VCAddtocartItems }));
    this.store.pipe(select(getCartItems), takeUntil(this.destroy$)).subscribe((data: VisionCareCartItems) => {
      if (data && !data.cartError) {
        this.errorMessage = '';
      } else {
        this.errorMessage = data.cartError.error.errors[0].message;
      }
      this.cd.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
