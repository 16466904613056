import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SemanticPathService } from '@spartacus/core';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AddressService } from 'src/app/services/addresses/address.service';
import { PatientService } from 'src/app/services/patient/patient.service';
import { UpdateUserProfileService } from 'src/app/services/updateuserprofile/update-user-profile.service';
import { VALIDATOR_CONSTANT } from 'src/app/shared/constants/constants';
import { DropdownConfig, DropdownOptions, DropdownSelectedStatus } from 'src/app/shared/model/common.mode';
import { ReloadCart } from 'src/app/store/Actions/cartItem.action';
import { PatientProfileData } from '../../../shared/model/patient.model';
@Component({
  selector: 'app-add-new-patient',
  templateUrl: './add-new-patient.component.html',
  styleUrls: ['./add-new-patient.component.scss'],
})
export class AddNewPatientComponent implements OnInit, OnDestroy {
  addPatientForm: UntypedFormGroup;
  titles$: Observable<DropdownOptions[]>;
  counties$: Array<Observable<DropdownOptions[]>> = [];
  @Output() cancel: EventEmitter<string> = new EventEmitter();
  private destroy$ = new Subject<void>();
  isAdditionalAddress = false;
  @Output() patientAddedSuccessfully: EventEmitter<string> = new EventEmitter();
  showInValidIcon = false;
  resetDropDown = false;
  clearCounty = false;
  @Input() editPatient: PatientProfileData;
  @Input() showAdditionalAddress = true;
  @Input() navigateNewOrderPage = false;
  addressError = '';
  resetTitleDropDown = false;
  resetCountyDropDown = false;

  selectedStatus = DropdownSelectedStatus.NEUTRAL;
  ischeckboxTicked = false;
  ischeckboxDisabled = false;

  readonly dropdownConfig: DropdownConfig = {
    defaultSelectText: 'Select',
  };

  constructor(
    private patientFormBuilder: UntypedFormBuilder,
    private store: Store,
    private patientService: PatientService,
    private cd: ChangeDetectorRef,
    private updateService: UpdateUserProfileService,
    private router: Router,
    private semanticPathService: SemanticPathService,
    private addressService: AddressService
  ) {}

  ngOnInit(): void {
    this.titles$ = this.updateService.titles$.pipe(
      map((titles) =>
        titles.map(
          (title) =>
            ({
              text: title.name,

              value: title.code,

              selected: this.editPatient && this.editPatient.titleCode === title.code ? true : false,
            } as DropdownOptions)
        )
      )
    );

    this.addPatientForm = this.patientFormBuilder.group({
      titleCode: [''],
      firstName: ['', [Validators.required, Validators.maxLength(30)]],
      lastName: ['', [Validators.required, Validators.maxLength(30)]],
      email: ['', [Validators.email, Validators.pattern(VALIDATOR_CONSTANT.EMAIL_PATTREN)]],
      externalReferenceNumber: ['', Validators.maxLength(30)],
      userPhoneNumberDataList: this.patientFormBuilder.array([
        this.patientFormBuilder.group({
          phoneNumber: ['', [this.phoneNumberValidator]],
          country: this.patientFormBuilder.group({
            isocode: ['GB'],
          }),
        }),
      ]),
      addresses: this.patientFormBuilder.array([this.addressService.getAddressFields()]),
      uid: '',
    });

    this.addresses[0].get('defaultAddress').setValue(true);
    this.addresses[0].disable();
    if (this.editPatient) {
      this.addPatientForm.addControl('patientId', this.patientFormBuilder.control(''));
      if (this.editPatient.addresses?.length > 1) {
        this.isAdditionalAddress = true;
        this.addAdditionalAddress(this.isAdditionalAddress);
      }
      this.addPatientForm.patchValue(this.editPatient, { onlySelf: true, emitEvent: false });
      if (this.editPatient.addresses?.length) {
        this.ischeckboxTicked = true;
        this.ischeckboxDisabled = true;
        this.enableAddresses();
      } else {
        this.ischeckboxTicked = false;
        this.ischeckboxDisabled = false;
        this.disableAddresses();
      }
    }
  }

  phoneNumberValidator(control: UntypedFormControl): { [key: string]: boolean } | null {
    const value = control.value?.trim();
    if (value) {
      if (/^\+\d+$/.test(value)) {
        if (value.length < 9) {
          return { minLength: true };
        }
      } else if (/^\d+$/.test(value)) {
        if (value.length < 9) {
          return { minLength: true };
        }
      } else if (/^\+$/.test(value)) {
        return { minLength: true };
      } else {
        return { charactersNotAllowed: true };
      }
    }
  }

  get title(): AbstractControl {
    return this.addPatientForm.get('titleCode');
  }
  get firstName(): AbstractControl {
    return this.addPatientForm.get('firstName');
  }
  get lastName(): AbstractControl {
    return this.addPatientForm.get('lastName');
  }
  get email(): AbstractControl {
    return this.addPatientForm.get('email');
  }
  get externalReferenceNumber(): AbstractControl {
    return this.addPatientForm.get('externalReferenceNumber');
  }
  get userPhoneNumberDataList(): AbstractControl {
    /* tslint:disable-next-line */
    return this.addPatientForm.get('userPhoneNumberDataList')['controls'];
  }
  get phoneNumber(): AbstractControl {
    /* tslint:disable-next-line */
    return this.addPatientForm.get('userPhoneNumberDataList')['controls'][0].get('phoneNumber');
  }
  get addresses(): Array<AbstractControl> {
    /* tslint:disable-next-line */
    return this.addPatientForm.get('addresses')['controls'];
  }

  mainAddressSelected(): void {
    this.ischeckboxTicked = !this.ischeckboxTicked;
    this.ischeckboxTicked ? this.enableAddresses() : this.disableAddresses();
  }

  enableAddresses(): void {
    this.addresses.forEach((control: AbstractControl) => {
      control.enable();
    });
  }

  disableAddresses(): void {
    this.addresses.forEach((control: AbstractControl) => {
      control.disable();
    });
  }

  onSubmit(): void {
    this.addresses.forEach((control: AbstractControl) => {
      control.markAsDirty();
      control.updateValueAndValidity();
    });
    if (this.addPatientForm.dirty && this.addPatientForm.valid) {
      const formData = this.addPatientForm.getRawValue();
      if (!this.ischeckboxTicked) {
        delete formData.addresses;
      }
      this.showInValidIcon = false;
      this.userPhoneNumberDataList[0].get('country.isocode').setValue('GB');
      if (this.editPatient) {
        this.patientService
          .updateUser(formData)
          .pipe(takeUntil(this.destroy$))
          .subscribe((val) => {
            this.store.dispatch(ReloadCart());
            this.submitSuccess(this.editPatient.patientId);
          });
      } else {
        this.patientService
          .createUser(formData)
          .pipe(takeUntil(this.destroy$))
          .subscribe((patient) => {
            this.submitSuccess(patient.patientId);
          });
      }
    } else {
      this.showInValidIcon = true;
      this.resetDropDown = false;
      this.resetTitleDropDown = false;
      this.resetCountyDropDown = false;
      this.addPatientForm.markAllAsTouched();
    }
  }

  submitSuccess(patientId: string): void {
    this.patientAddedSuccessfully.emit(patientId);
    this.cancelForm();
    if (this.navigateNewOrderPage) {
      this.router.navigate(
        this.semanticPathService.transform({
          cxRoute: 'newOrderPage',
          params: { patientID: patientId },
        })
      );
    }
  }

  titleSelected(event: DropdownOptions): void {
    if (event?.value) {
      this.resetTitleDropDown = false;
      this.addPatientForm.get('titleCode').setValue(event.value);
    } else {
      this.addPatientForm.get('titleCode').setValue('');
      this.resetTitleDropDown = true;
    }
  }

  trimString(formControl: AbstractControl): void {
    if (formControl && formControl.value && typeof formControl.value === 'string') {
      formControl.setValue(formControl.value.trim());
    }
  }

  cancelForm(): void {
    this.cancel.emit('cancelled');
  }

  addAdditionalAddress(isAdditionalAddress: boolean, index = 0): void {
    this.isAdditionalAddress = isAdditionalAddress;
    this.showInValidIcon = false;
    if (isAdditionalAddress) {
      this.addresses.push(this.addressService.getAddressFields());
      this.counties$.push(null);
    } else {
      if (this.addresses.length > 1) {
        this.counties$.pop();
        this.addresses.pop();
      }
    }
  }

  deleteAdditionalAddress(): void {
    if (this.editPatient && this.editPatient.addresses?.length > 1) {
      this.patientService
        .deleteAdditionalAddress(this.addPatientForm.getRawValue())
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (val) => {
            this.clearAdditionalAddress();
            this.cd.detectChanges();
          },
          (error) => {
            this.addressError = error.error.errors[0].message;
            this.cd.detectChanges();
          }
        );
    } else {
      this.clearAdditionalAddress();
    }
  }
  clearAdditionalAddress(): void {
    this.isAdditionalAddress = false;
    if (this.addresses.length > 1) {
      this.counties$.pop();
      this.addresses.pop();
    }
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
