<form class="register-terms" [formGroup]="termsForm" (ngSubmit)="submit()">
  <h1 class="register-terms__title d-none d-lg-block">
    {{ 'registerPage.termsAndConditionTitle' | cxTranslate }}
  </h1>
  <div class="register-terms__subtitle d-none d-lg-block">
    {{ 'registerPage.registrationFormTitle' | cxTranslate }}
  </div>
  <div class="register-terms__section">
    <div class="register-terms__section__main">
      <h3 class="register-terms__section__main__title">
        {{ 'registerPage.termsAndConditions' | cxTranslate }}
      </h3>
      <div class="register-terms__section__main__terms">
        <div *ngFor="let checkbox of termsCheckBoxes$ | async; index as i">
          <input type="checkbox" (click)="checkTermsAndConditions(i)" [name]="i" class="alconInput__checkbox" />
          <label class="register-terms__section__main__terms__label--checkbox" [for]="i">
            <div class="term-description" [innerHTML]="checkbox"></div>
          </label>
        </div>
      </div>
      <h3 class="register-terms__section__main__title mt-4 pt-4">
        {{ 'registerPage.consent' | cxTranslate }}
      </h3>
      <div
        class="register-terms__section__main__disclaimer"
        [innerHTML]="'registerPage.termsAndConditionDisclaimer' | cxTranslate"
      ></div>
      <div class="register-terms__section__main__mandatory_field_text">
        {{ 'registerPage.mandatory' | cxTranslate }}
      </div>
      <div class="register-terms__section__main__buttons">
        <div class="register-terms__section__main__buttons__leftside">
          <button class="btn btn-link register-terms__section__main__buttons__back" (click)="back()">
            <cx-icon class="register-terms__section__main__buttons__back__icon" type="ArrowRight"> </cx-icon>
            <div class="register-terms__section__main__buttons__back__text">
              {{ 'registerPage.back' | cxTranslate }}
            </div>
          </button>
        </div>
        <div class="register-terms__section__main__buttons__rightside">
          <button class="btn btn-link register-terms__section__main__buttons__cancel" (click)="cancel()">
            {{ 'registerPage.cancel' | cxTranslate }}
          </button>
          <button
            type="submit"
            class="btn btn-primary text-uppercase register-terms__section__main__buttons__submit"
            [attr.disabled]="disabledSubmit || !termsAndConditions.valid ? true : null"
          >
            {{ 'registerPage.submit' | cxTranslate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
