import { Component, Input, OnInit } from '@angular/core';
import { VisionCareAddtocartProductRequest } from 'src/app/store/States/cartItem.state';

@Component({
  selector: 'app-checkout-shipping-address',
  templateUrl: './checkout-shipping-address.component.html',
  styleUrls: ['./checkout-shipping-address.component.scss'],
})
export class CheckoutShippingAddressComponent implements OnInit {
  @Input() order: VisionCareAddtocartProductRequest;
  constructor() {}

  ngOnInit(): void {}
}
