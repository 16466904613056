import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslationService } from '@spartacus/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { CountryCountyService } from 'src/app/services/patient/country-county.service';
import { DropdownOptions, DropdownSelectedStatus } from 'src/app/shared/model/common.mode';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss'],
})
export class CountryComponent {
  constructor(
    private countyCountryService: CountryCountyService,
    private cd: ChangeDetectorRef,
    private translation: TranslationService
  ) {}

  readonly dropdownConfig = this.translation
    .translate('productListingPage.select')
    .pipe(map((text) => ({ defaultSelectText: text })));

  selectedCountry: BehaviorSubject<string> = new BehaviorSubject('');
  options: Observable<DropdownOptions[]> = this.getCountries();
  disable = false;

  @Input() selectedStatus = DropdownSelectedStatus.NEUTRAL;
  @Input() set selected(select) {
    this.cd.detectChanges();
    this.selectedCountry.next(select);
    this.options = this.getCountries();
  }
  @Input() set disableDropDown(value: boolean) {
    this.disable = value;
  }

  @Output() dropDownSelected: EventEmitter<DropdownOptions> = new EventEmitter();
  countrySelected(selectedOption: DropdownOptions): void {
    this.dropDownSelected.emit(selectedOption);
  }

  getCountries(): Observable<DropdownOptions[]> {
    return this.countyCountryService.getCountries().pipe(
      map((data) => data.countries),
      withLatestFrom(this.selectedCountry),
      map(([countries, selectedCountry]) => {
        return countries.map((country) => {
          return {
            text: country.name,
            value: country.isocode,
            selected: selectedCountry === country.isocode,
          } as DropdownOptions;
        });
      }),
      map((countries) => {
        if (countries.length === 1) {
          countries[0].selected = true;
        }
        return countries;
      }),
      tap((countries) => {
        if (countries.find((country) => country.selected)) {
          this.countrySelected(countries.find((country) => country.selected));
        }
      })
    );
  }
}
