import { Component, Input } from '@angular/core';
import { InvoiceInfo } from 'src/app/shared/model/invoice-list.model';

@Component({
  selector: 'app-invoice-order-number-accordion',
  templateUrl: './invoice-order-number-accordion.component.html',
  styleUrls: ['./invoice-order-number-accordion.component.scss'],
})
export class InvoiceOrderNumberAccordionComponent {
  @Input() invoice: InvoiceInfo;
  expandAccordion = false;
  currentInvoiceNumber: string;

  toggleAccordion(invoiceNumber: string): void {
    this.currentInvoiceNumber = invoiceNumber;
    this.expandAccordion = !this.expandAccordion;
  }
}
