<div class="alertPopup">
  <div class="modal-header border-0">
    <cx-icon role="button" type="Close" class="alertPopup__closeBtn" (click)="activeModal.dismiss()"></cx-icon>
  </div>
  <div class="modal-body alertPopup__text">
    <p class="alertPopup__text__infoMsg" *ngIf="alertConfig.infoMsg" [innerHTML]="alertConfig.infoMsg"></p>
    <p
      class="alertPopup__text__confirmationMsg"
      [innerHTML]="alertConfig.msg"
      [ngClass]="{ 'pt-4': !alertConfig.infoMsg }"
    ></p>
  </div>
  <div class="modal-footer border-0 p-0 justify-content-center">
    <a class="col-2 alertPopup__primaryBtn" target="_blank" role="button" (click)="alertBtnClick($event)">
      {{ alertConfig.btnOneTxt }}</a
    >
    <button class="btn btn-primary alertPopup__secondaryBtn" (click)="alertBtnClick($event)">
      {{ alertConfig.btnTwoTxt }}
    </button>
  </div>
</div>
