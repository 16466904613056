import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoutesService } from '@spartacus/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EntryRoot } from 'src/app/shared/model/product.model';
import { Utilities } from '../../shared/utility/utility';
@Component({
  selector: 'app-alcon-list-items',
  templateUrl: './alcon-list-items.component.html',
  styleUrls: ['./alcon-list-items.component.scss'],
})
export class AlconListItemsComponent implements OnInit, OnChanges, OnDestroy {
  isAccordionChange: boolean;
  quantity: number;
  @Input() openPanel: boolean;
  @Input() entry: any;
  @Input() finalPriceCalculated: boolean;
  @Input() isCheckoutPage: boolean;
  entryelement: EntryRoot;
  isOrderConfirmationPage: boolean;
  sortedValuePacks = [];
  openConfirmedOrder = true;
  private destroy$ = new Subject<void>();
  constructor(private activatedRoutesService: ActivatedRoutesService) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.entry) {
      this.entry = changes.entry.currentValue;
      if (this.entry?.children) {
        this.sortedValuePacks = Utilities.getSortedValuePack(this.entry?.children[0]?.entries);
      } else {
        this.sortedValuePacks = [];
      }
      this.quantity = this.getTotalQuantity();
    }
  }

  ngOnInit(): void {
    this.isAccordionChange = true;
    if (this.entry?.children) {
      this.sortedValuePacks = Utilities.getSortedValuePack(this.entry?.children[0]?.entries);
    } else {
      this.sortedValuePacks = [];
    }
    this.entryelement = JSON.parse(JSON.stringify(this.entry ?? {}));
    this.activatedRoutesService.routes$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      const type = data[data.length - 1].data.Type;
      switch (type) {
        case 'OrderConfirmation':
          this.isOrderConfirmationPage = true;
          break;
      }
    });
    if (this.openPanel) {
      this.entryelement.openPanel = true;
    }
    this.quantity = this.getTotalQuantity();
  }

  getTotalQuantity(): number {
    let totalQuantity = this.entry?.entries
      ? this.entry?.entries.reduce((sum, item) => sum + Number(item.quantity), 0)
      : 0;
    if (this.entry?.children?.length) {
      totalQuantity += this.entry.children[0]?.entries.length;
    }
    return totalQuantity;
  }

  open(): void {
    if (this.entryelement.openPanel) {
      this.entryelement.openPanel = false;
      this.isAccordionChange = false;
      this.openConfirmedOrder = true;
    } else {
      this.entryelement.openPanel = true;
      this.isAccordionChange = true;
      this.openConfirmedOrder = false;
    }
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
