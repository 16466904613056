import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CmsService } from '@spartacus/core';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-register-success-step',
  templateUrl: './register-success-step.component.html',
  styleUrls: ['./register-success-step.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterSuccessStepComponent {
  public button$ = this.cmsService.getComponentData('LoginLink');
  public par1$ = this.cmsService.getComponentData('VCRegistrationConfirmationSection2APara');
  public par2$ = this.cmsService.getComponentData('VCRegistrationConfirmationSection2BPara');
  public par3$ = this.cmsService.getComponentData('VCRegistrationConfirmationSection3APara');
  public image$ = this.cmsService.getComponentData('RegistrationConfirmationImageComponent');

  constructor(public router: Router, public cmsService: CmsService) {}
}
