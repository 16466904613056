<div class="delivery">
  <div class="delivery__option" *ngIf="showRegular$ | async">
    <input
      type="radio"
      [value]="deliveryTypes.REGULAR"
      id="delivery-practice-order"
      [(ngModel)]="deliveryType"
      (ngModelChange)="deliveryTypeChanged()"
      name="deliveryType"
      class="alconInput__radio"
      [disabled]="disable"
    />
    <label
      for="delivery-practice-order"
      class="delivery--radio-label"
      [ngClass]="{ 'delivery--radio-label-disabled': disable }"
      >{{ 'configureProducts.deliveryPanel.practiceOrder' | cxTranslate }}</label
    >
  </div>
  <div class="delivery__option" *ngIf="showDTP">
    <input
      type="radio"
      [disabled]="disable"
      [value]="deliveryTypes.DIRECT_TO_PATIENT"
      id="delivery-direct-to-patient"
      class="alconInput__radio"
      [(ngModel)]="deliveryType"
      (ngModelChange)="deliveryTypeChanged()"
      name="deliveryType"
    />
    <label
      for="delivery-direct-to-patient"
      class="delivery--radio-label"
      [ngClass]="{ 'delivery--radio-label-disabled': disable }"
      >{{ 'configureProducts.deliveryPanel.directToPatient' | cxTranslate }}</label
    >
  </div>
</div>
