import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { TranslationService } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AddressService } from 'src/app/services/addresses/address.service';
import { CountryCountyService } from 'src/app/services/patient/country-county.service';
import { POSTAL_CODE_VALIDATOR_CONST } from 'src/app/shared/constants/constants';
import { DropdownOptions, DropdownSelectedStatus } from 'src/app/shared/model/common.mode';
@Component({
  selector: 'app-patient-address-form',
  templateUrl: './patient-address-form.component.html',
  styleUrls: ['./patient-address-form.component.scss'],
})
export class PatientAddressFormComponent implements OnInit {
  address: UntypedFormGroup;
  @Input() AddressIndex = 0;
  @Input() ShowClearAll = true;
  counties$: Observable<DropdownOptions[]>;
  showInValidIcon: boolean;
  readonly dropdownConfig = this.translation
    .translate('productListingPage.select')
    .pipe(map((text) => ({ defaultSelectText: text })));

  selectedStatus = DropdownSelectedStatus.NEUTRAL;
  selectedCountry: string;

  resetCountyDropDown = false;
  clearCounty = false;
  MaxLenForUnRegCountries = 10;

  constructor(
    private countyCountryService: CountryCountyService,
    private formGroupDirective: FormGroupDirective,
    private cd: ChangeDetectorRef,
    private addressService: AddressService,
    private translation: TranslationService
  ) {}

  ngOnInit(): void {
    /* tslint:disable-next-line */
    const addresscontrol = this.formGroupDirective.control.get('addresses')['controls'];
    this.address = addresscontrol[this.AddressIndex];
    this.getCounties(this.addressCountry);
    this.selectedCountry = this.addressCountry;
  }
  get isocode(): string {
    return this.address.get('region').get('isocode').value;
  }
  get addressCountry(): string {
    return this.address.get('country').get('isocode').value;
  }

  get hasPostalCodeError(): boolean {
    return this.address.hasError(`invalidPostalCode`) || this.address.hasError(`coutryMatch`);
  }
  trimString(formControl: AbstractControl): void {
    if (formControl && formControl.value && typeof formControl.value === 'string') {
      formControl.setValue(formControl.value.trim());
    }
  }

  countrySelected(isoCode: string, name: string, country: AbstractControl, index, canShowSuccessIcon = true): void {
    if (isoCode) {
      this.selectedStatus = canShowSuccessIcon ? DropdownSelectedStatus.SUCCESS : DropdownSelectedStatus.NEUTRAL;
      country.get('isocode').setValue(isoCode);
      country.get('name').setValue(name);
      this.counties$ = this.countyCountryService.getCounties(isoCode).pipe(
        map((data) => data.regions),
        map((counties) => {
          counties = counties.filter((county) => county.name?.length > 0);
          if (counties.length) {
            const countiesList = [...counties];
            return countiesList.map((countryOption) => {
              if (countryOption.name) {
                return {
                  text: countryOption.name,
                  value: countryOption.isocode,
                  selected:
                    this.address.controls.region.get('isocode').value === countryOption.isocode && !this.clearCounty
                      ? true
                      : false,
                } as DropdownOptions;
              }
            });
          } else {
            return [];
          }
        }),
        tap((counties) => {
          if (counties.find((countie) => countie.selected)) {
            this.countySelected(
              counties.find((countie) => countie.selected),
              this.address.controls.region
            );
          } else {
            this.resetCountySelected();
          }
        })
      );
    }
    this.cd.detectChanges();
  }
  countySelected(event: DropdownOptions, region: UntypedFormGroup | AbstractControl): void {
    if (event?.value) {
      this.resetCountyDropDown = false;
      region.get('isocode').setValue(event.value);
      region.get('name').setValue(event.text);
    } else {
      region.get('isocode').setValue('');
      region.get('name').setValue('');
      this.clearCounty = true;
      this.resetCountyDropDown = true;
    }
  }

  clearall(): void {
    const id = this.address.value.id;
    this.address.reset();
    if (this.AddressIndex === 0) {
      this.address.controls.defaultAddress.setValue(true);
    }
    this.showInValidIcon = false;
    this.counties$ = of([]);
    this.resetCountySelected();
    this.selectedCountry = '';
    this.address.get('id').setValue(id);
  }

  resetCountySelected(): void {
    const region = this.address.controls.region;
    region.get('isocode').setValue('');
    region.get('name').setValue('');
  }

  getCounties(selectedCountry): void {
    if (selectedCountry) {
      this.counties$ = this.countyCountryService.getCounties(selectedCountry).pipe(
        map((data) => data.regions),
        map((counties) => {
          counties = counties.filter((county) => county.name?.length > 0);
          if (counties.length) {
            this.address.controls.region.get('isocode');
            this.address.controls.region.get('isocode').updateValueAndValidity();
            return counties.map((countryOption) => {
              if (countryOption.name) {
                return {
                  text: countryOption.name,
                  value: countryOption.isocode,
                  selected: this.isocode === countryOption.isocode,
                } as DropdownOptions;
              }
            });
          } else {
            return [];
          }
        })
      );
    }
  }
  postalCodeMaxLengthValidator(formControl: AbstractControl): number {
    const selectedCountry = formControl.get('country').get('isocode').value;
    if (formControl.get('country').get('isocode').valid) {
      if (this.addressService.isValidationExist(POSTAL_CODE_VALIDATOR_CONST, selectedCountry)) {
        const { Maxlength } = POSTAL_CODE_VALIDATOR_CONST[formControl.get('country').get('isocode').value];
        return Maxlength;
      } else {
        return this.MaxLenForUnRegCountries;
      }
    }
  }
}
