import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PostalCodeValidatorType } from '../model/postal-code-validator-type.model';

export const NAVIGATION_LINKS = {
  NEW_ORDER: 'New Order',
};

export const DIRECT_TO_PRACTICE = {
  BRAND: 'BRAND',
  PRACTICE_ORDER: '/practice-order',
  TYPE: 'Type',
  PRODUCT_COLOR: 'COLOR',
  PRODUCT_MULTIFOCAL: 'MULTIFOCAL',
  PRODUCT_SPERICAL: 'SPHERICAL',
  PRODUCT_TORIC: 'TORIC',
  STOCK_ORDER: 'STOCK_ORDER',
  TIMEOUTRANGE: 2000,
  STOCK_ORDER_PAGE: 'stockOrder',
  PRACTICE_ORDER_PAGE: 'practiceOrder',
  REGULAR: 'REGULAR',
  regular: 'Regular',
  VALUE_PACK: 'VALUE_PACK',
  valuePacks: 'Value pack',
  practiceOrder: 'Practice order',
  directToPatient: 'Direct to patient',
  DTP_VALUE_PACK: 'DTP_VALUE_PACK',
  PRACTICE: 'practice',
  DIRECT_TO_PATIENT: 'DIRECT_TO_PATIENT',
};
export const ORDER_TYPE = {
  Individual: 'Individual',
};
export const DIRECT_TO_PATIENT = {
  DIRECT_TO_PATIENT_ORDER: 'DIRECT_TO_PATIENT',
};
export const EYESIDE = {
  LEFT: 'left',
  RIGHT: 'right',
  BOTH: 'both',
};

export const CHECKOUT = {
  PAYMENT_TYPE_ACCOUNT: 'ACCOUNT',
  PAYMENT_TYPE_CARD: 'CARD',
  CART: 'Cart',
  paymentType: 'Invoice to Account',
};

export const LENSAXIS = {
  LENS_AXIS: [
    '010',
    '020',
    '030',
    '040',
    '050',
    '060',
    '070',
    '080',
    '090',
    '100',
    '110',
    '120',
    '130',
    '140',
    '150',
    '160',
    '170',
    '180',
  ],
};
export const CART_VARIENTS = {
  BASE_CURVE: 'BC',
  DIAMETER: 'DIA',
  ADD: 'ADD',
  COLOR: 'Colour',
  CYLINDER: 'CY',
  LENS_POWER: 'LP',
};

export const VarientCombinations = {
  BC: 'basecurve',
  DIA: 'diameter',
  ADD: 'powerAdd',
  Colour: 'color',
  CY: 'cylinder',
};

export const PROTOCOL = {
  HTTP: 'http:',
  HTTPS: 'https:',
};

export const SPECIFICATIONS = ['BC', 'DIA', 'ADD', 'Colour', 'CY'];

export enum CART_GROUP {
  PRACTICE_ORDER = 'PRACTICE_ORDER',
  DIRECT_TO_PATIENT = 'DIRECT_TO_PATIENT',
  INDIVIDUAL_ORDER = 'INDIVIDUAL_ORDER',
  STOCK_ORDER = 'STOCK_ORDER',
  VALUE_PACK = 'VALUE_PACK',
  CODE = 'current',
  DTP_VALUE_PACK = 'DTP_VALUE_PACK',
}

export enum Statues {
  NEW = 'orderStatus.NEW',
  IN_PROGRESS = 'orderStatus.IN_PROGRESS',
  SHIPPED = 'orderStatus.SHIPPED',
  CANCELLED = 'orderStatus.CANCELLED',
  PENDING_CONSOLIDATION = 'orderStatus.PENDING_CONSOLIDATION',
  DELIVERED = 'orderStatus.DELIVERED',
  NOT_SHOWN = 'orderStatus.Not_Shown',
  BILLED = 'orderStatus.BILLED',
  PARTIAL_BACKORDER = 'orderStatus.PARTIAL_BACKORDER',
  BACKORDER = 'orderStatus.BACKORDER',
}

export const SORT = {
  sortTypeAsc: 'asc',
  sortTypeDESC: 'desc',
};

export const ContactUs = {
  Morning: 'Morning',
  AM: 'AM',
  PM: 'PM',
  Email: 'email',
  PhoneNumber: 'phoneNumber',
};

export const PACK_SIZE = {
  TRIAL: 'Trial',
  NINETY: '90',
};
export const PageType = {
  EditScheduleOrder: 'editScheduledOrder',
};

export enum Variants {
  BASECURVE = 'basecurve',
  DIAMETER = 'diameter',
  LENSPOWER = 'lenspower',
  COLOR = 'color',
  POWERADD = 'powerAdd',
  CYLINDER = 'cylinder',
  AXIS = 'axis',
  PACKSIZE = 'packSize',
  VARIANTCODE = 'variant',
  ORDERNUMBER = 'orders',
}

export enum OrderPanel {
  CLASSNAME = '__columnDashboard',
}

export enum SCHEDULE_ORDER {
  MININUM_REPEAT_VALUE = 1,
  MAXIMUM_REPEAT_VALUE = 12,
}
export const LOGIN_PAGE = {
  DEFAULT_MESSAGE: 'Use your Online ID to access your account and place an order.',
};
export const VALIDATOR_CONSTANT = {
  EMAIL_PATTREN: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$',
};

export const POSTAL_CODE_VALIDATOR_CONST = {
  GB: {
    pattern: '^([A-Z]{1,2}\\d[A-Z\\d]? ?\\d[A-Z]{2}|GIR ?0A{2})$',
    Maxlength: 9,
    required: true,
  } as PostalCodeValidatorType,
  IE: { pattern: '(?:^[A-Z][0-9]{2}|D6W)[ -]?[0-9A-Z]{4}$', Maxlength: 8, required: true } as PostalCodeValidatorType,
  AU: { pattern: '^[0-9]{4}$', Maxlength: 4, required: true } as PostalCodeValidatorType,
  NZ: { pattern: '^[0-9]{4}$', Maxlength: 4, required: true } as PostalCodeValidatorType,

  FK: { pattern: '(FIQQ 1ZZ)', Maxlength: 8, required: true } as PostalCodeValidatorType,
  GG: {
    pattern: '^([A-Z]{1,2}\\d[A-Z\\d]? ?\\d[A-Z]{2}|GIR ?0A{2})$',
    Maxlength: 9,
    required: true,
  } as PostalCodeValidatorType,
  IM: {
    pattern: '^([A-Z]{1,2}\\d[A-Z\\d]? ?\\d[A-Z]{2}|GIR ?0A{2})$',
    Maxlength: 9,
    required: true,
  } as PostalCodeValidatorType,
  JE: {
    pattern: '^([A-Z]{1,2}\\d[A-Z\\d]? ?\\d[A-Z]{2}|GIR ?0A{2})$',
    Maxlength: 9,
    required: true,
  } as PostalCodeValidatorType,
  XI: {
    pattern: '^([A-Z]{1,2}\\d[A-Z\\d]? ?\\d[A-Z]{2}|GIR ?0A{2})$',
    Maxlength: 9,
    required: true,
  } as PostalCodeValidatorType,
  DE: { pattern: '^[0-9]{5}$', Maxlength: 5, required: true } as PostalCodeValidatorType,
  IT: { pattern: '^[0-9]{5}$', Maxlength: 5, required: true } as PostalCodeValidatorType,
  FR: { pattern: '^[0-9]{5}$', Maxlength: 5, required: true } as PostalCodeValidatorType,
  PL: { pattern: '^[0-9]{5}$', Maxlength: 5, required: true } as PostalCodeValidatorType,
  ES: { pattern: '^[0-9]{5}$', Maxlength: 5, required: true } as PostalCodeValidatorType,
};

export const LOGIN_ERROR_TYPE = {
  BAD_CREDENTIALS: 'BadCredentialsException',
  INVALID_USER: 'UsernameNotFoundException',
  LOCKED_USER: 'LockedException',
};
export const Specifications = ['BC', 'DIA', 'LP', 'ADD', 'CY', 'AX', 'Colour'];

export const addressType = {
  MAIN_ADDRESS: 'MainAddress',
  ADDITIONAL_ADDRESS: 'AdditionalAddress',
};
export const CART_TYPE = {
  UPDATE: 'update',
};

export const EYESIDE_SHORT_FORM = {
  RIGHT: 'R',
  LEFT: 'L',
};

export enum ORDER_LABELS {
  INDIVIDUAL_ORDER = 'Individual orders',
  VALUE_PACK = 'Value packs',
  STOCK_ORDER = 'Stock orders',
  DIRECT_TO_PATIENT = 'Direct to patients',
  DIRECT_PURCHASE = 'Direct purchase',
  CUSTOMPACK = 'Custompak',
  CONSUMABLE = 'Consumables',
}

export enum IOL_ORDER_LABELS {
  DIRECT_PURCHASE = 'IOL Direct Purchase',
  ADD_TO_CONSIGNMENT = 'IOL Add to Consignment',
  BILL_ONLY = 'IOL Bill',
}

export enum ORDER_STATUS {
  NEW = 'New',
  IN_PROGRESS = 'In progress',
  SHIPPED = 'Shipped',
  CANCELLED = 'Cancelled',
  UNSHIPPED = 'unshipped',
  PENDING_CONSOLIDATION = 'Pending consolidation',
  DELIVERED = 'Delivered',
  BILL_ONLY = 'Bill Only',
  PARTIAL_BACKORDER = 'Partial backorder',
  BACK_ORDER = 'Backorder',
}

export const ORDER_STATUS_LIST = {
  NEW: 'NewOrder',
  IN_PROGRESS: 'NewOrder',
  SHIPPED: 'Shipped',
  CANCELLED: 'Cancelled',
  PENDING_CONSOLIDATION: 'PendingConsolidation',
  DELIVERED: 'Delivered',
  PARTIAL_BACKORDER: 'PartialBackorder',
  BACKORDER: 'Backorder',
};

export const ORDER_STATUS_TXT = {
  NEW: 'New',
  IN_PROGRESS: 'In Progress',
  DELAYED: 'Delayed',
  PARTIALLY_SHIPPED: 'Partially Shipped',
  SHIPPED: 'Shipped',
  CANCELLED: 'Cancelled',
  UNSHIPPED: 'Unshipped',
  PENDING_CONSOLIDATION: 'Pending Consolidation',
  DELIVERED: 'Delivered',
  BILL_ONLY: 'BILL_ONLY',
  READY_FOR_SHIPPING: 'READY_FOR_SHIPPING',
  DELIVERY_COMPLETED: 'DELIVERY_COMPLETED',
  SHIPPED_FILTER: 'shipped',
  DELIVERED_FILTER: 'COMPLETED',
};

export const Category = {
  SERGICAL_BUSINESS: 'Surgical Business',
  DRY_EYE_PRODUCTS: 'dryEyeProducts',
  CONTACT_LENS_SOLUTION: 'contactLensSolution',
  CONTACT_LENSES: 'Contact Lenses',
};
export const GlobalMessage = {
  TIMEOUT: 10000,
};
export const ErrorResponse = {
  CART_MAX_EXCEPTION: 'CartMaxProductQuantityExceedException',
  ALCON_BUSINESS_EXCEPTION: 'AlconBusinessException',
};

export const NoDataText = {
  NoResultsFound: 'No results found',
  NoProductsFound: 'No products found',
  NoPatientsFound: 'No patients found',
};
export enum DELIVERY_TYPE {
  REGULAR = 'REGULAR',
  DIRECT_TO_PATIENT = 'DIRECT_TO_PATIENT',
  DIRECT_TO_PATIENT_ORDER_TYPE = 'Direct to patient',
}
export const COMMON = {
  EMPTY: '',
};
export const PDP_SECTION = {
  DOWNLOADS: 'Downloads',
  FEATURES: 'Features',
};
export const NEW_ORDER = {
  INDIVIDUAL_ORDER: 'individual-order',
  STOCK_ORDER: 'stock-order',
};
export const REORDER_VALIDATION = {
  itemDiscontinued: 'PRODUCT_DISCONTINUED_ERROR',
  maxLimit: 'CartMaxProductQuantityExceedException',
  alconBusinessException: 'AlconBusinessException',
  patientDeleted: 'INVALID_PATIENT_ERROR',
  valuePackDiscontinued: 'VALUE_PACK_PRODUCT_DISCONTINUED_ERROR',
  DTPMaxLimit: 'AlconDtpCartMaxQuantityException',
};
export const CANCELORDER_ERRORRESPONSE = {
  ERROR_UNSUPPORTEDOPERATION: 'UnsupportedOperationException',
  GENERIC_ERRORMESSAGE: 'There was some error with your request. Please try again',
};

export const STOCK_ORDER_PAGE = {
  LENS_IN_VIEW: 40,
};

export enum FILTER_ORDER_STATUS {
  NEW = 'CREATED,SUBMITTED,OPEN,PENDING_CONSOLIDATION',
  IN_PROGRESS = 'BEING_PROCESSED,IN_PROCESS',
  BACK_ORDER = 'BACK_ORDER',
  PARTIAL_BACKORDER = 'PARTIAL_BACKORDER',
  SHIPPED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  DELIVERED = 'DELIVERED',
  BILLED = 'BILLED',
}
export const AUTO_ORDER = {
  YES: 'YES',
  NO: 'NO',
  Cancel: 'Cancel',
  Save: 'Save',
};
export const VALUE_PACK_TYPES = {
  THREE_MONTH: '3-month pack',
  SIX_MONTH: '6-month pack',
};
export const RETURN_CONFIRMATION = {
  Timeout: 3000,
};
export const PATIENTLISTFILTERS = {
  Individual: 'Individual orders',
  subscriptions: 'Subscriptions',
  Active: 'Active',
  Inactive: 'Inactive',
  regular: 'Regular',
  valuepack: 'Value pack',
  practiceOrder: 'Practice order',
  directtoPatient: 'Direct to patient',
};

export const INITIAL_PRICE = {
  totalprice: '£00.00',
};

export const USER_PERMISSION_ROLES = {
  HIDE_SUBSCRIPTION: 'hidesubscription',
  HIDE_ORDER_HISTORY: 'hideorderhistory',
  HIDE_PRICE_GROUP: 'hidepricegroup',
};

export const INVOICES_TYPE = {
  ALL: 'All',
  PAID: 'Paid',
  UNPAID: 'Unpaid',
  OVERDUE: 'Overdue',
};

export const FAQ_PROPERTIES = {
  typeCount: 3,
};

export const BACKDROP_CLICK = {
  backdropclick: 0,
};

export const INVOICELIST_TYPES = {
  DAILYINVOICE: 'DAILYINVOICE',
  MONTHLYINVOICE: 'INVOICELIST',
};

export const MODAL_POPUP_CONFIG: NgbModalOptions = {
  backdrop: false,
  keyboard: false,
  centered: true,
};

export enum FRANCHISE_SELECTOR {
  SURGICAL = 'sx',
  VISION_CARE = 'vc',
}

export const ADDITIONAL_ACCOUNT = {
  CREATED_SUCCESSFULLY: 'You now have access to an additional Alcon account.',
};
export enum ADDRESS_TYPES {
  SOLD_TO_ADDRESS = 'ag',
  SHIP_TO_ADDRESS = 'we',
}

export enum PRODUCT_TYPES {
  SURGICAL_VALUE = 'surgicalusergroup',
  VISION_CARE_PRDUCT_VALUE = 'visioncareusergroup',
}

export const LOCALSTORAGE_KEY = {
  SELECTED_SOLD_TO: 'selectedSoldTo',
  SELECTED_SOLD_TO_COUNT: 'selectedSoldToCount',
  SHIP_TO_COUNT: 'shipToCount',
  SELECTED_SHIP_TO: 'selectedShipTo',
};

export const ERROR_KEYS = {
  CHECK_ACCOUNT_EXIST: 'error_12215',
  VARIFY_ACCOUNT_ACCESS: 'error_12212',
};

export enum ADDRESS_PERMISSION_KEY {
  NO_ACCESS_ACCOUNT = 'noAccessAccounts',
  INACTIVE_ACCOUNT = 'inActiveAccounts',
  ACTIVE_ACCOUNT = 'activeAccounts',
}
export const ERROR_TYPE = {
  alconBusinessError: 'AlconBusinessError',
  accessError: 'AccessError',
};
export enum USERMANAGEMENT_TABS {
  EXISTING_USERS = 'existingUsers',
  PENDING_USERS = 'pendingUsers',
}
