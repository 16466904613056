import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlconUserPermissionService } from 'src/app/shared-modules/alcon-user-permission-provider/service/alcon-user-permission.service';

@Component({
  selector: 'app-cart-valuepack-lineitem',
  templateUrl: './cart-valuepack-lineitem.component.html',
  styleUrls: ['./cart-valuepack-lineitem.component.scss'],
})
export class CartValuepackLineitemComponent implements OnInit {
  constructor(private route: ActivatedRoute, private userPermissionService: AlconUserPermissionService) {}
  @Input() cartEntry: any;
  @Input() showActions: boolean;
  valuePackduration: string;
  @Input() orderType: string;
  @Input() showPatientReference = true;
  calculated = false;
  isPriceVisible: boolean;
  ngOnInit(): void {
    this.isPriceVisible = this.userPermissionService.getPriceStatementPermission();
    this.calculated = this.route.snapshot.data.vcCart?.calculated;
    if (this.cartEntry.entries && this.cartEntry.entries[0]?.valuePackAttributes?.valuePackDuration) {
      this.valuePackduration = this.cartEntry.entries[0]?.valuePackAttributes?.valuePackDuration + '-months pack';
    }
  }
  deleteItem(value): void {
    this.cartEntry = { ...{} };
  }
}
