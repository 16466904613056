import { Injectable } from '@angular/core';
import { USER_PERMISSION_ROLES } from 'src/app/shared/constants/constants';

@Injectable({
  providedIn: 'root',
})
export class AlconUserPermissionService {
  private showSubscriptionPage = true;
  private showOrderHistoryPage = true;
  private showPriceStatements = true;
  private showPdpButton = true;
  private showPrices = true;
  private showSubscriptions = true;
  private showStatements = true;
  private showOrderhistory = true;
  private showInvoices = true;
  private showPatientManagement = true;
  private isAddToCartEnabled = true;
  private isReturnsEnabled = true;
  private isDTPDeliveryEnabled = true;

  constructor() {}

  public setPermissions(userDetails): void {
    this.showPdpButton = userDetails?.pdpVisibility;
    this.showPatientManagement =
      userDetails?.patientManagementVisibility !== undefined ? userDetails?.patientManagementVisibility : true;
    this.showPrices = userDetails?.priceVisibility !== undefined ? userDetails?.priceVisibility : true;
    this.showSubscriptions =
      userDetails?.subscriptionsVisibility !== undefined ? userDetails?.subscriptionsVisibility : true;
    this.showStatements = userDetails?.statementVisibility !== undefined ? userDetails?.statementVisibility : true;
    this.showOrderhistory =
      userDetails?.orderHistoryVisibility !== undefined ? userDetails?.orderHistoryVisibility : true;
    this.showInvoices = userDetails?.invoicesAccessible !== undefined ? userDetails?.invoicesAccessible : true;
    this.isAddToCartEnabled = userDetails?.addToCartVisibility !== undefined ? userDetails?.addToCartVisibility : true;
    this.isReturnsEnabled = userDetails?.isReturnsEnabled;
    this.isDTPDeliveryEnabled = userDetails?.isDTPDeliveryEnabled;

    for (const permission of userDetails?.roles) {
      if (permission === USER_PERMISSION_ROLES.HIDE_SUBSCRIPTION) {
        this.showSubscriptionPage = false;
      }
      if (permission === USER_PERMISSION_ROLES.HIDE_ORDER_HISTORY) {
        this.showOrderHistoryPage = false;
      }
      if (permission === USER_PERMISSION_ROLES.HIDE_PRICE_GROUP) {
        this.showPriceStatements = false;
      }
    }
  }

  public getAddToCartEnabled(): boolean {
    return this.isAddToCartEnabled;
  }

  public getDTPDeliveryPermission(): boolean {
    return this.isDTPDeliveryEnabled;
  }

  public getSubscriptionPagePermission(): boolean {
    return this.showSubscriptionPage;
  }

  public getOrderHistoryPagePermission(): boolean {
    return this.showOrderHistoryPage;
  }

  public getPriceStatementPermission(): boolean {
    return this.showPriceStatements;
  }

  public getPDPButtonpermission(): boolean {
    return this.showPdpButton;
  }

  public getSubscriptionsPermission(): boolean {
    return this.showSubscriptions;
  }

  public getStatementsPermission(): boolean {
    return this.showStatements;
  }

  public getPricesVisibilityPermission(): boolean {
    return this.showPrices;
  }

  public getOrderhistoryPermission(): boolean {
    return this.showOrderhistory;
  }
  public getInvoicesPermission(): boolean {
    return this.showInvoices;
  }
  public getPatientManagementPermission(): boolean {
    return this.showPatientManagement;
  }
  public getReturnsEnabledPermission(): boolean {
    return this.isReturnsEnabled;
  }
}
