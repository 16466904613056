import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-alcon-shipping-address',
  templateUrl: './alcon-shipping-address.component.html',
  styleUrls: ['./alcon-shipping-address.component.scss'],
})
export class AlconShippingAddressComponent {
  @Input() cartAddress;
}
