import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationService } from '@spartacus/core';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { OrderHistoryService } from 'src/app/services/order-history/order-history.service';
import { MODAL_POPUP_CONFIG } from 'src/app/shared/constants/constants';
import { PatientOrderData, ToggleConfiguration } from 'src/app/store/States/orderHistory';
import { AlconAlertPopupComponent } from '../alcon-alert-popup/alcon-alert-popup.component';
import { AlconRedirectPopupComponent } from '../alcon-redirect-popup/alcon-redirect-popup.component';

@Component({
  selector: 'app-change-subscription-status',
  templateUrl: './change-subscription-status.component.html',
  styleUrls: ['./change-subscription-status.component.scss'],
})
export class ChangeSubscriptionStatusComponent implements OnInit, OnDestroy {
  @Input() subData: PatientOrderData;
  @Input() toggleData: ToggleConfiguration;
  @Input() orderCode: string;
  @Output() updatedData: EventEmitter<any> = new EventEmitter();
  modalSubscription: Subscription;
  tooltipText: string;
  popupDesc: string;
  popupBtntext: string;
  deactivateText: string;
  popupYesText: string;
  popupNoText: string;
  activeToInactiveText: string;
  inactiveToActiveText: string;
  private destroy$ = new Subject<void>();

  constructor(
    private modalService: NgbModal,
    private cd: ChangeDetectorRef,
    private orderService: OrderHistoryService,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.getTranslatedValues();
    this.appendToolTipText(this.toggleData.status);
  }

  getTranslatedValues(): void {
    combineLatest([
      this.translationService.translate('myPatientsPage.addessRemovedMsg'),
      this.translationService.translate('myPatientsPage.ok'),
      this.translationService.translate('patientOrderHistory.deactivateScheduleOrder'),
      this.translationService.translate('patientOrderHistory.popupYesText'),
      this.translationService.translate('patientOrderHistory.popupNoText'),
      this.translationService.translate('patientOrderHistory.activeToInactive'),
      this.translationService.translate('patientOrderHistory.inactiveToActive'),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.popupDesc = data[0];
        this.popupBtntext = data[1];
        this.deactivateText = data[2];
        this.popupYesText = data[3];
        this.popupNoText = data[4];
        this.activeToInactiveText = data[5];
        this.inactiveToActiveText = data[6];
      });
  }

  /**
   * In case of schedule orders
   * 1) On Toggle from active to inactive triggers
   *    a) alert component upon user confirmation updates respective API and UI
   * 2) On Toggle from inactive to active updates respective API and UI
   * @param event holds checkbox event
   */
  onStatusChange(event: { target: { checked: boolean }; preventDefault: () => void }): void {
    // active to in-active state
    if (!event.target.checked) {
      event.preventDefault();
      const modalRef = this.modalService.open(AlconAlertPopupComponent, {
        ...MODAL_POPUP_CONFIG,
        windowClass: 'patientOrderList_alertPopup',
      });
      modalRef.componentInstance.alertConfig = {
        msg: this.deactivateText,
        btnOneTxt: this.popupYesText,
        btnTwoTxt: this.popupNoText,
      };
      this.modalSubscription = modalRef.componentInstance.btnClick.subscribe((value: string) => {
        if (value === this.popupYesText) {
          this.updateScheduleActiveStatus(!event.target.checked);
          modalRef.close();
        } else {
          modalRef.close();
        }
      });
    } else {
      // in-active to active state
      this.updateScheduleActiveStatus(event.target.checked);
    }
  }

  /**
   * Makes API call to update active status for an scheduled order
   * upon response updates UI
   * @param code contains unique order code
   * @param status Flag wich says the state of order
   */
  updateScheduleActiveStatus(status: boolean): void {
    this.orderService
      .updateActiveStatus(this.orderCode, status)
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.subData.toggleConfig.status = res.active;
          this.appendToolTipText(res.active);
          if (status) {
            this.subData.nextOrder = this.orderService.splitDate(res.trigger.activationTime);
          }
          this.subData.defaultAddress = res.entries[0]?.shippingAddress?.defaultAddress;
          this.subData.address = {
            ...this.subData.address,
            country: res.entries[0]?.shippingAddress?.country,
            region: res.entries[0]?.shippingAddress?.region,
            line1: res.entries[0]?.shippingAddress?.line1,
            line2: res.entries[0]?.shippingAddress?.line2,
            postalCode: res.entries[0]?.shippingAddress?.postalCode,
            town: res.entries[0]?.shippingAddress?.town,
          };
          this.updatedData.emit(this.subData);
        }
        if (res.notification) {
          const modalRef = this.modalService.open(AlconRedirectPopupComponent, {
            ...MODAL_POPUP_CONFIG,
            windowClass: 'patientOrderList_alertPopup',
          });

          modalRef.componentInstance.alertRedirectConfig = {
            title: this.popupDesc,
            desc: '',
            redirectBtnText: this.popupBtntext,
          };
        }
      });
  }

  /**
   * Based on the toggle status append respective tool tip text
   * @param toggleStatus  active/inactive
   */
  appendToolTipText(toggleStatus): void {
    this.cd.detectChanges();
    this.tooltipText = toggleStatus ? this.activeToInactiveText : this.inactiveToActiveText;
  }

  ngOnDestroy(): void {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
}
