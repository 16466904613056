<div class="product-tile">
  <div
    class="d-flex w-100 product-tile__header-actions"
    [ngClass]="product.privateLabel ? 'justify-content-between' : 'justify-content-end'"
  >
    <span class="badge badge-private-label font-weight-normal" *ngIf="product.privateLabel">
      {{ 'productDetailsPage.privateLabel' | cxTranslate }}
    </span>
    <app-favorite-product
      [iconSize]="iconSize"
      [isFavourite]="product.favourite"
      [productCode]="product.code"
      [isWishList]="isWishList"
    >
    </app-favorite-product>
  </div>
  <div class="product-tile__image-holder">
    <!-- <cx-media [container]="isWishList ? product.images : product.images?.PRIMARY" alt="{{ product.name }}"></cx-media> -->
    <cx-media
      *ngIf="isWishList"
      [container]="product.images | imageFormat : 'product'"
      alt="{{ product.name }}"
    ></cx-media>
    <cx-media
      *ngIf="!isWishList"
      [container]="product.images?.PRIMARY | imageFormat : 'product'"
      alt="{{ product.name }}"
    ></cx-media>
  </div>
  <p class="product-tile__product-name">
    {{ product.name }}
  </p>
  <div class="product-tile__footer">
    <p class="product-tile__price">
      {{ product.price?.formattedValue }}
    </p>
    <div class="product-tile__actions">
      <button class="btn btn-primary product-tile__select-button" (click)="openProductModal(product.code)">
        {{ 'productListingPage.select' | cxTranslate | uppercase }}
      </button>
    </div>
    <button
      *ngIf="showPdpDetailsButton"
      class="product-tile__details-button p-0 border-0"
      [routerLink]="{ cxRoute: 'product', params: { productCode: product.code } } | cxUrl"
    >
      {{ 'productListingPage.details' | cxTranslate }}
    </button>
  </div>
</div>
<app-product-modal-popup
  *ngIf="openModal"
  (emitCloseModal)="emitCloseModal()"
  [productCode]="productCode"
  [clcDryeyeFlag]="product.isDryEyeSolution || product.isLensCareSolution"
></app-product-modal-popup>
<app-loader-icon *ngIf="showLoader"></app-loader-icon>
<app-modal
  *ngIf="productData"
  class="stock-order stock-order-tablet"
  [modalOptions]="modalOptions"
  (closeHandeller)="closeStockOrderModal()"
>
  <div class="close-popup">
    <cx-icon type="blueCloseIcon" (click)="closeStockOrderModal()"> </cx-icon>
  </div>
  <app-stockorder-configurator [product]="productData" (closePopup)="closeStockOrderModal()">
  </app-stockorder-configurator>
</app-modal>
