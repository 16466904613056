<div class="d-flex" [ngStyle]="packStyles">
  <div *ngFor="let packsize of packSize; index as i">
    <input
      [id]="'packSize' + idPrefix + i"
      type="radio"
      [value]="packsize.value"
      class="packsize__radio"
      [ngClass]="{ 'packsize__radio--solution': isSolutionProducts }"
      [(ngModel)]="selectedPackSize"
      [name]="selectedPackSize + idPrefix + i"
      (change)="changePackSize()"
      [disabled]="!packsize.isEnabled || disable"
    />
    <label [for]="'packSize' + idPrefix + i"
      >{{ packsize.display }} <span *ngIf="isSolutionProducts">{{ packsize.unit }}</span></label
    >
  </div>
  <div *ngIf="!trialOrMlProduct && !isValuePackType && !returnFormCreatePage">
    <p
      class="warning-message"
      style="font-size: 0.75rem; font-family: OpenSans-Regular; color: #ff6b13; width: max-content"
    >
      {{ 'pageMetaResolver.product.packSizeWarningMessage' | cxTranslate }}
    </p>
  </div>
</div>
