<div class="account-details" *ngIf="useraccountInfo$ | async as accountDetail">
  <div class="account-details__header d-flex justify-content-between">
    <h2>{{ accountDetail.title }} {{ accountDetail.firstName }} {{ accountDetail.lastName }}</h2>
    <div class="account-details__link" *ngIf="basesites$ | async as basesites">
      <ul class="ml-0 pl-0">
        <li *ngIf="basesites?.additionalAccountEnabledForSx && basesites?.isSxStorefront">
          <cx-icon type="Refresh" (click)="openRequestPopup()"
            ><span class="account-details__label-link">{{
              'multiAccountSelector.requestAdditionalAccount' | cxTranslate
            }}</span>
          </cx-icon>
        </li>
        <li>
          <cx-icon
            *ngIf="!isSXUser"
            type="Refresh"
            [routerLink]="{ cxRoute: 'contactUsPage' } | cxUrl"
            [queryParams]="{ category: 'Change of billing/shipping address' }"
            ><span class="account-details__label-link">{{
              'accountDetailsPage.requestChangeAddress' | cxTranslate
            }}</span>
          </cx-icon>
        </li>
      </ul>
    </div>
  </div>

  <div class="d-flex account-details--pt-28 account-details--pb-65">
    <div class="w-50">
      <div class="d-flex flex-row">
        <h5 class="account-details__header-label">
          {{ 'accountDetailsPage.alconAccount' | cxTranslate }}
        </h5>
        <div>
          <button appAccountSwitch [isSoldto]="true" class="account-details__type--icon account-details__button-icon">
            <cx-icon type="Refresh" alt="switchaccount">
              <span class="account-details__iconEditStyles account-details__spacing">{{
                'accountDetailsPage.switchText' | cxTranslate
              }}</span>
            </cx-icon>
          </button>
        </div>
      </div>
      <div class="account-details__sold-to-line-1">{{ accountDetail?.billToAddress?.name }}</div>
      <div class="account-details__sold-to-line-2">{{ accountDetail?.billToAddress?.sapCustomerId }}</div>

      <app-address-block textSize="md" itemGap="" [address]="accountDetail?.billToAddress"></app-address-block>
    </div>
    <div class="w-50">
      <div class="d-flex flex-row">
        <h5 class="account-details__header-label">
          {{ 'accountDetailsPage.shippingAddress' | cxTranslate }}
        </h5>
        <div>
          <button appAccountSwitch [isSoldto]="false" class="account-details__type--icon account-details__button-icon">
            <cx-icon type="Refresh" alt="switchaccount">
              <span class="account-details__iconEditStyles account-details__spacing">{{
                'accountDetailsPage.switchText' | cxTranslate
              }}</span>
            </cx-icon>
          </button>
        </div>
      </div>
      <div class="account-details__sold-to-line-1">{{ accountDetail?.shipToAddress?.name }}</div>
      <div class="account-details__sold-to-line-2">{{ accountDetail?.shipToAddress?.sapCustomerId }}</div>

      <app-address-block textSize="md" itemGap="" [address]="accountDetail?.shipToAddress"></app-address-block>
    </div>
  </div>
  <div *ngIf="accountDetail?.consolidateOrder" class="account-details__accountconsolidation">
    <app-account-details-consolidation></app-account-details-consolidation>
  </div>
  <cx-page-slot position="AccountDetailsShoppingCartPosition"></cx-page-slot>
</div>
