import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { PatientService } from 'src/app/services/patient/patient.service';
import { addressType } from 'src/app/shared/constants/constants';
import { ModalOptions } from 'src/app/shared/model/common.mode';
import { AddressInfo } from 'src/app/shared/model/patient.model';
import { getDTPDefaultAddress, getPatientIDForEntry } from 'src/app/store/Selectors/cartItem.selector';

@Component({
  selector: 'app-cart-dtp-address',
  templateUrl: './cart-dtp-address.component.html',
  styleUrls: ['./cart-dtp-address.component.scss'],
})
export class CartDtpAddressComponent {
  expandAccordion = false;
  @Input() userAddress: any;
  @Input() entryNumber: number;
  @Output() updateAddress: EventEmitter<boolean> = new EventEmitter();
  patientInfo$;
  modalOptions = {
    open: false,
    header: false,
    modalTitle: '',
    borderRadius: '20px',
    width: '1080px',
  } as ModalOptions;
  @HostBinding('class') class = 'dtp-title';
  loading = false;
  @HostListener('document:click', ['$event'])
  hideDropdown(event): void {
    if (!event.target) {
      return;
    }
    if (
      !(
        event?.target?.parentElement?.className?.includes('dtp-title') ||
        event?.target?.parentElement?.className?.includes('address')
      )
    ) {
      this.expandAccordion = false;
    }
  }

  constructor(private store: Store, private patientService: PatientService) {}

  toggleAccordion(): void {
    this.expandAccordion = !this.expandAccordion;
  }

  changeAddress(): void {
    this.loading = true;
    this.patientInfo$ = combineLatest([
      this.patientService.getPatientStoreData(),
      this.store.pipe(select(getDTPDefaultAddress(this.entryNumber))),
      this.store.pipe(select(getPatientIDForEntry(this.entryNumber))),
    ]).pipe(
      map(([patientsData, defaultAddress, patientID]) => {
        this.loading = false;
        this.modalOptions.open = true;
        return {
          ...patientsData.filter((patient) => patient.patientId === patientID)[0],
          addressType: defaultAddress ? addressType.MAIN_ADDRESS : addressType.ADDITIONAL_ADDRESS,
        };
      })
    );
  }

  closeModal(): void {
    this.modalOptions.open = false;
    document.body.classList.remove('modal-open');
  }

  saveAddressInfo(address: AddressInfo): void {
    this.modalOptions.open = false;
    this.updateAddress.emit(address.defaultAddress);
  }
}
