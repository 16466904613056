import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { EditScheduledOrderService } from '../services/order-history/editScheduled/edit-scheduled-order.service';

@Injectable({
  providedIn: 'root',
})
export class EditScheduledOrdersResolver implements Resolve<Observable<any>> {
  constructor(private editScheduledOrderService: EditScheduledOrderService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.editScheduledOrderService.getEditScheduledOrderData(route.params.code);
  }
}
