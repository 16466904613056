<ng-container *ngIf="shipTOAddresses; else loadShipToAddress">
  <div class="address-container">
    <div class="header-container" *ngIf="shipTOAddresses as shiptoAccountDetails">
      <label class="header-container__labelheading">
        {{ 'multiAccountSelector.shipToSelector.title' | cxTranslate }}</label
      >
      <div class="header-container__address_name">{{ shiptoAccountDetails.accountName }}</div>
      <label>{{ shiptoAccountDetails.accountId }}</label>
      <span *ngIf="isMultipleSoldTo" class="header-account-selector__iconEditStyles" (click)="navigateToSoldTo()">
        <cx-icon type="Refresh" alt="switchaccount">
          <span class="header-account-selector__spacing"> {{ 'accountDetailsPage.switchText' | cxTranslate }}</span>
        </cx-icon>
      </span>
    </div>
    <div class="text-center search-label">
      {{ 'multiAccountSelector.shipToSelector.searchboxHeading' | cxTranslate }}
    </div>
    <div class="searchbox">
      <input
        class="searchbox__inputbox"
        type="text"
        placeholder="{{ 'multiAccountSelector.shipToSelector.searchPlaceholderTxt' | cxTranslate }}"
        [disabled]="disableSearch"
        [(ngModel)]="searchAddress"
        (keyup)="filterAddress(searchAddress)"
      />

      <button class="searchbox__button-icon" *ngIf="!searchAddress">
        <cx-icon class="searchbox__searchicon" type="SearchIcon"></cx-icon>
      </button>

      <button class="searchbox__button-icon" *ngIf="searchAddress" (click)="resetSearch()">
        <cx-icon type="Close" class="closebtn"> </cx-icon>
      </button>
    </div>
    <section class="address-container__addresses d-flex flex-wrap" *ngIf="shipToDetails.length > 0; else noShipto">
      <app-account-details
        *ngFor="let shiptoAccount of shipToDetails"
        [address]="shiptoAccount"
        (setUnitData)="setUnitData(shiptoAccount.sapCustomerId)"
        [ifShipToSelector]="ifShipToSelector"
      >
      </app-account-details>
    </section>
  </div>
</ng-container>

<ng-template #noShipto>
  <div class="address-container__norecordMSG">
    <p>{{ 'multiAccountSelector.shipToSelector.noResultFound' | cxTranslate }}</p>
  </div>
</ng-template>

<ng-template #loadShipToAddress>
  <div class="d-flex mt-5 justify-content-center">
    <strong>{{ 'multiAccountSelector.loaderMessage' | cxTranslate }}</strong>
    <div class="spinner-border ml-5" role="status">
      <p class="sr-only">{{ 'multiAccountSelector.loading' | cxTranslate }}</p>
    </div>
  </div>
</ng-template>
