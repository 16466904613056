import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductSearchService, TranslationService } from '@spartacus/core';
import { BehaviorSubject, Observable, Subject, forkJoin } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { OrderHistoryService } from 'src/app/services/order-history/order-history.service';
import { ValuePackService } from 'src/app/services/value-pack/valuePack.service';
import { Category, VALUE_PACK_TYPES } from 'src/app/shared/constants/constants';
import { DropdownConfig, DropdownOptions } from 'src/app/shared/model/common.mode';
import {
  BasicProductSelectedCheckboxStatus,
  BasicProductSelectorPanelConfig,
  QuantityErrorCodes,
} from 'src/app/shared/model/product.model';
import {
  ContactLensSolutionType,
  ScheduledOrderData,
  ValuePackAttributes,
  VisionCareAddtocartProductRequest,
  VisionCareAddtocartProductRequestInfo,
} from 'src/app/store/States/cartItem.state';
import { PatientOrderData, ProductDetails } from 'src/app/store/States/orderHistory';
import { SolutionProductsComponent } from '../../solution-products/solution-products.component';

declare module 'src/app/shared/model/common.mode' {
  interface DropdownOptions {
    valuePackNodeId?: number;
    isDTPEnabled?: boolean;
  }
}

@Component({
  selector: 'app-neworder-value-pack',
  templateUrl: './neworder-value-pack.component.html',
  styleUrls: ['./neworder-value-pack.component.scss'],
})
export class NeworderValuePackComponent implements OnInit, OnDestroy, AfterViewInit {
  patientID;
  selectedValuePack: string;
  productsList: DropdownOptions[];
  clcList: DropdownOptions[] = [];
  dryeyeList: DropdownOptions[] = [];
  valuePackInfo;
  valuePackLensGroupOneProductsList: DropdownOptions[] = [];
  valuePackLensGroupTwoProductsList: DropdownOptions[] = [];

  lensesLabel: DropdownConfig = {
    defaultSelectText: 'Select',
  };
  resetDropDown: boolean;
  private destroySubscriptions$ = new Subject<void>();
  firstProduct: DropdownOptions;
  secondProduct: DropdownOptions;
  selectedProduct: DropdownOptions;
  selectedProductValue: Array<VisionCareAddtocartProductRequest> = [];
  selectedSolutionsValue: Array<Array<VisionCareAddtocartProductRequest>> = [[]];
  selectedContactLensValue: Array<Array<VisionCareAddtocartProductRequest>> = [[]];
  checkBoxStatus: BasicProductSelectedCheckboxStatus;
  isValuePack: boolean;
  packSizeList: Observable<{ factor: string; value: string; selected?: boolean }[]>;
  valuePackSolutionsList = [];
  firstValuePackProduct: DropdownOptions;
  valuePackVariantTree: any;
  valuePackGroupOneChildProducts: any;
  selectedSolution: DropdownOptions;
  selectedValuePackSolution: DropdownOptions;
  disableAddLens = true;
  addLens = false;
  @Input() isDTP = false;
  private panelErrors: Array<{ baseProductCode: string; isDTPError: boolean; isMaxQuantityError: boolean }> = [];
  panelConfiguration: BasicProductSelectorPanelConfig = {
    checkboxStatus: {
      leftEye: true,
      rightEye: true,
      bothEye: false,
    } as BasicProductSelectedCheckboxStatus,
    solutions: {
      multiLine: false,
    },
  };

  disableCheckbox = false;

  private SolutionsPanel: Array<SolutionProductsComponent> = [];
  private displaySolutionPanel: BehaviorSubject<boolean> = new BehaviorSubject(false);

  valuePackpPanelConfiguration: BasicProductSelectorPanelConfig;
  searchResultCategories = [Category.CONTACT_LENSES, Category.CONTACT_LENS_SOLUTION, Category.DRY_EYE_PRODUCTS];

  @ViewChild('solutiontemplate', { read: ViewContainerRef, static: false }) solutiontemplate: ViewContainerRef;

  editSolutions = [];
  editlenses = [];
  replenishmentOrderCode: string;
  scheduledOrderList = [];

  scheduledOrderData = {} as PatientOrderData;
  selectedOrderType: string;
  selectedScheduledOrderData: ScheduledOrderData;
  solutionsData = {} as { key: DropdownOptions[] };
  selectedDeliveryType: string;
  packValue;
  get isDTPError(): boolean {
    let isError = !!this.panelErrors.find((errorPanel) => errorPanel.isDTPError);
    if (this.isValuePack) {
      isError =
        isError || (this.isDTP && this.selectedValuePackSolution && !this.selectedValuePackSolution?.isDTPEnabled);
    }
    return isError;
  }
  get isMaxQuantityError(): boolean {
    const isError = !!this.panelErrors.find((errorPanel) => errorPanel.isMaxQuantityError);
    return isError;
  }

  @Output() selectedProductLensValues: EventEmitter<any> = new EventEmitter();
  @Output() isDTPErrorFlag: EventEmitter<boolean> = new EventEmitter();

  firstProductConfiguration: BasicProductSelectorPanelConfig = {
    ...this.panelConfiguration,
  } as BasicProductSelectorPanelConfig;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private activatedRoute: ActivatedRoute,
    protected productSearchService: ProductSearchService,
    private cd: ChangeDetectorRef,
    private translation: TranslationService,
    private orderService: OrderHistoryService,
    private valuePackService: ValuePackService
  ) {}

  ngOnInit(): void {
    this.patientID = this.activatedRoute.snapshot.data.newOrderPatientInfo.patientId;
    const editPaneldata = this.activatedRoute.snapshot.data.editScheduledOrdersInfo;
    if (editPaneldata) {
      this.packValue = editPaneldata?.entries[0]?.valuePackAttributes?.valuePackDuration;
      this.scheduledOrderList = this.orderService.createComponentData(
        [{ ...editPaneldata }],
        this.scheduledOrderData,
        this.scheduledOrderList,
        this.patientID
      );
      this.isValuePack = this.scheduledOrderList[0].packType === 'Value Pack';
      this.scheduledOrderList[0].productDetails.forEach((val) => {
        if (val.isDryEye || val.isClc) {
          this.editSolutions.push(val);
        } else {
          this.editlenses.push(val);
        }
      });
      this.editlenses.sort((a, b) => {
        return a.entryNumber - b.entryNumber;
      });
    }

    this.packSizeList = forkJoin([
      this.translation.translate('selectProductPage.valuePackAttributes.threeMonthValuePack').pipe(take(1)),
      this.translation.translate('selectProductPage.valuePackAttributes.sixMonthValuePack').pipe(take(1)),
    ]).pipe(
      take(1),
      map(([threeMonthValuePack, sixMonthValuePack]) => {
        this.selectedValuePack = this.packValue === '6' ? VALUE_PACK_TYPES.SIX_MONTH : VALUE_PACK_TYPES.THREE_MONTH;
        return [
          {
            factor: '1',
            value: threeMonthValuePack,
            selected: this.selectedValuePack === threeMonthValuePack,
          },
          {
            factor: '1',
            value: sixMonthValuePack,
            selected: this.selectedValuePack === sixMonthValuePack,
          },
        ];
      })
    );
    this.selectedContactLensValue[0] = [];
    this.productSearchResult(this.searchResultCategories[0]);
  }

  ngAfterViewInit(): void {
    this.displaySolutionPanel.pipe(takeUntil(this.destroySubscriptions$)).subscribe((displayValue) => {
      if (displayValue) {
        this.editSolutions.length > 0 ? this.addSolutionPanel() : this.createPanel();
        this.cd.detectChanges();
      }
    });
  }

  productSearchResult(val?): void {
    const query = ':relevance:allCategories:' + val;
    this.productSearchService.search(query, { pageSize: 10000 });
  }

  private getDropDown(productsdata: any): DropdownOptions {
    return {
      text: productsdata.displayName,
      value: productsdata.nodeValue,
      selected: false,
      valuePackNodeId: productsdata.valuePackId,
      isDTPEnabled: productsdata.dtpInfo,
    } as DropdownOptions;
  }

  valuePackGroupOneProductSelected(product: DropdownOptions, reset: boolean = true): void {
    this.valuePackService.changeInValuePackPrice('0');
    this.disableAddLens = true;
    this.disableCheckbox = false;
    if (reset) {
      this.editlenses = [];
    }
    this.removePanelErrors(this.firstProduct?.value);
    this.firstProduct = product;
    this.cd.detectChanges();
    this.secondProduct = null;
    this.selectedValuePackSolution = undefined;
    this.valuePackSolutionsList = [];
    this.addLens = false;
    this.panelConfiguration = {
      checkboxStatus: {
        leftEye: false,
        rightEye: false,
        bothEye: true,
      } as BasicProductSelectedCheckboxStatus,
      solutions: {
        multiLine: false,
      },
    };
    this.valuePackGroupOneChildProducts = this.valuePackVariantTree
      ?.slice()
      .filter((valuePackVariant) => valuePackVariant.nodeValue === this.firstProduct.value);
    this.valuePackLensGroupTwoProductsList = this.valuePackGroupOneChildProducts[0].childNodes
      .filter((productsdata) => productsdata.nodeValue !== this.firstProduct.value)
      .map((productsdata) => {
        const secproduct = this.getDropDown(productsdata);
        if (this.scheduledOrderList.length > 0) {
          secproduct.selected = this.editlenses && secproduct.value === this.editlenses[1]?.productCode;
        }
        return secproduct as DropdownOptions;
      });
    if (this.editlenses.length > 1) {
      this.addLens = this.editlenses[0].productCode !== this.editlenses[1].productCode ? true : false;
      this.valuePackGroupTwoProductSelected(
        this.valuePackLensGroupTwoProductsList.find((prd) => prd.selected),
        false
      );
    } else if (this.scheduledOrderList.length > 0 && this.editlenses.length > 0) {
      this.addLens =
        this.editlenses.length > 1 && this.editlenses[0].productCode !== this.editlenses[1].productCode ? true : false;
      this.getValuePackInfo(this.valuePackLensGroupOneProductsList.find((prd) => prd.selected));
      this.setSelectedProductLensValue(this.editlenses[0], 0);
    }
    this.checkboxStatusChange(this.firstProductConfiguration.checkboxStatus);
    if (reset) {
      this.selectedContactLensValue[0] = [];
      this.updateSelectedProductValue();
    }
  }

  valuePackGroupTwoProductSelected(product: DropdownOptions, reset: boolean = true): void {
    this.valuePackService.changeInValuePackPrice('0');
    this.removePanelErrors(this.secondProduct?.value);
    this.secondProduct = product;
    this.valuePackSolutionsList = [];
    this.selectedValuePackSolution = undefined;
    this.getValuePackInfo(product);
    if (reset) {
      this.selectedContactLensValue[1] = [];
      this.updateSelectedProductValue();
    }
  }
  getValuePackInfo(product): void {
    const filteredProducts = this.valuePackGroupOneChildProducts[0].childNodes.filter(
      (groupTwoProducts) => groupTwoProducts.nodeValue === product?.value
    );
    filteredProducts?.forEach((filteredProduct) =>
      this.valuePackSolutionsList.push(
        ...filteredProduct.childNodes.map((groupTwoSolution) => this.getDropDown(groupTwoSolution))
      )
    );
    this.getValuepackSolutions(product);
  }
  private getValuepackSolutions(selectedSecondProduct: DropdownOptions): void {
    const { childNodes: firstProductNode = [] } = this.valuePackGroupOneChildProducts[0];
    const secondPrd = firstProductNode.find(
      (groupTwoProducts) => groupTwoProducts.nodeValue === selectedSecondProduct?.value
    );
    // Adding if block to retain valuePackSolutionsList to be an empty array instead of undefined
    if (secondPrd) {
      this.valuePackSolutionsList = secondPrd.childNodes.map((groupTwoSolution) => {
        const solution = this.getDropDown(groupTwoSolution);
        if (this.scheduledOrderList.length > 0 && this.editlenses.length > 0) {
          solution.selected = this.editSolutions && solution.value === this.editSolutions[0].code;
          if (solution.selected) {
            this.valuePackSolutionSelected(solution);
          }
        }
        return solution;
      });
    }

    if (this.valuePackSolutionsList?.length === 1 && this.editlenses.length === 0) {
      this.valuePackSolutionSelected(this.valuePackSolutionsList[0]);
    }
  }
  selectedProductLensValue(val, index): void {
    this.selectedContactLensValue[index] = val;
    this.updateSelectedProductValue();
  }
  private setSelectedProductLensValue(val, index): void {
    if (val.length > 1) {
      this.selectedContactLensValue[index] = val.map((prd) => this.createProductRequest(prd));
    } else {
      this.selectedContactLensValue[index] = [this.createProductRequest(val)];
    }
  }
  private createProductRequest(prd: ProductDetails): VisionCareAddtocartProductRequest {
    return {
      product: { code: prd.code } as VisionCareAddtocartProductRequestInfo,
      quantity: prd.quantity.toString(),
      orderEntryFlowType: prd.orderEntryFlowType,
      eyeSight: prd.eyeSight,
    } as VisionCareAddtocartProductRequest;
  }

  checkboxStatusChange(val: BasicProductSelectedCheckboxStatus): void {
    this.checkBoxStatus = val;
    this.disableAddLens =
      !((val.leftEye && !val.rightEye) || (val.rightEye && !val.leftEye)) ||
      this.valuePackLensGroupTwoProductsList.length < 1;

    this.handleValuePackStatusChange();
  }

  private handleValuePackStatusChange(): void {
    if (this.editlenses.length === 0) {
      this.valuePackSolutionsList = [];
      this.selectedValuePackSolution = undefined;
      this.isValuePack = true;
      if (this.checkBoxStatus.leftEye && this.checkBoxStatus.rightEye && this.isValuePack) {
        this.getValuepackSolutions({ ...this.firstProduct } as DropdownOptions);
      }
    }
  }

  updateSelectedProductValue(): void {
    const selectedValues = this.selectedContactLensValue.reduce((a, b) => [...a, ...b], []);
    if (selectedValues.length < this.selectedContactLensValue.length) {
      this.selectedProductValue = [];
      this.selectedProductLensValues.emit(this.selectedProductValue);
      return;
    }
    const solutionValues = this.selectedSolutionsValue.reduce((a, b) => [...a, ...b], []);
    if (!this.isDTPError && !this.isMaxQuantityError) {
      this.selectedProductValue = [...selectedValues, ...solutionValues];
    } else {
      this.selectedProductValue = [];
      this.selectedProductLensValues.emit(this.selectedProductValue);
      return;
    }

    if (this.isValuePack) {
      if (selectedValues.length > 1 && !this.selectedValuePackSolution) {
        this.getValuepackSolutions({ ...this.secondProduct } as DropdownOptions);
      }

      if (!this.selectedValuePackSolution) {
        this.selectedProductValue = [];
        this.selectedProductLensValues.emit(this.selectedProductValue);
        return;
      }
      const packid = this.selectedValuePackSolution.valuePackNodeId;
      this.selectedProductValue.forEach((varient) => {
        const valuePackAttributes = {
          valuePackId: packid,
          valuePackDuration: this.selectedValuePack === VALUE_PACK_TYPES.THREE_MONTH ? 3 : 6,
          valuePackType: true,
        } as ValuePackAttributes;
        varient.valuePackAttributes = valuePackAttributes;
      });
      const solution = {
        product: { code: this.selectedValuePackSolution?.value } as VisionCareAddtocartProductRequestInfo,
        quantity: '1',
        valuePackAttributes: {
          valuePackId: packid,
          valuePackDuration: this.selectedValuePack === VALUE_PACK_TYPES.THREE_MONTH ? 3 : 6,
          valuePackType: true,
        } as ValuePackAttributes,
      };
      if (this.selectedProductValue.length === 2) {
        this.selectedProductValue.push(solution);
      } else if (this.selectedProductValue.length > 2) {
        this.selectedProductValue[2] = solution;
      }
    }
    this.selectedProductValue = [...this.selectedProductValue];
    this.selectedProductLensValues.emit(this.selectedProductValue);
  }

  addotherLens(): void {
    this.selectedContactLensValue[1] = [];
    this.addLens = true;
    this.panelConfiguration.checkboxStatus.bothEye = this.checkBoxStatus.bothEye;
    this.panelConfiguration.checkboxStatus.leftEye = !this.checkBoxStatus.leftEye;
    this.panelConfiguration.checkboxStatus.rightEye = !this.checkBoxStatus.rightEye;
    this.disableCheckbox = true;
    if (this.valuePackLensGroupTwoProductsList.length === 1) {
      this.valuePackGroupTwoProductSelected(this.valuePackLensGroupTwoProductsList[0]);
    }
    this.updateSelectedProductValue();
  }

  deleteValuePackAddedItem(): void {
    this.selectedValuePackSolution = undefined;
    this.valuePackSolutionsList = [];
    this.addLens = false;
    this.removePanelErrors(this.secondProduct?.value);
    this.secondProduct = null;
    this.disableCheckbox = false;
    this.updateSelectedProductValue();
  }

  ngOnDestroy(): void {
    this.destroySubscriptions$.next();
    this.destroySubscriptions$.complete();
  }

  setToDefaults(): void {
    this.resetDropDown = true;
    this.valuePackLensGroupOneProductsList = [];
    let selectedProduct = '';
    if (this.scheduledOrderList.length > 0) {
      selectedProduct = this.editlenses[0].productCode;
    }
    this.valuePackLensGroupOneProductsList = this.valuePackVariantTree.slice().map((productsdata: any) => {
      const product = this.getDropDown(productsdata);
      product.selected = false;
      return product as DropdownOptions;
    });
    this.addLens = false;
    this.isValuePack = true;
    this.valuePackSolutionsList = [];
    this.resetAdditionalControls();
  }

  private resetAdditionalControls(): void {
    this.selectedProductValue = [];
    this.cd.detectChanges();
  }

  packTypeChanged(value): void {
    this.isValuePack = !value;
    this.resetAdditionalControls();
  }

  valuePackSolutionSelected(product: DropdownOptions): void {
    this.valuePackService.getPriceForValuePack(product.valuePackNodeId);
    this.removePanelErrors(this.selectedValuePackSolution?.value);
    this.selectedValuePackSolution = product;
    this.updateSelectedProductValue();
    this.cd.detectChanges();
  }

  changePackSize(value): void {
    this.valuePackService.changeInValuePackPrice('0');
    const pack = value === VALUE_PACK_TYPES.THREE_MONTH ? '3' : '6';
    this.selectedValuePack = value;
    this.valuePackService
      .getValuePackSelectedVarient(pack)
      .pipe(takeUntil(this.destroySubscriptions$))
      .subscribe((res) => {
        this.valuePackVariantTree = [...res.childNodes];
        this.firstProduct = null;
        this.secondProduct = null;
        this.addLens = false;
        this.selectedValuePackSolution = null;
        this.valuePackSolutionsList = [];
        this.valuePackLensGroupOneProductsList = [];
        if (this.scheduledOrderList.length > 0) {
          if (
            this.editlenses[0].discontinued &&
            this.editlenses[1].discontinued &&
            this.editSolutions[0].discontinued
          ) {
            this.scheduledOrderList = [];
            this.editSolutions = [];
            this.editlenses = [];
          }
        }
        this.setValuePackDropDown();
      });
  }

  setValuePackDropDown(): void {
    let selectedProduct = '';
    if (this.scheduledOrderList.length > 0) {
      selectedProduct = this.editlenses[0].productCode;
    }
    this.valuePackLensGroupOneProductsList = this.valuePackVariantTree.slice().map((productsdata: any) => {
      const product = this.getDropDown(productsdata);
      product.selected = product.value === selectedProduct;
      return product as DropdownOptions;
    });

    if (this.valuePackLensGroupOneProductsList.length === 1 && this.scheduledOrderList.length == 0) {
      this.valuePackGroupOneProductSelected(this.valuePackLensGroupOneProductsList[0]);
    }

    if (this.scheduledOrderList.length > 0) {
      if (this.editlenses.length > 1 && this.editlenses[0].productCode === this.editlenses[1].productCode) {
        this.editlenses[0] = [this.editlenses[0], this.editlenses[1]];
        this.editlenses.pop();
      }

      this.valuePackGroupOneProductSelected(
        this.valuePackLensGroupOneProductsList.find((prd) => prd.selected),
        false
      );
    }
  }

  addSolutionPanel(): void {
    this.editSolutions.forEach((solutions, index) => {
      this.createPanel();
      this.SolutionsPanel[index].clcList = [...this.clcList].map(
        (prd) =>
          ({
            ...prd,
            selected: prd.value === solutions.productCode,
          } as DropdownOptions)
      );
      this.SolutionsPanel[index].dryeyeList = [...this.dryeyeList].map(
        (prd) =>
          ({
            ...prd,
            selected: prd.value === solutions.productCode,
          } as DropdownOptions)
      );
      this.SolutionsPanel[index].editSolutionData = solutions;
      this.SolutionsPanel[index].radioAdditionalProducts = solutions.isClc
        ? ContactLensSolutionType.ContactLensSolution
        : ContactLensSolutionType.DryEyeProducts;
      this.SolutionsPanel[index].solProducts = {
        selected: true,
        value: solutions.productCode,
        text: solutions.productCode,
      } as DropdownOptions;
      this.selectedSolutionsValue[index] = [this.createProductRequest(solutions)];
    });
  }

  private createPanel(): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SolutionProductsComponent);
    const componentRef = this.solutiontemplate.createComponent<SolutionProductsComponent>(componentFactory);
    componentRef.instance.clcList = [...this.clcList];
    componentRef.instance.dryeyeList = [...this.dryeyeList];
    componentRef.instance.index = this.SolutionsPanel.length;
    componentRef.instance.isDTP = this.isDTP;
    componentRef.instance.selectedProductCode
      .pipe(takeUntil(this.destroySubscriptions$))
      .subscribe(({ value, index }) => {
        this.selectedSolutionLensValue(value, index);
      });
    componentRef.instance.selectedSolutionErrorStatus
      .pipe(takeUntil(this.destroySubscriptions$))
      .subscribe(([value, productCode]) => {
        this.setPanelErrors(value, productCode);
      });
    componentRef.instance.productChanged.pipe(takeUntil(this.destroySubscriptions$)).subscribe((productcode) => {
      this.removePanelErrors(productcode);
    });
    componentRef.instance.deleteSolutions.pipe(takeUntil(this.destroySubscriptions$)).subscribe((index) => {
      const selectedSols = [...this.selectedSolutionsValue];
      selectedSols.splice(index, 1);
      if (selectedSols.length === 0) {
        selectedSols.push([]);
      }
      this.selectedSolutionsValue = [...selectedSols];
      this.updateSelectedProductValue();
      this.solutiontemplate.remove(index);
      this.SolutionsPanel.splice(index, 1);
      this.SolutionsPanel.forEach((component, i) => {
        component.index = i;
      });
      if (this.SolutionsPanel.length === 0) {
        this.createPanel();
      }
      this.panelSelections();
    });
    this.SolutionsPanel.push(componentRef.instance);
    this.panelSelections();
  }

  panelSelections(): void {
    let productSel = '';
    this.SolutionsPanel.forEach((panel, i) => {
      panel.disableRadio = false;
      if (this.SolutionsPanel.length > 1) {
        if (i === 0) {
          productSel = panel.radioAdditionalProducts;
        } else {
          panel.radioAdditionalProducts =
            productSel === ContactLensSolutionType.ContactLensSolution
              ? ContactLensSolutionType.DryEyeProducts
              : ContactLensSolutionType.ContactLensSolution;
        }
        panel.disableRadio = true;
      }
    });
  }

  selectedSolutionLensValue(val, index): void {
    this.selectedSolutionsValue[index] = val;
    this.updateSelectedProductValue();
  }

  isEditRightPanel(): void {
    this.updateSelectedProductValue();
  }

  setPanelErrors(value: boolean | QuantityErrorCodes, productCode: string): void {
    this.pushPanelErrors(
      productCode,
      value === QuantityErrorCodes.DTP_MAX_QUANTITY,
      value === QuantityErrorCodes.MAX_QUANTITY
    );
  }

  pushPanelErrors(baseProductCode: string, isDTPError: boolean, isMaxQuantityError: boolean): void {
    const panel = this.panelErrors.find((panelError) => panelError.baseProductCode === baseProductCode);
    if (!panel) {
      this.panelErrors.push({
        baseProductCode,
        isDTPError,
        isMaxQuantityError,
      });
    } else {
      panel.isDTPError = isDTPError;
      panel.isMaxQuantityError = isMaxQuantityError;
    }
    this.isDTPErrorFlag.emit(this.isDTPError);
  }
  removePanelErrors(baseProductCode: string): void {
    this.panelErrors.splice(
      this.panelErrors.findIndex((panel) => panel.baseProductCode === baseProductCode),
      1
    );
  }
}
