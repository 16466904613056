import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { PageMeta, PageMetaService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { LoadItems } from 'src/app/store/Actions/cartItem.action';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit {
  title$: Observable<string>;
  constructor(private pageMetaService: PageMetaService, private store: Store) {}

  ngOnInit(): void {
    this.title$ = this.pageMetaService.getMeta().pipe(
      filter(Boolean),
      map((meta: PageMeta) => meta.heading || meta.title)
    );
  }
}
