import { Action, createReducer, on } from '@ngrx/store';
import {
  AddToCart,
  ClearStore,
  ErrorToDoAction,
  FinalPriceSimulated,
  RemoveFromCart,
  RetrievCartList,
  UpdateCalculatedFlag,
  UpdatedCart,
} from '../Actions/cartItem.action';
import { DeliveryAddressInfo, VisionCareCartItems } from '../States/cartItem.state';

export const initialState: VisionCareCartItems = {
  code: 'current',
  rootGroups: [],
  deliveryAddress: {
    isPONumberRequired: false,
  } as DeliveryAddressInfo,
  calculated: false,
  totalUnitCount: 0,
} as VisionCareCartItems;

const cartReducer = createReducer(
  initialState,
  on(RetrievCartList, (state, { vCcartItems }) => {
    return { ...vCcartItems };
  }),
  on(RemoveFromCart, (state, { vCcartItems }) => {
    return { ...vCcartItems, LastmodifiedType: 'REMOVE' };
  }),
  on(AddToCart, (state, { vCcartItems }) => {
    return { ...vCcartItems };
  }),
  on(UpdatedCart, (state, { vCcartItems }) => {
    return { ...vCcartItems, LastmodifiedType: 'UPDATE' };
  }),
  on(ErrorToDoAction, (state, error: Error) => {
    return { ...state, cartError: error };
  }),
  on(FinalPriceSimulated, (state, { simulatedFinalPriceCartItems }) => {
    return { ...simulatedFinalPriceCartItems };
  }),
  on(UpdateCalculatedFlag, (state, { calculated }) => {
    return { ...state, calculated };
  }),
  on(ClearStore, (state, action) => {
    const storeobj = initialState;
    return { ...storeobj };
  })
);

export function cartItemsReducer(state = initialState, action: Action): VisionCareCartItems {
  return cartReducer(state, action);
}
