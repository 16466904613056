import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-alcon-list-page-filter',
  templateUrl: './alcon-list-page-filter.component.html',
  styleUrls: ['./alcon-list-page-filter.component.scss'],
})
export class AlconListPageFilterComponent implements OnInit {
  @Input() radioInputOptions;
  @Output() inputChanged = new EventEmitter<string>();
  radioSelected: string;
  showFilter = false;

  constructor() {}

  ngOnInit(): void {
    this.radioSelected = this.radioInputOptions[0].name;
  }

  change(): void {
    this.inputChanged.emit(this.radioSelected);
  }
}
