import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CHECKOUT } from 'src/app/shared/constants/constants';
import { OrdersInfo } from 'src/app/shared/model/orderHistory.model';

@Component({
  selector: 'app-order-payment-method',
  templateUrl: './order-payment-method.component.html',
  styleUrls: ['./order-payment-method.component.scss'],
})
export class OrderPaymentMethodComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}
  orderDetails: OrdersInfo;
  cardType: string;
  paymentTypeCard = CHECKOUT.PAYMENT_TYPE_CARD;
  ngOnInit(): void {
    this.orderDetails = this.route.snapshot.data.vcCart;
    this.cardType = this.orderDetails.paymentType.code;
  }
}
