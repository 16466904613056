import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Product } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { ModalOptions } from 'src/app/shared/model/common.mode';
@Component({
  selector: 'app-product-image',
  templateUrl: './product-image.component.html',
  styleUrls: ['./product-image.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [],
})
export class ProductImageComponent implements OnInit {
  zoomImage: any;
  privateLabel;
  public modalOptions = {
    open: false,
    modalTitle: '',
  } as ModalOptions;

  private mainMediaContainer = new BehaviorSubject(null);
  product$: Observable<Product> = this.currentProductService.getProduct().pipe(
    filter(Boolean),
    distinctUntilChanged(),
    tap((p: Product) => {
      this.privateLabel = p.privateLabel;
      !this.privateLabel
        ? this.mainMediaContainer.next(p.images?.PRIMARY ? p.images.PRIMARY : {})
        : this.mainMediaContainer.next(p.images?.GALLERY ? p.images.GALLERY[0] : {});
    })
  );
  thumbs$: Observable<any[]> = this.product$.pipe(map((p: Product) => this.createThumbs(p)));
  mainImage$ = combineLatest([this.product$, this.mainMediaContainer]).pipe(map(([, container]) => container));
  ngOnInit(): void {
    this.modalOptions.open = false;
  }
  constructor(private currentProductService: CurrentProductService) {}

  private createThumbs(product: Product): Observable<any>[] {
    if (!product.images || !product.images.GALLERY || product.images.GALLERY.length.toString() < '2') {
      return [];
    }
    return (product.images.GALLERY as any as []).map((c) => of({ container: c }));
  }

  openModal(data): void {
    this.zoomImage = data;
    this.modalOptions.open = true;
    document.body.classList.add('modal-open');
  }
  closeModal(): void {
    this.modalOptions.open = false;
  }
}
