import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { DropdownConfig, DropdownOptions } from 'src/app/shared/model/common.mode';

@Component({
  selector: 'app-return-form-product-selector',
  templateUrl: './return-form-product-selector.component.html',
  styleUrls: ['./return-form-product-selector.component.scss'],
})
export class ReturnFormProductSelectorComponent {
  @Input() products: Array<DropdownOptions> = [];
  @Output() productSelected = new EventEmitter();
  @HostBinding('class') class = 'returnFormProductSelector';
  dropdownConfig: DropdownConfig = {
    showOptionHeader: false,
    maxItemsShown: 5,
    typeAheadConfig: {
      typeAhead: true,
    },
  };
}
