<ng-container [ngSwitch]="step">
  <h1 *ngIf="step == 1" class="title d-lg-none d-block">
    {{ 'registerPage.termsAndConditionTitle' | cxTranslate }}
  </h1>
  <h1 *ngIf="step == 2" class="title d-lg-none d-block">
    <cx-page-slot position="LeftPane1A"></cx-page-slot>
  </h1>
  <div *ngIf="step == 1 || step == 2" class="subtitle d-lg-none d-block">
    {{ 'registerPage.registrationFormTitle' | cxTranslate }}
  </div>
  <div class="col-10 section-full">
    <app-register-form-step *ngSwitchCase="0" [formData]="datas" (submitForm)="submittedData($event, 0)">
    </app-register-form-step>
    <app-register-terms-step
      *ngSwitchCase="1"
      (termsAccepted)="submittedData($event, 1)"
      (goBack)="goToStep(0)"
      [disabledSubmit]="disabledSubmit"
    >
    </app-register-terms-step>
    <app-register-success-step *ngSwitchCase="2"> </app-register-success-step>
  </div>
</ng-container>
