import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { IconModule, MediaModule } from '@spartacus/storefront';
import { CalendarModule } from 'primeng/calendar';
import { AccountSwitchDirective } from 'src/app/directives/account-switch.directive';
import { AddToCartDirective } from 'src/app/directives/add-to-cart.directive';
import { OnlyNumericDirective } from 'src/app/directives/only-numeric.directive';
import { RemoveFromCartDirective } from 'src/app/directives/remove-from-cart.directive';
import { ImageFormatPipe } from 'src/app/pipes/image-format.pipe';
import { RemoveWhitespacePipe } from 'src/app/pipes/remove-whitespace.pipe';
import { SortPipe } from 'src/app/pipes/sort.pipe';
import { ReOrderValidationComponent } from 'src/app/shared-modules/order-history/components/re-order-validation/re-order-validation.component';
import { ReOrderComponent } from 'src/app/shared-modules/order-history/components/re-order/re-order.component';
import { CartItemSpecificationsComponent } from '../alcon-cart-v2/cart-item-specifications/cart-item-specifications.component';
import { CartProductInformationComponent } from '../alcon-cart-v2/cart-product-information/cart-product-information.component';
import { AlconShippingAddressComponent } from '../alcon-shipping-address/alcon-shipping-address.component';
import { BasicProductEditSelectorComponent } from '../basic-product-variant-selector/basic-product-edit-selector/basic-product-edit-selector.component';
import { BasicVariantSelectorComponent } from '../basic-product-variant-selector/basic-variant-selector/basic-variant-selector.component';
import { ChangeSubscriptionStatusComponent } from '../change-subscription-status/change-subscription-status.component';
import { AlconItemCounterComponent } from '../common/alcon-item-counter/alcon-item-counter.component';
import { FavoriteProductComponent } from '../common/favorite-product/favorite-product.component';
import { PatientAddressFormComponent } from '../new-order/patient-address-form/patient-address-form.component';
import { SelectDeliveryAddressComponent } from '../new-order/select-delivery-address/select-delivery-address.component';
import { LensPowerSelectorComponent } from '../product-details-components/lens-power-selector/lens-power-selector.component';
import { ProductPackSizeComponent } from '../product-details-components/product-details/product-pack-size/product-pack-size.component';
import { StockorderConfiguratorComponent } from '../product-details-components/stockorder-configurator/stockorder-configurator.component';
import { StockorderDetailsPanelComponent } from '../product-details-components/stockorder-details-panel/stockorder-details-panel.component';
import { StockorderToricComponent } from '../product-details-components/stockorder-toric/stockorder-toric.component';
import { StockorderVariantSelectorComponent } from '../product-details-components/stockorder-variant-selector/stockorder-variant-selector.component';
import { VariantSpecsSelectorComponent } from '../product-details-components/variant-specs-selector/variant-specs-selector.component';
import { AddressBlockComponent } from './address-block/address-block.component';
import { AddressComponent } from './address/address.component';
import { AlconStatementDownloadComponent } from './alcon-statement-download/alcon-statement-download.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CountryComponent } from './country/country.component';
import { DeliveryTypeComponent } from './delivery-type/delivery-type.component';
import { DropdownSelectorComponent } from './dropdown-selector/dropdown-selector.component';
import { LegalStatementComponent } from './legal-statement/legal-statement.component';
import { LoaderIconComponent } from './loader-icon/loader-icon.component';
import { ModalComponent } from './modal-popup/modal.component';
import { ProductDetailTileComponent } from './product-detail-tile/product-detail-tile.component';
import { SwitchToggleComponent } from './switch-toggle/switch-toggle.component';
import { TimepickerComponent } from './timepicker/timepicker.component';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';
import { DropdownSelectorV2Component } from './v2/dropdown-selector-v2/dropdown-selector-v2.component';
@NgModule({
  declarations: [
    AddToCartDirective,
    ModalComponent,
    CalendarComponent,
    LoaderIconComponent,
    SortPipe,
    RemoveWhitespacePipe,
    ImageFormatPipe,
    FavoriteProductComponent,
    AlconItemCounterComponent,
    DropdownSelectorComponent,
    DropdownSelectorV2Component,
    ProductPackSizeComponent,
    StockorderDetailsPanelComponent,
    StockorderConfiguratorComponent,
    StockorderVariantSelectorComponent,
    VariantSpecsSelectorComponent,
    LensPowerSelectorComponent,
    StockorderToricComponent,
    BasicVariantSelectorComponent,
    RemoveFromCartDirective,
    BasicProductEditSelectorComponent,
    SwitchToggleComponent,
    AlconShippingAddressComponent,
    AddressComponent,
    AlconStatementDownloadComponent,
    LegalStatementComponent,
    SelectDeliveryAddressComponent,
    PatientAddressFormComponent,
    DeliveryTypeComponent,
    ProductDetailTileComponent,
    AddressBlockComponent,
    ToggleButtonComponent,
    ReOrderValidationComponent,
    ReOrderComponent,
    CartItemSpecificationsComponent,
    CartProductInformationComponent,
    OnlyNumericDirective,
    CountryComponent,
    AccountSwitchDirective,
    ChangeSubscriptionStatusComponent,
    TimepickerComponent,
  ],
  exports: [
    AddToCartDirective,
    ModalComponent,
    CalendarComponent,
    LoaderIconComponent,
    SortPipe,
    RemoveWhitespacePipe,
    ImageFormatPipe,
    FavoriteProductComponent,
    AlconItemCounterComponent,
    DropdownSelectorComponent,
    DropdownSelectorV2Component,
    ProductPackSizeComponent,
    StockorderDetailsPanelComponent,
    StockorderConfiguratorComponent,
    StockorderVariantSelectorComponent,
    LensPowerSelectorComponent,
    VariantSpecsSelectorComponent,
    BasicVariantSelectorComponent,
    RemoveFromCartDirective,
    AccountSwitchDirective,
    BasicProductEditSelectorComponent,
    AlconShippingAddressComponent,
    SwitchToggleComponent,
    AddressComponent,
    AlconStatementDownloadComponent,
    StockorderToricComponent,
    LegalStatementComponent,
    SelectDeliveryAddressComponent,
    PatientAddressFormComponent,
    DeliveryTypeComponent,
    ProductDetailTileComponent,
    AddressBlockComponent,
    ToggleButtonComponent,
    ReOrderValidationComponent,
    ReOrderComponent,
    CartItemSpecificationsComponent,
    CartProductInformationComponent,
    OnlyNumericDirective,
    CountryComponent,
    ChangeSubscriptionStatusComponent,
    TimepickerComponent,
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    // BrowserAnimationsModule,
    CalendarModule,
    IconModule,
    ConfigModule,
    I18nModule,
    MediaModule,
    UrlModule,
    RouterModule,
    NgbPopoverModule,
  ],
  providers: [],
})
export class AlconCommonModule {}
