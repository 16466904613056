<p class="minicartDTP__patientName mb-0">
  {{ dtpEntryData.patientName }}
</p>
<div *ngIf="isDVP; else dtp">
  <app-mini-cart-value-pack-item-list [valuePackComponentData]="dtpEntryData.entries[0]">
  </app-mini-cart-value-pack-item-list>
</div>

<ng-template #dtp>
  <app-minicart-item-list *ngFor="let eachProductList of dtpEntryData.entries" [listData]="eachProductList">
  </app-minicart-item-list>
</ng-template>

<p *ngIf="dtpEntryCount > displayCount" class="minicartDTP__moreItem">
  ({{ dtpEntryCount - displayCount }}
  {{ (dtpEntryCount - displayCount > 1 ? 'miniCartPage.moreitems' : 'miniCartPage.moreitem') | cxTranslate }}
  )
</p>
