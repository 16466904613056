import { NgModule } from '@angular/core';
import { ProductAdapter, ProductSearchAdapter, provideConfig, SiteContextConfigInitializer } from '@spartacus/core';
import { layoutConfig } from '@spartacus/storefront';
import { environment } from 'src/environments/environment';
import { CustomSiteContextConfigInitializer } from '../services/site-context-config/custom-site-context-config-initializer.service';
import { VcProductAdapter } from '../shared/adapters/vcProductAdapter';
import { VcProductSearchAdapter } from '../shared/adapters/vcProductSearchAdapter';
import { alconTranslationChunksConfig } from '../shared/translations/alcon-translation-chunks-config';
import { alconTranslations } from '../shared/translations/alcon-translations';

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    {
      provide: SiteContextConfigInitializer,
      useClass: CustomSiteContextConfigInitializer,
    },
    provideConfig(layoutConfig),
    provideConfig({
      featureModules: {
        organizationOrderApproval: {
          module: () => import('@spartacus/organization/order-approval').then((m) => m.OrderApprovalModule),
        },
        organizationAdministration: {
          module: () => import('@spartacus/organization/administration').then((m) => m.AdministrationModule),
        },
      },
      backend: {
        occ: {
          baseUrl: environment.API_BASE_URL,
          prefix: environment.API_PREFIX,
          endpoints: {
            baseSites:
              'basesites?fields=baseSites(uid,defaultLanguage(isocode),urlEncodingAttributes,urlPatterns,stores(currencies(isocode),defaultCurrency(isocode),languages(isocode),defaultLanguage(isocode)),theme,defaultPreviewCatalogId,defaultPreviewCategoryCode,defaultPreviewProductCode,isSxStorefront,isVCStorefront,additionalAccountEnabledForVC,additionalAccountEnabledForSx,manualRegistration)',
            user: 'users/${userId}',
            product: {
              alconDetails:
                'users/${userId}/products/${productCode}?fields=code,name,summary,price(formattedValue,DEFAULT),images(galleryIndex,FULL),baseProduct,DEFAULT,averageRating,classifications,manufacturer,numberOfReviews,categories(FULL),baseOptions,variantOptions,variantType,stock(DEFAULT),description,availableForPickup,url,priceRange,multidimensional,tags',
              attributes: 'users/${userId}/products/${productCode}?fields=classifications',
              default:
                'users/${userId}/products/${productCode}?fields=DEFAULT,averageRating,images(FULL),classifications,manufacturer,numberOfReviews,categories(FULL),baseOptions,baseProduct,variantOptions,variantType',
              details:
                'users/${userId}/products/${productCode}?fields=averageRating,stock(DEFAULT),description,availableForPickup,code,url,price(DEFAULT),numberOfReviews,manufacturer,categories(FULL),priceRange,multidimensional,tags,images(FULL)',
              list:
                'users/${userId}/products/${productCode}?fields=code,name,summary,price(formattedValue),images(DEFAULT,galleryIndex),baseProduct',
            },
            productSearch:
              'users/${userId}/products/search?fields=products(code,name,summary,configurable,configuratorType,multidimensional,price(FULL),images(DEFAULT),stock(FULL),averageRating,variantOptions),facets,breadcrumbs,pagination(DEFAULT),sorts(DEFAULT),freeTextSearch,currentQuery',
          },
        },
      },
      i18n: {
        resources: alconTranslations,
        chunks: alconTranslationChunksConfig,
        fallbackLang: 'en_GB',
      },
      features: {
        level: '6.0',
      },
    }),
    {
      provide: ProductSearchAdapter,
      useClass: VcProductSearchAdapter,
    },
    {
      provide: ProductAdapter,
      useClass: VcProductAdapter,
    },
  ],
})
export class SpartacusConfigurationModule {}
