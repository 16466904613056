<div
  class="cart-dtp-address position-absolute"
  [ngClass]="{ 'cart-dtp-address--open d-flex flex-column': expandAccordion }"
>
  <h6 class="cart-dtp-address__title dtp-title" (click)="toggleAccordion()">
    <cx-icon class="dtp-title" role="button" type="DropdownExpand" *ngIf="!expandAccordion"></cx-icon>
    <cx-icon class="dtp-title" role="button" type="DropdownCollapse" *ngIf="expandAccordion"></cx-icon>
    <span class="ml-1">Address</span>
  </h6>
  <app-address
    class="dtp-title"
    #address
    *ngIf="expandAccordion"
    [userAddress]="userAddress"
    (changeAddress)="changeAddress()"
  >
  </app-address>
</div>
<app-modal [modalOptions]="modalOptions" (closeHandeller)="closeModal()">
  <div class="delivery-address-modal">
    <div class="delivery-address-modal__close">
      <cx-icon type="Close" class="close-icon cx-icon close" (click)="closeModal()"> </cx-icon>
    </div>
    <h3 class="delivery-address-modal__header">
      {{ 'selectProductPage.patientOrderPanel.selectdeliveryAddress' | cxTranslate }}
    </h3>
    <ng-container *ngIf="patientInfo$ | async as patientInfo">
      <app-select-delivery-address
        [patientInfo]="patientInfo"
        (saveAddressInfo)="saveAddressInfo($event)"
        (cancel)="closeModal()"
      ></app-select-delivery-address>
    </ng-container>
  </div>
</app-modal>
<app-loader-icon *ngIf="loading"></app-loader-icon>
