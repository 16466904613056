import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  ActivatedRoutesService,
  Category,
  Product,
  ProductSearchPage,
  ProductSearchService,
  RoutingParamsService,
  SemanticPathService,
} from '@spartacus/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { AddressInfo, PatientProfileData } from 'src/app/shared/model/patient.model';
import { CategoryPage, CategoryProducts } from 'src/app/shared/model/product.model';
import { getPatientInfo } from 'src/app/store/Selectors/patient.selector';
declare module '@spartacus/core' {
  interface Product {
    category?: Category[];
    contactLensType?: string;
    parentCategory?: string;
    childCategory?: string;
    isOpen?: boolean;
  }
  export interface SearchConfig {
    pageSize?: number;
    currentPage?: number;
    sort?: string;
    pageType?: string;
  }
}

declare module '@spartacus/core' {
  export interface ProductSearchPage {
    priorities: Array<{ code: string; priority: string }>;
    isLandingPage: boolean;
    parentCategory?: Category;
    isContactLensSolution: boolean;
    isDryEyeSolution: boolean;
    categoriesList: Array<Category>;
  }
}
@Injectable({
  providedIn: 'root',
})
export class ProductListService {
  categoryCode = '';
  paramsValue: any;
  constructor(
    private activatedRoutesService: ActivatedRoutesService,
    private routingParams: RoutingParamsService,
    private semanticPathService: SemanticPathService,
    protected productSearchService: ProductSearchService,
    private store: Store
  ) {
    this.routingParams.getParams().subscribe((data: { [key: string]: string }) => {
      this.categoryCode = data.categoryCode;
    });
  }
  public rowElements = 5;
  private popupFlag = true;
  setPatientInfo = new Subject<PatientProfileData>();
  patientAddress = new Subject<AddressInfo>();
  disablePanel = new Subject<boolean>();
  patientCreated = new BehaviorSubject<string>('');

  newPatientIdSubject = new BehaviorSubject<string>('');
  newPatientId = this.newPatientIdSubject.asObservable();

  changedAddressTypeSubject = new BehaviorSubject<boolean>(true);
  changedAddressType = this.changedAddressTypeSubject.asObservable();

  addressTypeSubject = new BehaviorSubject<boolean>(true);
  addressType = this.addressTypeSubject.asObservable();

  private categoriesFilterSubject = new BehaviorSubject([]);
  categoriesFilter = this.categoriesFilterSubject.asObservable();

  private showDtpFilterSubject = new Subject();
  showDtpFilter = this.showDtpFilterSubject.asObservable();

  private facetSubject = new BehaviorSubject([]);
  facetSearchOptions = this.facetSubject.asObservable();

  priorities: Array<any> = [
    { category: 'Contact lens', priority: 1 },
    { category: 'Contact lens care', priority: 2 },
    { category: 'Dry eye products', priority: 3 },
    { category: 'Daily', priority: 1 },
    { category: 'Monthly', priority: 2 },
  ];

  addOption(id: string): void {
    this.disablePanel.next(true);
  }

  enablePanel(patientID = null): void {
    this.disablePanel.next(false);
    if (patientID) {
      this.patientCreated.next(patientID);
      this.newPatientIdSubject.next(patientID);
    }
  }

  setPatientAddress(address: AddressInfo): void {
    this.patientAddress.next(address);
  }

  setPatientData(patientID: string): void {
    this.store
      .select(getPatientInfo(patientID))
      .pipe(take(1))
      .subscribe((patientData: PatientProfileData) => {
        this.setPatientInfo.next({ ...patientData });
      });
  }

  clearPatientData(): void {
    this.setPatientInfo.next(undefined);
  }

  private getPriority(code: string, priorities): number {
    let priority = 1000;
    const category = priorities.find((ct) => ct.code.toLowerCase() === code.toLowerCase());
    if (category) {
      priority = category.priority;
    }
    return priority;
  }

  getFacetLink(): Observable<any[]> {
    return this.activatedRoutesService.routes$.pipe(
      map((actRouteArray: ActivatedRouteSnapshot[]) => {
        const actRoute: ActivatedRouteSnapshot = [...actRouteArray].pop();
        const { cxRoute } = actRoute.data;
        const { catid } = actRoute.params;
        return this.semanticPathService.transform({
          cxRoute,
          params: { code: catid },
        });
      })
    );
  }

  searchProductsList(query, pagetype): void {
    this.productSearchService.clearResults();
    this.productSearchService.search(query, { pageSize: 10000, pageType: pagetype });
  }
  /** @deprecated */
  searchStockOrderProducts(query: string): void {
    this.productSearchService.clearResults();
    this.productSearchService.search(query, { pageSize: 10000, pageType: 'StockOrder' });
  }
  /** @deprecated */
  resolveStockOrderProducts(routeData: ActivatedRouteSnapshot): void {
    const { catid = 'Contact Lenses' } = routeData.params;
    const query = routeData.queryParams.query ? routeData.queryParams.query : ':relevance:allCategories:' + catid;
    this.searchStockOrderProducts(query);
  }
  productServiceFalse(val): void {
    this.popupFlag = val;
  }
  getGroupedProductsList(): Observable<CategoryPage> {
    return this.productSearchService.getResults().pipe(
      filter((data) => !!data.products && this.popupFlag),
      tap((data) => {
        if (data.isContactLensSolution || data.isDryEyeSolution) {
          this.facetSubject.next([...data.facets].filter((facet) => facet.name.toLowerCase() !== 'type'));
        } else {
          this.facetSubject.next([...data.facets]);
        }
      }),
      tap((data) => {
        const categories = [...new Set(data.categoriesList.map((prd) => prd))].sort((a, b) => {
          return this.getPriority(a.code, data.priorities) - this.getPriority(b.code, data.priorities);
        });
        this.categoriesFilterSubject.next(categories);
      }),
      tap((data) =>
        data.isContactLensSolution || data.isDryEyeSolution
          ? this.showDtpFilterSubject.next(false)
          : this.showDtpFilterSubject.next(true)
      ),
      map((data: ProductSearchPage) => this.groupProducts(data))
    );
  }
  /** @deprecated */
  getGroupedStockOrderedProductsList(): Observable<CategoryPage> {
    return this.productSearchService.getResults().pipe(
      filter((stockorderInfo: ProductSearchPage) => !!stockorderInfo.products),
      tap((stockorderInfo: ProductSearchPage) => {
        this.facetSubject.next([...stockorderInfo.facets]);
        this.categoriesFilterSubject.next(stockorderInfo.categoriesList);
        this.showDtpFilterSubject.next(true);
      }),
      map((stockorderInfo: ProductSearchPage) => {
        this.productSearchService.clearResults();
        return this.groupStockOrderProducts(stockorderInfo);
      }),
      take(1)
    );
  }
  /** @deprecated */
  groupStockOrderProducts(data: ProductSearchPage): CategoryPage {
    const { products: unprocessedPrds = [], priorities, isLandingPage, parentCategory: prntCategory } = data;
    const products = unprocessedPrds.map((product) => {
      const parentCategory = product.category[1]?.code ? product.category[1].code : prntCategory.code;
      const childCategory = product.contactLensType;
      return {
        ...product,
        parentCategory,
        childCategory,
      } as Product;
    });
    const categories = [...new Set(products.map((prd) => prd.parentCategory))].sort((a, b) => {
      return this.getPriority(a, priorities) - this.getPriority(b, priorities);
    });
    const productObject: Array<CategoryProducts> = [];
    categories.forEach((category) => {
      const filterdProducts: Product[] = products.filter((prd) => prd.parentCategory === category);
      if (filterdProducts.every((prd) => prd.childCategory !== '')) {
        const subcategories = [...new Set(filterdProducts.map((prd) => prd.childCategory))].sort((a, b) => {
          return a < b ? -1 : 1;
        });
        const subCategoryProducts = [];
        subcategories.forEach((subcategory) => {
          const subproducts = filterdProducts.filter((subprd) => subprd.childCategory === subcategory);
          const subnodeCategory = { name: subcategory, code: subcategory } as Category;
          subCategoryProducts.push({
            products: subproducts,
            CategoryName: subnodeCategory.name,
            count: subproducts.length,
            CategoryCode: subnodeCategory.code,
          } as CategoryProducts);
        });
        const nodeCategory = { name: category, code: category } as Category;
        productObject.push({
          children: subCategoryProducts,
          CategoryName: nodeCategory.code,
          count: filterdProducts.length,
          CategoryCode: nodeCategory.code,
        } as CategoryProducts);
      } else {
        const nodeCategory = filterdProducts[0].category.find((cat: Category) => cat.code === category);
        productObject.push({
          CategoryName: nodeCategory.name,
          products: filterdProducts,
          count: filterdProducts.length,
          CategoryCode: nodeCategory.code,
          showMore: true,
        } as CategoryProducts);
      }
    });
    return {
      products: productObject,
      isLandingPage,
    } as CategoryPage;
  }
  groupProducts(data: ProductSearchPage): CategoryPage {
    const { products: unprocessedPrds = [], priorities, isLandingPage, parentCategory: prntCategory } = data;
    const products = unprocessedPrds.map((product) => {
      const parentCategory = isLandingPage ? product.category[1]?.code : prntCategory.code;
      const childCategory = isLandingPage
        ? product.category.length > 2
          ? product.category[2].code
          : ''
        : product.contactLensType;
      return {
        ...product,
        parentCategory,
        childCategory,
      } as Product;
    });
    const categories = [...new Set(products.map((prd) => prd.parentCategory))].sort((a, b) => {
      return this.getPriority(a, priorities) - this.getPriority(b, priorities);
    });

    const productObject: Array<CategoryProducts> = [];
    categories.forEach((category) => {
      const filterdProducts: Product[] = products.filter((prd) => prd.parentCategory === category);
      if (filterdProducts.every((prd) => prd.childCategory !== '')) {
        const subcategories = [...new Set(filterdProducts.map((prd) => prd.childCategory))].sort((a, b) => {
          return this.getPriority(a, priorities) - this.getPriority(b, priorities);
        });
        const subCategoryProducts = [];
        subcategories.forEach((subcategory) => {
          const subproducts = filterdProducts.filter((subprd) => subprd.childCategory === subcategory);
          const subnodeCategory = isLandingPage
            ? subproducts[0].category.find((cat: Category) => cat.code === subcategory)
            : ({ name: subcategory, code: subcategory } as Category);
          subCategoryProducts.push({
            products: subproducts,
            CategoryName: subnodeCategory.name,
            count: subproducts.length,
            CategoryCode: subnodeCategory.code,
            showMore: true,
          } as CategoryProducts);
        });
        const nodeCategory = isLandingPage
          ? filterdProducts[0].category.find((cat: Category) => cat.code === category)
          : ({ name: category, code: category } as Category);
        productObject.push({
          children: subCategoryProducts,
          CategoryName: nodeCategory.code,
          count: filterdProducts.length,
          CategoryCode: nodeCategory.code,
          showMore: true,
        } as CategoryProducts);
      } else {
        const nodeCategory = filterdProducts[0].category.find((cat: Category) => cat.code === category);
        productObject.push({
          CategoryName: nodeCategory?.name,
          products: filterdProducts,
          count: filterdProducts.length,
          CategoryCode: nodeCategory?.code,
          showMore: true,
        } as CategoryProducts);
      }
    });
    return {
      products: productObject,
      isLandingPage,
    } as CategoryPage;
  }
}
