<div class="switch-toggle">
  <ng-container *ngFor="let option of options">
    <input
      name="toggle"
      type="radio"
      [id]="option.value"
      [value]="option.value"
      [checked]="option.isSelected"
      (change)="switchToggle(option)"
    />
    <label [attr.for]="option.value">
      {{ 'visioncareSearch.' + (option.value | removeWhitespace | lowercase) | cxTranslate }}
    </label>
  </ng-container>
</div>
