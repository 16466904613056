import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SOLensValidationService } from 'src/app/services/stock-order/so-lens-validation.service';
import { STOCK_ORDER_PAGE } from 'src/app/shared/constants/constants';
import { VarientCode } from 'src/app/shared/model/product.model';
import { VisionCareAddtocartProductRequest } from 'src/app/store/States/cartItem.state';
@Component({
  selector: 'app-lens-power-selector',
  templateUrl: './lens-power-selector.component.html',
  styleUrls: ['./lens-power-selector.component.scss'],
})
export class LensPowerSelectorComponent implements OnChanges {
  stockorderConstants = STOCK_ORDER_PAGE;
  @Input() lensPowerSelector;
  @Output() selectedLenses: EventEmitter<Array<VisionCareAddtocartProductRequest>> = new EventEmitter();
  @Output() updatedPower: EventEmitter<VarientCode> = new EventEmitter();
  lensSelectedValues: Array<VisionCareAddtocartProductRequest> = [];
  startIndex;
  endIndex;
  constructor(private validationService: SOLensValidationService) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.lensPowerSelector) {
      this.startIndex = 0;
      this.endIndex = this.lensPowerSelector?.length;
      if (this.lensPowerSelector.length > STOCK_ORDER_PAGE.LENS_IN_VIEW) {
        const centerIndex = Math.floor(this.lensPowerSelector.length / 2);
        this.startIndex = Math.floor(centerIndex - STOCK_ORDER_PAGE.LENS_IN_VIEW / 2);
        this.endIndex = Math.floor(centerIndex + STOCK_ORDER_PAGE.LENS_IN_VIEW / 2);
      }
    }
  }

  updateQuantityToPower(updatedPower): void {
    this.lensPowerSelector.forEach((element) => {
      if (element.quantity) {
        element.displayError = this.validationService.individualMaxQuantity(element);
      }
    });

    this.updatedPower.emit(updatedPower);
  }

  getMoreMinusPowers(): void {
    if (this.lensPowerSelector.length > STOCK_ORDER_PAGE.LENS_IN_VIEW) {
      this.startIndex = 0;
    }
  }
  getLessMinusPowers(): void {
    const centerIndex = Math.floor(this.lensPowerSelector.length / 2);
    this.startIndex = Math.floor(centerIndex - STOCK_ORDER_PAGE.LENS_IN_VIEW / 2);
  }
  getLessPlusPowers(): void {
    const centerIndex = Math.floor(this.lensPowerSelector.length / 2);
    this.endIndex = Math.floor(centerIndex + STOCK_ORDER_PAGE.LENS_IN_VIEW / 2);
  }
  getMorePlusPowers(): void {
    if (this.lensPowerSelector.length > STOCK_ORDER_PAGE.LENS_IN_VIEW) {
      this.endIndex = this.lensPowerSelector.length;
    }
  }
}
