import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { ShowPriceModule } from 'src/app/shared-modules/show-price/show-price.module';
import { AlconCartModule } from '../alcon-cart-v2/alcon-cart.module';
import { AlconCommonModule } from '../common/alcon-common.module';
import { AlconminicartV2Component } from './alcon-mini-cart/alconminicart-v2.component';
import { MiniCartDtpItemListComponent } from './mini-cart-dtp-item-list/mini-cart-dtp-item-list.component';
import { MiniCartProductDetailsComponent } from './mini-cart-product-details/mini-cart-product-details.component';
import { MiniCartValuePackItemListComponent } from './mini-cart-value-pack-item-list/mini-cart-value-pack-item-list.component';
import { MinicartItemListComponent } from './minicart-item-list/minicart-item-list.component';
import { MinicartOrderTypeListComponent } from './minicart-order-type-list/minicart-order-type-list.component';
import { NoOrderMiniPanelComponent } from './no-order-panel/no-order-panel.component';

@NgModule({
  declarations: [
    AlconminicartV2Component,
    MiniCartDtpItemListComponent,
    MiniCartProductDetailsComponent,
    MiniCartValuePackItemListComponent,
    MinicartItemListComponent,
    MinicartOrderTypeListComponent,
    NoOrderMiniPanelComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    I18nModule,
    IconModule,
    RouterModule,
    AlconCommonModule,
    AlconCartModule,
    ShowPriceModule,
  ],
  exports: [
    AlconminicartV2Component,
    MiniCartDtpItemListComponent,
    MiniCartProductDetailsComponent,
    MiniCartValuePackItemListComponent,
    MinicartItemListComponent,
    MinicartOrderTypeListComponent,
    NoOrderMiniPanelComponent,
  ],
})
export class AlconMiniCartModule {}
