import { Injectable } from '@angular/core';
import {
  BaseSite,
  BaseSiteService,
  BASE_SITE_CONTEXT_ID,
  CURRENCY_CONTEXT_ID,
  JavaRegExpConverter,
  LANGUAGE_CONTEXT_ID,
  SiteContextConfig,
  SiteContextConfigInitializer,
  WindowRef,
} from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlconSiteService } from '../service/alcon-site-provider.service';

@Injectable({
  providedIn: 'root',
})
export class CustomSiteContextConfigInitializer extends SiteContextConfigInitializer {
  constructor(
    baseSiteService: BaseSiteService,
    javaRegExpConverter: JavaRegExpConverter,
    winRef: WindowRef,
    private alconSiteService: AlconSiteService
  ) {
    super(baseSiteService, javaRegExpConverter, winRef);
  }

  protected getConfig(source: BaseSite): SiteContextConfig {
    // get the site context config

    const siteContextConfig = super.getConfig(source);

    // define possible values of custom context deriving from ISO languages codes

    const [custom] = this.winRef.location.pathname.split('/').slice(1);
    this.alconSiteService.setCurrentSite(custom);

    const config = {
      context: { ...siteContextConfig.context, custom: [custom] },
    };

    return config;
  }

  protected resolveConfig(): Observable<SiteContextConfig> {
    return super.resolveConfig().pipe(
      catchError(() => {
        const noBaseSiteConfig = {
          context: {
            urlParameters: ['custom'],
            [BASE_SITE_CONTEXT_ID]: ['no_site'],
            [LANGUAGE_CONTEXT_ID]: ['no_lan'],
            [CURRENCY_CONTEXT_ID]: ['no_currency'],
          },
        } as SiteContextConfig;

        return of(noBaseSiteConfig);
      })
    );
  }
}
