import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AddressInfo } from 'src/app/shared/model/patient.model';

@Component({
  selector: 'app-patient-address',
  templateUrl: './patient-address.component.html',
  styleUrls: ['./patient-address.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PatientAddressComponent {
  @Input() patientAddress: AddressInfo;
  constructor() {}
}
