import { Address } from './b2b-unit.model';

export interface UserDetails {
  name?: string;
  email?: string;
  sessionTimeOut?: number;
  uid?: string;
  active?: boolean;
}

export enum UserFranchise {
  VC = 'visioncareusergroup',
  SX = 'surgicalusergroup',
}
declare module '@spartacus/user/account/root' {
  interface User {
    p44Enabled?: boolean;
  }
  interface Address {
    addToConsignmentEnabled?: boolean;
    shippingAddress: boolean;
  }
}
export interface UserDetailsList {
  b2bCustomers?: UserInfo[];
  pagination?: UserListPaginationInfo;
  sorts?: SortUserList[];
}
export interface UserListPaginationInfo {
  currentPage?: number;
  pageSize?: number;
  sort?: string;
  totalPages?: number;
  totalResults?: number;
}
export interface UserInfo {
  email?: string;
  firstName?: string;
  lastName?: string;
}
export interface SortUserList {
  code?: string;
  selected?: boolean;
}

export interface PermissionAcountList {
  email?: string;
  firstName?: string;
  lastName?: string;
  title?: string;
  phoneNumber?: string;
  activeAccounts?: AccountInfo[];
  inActiveAccounts?: AccountInfo[];
  noAccessAccounts?: AccountInfo[];
}
export interface AccountInfo {
  accountUID?: string;
  address?: Address;
  name?: string;
  uid?: string;
  lineItemKey?: string;
}

export enum USER_TYPE {
  User = 'User',
  Admin = 'Admin',
}

export interface LineLevelInfo {
  showLineLevelPO?: boolean;
  showLineLevelAccountRef?: boolean;
  displayLineLevel?: string;
}
