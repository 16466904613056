import { Component, Input } from '@angular/core';
import { OrderEntryFlowType } from 'src/app/store/States/cartItem.state';

@Component({
  selector: 'app-dtp-confirmed-order-details',
  templateUrl: './dtp-confirmed-order-details.component.html',
  styleUrls: ['./dtp-confirmed-order-details.component.scss'],
})
export class DtpConfirmedOrderDetailsComponent {
  @Input() dtpOrderDetails;
  @Input() finalPriceCalculated;
  openConfirmedOrder = true;
  get orderEntryFlowType(): any {
    return OrderEntryFlowType;
  }
  constructor() {}
}
