<div *ngIf="$salesRepData | async as data" class="sales">
  <div class="sales__btn d-block d-lg-none">
    <cx-icon
      [ngClass]="open ? 'd-none d-lg-inline' : 'd-inline'"
      class="sales__icon"
      type="UserProfile"
      (click)="toggle()"
    ></cx-icon>
    <cx-icon
      [ngClass]="!open ? 'd-none' : 'd-inline d-lg-none'"
      class="sales__icon"
      type="blueCloseIcon"
      (click)="toggle()"
    ></cx-icon>
  </div>
  <h2 class="sales__title">{{ 'contactusPage.salesRep.title' | cxTranslate }}</h2>
  <ng-container *ngTemplateOutlet="datas; context: { data: data }"></ng-container>
</div>

<div class="overlay d-lg-none" *ngIf="open"></div>

<ng-template #datas let-data="data">
  <div [ngClass]="open ? 'd-block panel' : 'd-none d-lg-inline'">
    <h2 class="sales__box">{{ 'contactusPage.salesRep.title' | cxTranslate }}</h2>
    <h4 class="sales__name">
      <cx-icon class="sales__name-icon" type="UserProfile" (click)="toggle()"></cx-icon>
      <span>{{ data?.salesRep?.name }}</span>
    </h4>

    <div class="sales__phone">
      <label>{{ 'contactusPage.salesRep.phone' | cxTranslate }}</label>
      <span *ngFor="let phoneNumbers of data?.salesRep?.salesRepPhoneNumber; count as count; last as isLast">
        {{ phoneNumbers?.phoneNumber }}{{ count > 1 && !isLast ? ', ' : '' }}</span
      >
    </div>
    <div class="sales__email">
      <label>{{ 'contactusPage.salesRep.email' | cxTranslate }}</label>
      <span> {{ data?.salesRep?.email }}</span>
    </div>

    <div class="customer__title">
      {{ customerServiceTitle?.content }}
    </div>
    <div class="customer__message" [innerHtml]="customerServiceMessage?.content"></div>
    <a class="sales__return" *ngIf="isReturnsEnabled">
      <cx-icon type="ReturnProduct">
        <span class="sales__return--product" [routerLink]="{ cxRoute: 'returnFormPage' } | cxUrl">
          {{ 'contactusPage.returnProdct' | cxTranslate }}
        </span>
      </cx-icon>
    </a>
  </div>
</ng-template>
