import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslationService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductDetailsService } from 'src/app/services/alcon-pdp/product-details.service';
import { LocalStorageService } from 'src/app/services/localstorage/local-storage.service';
import { CART_TYPE, CART_VARIENTS, LENSAXIS, Variants } from 'src/app/shared/constants/constants';
import { DropdownConfig, DropdownOptions, ErrorItem } from 'src/app/shared/model/common.mode';
import {
  BasicProductSelectedVariants,
  ProductInfo,
  ProductSpecifications,
  ProductVarientTree,
  VarientCode,
} from 'src/app/shared/model/product.model';
import { VisionCareAddtocartProductRequest } from 'src/app/store/States/cartItem.state';

@Component({
  selector: 'app-stockorder-toric',
  templateUrl: './stockorder-toric.component.html',
  styleUrls: ['./stockorder-toric.component.scss'],
})
export class StockorderToricComponent implements OnChanges {
  @ViewChild('toricTable', { read: ElementRef }) toricTable: ElementRef;
  @Input() cartType: string;
  @Input() cartData: ProductInfo;
  @Input() varients: BasicProductSelectedVariants;
  @Input() productVarientTree: ProductVarientTree;
  @Input() clearToricQuantity: boolean;
  @Input() errorObject: ErrorItem[];
  aixsVarients = LENSAXIS.LENS_AXIS;
  lensSelectedValues: Array<VisionCareAddtocartProductRequest> = [];
  totalAxisList: Array<DropdownOptions> = [];
  totalPowerList: Array<DropdownOptions> = [];
  selectedPowerList = [];
  totalQuantity = 0;
  @Output() getSelectedToricList: EventEmitter<any> = new EventEmitter();
  @Output() clearLensSelected: EventEmitter<any> = new EventEmitter();
  @Output() getQuantity: EventEmitter<any> = new EventEmitter();
  @Output() setVarients: EventEmitter<any> = new EventEmitter();
  @Output() toricPowers: EventEmitter<any> = new EventEmitter();
  updateLensFlag = false;
  quantityError = false;
  powerForAxisList = [];
  readonly defaultConfig: Observable<DropdownConfig> = this.translation.translate('productListingPage.select').pipe(
    map((text) => ({
      defaultSelectText: text,
      disableOnOnlyOption: false,
      noDataText: '',
    }))
  );
  constructor(
    private cd: ChangeDetectorRef,
    private productService: ProductDetailsService,
    private localStorageService: LocalStorageService,
    private translation: TranslationService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.clearToricQuantity?.currentValue?.status) {
      this.clearQuantities();
      return;
    }
    if (changes?.errorObject?.currentValue) {
      this.validateQuantityfields(this.errorObject);
      return;
    }
    this.varients = changes.varients?.currentValue;
    this.powerForAxisList = [];
    this.totalPowerList = [];
    this.totalAxisList = [];
    if (!this.varients?.cylinder) {
      this.productVarientTree = changes.productVarientTree?.currentValue;
      return;
    }
    this.totalAxisList = this.productService.formatDropdown(
      this.productService.generateVarientDropdownoptions(
        this.productVarientTree?.childrenNodes,
        Variants.AXIS,
        this.varients
      )
    );
    this.totalPowerList = this.productService.formatDropdown(
      this.productService.generateVarientDropdownoptions(
        this.productVarientTree.childrenNodes,
        Variants.LENSPOWER,
        this.varients
      )
    );

    this.totalPowerList = [...this.productService.sortLensPowers(this.totalPowerList)];
    this.cd.detectChanges();

    if (this.cartType === 'update' && this.compareLensCylinder()) {
      this.genaratedSelectedLens();
      if (this.updateLensFlag && !this.localStorageService.getTheStorage(this.cartData.productCode)) {
        this.clearLensSelected.emit();
      } else {
        this.lensSelectedValues = this.productService.getVarienntsFormCartObject(this.cartData, true);
        this.selectedPowerList.forEach((element, index) => {
          element.combinations.forEach((value) => {
            if (value.displayError) {
              this.quantityError = true;
            }
          });
        });
        if (this.quantityError) {
          this.setVarients.emit([]);
        } else {
          this.setVarients.emit(this.lensSelectedValues);
        }
      }
    }
  }
  compareLensCylinder(): ProductSpecifications {
    return this.cartData.specifications.find(
      (node) => node.specificationKey === CART_VARIENTS.CYLINDER && this.varients.cylinder === node.specificationValue
    );
  }

  /* Assigning the error to quantity fields in case of BE error*/
  validateQuantityfields(errorObj): void {
    let errorCodeExist = false;
    if (errorObj && errorObj.length > 0) {
      errorObj?.forEach((errorItem: any) => {
        this.powerForAxisList?.forEach((axisesForPower) => {
          axisesForPower?.axisData?.forEach((data) => {
            if (errorItem.subject === data.value) {
              data.displayError = true;
              errorCodeExist = true;
            }
          });
        });
      });
    } else {
      this.assignErrorsforToric();
    }
    if (errorObj && errorObj.length > 0 && !errorCodeExist) {
      this.assignErrorsforToric();
    }
  }

  assignErrorsforToric(): void {
    this.powerForAxisList.forEach((axisesForPower) => {
      axisesForPower.axisData.forEach((data) => {
        if (data.quantity && !isNaN(data.quantity)) {
          data.displayError = true;
        } else {
          data.displayError = false;
        }
      });
    });
  }

  genaratedSelectedLens(): void {
    this.cartData?.entries?.forEach((cart) => {
      this.totalPowerList?.forEach((power: DropdownOptions) => {
        if (
          cart?.specifications?.find(
            (node) => node.specificationKey === CART_VARIENTS.LENS_POWER && power.text === node.specificationValue
          )
        ) {
          this.selectLensPower(power);
        }
      });
    });
  }

  clearQuantities(): void {
    this.powerForAxisList?.forEach((power) => {
      power?.axisData?.forEach((data) => {
        if (data.quantity >= 0) {
          data.quantity = '';
          data.displayError = false;
        }
      });
    });
  }

  setTotalQuantity(): void {
    this.totalQuantity = 0;
    this.powerForAxisList?.forEach((axisesForPower) => {
      axisesForPower?.axisData?.forEach((variantData) => {
        if (!variantData.disabled && variantData.quantity > 0 && !variantData.displayError) {
          this.totalQuantity = this.totalQuantity + Number(variantData.quantity);
        }
      });
    });
  }

  selectLensPower(value: DropdownOptions): void {
    let axisData = [];
    const powerIndex = this.totalPowerList.findIndex((power: DropdownOptions) => power.text === value.text);
    if (powerIndex > -1) {
      this.totalPowerList.splice(powerIndex, 1);
      this.totalPowerList = [...this.totalPowerList];
    }
    const axisList = this.productService.generateVarientDropdownoptions(
      this.productVarientTree?.childrenNodes,
      Variants.AXIS,
      {
        ...this.varients,
        lenspower: value.text,
      },
      true
    );

    axisData = this.aixsVarients.map((axisVariant) => {
      let varientCode;
      let cartVariant;
      if (axisList.has(axisVariant)) {
        varientCode = this.productService.generateVarientCodes(this.productVarientTree.childrenNodes, {
          ...this.varients,
          lenspower: value.text,
          axis: axisVariant,
        });
      }
      if (this.cartType?.toLowerCase() === CART_TYPE.UPDATE) {
        cartVariant = this.productService.getVarientQuantity(varientCode?.code, this.cartData);
      }

      return {
        axis: axisVariant,
        power: value.text,
        quantity: axisList.has(axisVariant) ? (cartVariant ? cartVariant.quantity.toString() : '') : 'n/a',
        value: varientCode?.code,
        entryNumber: cartVariant?.entryNumber,
        disabled: !axisList.has(axisVariant),
        maxQuantity: varientCode?.maxQuantity,
      } as VarientCode;
    });

    this.powerForAxisList.push({
      power: value.text,
      axisList,
      axisData,
    });

    this.cd.detectChanges();
    this.toricTable.nativeElement.scrollLeft = this.toricTable.nativeElement.scrollWidth;
  }

  isAxisDisabled(powerData, axis): boolean {
    return powerData?.axisData?.find((axisForSelectedPower) => axisForSelectedPower.axis === axis).disabled;
  }

  getQuantityForAxis(powerData, axis): string {
    return powerData?.axisData?.find((axisForSelectedPower) => axisForSelectedPower.axis === axis).quantity;
  }

  updateQuantityForAxis(powerData, axis, $event): void {
    const quantity = $event.target.value.replace(/[^0-9.]*/g, '');

    // update quantity for that axis of that power
    this.powerForAxisList?.forEach((element) => {
      if (element.power === powerData.power && element?.axisData?.length) {
        element.axisData.filter((axisData) => axisData.axis === axis)[0].quantity = quantity;
      }
    });

    const inputAxisData = powerData?.axisData?.find((axisForSelectedPower) => axisForSelectedPower.axis === axis);
    // assign value to array
    inputAxisData.quantity = quantity;

    // validate max quantity for other quantities as well
    this.powerForAxisList?.forEach((axisesForPower) => {
      axisesForPower?.axisData?.forEach((data) => this.validateMaxQuantity(data));
    });

    // emit powers and calcuate total
    this.getSelectedToricList.emit(inputAxisData);
    this.setTotalQuantity();
    this.getQuantity.emit(this.totalQuantity);
    this.toricPowers.emit(this.powerForAxisList);
  }

  deletePowerFromSelection(power): void {
    const removedPower = this.powerForAxisList?.filter((powerData) => powerData.power === power);
    removedPower?.forEach((powerData) => {
      powerData?.axisData?.forEach((data) => {
        if (data.quantity && !data.disabled) {
          this.getSelectedToricList.emit({ ...data, quantity: '' });
        }
      });
    });
    this.powerForAxisList.splice(
      this.powerForAxisList.findIndex((powerData) => powerData.power === power),
      1
    );
    this.powerForAxisList = [...this.powerForAxisList];

    this.powerForAxisList?.forEach((axisesForPower) => {
      axisesForPower?.axisData?.forEach((variantData) => {
        if (variantData.quantity && !variantData.disabled) {
          this.getSelectedToricList.emit({ ...variantData });
        }
      });
    });

    this.totalPowerList.push({
      value: power,
      text: power,
    });
    this.totalPowerList = [...this.totalPowerList.sort((val1, val2) => Number(val1.text) - Number(val2.text))];

    this.setTotalQuantity();
    this.getQuantity.emit(this.totalQuantity);
  }

  hasMaxQuantityError(power, axis): boolean {
    return power?.axisData?.find((axisForSelectedPower) => axisForSelectedPower.axis === axis).displayError;
  }

  validateMaxQuantity(varientInfo): void {
    if (
      varientInfo.quantity &&
      varientInfo.maxQuantity &&
      Number(varientInfo.quantity) > Number(varientInfo.maxQuantity)
    ) {
      varientInfo.displayError = true;
    } else {
      varientInfo.displayError = false;
    }
  }

  setErrorForAddedQuantity(errors): void {
    if (errors && errors.length > 0) {
      errors?.forEach((errorItem: any) => {
        this.powerForAxisList?.forEach((axisesForPower) => {
          axisesForPower?.axisData?.forEach((data) => {
            if (errorItem.subject === data.value) {
              data.displayError = true;
            }
          });
        });
      });
    }
  }
}
