import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { AlconCommonModule } from '../common/alcon-common.module';
import { AddNewPatientComponent } from './add-new-patient/add-new-patient.component';
import { AddPatientButtonPopupComponent } from './add-patient-button-popup/add-patient-button-popup.component';
import { PatientAddressComponent } from './patient-address/patient-address.component';
import { PatientInfoSelectionComponent } from './patient-info-selection/patient-info-selection.component';

@NgModule({
  imports: [CommonModule, I18nModule, FormsModule, ReactiveFormsModule, AlconCommonModule, IconModule],
  declarations: [
    AddNewPatientComponent,
    AddPatientButtonPopupComponent,
    PatientAddressComponent,
    PatientInfoSelectionComponent,
  ],
  exports: [
    AddNewPatientComponent,
    AddPatientButtonPopupComponent,
    PatientAddressComponent,
    PatientInfoSelectionComponent,
  ],
})
export class AlconPatientModule {}
