<div class="d-flex minicartListItem justify-content-between">
  <div class="b2b-col-32 minicartListItem__image d-flex align-items-center">
    <img [attr.src]="productData?.image?.url" alt="{{ productData.productName }}" />
  </div>
  <div class="b2b-col-65 minicartListItem__product">
    <h4 class="minicartListItem__product__label">{{ productData.productName }}</h4>
    <div class="minicartListItem__product__sku">
      <app-cart-item-specifications
        [showQuantity]="showQuantity"
        [cartEntry]="productData"
      ></app-cart-item-specifications>
    </div>
  </div>
</div>
