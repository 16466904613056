import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OrderHistoryService } from 'src/app/services/order-history/order-history.service';
import { AlconUserPermissionService } from 'src/app/shared-modules/alcon-user-permission-provider/service/alcon-user-permission.service';
import { Statements } from 'src/app/shared/model/orderHistory.model';

@Component({
  selector: 'app-alcon-statement-download',
  templateUrl: './alcon-statement-download.component.html',
  styleUrls: ['./alcon-statement-download.component.scss'],
})
export class AlconStatementDownloadComponent implements OnInit {
  openDropDown = false;
  private destroy$ = new Subject<void>();
  options: Statements;
  showStatement = true;

  constructor(private orderHistoryService: OrderHistoryService, private userPermission: AlconUserPermissionService) {}

  ngOnInit(): void {
    this.showStatement = this.userPermission.getInvoicesPermission();
    this.orderHistoryService
      .getStatementsDates()
      .pipe(takeUntil(this.destroy$))
      .subscribe((list) => {
        this.options = this.formatDropdownOptionForDownload(list.statementEntries);
      });
  }

  formatDropdownOptionForDownload(optionsList): Statements {
    const options = optionsList?.map((option, index) => {
      return {
        text: option.year,
        value: option.year,
        months: option.month,
        openDropDown: index === 0 ? true : false,
      };
    });
    return options;
  }
  showDropDown(): void {
    this.openDropDown = !this.openDropDown;
  }
  download(month, year): void {
    const monthNo = new Date(Date.parse(month.date + year)).getMonth() + 1;
    const date = monthNo + '-' + year;
    this.orderHistoryService
      .downStatementsDates(date)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        const file = new Blob([data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });
  }
}
