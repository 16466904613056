<div class="order-payment-method-container">
  <div class="order-payment-method-container__payment-type">
    <cx-icon class="pr-2 paymentIcon" type="PaymentIcon"> </cx-icon>
    <label class="order-payment-method-container__payment-type--margin-b"
      >{{ 'checkoutPage.paymentMethod' | cxTranslate }}:</label
    >
  </div>
  <p class="payment text-right" *ngIf="cardType === paymentTypeCard">
    {{ 'orderDetailsPage.paymentMethodPanel.cardEndingWith' | cxTranslate }}{{ orderDetails?.paymentInfo?.cardNumber }}
  </p>
  <p class="order-payment-method-container__payment-info" *ngIf="cardType !== paymentTypeCard">
    {{ 'orderDetailsPage.paymentMethodPanel.invoiceToAccount' | cxTranslate }}
  </p>
  <div class="order-payment-method-container__print ml-auto">
    <app-alcon-print class="d-print-none"></app-alcon-print>
  </div>
</div>
