import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { IconModule, MediaModule, PageSlotModule } from '@spartacus/storefront';
import { NavOptionsPipe } from 'src/app/pipes/nav-options.pipe';
import { AlconCommonModule } from '../common/alcon-common.module';
import { VisioncareGlobalSearchModule } from '../vc-global-search/visioncare-global-search.module';
import { AccountDetailsComponent } from './header-link-nav/account-details/account-details.component';
import { HeaderAccountSelectorComponent } from './header-link-nav/header-account-selector/header-account-selector.component';
import { HeaderLinkNavComponent } from './header-link-nav/header-link-nav.component';
import { MarketingPopupComponent } from './marketing-popup/marketing-popup.component';
import { MarkettingBannerComponent } from './marketting-banner/marketting-banner.component';
import { NewOrderNavComponent } from './new-order-nav/new-order-nav.component';

@NgModule({
  declarations: [
    HeaderLinkNavComponent,
    NewOrderNavComponent,
    AccountDetailsComponent,
    HeaderAccountSelectorComponent,
    MarketingPopupComponent,
    MarkettingBannerComponent,
    NavOptionsPipe,
  ],
  imports: [
    CommonModule,
    NgbModule,
    I18nModule,
    PageSlotModule,
    MediaModule,
    AlconCommonModule,
    IconModule,
    RouterModule,
    VisioncareGlobalSearchModule,
    ConfigModule.withConfig({
      cmsComponents: {
        HeaderLinkNavigationComponent: {
          component: HeaderLinkNavComponent,
        },
        AlconMarketingBannerImagesComponent: {
          component: () => import('./marketing-popup/marketing-popup.component').then((m) => m.MarketingPopupComponent),
        },
      },
      skipLinks: [],
    } as CmsConfig),
  ],
  exports: [
    HeaderLinkNavComponent,
    NewOrderNavComponent,
    MarketingPopupComponent,
    VisioncareGlobalSearchModule,
    NavOptionsPipe,
  ],
})
export class HeaderModule {}
