<form [formGroup]="updatePatientForm" (ngSubmit)="onSubmit()">
  <div class="delivery-address-container">
    <div class="d-flex flex-column flex-lg-row">
      <div class="flex-grow-1 deliveryaddress selectdeliveryadress__inputwrap--width">
        <div
          class="d-flex align-items-end add-margin"
          [ngClass]="{ 'align-items-center justify-content-between': editMainAddress }"
        >
          <input
            type="radio"
            value="MainAddress"
            [checked]="addressType === 'MainAddress'"
            name="addressType"
            id="main-address"
            (click)="changeAddressType($event)"
            class="alconInput__radio"
            [attr.disabled]="disableMainAdd"
          />
          <label for="main-address" class="delivery-address__radio-label"
            >{{ 'selectDeliveryAddressPage.mainaddress' | cxTranslate }}
          </label>
          <div
            class="delivery-address__edit"
            *ngIf="!editAdditionalAddress && !editMainAddress"
            (click)="editMainAddress = true; addressType = 'MainAddress'"
          >
            <cx-icon class="delivery-address-edit-button" type="EditBlue"> </cx-icon>
            <span class="delivery-address-edit-button__text">{{ 'selectDeliveryAddressPage.edit' | cxTranslate }}</span>
          </div>
          <div class="d-md-block d-lg-block">
            <div *ngIf="editMainAddress" class="col d-flex justify-content-left align-items-center mandatorypd">
              {{ 'selectDeliveryAddressPage.mandatory' | cxTranslate }}
            </div>
          </div>
        </div>
        <app-address-block *ngIf="!editMainAddress" textSize="md" [address]="mainAddress"></app-address-block>
        <ng-container *ngIf="editMainAddress">
          <app-patient-address-form [AddressIndex]="0"> </app-patient-address-form>
        </ng-container>
      </div>
      <div class="flex-grow-1 deliveryaddress selectdeliveryadress__inputwrap--width">
        <div
          class="d-flex align-items-end add-margin"
          [ngClass]="{ ' justify-content-between': editAdditionalAddress }"
        >
          <div class="d-md-block d-none">
            <div class="d-flex flex-row additionaladresstm">
              <div *ngIf="addresses.length > 1">
                <input
                  type="radio"
                  value="AdditionalAddress"
                  (click)="changeAddressType($event)"
                  name="addressType"
                  id="additional-address"
                  [checked]="showAdditionalAdd || addressType === 'AdditionalAddress'"
                  class="alconInput__radio"
                />
                <label for="additional-address" class="delivery-address__radio-label"
                  >{{ 'selectDeliveryAddressPage.additonaladdress' | cxTranslate }}
                </label>
              </div>
              <div *ngIf="addresses.length === 1 && !editMainAddress" class="d-none d-lg-block">
                <cx-icon class="icon-style" (click)="addAdditionalAddress()" type="AddOrderOption"></cx-icon>
                <label class="delivery-address__radio-label"
                  >{{ 'selectDeliveryAddressPage.additonaladdress' | cxTranslate }}
                </label>
              </div>
            </div>
          </div>
          <div
            class="delivery-address__edit"
            *ngIf="patientInfo.addresses?.length > 1 && !editAdditionalAddress && !editMainAddress"
            (click)="editAdditionalAddress = true; addressType = 'AdditionalAddress'"
          >
            <cx-icon class="delivery-address-edit-button" type="EditBlue"> </cx-icon>
            <span class="delivery-address-edit-button__text">{{ 'selectDeliveryAddressPage.edit' | cxTranslate }}</span>
          </div>
          <div class="d-lg-block justify-content-end d-md-block">
            <div *ngIf="editAdditionalAddress" class="text-right mandatory pb-2">
              {{ 'selectDeliveryAddressPage.mandatory' | cxTranslate }}
            </div>
          </div>
        </div>

        <app-address-block
          *ngIf="patientInfo.addresses?.length > 1 && !editAdditionalAddress"
          textSize="md"
          [address]="additionalAddress"
        ></app-address-block>
        <ng-container *ngIf="editAdditionalAddress">
          <app-patient-address-form [AddressIndex]="1"></app-patient-address-form>
        </ng-container>
        <div *ngIf="editAdditionalAddress" class="delivery-address__delete">
          <cx-icon class="Remove" type="Remove" (click)="deleteAdditionalAddress()">
            <p class="delivery-address__delete--text">
              {{ 'patientAddressPage.form.deleteadditionaladdress' | cxTranslate }}
            </p>
          </cx-icon>
          <p class="errorDisplay text-left mb-0">{{ addressError }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex padding-x" [ngClass]="{ 'save-panel': !(editAdditionalAddress || editMainAddress) }">
    <div *ngIf="addresses.length === 1 && !editMainAddress" class="d-lg-none">
      <cx-icon class="icon-style" (click)="addAdditionalAddress()" type="AddOrderOption"></cx-icon>
      <label class="delivery-address__radio-label"
        >{{ 'selectDeliveryAddressPage.additonaladdress' | cxTranslate }}
      </label>
    </div>
    <div
      class="col d-flex justify-content-end align-items-center pr-5 cancel"
      [ngClass]="{
        'offset-3': editAdditionalAddress || editMainAddress
      }"
      (click)="cancelForm()"
    >
      {{ 'selectDeliveryAddressPage.cancel' | cxTranslate }}
    </div>
    <div class="d-flex savebutton">
      <button class="btn btn-primary text-uppercase btn-save" type="submit">
        {{ 'selectDeliveryAddressPage.save' | cxTranslate }}
      </button>
    </div>
  </div>
</form>
