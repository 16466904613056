import { Component, ViewEncapsulation } from '@angular/core';
import { CmsService } from '@spartacus/core';

@Component({
  selector: 'app-registeration-confirm-page',
  templateUrl: './registeration-confirm-page.component.html',
  styleUrls: ['./registeration-confirm-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RegisterationConfirmPageComponent {
  public backgroundImage$ = this.cmsService.getComponentData('registrationsuccesseImage');
  constructor(public cmsService: CmsService) {}
}
