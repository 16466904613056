<div class="d-md-flex d-lg-block flex-md-wrap">
  <div class="variant d-flex flex-row align-items-center">
    <label class="variant__label">{{ 'stockOrderPage.baseCurve' | cxTranslate }}</label>

    <app-dropdown-selector-v2
      class="variant__dropdown"
      [options]="baseCurveSelector"
      (dropDownSelected)="baseCurveSelected($event)"
    >
    </app-dropdown-selector-v2>
  </div>

  <div class="variant d-flex flex-row align-items-center">
    <label class="variant__label">{{ 'stockOrderPage.diameter' | cxTranslate }}</label>

    <app-dropdown-selector-v2
      class="variant__dropdown"
      [options]="diameterSelector"
      (dropDownSelected)="diameterSelected($event)"
    >
    </app-dropdown-selector-v2>
  </div>

  <div class="variant d-flex flex-row align-items-center" *ngIf="contactLensType == 'COLOR'">
    <label class="variant__label">{{ 'stockOrderPage.color' | cxTranslate }}</label>

    <app-dropdown-selector-v2
      class="variant__dropdown"
      [options]="colorSelector"
      (dropDownSelected)="colorSelected($event)"
    >
    </app-dropdown-selector-v2>
  </div>

  <div class="variant d-flex flex-row align-items-center" *ngIf="contactLensType == 'MULTIFOCAL'">
    <label class="variant__label">{{ 'stockOrderPage.add' | cxTranslate }}</label>

    <app-dropdown-selector-v2
      class="variant__dropdown"
      [options]="addSelector"
      (dropDownSelected)="addSelected($event)"
    >
    </app-dropdown-selector-v2>
  </div>

  <div class="variant d-flex flex-row align-items-center" *ngIf="contactLensType == 'TORIC'">
    <label class="variant__label">{{ 'stockOrderPage.lenscylinder' | cxTranslate }}</label>

    <app-dropdown-selector-v2
      class="variant__dropdown"
      [options]="toricSelector"
      (dropDownSelected)="lensCylinderSelected($event)"
    >
    </app-dropdown-selector-v2>
  </div>
</div>
