import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from '@spartacus/core';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ContactUsService } from 'src/app/services/contact-us/contact-us.service';
import { ValidatorService } from 'src/app/services/validators/validator.service';
import { ContactusConfig } from 'src/app/shared/config/contactus-config';
import { ContactUs, VALIDATOR_CONSTANT } from 'src/app/shared/constants/constants';
import { DropdownOptions, ModalOptions } from 'src/app/shared/model/common.mode';
import { ContactUsModel } from 'src/app/shared/model/contactus.model';
import { EContactUsSubmitButtonPosition } from '../../shared/config/contactus-submitButtonPosition.enum';
import { ContactUsType } from '../../shared/config/contactus-type.enum';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit, OnDestroy {
  @Input() configs: ContactusConfig = {
    showMandatoryFieldText: true,
    isInModal: false,
    type: ContactUsType.contactUs,
    submitButtonPosition: EContactUsSubmitButtonPosition.spaceBetween,
  };

  @Input() formConfig: object;
  @Output() closed: EventEmitter<void> = new EventEmitter<void>();

  topicList: DropdownOptions[];
  preferredTime: string;
  termsConditionsFlag = false;
  topicLabel = this.translation.translate('contactusPage.selectone').pipe(map((text) => ({ label: text })));
  contactInfo = { customerEmail: null, customerName: null, phone: null, topic: [], companyName: '' } as ContactUsModel;
  emailChanged: boolean;
  phoneNumberChanged: boolean;
  showInValidIcon = false;
  resetDropDown = false;
  public form: UntypedFormGroup;
  public readonly ContactUs = ContactUsType.contactUs;

  @Input() thankYouModalOptions = {
    open: false,
    header: false,
    modalTitle: '',
    borderRadius: '20px',
  } as ModalOptions;

  private destroy$ = new Subject<void>();
  constructor(
    private contactusFormBuilder: UntypedFormBuilder,
    private contactUsService: ContactUsService,
    private validatorService: ValidatorService,
    private route: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef,
    private translation: TranslationService
  ) {}

  ngOnInit(): void {
    this.form = this.contactusFormBuilder.group(
      this.formConfig
        ? this.formConfig
        : {
            topic: ['', [Validators.required]],
            message: ['', [Validators.required, Validators.maxLength(1000)]],
            contactname: ['', [Validators.required, Validators.maxLength(30)]],
            contactMethod: this.contactusFormBuilder.array([], Validators.required),
            preferredTime: ['', [Validators.required]],
            email: [''],
            phoneNumber: [''],
            termsConditions: [false],
            // uploadedFile: [''],
          }
    );

    this.assignContactusInfo();
    const selectedTopic = this.route.snapshot.queryParams;
    if (this.contactInfo && this.contactInfo.topic.length > 0) {
      this.topicList = this.contactInfo.topic.map((topic: string) => {
        return {
          text: topic,
          value: topic,
          selected: selectedTopic.category === topic,
        };
      });
    }
    if (selectedTopic.category) {
      this.TopicSelected({ text: selectedTopic.category });
    }
    this.emailAddress.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((emailchange: string) => {
      this.emailChanged = emailchange !== this.contactInfo.customerEmail;
      this.checkTermsConditons();
    });
    this.phoneNumber.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((phonechanged: string) => {
      this.phoneNumberChanged = phonechanged && phonechanged !== this.contactInfo.phone;
      this.checkTermsConditons();
    });
  }
  assignContactusInfo(): void {
    if (this.route.snapshot.data.contactusInfo) {
      this.contactInfo = this.route.snapshot.data.contactusInfo;
    }
    if (this.contactInfo?.customerEmail) {
      this.emailAddress.setValue(this.contactInfo?.customerEmail);
    }
    if (this.contactInfo?.phone) {
      this.phoneNumber.setValue(this.contactInfo?.phone);
    }
    if (this.contactInfo?.firstName && this.contactInfo?.lastName) {
      this.contactname.setValue(this.contactInfo?.firstName + ' ' + this.contactInfo?.lastName);
    }
  }
  checkTermsConditons(): void {
    if (this.emailChanged || this.phoneNumberChanged) {
      this.form.get('termsConditions').setValidators([Validators.pattern('^true$')]);
    } else {
      this.form.get('termsConditions').clearValidators();
    }
    this.form.get('termsConditions').updateValueAndValidity();

    this.cd.detectChanges();
  }

  selectTermsConditions(termsConditions: boolean): void {
    this.termsConditionsFlag = termsConditions;
    this.form.get('termsConditions').setValue(termsConditions);
    this.form.get('termsConditions').markAsTouched();
  }

  selectContactMethod(contactMethod: string, checked: boolean): void {
    this.form.get('contactMethod').markAsTouched();
    const contactMethodList = this.form.controls.contactMethod as UntypedFormArray;
    if (checked) {
      contactMethod === 'email'
        ? this.form
            .get(contactMethod)
            .setValidators([Validators.required, Validators.pattern(VALIDATOR_CONSTANT.EMAIL_PATTREN)])
        : this.form.get(contactMethod).setValidators([Validators.required, this.validatorService.phoneNumberValidator]);
      contactMethodList.push(new UntypedFormControl(contactMethod));
    } else {
      this.form.get(contactMethod).clearValidators();
      const index = contactMethodList.controls.findIndex((x) => x.value === contactMethod);
      contactMethodList.removeAt(index);
    }
    contactMethod === 'email'
      ? this.emailAddress.setValue(this.contactInfo?.customerEmail)
      : this.phoneNumber.setValue(this.contactInfo?.phone);
    this.form.get(contactMethod).updateValueAndValidity();
  }
  changePreferredTime(preferredTime: string): void {
    this.preferredTime = preferredTime;
  }
  get topic(): AbstractControl {
    return this.form.get('topic');
  }
  get message(): AbstractControl {
    return this.form.get('message');
  }
  get contactMethod(): AbstractControl {
    return this.form.get('contactMethod');
  }
  get companyName(): AbstractControl {
    return this.form.get('companyName');
  }
  get contactname(): AbstractControl {
    return this.form.get('contactname');
  }
  get emailAddress(): AbstractControl {
    return this.form.get('email');
  }
  get phoneNumber(): AbstractControl {
    return this.form.get('phoneNumber');
  }
  get termsConditions(): AbstractControl {
    return this.form.get('termsConditions');
  }

  TopicSelected(event: DropdownOptions): void {
    this.topic.setValue(event.text);
  }

  callMethod(methodName: string): void {
    this.contactUsService[methodName](this.contactInfo)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.thankYouModalOptions.open = true;
        document.body.classList.add('modal-open');
        this.resetContactUsForm();
        this.cd.detectChanges();
      });
  }

  onSubmit(): void {
    if (this.form.dirty && this.form.valid) {
      this.showInValidIcon = false;
      const contactData = this.form.getRawValue();
      this.contactInfo.reasonCode = contactData.topic;
      this.contactInfo.comments = contactData.message;
      contactData.contactMethod.forEach((type) => {
        if (type === ContactUs.Email) {
          this.contactInfo.preferredMethodEmail = 'Email';
          this.contactInfo.preferredMethodValEmail = contactData.email;
        }
        if (type === ContactUs.PhoneNumber) {
          this.contactInfo.preferredMethodPhone = 'Phone';
          this.contactInfo.preferredMethodValPhone = contactData.phoneNumber;
        }
      });
      this.contactInfo.preferredTime = contactData.preferredTime === ContactUs.Morning ? ContactUs.AM : ContactUs.PM;
      this.contactInfo.email = contactData.email;
      this.contactInfo.customerName = contactData.contactname;
      this.contactInfo.companyName = contactData.companyName;

      switch (this.configs.type) {
        case ContactUsType.contactUs: {
          this.callMethod('saveContactUsDetails');
          break;
        }
        case ContactUsType.accountCreation: {
          this.callMethod('requestAccountCreation');
          break;
        }
        case ContactUsType.requestDemo: {
          this.callMethod('requestDemo');
          break;
        }
        default: {
          this.showInValidIcon = true;
          this.resetDropDown = false;
          this.form.markAllAsTouched();
          this.cd.detectChanges();
        }
      }
    }
  }
  closeModal(): void {
    this.thankYouModalOptions.open = false;
    document.body.classList.remove('modal-open');
    this.closed.emit();
    this.resetContactUsForm();
  }
  resetContactUsForm(): void {
    this.topicList?.forEach((item) => (item.selected = false));
    this.resetDropDown = true;
    this.form.reset();
    this.assignContactusInfo();
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
