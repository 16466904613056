<div class="product-details__wrap">
  <div class="d-flex justify-content-between">
    <h2 class="product-details__productname">{{ (product | async)?.name }}</h2>
    <app-favorite-product
      class="product-details__favorite"
      [isFavourite]="(product | async)?.favourite"
      [productCode]="(product | async)?.code"
      [isWishList]="isWishList"
    ></app-favorite-product>
  </div>
  <span class="badge badge-private-label" *ngIf="(product | async)?.privateLabel">{{
    'productDetailsPage.privateLabel' | cxTranslate
  }}</span>
  <div class="product-details__products">
    <app-product-selector
      *ngIf="showPanel"
      (changeDtpError)="setDtpError($event)"
      [dtpError]="dtpError"
      [resetMaxQuantityError]="resetMaxQuantityError"
      [selectedProduct]="product | async"
      [isDTP]="isDTP$ | async"
      [orderTo]="orderTo"
      (selectedProductLensValue)="selectedProductLensValue($event)"
      [panelConfiguration]="panelConfiguration"
      [refreshProductPanel]="refreshProductPanel"
    ></app-product-selector>
  </div>
  <div class="product-details__row">
    <app-delivery-type [deliveryType]="orderTo" (changeDeliveryType)="orderToChange($event)"> </app-delivery-type>
  </div>
  <p class="errorDisplay errorDisplay--orange text-left mt-3 custom-left-margin" *ngIf="dtpError">
    {{ 'productDetailsPage.dtpSummaryError' | cxTranslate }}
  </p>
  <div>
    <app-patient-info-selection
      [reset]="resetPatientInfo"
      [isDTP]="orderTo === deliveryTypes.DIRECT_TO_PATIENT"
      (changeAddress)="changeAddress($event)"
      (addPatient)="addPatient($event)"
      (patientSelected)="patientSelected($event)"
      (hasAddress)="noAddress = $event"
    >
    </app-patient-info-selection>
  </div>
  <div class="Alcon-form__error-message text-center m-0" *ngIf="invalidPatientReference">
    <p>{{ 'productDetailsPage.errorMessage' | cxTranslate }}</p>
  </div>
</div>
<app-modal [modalOptions]="modalOptions" [showBigPopup]="true" (closeHandeller)="closeModal()">
  <div [ngClass]="{ 'modal-container': (patientInfo$ | async) }">
    <div *ngIf="patientInfo$ | async" class="product-modal__close">
      <cx-icon type="Close" class="close-icon cx-icon close" (click)="closeModal()"> </cx-icon>
    </div>
    <ng-container *ngIf="patientInfo$ | async as patientInfo">
      <h3 *ngIf="patientInfo" class="delivery-address-modal__header">
        {{ 'selectProductPage.patientOrderPanel.selectdeliveryAddress' | cxTranslate }}
      </h3>
      <app-select-delivery-address
        [patientInfo]="patientInfo"
        (saveAddressInfo)="saveAddressInfo($event)"
        (cancel)="closeAddressModal()"
      >
      </app-select-delivery-address>
    </ng-container>
    <ng-container *ngIf="createPatient$ | async">
      <app-add-new-patient
        [showAdditionalAddress]="true"
        (cancel)="closeAddPatientSection()"
        (patientAddedSuccessfully)="patientAddedSuccessfully($event)"
      >
      </app-add-new-patient>
    </ng-container>
  </div>
</app-modal>
