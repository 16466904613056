import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-cart-actions',
  templateUrl: './cart-actions.component.html',
  styleUrls: ['./cart-actions.component.scss'],
})
export class CartActionsComponent implements OnInit {
  constructor() {}

  @Input() cartEntry: any;
  @Input() disableEditButton = false;
  @Output() emitDeleteItem: EventEmitter<any> = new EventEmitter();
  @Output() emitUpdateItem: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {}

  deleteItem(value): void {
    this.emitDeleteItem.emit(value);
  }

  updateItem(value): void {
    this.emitUpdateItem.emit(value);
  }
}
