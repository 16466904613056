import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Category } from '@spartacus/core';
import { Observable, Subject } from 'rxjs';
import { filter, skip, startWith, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { ProductListService } from 'src/app/services/product-list-page/product-list.service';
import { PageType } from 'src/app/shared/model/common.mode';
import { CategoryPage } from 'src/app/shared/model/product.model';

declare module '@spartacus/core' {
  interface Product {
    category?: Category[];
    parentCategory?: string;
    childCategory?: string;
  }
}

@Component({
  selector: 'app-alcon-product-display-list',
  templateUrl: './alcon-product-display-list.component.html',
  styleUrls: ['./alcon-product-display-list.component.scss'],
})
export class AlconProductDisplayListComponent implements OnInit, OnDestroy {
  isLandingPage: boolean;
  isStockOrder = false;
  defaultProducts;
  private destroySubject = new Subject<void>();
  groupedProducts$: Observable<CategoryPage> = this.productListService.getGroupedProductsList().pipe(
    startWith(this.route?.snapshot?.data?.IndividualorStockOrder),
    tap((page: CategoryPage) => {
      this.isLandingPage = page.isLandingPage;
      if (!this.isLandingPage) {
        this.defaultProducts = 10;
      } else {
        this.defaultProducts = 5;
      }
    })
  );
  constructor(private productListService: ProductListService, private route: ActivatedRoute) {}
  ngOnInit(): void {
    this.route?.queryParams
      .pipe(
        skip(1),
        filter(({ query }) => !!query),
        withLatestFrom(this.route?.params),
        takeUntil(this.destroySubject)
      )
      .subscribe(([{ query }, { catid }]) => {
        query = query ? query : ':relevance:allCategories:' + catid;
        this.productListService.searchProductsList(query, this.route?.snapshot?.data?.Type);
      });
    this.route?.data
      .pipe(takeUntil(this.destroySubject))
      .subscribe((data) => (this.isStockOrder = data.Type === PageType.STOCK_ORDER));
  }
  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}
