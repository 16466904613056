class GetLocation {
    
  constructor(attribute) {
    
        this.attribute="all";
  }
 
     ipToLocation=async (param)=>{
    return await fetch('https://geolocation-db.com/json/'+param).then((response) => { 
             return response.json().then((data) => {
                 //console.log(data.country_name);
                 let responseVal="";
                 if(this.attribute!="all")
                    responseVal= data[this.attribute];
                 else
                    responseVal=JSON.stringify(data);
                return responseVal;
             }).catch((err) => {
                 console.log(err);
             }) 
         });
         
 }
  getResult =async()=>{
  let getIp= await fetch('https://api.ipify.org/?format=json').then((response) => { 
             return response.json().then((data) => {
                 return data.ip;
             }).catch((err) => {
                 console.log(err);
             }) 
         });
     let getLocation=await this.ipToLocation(getIp);
     return getLocation;
 }

 setOutput=async()=>{
    return await this.getResult();
 }
}

export default GetLocation;
