import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoutesService, TranslationService } from '@spartacus/core';
import { FacetService } from '@spartacus/storefront';
import { Observable, Subject, of } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { ProductListService } from 'src/app/services/product-list-page/product-list.service';

@Component({
  selector: 'app-brand-filter',
  templateUrl: './brand-filter.component.html',
  styleUrls: ['./brand-filter.component.scss'],
})
export class BrandFilterComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroySubscription$ = new Subject<void>();
  showClearAllFilter = false;
  firstRow = [];
  toggleText = this.translation.translate('productListingPage.moreFilter');
  showMoreFlag = true;
  checkBoxFacets = [];
  dropdownFacets = [];
  cxRoute: Observable<string> = of('');
  @ViewChild('brandForm') brandForm!: NgForm;
  imgValue = 'account-expand-icon';
  categories = [];
  facetSearchOptions$: Observable<any>;
  constructor(
    private productListService: ProductListService,
    private translation: TranslationService,
    private cd: ChangeDetectorRef,
    private router: Router,
    private activatedRoutesService: ActivatedRoutesService,
    private facetService: FacetService
  ) {}

  ngOnInit(): void {
    this.toggleText.pipe(takeUntil(this.destroySubscription$)).subscribe((data) => {});
    this.cxRoute = this.activatedRoutesService.routes$.pipe(
      map(([route1, route2]) => {
        return route2.data.cxRoute as string;
      })
    );
  }
  ngAfterViewInit(): void {
    this.productListService.facetSearchOptions.pipe(takeUntil(this.destroySubscription$)).subscribe((data) => {
      if (data.length > 0) {
        this.checkBoxFacets = data
          .filter((item) => item.isDropDown === false)
          .map((facet) => ({
            ...facet,
            translate: facet.code !== 'brand',
          }));
        this.dropdownFacets = [...data.filter((item) => item.isDropDown === true)];
        const updatedDropDownFacets = [];
        this.dropdownFacets.forEach((dropdownFacet) => {
          const newDDFacet = {
            category: dropdownFacet.category,
            isDropDown: dropdownFacet.isDropDown,
            multiSelect: dropdownFacet.multiSelect,
            name: dropdownFacet.name,
            code: dropdownFacet.code,
            priority: dropdownFacet.priority,
            topValueCount: dropdownFacet.topValueCount,
            visible: dropdownFacet.visible,
            values: [],
          };
          updatedDropDownFacets.push(newDDFacet);

          dropdownFacet.values.forEach((value) => {
            const newValue = {
              ...value,
              displayName: value.name.split('_').length > 1 ? value.name.split('_')[1] : '',
            };
            newDDFacet.values.push(newValue);
          });
        });
        this.dropdownFacets = updatedDropDownFacets;
        this.firstRow = this.dropdownFacets?.[0];
        if (window.location.search === '') {
          this.showClearAllFilter = false;
        } else if (window.location.search !== '?query=:name-asc:allCategories:Surgical%20Business') {
          this.showClearAllFilter = true;
        } else {
          this.showClearAllFilter = false;
        }
        this.dropdownFacets.shift();
        this.cd.detectChanges();
      }
    });

    this.productListService.categoriesFilter.pipe(takeUntil(this.destroySubscription$)).subscribe((data: any) => {
      if (data.length > 0) {
        this.categories = [...data];
      }
    });
  }

  getCategoryParams(categoryCode): any {
    return {
      query: ':relevance:allCategories:' + categoryCode,
    };
  }

  clearAllFilters(): void {
    this.productListService
      .getFacetLink()
      .pipe(take(1))
      .subscribe((route: any[]) => {
        this.router.navigate(route, {
          queryParams: this.facetService.getLinkParams(':name-asc:allCategories:Surgical+Business'),
        });
      });
  }

  ngOnDestroy(): void {
    this.destroySubscription$.next();
    this.destroySubscription$.complete();
  }
}
