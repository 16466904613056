import { Component, Input, OnInit } from '@angular/core';
import { ORDER_STATUS } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-cart-product-details',
  templateUrl: './cart-product-details.component.html',
  styleUrls: ['./cart-product-details.component.scss'],
})
export class CartProductDetailsComponent implements OnInit {
  specifications = ['BC', 'DIA', 'LP', 'ADD', 'CY', 'AX', 'Colour'];
  @Input() cartEntry = {
    showPower: false,
  } as any;
  @Input() status: string;
  updatedSpecificationValues = [];
  unShippedPowers = [];
  shippedPowers = [];
  statusList = ORDER_STATUS;
  constructor() {}
  ngOnInit(): void {
    this.cartEntry?.entries?.forEach((entry) => {
      if (entry.unShipped > 0 || !entry.unShipped) {
        this.unShippedPowers.push(
          entry?.specifications?.find((specifications) => specifications?.specificationKey === 'LP')?.specificationValue
        );
      }
      if (entry.shipped > 0) {
        this.shippedPowers.push(
          entry?.specifications?.find((specifications) => specifications?.specificationKey === 'LP')?.specificationValue
        );
      }
    });
    this.specifications.forEach((value) => {
      const specificationsArray = { key: '', value: '' };
      const specificationValue = this.cartEntry?.specifications?.find(
        (specifications) => specifications?.specificationKey === value
      );
      if (specificationValue) {
        specificationsArray.key = specificationValue?.specificationKey;
        specificationsArray.value = specificationValue?.specificationValue;
        this.updatedSpecificationValues.push(specificationsArray);
      }
    });
  }
}
