<ul *ngIf="(styleClass$ | async) === 'unknown'" class="navigationbar__wrapper">
  <li *ngFor="let menu of (node$ | async)?.children | navOptions; let i = index" class="navigationbar__item-hold">
    <ng-container *ngIf="menu?.external; else internalLink">
      <a
        class="navigationbar__parent-nav"
        *ngIf="!menu.children && menu.title"
        target="{{ menu?.target }}"
        href="{{ menu.url }}"
      >
        <icon *ngIf="menu.image" class="d-none">
          <cx-media [container]="menu.image" alt="{{ menu.title }}"></cx-media>
        </icon>
        <span>{{ menu.title }}</span>
      </a>
    </ng-container>
    <ng-template #internalLink>
      <a class="navigationbar__parent-nav" *ngIf="!menu.children && menu.title" [routerLink]="menu.url">
        <icon *ngIf="menu.image" class="d-none">
          <cx-media [container]="menu.image" alt="{{ menu.title }}"></cx-media>
        </icon>
        <span>{{ menu.title }}</span>
      </a>
    </ng-template>
    <a class="navigationbar__parent-nav" *ngIf="menu.children && menu.title">
      <icon *ngIf="menu.image" class="d-none">
        <cx-media [container]="menu.image" alt="{{ menu.title }}"></cx-media>
      </icon>
      <span>{{ menu.title }}</span>
    </a>
    <div class="navigationbar__background" *ngIf="menu.children && menu.title"></div>
    <ul class="navigationbar__wrapper navigationbar__submenu" *ngIf="menu.children && menu.title">
      <li *ngFor="let submenu of menu.children" class="navigationbar__submenu-items">
        <a
          *ngIf="submenu?.title === 'Invoices' ? showInvoiceOption : true"
          [routerLink]="submenu.url"
          routerLinkActive="active-link"
          class="navigationbar__child-nav"
        >
          <icon *ngIf="submenu.image">
            <cx-media [container]="submenu.image" alt="{{ submenu.title }}"></cx-media>
          </icon>
          <span class="pl-2">{{ submenu.title }}</span>
        </a>
      </li>
    </ul>
  </li>
</ul>

<ng-container *ngIf="node$ | async as node">
  <ng-container *ngIf="(styleClass$ | async) !== 'order-menu' && (styleClass$ | async) !== 'account-menu'">
    <ul class="navbar-nav mr-auto" [ngClass]="styleClass$ | async">
      <ng-container *ngFor="let menu of node.children; let i = index">
        <li class="nav-item" *ngIf="!menu.children && menu.title">
          <a class="nav-link" [routerLink]="menu.url">
            <icon *ngIf="menu.image">
              <cx-media [container]="menu.image" alt="{{ menu.title }}"></cx-media>
            </icon>
            <span>{{ menu.title }}</span>
          </a>
        </li>
        <li
          class="nav-item dropdown"
          appMenuDropdownShow
          ngbDropdown
          [autoClose]="true"
          #dp="ngbDropdown"
          *ngIf="menu.children && menu.title"
          [ngClass]="{ 'new-order': menu.title === NEW_ORDER }"
          (mouseenter)="onHover(dp)"
        >
          <div class="nav-link dropdown-toggle" id="id-{{ i }}" ngbDropdownToggle>
            <icon *ngIf="menu.image">
              <cx-media [container]="menu.image" alt="{{ menu.title }}"></cx-media>
            </icon>
            <span>{{ menu.title }}</span>
          </div>
          <div class="dropdown-menu" aria-labelledby="id-{{ i }}" ngbDropdownMenu>
            <ul>
              <li *ngFor="let submenu of menu.children">
                <a class="dropdown-item" href="" [routerLink]="submenu.url" routerLinkActive="active-link">
                  <icon *ngIf="submenu.image">
                    <cx-media [container]="submenu.image" alt="{{ submenu.title }}"></cx-media>
                  </icon>
                  <span>{{ submenu.title }}</span>
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ng-container>
    </ul>
  </ng-container>
  <ng-container *ngIf="(styleClass$ | async) === 'order-menu'">
    <div class="row justify-content-center">
      <ng-container *ngFor="let menu of node.children">
        <div class="col-6 col-sm-3 mb-4 flex-column" *ngIf="!menu.children && menu.title">
          <a [routerLink]="menu.url">
            <div class="icon" *ngIf="menu.image">
              <cx-media [container]="menu.image" alt="{{ menu.title }}"></cx-media>
            </div>
            <div class="title">{{ menu.title }}</div>
          </a>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="(styleClass$ | async) === 'account-menu'">
    <ul class="account">
      <ng-container *ngFor="let menu of node.children">
        <li *ngIf="!menu.children && menu.title">
          <a [routerLink]="menu.url">
            <span class="d-flex">
              <icon class="icon" *ngIf="menu.image">
                <cx-media [container]="menu.image" alt="{{ menu.title }}"></cx-media>
              </icon>
              <p class="mb-0 d-flex align-items-center">{{ menu.title }}</p>
            </span>
            <cx-icon class="arrow-icon" type="navArrowIcon"></cx-icon>
          </a>
        </li>
      </ng-container>
    </ul>
    <div class="account-details" *ngIf="useraccountInfo$ | async as accountDetail">
      <div class="account-details__bg-color">
        <app-header-account-selector
          [addressTitle]="'accountDetailsPage.alconAccount' | cxTranslate"
          [accountDetails]="accountDetail?.billToAddress"
          [isSoldto]="true"
        ></app-header-account-selector>

        <div class="account-details__divider">
          <app-header-account-selector
            [addressTitle]="'accountDetailsPage.shippingAccount' | cxTranslate"
            [accountDetails]="accountDetail?.shipToAddress"
            [isSoldto]="false"
          ></app-header-account-selector>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
