import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { GlobalMessageService, GlobalMessageType, TranslationService } from '@spartacus/core';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ProductDetailsService } from '../services/alcon-pdp/product-details.service';
import { ProductErrorsService } from '../services/product-errors.service';
import { ProductListService } from '../services/product-list-page/product-list.service';
import { DELIVERY_TYPE, GlobalMessage } from '../shared/constants/constants';
import { VisionCareAddtocartProductRequest } from '../store/States/cartItem.state';

@Component({
  selector: 'app-delivery-panel',
  templateUrl: './delivery-panel.component.html',
  styleUrls: ['./delivery-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DeliveryPanelComponent implements OnInit, OnDestroy {
  deliveryType;
  addToCartText: string;
  addingtoCartSuccessText: string;
  cartType: string;
  addToCartTextLoading: boolean;
  addToCartButtonText: string;
  $disable = this.productListService.disablePanel;
  selectedProduct: VisionCareAddtocartProductRequest[];
  patientData = { patientID: '', patientReference: '', addressType: true };
  resetPatientInfo: string;
  noAddress = false;
  get deliveryTypes(): typeof DELIVERY_TYPE {
    return DELIVERY_TYPE;
  }
  @Output() emitCloseModal: EventEmitter<any> = new EventEmitter();
  @Output() setToDefaults: EventEmitter<any> = new EventEmitter();
  @Output() changedDeliveryType: EventEmitter<string> = new EventEmitter();
  @Output() changeAddressTypeEmitter: EventEmitter<boolean> = new EventEmitter();

  orders: Array<VisionCareAddtocartProductRequest> = [];
  private destroy$ = new Subject<void>();
  @Input() dtpError: boolean;
  @Input() set selectedProductValue(value: Array<VisionCareAddtocartProductRequest>) {
    this.selectedProduct = value;
    this.validateAddToCart();
  }

  constructor(
    private productListService: ProductListService,
    private translation: TranslationService,
    private cd: ChangeDetectorRef,
    private globalMessageService: GlobalMessageService,
    private productErrorsService: ProductErrorsService,
    private productDetailService: ProductDetailsService
  ) {
    this.getTranslatedValues();
  }

  getTranslatedValues(): void {
    combineLatest([
      this.translation.translate('selectProductPage.patientOrderPanel.addToCart'),
      this.translation.translate('selectProductPage.patientOrderPanel.addToCartSuccess'),
    ])
      .pipe(
        map(([addToCartText, addToCartSuccessText]) => {
          return {
            addToCartText,
            addToCartSuccessText,
          };
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        this.addToCartText = data.addToCartText;
        this.addToCartButtonText = this.addToCartText;
        this.addingtoCartSuccessText = data.addToCartSuccessText;
      });
  }

  ngOnInit(): void {
    this.deliveryType = this.deliveryTypes.REGULAR;

    this.$disable.pipe(takeUntil(this.destroy$)).subscribe((disable) => {
      if (disable) {
        this.patientSelected({ addressType: true });
      } else {
        this.validateAddToCart();
      }
    });
  }

  deliveryTypeChanged(deliveryType: string): void {
    this.deliveryType = deliveryType;
    this.changedDeliveryType.emit(deliveryType);
    this.patientData = { patientID: '', patientReference: '', addressType: true };
    this.productDetailService.emitDTPFlag(deliveryType !== DELIVERY_TYPE.REGULAR);
    this.validateAddToCart();
  }

  private validateAddToCart(): void {
    const isInvalid = this.deliveryType === this.deliveryTypes.DIRECT_TO_PATIENT && !this.patientData.patientID;
    let order = [];
    if (!isInvalid && !this.noAddress) {
      order = this.getProduct();
    }
    this.orders = order;
  }

  private getProduct(): Array<VisionCareAddtocartProductRequest> {
    return [
      ...(this.selectedProduct?.map((product) => {
        return {
          ...product,
          patientName: this.patientData.patientReference,
          patientId: this.patientData.patientID,
          orderEntryFlowType: this.deliveryType,
          patientShippingAddressMain: this.patientData.addressType,
        };
      }) || []),
    ];
  }

  addingtocartLoad(loading): void {
    this.addToCartTextLoading = loading;
  }

  addingtoCartSuccess(): void {
    this.resetPatientInfo = Math.random.toString();
    this.addToCartTextLoading = false;
    this.addToCartButtonText = this.addingtoCartSuccessText;
    this.deliveryType = DELIVERY_TYPE.REGULAR;
    this.setToDefaults.emit(true);
    setTimeout(() => {
      this.addToCartButtonText = this.addToCartText;
      this.cartType = '';
      this.emitCloseModal.emit();
      this.cd.detectChanges();
    }, 3000);
  }

  cartError(): void {}
  emitErrorMessage(errorMsg): void {
    const { message = '', reason = '' } = errorMsg.error.errors[0];
    if (message && reason === 'CartMaxProductQuantityExceedException') {
      errorMsg.error.errors.forEach((err) => {
        this.productErrorsService.addProductError(err);
      });
    } else {
      this.globalMessageService.add(message, GlobalMessageType.MSG_TYPE_ERROR, GlobalMessage.TIMEOUT);
      this.setToDefaults.emit('');
      this.addToCartTextLoading = false;
    }
  }

  patientSelected(patientData): void {
    this.changeAddressTypeEmitter.emit(patientData.addressType);
    this.patientData = patientData;
    this.validateAddToCart();
  }

  changeAddress(patientID: string): void {
    this.productListService.setPatientData(patientID);
    this.productListService.patientCreated.next(patientID);
  }

  addPatient(buttonId: string): void {
    this.productListService.addOption(buttonId);
  }

  closeModal(): void {
    this.emitCloseModal.emit('');
  }
  ngOnDestroy(): void {
    this.productListService.patientCreated.next('');
    this.destroy$.next();
    this.destroy$.complete();
  }
}
