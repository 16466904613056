import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { DIRECT_TO_PRACTICE, Specifications } from 'src/app/shared/constants/constants';
import { PatientOrderData } from 'src/app/store/States/orderHistory';

@Injectable({
  providedIn: 'root',
})
export class OrderHistoryPatientListService {
  constructor(private datePipe: DatePipe) {}

  createpatientListData(list: any, orderObject: PatientOrderData, orderArray: any[], patientId: string): Array<object> {
    list.forEach((element) => {
      orderObject = {} as PatientOrderData;
      orderObject.productDetails = [];

      if (element?.replenishmentOrderCode) {
        orderObject.orderPlaced = this.splitDate(element.trigger.creationTime);
        orderObject.nextOrder = this.splitDate(element.trigger.activationTime);
        orderObject.occurance = element.trigger.displayTimeTable;
        orderObject.replenishmentOrderCode = element.replenishmentOrderCode;
        orderObject.isScheduledOrder = true;
        this.setToggleConfig(orderObject, element.active);
      } else {
        orderObject.orderPlaced = this.splitDate(element.created);
        orderObject.orderNo = element.code;
        orderObject.isScheduledOrder = false;
      }

      if (element?.entries) {
        if (element?.entries[0]?.groupType !== 'DIRECT_TO_PATIENT') {
          orderObject.orderType = DIRECT_TO_PRACTICE.practiceOrder;
        } else {
          orderObject.orderType = DIRECT_TO_PRACTICE.directToPatient;

          orderObject = { ...this.addShippingAddress(element, orderObject) };
        }
        orderObject = this.getProductDetails(element, orderObject, patientId);
        orderArray.push({ ...orderObject });
      }
    });
    orderArray = orderArray.filter((entry) => entry.productDetails.length > 0);
    return orderArray;
  }

  getProductDetails(element: any, orderObject: PatientOrderData, patientId): PatientOrderData {
    orderObject.productDetails = [];
    // entries might include different/no  patient reference orders
    if (element.entries.length > 0) {
      element.entries.forEach((entry, index) => {
        entry?.specifications?.sort(
          (entry1, entry2) =>
            Specifications.indexOf(entry1.specificationKey) - Specifications.indexOf(entry2.specificationKey)
        );
        orderObject.productDetails.push({
          name: entry?.product?.baseProduct,
          specifications: entry?.specifications,
          quantity: entry?.quantity,
          productCode: entry?.product?.baseProduct,
          code: entry?.product?.code,
          packsize: entry?.product?.packSize,
          isDryEye: entry?.product?.isDryEyeSolution,
          isClc: entry?.product?.isLensCareSolution,
          orderEntryFlowType: entry?.orderEntryFlowType,
          patientId: entry?.patientId,
          entryNumber: entry?.entryNumber,
          discontinued: entry?.product?.discontinued,
        });
        orderObject.isValid =
          orderObject.isValid ?? (entry?.product?.baseProduct && entry?.specifications) ? true : false;
        if (entry?.eyeSight) {
          orderObject.productDetails[index].eyeSight = entry.eyeSight === 'RIGHT' ? 'R' : 'L';
        }
        if (orderObject.productDetails[index].orderEntryFlowType.includes('VALUE_PACK')) {
          orderObject.valuePackDuration = entry?.valuePackAttributes?.valuePackDuration + '-months pack';
          orderObject.packType = DIRECT_TO_PRACTICE.valuePacks;
        } else {
          const saleCount = entry.salesUnitCount ?? 1;
          const packSize = entry?.product?.packSize ?? 1;
          orderObject.productDetails[index].salesUnitCount = saleCount * packSize;
          orderObject.productDetails[index].salesUnitName = entry?.salesUnitName;
          orderObject.packType = DIRECT_TO_PRACTICE.regular;
        }
      });
    }
    return orderObject;
  }
  /**
   * splits date from date and time
   * @param value input date and time
   */
  splitDate(value: string): string {
    return typeof value === 'string' ? this.datePipe.transform(value.split('T')[0], 'dd/MM/yyyy') : '';
  }

  /**
   * Creates toggle configuration i.e labels and status
   *
   */
  setToggleConfig(orderObject: PatientOrderData, orderStatus: boolean): void {
    orderObject.toggleConfig = { status: orderStatus, onLabel: 'ACTIVE', offLabel: 'INACTIVE' };
  }
  /**
   * adds shipping address to the object incase of DTP
   * @param children holds last level of entries[0] object
   * @param orderObject holds object which will be used in UI
   */
  addShippingAddress(children: { shippingAddress: any }, orderObject: PatientOrderData): PatientOrderData {
    if ('shippingAddress' in children) {
      orderObject.defaultAddress = children.shippingAddress.defaultAddress;
      orderObject.address = children.shippingAddress;
      return orderObject;
    } else {
      return orderObject;
    }
  }
}
