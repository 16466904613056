import { AfterViewInit, Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SemanticPathService, TranslationService, WindowRef } from '@spartacus/core';
import { forkJoin, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { OktaService } from 'src/app/services/okta/okta.service';
import { UpdateUserProfileService } from 'src/app/services/updateuserprofile/update-user-profile.service';
import AxonSignIn from 'src/axon-signin-widget/dist/js/okta-sign-in.min.js';

@Component({
  selector: 'app-surgical-register-page',
  templateUrl: './surgical-register-page.component.html',
  styleUrls: ['./surgical-register-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SurgicalRegisterPageComponent implements AfterViewInit, OnDestroy {
  public titles$ = this.updateService.titles$.pipe(
    map((titles) =>
      titles.map((title) => ({
        const: title.code,
        title: title.name,
      }))
    )
  );
  titles: any;
  labelsData: any;
  private destroy$ = new Subject<void>();
  constructor(
    private oktaService: OktaService,
    protected winRef: WindowRef,
    private router: Router,
    private semanticPathService: SemanticPathService,
    private translation: TranslationService,
    public updateService: UpdateUserProfileService
  ) {}
  ngAfterViewInit(): void {
    this.titles$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.titles = res;
      this.getTranslatedValues();
    });
  }
  getTranslatedValues(): void {
    forkJoin([
      this.translation.translate('sxRegistration.email').pipe(take(1)),
      this.translation.translate('sxRegistration.cancel').pipe(take(1)),
      this.translation.translate('sxRegistration.next').pipe(take(1)),
      this.translation.translate('sxRegistration.emailVal').pipe(take(1)),
      this.translation.translate('sxRegistration.title').pipe(take(1)),
      this.translation.translate('sxRegistration.passwordVal').pipe(take(1)),
      this.translation.translate('sxRegistration.mandatory').pipe(take(1)),
      this.translation.translate('sxRegistration.login').pipe(take(1)),
      this.translation.translate('sxRegistration.invalidEmail').pipe(take(1)),
      this.translation.translate('sxRegistration.emailExist').pipe(take(1)),
      this.translation.translate('sxRegistration.phonelong').pipe(take(1)),
      this.translation.translate('sxRegistration.phoneshort').pipe(take(1)),
      this.translation.translate('sxRegistration.select').pipe(take(1)),
      this.translation.translate('sxRegistration.consentLinks.termsCondition').pipe(take(1)),
      this.translation.translate('sxRegistration.consentLinks.privacyPolicy').pipe(take(1)),
      this.translation.translate('sxRegistration.consentLinks.marketingPrivacyPolicy').pipe(take(1)),
    ])
      .pipe(take(1))
      .subscribe((data) => {
        this.labelsData = data;
        if (this.titles?.length > 0 && this.labelsData) {
          this.titles.unshift({ const: '', title: this.labelsData[12] });
          this.loadAxonWidget();
        }
      });
  }

  loadAxonWidget(): void {
    const config = this.oktaService.getOktaConfig(this.titles, this.labelsData);
    config.i18n.en = {
      'primaryauth.title': ' ',
      'primaryauth.username.placeholder': this.labelsData[0],
      'primaryauth.submit': this.labelsData[7],
      'error.username.required': this.labelsData[3],
      'error.password.required': this.labelsData[5],
      'password.forgot.email.or.username.placeholder': this.labelsData[0],
      'password.forgot.email.or.username.tooltip': this.labelsData[0],
      goback: this.labelsData[1],
      'registration.form.submit': this.labelsData[2],
      'registration.form.title': this.labelsData[4],
      'registration.required.fields.label': this.labelsData[6],
      'enroll.choices.list.setup': ' ',
      'enroll.choices.submit.configure': this.labelsData[2],
      'registration.error.userName.notUniqueWithinOrg': this.labelsData[9],
      'model.validation.field.invalid.format.email': this.labelsData[8],
      'model.validation.field.phone.numberIsTooLong': this.labelsData[10],
      'model.validation.field.phone.numberIsTooShort': this.labelsData[11],
    };
    const registerWidget = new AxonSignIn(config);
    const registerWrapper = this.winRef.document.getElementById('registerWrapper');

    // initially removing the element from DOM
    registerWidget.remove();
    registerWidget.on('afterRender', (context) => {
      if (context.controller !== 'registration') {
        return;
      }
      const cancelLink = this.winRef.document.querySelector('.help[data-se="back-link"]');
      cancelLink?.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.router.navigate(
          this.semanticPathService.transform({
            cxRoute: 'login',
          })
        );
      });
    });
    registerWidget.on('afterError', (context, error) => {
      if (context.controller !== 'registration') {
        return;
      }
      window.scroll(0, 0);
    });
    registerWidget
      .showSignInToGetTokens({
        el: registerWrapper,
      })
      .then((response) => {
        if (response) {
          this.router.navigate(
            this.semanticPathService.transform({
              cxRoute: 'registerSuccess',
            })
          );
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
