export const itIT = {
  contactlenses: {
    contactlenses: 'Lente a contatto',
  },
  dailycontactlenses: {
    dailycontactlenses: 'Lenti a contatto giornaliere',
  },
  monthlycontactlenses: {
    monthlycontactlenses: 'Lenti a contatto mensili',
  },
  dryeyeproducts: {
    dryeyeproducts: 'Prodotti per occhi secchi',
  },
  contactlenssolutions: {
    contactlenssolutions: 'Soluzioni per lenti a contatto',
  },
  contactlenssolution: {
    contactlenssolution: 'Soluzioni per lenti a contatto',
  },
  multifocal: {
    multifocal: 'Multifocal',
  },
  colour: {
    colour: 'Colore',
  },
  color: {
    color: 'Colore',
  },
  spherical: {
    spherical: 'Sferico',
  },
  toric: {
    toric: 'Toric',
  },

  common: {
    common: {
      home: 'Home',
    },
  },
  cms: {
    login: {
      backToLogin: 'Torna all’accesso ',
      backToLoginPage: 'Torna alla pagina d’accesso',
      emailAddress: 'Indirizzo e-mail',
      emailPlaceholer: 'Il tuo indirizzo e-mail',
      emailSentConfirmText: 'Abbiamo inviato un’e-mail con le istruzioni per reimpostare la password.',
      forgotLink: 'Password dimenticata?',
      forgotPwdText: 'Inserisci il tuo indirizzo e-mail e ti invieremo un link per cambiare la password:',
      logIn: 'accedi',
      passwordLbl: 'Password',
      requireEmail: 'Inserisci un’e-mail valida',
      requirePassword: 'Inserisci la tua password',
      sendEmail: 'Invia',
      forgotYourPwd: 'Password dimenticata?',
    },
    productListingPage: {
      moreFilter: 'Più filtri',
      lessFilter: 'Meno filtri',
      product: 'prodotto',
      seeAll: 'Vedi tutto',
      select: 'Seleziona',
      showMore: 'Mostra di più',
      showLess: 'Mostra meno',
      sortBy: 'Ordina per',
      results: 'Risultati',
      products: 'prodotti',
      nameAZ: 'Nomi A-Z',
      price: 'Prezzo',
      oxygenPermeability: 'Permeabilità all’ossigeno',
      brand: 'Marchio',
      categories: 'Categorie',
      showAll: 'Mostra tutto',
      configureProducts: 'Configura prodotti',
      valuepack: 'Confezioni risparmio',
    },
    productPage: {
      productDescription: 'Descrizione prodotto',
      productSpecification: 'Specifiche prodotto',
      features: 'Caratteristiche',
      benefits: 'Benefici',
      downloads: 'Download:',
    },
    stockOrderProductListPage: {
      from: 'da',
    },
    productDetailsPage: {
      privateLabel: 'Etichetta privata',
      patientReference: 'Riferimento paziente:',
      parametersFor: 'Parametri per:',
      left: 'Sinistra',
      right: 'Destra',
      baseCurve: 'Curvatura di base:*',
      power: 'Potere:*',
      add: 'Addizione*:',
      colour: 'Colore*:',
      cylinder: 'Cilindro*:',
      axis: 'Asse*:',
      sameForBothEye: 'Uguale per entrambi gli occhi',
      packSize: 'Dimensioni confezione:',
      practiceOrder: 'Ordine ambulatorio',
      individualOrders: 'Ordini individuali',
      dtpError: 'Questo prodotto può essere consegnato solo in ambulatorio.',
      dtpSummaryError: 'Questo ordine può essere consegnato solo in ambulatorio.',
      addressError: 'Fornisci l’indirizzo corretto',
      directToPatient: 'Diretto al paziente',
      errorMessage: 'Non puoi digitare più di 18 caratteri.',
      quantityErrorMsg: 'La quantità max. acquistabile per questo articolo è',
      PurchaseStockOrder: 'Acquisto in ordine di riserva',
      perEye: 'per occhio',
    },
    practiceOrderPage: {
      patientReference: 'Riferimento paziente:',
      parametersFor: 'Parametri per:',
      left: 'Occhio sinistro',
      right: 'Occhio destro',
      baseCurve: 'Curvatura di base:',
      power: 'Potere:*',
      add: 'Addizione:*',
      colour: 'Colore:*',
      cylinder: 'Cilindro:*',
      axis: 'Asse:*',
      sameForBothEye: 'Uguale per entrambi gli occhi',
      packSize: 'Dimensioni confezione:',
      practiceOrder: 'Ordine ambulatorio',
      individualOrders: 'Ordini individuali',
      diameter: 'Diametro:',
      typeOfWear: 'Tipo d’uso:',
      material: 'Materiale:',
      waterContent: 'Contenuto di acqua:',
      schedule: 'Programma ordine ricorrente',
      prefer: 'Preferisci una confezione risparmio?',
      errorMessage: 'Troppi articoli selezionati. Riduci la quantità a un massimo di',
      LEFT: 'Occhio sinistro',
      RIGHT: 'Occhio destro',
      update: 'AGGIORNA',
      cancel: 'Annulla',
      dtpLimitMessage: 'La quantità max. per la consegna Diretto al paziente è',
    },
    favoriteListPage: {
      yourFavProduct: 'I tuoi prodotti preferiti',
      products: 'prodotti',
      product: 'prodotto',
      noFavProduct:
        'Attualmente non hai indicato nessun prodotto preferito. Per aggiungere i prodotti alla lista dei preferiti, clicca sull’icona del cuore accanto al prodotto',
    },
    legalAnnouncement: {
      accept: 'ACCETTA',
      decline: 'RIFIUTA',
    },
    practicePage: {
      mostFrequentOrders: 'Ordini più frequenti',
    },
    stockOrderPage: {
      configureProducts: 'Configura prodotto',
      packSize: 'Dimensioni confezione:*',
      baseCurve: 'Curvatura di base:',
      diameter: 'Diametro:',
      color: 'Colore:',
      add: 'Addizione:',
      cancel: 'Annulla',
      lenscylinder: 'Cilindro delle lenti:*',
      moreMinus: 'Più potenze negative (-)',
      lessMinus: 'Meno potenze negative (-)',
      morePlus: 'Più potenze positive (+)',
      lessPlus: 'Meno potenze positive (+)',
      clearAll: 'Cancella tutto',
      addToCart: 'AGGIUNGI AL CARRELLO',
      addToCartLoading: 'AGGIUNTA AL CARRELLO IN CORSO',
      addToCartSuccess: 'AGGIUNTO AL CARRELLO',
      updateToCartText: 'AGGIORNA',
      updatingCart: 'Carrello in aggiornamento',
      updateToCartSuccess: 'Carrello aggiornato',
      maxQty: 'La quantità totale max. per questo prodotto è',
      errorMessage: 'Troppi articoli selezionati. Riduci la quantità a un massimo di',
      inputMessageError: 'La quantità deve essere un numero maggiore di 0',
      each: 'ciascuno',
      forthisbrand: 'per questo marchio',
    },
    miniCartPage: {
      PRACTICE_ORDER: 'Ordini ambulatorio',
      INDIVIDUAL_ORDER: 'Ordini individuali',
      VALUE_PACK: 'Confezione risparmio',
      DTP_VALUE_PACK: 'Confezione risparmio',
      DIRECT_TO_PATIENT: 'Diretto al paziente',
      STOCK_ORDER: 'Ordine di riserva',
      title: 'Il tuo carrello',
      RIGHT: 'Destra',
      LEFT: 'Sinistra',
      total: 'Totale',
      delete: 'Cancella',
      gotocart: 'Vai al Carrello',
      moreitem: 'più articoli',
      totalprice: '£00.00',
      yourcartIsEmpty: 'Il tuo carrello è vuoto',
      goTo: 'Vai a',
      productCatalogue: 'Catalogo prodotti',
      orClick: 'o clicca',
      orClickthe: 'o clicca sul',
      newOrder: 'Nuovo ordine',
      editOrder: 'Modifica ordine',
      toAddItems: 'per aggiungere articoli',
      edit: 'Modifica',
      patientReference: 'Riferimento paziente',
      quantity: 'Quantità',
      YouHaveNoProductsInYourCart: 'Non ci sono prodotti nel tuo carrello',
      seeProduct: 'Vedi prodotti',
      hideProduct: 'Nascondi prodotti',
      deliveryMethod: 'Modalità di consegna:',
      poNumber: 'Numero ordine di acquisto:',
      orderNumber: 'Numero ordine:',
      LP: 'POT',
      AX: 'ASS',
      CY: 'CIL',
      Colour: 'Colore',
      DIA: 'DIA',
      BC: 'CURV',
      ADD: 'ADD',
      PatientReference: 'Riferimento paziente',
      PowerShipped: 'potenze consegnate',
      PowersInProgress: 'potenze in corso',
      cartMessage1: 'Non hai prodotti aggiunti al carrello.',
      cartMessage2: 'Usa uno dei seguenti metodi per aggiungere i prodotti al carrello. ',
      cartMessage:
        'Non hai prodotti aggiunti al carrello. Usa uno dei seguenti metodi per aggiungere i prodotti al carrello. ',
      valuePack: 'Confezione risparmio:',
      valuePacks: 'Confezioni risparmio',
      valuePackDuration: 'Confezione risparmio (',
      valuePackMonth: '-mesi)',
      moreitems: 'più articoli',
    },
    cartPagev2: {
      INDIVIDUAL_ORDER: 'Ordini individuali',
      VALUE_PACK: 'Confezioni risparmio',
      STOCK_ORDER: 'Ordini di riserva',
      DIRECT_TO_PATIENT: 'Diretto ai pazienti',
      orderAndPay: 'ORDINA E PAGA',
      simulation: {
        errorMessage:
          'Il prezzo finale non può essere calcolato in questa fase. Quando il problema sarà risolto, potrai visualizzare i prezzi nella cronologia degli ordini e nelle fatture.',
      },
      disclaimer:
        'Cliccando su ORDINA E PAGA 1) riconosci e accetti i termini e le condizioni di MyAlcon con cui hai concordato al momento dell’inserimento dei tuoi dati per usare questo sistema e 2) confermi che in caso di ordine diretto al paziente a) il paziente ha almeno 16 anni di età e b) hai comunicato al paziente che le sue informazioni saranno condivise via MyAlcon e ne hai ottenuto le eventuali autorizzazioni necessarie.',
      deliveryCost: 'Costo della consegna',
      taxes: 'Imposte',
      total: 'Totale',
    },
    stockToricPage: {
      lensPower: 'Potere delle lenti',
      lensAxis: 'Asse delle lenti',
      message: 'Seleziona il potere per vedere l’asse delle lenti corrispondente',
    },
    cartPage: {
      PRACTICE_ORDER: 'Ordini ambulatorio',
      summary: 'Riepilogo',
      goToCheckout: 'VAI AL PAGAMENTO',
      ShippingAddress: 'Indirizzo di spedizione',
      DeliveryMethod: 'Modalità di consegna',
      calcFinalPrice: 'CALCOLA IL PREZZO FINALE',
      DIRECT_TO_PATIENT: 'Diretto al paziente',
      deliveryMethod: 'Modalità di consegna:',
      deliveryMethodDetails: 'Consegna standard (2-3 giorni lavorativi)',
      IndividualOrders: 'Ordini individuali',
      StockOrders: 'Ordini di riserva',
      ValuePacks: 'Confezioni risparmio',
      DirecttoPatient: 'Diretto ai pazienti',
      products: 'Prodotti',
      shippingsuggestion: 'La spedizione sarà calcolata al momento del pagamento.',
      patients: 'pazienti',
      externalOrderMsg: 'Il prezzo finale sarà comunicato sulla fattura',
      cancelOrder: 'ANNULLA ORDINE',
    },
    orderConfirmation: {
      orderNumber: 'Numero ordine:',
      shippingAddress: 'Indirizzo di spedizione:',
      deliveryMethod: 'Modalità di consegna:',
      deliveryMethodDetails: 'Consegna postale standard',
      poNumber: 'Numero ordine di acquisto:',
      valuePackQuantity: '1 articolo',
    },
    checkoutPage: {
      paymentMethod: 'Metodo di pagamento',
      paymentType1: 'Carta di credito',
      paymentType2: 'Fattura da contabilizzare',
      valuePackQuantity: '1 articolo',
      poNumber: 'Numero ordine di acquisto:',
      LP: 'Potere',
      AX: 'Asse',
      CY: 'Cilindro',
      Colour: 'Colore',
      DIA: 'DIA',
      BC: 'CURV',
      ADD: 'ADD',
      print: 'Stampa',
      finalPrice: {
        products: 'Prodotti',
        delivery: 'Consegna',
        taxes: 'Imposte',
        total: 'TOTALE',
        placeOrder: 'Effettua ordine',
        obligationText: 'con obbligo di pagamento',
        estimatedDelivery: 'Tempo di consegna stimato:',
        loaderMessage: 'Dacci un momento, stiamo raccogliendo i dati',
      },
      delivery: 'Consegna:',
      practiceOrders: 'Ordini ambulatorio ',
      practiceOrdersPanel: 'Ordini individuali, confezioni risparmio, ordini di riserva',
      directtoPatients: 'Diretto ai pazienti ',
      seeAll: 'Vedi tutto',
      patients: 'pazienti',
      addNewCardHeading: 'Aggiungi una nuova carta',
      setDefaultCard: 'Imposta questa carta come predefinita',
      selectPaymentCard: 'Seleziona carta di credito',
      selectCardtitle: 'Seleziona una carta di credito dalle carte salvate o aggiungi una nuova carta.',
    },
    myPatientsPage: {
      addItems: '+ AGGIUNGI PAZIENTE',
      addNewPatient: {
        heading: 'Aggiungi un nuovo paziente',
        editHeading: 'Modifica informazioni sul paziente',
        personalData: 'Dati personali',
        mainAddress: 'Indirizzo principale',
        additionalAddress: 'Indirizzo supplementare',
        form: {
          title: 'Titolo',
          firstName: 'Nome *',
          surName: 'Cognome *',
          email: 'Indirizzo e-mail',
          phoneNumber: 'Numero di telefono',
          referenceNumber: 'Numero di riferimento',
          address: {
            street: 'Numero e via *',
            building: 'Palazzo / appartamento / altro',
            postcode: 'CAP *',
            city: 'Città *',
            county: 'Provincia',
            notMandatoryCounty: 'Provincia',
            country: 'Paese *',
          },
          mandatoryFields: '* Campi obbligatori',
          additionalAddress: 'Aggiungi indirizzo supplementare',
          deleteadditionaladdress: 'Cancella indirizzo supplementare',
          clearAll: 'Cancella tutto',
          save: 'salva',
          cancel: 'Annulla',
          update: 'Aggiorna',
          errorMessages: {
            title: 'Seleziona un titolo.',
            mandatoryField: 'Questo campo è obbligatorio.',
            firstName: `Inserisci il nome del paziente.`,
            surName: `Inserisci il cognome del paziente.`,
            email: 'Il formato dell’e-mail non è corretto. Usa il seguente formato: john@gmail.com',
            phoneNumber: 'Questo campo non accetta le lettere.',
            phoneNumberMinLength:
              'Il numero di telefono è troppo corto.  Verifica di avere inserito tutti i caratteri.',
            moreThanThirtyCharacters: 'Non puoi digitare più di 30 caratteri in questo campo.',
            street: `Inserisci il numero civico del paziente.`,
            buildingNumberName: `Inserisci il numero del palazzo del paziente.`,
            postcode: `Inserisci il CAP del paziente.`,
            city: `Inserisci la città del paziente.`,
            county: `Inserisci la provincia del paziente.`,
            country: `Inserisci il Paese del paziente.`,
            invalidPattren: 'CAP non valido',
            selectCountry: 'Seleziona un Paese',
          },
        },
        patientAddedSuccessfully: 'Il paziente è stato aggiunto con successo',
      },
      address: 'Indirizzo',
      showMore: 'Mostra di più',
      showLess: 'Mostra meno',
      mostRecentOrders: 'Ordini più recenti',
      order: 'Ordine',
      delivery: 'Consegna',
      shippingAddress: 'Indirizzo di spedizione',
      status: 'Stato',
      packtype: 'Tipo di confezione',
      date: 'Data',
      frequency: 'Frequenza',
      addOrder: 'Aggiungi ordine',
      addOrderButton: '+ AGGIUNGI ORDINE',
      options: 'Opzioni',
      active: 'attivo',
      inactive: 'inattivo',
      switchtoSubscription: 'Passa all’abbonamento',
      sort: 'Ordina:',
      orderHistory: 'Cronologia ordini',
      editPatient: 'Modifica paziente',
      deletePatient: 'Cancella paziente',
      search: 'Cerca nome paziente, n° di riferimento',
      noPatientFound: 'Non hai ancora pazienti. Aggiungi il tuo primo paziente cliccando il bottone qui sotto.',
      searchNoPatient: 'Nessun paziente trovato. Verifica di avere digitato correttamente.',
      top: 'Inizio',
    },
    patientProfilePage: {
      personalData: 'Dati personali',
      mainAddress: 'Indirizzo principale',
      additionalAddress: 'Indirizzo supplementare',
      edit: 'Modifica',
      tel: 'Tel: ',
      eMail: 'E-mail: ',
      refNumber: 'N° riferimento: ',
      addOrder: '+ AGGIUNGI ORDINE',
      noOrder: 'Non ci sono ancora ordini',
    },
    orderHistoryPage: {
      orderPlaced: 'Ordine effettuato',
      orderNo: 'Ordine n°',
      type: 'Tipo',
      totalPrice: 'Prezzo totale',
      status: 'Stato',
      invoice: 'Fattura',
      search: 'Cerca numero ordine o numero ordine di acquisto',
      searchButton: 'cerca',
      timePeriod: 'Periodo di tempo:',
      dateRange: 'Intervallo di date:',
      selectDate: 'Seleziona data/e',
      sort: 'Ordina:',
      reorder: 'RIORDINA',
      viewOrderdetails: 'Visualizza dettagli ordine',
      noOrders: 'Non hai ancora ordinato nessun prodotto.',
      noOrderText: 'Quando effettuerai un ordine, potrai vederne lo stato e i dettagli qui.',
      filter: {
        ALL: 'Tutti',
        ACTIVE: 'Attivo',
        COMPLETED: 'Completato',
        STATUS: 'Stato',
        filterPlus: '+ filtri',
        filterMinus: '- filtri',
        orderStatus: {
          NEW: 'Nuovo',
          IN_PROGRESS: 'In corso',
          DELAYED: 'In ritardo',
          SHIPPED: 'Spedito',
          CANCELLED: 'Cancellato',
          PARTIALLY_SHIPPED: 'Parzialmente spedito',
          DELIVERED: 'Consegnato',
        },
      },
      showMore: 'MOSTRA ALTRI 10 SU',
      noResults: 'Nessun ordine trovato. Verifica di aver digitato il numero corretto o prova con un altro.',
      noResultsFilter: 'Nessun ordine trovato con i criteri di filtro specificati',
      cancel: 'ANNULLA',
    },
    baseLoginPage: {
      youWereLoggedOut: 'Sei stato disconnesso',
      logoutMsg:
        'La tua sessione è scaduta. Per la tua sicurezza e quella dei tuoi pazienti, sei stato disconnesso automaticamente.',
      welcome: 'Benvenuto/a',
      backToLogin: 'Torna alla pagina d’accesso',
    },
    sxRegistration: {
      consentLinks: {
        termsCondition: 'https://www.au.alcon.com/terms-use',
        privacyPolicy: 'https://www.au.alcon.com/privacy-policy',
        marketingPrivacyPolicy: 'https://www.au.alcon.com/privacy-policy',
      },
    },
    orderStatus: {
      NEW: 'Nuovo',
      IN_PROGRESS: 'In corso',
      DELAYED: 'In ritardo',
      SHIPPED: 'Spedito',
      CANCELLED: 'Cancellato',
      PARTIALLY_SHIPPED: 'Parzialmente spedito',
      PENDING_CONSOLIDATION: 'Ordine da maturare in attesa',
      DELIVERED: 'Consegnato',
      NOT_SHOWN: 'Non_mostrato',
      BILLED: 'Fatturato',
    },
    confirmEmail: {
      success: 'Indirizzo e-mail confermato. Il nostro responsabile vendite ti contatterà a breve.',
      failure: 'Link scaduto. Riempi di nuovo il modulo di registrazione.',
    },
    registerPage: {
      registrationFormTitle:
        'Apri un account per ottenere un accesso permanente agli ordini online, alle fatture, agli estratti conto, alla tracciabilità degli ordini e ad altre funzioni di gestione dell’account.',
      imAlconCustomer: 'Sono un cliente Alcon',
      iDoNotHaveAnAccountTitle: 'Non ho ancora un account Alcon',
      iDoNotHaveAnAccountDescription:
        'Nessun problema! Puoi lasciarci le tue coordinate e un responsabile vendite di Alcon ti contatterà in breve!',
      termsAndConditions: 'Termini e condizioni',
      consent: 'Acconsenti',
      termsAndConditionTitle: 'Registrazione all’e-commerce di Alcon',
      termsAndConditionLabel: `Ho preso visione e acconsento ai termini e alle condizioni descritte nelle <a src="#">Condizioni generali di vendita di Alcon Eye Care UK Limited.</a>*`,
      termsAndConditionDisclaimer: `Alcon gestirà sempre i tuoi dati personali nel rispetto di tutte le normative in vigore. </br></br>Alcon fa parte del gruppo mondiale di società Alcon Group e si avvale di database presenti in diversi Paesi. Alcuni di questi sono diretti da soci locali di Alcon, mentre altri sono diretti da terzi, per conto dei soci locali di Alcon. Alcon può trasmettere i tuoi dati a uno o più dei database soci fuori dal Paese in cui risiedi, anche in Paesi che potrebbero non dotarsi di livelli di protezione dei dati personali adeguati rispetto agli standard britannici o europei.</br></br>Per nessun motivo Alcon venderà i tuoi dati personali a terzi.</br></br>Se desideri contattarci in merito al nostro uso dei tuoi dati personali, per cambiare le tue preferenze o per opporti all’elaborazione dei tuoi dati personali, manda un’e-mail all’indirizzo privacy.uk@alcon.com.`,
      setUpAnAccount: 'Configura un account',
      title: 'Titolo',
      firstName: 'Nome*',
      lastName: 'Cognome*',
      fullCompanyName: 'Nome completo della società*',
      email: 'Indirizzo e-mail per l’account MyAlcon Store*',
      accountNumber: 'Numero di account Alcon*',
      next: 'Avanti',
      back: 'Indietro',
      cancel: 'Annulla',
      submit: 'Invia',
      mandatory: '* Campi obbligatori',
      successText: `Manderemo una copia di questo modulo nella tua casella di posta.</br></br>Nota bene: Possono essere necessari fino a tre giorni lavorativi per configurare un nuovo account a partire dal giorno in cui riceviamo il modulo completo, munito di un comprovante di iscrizione all’albo.`,
      successTitle:
        'Ti ringraziamo di aver completato il modulo di iscrizione per aprire un account con Alcon Eye Care UK Limited.',
      goToLoginPage: 'Vai alla pagina d’accesso',
      errorMessages: {
        mandatoryField: 'Questo campo è obbligatorio.',
        maxLengthReached: 'Non puoi usare più di {{characterNumber}} caratteri in questo campo.',
        couldContainsOnlyNumbers: 'Questo campo non accetta le lettere.',
        firstName: 'Inserisci il nome',
        lastName: 'Inserisci il cognome',
        fullCompanyName: 'Inserisci il nome completo della società',
        email: 'Inserisci l’indirizzo e-mail',
        emailPattern: 'Il formato dell’e-mail non è corretto. Usa il seguente formato: john@gmail.com',
        accountNumber: 'Inserisci il numero di account Alcon',
        termsAndConditions: 'Accetta i campi obbligatori',
        responseError:
          'Abbiamo riscontrato un problema con la tua richiesta. Prova di nuovo o contatta il nostro servizio clienti',
      },
    },
    contactusPage: {
      pleaseSelectATopic: 'Seleziona un argomento:*',
      typeYourMessageHere: 'Digita il tuo messaggio qui:*',
      contactMethod: 'Seleziona il tuo canale di contatto preferito per questa richiesta:*',
      contactMethodLabel1: 'E-mail',
      contactMethodLabel2: 'Telefono',
      emailAddress: 'Indirizzo e-mail',
      emailInfo:
        'Le informazioni sopra indicate non cambiano le informazioni di contatto associate al tuo account. Puoi cambiare il tuo numero di telefono in Il mio profilo oppure contattare il Servizio clienti per cambiare la tua e-mail.',
      termsConditions: `Alcon userà queste informazioni per rispondere alla tua richiesta. Ho letto la Politica sulla privacy di Alcon e ne accetto i termini.*`,
      preferredTime: 'Seleziona l’orario che preferisci:*',
      preferredTimeLabel1: 'Mattina',
      preferredTimeLabel2: 'Pomeriggio',
      contactName: 'Fornisci un nome contatto:*',
      contactNameLabel: ' Nome contatto',
      fullCompanyName: 'Nome completo della società',
      returnToLastVisitedPage: 'Torna all’ultima pagina visitata',
      mandatoryFields: '* Campi obbligatori',
      provideContactName: 'Fornisci un nome contatto:*',
      contactusSubmit: 'Invia',
      phoneNumber: 'Numero di telefono',
      characters: '1000 caratteri',
      thankyoumsg: 'Grazie per la tua richiesta',
      additionalmsg:
        'Un rappresentante di Alcon prenderà contatto con te in merito a questa richiesta entro 1 giorno lavorativo.',
      returntoHome: 'Torna alla Home',
      returnProdct: 'Restituisci prodotto',
      gotoform: 'Vai al modulo',
      selectFile: 'Seleziona file',
      errorMessages: {
        topic: 'Seleziona un Argomento.',
        mandatoryField: 'Questo campo è obbligatorio.',
        message: `Digita il messaggio.`,
        contactmethod: 'Seleziona almeno un canale di contatto',
        email: 'Inserisci l’e-mail',
        emailPattren: 'Il formato dell’e-mail non è corretto. Usa il seguente formato: john@gmail.com',
        phoneNumber: `Inserisci il numero di telefono.`,
        phoneNumberCharecters: 'Questo campo non accetta le lettere.',
        phoneNumberMinLength: 'Il numero di telefono è troppo corto. Verifica di avere inserito tutti i caratteri.',
        preferredTime: 'Seleziona l’orario di contatto che preferisci',
        contactName: 'Inserisci il nome di contatto',
        termsConditions: 'Seleziona i termini e condizioni',
        contactNamePattern: 'Non puoi digitare più di 30 caratteri in questo campo',
      },
    },
    selectProductPage: {
      selectPackType: 'Seleziona il tipo di confezione',
      selectProducts: 'Seleziona i prodotti',
      regular: 'Regolare',
      valuePack: 'Confezione risparmio',
      chooseLenses: 'Scegli le lenti:*',
      patientName: 'Paziente: ',
      valuePacktooltip: 'Alcune soluzioni e lenti a contatti sono associate in un vantaggioso pacchetto.',
      additionalProducts: 'Scegli prodotti aggiuntivi:',
      contactLens: 'Soluzione per lenti a contatto',
      dryEye: 'Prodotti per occhi secchi',
      addAnotherproduct: 'Aggiungi un altro prodotto',
      mandatory: '* Campi obbligatori',
      addOtherLenses: 'AGGIUNGI ALTRE LENTI',
      valuePackAttributes: {
        threeMonthValuePack: 'Confezione da 3 mesi',
        sixMonthValuePack: 'Confezione da 6 mesi',
        chooseSolution: 'Scegli la soluzione:*',
      },
      patientOrderPanel: {
        delivery: '3. Scegli la consegna',
        practiceOrder: 'Ordine ambulatorio',
        directToPatient: 'Diretto al paziente',
        selectOrderType: 'Seleziona il tipo di ordine',
        individual: 'Individuale',
        total: 'Totale:',
        addToCart: 'AGGIUNGI AL CARRELLO',
        addToCartSuccess: 'AGGIUNTO AL CARRELLO',
        cancel: 'Annulla',
        address: 'Indirizzo:',
        delivertodiffAddress: 'Consegna a un indirizzo diverso',
        selectdeliveryAddress: 'Seleziona indirizzo di consegna:',
        dropdown: {
          buttonText: 'Aggiungi paziente',
        },
        scheduled: {
          orderType: 'Programmato',
          repeatEveryText: 'Ripeti ogni:',
          week: 'settimane',
          month: 'mesi',
          startDate: 'Data di inizio:',
          save: 'Salva',
          startSubscriptions: 'INIZIA ABBONAMENTI',
          addToCartNow: 'AGGIUNGI SUBITO AL CARRELLO',
        },
        title: `Aggiunta al carrello non riuscita`,
        description: `Gli articoli non sono stati aggiunti al carrello. <br> Risolvi il problema e riprova.`,
        redirectButton: 'OK',
      },
      tooltipText: `Non puoi selezionare un secondo prodotto se hai selezionato due occhi. Se vuoi aggiungere un altro prodotto per lo stesso paziente, deseleziona un occhio.`,
    },
    safeOrderingPage: {
      safeOrdering: 'Ordine sicuro ',
    },
    configureProducts: {
      deliveryPanel: {
        delivery: 'Consegna',
        selectDelivery: 'Scegli la consegna',
        practiceOrder: 'Ordine ambulatorio',
        directToPatient: 'Diretto al paziente',
        patientReferencePlaceholder: 'Digita riferimento',
        patientReference: 'Riferimento paziente:',
        patientReferenceMandatory: 'Riferimento paziente:*',
        needStockOrder: 'Ti serve un ordine di riserva?',
        practiceErrorMessage: 'Non puoi usare più di 30 caratteri.',
        noResultsFound: 'Nessun risultato trovato',
        selectFromList: 'Seleziona dalla lista',
        deliveryToDifferentAddress: 'Consegna a un indirizzo diverso',
      },
    },
    orderDetailsPage: {
      orderNo: 'Ordine n°',
      shipped: 'Spedito',
      inProgess: 'In corso',
      delivered: 'Consegnato',
      ACCOUNT: 'Fattura da contabilizzare',
      CARD: 'Carta di credito',
      billOnly: 'Solo fattura',
      products: 'Prodotti',
      statusPanel: {
        partiallyShipped: 'Parzialmente spedito',
        trackingno: 'N° di tracciamento:',
      },
      paymentMethodPanel: {
        orderPlaced: 'Ordine effettuato:',
        orderDate: 'Data dell’ordine:',
        orderSource: 'Fonte dell’ordine:',
        paymentMethod: 'Metodo di pagamento:',
        cardEndingWith: 'Carta che finisce con ****',
        invoiceno: 'Fattura n°',
        addNewCard: 'Aggiungi nuova carta',
        noCreditCard: 'Non hai collegato nessuna carta a questo account.',
        Change: 'Cambiare',
        useOneTime: 'USA CARTA UNA VOLTA',
        useAndSaveLater: 'USA E SALVA PER DOPO',
        useSelectedCard: 'USA CARTA SELEZIONATA',
        useAndSaveDefault: 'USA E SALVA COME PREDEFINITA',
        useNewCard: 'USA NUOVA CARTA',
      },
      deliveryMethodPanel: {
        practiceOrders: 'Ordini ambulatorio',
        deliveryMethod: 'Modalità di consegna:',
        shippingAddress: 'Indirizzo di spedizione:',
        poNumber: 'Numero ordine di acquisto:',
        DTPOrders: 'Diretto al paziente',
      },
      finalPrice: {
        reOrder: 'riordina',
      },
      orderItemPage: {
        INDIVIDUAL_ORDER: 'Ordini individuali',
        STOCK_ORDER: 'Ordine di riserva',
        VALUE_PACK: 'Confezione risparmio',
        total: 'Totale',
        item: '{{count}} articolo',
        item_plural: '{{count}} articoli',
        materialNumber: 'Materiale n°',
        serialNumber: 'Serie n°',
        IOL_Bill: 'Fattura online',
        diopter: 'Diottria',
        estDeliveryDate: 'Giorno di consegna stimato',
        deliveryDate: 'Data di consegna',
        'labelstatus_In transit': 'In transito:',
        'labelstatus_In delivery': 'In corso di consegna:',
        labelstatus_delivered: 'Consegnato:',
        labelstatus_undefined: '',
        fromitem: '{{othercount}} articolo su {{count}}',
        tooltip: '{{othercount}} articoli su {{count}} sono {{status}}. Il resto degli articoli hanno stati diversi',
      },
    },
    selectDeliveryAddressPage: {
      mainaddress: 'Indirizzo principale',
      additonaladdress: 'Indirizzo supplementare',
      edit: 'Modifica',
      cancel: 'Annulla',
      save: 'Salva',
      mandatory: '* Campi obbligatori',
    },
    patientAddressPage: {
      heading: 'Indirizzo:',
      form: {
        address: {
          street: 'Numero e via *',
          building: 'Palazzo / appartamento / altro',
          postcode: 'CAP *',
          city: 'Città *',
          county: 'Provincia',
          notMandatoryCounty: 'Provincia',
          country: 'Paese *',
        },
        mandatoryFields: '* Campi obbligatori',
        additionalAddress: 'Aggiungi indirizzo supplementare',
        clearAll: 'Cancella tutto',
        save: 'salva',
        cancel: 'Annulla',
        update: 'Aggiorna',
        deleteadditionaladdress: 'Cancella indirizzo supplementare',
        errorMessages: {
          mandatoryField: 'Questo campo è obbligatorio.',
          street: `Inserisci il numero civico del paziente.`,
          buildingNumberName: `Inserisci il numero del palazzo del paziente.`,
          postcode: `Inserisci il CAP del paziente.`,
          city: `Inserisci la città del paziente.`,
          county: `Inserisci la provincia del paziente.`,
          country: `Inserisci il Paese del paziente.`,
          invalidPattren: 'CAP non valido',
          selectCountry: 'Seleziona un Paese',
        },
      },
    },
    recentOrders: {
      lastOrders: 'Ultimi ordini',
      latestOrders: 'Ordini più recenti',
      seeMore: 'Più dettagli nella cronologia ordini',
      noOrders: `Non hai nessun ordine.`,
      noOrderText: 'Quando effettuerai un ordine, potrai vederne lo stato qui. ',
      noOrdersSX: `Non hai ancora ordinato nessun prodotto. Quando effettuerai l’ordine, potrai vederne lo stato qui.`,
    },
    myProfilePage: {
      emailAddress: 'Indirizzo e-mail:',
      phoneNumber: 'Numero di telefono:',
      password: 'Password',
      changepassword: 'Cambia password',
      edit: 'Modifica',
    },
    contactInformation: {
      title: 'Titolo',
      firstName: 'Nome*',
      lastName: 'Cognome*',
      email: 'Indirizzo e-mail',
      phonenumber: 'Numero di telefono',
      cancel: 'Annulla',
      save: 'Salva',
      errorMessages: {
        requireFirstName: 'Inserisci il nome',
        moreThanThirtyCharacters: 'Non puoi digitare più di 30 caratteri in questo campo.',
        requireLastName: 'Inserisci il cognome',
        phoneNumber: 'Questo campo non accetta le lettere.',
        phoneNumberMinLength: 'Il numero di telefono è troppo corto. Verifica di avere inserito tutti i caratteri.',
      },
    },
    changePassword: {
      currentpassword: 'Password attuale*',
      newpassword: 'Nuova password*',
      reenterpassword: 'Reinserisci la nuova password*',
      mandatory: '* Campi obbligatori',
      showpassword: 'Mostra password',
      cancel: 'Annulla',
      save: 'Salva',
      suggestion: 'Usa 8 o più caratteri con una combinazione di lettere, numeri e simboli',
      errorMessages: {
        requireCurrentpassword: 'Inserisci la password attuale',
        requireNewPassword: 'Inserisci la nuova password',
        requireReEnterpassword: 'Reinserisci la nuova password',
        match: `Le password non corrispondono. Riprova.`,
        strong: 'Scegli una password più sicura. Prova con una combinazione di lettere, numeri e simboli.',
        maxCharcters: 'Usa almeno 8 caratteri per la password.',
      },
    },
    patientOrderHistory: {
      io: 'Ordini individuali ',
      subscription: 'Abbonamenti ',
      switchSub: 'Passa all’abbonamento',
      nof: 'Nessun ordine trovato nel periodo specificato',
      orderNo: 'Ordine n°',
      mainAddress: 'Indirizzo principale',
      addAddress: 'Indirizzo supplementare',
      orderPlaced: 'Ordine effettuato:',
      orderSent: 'Ordine inviato:',
      nextOrder: 'Prossimo ordine:',
      subCreated: 'Abbonamento creato:',
      every: 'Ogni',
      active: 'ATTIVO',
      inactive: 'INATTIVO',
      noOrdersYet: 'Ancora nessun ordine',
      oneTime: 'Una volta',
      frequency: 'Frequenza',
    },
    accountDetailsPage: {
      requestChangeAddress: 'Richiedi cambio di indirizzo',
      shippingAddress: 'Indirizzo di spedizione',
      billingAddress: 'Indirizzo di fatturazione',
      orderconsolidationenabled: 'Maturazione abilitata',
    },
    commonTitles: {
      edit: 'Modifica',
      delete: 'Cancella',
    },
    visioncareSearch: {
      searchFor: 'Cerca per:',
    },
    subscriptions: {
      nosubscriptions: `Non hai nessun abbonamento`,
      subscriptions: 'Abbonamenti',
      allPatients: 'Tutti i pazienti',
      subscriptionMsg:
        'Gli abbonamenti sono ordini programmati, consegnati direttamente ai pazienti o all’ambulatorio.',
    },
    returnForm: {
      noProducts: 'Non ci sono prodotti da restituire',
      searchProducts: 'Cerca prodotti o seleziona dalla lista',
      selectProductHeader: 'Seleziona il prodotto che vuoi restituire:',
      addAnotherProduct: 'Aggiungi un prodotto da restituire:',
      baseCurve: 'Curvatura di base:*',
      power: 'Potere:*',
      add: 'Addizione*:',
      colour: 'Colore*:',
      cylinder: 'Cilindro*:',
      axis: 'Asse*:',
      packSize: 'Dimensioni confezione:',
      diameter: 'Diametro:',
      ordernumber: 'Ordine n°:*',
      quantity: 'Quantità:*',
      mandatory: '* Campi obbligatori',
      delete: 'Cancella prodotto',
      save: 'SALVA PRODOTTO',
      returnReason: 'Motivo del reso',
      dateoforder: 'Data dell’ordine:',
      reasonMandatory: 'Identifica il motivo della restituzione dei prodotti *',
      selectreason: 'Seleziona motivo',
      quantityError:
        'Non puoi scegliere più prodotti di uno stesso tipo di quanti siano stati ordinati. Se non sei sicuro della quantità di prodotti, verifica il numero dell’ordine',
      orderNumberError:
        'Questo numero dell’ordine è già stato usato nel tuo modulo di reso. Cambia il numero dell’ordine o cancella questo articolo',
      returnproduct: 'RESTITUISCI PRODOTTO',
      specifications: {
        baseCurve: 'Curvatura di base:',
        power: 'Potere:',
        add: 'Addizione:',
        colour: 'Colore:',
        cylinder: 'Cilindro:',
        axis: 'Asse:',
        packSize: 'Dimensioni confezione:',
        diameter: 'Diametro:',
        ordernumber: 'Ordine n°:',
        quantity: 'Quantità:',
      },
    },
    statements: {
      statementForDownload: 'Estratti conto scaricabili',
      tooltipText: 'Il primo estratto conto sarà disponibile all’inizio del prossimo mese.',
      downloadText: 'Scarica estratto conto (PDF)',
    },
    reOrderValidation: {
      heading: 'Abbiamo riscontrato un problema con il tuo ri-ordine',
      ok: 'OK',
      valuePackQauntity: '1 articolo',
      itemDiscontinued: 'Contatta il nostro servizio clienti',
      maxLimit: 'Salda il tuo carrello attuale prima di riordinare i prodotti o riconfigura il tuo ordine',
      patientDeleted: 'Il paziente è stato cancellato e non è più associabile a un ordine',
      valuPackexist: 'Salda il tuo carrello attuale prima di riordinare i prodotti',
      GENERIC_API_ERRORMESSAGE:
        'Gli articoli non sono stati aggiunti al carrello. Riprova più tardi o contatta il nostro servizio clienti',
      DIRECT_TO_PATIENT_MESSAGE: `Gli articoli sono stati aggiunti al carrello e destinati all’indirizzo principale del paziente. Verifica l’indirizzo nel carrello`,
      GENERIC_MESSAGE: 'Gli articoli sono stati aggiunti al carrello',
    },
  },
};
