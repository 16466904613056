import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { UserPermissionGuard } from 'src/app/services/user-permission.guard';
import { AlconProductSelectorModule } from '../basic-product-variant-selector/product-selector.module';
import { AlconCommonModule } from '../common/alcon-common.module';
import { AlconPatientModule } from '../patient/patient.module';
import { AddToCartPlpComponent } from './add-to-cart-plp/add-to-cart-plp.component';
import { AlconProductAddToCartComponent } from './alcon-product-add-to-cart/alcon-product-add-to-cart.component';
import { ProductDetailsComponent } from './product-details/product-details.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    UrlModule,
    RouterModule,
    AlconCommonModule,
    AlconProductSelectorModule,
    AlconPatientModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductDetailsFlexComponent: {
          component: ProductDetailsComponent,
        },
        ProductAddToCartComponent: {
          component: AlconProductAddToCartComponent,
          guards: [UserPermissionGuard],
        },
      },
    } as CmsConfig),
  ],
  declarations: [ProductDetailsComponent, AddToCartPlpComponent, AlconProductAddToCartComponent],
  exports: [ProductDetailsComponent, AddToCartPlpComponent, AlconProductAddToCartComponent],
})
export class AlconProductDetailsComponentsModule {}
