import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alcon-print',
  templateUrl: './alcon-print.component.html',
  styleUrls: ['./alcon-print.component.scss'],
})
export class AlconPrintComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
  printForm(): void {
    window.print();
  }
}
