<div *ngFor="let data of componentData; let i = index; let first = first">
  <table class="table table-borderless listWrapper__table mt-4" [ngClass]="{ 'pt-22': listType === 'Scheduled' }">
    <thead class="patient-heading">
      <th class="p-0 patient-heading__orderno">
        <p *ngIf="listType === orderTypeIndividual; else orderWithoutNumber">
          {{ 'patientOrderHistory.orderNo' | cxTranslate }}
          <a
            class="text-decoration-none switchPatient"
            [routerLink]="{ cxRoute: 'orderDetailsPage', params: { orderid: data?.orderNo } } | cxUrl"
          >
            {{ data?.orderNo }}
          </a>
        </p>
        <ng-template #orderWithoutNumber>
          <p>{{ 'myPatientsPage.subscription' | cxTranslate }}</p>
        </ng-template>
      </th>
      <th class="p-0 patient-heading__delivery">
        <p>{{ 'myPatientsPage.delivery' | cxTranslate }}</p>
      </th>
      <th class="p-0 patient-heading__packtype">
        <p>{{ 'myPatientsPage.packtype' | cxTranslate }}</p>
      </th>
      <th class="p-0 patient-heading__date">
        <p>{{ 'myPatientsPage.date' | cxTranslate }}</p>
      </th>
      <th class="p-0 patient-heading__status">
        <p>{{ 'myPatientsPage.status' | cxTranslate }}</p>
      </th>
    </thead>
    <tbody>
      <tr *ngIf="data?.productDetails?.length > 0; else warning" class="listWrapper__table__bottom-border">
        <td
          [ngClass]="{
            'more-products': data?.productDetails.length > 1 && data?.showMore,
            'less-products': data?.productDetails.length > 1 && !data?.showMore
          }"
          class="listWrapper__table__productDetails divider"
        >
          <div
            [ngClass]="{
              'less-products': data?.productDetails.length > 1 && !data?.showMore,
              'more-products': data?.productDetails.length > 1 && data?.showMore
            }"
            class="d-flex flex-column patient-data__orderno text-break"
            *ngFor="let productData of data?.productDetails"
          >
            <label>{{ productData?.name }}</label>
            <div
              class="listWrapper__table__productDetails__sku"
              *ngIf="productData?.specifications?.length > 0; else solution"
            >
              <p class="mb-0">
                <span>{{ productData?.eyeSight }}</span>
                <span *ngFor="let specification of productData?.specifications">
                  {{ 'checkoutPage.' + specification?.specificationKey | cxTranslate }}:
                  {{ specification?.specificationValue }}
                </span>
                <span>qty.{{ productData?.quantity }}</span>
              </p>
            </div>

            <ng-template #solution>
              <span class="listWrapper__table__productDetails__sku"> qty.{{ productData?.quantity }} </span>
            </ng-template>
          </div>
        </td>
        <td class="flex-column divider">
          <div>
            <p *ngIf="!data?.orderType.includes('Direct')">
              {{ data?.orderType | removeWhitespace | lowercase | cxTranslate }}
            </p>
            <div class="lightText" *ngIf="data?.orderType.includes('Direct')">
              <a
                *ngIf="data?.address"
                type="button"
                (click)="isCollapsed[i] = !isCollapsed[i]"
                [attr.aria-expanded]="isCollapsed[i]"
              >
                <cx-icon *ngIf="!isCollapsed[i]" type="DropdownExpandBlack"></cx-icon>
                <cx-icon *ngIf="isCollapsed[i]" type="DropdownCollapseBlack"></cx-icon>
              </a>
              <span> {{ data?.orderType | removeWhitespace | lowercase | cxTranslate }}</span>
              <app-address-block
                #collapse="ngbCollapse"
                [(ngbCollapse)]="!isCollapsed[i]"
                class="orderRow__orderList__mainAddr pt-2"
                [address]="data.address"
                [removeLineHeight]="true"
              >
              </app-address-block>
            </div>
          </div>
        </td>
        <td class="patient-heading__packtype divider">
          <p>{{ data?.packType | removeWhitespace | lowercase | cxTranslate }}</p>
        </td>
        <td class="patient-heading__date divider">
          <div class="d-flex flex-column" *ngIf="listType === 'Scheduled'; else individual">
            <span>{{ 'patientOrderHistory.nextOrder' | cxTranslate }}</span>
            <span> {{ data?.nextOrder }} </span>
          </div>
          <ng-template #individual>
            <div class="d-flex flex-column">
              <span>{{ 'patientOrderHistory.orderSent' | cxTranslate }} </span>
              <span> {{ data?.orderPlaced }}</span>
            </div>
          </ng-template>
        </td>
        <td class="text-left divider flex-column listWrapper__status-column patient-heading__status">
          <p class="mb-0" *ngIf="listType === 'Scheduled'">
            <app-change-subscription-status
              [toggleData]="data.toggleConfig"
              [subData]="data"
              [orderCode]="data.replenishmentOrderCode"
              (updatedData)="updateComponentData($event, i)"
            >
            </app-change-subscription-status>
          </p>

          <app-re-order
            *ngIf="listType === orderTypeIndividual"
            [productDetails]="data.productDetails"
            [orderID]="data.orderNo"
            class="d-block pb-4"
            [buttonType]="'patientReorder'"
            [disableReOrder]="!alconUserPermissionService.getAddToCartEnabled()"
            (reOrderResponse)="reOrderResponse($event, data.orderType)"
          >
          </app-re-order>

          <p class="pb-2 d-flex flex-column" *ngIf="listType === 'Scheduled'; else onlyOccurance">
            <span>{{ 'patientOrderHistory.frequency' | cxTranslate | uppercase }}: </span>
            <span>{{ 'patientOrderHistory.every' | cxTranslate }} {{ data?.occurance }}</span>
          </p>

          <ng-template #onlyOccurance>
            <p class="mt-2">{{ 'patientOrderHistory.oneTime' | cxTranslate | uppercase }}</p>
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="d-flex actions">
    <div class="col-2 flex-column actions__showmore-wrapper">
      <div
        *ngIf="data?.productDetails.length > 1"
        class="d-flex d-lg-none pointer actions__showmore-wrapper__action"
        (click)="showMoreToggle(data)"
      >
        <cx-icon *ngIf="!data?.showMore" type="DropdownExpandBlack"></cx-icon>
        <cx-icon *ngIf="data?.showMore" type="DropdownCollapseBlack"></cx-icon>
        <p class="ml-2">
          {{ (!data?.showMore ? 'myPatientsPage.showMore' : 'myPatientsPage.showLess') | cxTranslate }}
        </p>
      </div>
    </div>
    <div class="col-2 flex-column justify-content-end actions__edit-delete-wrapper">
      <div
        *ngIf="listType === 'Scheduled'"
        class="d-flex align-items-end justify-content-end position-relative actions__edit-delete-wrapper__action"
      >
        <cx-icon
          type="DisabledDelete"
          class="d-flex align-items-center"
          [ngbPopover]="popoverTmpl"
          popoverClass="popover--sm"
          placement="bottom"
          triggers="mouseenter:mouseleave"
          *ngIf="data.toggleConfig.status"
          #p="ngbPopover"
          (click)="p.open()"
          alt="delete"
        >
          <span class="orderRow__orderList__productDetails__disableIconText">{{
            'miniCartPage.delete' | cxTranslate
          }}</span>
        </cx-icon>

        <ng-template #popoverTmpl>
          {{ 'patientOrderHistory.canDelOnlyActive' | cxTranslate }}
        </ng-template>
        <cx-icon
          type="Remove"
          class="d-flex align-items-center"
          *ngIf="!data.toggleConfig.status"
          (click)="deleteScheduleOrder(data.replenishmentOrderCode)"
          alt="delete"
          ><span class="orderRow__orderList__productDetails__iconText">{{ 'miniCartPage.delete' | cxTranslate }}</span>
        </cx-icon>
        <cx-icon
          class="d-flex align-items-center"
          type="EditBlue"
          (click)="editScheduledOrder(data?.replenishmentOrderCode, data)"
          alt="edit"
          ><span class="orderRow__orderList__productDetails__iconText">{{
            'patientProfilePage.edit' | cxTranslate
          }}</span>
        </cx-icon>
      </div>
    </div>
  </div>
  <hr class="hor-line" />
</div>

<ng-template #warning>
  <tr>
    <td colspan="1">
      <cx-icon class="d-flex warning-message" type="WarningIcon"
        >{{ 'myPatientsPage.warningMessage' | cxTranslate }}
      </cx-icon>
    </td>
  </tr>
</ng-template>
