<div class="fixedtable" #toricTable>
  <table>
    <tr>
      <th class="powers-label">{{ 'stockToricPage.lensPower' | cxTranslate }}</th>
      <th
        *ngFor="let pwr of powerForAxisList; first as isFirst"
        class="power-header text-center"
        [ngClass]="{ 'power-header--first-child': isFirst }"
      >
        {{ pwr.power }}<sup (click)="deletePowerFromSelection(pwr.power)" class="close-power">X</sup>
      </th>
      <th class="powers-list">
        <app-dropdown-selector-v2
          [options]="totalPowerList"
          [config]="defaultConfig | async"
          (dropDownSelected)="selectLensPower($event)"
        >
        </app-dropdown-selector-v2>
      </th>
    </tr>
    <tr>
      <th class="axis-label">{{ 'stockToricPage.lensAxis' | cxTranslate }}</th>
    </tr>

    <tr *ngFor="let axis of aixsVarients">
      <td class="axis">{{ axis }}</td>
      <td *ngFor="let pwr of powerForAxisList">
        <input
          class="power-quantity-input text-center"
          type="text"
          appOnlyNumeric
          [ngClass]="{
            'power-quantity-input--disabled': !pwr.axisList.has(axis),
            'power-quantity-input--error': hasMaxQuantityError(pwr, axis)
          }"
          (keyup)="updateQuantityForAxis(pwr, axis, $event)"
          [value]="getQuantityForAxis(pwr, axis)"
          [disabled]="isAxisDisabled(pwr, axis)"
        />
      </td>
    </tr>
  </table>
</div>
