import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OccEndpointsService, UserIdService } from '@spartacus/core';
import { combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import { map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { PatientProfileData } from 'src/app/shared/model/patient.model';
import { LoadItems } from 'src/app/store/Actions/cartItem.action';
import { AddPatient, UpdatePatient } from 'src/app/store/Actions/patient.action';
import { getOrderFlag, getPatientData, getPatientInfo } from 'src/app/store/Selectors/patient.selector';
import { PatientListService } from '../patients-list/patient-list.service';
@Injectable({
  providedIn: 'root',
})
// TODO : Handle error scenarios using global error service
export class PatientService {
  constructor(
    private httpClient: HttpClient,
    private occEndPoints: OccEndpointsService,
    private userIdService: UserIdService,
    private store: Store,
    private patientListService: PatientListService
  ) {}
  private patientData: PatientProfileData;
  patientMessages = new Subject<string>();
  newPatientAdded = new Subject<PatientProfileData>();
  // TODO : Handle error scenarios using global error service
  createUser(newPatient): Observable<any> {
    return this.userIdService.getUserId().pipe(
      mergeMap((userId: string) => {
        const url = this.occEndPoints.buildUrl(`users/${userId}/patients/create`);
        return this.httpClient
          .post<any>(url, newPatient)
          .pipe(tap((patientData) => this.store.dispatch(AddPatient({ patient: patientData }))));
      })
    );
  }

  // TODO: update store only on succes
  updateUser(newPatient): Observable<any> {
    return this.userIdService.getUserId().pipe(
      switchMap((userId: string) => {
        const url = this.occEndPoints.buildUrl(`users/${userId}/patients/update`);
        return this.httpClient.patch<any>(url, newPatient).pipe(
          tap(() => this.getPatientProfileInformation(newPatient.uid).pipe(take(1)).subscribe()),
          tap(() => this.store.dispatch(LoadItems()))
        );
      })
    );
  }
  getPatientProfileInformation(patientID, isOdersRequired = true): Observable<PatientProfileData> {
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap((userId) => {
        const url = this.occEndPoints.buildUrl(
          `users/${userId}/patients/${patientID}?ordersRequired=${isOdersRequired}`
        );
        return this.httpClient.get<any>(url).pipe(
          map((patientData) => {
            delete patientData.type;
            return {
              ...patientData,
            };
          }),
          tap((patientData) => this.store.dispatch(UpdatePatient({ updatedPatient: patientData })))
        );
      })
    );
  }

  /** @deprecated */
  getPatientProfileData(patientID, compare = false): Observable<PatientProfileData> {
    if (compare && this.patientData?.uid === patientID) {
      return of({ ...this.patientData } as PatientProfileData);
    }
    return combineLatest([this.userIdService.getUserId()]).pipe(
      take(1),
      switchMap(([userId]) => {
        const url = this.occEndPoints.buildUrl(`users/${userId}/patients/${patientID}`);
        return this.httpClient.get<any>(url).pipe(
          tap((data: PatientProfileData) => {
            this.patientData = data;
          })
        );
      })
    );
  }
  /** getting the patient profile data from the store if the patient data is not exist will
   * call the getPatientProfileData api to fetch the data
   */

  getPatientProfileFromStore(PatientID): Observable<PatientProfileData> {
    return this.store.select(getPatientInfo(PatientID)).pipe(
      take(1),
      switchMap((patientInfo) => {
        return patientInfo
          ? of(patientInfo)
          : this.patientListService
              .getPatientsList()
              .pipe(
                map((patients) =>
                  patients.find((patient) => patient.patientId === PatientID || patient.uid === PatientID)
                )
              );
      })
    );
  }

  setPatientInfo(patientInfo: PatientProfileData): void {
    this.patientData = patientInfo;
  }

  /**
   * Check if data exist in store
   * if data exist return the store data
   * if not then call getPatient Api
   * Update the store and return the same
   */

  getPatientStoreData(ordersRequired = false): Observable<Array<PatientProfileData>> {
    return forkJoin([
      this.store.select(getPatientData).pipe(take(1)),
      this.store.select(getOrderFlag).pipe(take(1)),
    ]).pipe(
      take(1),
      switchMap(([patientsData, fetchOrderFlag]) => {
        if (ordersRequired) {
          return fetchOrderFlag ? of(patientsData) : this.patientListService.getPatientsList(true);
        } else {
          return patientsData.length ? of(patientsData) : this.patientListService.getPatientsList(false);
        }
      })
    );
  }

  deleteAdditionalAddress(patientData): Observable<any> {
    return forkJoin([
      this.getPatientProfileFromStore(patientData.patientId).pipe(take(1)),
      this.userIdService.getUserId().pipe(take(1)),
    ]).pipe(
      switchMap(([patientProfileData, userId]) => {
        const url = this.occEndPoints.buildUrl(
          `users/${userId}/patients/deleteAdditionalAddress/?patientId=${patientData.patientId}&addressId=${patientData.addresses[1].id}`
        );
        return this.httpClient.post<any>(url, {}).pipe(
          tap(() => {
            this.store.dispatch(
              UpdatePatient({
                updatedPatient: {
                  ...patientProfileData,
                  addresses: patientProfileData.addresses?.filter((address) => address?.defaultAddress),
                },
              })
            );
          })
        );
      })
    );
  }
}
