<ng-template cxOutletRef="cx-footer">
  <footer class="d-print-none footer-wrapper">
    <div class="footer-width">
      <div class="container-fluid footer-grid-padding">
        <div class="row justify-content-center">
          <div class="col-10">
            <cx-page-slot position="Footer"></cx-page-slot>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-10 footer-copy-right">
            <cx-page-slot position="UKSiteNoticeContent"></cx-page-slot>
          </div>
        </div>
        <div *ngIf="showReferenceSection" class="row references">
          <div class="col-sm-10 footer-wrapper footer-copy-right references--border">
            <div class="row references__section col-8">
              <cx-icon
                *ngIf="showReferences"
                class="references__expand-content-down"
                (click)="openReference()"
              ></cx-icon>
              <cx-icon
                *ngIf="!showReferences"
                class="references__expand-content-up"
                (click)="openReference()"
              ></cx-icon>
              <cx-icon *ngIf="!showReferences" type="arrowWhite" (click)="openReference()"></cx-icon>
              <p class="references--header">References</p>
            </div>
            <div class="col-10 p-l-11">
              <cx-page-slot *ngIf="showReferences" position="PositionSlotReferenceFooter"></cx-page-slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</ng-template>
