<ng-container *ngIf="products?.length > 0">
  <label for="returnFormproductSelector" class="selector__label">{{ 'returnForm.searchProducts' | cxTranslate }}</label>
  <app-dropdown-selector-v2
    [config]="dropdownConfig"
    (dropDownSelected)="productSelected.emit($event)"
    [options]="products"
  ></app-dropdown-selector-v2>
</ng-container>
<ng-container *ngIf="products?.length === 0">
  <p class="selector__label selector__label--noproducts">{{ 'returnForm.noProducts' | cxTranslate }}</p>
</ng-container>
