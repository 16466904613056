<div class="invoices" *ngIf="invoicesList?.length > 0 || recordsFound > 0">
  <div class="invoices__download">
    <app-alcon-statement-download *ngIf="showStatements"></app-alcon-statement-download>
  </div>

  <div>
    <table class="invoices__list">
      <thead>
        <tr>
          <th class="invoices__list--heading invoices__list--width invoices__list--row-border" scope="col">
            {{ 'invoicesListPage.invoiceNo' | cxTranslate }}
          </th>
          <th class="invoices__list--heading invoices__list--width invoices__list--row-border" scope="col">
            {{ 'invoicesListPage.orderNo' | cxTranslate }}
          </th>
          <th class="invoices__list--heading invoices__list--width invoices__list--row-border" scope="col">
            {{ 'invoicesListPage.totalPrice' | cxTranslate }}
          </th>
          <th class="invoices__list--width invoices__list--row-border"></th>
          <th class="invoices__list-sort invoices__list--row-border">
            <label class="invoices__list--sort-label justify-content-start">{{
              'invoicesListPage.sort' | cxTranslate
            }}</label>
            <app-dropdown-selector
              class="dropdown-invoices"
              [selectedIndex]="0"
              [options]="sortInvoiceListOptions"
              (dropDownSelected)="sortBy($event)"
            ></app-dropdown-selector>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="invoices__list--row invoices__list--row-border" *ngFor="let invoice of invoicesList?.invoices">
          <td class="font-weight-bold invoices__list--width">{{ invoice?.invoiceNumber }}</td>
          <td class="invoices__list--width">
            <span *ngIf="invoiceListType === dailyInvoice; else accordion">{{ invoice?.orderNumber }}</span>
            <ng-template #accordion>
              <app-invoice-order-number-accordion [invoice]="invoice"></app-invoice-order-number-accordion>
            </ng-template>
          </td>
          <td class="invoices__list--width font-weight-bold">{{ invoice?.amount.formattedValue }}</td>
          <td class="invoices__list--width"></td>
          <td
            class="invoices__list--width invoices__list-download text-right"
            (click)="downloadInvoice(invoice?.invoiceNumber)"
          >
            <cx-icon role="button" type="DownloadIcon"></cx-icon> {{ invoice?.invoiceNumber }}
          </td>
        </tr>
      </tbody>
    </table>

    <ngb-pagination
      class="d-flex justify-content-center mt-4 pt-1"
      [ngClass]="{ 'd-none': !defaultPanelConfiguration.pagination }"
      *ngIf="invoicesPagination?.totalNumberOfResults > invoicesPagination?.pageSize"
      [collectionSize]="invoicesPagination.totalNumberOfResults"
      [pageSize]="invoicesPagination.pageSize"
      [page]="invoicesPagination.currentPage + 1"
      (pageChange)="setPage($event)"
      [maxSize]="maxSize"
      [ellipses]="false"
      [rotate]="true"
    ></ngb-pagination>
  </div>
</div>
