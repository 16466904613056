<div class="panel-container">
  <div class="patient-order d-flex justify-content-between flex-lg-column">
    <div>
      <div class="patient-order__delivery patient-order__section">
        <h5 class="patient-order--heading">{{ 'selectProductPage.patientOrderPanel.delivery' | cxTranslate }}</h5>
        <div class="patient-order--options">
          <app-delivery-type [deliveryType]="deliveryType" (changeDeliveryType)="changeDeliveryType($event)">
          </app-delivery-type>
        </div>
        <div>
          <p class="errorDisplay text-left mt-3" *ngIf="isDTPError">
            {{ 'productDetailsPage.dtpSummaryError' | cxTranslate }}
          </p>
        </div>
      </div>
      <div
        *ngIf="deliveryType === deliveryTypes.DIRECT_TO_PATIENT"
        class="patient-order__addresses-container"
        [ngClass]="{ 'pb-0': deliveryAddressError }"
      >
        <div class="d-flex">
          <h5 class="__addresses-header">
            {{ 'selectProductPage.patientOrderPanel.address' | cxTranslate }}
          </h5>
          <button class="patient-order__different-address d-none d-lg-block" (click)="changeDeliveryAddress()">
            {{ 'selectProductPage.patientOrderPanel.delivertodiffAddress' | cxTranslate }}
          </button>
        </div>
        <div class="d-flex patient-order__addresses-container__patient-delivery">
          <app-address-block *ngIf="deliveryAddress" [address]="deliveryAddress"></app-address-block>
          <button class="patient-order__different-address d-md-block d-lg-none" (click)="changeDeliveryAddress()">
            {{ 'selectProductPage.patientOrderPanel.delivertodiffAddress' | cxTranslate }}
          </button>
        </div>

        <div *ngIf="deliveryAddressError">
          <p class="errorDisplay text-left mt-3">
            {{ 'productDetailsPage.addressError' | cxTranslate }}
          </p>
        </div>
      </div>
      <div class="patient-order__type patient-order__section">
        <h5 class="patient-order--heading">
          {{ 'selectProductPage.patientOrderPanel.selectOrderType' | cxTranslate }}
        </h5>
        <div class="d-flex patient-order--options patient-order__type--options-padding">
          <div class="patient-order__option--w-110">
            <input
              type="radio"
              value="Individual"
              [(ngModel)]="orderType"
              id="individual-order"
              name="orderType"
              selected="true"
              class="alconInput__radio"
              (click)="changeOrderType($event)"
              [disabled]="scheduledOrderList"
            />
            <label for="individual-order" class="patient-order--radio-label">{{
              'selectProductPage.patientOrderPanel.individual' | cxTranslate
            }}</label>
          </div>
          <div class="patient-order__option--w-110" *ngIf="subscriptionsVisibility">
            <input
              type="radio"
              value="Scheduled"
              [(ngModel)]="orderType"
              id="scheduled-order"
              name="orderType"
              class="alconInput__radio"
              (click)="changeOrderType($event)"
            />
            <label for="scheduled-order" class="patient-order--radio-label">{{
              'selectProductPage.patientOrderPanel.scheduled.orderType' | cxTranslate
            }}</label>
          </div>
        </div>
      </div>
      <div *ngIf="orderType === 'Scheduled'" class="patient-order__scheduled-order">
        <div class="d-flex patient-order__scheduled-order-repeat align-items-center">
          <div class="patient-order__scheduled-order-repeat-text">
            {{ 'selectProductPage.patientOrderPanel.scheduled.repeatEveryText' | cxTranslate }}
          </div>
          <input
            appRangeCheck
            [min]="scheduleOrder.MININUM_REPEAT_VALUE"
            [max]="scheduleOrder.MAXIMUM_REPEAT_VALUE"
            class="patient-order__scheduled-order-repeat-input"
            type="text"
            [(ngModel)]="scheduledOrderData.repeatOrderValue"
            (ngModelChange)="repeatOrderValueChange($event)"
          />
          <div class="patient-order__scheduled-order-repeat-week">
            <input
              type="radio"
              [value]="recurrenceType.weekly"
              id="scheduled-repeat-order-weeks"
              class="alconInput__radio"
              [(ngModel)]="scheduledOrderData.repeatOrderOption"
              (ngModelChange)="changeRepeatOrderTime($event)"
              name="repeatOrderOption"
            />
            <label for="scheduled-repeat-order-weeks" class="patient-order--radio-label">
              {{ 'selectProductPage.patientOrderPanel.scheduled.week' | cxTranslate }}
            </label>
          </div>
          <div class="patient-order__scheduled-order-repeat-month">
            <input
              type="radio"
              [value]="recurrenceType.monthly"
              id="scheduled-repeat-order-months"
              class="alconInput__radio"
              [(ngModel)]="scheduledOrderData.repeatOrderOption"
              (ngModelChange)="changeRepeatOrderTime($event)"
              name="repeatOrderOption"
            />
            <label for="scheduled-repeat-order-months" class="patient-order--radio-label">
              {{ 'selectProductPage.patientOrderPanel.scheduled.month' | cxTranslate }}
            </label>
          </div>
        </div>
        <div
          class="d-flex patient-order__scheduled-order-date align-items-baseline"
          appCalendar
          [configuration]="scheduledOrderConfig"
          (dateSelected)="dateSelected($event)"
        >
          <cx-icon type="Calender"></cx-icon>
          <div class="patient-order__scheduled-order-date-value">
            <span>
              {{ 'selectProductPage.patientOrderPanel.scheduled.startDate' | cxTranslate }}
            </span>
            <span>{{ scheduledOrderData.defaultDate.toLocaleDateString('en-uk') }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="patientOrder__total_Container">
      <div class="d-none patient-order__total">
        <div class="patient-order--heading patient-order__total--heading">
          {{ 'selectProductPage.patientOrderPanel.total' | cxTranslate }}
        </div>
        <div class="patient-order--heading patient-order__total--heading ml-auto">--</div>
      </div>
      <!--  visible on desktop -->
      <div class="d-none d-lg-block" *ngIf="showPrices">
        <hr class="patient-order__hor-line d-none d-lg-block" />
        <div class="d-flex justify-content-between patient-order__total-price">
          <span>{{ 'miniCartPage.total' | cxTranslate }}:</span>
          <span *ngIf="!isValuePack && isPriceVisible">{{
            totalPrice | currency: (currencyIso$ | async):'symbol-narrow':'2.2-2'
          }}</span>
          <span *ngIf="isValuePack && isPriceVisible">{{ valuePackPrice }}</span>
          <span *ngIf="!isPriceVisible">-</span>
        </div>
      </div>
      <!-- hidden on desktop -->
      <div class="d-md-block d-lg-none" *ngIf="showPrices">
        <div class="d-flex patient-order__total-price">
          <span>{{ 'miniCartPage.total' | cxTranslate }}:</span>
        </div>
        <div class="d-flex patient-order__total-price pt-1 pb-4">
          <span *ngIf="!isValuePack && isPriceVisible">{{
            totalPrice | currency: (currencyIso$ | async):'symbol-narrow':'2.2-2'
          }}</span>
          <span *ngIf="isValuePack && isPriceVisible">{{ valuePackPrice }}</span>
          <span *ngIf="!isPriceVisible">-</span>
        </div>
      </div>

      <div *ngIf="orderType === 'Individual'" class="pt-2 patient-order__add">
        <button
          appAddToCart
          [orderType]="orderFlowType"
          [product]="patientOrders"
          *ngIf="packType"
          [cartType]="cartType"
          (addingtocartLoad)="addingtocartLoad($event)"
          (addingtocartSuccess)="addingtoCartSuccess($event)"
          (emitErrorMessage)="emitErrorMessage($event)"
          (cartError)="cartError()"
          class="btn btn-primary text-uppercase ml-auto"
        >
          {{ addToCartButtonText }}
          <span class="addedToCartIcon" *ngIf="addToCartTextLoading">
            <cx-icon class="load-icon" type="LoadIcon"></cx-icon>
          </span>
        </button>
        <button
          *ngIf="!packType"
          appAddToCartValuePack
          [product]="patientOrders"
          (addingtocartLoad)="addingtocartLoad($event)"
          (addingtocartSuccess)="addingtoCartSuccess($event)"
          (emitErrorMessage)="emitErrorMessage($event)"
          (cartError)="cartError()"
          class="btn btn-primary text-uppercase ml-auto"
        >
          {{ addToCartButtonText }}
          <span class="addedToCartIcon" *ngIf="addToCartTextLoading">
            <cx-icon class="load-icon" type="LoadIcon"></cx-icon>
          </span>
        </button>
      </div>
      <div *ngIf="orderType === 'Scheduled'" class="pt-2 d-flex flex-column patient-order__scheduled-order-save">
        <button
          appSaveScheduledOrder
          [product]="patientOrders"
          [disableButton]="disableButton"
          [scheduledOrderData]="scheduledOrderData"
          [editScheduledOrderList]="scheduledOrderList"
          (orderScheduledLoad)="orderScheduledLoad($event)"
          (orderScheduledSuccess)="orderScheduledSuccess($event)"
          (emitErrorMessage)="emitErrorMessage($event)"
          (cartError)="cartError()"
          class="btn btn-primary text-uppercase ml-auto"
        >
          {{
            ((isVCUser$ | async)
              ? 'selectProductPage.patientOrderPanel.scheduled.startSubscriptions'
              : 'selectProductPage.patientOrderPanel.scheduled.save'
            ) | cxTranslate
          }}
        </button>
        <ng-container *ngIf="isVCUser$ | async">
          <button
            appAddToCart
            [orderType]="orderFlowType"
            [product]="patientOrders"
            *ngIf="packType"
            [cartType]="cartType"
            (addingtocartLoad)="addingtocartLoad($event)"
            (addingtocartSuccess)="addingtoCartSuccess($event, true)"
            (emitErrorMessage)="emitErrorMessage($event)"
            (cartError)="cartError()"
            class="btn btn-primary btn-primary--add-to-cart-now text-uppercase ml-auto mt-2"
          >
            {{ addToCartNowButtonText }}
            <span class="addedToCartIcon" *ngIf="addToCartTextLoading">
              <cx-icon class="load-icon" type="LoadIcon"></cx-icon>
            </span>
          </button>
          <button
            *ngIf="!packType"
            appAddToCartValuePack
            [product]="patientOrders"
            (addingtocartLoad)="addingtocartLoad($event)"
            (addingtocartSuccess)="addingtoCartSuccess($event, true)"
            (emitErrorMessage)="emitErrorMessage($event)"
            (cartError)="cartError()"
            class="btn btn-primary--add-to-cart-now text-uppercase ml-auto mt-2"
          >
            {{ addToCartNowButtonText }}
            <span class="addedToCartIcon" *ngIf="addToCartTextLoading">
              <cx-icon class="load-icon" type="LoadIcon"></cx-icon>
            </span>
          </button>
        </ng-container>
      </div>
      <div
        [ngClass]="
          orderType === 'Scheduled'
            ? 'd-flex justify-content-center patient-order__cancel-scheduled'
            : 'd-flex justify-content-center patient-order__cancel'
        "
        (click)="getBackToHistory()"
      >
        <span class="p-0 cancelOrder"> {{ 'selectProductPage.patientOrderPanel.cancel' | cxTranslate }} </span>
      </div>
    </div>
  </div>
</div>
<app-modal [modalOptions]="modalOptions" [showBigPopup]="true">
  <div class="delivery-address-modal">
    <div class="delivery-address-modal__close">
      <cx-icon type="Close" class="close-icon cx-icon close" (click)="closeModal()"> </cx-icon>
    </div>
    <h3 class="delivery-address-modal__header">
      {{ 'selectProductPage.patientOrderPanel.selectdeliveryAddress' | cxTranslate }}
    </h3>
    <ng-container *ngIf="openSelectdelivery">
      <app-select-delivery-address
        [patientInfo]="patientData"
        (saveAddressInfo)="saveAddressInfo($event)"
        (cancel)="closeModal()"
      ></app-select-delivery-address>
    </ng-container>
  </div>
</app-modal>
