<div>
  <div class="additional-products__additional" [ngClass]="{ 'mt-68': index === 1 }">
    <span class="additional-products__count">{{ index + 1 }}.</span>
    <span class="additional-products__radio-button-margin">
      <input
        [disabled]="disableRadio"
        type="radio"
        value="contactLensSolution"
        [(ngModel)]="radioAdditionalProducts"
        [id]="'additional-contactLensSolution_' + index"
        [name]="'additional-contactLensSolution_' + index"
        class="alconInput__radio"
        (click)="radioClickProducts('contactLensSolution')"
      />
      <label [for]="'additional-contactLensSolution_' + index" class="additional-products__radio-label">{{
        'selectProductPage.contactLens' | cxTranslate
      }}</label>
    </span>
    <span class="additional-products__radio-button-margin">
      <input
        [disabled]="disableRadio"
        type="radio"
        value="dryEyeProducts"
        [(ngModel)]="radioAdditionalProducts"
        [id]="'additional-dryEyeProducts_' + index"
        [name]="'additional-dryEyeProducts_' + index"
        class="alconInput__radio"
        (click)="radioClickProducts('dryEyeProducts')"
      />
      <label [for]="'additional-dryEyeProducts_' + index" class="additional-products__radio-label"
        >{{ 'selectProductPage.dryEye' | cxTranslate }}
      </label>
    </span>
  </div>
</div>
<div *ngIf="radioAdditionalProducts" class="d-flex">
  <div class="select-product__lensdrpdn">
    <div class="select-product__row--spacing-md">
      <app-dropdown-selector-v2
        [options]="
          radioAdditionalProducts === 'contactLensSolution'
            ? (clcList | sortPipe: 'asc')
            : (dryeyeList | sortPipe: 'asc')
        "
        [config]="lensesLabel"
        (dropDownSelected)="solutionDrpdwnSelected($event)"
      >
      </app-dropdown-selector-v2>
    </div>
  </div>
  <div (click)="deleteSolutionProducts()" class="select-product__deleteItem--icon-remove">
    <cx-icon class="Remove" type="Remove"> </cx-icon
    ><a href="javascript:void(0)">{{ 'miniCartPage.delete' | cxTranslate }}</a>
  </div>
</div>
<div *ngIf="solProducts" class="select-product__productsSelector">
  <app-basic-product-selector
    [selectedProduct]="solProducts.value"
    (selectedProductLensValue)="selectedSolutionLensValue($event)"
    [panelConfiguration]="panelConfiguration"
    [editproductData]="editSolutionData"
    [isDTP]="isDTP"
    (Errors)="panelErrors($event, solProducts.value)"
  >
  </app-basic-product-selector>
</div>
