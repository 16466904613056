<div class="header-account-selector">
  <div class="d-flex header-account-selector__type">
    <h4 class="header-account-selector__account">{{ addressTitle }}:</h4>
    <button appAccountSwitch [isSoldto]="isSoldto" class="header-account-selector__type--icon button-icon">
      <cx-icon type="Refresh" alt="switchaccount">
        <span class="header-account-selector__iconEditStyles header-account-selector__spacing">Switch</span>
      </cx-icon>
    </button>
  </div>
  <p class="header-account-selector__account-name">{{ accountDetails?.name }}</p>
  <p class="header-account-selector__unit-id">{{ accountDetails?.sapCustomerId }}</p>
  <address class="header-account-selector__street-number-city-zip-state">
    <ng-container *ngIf="accountDetails?.line1">{{ accountDetails?.line1 }}, </ng-container>
    <ng-container *ngIf="accountDetails?.line2">{{ accountDetails?.line2 }} <br /></ng-container>
    <ng-container *ngIf="accountDetails?.building">{{ accountDetails?.building }},</ng-container>
    <ng-container *ngIf="accountDetails?.appartment"> {{ accountDetails?.appartment }} <br /></ng-container>
    <ng-container *ngIf="accountDetails?.town">{{ accountDetails?.town }} </ng-container>
    <ng-container *ngIf="accountDetails?.postalCode">{{ accountDetails?.postalCode }} <br /></ng-container>
    <ng-container *ngIf="accountDetails?.region?.name">{{ accountDetails?.region?.name }}, </ng-container>
    <ng-container *ngIf="accountDetails?.country?.name">{{ accountDetails?.country?.name }} </ng-container>
  </address>
</div>
