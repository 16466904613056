import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CART_VARIENTS, DIRECT_TO_PRACTICE, PACK_SIZE } from 'src/app/shared/constants/constants';
import { BasicProductSelectedVariants, Specs } from 'src/app/shared/model/product.model';
import { Utilities } from 'src/app/shared/utility/utility';

@Component({
  selector: 'app-stockorder-variant-selector',
  templateUrl: './stockorder-variant-selector.component.html',
  styleUrls: ['./stockorder-variant-selector.component.scss'],
})
export class StockorderVariantSelectorComponent implements OnChanges, OnInit {
  contactLensType: any;
  variantTree: any;
  variantSpecs = {
    packSize: null,
    basecurve: null,
    diameter: null,
    color: null,
    powerAdd: null,
    cylinder: null,
  } as BasicProductSelectedVariants;
  @Input() product;
  @Input() cartData;
  @Output() specsSelectedEmit: EventEmitter<Specs> = new EventEmitter();
  @Output() resetLensPowers: EventEmitter<void> = new EventEmitter();
  packsizeList;
  updateCart;
  defaultPackSize: string;
  selectedPack: any;
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.product) {
      this.packsizeList = [...changes?.product?.currentValue?.packSizeList];
      this.contactLensType = changes?.product?.currentValue?.contactLensType;
    }

    if (changes?.cartData?.currentValue) {
      const currentPackSize = changes?.cartData?.currentValue?.product?.packSize;
      this.selectedPack = currentPackSize?.toLowerCase().includes(PACK_SIZE.TRIAL.toLowerCase())
        ? currentPackSize.split(' ').join('_')
        : currentPackSize;
      this.updateCart = true;
      this.setUpdateSelectedVarients(changes?.cartData?.currentValue?.specifications);
      this.packsizeList = this.packsizeList.map((pack) => {
        pack.selected = Utilities.getSelectedPackSize(currentPackSize, pack);
      });
    }
  }

  setUpdateSelectedVarients(variantSpecs): void {
    const variant = {
      packSize: null,
      basecurve: null,
      diameter: null,
      color: null,
      powerAdd: null,
      cylinder: null,
    } as BasicProductSelectedVariants;
    variant.basecurve = this.genarateSelectedVarients(variantSpecs, CART_VARIENTS.BASE_CURVE);
    variant.diameter = this.genarateSelectedVarients(variantSpecs, 'DIA');
    switch (this.contactLensType) {
      case DIRECT_TO_PRACTICE.PRODUCT_MULTIFOCAL:
        variant.powerAdd = this.genarateSelectedVarients(variantSpecs, CART_VARIENTS.ADD);
        break;
      case DIRECT_TO_PRACTICE.PRODUCT_COLOR:
        variant.color = this.genarateSelectedVarients(variantSpecs, CART_VARIENTS.COLOR);
        break;
      case DIRECT_TO_PRACTICE.PRODUCT_TORIC:
        variant.cylinder = this.genarateSelectedVarients(variantSpecs, CART_VARIENTS.CYLINDER);
        break;
    }
    this.variantSpecs = { ...variant, packSize: this.selectedPack };
  }

  genarateSelectedVarients(variantSpecs, param: string): string {
    return variantSpecs.find((node) => node.specificationKey === param)?.specificationValue;
  }

  ngOnInit(): void {
    this.packsizeList = this.product.packSizeList;
    this.variantTree = JSON.parse(this.product.variantTree);
    this.contactLensType = this.product.contactLensType;
  }

  changePacksize(selectedPackSize): void {
    if (selectedPackSize !== this.selectedPack) {
      this.resetLensPowers.emit();
    }
    this.defaultPackSize = selectedPackSize;
    this.selectedPack = selectedPackSize;
  }

  specsSelected(variantObject: Specs): void {
    this.specsSelectedEmit.emit({
      ...variantObject,
      variants: {
        ...variantObject.variants,
        packSize: this.selectedPack,
      },
    });
    this.updateCart = false;
  }
}
