import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeliveryMode } from '@spartacus/cart/base/root';

@Component({
  selector: 'app-checkout-deliverymode',
  templateUrl: './checkout-deliverymode-panel.component.html',
  styleUrls: ['./checkout-deliverymode-panel.component.scss'],
})
export class CheckoutDeliverymodeComponent implements OnInit {
  @Input() deliveryMethods: DeliveryMode[];
  @Output() deliveryChanged: EventEmitter<DeliveryMode> = new EventEmitter();
  @Input() radioSelected: string;
  constructor() {}

  ngOnInit(): void {}
  changeDeliveryMode(method: DeliveryMode): void {
    this.deliveryChanged.emit(method);
  }
}
