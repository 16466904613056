import { Action, createReducer, on } from '@ngrx/store';
import { PatientProfileData } from 'src/app/shared/model/patient.model';
import {
  AddPatient,
  ClearPatientStore,
  DeletePatient,
  LoadPatient,
  UpdatePatient,
  LoadOrderFlag,
} from '../Actions/patient.action';
export const initialState: Array<PatientProfileData> = new Array<PatientProfileData>();

const patientReducer = createReducer(
  initialState,
  on(LoadPatient, (state, { patients }) => {
    return patients;
  }),
  on(AddPatient, (state, { patient }) => {
    return [...state, { ...patient }];
  }),
  on(UpdatePatient, (state, { updatedPatient }) => {
    return state.map((patient) => (patient.patientId === updatedPatient.patientId ? { ...updatedPatient } : patient));
  }),
  on(DeletePatient, (state, { patientId }) => {
    return state.filter((patient) => patient.patientId !== patientId);
  }),
  on(ClearPatientStore, () => {
    const storeobj = initialState;
    return { ...storeobj };
  })
);

export function patientItemsReducer(state = initialState, action: Action): Array<PatientProfileData> {
  return patientReducer(state, action);
}
