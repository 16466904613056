import { Action, createReducer, on } from '@ngrx/store';
import { RetrievedValuePackVarients, GetValuePackVarients } from '../Actions/valuePack.action';

export const initialState: any = {};

const getValuePackVarients = createReducer(
  initialState,
  on(RetrievedValuePackVarients, (state, { valuePackVarients }) => {
    return valuePackVarients;
  })
);

export function valuePackReducer(state = initialState, action: Action): any {
  return getValuePackVarients(state, action);
}
