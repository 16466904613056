import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { CartItemsService } from '../services/cart-items/cart-items.service';
import { VisionCareCartItems } from '../store/States/cartItem.state';

@Injectable({
  providedIn: 'root',
})
export class OrderSimulationResolver implements Resolve<Observable<any>> {
  constructor(private cartItemsService: CartItemsService) {}
  resolve(): Observable<VisionCareCartItems> {
    return this.cartItemsService.simulateFinalPrice();
  }
}
