<div class="product-addtoCart">
  <p class="product-addtoCart__errormsg" [innerHTML]="errormessage" *ngIf="error"></p>
  <div class="d-flex justify-content-end align-items-baseline w-100">
    <div class="d-flex mr-4" *ngIf="showPrices">
      <ng-container *ngIf="price$ | async as price">
        <ng-container *ngIf="price.price">
          <span class="product-addtoCart__price">{{ price.price?.formattedValue }}*</span>
          <span class="pl-2 product-addtoCart__price--each">{{ 'productDetailsPage.each' | cxTranslate }}</span>
        </ng-container>
      </ng-container>
    </div>
    <div>
      <button
        appAddToCart
        [orderType]="orderType"
        [product]="patientOrders"
        (addingtocartSuccess)="addingtoCartSuccess($event)"
        (addingtocartLoad)="addingtocartLoad($event)"
        (emitErrorMessage)="emitErrorMessage($event)"
        class="product-addtoCart__button btn btn-primary text-uppercase ml-auto"
      >
        {{ addToCartButtonText }}
        <span class="product-addtoCart__addedToCartIcon" *ngIf="addToCartTextLoading">
          <cx-icon class="load-icon" type="LoadIcon"></cx-icon>
        </span>
        <span class="product-addtoCart__addedToCartIcon" *ngIf="addingtocartTick">
          <cx-icon class="tick-icon" type="TickIcon"></cx-icon>
        </span>
      </button>
    </div>
  </div>
  <a
    *ngIf="!((product | async)?.isDryEyeSolution || (product | async)?.isLensCareSolution)"
    class="ml-auto mr-5 product-addtoCart__purchase-link"
    [routerLink]="{ cxRoute: 'StockOrder' } | cxUrl"
    >{{ 'productDetailsPage.PurchaseStockOrder' | cxTranslate }}</a
  >
  <p *ngIf="price$ | async as price" class="product-addtoCart__price--info mb-0">
    <ng-container *ngIf="price.price && showPrices">
      {{ 'productDetailsPage.finalPrice' | cxTranslate }}
    </ng-container>
  </p>
</div>
