<div
  class="product-image-panel d-flex align-items-center pb-2 pt-3"
  *ngIf="cartType == 'update' && product?.code && !storageFalg"
>
  <div class="brand-img-container pl-4 pr-5">
    <div class="stock-image-holder">
      <cx-media [container]="product?.images" alt=" {{ product.name }}"></cx-media>
    </div>
  </div>
  <div
    class="d-flex justify-content-start align-items-center font-weight-bold"
    [ngClass]="{ 'brand-name-open': product.isOpen, 'brand-name-close': !product.isOpen }"
  >
    {{ product.name }}
  </div>
</div>

<div class="product-details-panel" [ngClass]="{ 'disable-panel': disablePanel }">
  <div class="product-details-panel__row">
    <div class="product-details-panel__packsize">
      <div class="product-details-panel__label">
        <label class="product-details-panel__label--nowidth">{{ 'stockOrderPage.packSize' | cxTranslate }}</label>
        <app-product-pack-size
          *ngIf="packSize.length > 0"
          [packSizeList]="packSize"
          (packSizeChanged)="changePacksize($event)"
        ></app-product-pack-size>
      </div>
      <div class="product-details-panel__price">
        <p>{{ price }}</p>
      </div>
    </div>
    <div class="product-details-panel__bc-dm">
      <div class="product-details-panel__bc-dm__block">
        <div class="product-details-panel__bc-dm__block--bc">
          <label for="patientName" class="product-details-panel__bc-dm__block--bc--label">{{
            'stockOrderPage.baseCurve' | cxTranslate
          }}</label>
          <div class="product-details-panel__bc-dm__block--bc--dropdown">
            <app-dropdown-selector
              [options]="BaseCurveSelector | sortPipe: 'asc'"
              (dropDownSelected)="baseCurveSelected($event)"
            >
            </app-dropdown-selector>
          </div>
        </div>
        <div class="product-details-panel__bc-dm__block--dm">
          <label for="patientName" class="product-details-panel__bc-dm__block--dm--label">{{
            'stockOrderPage.diameter' | cxTranslate
          }}</label>
          <div class="product-details-panel__bc-dm__block--dm--dropdown">
            <app-dropdown-selector [options]="DiameterSelector" (dropDownSelected)="DiameterSelected($event)">
            </app-dropdown-selector>
          </div>
        </div>
        <div class="product-details-panel__bc-dm__block--dm" *ngIf="contactLensType == 'COLOR'">
          <label for="patientName" class="product-details-panel__bc-dm__block--dm--label">{{
            'stockOrderPage.color' | cxTranslate
          }}</label>
          <div class="product-details-panel__bc-dm__block--dm--dropdown">
            <app-dropdown-selector
              [dropDownSize]="'large'"
              [options]="colorSelector | sortPipe: 'asc'"
              (dropDownSelected)="colorSelected($event)"
            >
            </app-dropdown-selector>
          </div>
        </div>
        <div class="product-details-panel__bc-dm__block--dm" *ngIf="contactLensType == 'MULTIFOCAL'">
          <label for="patientName" class="product-details-panel__bc-dm__block--dm--label">{{
            'stockOrderPage.add' | cxTranslate
          }}</label>
          <div class="product-details-panel__bc-dm__block--dm--dropdown">
            <app-dropdown-selector [options]="addSelector | sortPipe: 'asc'" (dropDownSelected)="addSelected($event)">
            </app-dropdown-selector>
          </div>
        </div>
        <div class="product-details-panel__bc-dm__block--dm" *ngIf="contactLensType == 'TORIC'">
          <label for="patientName" class="product-details-panel__bc-dm__block--dm--label">{{
            'stockOrderPage.lenscylinder' | cxTranslate
          }}</label>
          <div class="product-details-panel__bc-dm__block--dm--dropdown">
            <app-dropdown-selector
              [options]="lenscylinderSelector | sortPipe: 'asc'"
              (dropDownSelected)="lenscylinderSelected($event)"
            >
            </app-dropdown-selector>
          </div>
        </div>
      </div>
    </div>
    <div class="product-details-panel__packsize_3">
      <div class="product-details-panel__more">
        <div
          class="product-details-panel__label_3"
          *ngIf="
            LensPowerSelector &&
            LensPowerSelector.length > 40 &&
            powerIndexMinus > -1 &&
            contactLensType !== toricProduct
          "
          (click)="getMoreMinusPowers()"
        >
          <label class="product-details-panel__label_3--leftwidth">{{
            'stockOrderPage.moreMinus' | cxTranslate
          }}</label>
          <cx-icon class="expandUp" type="ExpandUp"></cx-icon>
        </div>
        <div
          class="product-details-panel__label_3"
          *ngIf="
            LensPowerSelector &&
            LensPowerSelector.length > 40 &&
            powerIndexMinus == -1 &&
            contactLensType !== toricProduct
          "
          (click)="getLessMinusPowers()"
        >
          <label class="product-details-panel__label_3--leftwidth">{{
            'stockOrderPage.lessMinus' | cxTranslate
          }}</label>
          <cx-icon class="ExpandDown" type="ExpandDown"></cx-icon>
        </div>
      </div>
      <div class="product-details-panel__power" *ngIf="contactLensType !== toricProduct">
        <div class="product-details-panel__power__block" *ngFor="let power of LensPowerSelector; index as i">
          <div *ngIf="showPower(power, i)">
            <label class="product-details-panel__power__block--leftwidth">{{ power.text }}</label>
            <input
              appOnlyNumeric
              type="text"
              class="product-details-panel__power__block--input"
              [ngClass]="{
                'product-details-panel__power__block--input-error': power.displayError
              }"
              [(ngModel)]="LensPowerSelector[i].quantity"
              (ngModelChange)="updateQuantityToPower(LensPowerSelector[i])"
            />
          </div>
        </div>
      </div>
      <div
        class="product-details-panel__label_right"
        *ngIf="LensPowerSelector && LensPowerSelector.length > 40 && powerIndexPlus &lt; LensPowerSelector.length && contactLensType !== toricProduct"
        (click)="getMorePlusPowers()"
      >
        <label class="product-details-panel__label_3--rightwidth">{{ 'stockOrderPage.morePlus' | cxTranslate }}</label>
        <cx-icon class="expandDown" type="ExpandDown"></cx-icon>
        <br />
      </div>
      <div
        class="product-details-panel__label_right"
        *ngIf="
          LensPowerSelector &&
          LensPowerSelector.length > 40 &&
          powerIndexPlus == LensPowerSelector.length &&
          contactLensType !== toricProduct
        "
        (click)="getLessPlusPowers()"
      >
        <label class="product-details-panel__label_3--rightwidth">{{ 'stockOrderPage.lessPlus' | cxTranslate }}</label>
        <cx-icon class="expandUp" type="ExpandUp"></cx-icon><br />
      </div>
    </div>
    <div class="product-details-panel__packsize_3" *ngIf="contactLensType == 'TORIC'">
      <app-stockorder-toric
        [clearLensValues]="clearLensValues"
        [productVarientTree]="variantTree"
        [clearToricQuantity]="clearQuantity"
        [errorObject]="errorResponse"
        [varients]="varients"
        [cartType]="cartType"
        [cartData]="selectedProduct"
        [storageFlag]="storageFalg"
        (getSelectedToricList)="updateQuantityToPower($event)"
        (clearLensSelected)="lensSelectedValues = []"
        (getQuantity)="getQuantityForVarients($event)"
        (setVarients)="getTheVarients($event)"
      >
      </app-stockorder-toric>
    </div>
    <div class="product-details-panel__button-box">
      <div class="product-details-panel__cancel">
        <div class="product-details-panel__error-block errorText errorDisplay" *ngIf="displayError">
          {{ 'stockOrderPage.errorMessage' | cxTranslate }} {{ maxQuantity }} {{ 'stockOrderPage.each' | cxTranslate }}
        </div>
        <div class="product-details-panel__error-block errorText errorDisplay" *ngIf="trailLimitError && trailPack">
          {{ 'stockOrderPage.errorMessage' | cxTranslate }} {{ trailLimit }}
          {{ 'stockOrderPage.forthisbrand' | cxTranslate }}
        </div>
        <div class="product-details-panel__error-block errorText errorDisplay" *ngIf="displayErrMsg && !displayError">
          {{ displayErrMsg }}
        </div>
        <div class="product-details-panel__error-block errorText errorDisplay" *ngIf="quantityError">
          {{ 'stockOrderPage.inputMessageError' | cxTranslate }}
        </div>
        <div>
          <cx-icon class="close close-icon" *ngIf="totalQuantitySelected > 0 && !editCart" type="Close"></cx-icon>
          <label
            class="product-details-panel__label--clear"
            *ngIf="totalQuantitySelected > 0 && !editCart"
            (click)="clearAll()"
            >{{ 'stockOrderPage.clearAll' | cxTranslate }}</label
          >
          <label class="product-details-panel__label--cancel" *ngIf="editCart" (click)="closePopup()">{{
            'stockOrderPage.cancel' | cxTranslate
          }}</label>
        </div>
      </div>

      <div class="product-details-panel__add-to-cart">
        <button
          appAddToCart
          [orderType]="orderType"
          [product]="lensSelectedValues"
          [positionId]="positionId"
          [cartType]="storageFalg && cartType === 'update' ? null : cartType"
          (addingtocartLoad)="addingtocartLoad($event)"
          (addingtocartSuccess)="addingtocartSuccess($event)"
          (emitErrorMessage)="emitErrorMessage($event)"
          (cartError)="cartError()"
          (updateLineItem)="setUpdatedData($event)"
          [trailPack]="trailPack"
          [trailLimit]="trailLimit"
          class="btn btn-primary text-uppercase"
        >
          {{ addToCartText }}
          <span *ngIf="totalQuantitySelected">({{ totalQuantitySelected }})</span>
          <span class="addedToCartIcon" *ngIf="addingtocartLoading">
            <cx-icon class="load-icon" type="LoadIcon"></cx-icon>
          </span>
          <span class="addedToCartIcon" *ngIf="addingtocartTick">
            <cx-icon class="tick-icon" type="TickIcon"></cx-icon>
          </span>
        </button>
      </div>
    </div>
  </div>
</div>
<app-loader-icon *ngIf="showLoader"></app-loader-icon>
