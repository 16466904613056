import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-single-listview-product-tile',
  templateUrl: './single-listview-product-tile.component.html',
  styleUrls: ['./single-listview-product-tile.component.scss'],
})
export class SingleListviewProductTileComponent implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input() product: any;
  @Input() isWishList = false;
  errorResponse: any;
  @Output() productSelected: EventEmitter<any> = new EventEmitter();
  cartType = 'create';
  constructor() {}
  ngOnInit(): void {}
  selectProduct(product): void {
    this.productSelected.emit(product);
    product.isOpen = true;
  }
  hideProduct(product): void {
    product.isOpen = false;
  }
  getValidate(event): void {
    this.errorResponse = [...event?.error.errors];
    this.product.isOpen = true;
  }
}
