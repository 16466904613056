import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { take } from 'rxjs/operators';
import { CartItemsService } from 'src/app/services/cart-items/cart-items.service';
import { LineItemCountComponent } from 'src/app/shared/classes/lineitem.count';
import { CART_GROUP, EYESIDE, EYESIDE_SHORT_FORM } from 'src/app/shared/constants/constants';
import { PageType } from 'src/app/shared/model/common.mode';
import { ReloadCart } from 'src/app/store/Actions/cartItem.action';
import { getPatientDTPOrders } from 'src/app/store/Selectors/cartItem.selector';
import { VisionCareAddtocartProductRequest } from 'src/app/store/States/cartItem.state';

@Component({
  selector: 'app-cart-dtp-orders',
  templateUrl: './cart-dtp-orders.component.html',
  styleUrls: ['./cart-dtp-orders.component.scss'],
})
export class CartDtpOrdersComponent implements OnInit {
  constructor(private cartItemsService: CartItemsService, private store: Store, private route: ActivatedRoute) {}
  @Input() cartEntry;
  @Input() orderType;
  get cartGroup(): typeof CART_GROUP {
    return CART_GROUP;
  }
  record$;
  @Input() showAddress: boolean;
  showActions: boolean;
  pageType: string;
  deliveryInfo = {
    orderNumber: '',
    deliveryMethod: '',
    deliveryAddress: null,
    patientName: '',
  };
  deliveryAddress = null;

  ngOnInit(): void {
    this.pageType = this.route.snapshot.data.Type;
    if (this.pageType === PageType.ORDER_CONFIRMATION) {
      const { rootGroups } = this.route.snapshot.data.vcCart;
      this.record$ = of(LineItemCountComponent.DTPPatientRecords(rootGroups, this.cartEntry.patientId));
    } else {
      this.record$ = this.store.select(getPatientDTPOrders(this.cartEntry.patientId));
      this.showActions = true;
    }
    const orderInfo = this.route.snapshot.data.vcCart;
    this.deliveryInfo.deliveryAddress = this.cartEntry.shippingAddress;
    this.deliveryInfo.deliveryMethod = this.cartEntry?.deliveryMode?.name;
    this.deliveryInfo.orderNumber = this.cartEntry.splitOrderRef;
    this.deliveryInfo.patientName = this.cartEntry.patientName;
    this.deliveryInfo = { ...this.deliveryInfo };
    this.deliveryAddress = this.cartEntry.shippingAddress;
  }

  updateAddress(defaultAddress: boolean): void {
    const product = [];
    this.cartItemsService.setLoading(true);
    this.cartEntry.entries.forEach((entry) => {
      product.push({
        product: { code: entry.product.code },
        eyeSight: entry.eyeSight
          ? entry.eyeSight.toLowerCase() === EYESIDE.RIGHT
            ? EYESIDE_SHORT_FORM.RIGHT
            : EYESIDE_SHORT_FORM.LEFT
          : '',
        quantity: entry.quantity.toString(),
        entryNumber: entry.entryNumber.toString(),
        orderEntryFlowType: entry.orderEntryFlowType,
        patientName: entry.patientName,
        patientId: entry.patientId,
        patientShippingAddressMain: defaultAddress,
      } as VisionCareAddtocartProductRequest);
    });

    const VCAddtocartItems = {
      entries: product,
      orderFlowType: this.cartEntry.orderEntryFlowType,
      trialProduct: false,
      positionId: '',
    };
    this.cartItemsService
      .updateCartEntryAddress({ VCAddtocartItems })
      .pipe(take(1))
      .subscribe(() => {
        this.store.dispatch(ReloadCart());
      });
  }
}
