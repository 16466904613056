<div
  class="cart-wrapper__heading"
  [ngClass]="{ 'cart-wrapper__heading-bottom': entryelement?.openPanel }"
  (click)="open()"
>
  <div class="d-flex align-items: center; justify-content-between cart-wrapper__heading-body">
    <div>
      <div class="cart-wrapper__lableEachEntry">{{ 'miniCartPage.' + entry?.label | cxTranslate }}</div>
    </div>
    <div class="d-flex align-items-center cart-wrapper__totalText">
      <label class="cart-wrapper__items">{{ quantity }} items</label>
      <label class="cart-wrapper__total"
        >{{ 'miniCartPage.total' | cxTranslate }}:
        <span>{{ finalPriceCalculated ? entry?.totalPrice?.formattedValue : '-' }}</span>
      </label>
      <div class="cart-wrapper__expand-content-down mr-3" *ngIf="!isOrderConfirmationPage && !isAccordionChange"></div>
      <div class="cart-wrapper__expand-content-up mr-3" *ngIf="!isOrderConfirmationPage && isAccordionChange"></div>
    </div>
  </div>
</div>
<div *ngIf="isOrderConfirmationPage" class="cart-wrapper__accordionChange d-print-none">
  <cx-icon type="ShowEye" *ngIf="openConfirmedOrder"> </cx-icon>
  <cx-icon type="HideEye" *ngIf="!openConfirmedOrder"> </cx-icon>
  <a href="javascript:void(0)" *ngIf="openConfirmedOrder" (click)="open()" class="cart-wrapper__product_lable">{{
    'miniCartPage.seeProduct' | cxTranslate
  }}</a>
  <a href="javascript:void(0)" *ngIf="!openConfirmedOrder" (click)="open()" class="cart-wrapper__product_lable">{{
    'miniCartPage.hideProduct' | cxTranslate
  }}</a>
</div>
<div [ngClass]="{ 'd-block': entryelement?.openPanel, 'd-none': !entryelement?.openPanel }" class="d-print-block">
  <app-cart-line-item
    [entryLabel]="entry?.label"
    [cartEntry]="cartEntry"
    [finalPriceCalculated]="finalPriceCalculated"
    *ngFor="let cartEntry of entry?.entries"
  >
  </app-cart-line-item>
  <app-alcon-cart-data
    [quantityEdit]="entry?.label === 'INDIVIDUAL_ORDER'"
    [entryLabel]="entry?.label"
    [cartEntry]="cartEntry"
    [finalPriceCalculated]="finalPriceCalculated"
    *ngFor="let cartEntry of entry?.entries"
  >
  </app-alcon-cart-data>
  <div class="cart-wrapper__value-pack" *ngFor="let valuePack of sortedValuePacks">
    <div *ngIf="entry?.children" class="cart-wrapper__value-pack__label">
      {{ 'miniCartPage.' + entry?.children[0]?.label | cxTranslate }}
    </div>
    <app-value-pack-items
      [finalPriceCalculated]="finalPriceCalculated"
      [valuePack]="valuePack"
      [isCheckoutPage]="isCheckoutPage"
    >
    </app-value-pack-items>
  </div>
</div>
