<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-10 col-xl-10">
      <cx-page-slot class="account-details__header" position="AccountDetailsHeadingPosition"></cx-page-slot>
    </div>
  </div>
  <div class="row">
    <div class="col-10 col-lg-9 col-xl-7 account-details__panel-padding">
      <cx-page-slot position="AccountDetailsContentPosition"></cx-page-slot>
    </div>
    <div class="col-10 col-lg-3 col-xl-3 account-details__panel-padding d-md-none d-lg-block">
      <cx-page-slot position="AddressChangePosition"></cx-page-slot>
    </div>
  </div>
</div>
