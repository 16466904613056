<div class="invoices">
  <div *ngIf="showInvoicesPage; else noPage" class="container-fluid">
    <div class="row justify-content-center pb-xl-5">
      <div class="col-5" [ngClass]="{ 'col-10': invoicesCount === 0 && !searchParams?.searchString }">
        <h1 class="mb-0 pb-5 text-left">{{ title$ | async }} ({{ invoicesCount }})</h1>
        <cx-page-slot
          *ngIf="invoicesCount === 0 && !searchParams?.searchString"
          position="EmptyInvoiceListDescriptionSlotPosition"
        ></cx-page-slot>
      </div>

      <div class="col-5" *ngIf="searchParams?.searchString || invoicesCount > 0">
        <cx-page-slot position="VCInvoiceSearchBoxPosition"></cx-page-slot>
      </div>
    </div>

    <div class="row">
      <div class="col-10">
        <cx-page-slot position="MyInvoiceListPaneSlotPosition"></cx-page-slot>
      </div>
      <div class="col-10 invoices__no-records" *ngIf="invoicesCount === 0 && searchParams?.searchString">
        <p>{{ 'invoicesListPage.search.noResults' | cxTranslate }}</p>
      </div>
    </div>
  </div>

  <ng-template #noPage>
    <div class="container-fluid">
      <h1 class="col-10 invoices__error-header">
        <cx-page-slot position="ErrorPageHeadingPosition"></cx-page-slot>
      </h1>
      <div class="col-10 invoices__error-message">
        <cx-page-slot position="ErrorPageMessagePosition"></cx-page-slot>
      </div>
      <div class="col-10 invoices__error-image">
        <cx-media [container]="errorImage.media" alt="error-image"></cx-media>
      </div>
    </div>
  </ng-template>
</div>
