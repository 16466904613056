import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService, UserIdService } from '@spartacus/core';
import { Observable, Subject, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { Specifications } from 'src/app/shared/constants/constants';
import { DownloadOptions, Orderhistory } from 'src/app/shared/model/orderHistory.model';
import { OrderRootGroup, PatientOrderData } from 'src/app/store/States/orderHistory';

@Injectable({
  providedIn: 'root',
})
export class OrderHistoryService {
  private paginateOrderHistorySubject = new Subject<any>();
  orderHistorylist = this.paginateOrderHistorySubject.asObservable();

  editOrderHistorySubject = new Subject<any>();
  editOrderHistorylist = this.editOrderHistorySubject.asObservable();
  private setPatientOrderHistoryCount: Subject<any> = new Subject<void>();
  getPatientOrderHistoryCount = this.setPatientOrderHistoryCount.asObservable();

  private patientOrderHistoryListSubject = new Subject<void>();
  patientOrderHistoryList = this.patientOrderHistoryListSubject.asObservable();
  constructor(
    private http: HttpClient,
    private userIdService: UserIdService,
    private occEndPoints: OccEndpointsService,
    private datePipe: DatePipe
  ) {}

  getorderHistoryList(param = null): Observable<Orderhistory> {
    let pageNo = 0;
    if (param?.currentPage) {
      pageNo = param.currentPage - 1;
    }
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap((userId) => {
        const searchurl = `/users/${userId}/orders`;
        const params = new HttpParams({ fromObject: param });
        params.set('currentPage', pageNo);
        if (!param?.filterValues && param?.category) {
          return of({
            orders: [],
            pagination: { currentPage: 0, pageSize: 20, sort: 'DEFAULT_SORT', totalNumberOfResults: 0 },
            sort: [{ code: 'DEFAULT_SORT', selected: true }],
          });
        } else {
          const url = this.occEndPoints.buildUrl(searchurl);
          return this.http.get<any>(url, { params });
        }
      })
    );
  }

  paginateOrderHistoryList(params): void {
    this.getorderHistoryList(params).subscribe((list) => {
      this.paginateOrderHistorySubject.next(list);
    });
  }

  /**
   * Fetches order history list with respective to patient
   * @param patientId holds patiend Id
   */
  getPatientOrderHistory(patientId, startDate = '', endDate = ''): Observable<any> {
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap((userId) => {
        const url = this.occEndPoints.buildUrl(
          `/users/${userId}/patientOrders?patientId=${patientId}&startDate=${startDate}&endDate=${endDate}`
        );
        return this.http.get<any>(url);
      })
    );
  }

  fetchPatientOrderHistoryList(patientId: string, startDate: string, endDate: string, isDelete: boolean): void {
    this.getPatientOrderHistory(patientId, startDate, endDate).subscribe((list) => {
      this.patientOrderHistoryListSubject.next(list);
      const hasOrders = list.orders.length > 0 || list.replenishmentOrders.length > 0;
      if (isDelete && !hasOrders) {
        this.setPatientOrderHistoryCount.next(hasOrders);
      }
    });
  }

  /**
   * Trigger when user toggle schedule order status from Active to Inactive or viceversa
   * @param orderCode replenishmentOrder number
   * @param status   active toggle status
   */
  updateActiveStatus(orderCode: string, status: boolean): Observable<any> {
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap((userId) => {
        const url = this.occEndPoints.buildUrl(`/users/${userId}/replenishmentOrders/${orderCode}?active=${status}`);
        return this.http.patch<any>(url, '');
      })
    );
  }

  /**
   * Creates Object in regards to UI fileds for both Individual and scheduled orders
   * @param list contains data (order/scheduled)
   * @param orderObject contains object which will be used in UI
   * @param orderArray  contains array of orderObjects
   * @param patientId contains rootlevel patientId
   */
  createComponentData(list: any, orderObject: PatientOrderData, orderArray: any[], patientId: string): Array<object> {
    let key: any;
    let rg: { label: string; children: any[] };
    let isDTP: boolean;

    list.forEach((element: OrderRootGroup) => {
      orderObject = {} as PatientOrderData;
      orderObject.productDetails = [];

      if (element?.replenishmentOrderCode) {
        orderObject.orderPlaced = this.splitDate(element.trigger.creationTime);
        orderObject.nextOrder = this.splitDate(element.trigger.activationTime);
        orderObject.occurance = element.trigger.displayTimeTable;
        orderObject.replenishmentOrderCode = element.replenishmentOrderCode;
        orderObject.isScheduledOrder = true;
        this.setToggleConfig(orderObject, element.active);
      } else {
        orderObject.orderPlaced = this.splitDate(element.created);
        orderObject.orderNo = element.code;
        orderObject.isScheduledOrder = false;
      }
      // considering per order there will be only one PO/DTP
      if (element?.rootGroups) {
        key = rg = { ...element.rootGroups[0] };
        if (rg.label !== 'DIRECT_TO_PATIENT') {
          orderObject.orderType = 'Practice Order';
          key = rg.children[0];
          isDTP = false;
        } else {
          orderObject.orderType = 'Direct to patient';
          isDTP = true;
        }
        this.getListDetails(key, orderObject, isDTP, orderArray, patientId);
      } else {
        orderArray.push({ ...orderObject });
      }
    });

    return orderArray;
  }
  /**
   * If the order contains both value pack and regular then
   * those will be pushed as individual entries with same order number
   * returns orderObject
   * @param group contains rootgroup array in case of DTP ; rootgroup.children[0] in case of PO
   */
  getListDetails(group, orderObject: PatientOrderData, isDTP: boolean, orderArray, patientId): any {
    let children: { shippingAddress: { defaultAddress: boolean; formattedAddress: string }; patientName: string };
    let tempObject = { ...orderObject };
    if (group?.children?.length > 0) {
      orderObject.packType = 'Value Pack';
      tempObject.patientName = group.children[0]?.patientName;
      if (isDTP) {
        orderObject = { ...this.addShippingAddress(group.children[0].entries[0], orderObject) };
      }
      //  To handle group with multiple value packs
      group.children.forEach((child) => {
        children = child.entries[0];
        orderObject = this.getProductDetails(children, orderObject, patientId);
        orderObject.patientName = children.patientName;
        orderArray.push({ ...orderObject });
      });
    }
    if (group.entries.length > 0) {
      tempObject.packType = 'Regular';
      tempObject.patientName = group.entries[0]?.patientName;
      children = isDTP ? group.entries[0] : group;
      if (isDTP) {
        tempObject = { ...this.addShippingAddress(children, tempObject) };
      }
      tempObject = this.getProductDetails(children, tempObject, patientId);
      orderArray.push({ ...tempObject });
    }
  }

  /**
   * adds shipping address to the object incase of DTP
   * @param isDTP  flag for dtp/po
   * @param children holds last level of entries[0] object
   * @param orderObject holds object which will be used in UI
   */
  addShippingAddress(children: { shippingAddress: any }, orderObject: PatientOrderData): PatientOrderData {
    if ('shippingAddress' in children) {
      orderObject.defaultAddress = children.shippingAddress.defaultAddress;
      orderObject.address = children.shippingAddress;
      return orderObject;
    }
  }
  /**
   * returns product details [right,left,sol]
   * @param children contains last level of entries[0] object in both cases of DTP and PO
   * @param orderObject contains object which will be used in UI
   */
  getProductDetails(children: any, orderObject: PatientOrderData, patientId): PatientOrderData {
    orderObject.productDetails = [];
    // entries might include different/no  patient reference orders
    const patientEntries = children.entries.filter((entry) => patientId && entry.patientId === patientId);
    if (patientEntries.length > 0) {
      patientEntries.forEach((entry, index) => {
        entry?.specifications?.sort(
          (entry1, entry2) =>
            Specifications.indexOf(entry1.specificationKey) - Specifications.indexOf(entry2.specificationKey)
        );
        orderObject.productDetails.push({
          name: entry.productName,
          specifications: entry.specifications,
          quantity: entry.quantity,
          productCode: entry.productCode,
          code: entry.product.code,
          packsize: entry.product.packSize,
          isDryEye: entry.product.isDryEyeSolution,
          isClc: entry.product.isLensCareSolution,
          orderEntryFlowType: entry.orderEntryFlowType,
          patientId: entry.patientId,
          entryNumber: entry?.entryNumber,
          discontinued: entry.product.discontinued,
        });
        if (entry.eyeSight) {
          orderObject.productDetails[index].eyeSight = entry.eyeSight === 'RIGHT' ? 'R' : 'L';
        } else if (orderObject.packType !== 'Value Pack') {
          const saleCount = entry.salesUnitCount ?? 1;
          const packSize = entry.product.packSize ?? 1;

          orderObject.productDetails[index].salesUnitCount = saleCount * packSize;
          orderObject.productDetails[index].salesUnitName = entry.salesUnitName;
        }
        if (orderObject.packType === 'Value Pack') {
          orderObject.valuePackDuration = entry?.valuePackAttributes?.valuePackDuration + '-months pack';
        }
      });
    }
    return orderObject;
  }

  /**
   * splits date from date and time
   * @param value input date and time
   */
  splitDate(value: string): string {
    return typeof value === 'string' ? this.datePipe.transform(value.split('T')[0], 'dd/MM/yyyy') : '';
  }

  /**
   * Creates toggle configuration i.e labels and status
   *
   */
  setToggleConfig(orderObject: PatientOrderData, orderStatus: boolean): void {
    orderObject.toggleConfig = { status: orderStatus, onLabel: 'ACTIVE', offLabel: 'INACTIVE' };
  }

  /**
   * Trigger when user clicks on delete  schedule order in order history
   * @param orderCode replenishmentOrder number
   */
  deleteOrder(orderCode: string): Observable<any> {
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap((userId) => {
        const url = this.occEndPoints.buildUrl(`/users/${userId}/replenishmentOrders/${orderCode}`);
        return this.http.delete<any>(url);
      })
    );
  }

  getStatementsDates(): Observable<DownloadOptions> {
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap((userId) => {
        const url = this.occEndPoints.buildUrl(`/users/${userId}/statementsHistory`);
        return this.http.get<DownloadOptions>(url);
      })
    );
  }

  downStatementsDates(date): Observable<any> {
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap((userId) => {
        const url = this.occEndPoints.buildUrl(`/users/${userId}/statement-pdf/${date}`);
        return this.http.get(url, { responseType: 'blob' });
      })
    );
  }
  reOrder(orderCode: string, buttonType?: string, entryString?: any): Observable<any> {
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap((userId) => {
        let url = this.occEndPoints.buildUrl(`/users/${userId}/orders/${orderCode}/reorder`);
        if (buttonType === 'patientReorder') {
          url = this.occEndPoints.buildUrl(
            `/users/${userId}/orders/${orderCode}/reorderForPatient?entryNumbers=${entryString}`
          );
        }
        return this.http.post(url, '');
      })
    );
  }

  cancelOrder(orderCode: string): Observable<any> {
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap((userId) => {
        const url = this.occEndPoints.buildUrl(`/users/${userId}/orders/${orderCode}/cancel`);
        return this.http.put<any>(url, {});
      })
    );
  }

  canceledOrderHistoryList(): void {
    this.getorderHistoryList().subscribe((list) => {
      this.paginateOrderHistorySubject.next(list);
    });
  }
}
