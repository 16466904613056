<ng-container *ngIf="baseSite | async">
  <app-header-nav></app-header-nav>
  <app-alcon-footer></app-alcon-footer>
  <cx-storefront></cx-storefront>
  <app-page-not-found></app-page-not-found>
  <app-user-activity [clearTheSession]="clearSession"></app-user-activity>
  <app-loader-icon *ngIf="loading$ | async"></app-loader-icon>
</ng-container>

<ng-container *ngIf="(baseSite | async) === false">
  <router-outlet></router-outlet>
</ng-container>

<app-alcon-global-message></app-alcon-global-message>
