import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewContainerRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CalendarComponent } from '../cms-components/common/calendar/calendar.component';

@Directive({
  selector: '[appCalendar]',
})
export class CalendarDirective implements OnInit, OnDestroy {
  calendarComponent: ComponentRef<CalendarComponent>;
  componentFactory: ComponentFactory<CalendarComponent>;
  @Output() dateSelected: EventEmitter<Date[]> = new EventEmitter();
  @Output() outsideClicked: EventEmitter<Date> = new EventEmitter();
  @Input() configuration;
  private destroySubscriptions$ = new Subject<void>();

  constructor(private viewContainerRef: ViewContainerRef, private componentFactoryResolver: ComponentFactoryResolver) {
    this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(CalendarComponent);
  }

  ngOnInit(): void {}

  @HostListener('click', ['$event']) calendar(e): void {
    e.stopPropagation();
    if (this.viewContainerRef.length) {
      return;
    }
    this.calendarComponent = this.viewContainerRef.createComponent(this.componentFactory);
    this.calendarComponent.instance.calendarConfig = this.configuration;
    this.calendarComponent.instance.outsideClicked.pipe(takeUntil(this.destroySubscriptions$)).subscribe(() => {
      this.outsideClicked.emit();
      this.calendarComponent.destroy();
      this.viewContainerRef.clear();
    });
    this.calendarComponent.instance.dateSelected.pipe(takeUntil(this.destroySubscriptions$)).subscribe((value) => {
      this.dateSelected.emit(value);
      this.calendarComponent.destroy();
      this.viewContainerRef.clear();
    });
  }

  ngOnDestroy(): void {
    this.destroySubscriptions$.next();
    this.destroySubscriptions$.complete();
  }
}
