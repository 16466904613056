<div class="visioncare-search">
  <label class="visioncare-search__for">{{ 'visioncareSearch.searchFor' | cxTranslate }}</label>
  <div class="visioncare-search__togggle">
    <app-switch-toggle [options]="toggleSelector"></app-switch-toggle>
  </div>

  <div *ngIf="selectedData === 'product'" class="visioncare-search__input-box">
    <app-dropdown-selector-v2
      [options]="[]"
      [config]="config"
      [filteredOptions]="searchProducts | async"
      (searchTextChange)="getProducts($event)"
      (dropDownSelected)="selectedProduct($event)"
    ></app-dropdown-selector-v2>
  </div>
  <div *ngIf="selectedData === 'patient'" class="visioncare-search__input-box">
    <app-dropdown-selector-v2
      [options]="[]"
      [config]="patientsConfig"
      [filteredOptions]="searchPatientResult | async"
      (searchTextChange)="getPatients($event)"
      (dropDownSelected)="selectedPatient($event)"
    ></app-dropdown-selector-v2>
  </div>
</div>
