import { Component, OnInit } from '@angular/core';
import { VcGlobalSearchService } from 'src/app/services/vc-global-search/vc-global-search.service';
import { AlconUserPermissionService } from 'src/app/shared-modules/alcon-user-permission-provider/service/alcon-user-permission.service';

@Component({
  selector: 'app-alconsearch',
  templateUrl: './alconsearch.component.html',
  styleUrls: ['./alconsearch.component.scss'],
})
export class AlconsearchComponent implements OnInit {
  isSearchDisplay = false;
  isVisible = true;

  constructor(
    private vcGlobalSearchService: VcGlobalSearchService,
    private userPermissionService: AlconUserPermissionService
  ) {}

  ngOnInit(): void {
    this.isVisible =
      !this.userPermissionService.getPDPButtonpermission() &&
      !this.userPermissionService.getPatientManagementPermission();
  }

  search(): void {
    this.vcGlobalSearchService.productSearch.next(true);
  }
}
