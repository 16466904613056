<label class="switch">
  <input
    type="checkbox"
    id="togBtn"
    value="toggleConfig ?.status ? toggleConfig.onLabel : toggleConfig.offLabel"
    [disabled]="disabled"
    [checked]="toggleConfig?.status"
    (click)="onToggled($event)"
  />
  <div class="slider round">
    <span class="on">{{ 'toggleValues.' + (toggleConfig?.onLabel | lowercase) | cxTranslate }}</span>
    <span class="off l-63">{{ 'toggleValues.' + (toggleConfig?.offLabel | lowercase) | cxTranslate }}</span>
  </div>
</label>
<!-- <label class="subscription" *ngIf="patientData.subscription">  // subscription future sprint
    <a href="#">{{ 'myPatientsPage.switchtoSubscription' | cxTranslate }}</a>
  </label> -->
