<div class="patient-profile-wrapper" [ngClass]="{ 'patient-profile-wrapper__noCount': (orderCount | async) === false }">
  <div class="patient-profile-wrapper__main">
    <div class="patient-profile-wrapper__heading">
      <label class="patient-profile-wrapper__patient-name">{{ patientName }}</label>
    </div>
    <div class="patient-profile-wrapper__heading">
      <div class="d-flex editDiv">
        <cx-icon (click)="editPatientProfileDetails()" type="EditBlue"
          ><span class="editText">{{ 'patientProfilePage.edit' | cxTranslate }}</span>
        </cx-icon>
      </div>
    </div>
    <div class="d-flex justify-content-end addOrderdiv">
      <button class="btn btn-primary patient-profile-wrapper__addOrder" (click)="addOrder()">
        {{ 'patientProfilePage.addOrder' | cxTranslate }}
      </button>
    </div>
  </div>
  <div class="d-flex mt-3">
    <div class="patient-profile-wrapper__personalData">
      <div class="patient-profile-wrapper__patientDataheader">
        {{ 'patientProfilePage.personalData' | cxTranslate }}:
      </div>
      <div class="d-flex patient-profile-wrapper__patientData">
        <div>{{ 'patientProfilePage.tel' | cxTranslate }}</div>
        <div class="value">{{ patientProfileInfo?.contactNumber }}</div>
      </div>
      <div class="d-flex patient-profile-wrapper__patientData">
        <div>{{ 'patientProfilePage.eMail' | cxTranslate }}</div>
        <div title="{{ patientProfileInfo?.email }}" class="value">{{ patientProfileInfo?.email }}</div>
      </div>
      <div class="d-flex patient-profile-wrapper__patientData">
        <div class="w-auto">{{ 'patientProfilePage.refNumber' | cxTranslate }}</div>
        <div title="{{ patientProfileInfo?.externalReferenceNumber }}" class="value">
          {{ patientProfileInfo?.externalReferenceNumber }}
        </div>
      </div>
    </div>

    <div *ngIf="mainAddress?.line1" class="patient-profile-wrapper__mainAddress ml-3">
      <app-address
        textSize="md"
        itemGap="sm"
        class="patient-profile-addr"
        [showColon]="true"
        [userAddress]="mainAddress"
        [addressTitle]="'patientProfilePage.mainAddress' | cxTranslate"
        [showEdit]="false"
      ></app-address>
    </div>
    <div *ngIf="additionalAddress?.line1" class="patient-profile-wrapper__addAddress ml-3">
      <app-address
        textSize="md"
        itemGap="sm"
        class="patient-profile-addr"
        [showColon]="true"
        [userAddress]="additionalAddress"
        [addressTitle]="'patientProfilePage.additionalAddress' | cxTranslate"
        [showEdit]="false"
      ></app-address>
    </div>
  </div>
  <div class="patient-profile-wrapper__noOrder" *ngIf="(orderCount | async) === false">
    <p class="mb-0">{{ 'patientProfilePage.noOrder' | cxTranslate }}</p>
  </div>

  <app-modal [modalOptions]="modalOptions" [showBigPopup]="true" (closeHandeller)="closeModal()">
    <div class="modal-block">
      <ng-container *ngIf="productOpen">
        <app-add-new-patient
          [editPatient]="patientProfileInfo"
          (cancel)="closeModal()"
          (patientAddedSuccessfully)="patientAddedSuccessfully()"
        ></app-add-new-patient>
      </ng-container>
    </div>
  </app-modal>
</div>
