import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoutesService } from '@spartacus/core';
import { Observable, Subject, of } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ProductListService } from 'src/app/services/product-list-page/product-list.service';

@Component({
  selector: 'app-product-category',
  templateUrl: './product-category.component.html',
  styleUrls: ['./product-category.component.scss'],
})
export class ProductCategoryComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroySubscription$ = new Subject<void>();
  cxRoute: Observable<string> = of('');
  categories = [];

  constructor(private productListService: ProductListService, private activatedRoutesService: ActivatedRoutesService) {}

  ngOnInit(): void {
    this.cxRoute = this.activatedRoutesService.routes$.pipe(
      map(([route1, route2]) => {
        return route2.data.cxRoute as string;
      })
    );
  }

  ngAfterViewInit(): void {
    this.productListService.categoriesFilter.pipe(takeUntil(this.destroySubscription$)).subscribe((data: any) => {
      if (data.length > 0) {
        this.categories = [...data];
      }
    });
  }

  ngOnDestroy(): void {
    this.destroySubscription$.next();
    this.destroySubscription$.unsubscribe();
  }
}
