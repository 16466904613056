<!-- static values will be replaced in future sprint -->
<div class="listWrapper">
  <div class="row divider ml-0 mr-0">
    <div class="col-6 ml-0 pl-0">
      <a class="listWrapper__name" (click)="patientOrderHistory()"
        >{{ patientData?.lastName }}, {{ patientData?.firstName }}</a
      >
    </div>
    <div class="col-4 mr-0 pr-0">
      <div class="d-flex-column listWrapper__options position-relative">
        <cx-icon
          class="pointer mr-4"
          type="AddOrderOption"
          *ngIf="patientData?.recentOrders?.length > 0"
          (click)="addOrder()"
          >{{ 'myPatientsPage.addOrder' | cxTranslate }}</cx-icon
        >
        <cx-icon class="ml-8 pointer" (click)="optionList()" type="Option">{{
          'myPatientsPage.options' | cxTranslate
        }}</cx-icon>

        <div *ngIf="optionsList" class="d-flex-column optionsList position-absolute rightPosition">
          <button class="d-flex align-items-start optionsList__item" (click)="patientOrderHistory()">
            <cx-icon type="OrderHistory"></cx-icon>
            <span class="text-left">{{ 'myPatientsPage.orderHistory' | cxTranslate }}</span>
          </button>
          <button
            class="d-flex align-items-start optionsList__item"
            (click)="editPatientDetails()"
            [disabled]="(orderLoadedFlag | async) === false"
          >
            <cx-icon type="EditInfo"></cx-icon>
            <span class="text-left">{{ 'myPatientsPage.editPatient' | cxTranslate }}</span>
          </button>
          <button
            class="d-flex align-items-start optionsList__item"
            (click)="deletePatient()"
            [disabled]="(orderLoadedFlag | async) === false"
          >
            <cx-icon type="CloseMark"></cx-icon>
            <span class="text-left">{{ 'myPatientsPage.deletePatient' | cxTranslate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="(orderLoadedFlag | async) === false; else loadedOrders">
    <div class="d-flex mt-5 justify-content-center">
      <strong>{{ 'myPatientsPage.fetchingOrders' | cxTranslate }}</strong>
      <div class="spinner-border ml-5" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </ng-container>
  <ng-template #loadedOrders>
    <ng-container *ngIf="patientData?.recentOrders?.length > 0; else noOrders">
      <table
        class="table table-borderless listWrapper__table mt-4"
        *ngFor="
          let data of patientData?.recentOrders | slice: 0:(!isOrdersCollapsed ? patientData?.recentOrders?.length : 1);
          let i = index;
          let first = first;
          let last = last
        "
      >
        <thead>
          <th class="listWrapper__table--order p-0 align-top">
            <p *ngIf="data.isScheduledOrder; else orderno">
              {{ 'myPatientsPage.subscription' | cxTranslate }}
            </p>
            <ng-template #orderno>
              <span> {{ 'myPatientsPage.orderNo' | cxTranslate }} </span>
              <a
                class="text-decoration-none switchPatient"
                [routerLink]="{ cxRoute: 'orderDetailsPage', params: { orderid: patientData?.order?.code } } | cxUrl"
              >
                {{ data?.orderNo }}
              </a>
            </ng-template>
          </th>
          <th class="listWrapper__table--delivery p-0">
            <p>{{ 'myPatientsPage.delivery' | cxTranslate }}</p>
          </th>
          <th class="listWrapper__table--packtype p-0">
            <p>{{ 'myPatientsPage.packtype' | cxTranslate }}</p>
          </th>
          <th class="listWrapper__table--date p-0">
            <p>{{ 'myPatientsPage.date' | cxTranslate }}</p>
          </th>
          <th class="listWrapper__table--status p-0">
            <p>{{ 'myPatientsPage.status' | cxTranslate }}</p>
          </th>
        </thead>
        <tbody>
          <tr *ngIf="data?.isValid; else warning">
            <td
              class="listWrapper__table__productDetails listWrapper__table--order"
              [ngClass]="{ divider: !last || (isOrdersCollapsed && !last) }"
            >
              <div class="d-flex flex-column text-break mr-3" *ngFor="let productData of data.productDetails">
                <label>{{ productData.name }}</label>
                <div
                  class="listWrapper__table__productDetails__sku"
                  *ngIf="productData?.specifications?.length > 0; else solution"
                >
                  <p class="mb-0">
                    <span>{{ productData?.eyeSight }}</span>
                    <span *ngFor="let specification of productData.specifications">
                      {{ 'checkoutPage.' + specification.specificationKey | cxTranslate }}:
                      {{ specification.specificationValue }}
                    </span>
                    <span>qty.{{ productData?.quantity }}</span>
                  </p>
                </div>

                <ng-template #solution>
                  <span class="listWrapper__table__productDetails__sku"> qty.{{ productData?.quantity }} </span>
                </ng-template>
              </div>
            </td>
            <td class="listWrapper__table--delivery" [ngClass]="{ divider: !last || (isOrdersCollapsed && !last) }">
              <div class="addresscard" [ngClass]="{ showAddress: !isAddressCollapsed[i] }">
                <span class="d-flex pointer" (click)="isAddressCollapsed[i] = !isAddressCollapsed[i]">
                  <cx-icon *ngIf="isAddressCollapsed[i]" type="DropdownExpandBlack"></cx-icon>
                  <cx-icon *ngIf="!isAddressCollapsed[i]" type="DropdownCollapseBlack"></cx-icon>
                  <p class="ml-8">
                    {{
                      isAddressCollapsed[i]
                        ? (data?.orderType | removeWhitespace | lowercase | cxTranslate)
                        : ('myPatientsPage.address' | cxTranslate)
                    }}
                  </p>
                </span>
                <div
                  *ngIf="
                    (patientData?.order?.deliveryAddress || patientData?.replenishmentOrder?.deliveryAddress) &&
                    !isAddressCollapsed[i]
                  "
                >
                  <app-address-block
                    [address]="
                      data.isScheduledOrder
                        ? patientData?.replenishmentOrder?.deliveryAddress
                        : patientData?.order?.deliveryAddress
                    "
                  ></app-address-block>
                </div>
              </div>
            </td>
            <td class="listWrapper__table--packtype" [ngClass]="{ divider: !last || (isOrdersCollapsed && !last) }">
              <p>{{ data?.packType | removeWhitespace | lowercase | cxTranslate }}</p>
            </td>
            <td class="listWrapper__table--date" [ngClass]="{ divider: !last || (isOrdersCollapsed && !last) }">
              <ng-container *ngIf="data.isScheduledOrder; else individual">
                {{ 'patientOrderHistory.nextOrder' | cxTranslate }} {{ data?.nextOrder }}
              </ng-container>
              <ng-template #individual
                >{{ 'patientOrderHistory.orderSent' | cxTranslate }} {{ data?.orderPlaced }}
              </ng-template>
            </td>
            <td
              class="text-left listWrapper__table--status"
              [ngClass]="{
                divider: !last || (isOrdersCollapsed && !last),
                listWrapper__table__switchSub: !data.isScheduledOrder
              }"
            >
              <p *ngIf="data.isScheduledOrder; else switchSub">
                <app-change-subscription-status
                  [subData]="patientData.recentOrders[i]"
                  [toggleData]="data?.toggleConfig"
                  [orderCode]="data.replenishmentOrderCode"
                  (updatedData)="updatePatientData($event, i)"
                >
                </app-change-subscription-status>
              </p>
              <app-re-order
                *ngIf="!data.isScheduledOrder"
                [productDetails]="patientData.recentOrders[i].productDetails"
                [orderID]="data.orderNo"
                class="d-block pb-4"
                [buttonType]="'patientReorder'"
                (reOrderResponse)="reOrderResponse($event, data.orderType)"
              >
              </app-re-order>
              <p class="mt-2 pr-2" *ngIf="data.isScheduledOrder; else onlyOccurance">
                {{ 'patientOrderHistory.frequency' | cxTranslate | uppercase }}:
                <br />
                {{ 'patientOrderHistory.every' | cxTranslate }} {{ data?.occurance }}
              </p>
              <ng-template #onlyOccurance>
                <p class="mt-2">{{ 'patientOrderHistory.oneTime' | cxTranslate | uppercase }}</p>
              </ng-template>

              <!--
              For R2 so commenting it out
              <ng-template #switchSub>
              <a role="button" href="javascript:void(0)"> {{ 'patientOrderHistory.switchSub' | cxTranslate }}</a>
            </ng-template> -->
            </td>
          </tr>
        </tbody>
      </table>
      <div
        *ngIf="patientData?.recentOrders?.length > 1"
        class="d-flex d-lg-none pointer listWrapper__showMore"
        (click)="isOrdersCollapsed = !isOrdersCollapsed"
      >
        <cx-icon *ngIf="isOrdersCollapsed" type="DropdownExpandBlack"></cx-icon>
        <cx-icon *ngIf="!isOrdersCollapsed" type="DropdownCollapseBlack"></cx-icon>
        <p class="ml-2">
          {{ (isOrdersCollapsed ? 'myPatientsPage.showMore' : 'myPatientsPage.showLess') | cxTranslate }}
        </p>
      </div>
    </ng-container>
  </ng-template>
  <ng-template #noOrders>
    <div class="text-center">
      <p class="listWrapper__table__noOrders">{{ 'patientOrderHistory.noOrdersYet' | cxTranslate }}</p>
      <button class="btn btn-primary addOrder__btn" (click)="addOrder()">
        {{ 'myPatientsPage.addOrderButton' | cxTranslate }}
      </button>
    </div>
  </ng-template>
</div>
<app-modal [modalOptions]="modalOptions" [showBigPopup]="true" (closeHandeller)="closeModal()">
  <div class="modal-block">
    <ng-container *ngIf="productOpen">
      <app-add-new-patient
        [editPatient]="patientData"
        (cancel)="closeModal()"
        (patientAddedSuccessfully)="patientAddedSuccessfully()"
      ></app-add-new-patient>
    </ng-container>
  </div>
</app-modal>
<ng-template #warning>
  <tr>
    <td colspan="1">
      <cx-icon class="d-flex warning-message" type="WarningIcon"
        >{{ 'myPatientsPage.warningMessage' | cxTranslate }}
      </cx-icon>
    </td>
  </tr>
</ng-template>
