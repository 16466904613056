import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { PatientService } from '../services/patient/patient.service';

@Injectable({ providedIn: 'root' })
export class PatientListResolver implements Resolve<Observable<any>> {
  constructor(private patientService: PatientService) {}

  resolve(): Observable<any> {
    return this.patientService.getPatientStoreData(false);
  }
}
