import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseSiteService, CmsService, TranslationService } from '@spartacus/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { PRODUCT_TYPES } from 'src/app/shared/constants/constants';
import { AlconBaseSite } from 'src/app/shared/model/alcon-basesite.model';
import { ModalOptions } from 'src/app/shared/model/common.mode';
import { UpdateUserProfileService } from '../../../services/updateuserprofile/update-user-profile.service';
import { ValidatorService } from '../../../services/validators/validator.service';
import { ContactusConfig } from '../../../shared/config/contactus-config';
import { EContactUsSubmitButtonPosition } from '../../../shared/config/contactus-submitButtonPosition.enum';
import { ContactUsType } from '../../../shared/config/contactus-type.enum';
import { DropdownOptions } from '../../../shared/model/common.mode';
import { IRegisterFormData } from '../i-register-form-data';

@Component({
  selector: 'app-register-form-step',
  templateUrl: './register-form-step.component.html',
  styleUrls: ['./register-form-step.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterFormStepComponent implements OnInit, OnDestroy {
  @Input() formData!: IRegisterFormData;
  @Output() public submitForm = new EventEmitter<IRegisterFormData>();

  public registerForm: UntypedFormGroup;
  public modalOptions: ModalOptions = {
    open: false,
    header: false,
    modalTitle: '',
    borderRadius: '15px',
    backgroundColor: '#fff',
    class: 'modal-size',
  } as ModalOptions;

  public thankYouModalOptions: ModalOptions = {
    open: false,
    header: false,
    modalTitle: '',
    borderRadius: '20px',
    closeIcon: false,
    ctaText: 'contactusPage.returnToLastVisitedPage',
    ctaClass: 'thankyou-cta',
  };

  public modalConfigs: ContactusConfig = {
    showMandatoryFieldText: false,
    isInModal: true,
    type: ContactUsType.accountCreation,
    submitButtonPosition: EContactUsSubmitButtonPosition.center,
  };

  public contactFormConfig = {
    contactname: ['', [Validators.required, Validators.maxLength(30)]],
    contactMethod: this.formBuilder.array([], Validators.required),
    preferredTime: ['', [Validators.required]],
    companyName: ['', [Validators.required]],
    email: [''],
    phoneNumber: [''],
    termsConditions: [false],
  };

  public titles$: Observable<DropdownOptions[]> = this.updateService.titles$.pipe(
    map((titles) =>
      titles.map(
        (title) =>
          ({
            text: title.name,
            value: title.code,
            selected: this.formData?.titleCode === title.code,
          } as DropdownOptions)
      )
    )
  );
  dropdownConfig = this.translation.translate('registerPage.selectProductType').pipe(
    map((data) => {
      return { defaultSelectText: data };
    })
  );
  private destroy$ = new Subject<void>();
  franchiseOptions: Observable<DropdownOptions[]> = forkJoin([
    this.translation.translate('registerPage.productTypeSurgical').pipe(take(1)),
    this.translation.translate('registerPage.productTypeVisionCare').pipe(take(1)),
  ]).pipe(
    take(1),
    map(([productTypeSurgical, productTypeVisionCare]) => {
      return [
        {
          text: productTypeSurgical,
          value: PRODUCT_TYPES.SURGICAL_VALUE,
          selected: this.formData?.userGroups?.includes(PRODUCT_TYPES.SURGICAL_VALUE) ? true : false,
        },
        {
          text: productTypeVisionCare,
          value: PRODUCT_TYPES.VISION_CARE_PRDUCT_VALUE,
          selected: this.formData?.userGroups?.includes(PRODUCT_TYPES.VISION_CARE_PRDUCT_VALUE) ? true : false,
        },
      ];
    })
  );

  productTypeError = false;
  manualRegistration = false;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public updateService: UpdateUserProfileService,
    public validator: ValidatorService,
    public cmsService: CmsService,
    private translation: TranslationService,
    private baseSite: BaseSiteService
  ) {}

  ngOnInit(): void {
    this.baseSite
      .getActive()
      .pipe(
        takeUntil(this.destroy$),
        switchMap((currentSite) => {
          return this.baseSite.get(currentSite);
        })
      )
      .subscribe((siteDetails: AlconBaseSite) => {
        this.manualRegistration = siteDetails?.manualRegistration;
        this.populateForm();
      });
    this.registerForm.statusChanges.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (!this.userGroups.value) {
        this.productTypeError = true;
      }
    });
  }

  populateForm(): void {
    this.registerForm = this.formBuilder.group({
      titleCode: [this.formData?.titleCode],
      firstName: [this.formData?.firstName, [Validators.required, Validators.maxLength(30)]],
      lastName: [this.formData?.lastName, [Validators.required, Validators.maxLength(30)]],
      email: [this.formData?.email, [Validators.required, Validators.email, Validators.maxLength(30)]],
      companyName: [this.formData?.companyName, [Validators.required, Validators.maxLength(30)]],
      accountNumber: [this.formData?.accountNumber, [Validators.required, this.validator.accountNumberValidator()]],
      userGroups: [
        this.manualRegistration ? this.formData.userGroups : [PRODUCT_TYPES.VISION_CARE_PRDUCT_VALUE],
        this.manualRegistration ? [Validators.required] : null,
      ],
    });
  }

  public next(): void {
    this.submitForm.emit(this.registerForm.value);
    window.scrollTo(0, 0);
  }

  get titleCode(): AbstractControl {
    return this.registerForm.get('titleCode');
  }

  get firstName(): AbstractControl {
    return this.registerForm.get('firstName');
  }

  get lastName(): AbstractControl {
    return this.registerForm.get('lastName');
  }

  get email(): AbstractControl {
    return this.registerForm.get('email');
  }

  get companyName(): AbstractControl {
    return this.registerForm.get('companyName');
  }

  get accountNumber(): AbstractControl {
    return this.registerForm.get('accountNumber');
  }

  get userGroups(): AbstractControl {
    return this.registerForm.get('userGroups');
  }

  setProductType(event): void {
    this.registerForm.get('userGroups').patchValue([event.value]);
    this.productTypeError = false;
  }

  openContactForm(event: Event): void {
    event.preventDefault();
    this.modalOptions.open = true;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
