import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Route, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule, PageMetaResolver, UrlModule } from '@spartacus/core';
import { IconModule, LinkModule, MediaModule, PageComponentModule, PageSlotModule } from '@spartacus/storefront';
import { AlconCmsComponentsModule } from '../cms-components/alcon-cms-components.module';
import { AlconCommonModule } from '../cms-components/common/alcon-common.module';
import { AlconPatientModule } from '../cms-components/patient/patient.module';
import { ProductPageResolver } from '../resolver/metaresolvers/product-page.resolver';
import { AlconAccountDetailsPageComponent } from './alcon-account-details-page/alcon-account-details-page.component';
import { AlconFavoriteListComponent } from './alcon-favorite-list/alcon-favorite-list.component';
import { CartComponent } from './cart/cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ContactusPageComponent } from './contactus-page/contactus-page.component';
import { FranchiseSelectorPageComponent } from './franchise-selector-page/franchise-selector-page.component';
import { LegalAnnouncementComponent } from './legal-announcement/legal-announcement.component';
import { MyPatientComponent } from './my-patient/my-patient.component';
import { NewOrderComponent } from './new-order/new-order.component';
import { OrderConfirmationComponent } from './order-confirmation/order-confirmation.component';
import { PatientProfileComponent } from './patient-profile/patient-profile.component';
import { ProductListPageComponent } from './product-list-page/product-list-page.component';
import { RegisterPageComponent } from './register-page/register-page.component';
import { RegisterationConfirmPageComponent } from './registeration-confirm-page/registeration-confirm-page.component';
import { SiteNotFoundComponent } from './site-not-found/site-not-found.component';
import { SurgicalRegisterPageComponent } from './surgical-register-page/surgical-register-page.component';
import { ToolsAndResourcesComponent } from './tools-resources/tools-resources.component';

const routes: Route[] = [];

@NgModule({
  declarations: [
    AlconFavoriteListComponent,
    LegalAnnouncementComponent,
    CartComponent,
    CheckoutComponent,
    OrderConfirmationComponent,
    MyPatientComponent,
    PatientProfileComponent,
    ContactusPageComponent,
    NewOrderComponent,
    ToolsAndResourcesComponent,
    ProductListPageComponent,
    SiteNotFoundComponent,
    AlconAccountDetailsPageComponent,
    RegisterPageComponent,
    SurgicalRegisterPageComponent,
    FranchiseSelectorPageComponent,
    RegisterationConfirmPageComponent,
  ],
  imports: [
    CommonModule,
    PageSlotModule,
    PageComponentModule,
    AlconCmsComponentsModule,
    AlconCommonModule,
    IconModule,
    MediaModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    UrlModule,
    RouterModule.forChild(routes),
    LinkModule,
    AlconPatientModule,
    NgbModule,
  ],
  providers: [
    {
      provide: PageMetaResolver,
      useExisting: ProductPageResolver,
      multi: true,
    },
  ],
})
export class AlconCmsPagesModule {}
