<a
  *ngIf="!checkBox"
  [routerLink]="getRouterLink() | async"
  class="facet__link"
  [ngClass]="{ 'facet__link--selected': item?.selected }"
  [queryParams]="getLinkParams()"
  ><span *ngIf="translate; else name"></span>{{ item?.name | removeWhitespace | lowercase | cxTranslate }}</a
>

<ng-template #name>{{ item?.name }}</ng-template>
<ng-container *ngIf="checkBox">
  <input
    [id]="checkBoxId"
    type="checkbox"
    name="facetvalue"
    (change)="checkBoxChangeHandler()"
    [(ngModel)]="facetvalue"
    class="alconInput__checkbox"
  />
  <label [for]="checkBoxId" class="facet__link">{{ individualOrderDD ? item?.displayName : item?.name }}</label>
</ng-container>
