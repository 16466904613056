import { Component } from '@angular/core';
import { TranslationService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DropdownOptions } from 'src/app/shared/model/common.mode';
import { CustomerContactService } from '../../services/customer-contact.service';
declare module 'src/app/shared/model/common.mode' {
  interface DropdownOptions {
    branches?: IBranches[];
  }
}
interface IBranches {
  name: string;
  email?: string;
  phone?: string;
}
@Component({
  selector: 'app-customer-service-details',
  templateUrl: './customer-service-details.component.html',
  styleUrls: ['./customer-service-details.component.scss'],
})
export class CustomerServiceDetailsComponent {
  zoneLabel = this.translation.translate('loginPage.selectZone').pipe(
    map((data) => {
      return { defaultSelectText: data };
    })
  );
  branchesLabel = this.translation.translate('loginPage.selectBranch').pipe(
    map((data) => {
      return { defaultSelectText: data };
    })
  );
  disableBranch = true;
  branches: DropdownOptions[] = [];
  selectedZone: any;
  contactDetails = [];
  zoneDropdownOptions: Observable<DropdownOptions[]> = this.getRegionalZone();
  constructor(private customerContactService: CustomerContactService, private translation: TranslationService) {}

  getRegionalZone(): Observable<DropdownOptions[]> {
    return this.customerContactService.getRegionalZone().pipe(
      map((zones) => {
        return zones.regionalZones
          .sort((zone1, zone2) => (zone1.name.toLowerCase() > zone2.name.toLowerCase() ? 1 : -1))
          .map(
            (zone) =>
              ({
                text: zone.name,
                value: zone.code,
                selected: false,
                branches: zone.branches.sort((zone1, zone2) =>
                  zone1.name.toLowerCase() > zone2.name.toLowerCase() ? 1 : -1
                ),
              } as DropdownOptions)
          );
      })
    );
  }

  zoneSelected(event: DropdownOptions): void {
    this.contactDetails = [];
    if (event.value) {
      this.selectedZone = event;
      this.disableBranch = false;
      this.branches = [...new Set(event.branches.map((item) => item.name))].map((branchName) => {
        return {
          text: branchName,
          value: branchName,
          selected: false,
        } as DropdownOptions;
      });
      if (this.branches.length === 1) {
        this.branchSelected(this.branches[0]);
      }
    }
  }

  branchSelected(event: DropdownOptions): void {
    this.contactDetails = [];
    if (event.value) {
      this.contactDetails = this.selectedZone.branches
        .filter((branches) => branches.name === event.value)
        .sort((value) => {
          return value.isVCS ? 1 : -1;
        });
    }
  }
}
