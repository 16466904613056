<div class="productSelector">
  <div class="productSelector__list">
    <div class="productSelector__product-tile">
      <div class="d-flex w-100 justify-content-end">
        <app-favorite-product
          [isFavourite]="product?.favourite"
          [productCode]="product?.code"
          iconSize="sm"
          *ngIf="isFavouritePage$ | async"
        >
        </app-favorite-product>
      </div>
      <div class="productSelector__product-tile__image-holder">
        <cx-media
          [container]="product?.privateLabel ? product?.images[product?.images.length - 1] : product?.images"
          alt="{{ product?.name }}"
        ></cx-media>
      </div>
      <p class="productSelector__product-tile__product-name">
        {{ product?.name }}
      </p>
      <ng-container *ngIf="!isValuePack && showPrices">
        <ng-container *ngIf="price$ | async as price">
          <span class="productSelector__product-tile__price"
            >{{ price.price?.formattedValue }}
            <span *ngIf="price.price?.formattedValue" class="productSelector__product-tile__each-label">
              {{ 'stockOrderPage.each' | cxTranslate }}
            </span>
          </span>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div class="flex-column product-details w-100" *ngIf="product">
    <app-product-selector
      [isValuePack]="isValuePack"
      [dtpError]="dtpError"
      [resetMaxQuantityError]="resetMaxQuantityError"
      (changeDtpError)="setDtpError($event)"
      [selectedProduct]="product"
      (selectedProductLensValue)="selectedProductLens($event)"
      (changeProductQuantity)="selectedProductQuantity($event)"
      (checkboxStatus)="checkboxStatusChange($event)"
      [disableCheckbox]="disableCheckbox"
      [disable]="disable"
      [editproductData]="editproductData"
      [isDTP]="isDTP$ | async"
      [panelConfiguration]="panelConfiguration"
      (Errors)="setPanelErrors($event)"
      [isValuePackSolution]="isValuePackSolution"
      [refreshProductPanel]="refreshProductPanel"
    ></app-product-selector>
  </div>
</div>
<app-loader-icon *ngIf="showLoader"></app-loader-icon>
