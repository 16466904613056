export interface DropdownOptions {
  text?: string;
  value?: string;
  selected?: boolean;
  iconType?: string;
  title?: string;
  uid?: string;
  image?: string;
}

export enum DropdownSelectedStatus {
  NEUTRAL = 1,
  SUCCESS = 2,
}

export interface DropdownConfig {
  showOptionHeader?: boolean;
  defaultSelectText?: string;
  typeAheadConfig?: TypeAheadConfig;
  buttons?: Array<DropdownButtonConfig>;
  maxItemsShown?: number;
  iconReference?: string;
  noDataText?: string;
  disableOnOnlyOption?: boolean;
}

export interface TypeAheadConfig {
  typeAhead?: boolean;
  typeAheadLength?: number;
  internalSearch?: boolean;
}

export interface DropdownButtonConfig {
  name: string;
  buttonId: string;
  iconRef: string;
}

export interface ModalOptions {
  open?: boolean;
  modalTitle?: string;
  header?: boolean;
  class?: string;
  width?: string;
  borderRadius?: string;
  backgroundColor?: string;
  closeIcon?: boolean;
  ctaText?: string;
  ctaClass?: string;
}

export interface PaymentMode {
  value?: string;
  code?: string;
  isSelected?: boolean;
}
export enum FavouriteIconSize {
  LARGE = 'lg',
  SMALL = 'sm',
}
export interface ChangePasswordModel {
  oldPassword?: string;
  newPassword?: string;
  checkPassword?: string;
}
export enum PageType {
  ORDER_CONFIRMATION = 'OrderConfirmation',
  CART = 'Cart',
  CHECKOUT = 'Checkout',
  STOCK_ORDER = 'StockOrder',
  ORDER_DETAILS = 'orderDetailsPage',
  RETURN_FORM_CREATE_PAGE = 'returnFormCreatePage',
}
export interface SwitchOptions {
  code?: string;
  isSelected?: boolean;
  value?: string;
}
export interface ErrorItem {
  message?: string;
  subject?: string;
}

export interface NavgationMenu {
  children?: any;
  image?: object;
  title?: string;
  url?: string;
}
