import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ConfigModule, I18nModule } from '@spartacus/core';
import { AlconCmsComponentsModule } from 'src/app/cms-components/alcon-cms-components.module';
import { AlconCommonModule } from 'src/app/cms-components/common/alcon-common.module';
import { CustomerServiceDetailsComponent } from './components/customer-service-details/customer-service-details.component';
import { CustomerContactService } from './services/customer-contact.service';

@NgModule({
  declarations: [CustomerServiceDetailsComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    ConfigModule,
    RouterModule,
    FormsModule,
    I18nModule,
    AlconCommonModule,
    AlconCmsComponentsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        UKMarketingPageTopSection2BFlex: {
          component: CustomerServiceDetailsComponent,
        },
      },
    }),
  ],
  providers: [CustomerContactService],
  exports: [CustomerServiceDetailsComponent],
})
export class CustomerContactDetailsModule {}
