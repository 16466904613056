<div class="panel-container">
  <div class="plp-delivery">
    <div class="plp-delivery__delivery plp-delivery__section">
      <div class="select-delivery">
        <h5 class="plp-delivery--heading">{{ 'configureProducts.deliveryPanel.selectDelivery' | cxTranslate }}</h5>
        <div class="row plp-delivery--options">
          <app-delivery-type
            [deliveryType]="deliveryType"
            [disable]="$disable | async"
            (changeDeliveryType)="deliveryTypeChanged($event)"
          >
          </app-delivery-type>
        </div>
        <p class="errorDisplay errorDisplay--orange text-left mt-3 custom-left-margin" *ngIf="dtpError">
          {{ 'productDetailsPage.dtpSummaryError' | cxTranslate }}
        </p>
        <app-patient-info-selection
          [disable]="$disable | async"
          [reset]="resetPatientInfo"
          [isDTP]="deliveryType === deliveryTypes.DIRECT_TO_PATIENT"
          (changeAddress)="changeAddress($event)"
          (addPatient)="addPatient($event)"
          (patientSelected)="patientSelected($event)"
          (hasAddress)="noAddress = $event"
        >
        </app-patient-info-selection>
      </div>

      <div class="add-to-cart">
        <div class="d-none plp-delivery__price text-center">
          <div class="plp-delivery__price--value">£00.00</div>
        </div>
        <div class="plp-delivery__add">
          <button
            appAddToCart
            [disableButton]="$disable | async"
            [orderType]="deliveryType"
            [product]="orders"
            [cartType]="cartType"
            (addingtocartLoad)="addingtocartLoad($event)"
            (addingtocartSuccess)="addingtoCartSuccess()"
            (emitErrorMessage)="emitErrorMessage($event)"
            class="btn btn-primary text-uppercase ml-auto"
          >
            {{ addToCartButtonText }}
            <span class="addedToCartIcon" *ngIf="addToCartTextLoading">
              <cx-icon class="load-icon" type="LoadIcon"></cx-icon>
            </span>
          </button>
        </div>
        <div class="plp-delivery__stock-order text-center" routerLink="/stock-order" (click)="closeModal()">
          <span> {{ 'configureProducts.deliveryPanel.needStockOrder' | cxTranslate }} </span>
        </div>
      </div>
    </div>
  </div>
</div>
