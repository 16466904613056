import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService, UserIdService } from '@spartacus/core';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { DropdownOptions } from 'src/app/shared/model/common.mode';
import { ReturnResponse } from 'src/app/shared/model/return-product.model';

@Injectable({
  providedIn: 'root',
})
export class ReturnFormService {
  public accordionStatus: Subject<any> = new Subject<void>();
  getAccordionStatus = this.accordionStatus.asObservable();
  constructor(
    private http: HttpClient,
    private userIdService: UserIdService,
    private occEndPoints: OccEndpointsService
  ) {}

  getProducts(): Observable<Array<DropdownOptions>> {
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap((userId: string) => {
        const url = this.occEndPoints.buildUrl(`users/${userId}/returns`);
        return this.http.get<any>(url);
      }),
      map((products) =>
        products.map((prd) => {
          return {
            code: prd.code,
            text: prd.name,
            selected: false,
            image: prd.images.find((i) => i.format === 'thumbnail').url,
          } as DropdownOptions;
        })
      )
    );
  }
  getReturnProductInfo(productCode): Observable<ReturnResponse> {
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap((userId: string) => {
        const url = this.occEndPoints.buildUrl(`users/${userId}/returnsSpecification/${productCode}`);
        return this.http.get<any>(url);
      })
    );
  }
  saveReturnProductInfo(returnData): Observable<any> {
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap((userId: string) => {
        const url = this.occEndPoints.buildUrl(`users/${userId}/createReturn`);
        return this.http.post<any>(url, returnData);
      })
    );
  }
  sendReturnConfirmationToAdditionalEmail(returnInfo): Observable<any> {
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap((userId: string) => {
        const url = this.occEndPoints.buildUrl(
          `users/${userId}/returnAlternateMail/${returnInfo.returnCode}/${returnInfo.email}`
        );
        return this.http.post<any>(url, '');
      })
    );
  }
}
