import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { OccEndpointsService, ProductService, RoutingService } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { AlconUserPermissionService } from 'src/app/shared-modules/alcon-user-permission-provider/service/alcon-user-permission.service';
import { CurrentUserIdService } from 'src/app/shared-modules/user-id-provider/service/current-user-id.service';
import { DIRECT_TO_PRACTICE, PACK_SIZE } from 'src/app/shared/constants/constants';
import { DropdownOptions } from 'src/app/shared/model/common.mode';
import { PackSize, ProductInfo, SKULevelDetails, VarientCode } from 'src/app/shared/model/product.model';
import { VisionCareAddtocartProductRequest } from 'src/app/store/States/cartItem.state';

declare module '@spartacus/core' {
  interface Product {
    variantTree?: any;
    packSizeList?: Array<PackSize>;
    privateLabel?: boolean;
    isLensCareSolution?: boolean;
    favourite?: boolean;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProductDetailsService extends CurrentProductService {
  isPriceVisible: boolean;
  packSizePriceChanged$$: BehaviorSubject<any> = new BehaviorSubject([]);
  productSelectionChanged = new EventEmitter();
  private isAddtoCartEnabled = false;
  selectedVarientInfo = [];

  private isDTPFlag: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  addToCartButtonSubject = new BehaviorSubject<any>(this.isAddtoCartEnabled);
  checkAddToCartButton = this.addToCartButtonSubject.asObservable();

  refreshPanelSubject = new Subject<boolean>();
  refreshPanel = this.refreshPanelSubject.asObservable();

  errorSubject = new Subject<string>();
  errorMsg = this.errorSubject.asObservable();
  private priceArray = [];
  private totalPriceArray = [];
  constructor(
    routingService: RoutingService,
    productService: ProductService,
    private occEndPoints: OccEndpointsService,
    private httpClient: HttpClient,
    private userIdService: CurrentUserIdService,
    private userPermissionService: AlconUserPermissionService
  ) {
    super(routingService, productService);
    this.isPriceVisible = this.userPermissionService.getPriceStatementPermission();
  }

  getProductInfo(productCode, pageType): Observable<any> {
    let url = '';
    const userId = this.userIdService.getUserId();
    let urlString = `users/${userId}/products/${productCode}`;
    if (pageType === DIRECT_TO_PRACTICE.STOCK_ORDER_PAGE) {
      urlString = urlString + '?stockOrder=true';
    }
    url = this.occEndPoints.buildUrl(urlString);
    return this.httpClient.get<any>(url);
  }

  generateVarientDropdownoptions(
    variantTree,
    item: string,
    selectedVariants: object = null,
    validateChild: boolean = null
  ): Set<string> {
    const value: Set<string> = new Set();
    variantTree?.forEach((child) => {
      if (
        selectedVariants &&
        selectedVariants.hasOwnProperty(child.nodeType) &&
        selectedVariants[child.nodeType] &&
        child.nodeType !== item
      ) {
        if (child.nodeValue === selectedVariants[child.nodeType]) {
          const setValues: Set<string> = this.generateVarientDropdownoptions(
            child.childrenNodes,
            item,
            selectedVariants,
            validateChild
          );
          setValues.forEach((val) => {
            value.add(val);
          });
        }
      } else {
        if (child.nodeType !== item) {
          const setValues: Set<string> = this.generateVarientDropdownoptions(
            child.childrenNodes,
            item,
            selectedVariants,
            validateChild
          );
          setValues.forEach((val) => {
            value.add(val);
          });
        } else {
          if (validateChild) {
            if (child.childrenNodes.find((node) => node.nodeValue === selectedVariants[node.nodeType])) {
              value.add(child.nodeValue);
            }
          } else {
            value.add(child.nodeValue);
          }
        }
      }
    });
    return value;
  }
  generateVarientCodes(varientTree, selectedVariants: object): SKULevelDetails {
    let value;
    varientTree.forEach((child) => {
      if (child.leafNode) {
        value = {
          code: child.nodeValue,
          dtpMaxLimit: child.dtpMaxLimit,
          maxQuantity: child.quantity,
          unitFactor: child.unitFactor,
        };
      } else if (child.nodeValue === selectedVariants[child.nodeType]) {
        value = this.generateVarientCodes(child.childrenNodes, selectedVariants);
      }
    });
    return value;
  }

  generateReturnVarientCodes(varientTree, selectedVariants: object): SKULevelDetails {
    let value;
    varientTree.forEach((child) => {
      if (child.leafNode) {
        value = {
          quantity: child.quantity,
          orderDate: child.orderDate,
          entryNumber: child.nodeValue,
        };
      } else if (child.nodeValue === selectedVariants[child.nodeType]) {
        value = this.generateReturnVarientCodes(child.childrenNodes, selectedVariants);
      }
    });
    return value;
  }

  getLensVariantList(
    updatedLensPower: VarientCode,
    lensArray: Array<VisionCareAddtocartProductRequest>
  ): Array<VisionCareAddtocartProductRequest> {
    let lensCurrentSelectedVariant;
    lensCurrentSelectedVariant = JSON.parse(JSON.stringify(lensArray));
    updatedLensPower.quantity = updatedLensPower.quantity.replace(/[^0-9.]*/g, '');
    const variantIndex = lensCurrentSelectedVariant.findIndex(
      (variant: VisionCareAddtocartProductRequest) => variant?.product?.code === updatedLensPower.value
    );
    if (variantIndex > -1) {
      lensCurrentSelectedVariant[variantIndex].quantity = updatedLensPower.quantity ? updatedLensPower.quantity : '0';
      if (
        (!updatedLensPower.quantity || updatedLensPower.quantity.toString() === '0') &&
        updatedLensPower.entryNumber === undefined
      ) {
        lensCurrentSelectedVariant.splice(variantIndex, 1);
      }
    }
    // this is for edit stock order in cart and if the item is new
    else if (updatedLensPower.quantity) {
      lensCurrentSelectedVariant.push({
        quantity: updatedLensPower.quantity ? updatedLensPower.quantity : '0',
        orderEntryFlowType: DIRECT_TO_PRACTICE.STOCK_ORDER,
        product: { code: updatedLensPower.value },
        entryNumber: updatedLensPower.entryNumber,
      });
    }
    return lensCurrentSelectedVariant;
  }

  /** @deprecated */
  getLensVarientNodeList(
    updatedLensPower: VarientCode,
    lensArray: Array<VisionCareAddtocartProductRequest>,
    cartType
  ): Array<VisionCareAddtocartProductRequest> {
    let lensCurrentSelectedVarient;
    cartType === 'update'
      ? (lensCurrentSelectedVarient = JSON.parse(JSON.stringify(this.selectedVarientInfo)))
      : (lensCurrentSelectedVarient = JSON.parse(JSON.stringify(lensArray)));
    updatedLensPower.quantity = updatedLensPower.quantity.replace(/[^0-9.]*/g, '');
    const varientIndex = lensCurrentSelectedVarient.findIndex(
      (varient: VisionCareAddtocartProductRequest) => varient?.product?.code === updatedLensPower.value
    );
    if (varientIndex > -1) {
      lensCurrentSelectedVarient[varientIndex].quantity = !updatedLensPower.quantity ? '0' : updatedLensPower.quantity;
      if (
        (!updatedLensPower.quantity || updatedLensPower.quantity.toString() === '0') &&
        updatedLensPower.entryNumber === undefined
      ) {
        lensCurrentSelectedVarient.splice(varientIndex, 1);
      }
    }
    // this is for edit stock order in cart and if the item is new
    else if (updatedLensPower.quantity) {
      lensCurrentSelectedVarient.push({
        quantity: !updatedLensPower.quantity ? '0' : updatedLensPower.quantity,
        orderEntryFlowType: DIRECT_TO_PRACTICE.STOCK_ORDER,
        product: { code: updatedLensPower.value },
        entryNumber: updatedLensPower.entryNumber,
      });
    }
    if (cartType === 'update') {
      this.selectedVarientInfo = lensCurrentSelectedVarient;
    }
    return lensCurrentSelectedVarient;
  }
  getTotalQuantity(varientData): number {
    return varientData.reduce((sum, item) => sum + Number(item.quantity), 0);
  }
  getVarientQuantity(
    varientCode: number,
    selectedProduct: ProductInfo
  ): { quantity: number; entryNumber: number } | boolean {
    const product = selectedProduct?.entries.find((productData) => productData.product.code === varientCode);
    return product ? { quantity: product.quantity, entryNumber: product.entryNumber } : false;
  }
  formatDropdown(
    data: Set<string>,
    varientKey: string = null,
    setSeleted: boolean = false,
    varients: object = null,
    sortType = 'asc'
  ): Array<DropdownOptions> {
    const options = [];
    data.forEach((element) => {
      let compareValue = '';
      if (setSeleted && varients[varientKey]) {
        compareValue = varients[varientKey];
      }
      options.push({ text: element, value: element, selected: element === compareValue } as DropdownOptions);
    });
    return this.sortOptions(options, sortType);
  }
  getVaientsFromStorage(productCode): Array<VisionCareAddtocartProductRequest> {
    const selectedProduct = JSON.parse(localStorage.getItem('stockorder_info'))?.find(
      (node) => node.productCode === productCode
    );
    return selectedProduct?.entries?.map((entry) => {
      return {
        quantity: entry.quantity,
        orderEntryFlowType: DIRECT_TO_PRACTICE.STOCK_ORDER,
        entryNumber: entry.entryNumber,
        product: { code: entry.product.code },
      } as VisionCareAddtocartProductRequest;
    });
  }

  sortOptions(options, sortType): Array<DropdownOptions> {
    if (sortType === 'asc') {
      return options.sort((val1, val2) => val1.text - val2.text);
    } else {
      return options.sort((val1, val2) => val2.text - val1.text);
    }
  }

  /** @deprecated */
  sortArray(options: Array<VarientCode>): Array<VarientCode> {
    options = options.sort((val1, val2) => 0 - (val1.text < val2.text ? -1 : 1));
    return options;
  }

  sortLensPowers(options: Array<any>): Array<any> {
    return options.sort((val1, val2) => Number(val1.text) - Number(val2.text));
  }
  getVarienntsFormCartObject(
    selectedProduct: ProductInfo,
    storageFalg: boolean = null,
    reset: boolean = null
  ): Array<VisionCareAddtocartProductRequest> {
    this.selectedVarientInfo = selectedProduct?.entries?.map((entry) => {
      return {
        quantity: storageFalg && !reset ? entry.quantity : '0',
        orderEntryFlowType: DIRECT_TO_PRACTICE.STOCK_ORDER,
        entryNumber: entry.entryNumber,
        product: { code: entry.product.code },
      } as VisionCareAddtocartProductRequest;
    });
    return this.selectedVarientInfo;
  }

  sendVariantDetails(addtoCart): void {
    this.addToCartButtonSubject.next(addtoCart);
  }

  getMaterialGroupPrice(value, pricingMap, selectedProduct): void {
    if (this.isPriceVisible) {
      if (value.toString().toLowerCase() === PACK_SIZE.TRIAL.toLowerCase() || !pricingMap) {
        this.emitPrice(selectedProduct, null);
      } else if (pricingMap) {
        const packSizePrice = pricingMap?.find((pricingInfo) => pricingInfo.key === value);
        packSizePrice ? this.emitPrice(selectedProduct, packSizePrice.value) : this.emitPrice(selectedProduct, null);
      }
    }
  }

  getMaterialPrice(variantCode: string): Observable<any> {
    if (this.isPriceVisible) {
      const userId = this.userIdService.getUserId();
      const url = this.occEndPoints.buildUrl(`users/${userId}/products/price/ninetypack/${variantCode}`);
      return this.httpClient.get(url).pipe(take(1));
    }
  }

  emitPrice(code, price): void {
    if (this.isPriceVisible) {
      this.priceArray = this.generateFilterredPriceArray(price, code, this.priceArray);
      this.packSizePriceChanged$$.next([...this.priceArray]);
    }
  }

  removePrice(code): void {
    if (this.isPriceVisible) {
      const filteredPriceArray = this.priceArray.filter((product) => product.code !== code);
      this.priceArray = [...filteredPriceArray];
    }
  }

  calculatePrice(price: number, code: string): void {
    if (this.isPriceVisible) {
      this.totalPriceArray = this.generateFilterredPriceArray(price, code, this.totalPriceArray);
      this.productSelectionChanged.emit([...this.totalPriceArray]);
    }
  }

  generateFilterredPriceArray(price, code, priceArray): any[] {
    const filteredPriceArray = priceArray.filter((product) => product.code !== code);
    filteredPriceArray.push({
      code,
      price,
    });
    return filteredPriceArray;
  }

  removeAddedItem(productCode): void {
    this.totalPriceArray = this.totalPriceArray.filter((product) => product.code !== productCode);
    this.productSelectionChanged.emit([...this.totalPriceArray]);
  }

  /**
   * @description to set the DTP flag when DTP is selected
   */
  emitDTPFlag(isDtp: boolean): void {
    this.isDTPFlag.next(isDtp);
  }
  /**
   * @description to subscribe the emited flag on DTP selection
   */
  receiveDTPFlag(): Observable<boolean> {
    return this.isDTPFlag;
  }
}
