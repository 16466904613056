import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { User } from '@spartacus/user/account/root';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { PatientListService } from 'src/app/services/patients-list/patient-list.service';
import { PATIENTLISTFILTERS } from 'src/app/shared/constants/constants';
import { getOrderFlag } from 'src/app/store/Selectors/patient.selector';
import { AlconUserPermissionService } from '../../../shared-modules/alcon-user-permission-provider/service/alcon-user-permission.service';

@Component({
  selector: 'app-patient-list-filters',
  templateUrl: './patient-list-filters.component.html',
  styleUrls: ['./patient-list-filters.component.scss'],
})
export class PatientListFiltersComponent implements OnInit {
  showFilter = false;
  selectedFilters = [];
  selectedOrderTypes = [];
  isOrdersLoaded$: Observable<boolean>;
  private destroy$ = new Subject<void>();
  filtersCount = 0;
  orderTypes = [
    {
      label: PATIENTLISTFILTERS.subscriptions,
      value: PATIENTLISTFILTERS.subscriptions,
      checked: false,
    },
    {
      label: PATIENTLISTFILTERS.Individual,
      value: PATIENTLISTFILTERS.Individual,
      checked: false,
    },
  ];
  statuses = [
    {
      label: PATIENTLISTFILTERS.Active,
      value: PATIENTLISTFILTERS.Active,
      checked: false,
    },
    {
      label: PATIENTLISTFILTERS.Inactive,
      value: PATIENTLISTFILTERS.Inactive,
      checked: false,
    },
  ];
  packTypes = [
    {
      label: PATIENTLISTFILTERS.regular,
      value: PATIENTLISTFILTERS.regular,
      checked: false,
    },
    {
      label: PATIENTLISTFILTERS.valuepack,
      value: PATIENTLISTFILTERS.valuepack,
      checked: false,
    },
  ];
  deliveryOptions = [
    {
      label: PATIENTLISTFILTERS.practiceOrder,
      value: PATIENTLISTFILTERS.practiceOrder,
      checked: false,
    },
    {
      label: PATIENTLISTFILTERS.directtoPatient,
      value: PATIENTLISTFILTERS.directtoPatient,
      checked: false,
    },
  ];
  isValuePackEnabled$: Observable<boolean> = this.userProfileService.get().pipe(
    filter((response: User) => !!response),
    map((response: User) => response.isValuePackEnabled)
  );

  constructor(
    private patientListService: PatientListService,
    private userProfileService: UserProfileFacade,
    private store: Store,
    private userPermissionService: AlconUserPermissionService
  ) {}
  ngOnInit(): void {
    this.patientListService.ClearFilters.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value === 'clear') {
        this.clearFilters();
      }
    });

    if (!this.userPermissionService.getSubscriptionsPermission()) {
      this.orderTypes = this.orderTypes.filter((oT) => oT.value !== PATIENTLISTFILTERS.subscriptions);
    }

    this.isOrdersLoaded$ = this.store.select(getOrderFlag);
  }
  changeFilterSelection(event, type): void {
    if (type === PATIENTLISTFILTERS.subscriptions) {
      if (event === false) {
        this.statuses.find((item) => {
          item.checked = false;
          this.selectedFilters = this.selectedFilters.filter((status) => status !== item.value);
        });
      }
    }
    if (type === PATIENTLISTFILTERS.Individual || type === PATIENTLISTFILTERS.subscriptions) {
      event
        ? this.selectedOrderTypes.push(type)
        : (this.selectedOrderTypes = this.selectedOrderTypes.filter((item) => item !== type));
    } else {
      event
        ? this.selectedFilters.push(type)
        : (this.selectedFilters = this.selectedFilters.filter((item) => item !== type));
    }
    const filters = {
      orderTypes: this.selectedOrderTypes,
      subfilters: this.selectedFilters,
    };
    this.filtersCount = this.selectedOrderTypes.length + this.selectedFilters.length;
    this.patientListService.orderFiltersSubject.next(filters);
  }
  clearFilters(): void {
    this.filtersCount = 0;
    this.selectedFilters = [];
    this.selectedOrderTypes = [];
    this.orderTypes.find((item) => {
      item.checked = false;
    });
    this.statuses.find((item) => {
      item.checked = false;
    });
    this.packTypes.find((item) => {
      item.checked = false;
    });
    this.deliveryOptions.find((item) => {
      item.checked = false;
    });
    this.patientListService.orderFiltersSubject.next('All');
  }
}
