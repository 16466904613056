import { Injectable } from '@angular/core';
import { SPECIFICATIONS, VarientCombinations } from 'src/app/shared/constants/constants';
import {
  BasicProductSelectedVariants,
  ProductEntries,
  ProductInfo,
  VarientCode,
} from 'src/app/shared/model/product.model';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  productStorage = {
    productCode: null,
    specifications: [],
    entries: [],
  } as ProductInfo;
  varientStorage: Array<ProductInfo> = [];
  constructor() {}

  getTheStorage(productCode: string): ProductInfo {
    return JSON.parse(localStorage.getItem('stockorder_info'))?.find((node) => node.productCode === productCode);
  }

  setStorage(productcode: string, varients: BasicProductSelectedVariants, updatedpower: VarientCode): void {
    let storageVarient: ProductInfo;
    if (this.getTheStorage(productcode)) {
      storageVarient = this.getTheStorage(productcode);
    } else {
      storageVarient = {
        productCode: null,
        specifications: [],
        entries: [],
        product: {
          packSize: null,
        },
      } as ProductInfo;
      storageVarient.productCode = productcode;
    }
    storageVarient.product.packSize = varients.packSize;
    const productIndex = this.varientStorage.findIndex((node) => node.productCode === productcode);
    SPECIFICATIONS.forEach((element) => {
      if (varients[VarientCombinations[element]]) {
        const index = storageVarient.specifications.findIndex(
          (node) =>
            node.specificationKey === element && node.specificationValue === varients[VarientCombinations[element]]
        );
        if (index > -1) {
          storageVarient.specifications[index] = {
            specificationKey: element,
            specificationValue: varients[VarientCombinations[element]],
          };
        } else {
          storageVarient.specifications.push({
            specificationKey: element,
            specificationValue: varients[VarientCombinations[element]],
          });
        }
      }
    });

    const varientIndex = storageVarient.entries.findIndex((node) => node.product.code === updatedpower.value);
    if (varientIndex > -1) {
      storageVarient.entries[varientIndex].quantity = Number(updatedpower.quantity);
      if ((!updatedpower.quantity || updatedpower.quantity.toString() === '0') && !updatedpower.entryNumber) {
        storageVarient.entries.splice(varientIndex, 1);
      }
    } else if (updatedpower.quantity && updatedpower.quantity.toString() !== '0') {
      const entry = {
        quantity: null,
        product: {
          code: null,
          categories: [],
        },
        specifications: [],
      } as ProductEntries;
      entry.quantity = Number(updatedpower.quantity);
      entry.product.code = updatedpower.value;
      entry.product.categories.push({
        name: varients.packSize,
        code: 'Pack Size_' + varients.packSize,
      });
      entry.specifications.push({ specificationKey: 'LP', specificationValue: updatedpower.power });
      storageVarient.entries.push(entry);
    }
    if (productIndex > -1) {
      this.varientStorage[productIndex] = storageVarient;
    } else {
      this.varientStorage.push(storageVarient);
    }
    if (storageVarient.entries.length > 0) {
      localStorage.setItem('stockorder_info', JSON.stringify(this.varientStorage));
    } else {
      this.removeProductFromStorage(productcode);
    }
  }
  clearStorage(val: string): void {
    localStorage.removeItem(val);
  }
  removeProductFromStorage(productCode): ProductInfo {
    const itemIndex = JSON.parse(localStorage.getItem('stockorder_info'))?.findIndex(
      (node) => node.productCode === productCode
    );
    if (itemIndex > -1) {
      const storage = JSON.parse(localStorage.getItem('stockorder_info'));
      storage.splice(itemIndex, 1);
      localStorage.setItem('stockorder_info', JSON.stringify(storage));
      this.varientStorage = JSON.parse(localStorage.getItem('stockorder_info'));
    }
    return { productCode };
  }
}
