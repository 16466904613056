import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { FavoriteProductListService } from 'src/app/services/favorite-product-list/favorite-product-list.service';
import { FavouriteIconSize } from 'src/app/shared/model/common.mode';

@Component({
  selector: 'app-favorite-product',
  templateUrl: './favorite-product.component.html',
  styleUrls: ['./favorite-product.component.scss'],
})
export class FavoriteProductComponent implements OnInit, OnDestroy {
  @Input() isFavourite = false;
  @Input() isWishList = false;
  @Input() productCode: string;
  @Input() iconSize: string = FavouriteIconSize.LARGE;
  clicked = false;
  private destroy = new Subject<void>();

  constructor(private favoriteProductListService: FavoriteProductListService, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.favoriteProductListService.favouriteStatus
      .pipe(
        filter(({ code }) => code === this.productCode),
        takeUntil(this.destroy)
      )
      .subscribe(({ code, status }) => {
        this.isFavourite = status;
      });
  }

  addItem(): void {
    this.clicked = true;
    this.favoriteProductListService
      .addtoFavoriteList(this.productCode)
      .pipe(take(1))
      .subscribe((res) => {
        this.clicked = false;
        this.cd.detectChanges();
      });
  }

  removeItem(): void {
    this.clicked = true;
    this.favoriteProductListService
      .removeFromFavoriteList(this.productCode)
      .pipe(take(1))
      .subscribe((res) => {
        this.clicked = false;
        this.cd.detectChanges();
        this.favoriteProductListService.removeProduct.next(this.productCode);
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
