import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SemanticPathService } from '@spartacus/core';
import { InvoiceList } from 'src/app/shared/model/invoice-list.model';

@Component({
  selector: 'app-invoice-list-search',
  templateUrl: './invoice-list-search.component.html',
  styleUrls: ['./invoice-list-search.component.scss'],
})
export class InvoiceListSearchComponent implements OnInit {
  invoiceList: InvoiceList;
  searchParams: Params;
  searchNumber: string;
  disableSearch: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private semanticPathService: SemanticPathService
  ) {}

  ngOnInit(): void {
    this.invoiceList = this.route.snapshot.data.invoices;
    this.searchParams = this.route.snapshot.queryParams;
    if (this.searchParams.searchString) {
      this.searchNumber = this.route.snapshot.queryParams.searchString;
      this.disableSearch = true;
    }
  }

  search(): void {
    this.searchNumber ? (this.disableSearch = true) : (this.disableSearch = false);
    this.router.navigate(
      this.semanticPathService.transform({
        cxRoute: 'invoices',
      }),
      {
        queryParams: { searchString: this.searchNumber },
      }
    );
  }
}
