import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService, UserIdService } from '@spartacus/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { OrderDetailsObj } from 'src/app/store/States/cartItem.state';

@Injectable({
  providedIn: 'root',
})
export class OrderDetailsService {
  orderdetails = null;
  getOrderStatusSubject = new Subject<string>();
  orderStatus = this.getOrderStatusSubject.asObservable();

  constructor(
    private http: HttpClient,
    private occEndPoints: OccEndpointsService,
    private userIdService: UserIdService
  ) {}
  private orderInfoSubject = new BehaviorSubject(this.orderdetails);
  orderInfo = this.orderInfoSubject.asObservable();
  getOrderInfo(OrderID): Observable<any> {
    return this.userIdService.getUserId().pipe(
      mergeMap((userId) => {
        const url = this.occEndPoints.buildUrl(`users/${userId}/orders/${OrderID}`);
        return this.http.get<any>(url);
      })
    );
  }
  getOrderDetails(OrderID): void {
    this.getOrderInfo(OrderID).subscribe((orderData: OrderDetailsObj) => {
      if (orderData) {
        this.orderInfoSubject.next(orderData);
      }
    });
  }
}
