import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NoDataText } from 'src/app/shared/constants/constants';
import { DropdownConfig, DropdownOptions, DropdownSelectedStatus } from 'src/app/shared/model/common.mode';
@Component({
  selector: 'app-dropdown-selector-v2',
  templateUrl: './dropdown-selector-v2.component.html',
  styleUrls: ['./dropdown-selector-v2.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DropdownSelectorV2Component implements OnInit, OnChanges {
  @ViewChild('typeAhead', { static: false })
  typeAheadInput: ElementRef<any>;
  @Input() options!: Array<DropdownOptions>;
  disable = false;
  @Input() set disableDropDown(value: boolean) {
    this.disable = value;
  }

  @Input() filteredOptions!: Array<DropdownOptions>;

  @Input() selectedStatus = DropdownSelectedStatus.NEUTRAL;

  optionsDisabled: boolean;
  selectText: string;
  @Input() set config(value: DropdownConfig) {
    this.defaultConfig = {
      ...this.defaultConfig,
      ...value,
      typeAheadConfig: { ...this.defaultConfig.typeAheadConfig, ...value.typeAheadConfig },
    };
    if (this.defaultConfig.typeAheadConfig.typeAhead) {
      this.defaultConfig.disableOnOnlyOption = false;
    }
    this.selectText = this.defaultConfig.defaultSelectText;
  }
  get noDatamsg(): typeof NoDataText {
    return NoDataText;
  }
  defaultConfig: DropdownConfig = {
    showOptionHeader: true,
    defaultSelectText: '--',
    typeAheadConfig: {
      typeAhead: false,
      typeAheadLength: 1,
      internalSearch: true,
    },
    maxItemsShown: 7,
    disableOnOnlyOption: true,
    iconReference: 'DropdownExpand',
    noDataText: this.noDatamsg.NoResultsFound,
  };
  @Output() dropDownSelected: EventEmitter<DropdownOptions> = new EventEmitter();
  @Output() searchTextChange: EventEmitter<string> = new EventEmitter();
  @Output() buttonEvent: EventEmitter<string> = new EventEmitter();

  @ViewChild('dropdownOptionContainer', { read: ElementRef })
  public dropdownOptionContainer: ElementRef<any>;
  isDropdownOpen = false;
  inputText = '';
  private previousSelection: string;

  @HostListener('document:click', ['$event'])
  clickout(event): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
      // this.compareSelection();
    }
  }

  constructor(private eRef: ElementRef, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setOptions();
  }
  ngOnChanges(changes: SimpleChanges): void {
    const newoptions = changes.options?.currentValue;
    if (newoptions) {
      this.selectText = this.defaultConfig.defaultSelectText;
      this.setOptions();
    }
  }

  get selectedStatusSuccess(): boolean {
    return this.selectedStatus === DropdownSelectedStatus.SUCCESS && !this.isDefaultSelection();
  }

  select(selectedOption: DropdownOptions): void {
    this.options.forEach((option) => {
      option.selected = false;
    });
    this.selectText = selectedOption.text;
    this.previousSelection = this.selectText;
    selectedOption.selected = true;
    this.isDropdownOpen = false;
    this.dropDownSelected.emit(selectedOption);
  }
  setOptions(): void {
    this.options = this.options?.map((option) => ({ ...option } as DropdownOptions));
    if (this.options?.length === 1 && this.defaultConfig.disableOnOnlyOption) {
      this.selectText = this.options[0].text;
      this.optionsDisabled = true;
      return;
    } else {
      this.optionsDisabled = false;
    }
    this.filteredOptions = [...(this.options || [])];
    this.options?.forEach((option) => {
      if (option.selected) {
        this.selectText = option.text;
      }
    });
  }

  showDropDown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
    setTimeout(() => {
      if (this.typeAheadInput) {
        this.typeAheadInput.nativeElement.focus();
        this.inputText = '';
        this.cd.detectChanges();
      }
    });
    this.filteredOptions = [...(this.options || [])];
  }

  filterText(): void {
    this.selectText = this.inputText;
    this.filteredOptions = this.options.filter((option) =>
      option.text.trim().toLowerCase().includes(this.selectText.trim().toLowerCase())
    );
    this.searchTextChange.emit(this.selectText);
  }

  buttonAction(buttonId: string): void {
    this.buttonEvent.emit(buttonId);
    this.isDropdownOpen = false;
    this.selectText = '';
  }

  private isDefaultSelection(): boolean {
    return this.selectText === this.defaultConfig.defaultSelectText;
  }

  private compareSelection(): void {
    if (this.isValidSelection(this.previousSelection) && this.previousSelection !== this.selectText) {
      this.selectText = this.previousSelection;
    }
  }

  private isValidSelection(text: string): boolean {
    return Boolean(text) && text.trim() !== '';
  }
}
