import { Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { AlconSiteService } from 'src/app/services/service/alcon-site-provider.service';

@Component({
  selector: 'app-tools-resources-panel',
  templateUrl: './tools-resources-panel.component.html',
  styleUrls: ['./tools-resources-panel.component.scss'],
})
export class ToolsResourcesPanelComponent {
  panelContent = this.componentData.data$;
  constructor(private componentData: CmsComponentData<any>, private alconSiteService: AlconSiteService) {}
}
