<!-- <ng-template cxOutletRef="ProductDetailsPageTemplate" cxOutletPos="replace">
  
</ng-template> -->

<section>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-4 col-lg-5">
        <cx-page-slot position="productImagesSection"></cx-page-slot>
        <cx-page-slot position="productDescriptionSection"></cx-page-slot>
      </div>
      <div class="col-5 product-details-tablet">
        <div class="product-deatils-panel">
          <cx-page-slot position="productsDetailsSection"></cx-page-slot>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="bg-cream prodcut-benefit-section-wrapper">
  <div class="container-fluid">
    <cx-page-slot position="productBenefitsSection"></cx-page-slot>
  </div>
</section>
