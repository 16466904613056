<div class="dropdown__container">
  <div class="d-flex justify-content-between dropdown__select" (click)="showDropDown()">
    {{ dropDownHeader }} ({{ dropDownValues?.length }})
    <cx-icon *ngIf="!openDropDown" class="dropdown__icon" type="DropdownExpand"></cx-icon>
  </div>

  <div class="dropdown__options" [ngClass]="{ 'dropdown__options--open': openDropDown }">
    <div class="d-flex justify-content-between dropdown__options--header" (click)="showDropDown()">
      {{ dropDownHeader }} ({{ dropDownValues?.length }})
      <cx-icon *ngIf="!openDropDown" class="dropdown__icon" type="DropdownExpand"></cx-icon>
      <cx-icon *ngIf="openDropDown" class="dropdown__icon" type="DropdownCollapse"></cx-icon>
    </div>
    <ul class="dropdown__options__list" [ngClass]="{ 'd-flex flex-wrap dropdown__options--list': !list }">
      <li class="d-flex align-items-center dropdown__options--item" *ngFor="let drpdwn of dropDownValues; index as i">
        <app-facet-link
          [item]="drpdwn"
          [checkBox]="true"
          [checkBoxId]="dropDownCode + '_' + i"
          (checkBoxChanged)="setCheckboxValue($event)"
          [individualOrderDD]="individualOrderDD"
        ></app-facet-link>
      </li>
    </ul>
    <div class="d-flex justify-content-end align-items-center dropdown__options--button-part">
      <div class="end-part" (click)="clear()">
        <cx-icon class="closeMark" type="CloseMark"></cx-icon>
        {{ 'contactInformation.clear' | cxTranslate }}
      </div>
      <button class="btn btn-primary text-uppercase" (click)="save()">
        {{ 'contactInformation.save' | cxTranslate }}
      </button>
    </div>
  </div>
</div>
