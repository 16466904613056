<div class="container-fluid">
  <div class="row justify-content-center mt-4">
    <div class="col-10 col-xl-10">
      <h1>{{ title$ | async }}</h1>
      <cx-page-slot position="categoryPageHeaderDescriptionSection"></cx-page-slot>
    </div>
  </div>

  <!-- Brand Section -->
  <div class="row justify-content-center mt-4 filter-padding-bottom">
    <div class="col-10 col-xl-10">
      <cx-page-slot position="categoryPageFiltersSection"></cx-page-slot>
    </div>
  </div>

  <!-- Actual Product Display Section -->
  <div class="row justify-content-center">
    <div class="col-10 col-xl-10">
      <cx-page-slot class="w-100" position="categoryPageProductListSection"></cx-page-slot>
    </div>
  </div>
</div>
