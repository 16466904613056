<div class="category">
  <app-product-category></app-product-category>
</div>

<div class="product-filter d-flex d-lg-block flex-row justify-content-between align-items-baseline">
  <div class="d-flex w-100 flex-column product-filter__container">
    <div class="d-md-flex d-lg-none product-filter__row product-filter__item d-flex">
      <div class="d-flex product-filter__row flex-wrap">
        <ng-container *ngIf="checkBoxFacets?.length > 0">
          <app-filter-dropdown-selector
            *ngFor="let checkBoxFacet of checkBoxFacets"
            [dropDownValues]="checkBoxFacet?.values"
            [dropDownHeader]="checkBoxFacet?.name"
            [dropDownCode]="checkBoxFacet?.code"
            [individualOrderDD]="false"
            [list]="true"
          >
          </app-filter-dropdown-selector>
          <ng-container *ngIf="firstRow">
            <app-filter-dropdown-selector
              [dropDownValues]="firstRow?.values"
              [dropDownHeader]="firstRow?.name"
              [dropDownCode]="firstRow?.code"
              [individualOrderDD]="true"
            >
            </app-filter-dropdown-selector>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="d-lg-flex flex-column d-none product-filter__row">
      <ng-container *ngFor="let checkBoxFacet of checkBoxFacets">
        <div class="d-flex product-filter__group" *ngIf="checkBoxFacet.values && checkBoxFacet.values.length > 0">
          <p class="product-filter__facet--typename m-0 text-left">{{ checkBoxFacet.name }}:</p>

          <ul class="product-filter__facet--list d-flex flex-wrap">
            <li
              class="product-filter__facet--listitem product-filter__facet--listitem-p"
              *ngFor="let items of checkBoxFacet.values"
            >
              <app-facet-link [item]="items" [translate]="checkBoxFacet.translate"></app-facet-link>
            </li>
          </ul>
        </div>
      </ng-container>
    </div>
    <div class="d-flex product-filter__row flex-wrap mt-lg-3" *ngIf="!showMoreFlag && dropdownFacets.length > 0">
      <app-filter-dropdown-selector
        *ngFor="let dropdownFacet of dropdownFacets"
        [dropDownValues]="dropdownFacet.values"
        [dropDownHeader]="dropdownFacet.name"
        [dropDownCode]="dropdownFacet.code"
        [individualOrderDD]="true"
      >
      </app-filter-dropdown-selector>
    </div>

    <div class="d-lg-flex d-md-none justify-content-end">
      <div class="product-filter__showmore" *ngIf="showMoreFlag && dropdownFacets.length">
        <a
          class="product-filter__showmore--link product-filter__showmore--link--margin-top-18"
          (click)="showMoreFlag = !showMoreFlag"
        >
          {{ 'productListingPage.moreFilter' | cxTranslate }}

          <cx-icon type="PLPShowMore" class="product-filter__showmore--icon"></cx-icon>
        </a>
      </div>
      <div class="product-filter__showmore" *ngIf="!showMoreFlag">
        <a class="product-filter__showmore--link" (click)="showMoreFlag = !showMoreFlag">
          {{ 'productListingPage.lessFilter' | cxTranslate }}
          <cx-icon type="PLPShowLess" class="product-filter__showmore--icon"></cx-icon>
        </a>
      </div>
    </div>
  </div>
  <div class="d-md-flex d-lg-none flex-column product-filter__filters">
    <div class="product-filter__showmore" *ngIf="showMoreFlag && dropdownFacets?.length">
      <a
        class="product-filter__showmore--link product-filter__showmore--link--margin-top-18"
        (click)="showMoreFlag = !showMoreFlag"
      >
        {{ 'productListingPage.moreFilter' | cxTranslate }}
        <cx-icon type="PLPShowMore" class="product-filter__showmore--icon"></cx-icon>
      </a>
    </div>
    <div class="product-filter__showmore" *ngIf="!showMoreFlag">
      <a class="product-filter__showmore--link text-left" (click)="showMoreFlag = !showMoreFlag">
        {{ 'productListingPage.lessFilter' | cxTranslate }}
        <cx-icon type="PLPShowLess" class="product-filter__showmore--icon"></cx-icon>
      </a>
    </div>
    <button *ngIf="showClearAllFilter" class="d-flex product-filter__clear-filter" (click)="clearAllFilters()">
      <cx-icon class="closeMark" type="CloseMark"></cx-icon>
      {{ 'patientListFilters.clearfilters' | cxTranslate }}
    </button>
  </div>
</div>
