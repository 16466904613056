<ng-container *ngIf="invoice?.invoiceListOrders?.length === 1; else accordion"
  >{{ invoice?.invoiceListOrders[0] }}
</ng-container>
<ng-template #accordion>
  <div *ngIf="!expandAccordion">
    <span class="invoice-order-number__title" (click)="toggleAccordion(invoice?.invoiceNumber)">
      <cx-icon role="button" type="DropdownExpand"></cx-icon>
      <span class="ml-1">{{ invoice?.invoiceListOrders?.length }} {{ 'invoicesListPage.orders' | cxTranslate }}</span>
    </span>
  </div>

  <div *ngIf="expandAccordion && currentInvoiceNumber === invoice?.invoiceNumber">
    <div class="invoice-order-number position-absolute" [ngClass]="{ 'invoice-order-number__open': expandAccordion }">
      <cx-icon
        role="button"
        type="DropdownCollapse"
        class="invoice-order-number__toggle-button"
        (click)="toggleAccordion(invoice?.invoiceNumber)"
      ></cx-icon>
      <ul class="invoice-order-number__popover" *ngFor="let orderNumber of invoice?.invoiceListOrders">
        <li>{{ 'invoicesListPage.orderNumber' | cxTranslate }} {{ orderNumber }}</li>
      </ul>
    </div>
  </div>
</ng-template>
