<div class="marketing-page">
  <div class="container-fluid mobile-container-fluid">
    <div class="row justify-content-center">
      <div class="col-10 col-md-5">
        <ng-container *ngIf="!isForgotPwdRoute">
          <!---
          <div class="login-container">
            <div class="banner-text-container">
              <div class="use-your-online-id-login LeftPane1A has-components">
                <p class="login-header" *ngIf="errorInfo?.errorMessage && !userLocked">
                  {{ errorInfo?.errorMessage }}
                </p>
                <p class="login-error" *ngIf="errorInfo?.errorDescription && !userLocked">
                  {{ errorInfo?.errorDescription }}
                </p>
              </div>
              <div class="d-flex justify-content-end" *ngIf="!userLocked">
                <div class="lock-image">
                  <img src="/assets/images/loginpage-lock/webp/Shape.webp" alt="lock image" />
                </div>
              </div>
              <div class="d-flex justify-content-end" *ngIf="userLocked">
                <div class="lock-image">
                  <img src="/assets/images/loginpage-lock/user-lock.webp" alt="lock image" />
                </div>
              </div>
            </div>
          </div>
          -->
          <app-axon-okta-login></app-axon-okta-login>
          <!--<app-login *ngIf="!userLocked"></app-login>!-->
          <div class="lock-screen" *ngIf="userLocked">
            <div class="left-section-upper-half">
              <p class="lock-screen__error1">{{ errorInfo?.errorMessage }}</p>
              <p class="lock-screen__error2">
                {{ errorInfo?.errorDescription }}
              </p>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="isForgotPwdRoute && isEmailSent">
          <div class="left-section-upper-half">
            <div class="banner-text-container">
              <div class="use-your-online-id-t">
                <p class="access-left-text">{{ 'login.forgotYourPwd' | cxTranslate }}</p>
                <cx-page-slot class="enter-your-e-mail-ad" position="LeftPane1A"> </cx-page-slot>
              </div>
              <div class="d-flex justify-content-end">
                <div class="lock-image">
                  <img src="/assets/images/loginpage-lock/webp/Shape.webp" alt="lock image" />
                </div>
              </div>
            </div>
          </div>
          <!-- <cx-page-slot position="LeftPane1A"> </cx-page-slot> -->
        </ng-container>
      </div>
      <div class="col-10 col-md-5">
        <div class="right-section-upper-half-wrapper">
          <div class="right-section-upper-half">
            <cx-page-slot position="RightPane2A"> </cx-page-slot>
            <div class="address-section-header" *ngIf="UKMarketingPageTopSection2BPara1">
              <p [innerHTML]="UKMarketingPageTopSection2BPara1.content"></p>
            </div>
            <div class="address-section-division">
              <div class="address-section-address" *ngIf="UKMarketingPageTopSection2BPara2?.content">
                <p [innerHTML]="UKMarketingPageTopSection2BPara2.content"></p>
              </div>
              <div class="address-section-address" *ngIf="UKMarketingPageTopSection2BPara3?.content">
                <p [innerHTML]="UKMarketingPageTopSection2BPara3.content"></p>
              </div>
            </div>
            <cx-page-slot position="RightPane2D"></cx-page-slot>
            <hr />
            <div class="d-block d-lg-flex demo justify-content-between text-center text-lg-left">
              <div
                class="address-section-header demo__txt text-center text-lg-left"
                *ngIf="UKMarketingPageTopSection2CPara"
              >
                <p [innerHTML]="UKMarketingPageTopSection2CPara.content"></p>
              </div>
              <button (click)="openDemoForm()" *ngIf="MarketingRequestDemoLink" class="btn font-weight-bold demo__btn">
                {{ MarketingRequestDemoLink?.name }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!isForgotPwdRoute">
    <div class="container-fluid mobile-container-fluid">
      <div class="row justify-content-center loginbanner__container">
        <div class="col-12 col-xl-10 d-flex">
          <div class="w-50 loginbanner__imagehold">
            <app-responsive-media [media]="MarketingBanner1?.media" alt="MarketingBanner1"></app-responsive-media>
          </div>
          <div class="w-50 loginbanner__content loginbanner__background--blue" *ngIf="UKMarketingPageTopSection3APara">
            <h1 class="loginbanner__title" [innerHTML]="UKMarketingPageTopSection3APara.name"></h1>
            <ul class="loginbanner__listitems">
              <li class="loginbanner__listitem" *ngFor="let item of UKMarketingPageTopSection3AParaItems">
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-xl-10 d-flex">
          <div
            class="w-50 loginbanner__content loginbanner__content--left loginbanner__background--voilet"
            *ngIf="UKMarketingPageTopSection4APara"
          >
            <h1 class="loginbanner__title" [innerHTML]="UKMarketingPageTopSection4APara.name"></h1>
            <ul class="loginbanner__listitems">
              <li class="loginbanner__listitem" *ngFor="let item of UKMarketingPageTopSection4AParaItems">
                {{ item }}
              </li>
            </ul>
          </div>
          <div class="w-50 loginbanner__imagehold">
            <app-responsive-media [media]="MarketingBanner2?.media" alt="MarketingBanner2"></app-responsive-media>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- 5A -->
  <ng-container *ngIf="isForgotPwdRoute">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-xl-10">
          <cx-page-slot position="MainPane5A"> </cx-page-slot>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<app-contact-us-modal
  [configs]="modalConfigs"
  [thankYouModalOptions]="thankYouModalOptions"
  [formConfig]="contactFormConfig"
  [options]="modalOptions"
></app-contact-us-modal>
