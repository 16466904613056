<div class="cart-dtp-orders" *ngIf="record$ | async">
  <div class="cart-dtp-orders__name-address mt-4" *ngIf="!showAddress">
    <div class="cart-dtp-orders__patient-name">{{ cartEntry.patientName }}</div>
    <div class="ml-3">
      <app-cart-dtp-address
        [userAddress]="deliveryAddress"
        [entryNumber]="cartEntry.entryNumber"
        (updateAddress)="updateAddress($event)"
      ></app-cart-dtp-address>
    </div>
  </div>
  <app-delivery-method [deliveryInfo]="deliveryInfo" *ngIf="showAddress"></app-delivery-method>
  <div *ngFor="let cartEntry of cartEntry.entries">
    <div *ngIf="cartEntry.orderEntryFlowType === 'DIRECT_TO_PATIENT'">
      <app-cart-single-line-item
        [enableCounter]="true"
        [orderType]="orderType"
        [showActions]="showActions"
        [showPatientReference]="false"
        [cartEntry]="cartEntry"
      >
      </app-cart-single-line-item>
    </div>
    <div *ngIf="cartEntry.orderEntryFlowType === 'DTP_VALUE_PACK'">
      <!-- R1 temp fix for DTP Price -->
      <app-cart-valuepack-lineitem
        [showActions]="showActions"
        [showPatientReference]="false"
        [cartEntry]="cartEntry"
        [orderType]="'DIRECT_TO_PATIENT'"
      >
      </app-cart-valuepack-lineitem>
    </div>
  </div>
</div>
