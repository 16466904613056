import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CsAgentAuthService } from '@spartacus/asm/root';
import {
  AuthService,
  AuthStorageService,
  OccEndpointsService,
  OCC_USER_ID_CURRENT,
  UserIdService,
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { ADDRESS_TYPES, LOCALSTORAGE_KEY } from 'src/app/shared/constants/constants';
import { B2BUnitStorageItem, ShipToSelector } from 'src/app/shared/model/b2b-unit.model';
import { GetB2BUnitsList, LoadB2BUnits } from 'src/app/store/Actions/b2bUnits.action';
import { getb2bUnitsData } from 'src/app/store/Selectors/b2bUnits.selector';
import { B2BUnits } from 'src/app/store/States/b2bUnits.state';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AccountSelectorService {
  b2bUnitLoaded$ = this.actions$.pipe(ofType(GetB2BUnitsList));
  constructor(
    private http: HttpClient,
    private userIdService: UserIdService,
    private authStorageService: AuthStorageService,
    private occEndPoints: OccEndpointsService,
    private store: Store,
    private actions$: Actions,
    private CsAgentService: CsAgentAuthService,
    private authservice: AuthService
  ) {}

  getHybrisTokenForSelectedUnit(accesstoken, uId): any {
    const params = new HttpParams()
      .set('client_id', 'Okta_client')
      .set('grant_type', 'urn:ietf:params:oauth:grant-type:jwt-bearer')
      .set('client_secret', 'secret')
      .set('token', accesstoken)
      .set('uId', uId);
    return this.http.post<any>(
      environment.API_BASE_URL + '/authorizationserver/oauth/token',
      {},
      {
        params,
      }
    );
  }

  updateAuthStorage(uId: string): any {
    const token = JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken;
    return this.getHybrisTokenForSelectedUnit(token, uId);
  }

  getB2bUnits(): Observable<B2BUnits> {
    return this.userIdService.getUserId().pipe(
      take(1),
      withLatestFrom(this.CsAgentService.isCustomerEmulated()),
      switchMap(([userId, isCustomerEmulated]) => {
        const asm = isCustomerEmulated;
        const params = new HttpParams().set('asm', asm);
        const url = this.occEndPoints.buildUrl(`users/${userId}/b2bunits`);
        return this.http.get<B2BUnits>(url, { params }).pipe(
          map((unitdata) => {
            return unitdata;
          })
        );
      })
    );
  }

  setShipToAddress(sapCustomerId): any {
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap((userId) => {
        const url = this.occEndPoints.buildUrl(`users/${userId}/changeShipTo/${sapCustomerId}`);
        return this.http.post<any>(url, {}, {});
      })
    );
  }
  checkMuleSoft(accountId): Observable<any> {
    const url = this.occEndPoints.buildUrl(`/users/current/multipleAccountAccess/${accountId}/check`);
    return this.http.get<any>(url);
  }

  createNewB2BCustomer(accountId): Observable<any> {
    const url = this.occEndPoints.buildUrl(`/users/current/multipleAccountAccess/${accountId}/create`);
    return this.http.get<any>(url);
  }

  updateTokenInAuthStorage(tokenData): void {
    this.authStorageService.setItem('access_token', tokenData.access_token);
    this.authStorageService.setItem('granted_scopes', tokenData.granted_scopes);
    this.authStorageService.setItem('access_token_stored_at', tokenData.access_token_stored_at);
    this.authStorageService.setItem('expires_at', tokenData.expires_at);
    this.authStorageService.setItem('token_type', tokenData.token_type);
    this.userIdService.setUserId(OCC_USER_ID_CURRENT);
  }

  getUnitStoreData(): Observable<B2BUnits> {
    return this.store.select(getb2bUnitsData).pipe(
      tap((unitdata) => {
        if (!unitdata.units.length) {
          this.store.dispatch(LoadB2BUnits());
        }
      }),
      filter((unitdata) => !!unitdata.units.length),
      take(1)
    );
  }

  getShiptoAddresses(): Observable<ShipToSelector> {
    return this.getUnitStoreData().pipe(
      withLatestFrom(this.authservice.isUserLoggedIn()),
      map(([data, isloggedin]) => {
        if (data && isloggedin) {
          const selectedSoldTo = data?.units?.find(
            (item) => item.uid === localStorage.getItem(LOCALSTORAGE_KEY.SELECTED_SOLD_TO)
          );
          const shiptoList = selectedSoldTo.addresses.filter(
            (addresses) => addresses?.title?.toLowerCase() === ADDRESS_TYPES.SHIP_TO_ADDRESS
          );
          return {
            accountName: selectedSoldTo.name,
            accountId: selectedSoldTo.uid,
            shiptoList,
          } as ShipToSelector;
        }
      })
    );
  }
  getLocalStorageItem(): B2BUnitStorageItem {
    return {
      selectedSoldTo: localStorage.getItem(LOCALSTORAGE_KEY.SELECTED_SOLD_TO),
      selectedSoldToCount: localStorage.getItem(LOCALSTORAGE_KEY.SELECTED_SOLD_TO_COUNT),
      shipToCount: localStorage.getItem(LOCALSTORAGE_KEY.SHIP_TO_COUNT),
      selectedShipTo: localStorage.getItem(LOCALSTORAGE_KEY.SELECTED_SHIP_TO),
    };
  }

  setLocalStorageSoldTo(soldTo): void {
    localStorage.setItem(LOCALSTORAGE_KEY.SELECTED_SOLD_TO, soldTo);
  }

  setLocalStorageShipTo(shipTo): void {
    localStorage.setItem(LOCALSTORAGE_KEY.SELECTED_SHIP_TO, shipTo);
  }

  setLocalStorageSoldToCount(soldTOCount): void {
    localStorage.setItem(LOCALSTORAGE_KEY.SELECTED_SOLD_TO_COUNT, soldTOCount ? 'true' : 'false');
  }

  setLocalStorageShipToCount(shipTOCount): void {
    localStorage.setItem(LOCALSTORAGE_KEY.SHIP_TO_COUNT, shipTOCount ? 'true' : 'false');
  }
}
