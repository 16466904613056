import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslationService } from '@spartacus/core';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ProductDetailsService } from 'src/app/services/alcon-pdp/product-details.service';
import { SOLensValidationService } from 'src/app/services/stock-order/so-lens-validation.service';
import { CART_TYPE, DIRECT_TO_PRACTICE, PACK_SIZE, Variants } from 'src/app/shared/constants/constants';
import { BasicProductSelectedVariants, Specs, VarientCode } from 'src/app/shared/model/product.model';
import { VisionCareAddtocartProductRequest } from 'src/app/store/States/cartItem.state';
import { StockorderToricComponent } from '../stockorder-toric/stockorder-toric.component';

@Component({
  selector: 'app-stockorder-configurator',
  templateUrl: './stockorder-configurator.component.html',
  styleUrls: ['./stockorder-configurator.component.scss'],
})
export class StockorderConfiguratorComponent implements OnInit, OnChanges {
  cartTypes = CART_TYPE;
  lensType = DIRECT_TO_PRACTICE;
  clearQuantity = { status: false };
  quantityError = false;
  displayErrMsg: string;
  @Input() product;
  @Input() cartType;
  @Input() cartData;
  addToCart;
  addUpdateButtonText = '';
  maxQuantityError = false;
  addingToCartLoading = false;
  @Output() closePopup: EventEmitter<void> = new EventEmitter();
  @Output() emitUpdatedData: EventEmitter<any> = new EventEmitter();
  positionId: any;
  lensPowerSelector: any[];
  variantTree: any;
  variants: BasicProductSelectedVariants = {};
  lensSelectedValues: VisionCareAddtocartProductRequest[] = [];
  totalQuantitySelected: any;
  initialText: string;
  cartLoadingText: string;
  cartSuccessText: string;
  addingToCartTick: boolean;
  errorResponse: any[];
  trailLimitError: boolean;
  trialLimit = 0;
  trialPack: boolean;
  maxQuantity: string;
  initialLensSelected: VisionCareAddtocartProductRequest[];
  tempLensSelectedValues = [];
  cartLensValues: VisionCareAddtocartProductRequest[] = [];
  @ViewChild(StockorderToricComponent) stockToricComponent: StockorderToricComponent;

  constructor(
    private productDetailsService: ProductDetailsService,
    private validationService: SOLensValidationService,
    private translationService: TranslationService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.product = { ...changes?.product?.currentValue };
    }
    if (changes.cartData?.currentValue) {
      this.cartLensValues = this.cartData?.entries?.map((entry) => {
        return {
          quantity: entry.quantity ?? '0',
          orderEntryFlowType: DIRECT_TO_PRACTICE.STOCK_ORDER,
          entryNumber: entry.entryNumber,
          product: { code: entry.product.code },
        } as VisionCareAddtocartProductRequest;
      });
    }
  }

  ngOnInit(): void {
    this.variantTree = JSON.parse(this.product.variantTree);
    this.trialLimit = this.product.trialLimit;
    this.maxQuantity = this.product.maxPurchaseableQty;
    this.getLensPower({}, false);
    this.getTranslatedValues();
  }

  getTranslatedValues(): any {
    const translations: Array<Observable<string>> = [];
    if (this.cartType === CART_TYPE.UPDATE) {
      translations.push(this.translationService.translate('stockOrderPage.updateToCartText'));
      translations.push(this.translationService.translate('stockOrderPage.updatingCart'));
      translations.push(this.translationService.translate('stockOrderPage.updateToCartSuccess'));
    } else {
      translations.push(this.translationService.translate('stockOrderPage.addToCart'));
      translations.push(this.translationService.translate('stockOrderPage.addToCartLoading'));
      translations.push(this.translationService.translate('stockOrderPage.addToCartSuccess'));
    }

    return combineLatest(translations)
      .pipe(
        map(([initialText, cartLoadingText, cartSuccessText]) => {
          return {
            initialText,
            cartLoadingText,
            cartSuccessText,
          };
        }),
        take(1)
      )
      .subscribe((data) => {
        this.initialText = data.initialText;
        this.cartLoadingText = data.cartLoadingText;
        this.cartSuccessText = data.cartSuccessText;

        this.addUpdateButtonText = this.initialText;
      });
  }

  specsSelected(specs: Specs): void {
    // reset cart lens values when any specification has changed
    if (Object.keys(this.variants).length) {
      this.removeCartLensValues();
    }
    this.variants = specs.variants;
    if (this.variants?.packSize?.toLocaleLowerCase().includes(PACK_SIZE.TRIAL.toLowerCase())) {
      this.trialPack = true;
    } else {
      this.trialPack = false;
    }
    this.getLensPower(specs.variants, this.product?.contactLensType !== DIRECT_TO_PRACTICE.PRODUCT_SPERICAL);
    this.tempLensSelectedValues = [];
    this.lensSelectedValues = [];
    this.resetErrors();
  }

  resetLensPowers(): void {
    this.getLensPower({}, false);
    this.lensSelectedValues = [];
    // reset cart lens values when pack size has changed
    this.removeCartLensValues();
  }

  /**
   * This method sets the quantities to zero which is sent to
   * backend to mark them as removed by user.
   */
  private removeCartLensValues(): void {
    this.cartLensValues = this.cartData?.entries?.map((entry) => {
      return {
        quantity: '0',
        orderEntryFlowType: DIRECT_TO_PRACTICE.STOCK_ORDER,
        entryNumber: entry.entryNumber,
        product: { code: entry.product.code },
      } as VisionCareAddtocartProductRequest;
    });
  }

  formatLenspower(data: Set<string>): Array<VarientCode> {
    const options = [];
    data.forEach((element) => {
      options.push({ text: element, value: null, quantity: '', error: '' } as VarientCode);
    });
    return options;
  }

  // validate individual axis for errors
  selectedLenses(updatedLensPower): void {
    this.maxQuantity = updatedLensPower.maxQuantity;
    this.resetErrors();

    if (!updatedLensPower.value) {
      return;
    }

    this.quantityError = this.validationService.validateNonZero(this.lensPowerSelector);
    this.lensPowerSelector?.forEach((element) => {
      if (element.quantity) {
        element.displayError = this.validationService.individualMaxQuantity(element);
        if (element.displayError) {
          this.maxQuantityError = true;
        }
      }
    });

    this.lensSelectedValues = [
      ...this.productDetailsService.getLensVariantList(
        updatedLensPower,
        this.cartType === CART_TYPE.UPDATE ? this.cartLensValues : [...this.tempLensSelectedValues]
      ),
    ];
    this.cartLensValues = [...this.lensSelectedValues];
    if (this.lensSelectedValues.every((lens) => lens.quantity === '0')) {
      this.lensSelectedValues = [];
    }
    this.tempLensSelectedValues = [...this.lensSelectedValues];

    if (
      this.validationService.validatePower(updatedLensPower) ||
      this.lensPowerSelector.find((variant) => variant.displayError) ||
      this.quantityError
    ) {
      this.lensSelectedValues = [];
      this.totalQuantitySelected = null;
      return;
    }

    if (DIRECT_TO_PRACTICE.PRODUCT_TORIC !== this.product?.contactLensType) {
      this.totalQuantitySelected = this.productDetailsService.getTotalQuantity(this.lensPowerSelector);
    }
  }

  // validate max quantity and is number error for all the powers and their axises
  validateQuantity(toricPowers): void {
    toricPowers?.forEach((dataForEachPower) => {
      dataForEachPower?.axisData?.forEach((variantData) => {
        if (!variantData.disabled) {
          if (variantData.displayError) {
            this.maxQuantityError = true;
          }
          if (this.validationService.validateNonZero([variantData])) {
            this.quantityError = true;
          }
        }
      });
    });
    this.disableAddToCart();
  }

  disableAddToCart(): void {
    if (this.maxQuantityError || this.quantityError) {
      this.lensSelectedValues = [];
      this.totalQuantitySelected = undefined;
    }
  }

  /** @deprecated */
  toricPowers(toricPowers): void {
    toricPowers?.forEach((power) => {
      power?.combinations?.forEach((powerCombination) => {
        if (!powerCombination.disabled) {
          if (powerCombination.displayError) {
            this.maxQuantityError = true;
          }
          if (this.validationService.validateNonZero([powerCombination])) {
            this.quantityError = true;
          }
        }
      });
    });
    if (this.maxQuantityError || this.quantityError) {
      this.lensSelectedValues = [];
      this.totalQuantitySelected = undefined;
    }
  }

  private resetErrors(): void {
    this.maxQuantityError = false;
    this.trailLimitError = false;
    this.displayErrMsg = '';
    this.quantityError = false;
  }

  getLensPower(variants, flag: boolean): void {
    const varientValues = [];
    const LensPowerSelector = this.formatLenspower(
      this.productDetailsService.generateVarientDropdownoptions(
        this.variantTree.childrenNodes,
        Variants.LENSPOWER,
        variants,
        flag
      )
    );

    LensPowerSelector.forEach((power) => {
      const variant = this.productDetailsService.generateVarientCodes(this.variantTree.childrenNodes, {
        ...variants,
        lenspower: power.text,
      });
      if (variant) {
        const varientInfo = {
          text: power.text,
          value: variant.code,
          quantity: '',
          maxQuantity: variant.maxQuantity,
          error: '',
        } as VarientCode;
        if (this.cartType === CART_TYPE.UPDATE) {
          const quantity: any = this.productDetailsService.getVarientQuantity(variant.code, this.cartData);
          if (quantity) {
            varientInfo.quantity = quantity.quantity.toString();
            varientInfo.entryNumber = quantity.entryNumber;
          }
          this.positionId = this.cartData.positionId;
        }
        varientValues.push(varientInfo);
      } else {
        varientValues.push({ text: power.text, value: '' });
      }
    });
    this.lensPowerSelector = this.productDetailsService.sortLensPowers([...varientValues]);
    if (!this.maxQuantityError) {
      this.totalQuantitySelected = this.productDetailsService.getTotalQuantity(this.lensPowerSelector);
    }
    if (this.lensPowerSelector.find((variant) => variant.displayError)) {
      this.lensSelectedValues = [];
      this.totalQuantitySelected = null;
      return;
    }
  }

  getTheVarients(lensData: Array<VisionCareAddtocartProductRequest>): void {
    this.lensSelectedValues = [...lensData];
    this.totalQuantitySelected = this.productDetailsService.getTotalQuantity(this.lensSelectedValues);
  }

  // set total quantity
  getQuantityForVarients(quantity): void {
    this.totalQuantitySelected = quantity;
  }

  closeConfigurator(): void {
    this.closePopup.emit();
  }

  addingtocartLoad(loading): void {
    this.maxQuantityError = false;
    if (loading) {
      this.addingToCartLoading = loading;
      this.addUpdateButtonText = this.cartLoadingText;
    } else {
      if (this.cartType === CART_TYPE.UPDATE) {
        this.addUpdateButtonText = this.initialText;
        this.addingToCartTick = false;
        this.addingToCartLoading = loading;
      } else {
        this.addingToCartLoading = loading;
        this.addUpdateButtonText = this.initialText;
        this.addingToCartTick = false;
        this.totalQuantitySelected = 0;
      }
    }
  }

  addingtocartSuccess(tickValue): void {
    this.addingToCartLoading = false;
    this.totalQuantitySelected = 0;
    this.addUpdateButtonText = this.cartSuccessText;
    this.addingToCartTick = tickValue;
    setTimeout(() => {
      this.addUpdateButtonText = this.initialText;
      this.addingToCartTick = false;
      this.closeConfigurator();
    }, DIRECT_TO_PRACTICE.TIMEOUTRANGE);
  }

  cartError(): void {
    if (DIRECT_TO_PRACTICE.PRODUCT_TORIC === this.product?.contactLensType) {
      this.errorResponse = [];
    } else {
      this.validateQuantityfields([]);
    }
    this.trailLimitError = true;
  }

  validateQuantityfields(errorObj): void {
    if (errorObj && errorObj.length > 0) {
      errorObj?.forEach((errorItem: any) => {
        this.lensPowerSelector?.forEach((element: any) => {
          if (element.quantity && element.value === errorItem.subject) {
            element.displayError = true;
          }
        });
      });
    } else {
      this.lensPowerSelector?.forEach((element) => {
        if (element.quantity) {
          element.displayError = true;
        }
      });
    }
  }

  clearVarients(): void {
    this.variants = {
      packSize: null,
      basecurve: null,
      diameter: null,
      color: null,
      powerAdd: null,
      cylinder: null,
      lenspower: null,
      axis: null,
    } as BasicProductSelectedVariants;
  }

  emitErrorMessage(errorMsg): void {
    const errors = errorMsg.error?.errors;
    if (DIRECT_TO_PRACTICE.PRODUCT_TORIC === this.product.contactLensType) {
      this.errorResponse = [...(errors || [])];
    } else {
      this.validateQuantityfields(errors);
    }
    this.addingToCartLoading = false;
    this.displayErrMsg = errors[0].message;
    this.stockToricComponent.setErrorForAddedQuantity(errors);
  }

  setUpdatedData(value): void {
    this.emitUpdatedData.emit(value);
  }

  clearAll(): void {
    this.totalQuantitySelected = 0;
    this.displayErrMsg = '';
    this.trailLimitError = false;
    this.tempLensSelectedValues = [];
    this.lensSelectedValues = [];

    if (this.product.contactLensType !== DIRECT_TO_PRACTICE.PRODUCT_TORIC) {
      this.getLensPower(this.variants, this.product?.contactLensType !== DIRECT_TO_PRACTICE.PRODUCT_SPERICAL);
    } else {
      this.clearQuantity = { ...{ status: true } };
      this.maxQuantityError = false;
      this.displayErrMsg = '';
    }
  }
}
