import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CartItemsService } from 'src/app/services/cart-items/cart-items.service';

@Component({
  selector: 'app-minicart-item-list',
  templateUrl: './minicart-item-list.component.html',
  styleUrls: ['./minicart-item-list.component.scss'],
})
export class MinicartItemListComponent implements OnInit {
  @Input() listData: any;
  isPriceVisible: boolean;
  onCartPage: Observable<any>;
  constructor(private cartService: CartItemsService) {}

  ngOnInit(): void {
    this.onCartPage = this.cartService.getActivatedRouteData();
  }
}
