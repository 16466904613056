<div class="alertRedirectPopup">
  <div class="modal-header border-0">
    <cx-icon role="button" type="Close" class="alertRedirectPopup__closeBtn" (click)="activeModal.dismiss()"></cx-icon>
  </div>
  <div class="modal-body alertRedirectPopup__text">
    <b>{{ alertRedirectConfig.title }}</b>
    <p class="alertRedirectPopup__text__desc" [innerHTML]="alertRedirectConfig.desc"></p>
  </div>
  <div class="modal-footer border-0 p-0 justify-content-center">
    <a
      class="btn btn-primary alertRedirectPopup__primaryBtn py-2"
      [routerLink]="alertRedirectConfig?.redirectLink ? ({ cxRoute: alertRedirectConfig.redirectLink } | cxUrl) : null"
      role="button"
      (click)="activeModal.dismiss()"
    >
      {{ alertRedirectConfig.redirectBtnText }}
    </a>
  </div>
</div>
