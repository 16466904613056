<div class="return-form">
  <div class="container-fluid">
    <div class="col-10 col-xl-10">
      <h1 class="mb-0 return-form__title">
        <cx-icon *ngIf="showBackButton" type="BackButton" (click)="backHistoryUrl()"></cx-icon>
        {{ title$ | async }}
      </h1>
    </div>
    <div class="col-10 col-xl-10 return-form__panel" *ngIf="!createOutlet.isActivated">
      <div class="d-flex flex-column flex-lg-row flex-lg-wrap">
        <div class="col-10 col-lg-6 pl-0 pr-0">
          <cx-page-slot position="ReturnformHeadingPosition" class="pb-2 pb-lg-0 d-block"></cx-page-slot>
          <cx-page-slot position="ReturnformMessagePosition"></cx-page-slot>
        </div>
        <div class="col-10 col-lg-4 pl-4 align-self-lg-end text-center return-form__media">
          <cx-media [container]="returnFormImgContent.media" alt="Return Form Image"></cx-media>
        </div>
        <button
          class="btn btn-primary text-uppercase return-form__button-submit"
          [routerLink]="[{ cxRoute: 'returnFormPage' }, { cxRoute: 'returnFormCreatePage' }] | cxUrl"
        >
          {{ 'contactusPage.gotoform' | cxTranslate }}
        </button>
      </div>
    </div>
    <div class="col-10">
      <router-outlet #createOutlet="outlet"></router-outlet>
    </div>
  </div>
</div>
