<div class="flex-column product-details" *ngIf="product && !isValuePackSolution">
  <div *ngIf="!solutionProducts" class="product-details__row">
    <label class="product-details__label margin-top-0">{{ 'practiceOrderPage.parametersFor' | cxTranslate }}</label>
    <input
      type="checkbox"
      [name]="'botheye' + productcode"
      class="alconInput__checkbox"
      [id]="'botheye' + productcode"
      [(ngModel)]="botheye"
      (change)="setBothEye()"
      [disabled]="disableCheckbox"
    />
    <label
      [for]="'botheye' + productcode"
      class="product-details__label--checkbox"
      [ngClass]="{ 'product-details__label--checkbox--disabled': disableCheckbox }"
      >{{ 'practiceOrderPage.sameForBothEye' | cxTranslate }}</label
    >
    <cx-icon *ngIf="botheye && this.bothEyeErrorMsg" class="invalidInput" type="InvalidInput"></cx-icon>
  </div>
  <div *ngIf="!solutionProducts" class="d-flex p-0 mt-3">
    <label class="product-details__labelBotheye"></label>
    <div class="d-flex justify-content-between justify-content-lg-start p-0">
      <div class="product-details__label-right-eye d-flex">
        <input
          type="checkbox"
          [name]="'rightEyeSelection' + productcode"
          [disabled]="botheye || disableCheckbox"
          [(ngModel)]="rightEye"
          class="alconInput__checkbox"
          [id]="'rightEyeSelection' + productcode"
          (change)="setIndividualEye()"
        />
        <label
          for="prd_checkbox"
          class="product-details__label--checkbox"
          [for]="'rightEyeSelection' + productcode"
          [ngClass]="{ 'product-details__label--checkbox--disabled': disableCheckbox }"
          >{{ 'practiceOrderPage.right' | cxTranslate }}</label
        >
        <cx-icon
          *ngIf="this.selectedVariantCode.right.maxQuantityError || (this.bothEyeErrorMsg && !botheye)"
          class="invalidInput"
          type="InvalidInput"
        ></cx-icon>
      </div>
      <div class="d-flex product-details__label-left-eye">
        <input
          type="checkbox"
          [disabled]="botheye || disableCheckbox"
          [name]="'leftEyeSelection' + productcode"
          [(ngModel)]="leftEye"
          class="alconInput__checkbox"
          [id]="'leftEyeSelection' + productcode"
          (change)="setIndividualEye()"
        />
        <label
          class="product-details__label--checkbox"
          [for]="'leftEyeSelection' + productcode"
          [ngClass]="{ 'product-details__label--checkbox--disabled': disableCheckbox }"
          >{{ 'practiceOrderPage.left' | cxTranslate }}</label
        >
        <cx-icon
          *ngIf="this.selectedVariantCode.left.maxQuantityError || (this.bothEyeErrorMsg && !botheye)"
          class="invalidInput"
          type="InvalidInput"
        ></cx-icon>
      </div>
    </div>
  </div>
  <div
    class="product-details__row flex-wrap flex-grow-1 justify-content-end align-items-end"
    [ngClass]="{ 'd-none': isValuePack }"
  >
    <div
      class="d-flex flex-grow-1 margin-top-20px"
      [ngClass]="{ 'flex-column': solutionProducts && multilinePacksize }"
    >
      <label
        class="product-details__label margin-top-0"
        [ngClass]="{ 'mb-3': solutionProducts && multilinePacksize }"
        >{{ 'productDetailsPage.packSize' | cxTranslate }}</label
      >
      <app-product-pack-size
        [packSizeList]="packSize"
        [disabledPackSizeList]="disabledPackSizeList"
        [disable]="disablePackSize"
        (packSizeChanged)="changePackSize($event)"
        [isSolutionProducts]="solutionProducts"
        [idPrefix]="selectedProduct.code"
        [packStyles]="packStyles"
      ></app-product-pack-size>
    </div>
  </div>
  <div *ngIf="!solutionProducts" class="product-details__row d-flex">
    <div class="product-details__row d-flex flex-column justify-content-around">
      <label class="product-details__label">{{ 'practiceOrderPage.baseCurve' | cxTranslate }}</label>
      <label class="product-details__label">{{ 'practiceOrderPage.diameter' | cxTranslate }}</label>
      <label class="product-details__label">{{ 'practiceOrderPage.power' | cxTranslate }}</label>
      <label *ngIf="contactLensType === color" class="product-details__label">{{
        'practiceOrderPage.colour' | cxTranslate
      }}</label>
      <label *ngIf="contactLensType === multifocal" class="product-details__label">{{
        'practiceOrderPage.add' | cxTranslate
      }}</label>
      <label *ngIf="contactLensType === toric" class="product-details__label">{{
        'practiceOrderPage.cylinder' | cxTranslate
      }}</label>
      <label *ngIf="contactLensType === toric" class="product-details__label">{{
        'practiceOrderPage.axis' | cxTranslate
      }}</label>
    </div>
    <app-basic-variant-selector
      *ngIf="variantTree && selectedPackSize"
      [disable]="disableVarientSelector"
      [contactLensType]="product.contactLensType"
      [variantTree]="rightVariantTree"
      [packSize]="selectedPackSize"
      (ProductCodeSelected)="selectedCode($event, 'right')"
      (editProductCodeSelected)="checkDTPmaxCount($event, 'right')"
      class="product-details__width-6"
      [ngClass]="rightEye ? 'product-details__width-6' : 'disabled'"
      [editPanel]="isEditPanel"
      [productData]="rightEye ? editrightPanelData : null"
    ></app-basic-variant-selector>
    <app-basic-variant-selector
      *ngIf="variantTree && selectedPackSize"
      [disable]="disableVarientSelector"
      [contactLensType]="product.contactLensType"
      [variantTree]="leftVariantTree"
      [packSize]="selectedPackSize"
      (ProductCodeSelected)="selectedCode($event, 'left')"
      (editProductCodeSelected)="checkDTPmaxCount($event, 'left')"
      class="product-details__width-6"
      [ngClass]="{ 'd-none': botheye, disabled: !leftEye }"
      [editPanel]="isEditPanel"
      [productData]="leftEye ? editleftPanelData : null"
    ></app-basic-variant-selector>
  </div>

  <hr *ngIf="!solutionProducts && !isValuePack" class="product-details__hr" />

  <div *ngIf="!isValuePack" class="d-flex flex-row bd-highlight mb-3">
    <div class="product-details__row d-flex flex-column justify-content-around">
      <label class="product-details__label">{{ 'practiceOrderPage.quantity' | cxTranslate }}</label>
    </div>
    <div class="margin-top-20px d-flex flex-column justify-content-around">
      <app-alcon-item-counter
        [disabled]="disableCounter"
        [minimum]="1"
        [defaultValue]="quantity"
        (ValueChanged)="setProductQuantity($event)"
        [qtyFactor]="isDTP ? 1 : qtyFactor"
        [productType]="isDTP && this.selectedVariantCode.solution.isSelected"
      ></app-alcon-item-counter>
    </div>
    <div
      *ngIf="!solutionProducts"
      class="margin-top-20px product-details__per-eye d-flex flex-column justify-content-around"
    >
      {{ 'productDetailsPage.perEye' | cxTranslate }}
    </div>
  </div>

  <div class="p-120 w-370">
    <p class="errorDisplay errorDisplay--orange text-left mt-3" *ngIf="dtpError">
      {{ 'productDetailsPage.dtpError' | cxTranslate }}
    </p>
    <div>
      <p class="errorDisplay errorDisplay--orange maxCLerrorDisplay text-left" *ngIf="maxQuantityError">
        {{ 'productDetailsPage.quantityErrorMsg' | cxTranslate }} {{ maxQuantity }}.
      </p>
      <p class="errorDisplay errorDisplay--orange maxCLerrorDisplay text-center" *ngIf="errMsg">{{ errMsg }}.</p>
    </div>
    <div class="d-flex-column">
      <ng-container
        *ngIf="
          this.selectedVariantCode.left.maxQuantityError === this.selectedVariantCode.right.maxQuantityError;
          else displayIndividualError
        "
      >
        <p
          class="errorDisplay errorDisplay--orange maxCLerrorDisplay text-left"
          *ngIf="this.selectedVariantCode.left.maxQuantityError"
        >
          {{ this.selectedVariantCode.left.maxQuantityError }}
        </p>
      </ng-container>

      <ng-template #displayIndividualError>
        <p
          class="errorDisplay errorDisplay--orange maxCLerrorDisplay text-left"
          *ngIf="this.selectedVariantCode.left.maxQuantityError"
        >
          {{ this.selectedVariantCode.left.maxQuantityError }}
        </p>

        <p
          class="errorDisplay errorDisplay--orange maxCLerrorDisplay text-left"
          *ngIf="this.selectedVariantCode.right.maxQuantityError"
        >
          {{ this.selectedVariantCode.right.maxQuantityError }}
        </p>
      </ng-template>
      <p
        class="errorDisplay errorDisplay--orange maxCLerrorDisplay text-left"
        *ngIf="this.selectedVariantCode.solution.maxQuantityError"
      >
        {{ this.selectedVariantCode.solution.maxQuantityError }}
      </p>
      <p class="errorDisplay errorDisplay--orange maxCLerrorDisplay text-left" *ngIf="this.trialErrorMsg">
        {{ this.trialErrorMsg }}
      </p>
      <p class="errorDisplay errorDisplay--orange maxCLerrorDisplay text-left" *ngIf="this.bothEyeErrorMsg">
        {{ this.bothEyeErrorMsg }}
      </p>
    </div>
  </div>
</div>
