import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import { FavoriteProductListService } from 'src/app/services/favorite-product-list/favorite-product-list.service';
import { FavouriteCount, SetFavouriteCount } from '../Actions/favouriteCount.action';
@Injectable()
export class FavouriteCountEffect {
  constructor(private actions: Actions, private favservice: FavoriteProductListService) {}
  loadFavourites = createEffect(() =>
    this.actions.pipe(
      ofType(FavouriteCount.LoadFavourite),
      mergeMap(() =>
        this.favservice.getFavoriteList().pipe(
          map(({ products }) => {
            return SetFavouriteCount({
              count: products.length,
            });
          })
        )
      )
    )
  );
}
