import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CART_TYPE, DIRECT_TO_PRACTICE } from 'src/app/shared/constants/constants';
import { SaveCart } from 'src/app/store/Actions/cartItem.action';
import { getCartItems } from 'src/app/store/Selectors/cartItem.selector';
import { VisionCareCartItems, VisionCareGroupEntries } from 'src/app/store/States/cartItem.state';
import { CartItemsService } from '../services/cart-items/cart-items.service';
import { AlconUserPermissionService } from '../shared-modules/alcon-user-permission-provider/service/alcon-user-permission.service';
import { VisionCareAddToCart } from '../store/States/cartItem.state';

@Directive({
  selector: '[appAddToCart]',
})
export class AddToCartDirective implements OnInit, OnChanges, OnDestroy {
  @Input() product = [];
  @Input() orderType: string = DIRECT_TO_PRACTICE.REGULAR;
  @Input() positionId;
  @Input() maxQuantity: number;
  @Input() cartType: any;
  @Input() trailPack = false;
  @Input() trailLimit: number;
  emitsuccess = false;
  @HostBinding('disabled') disable = true;
  @Input() set disableButton(value) {
    this.disable = value;
  }

  @Output() addingtocartLoad = new EventEmitter();
  @Output() addingtocartSuccess = new EventEmitter();
  @Output() updateLineItem = new EventEmitter();
  @Output() cartError = new EventEmitter();
  @Output() emitErrorMessage = new EventEmitter();
  totalQuantitySelected = 0;
  errorMessage;
  private destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private cartItemsService: CartItemsService,
    private alconUserPermissionService: AlconUserPermissionService
  ) {
    store.pipe(select(getCartItems), takeUntil(this.destroy$)).subscribe((data: VisionCareCartItems) => {
      if (data && !data.cartError) {
        if (!this.emitsuccess) {
          this.disable = false;
          this.addingtocartSuccess.emit(true);
        }
        if (this.cartType === CART_TYPE.UPDATE) {
          document.body.classList.remove('modal-open');
        }
      } else {
        this.errorMessage = data.cartError;
        if (!this.emitsuccess) {
          this.disable = true;
          this.emitErrorMessage.emit(this.errorMessage);
          this.addingtocartLoad.emit(false);
        }
      }
      this.emitsuccess = true;
    });
  }
  public ngOnInit(): void {
    this.enableAddToCart();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.product) {
      this.enableAddToCart();
    }
  }

  private enableAddToCart(): void {
    this.disable = this.alconUserPermissionService.getAddToCartEnabled() ? this.product?.length === 0 : true;
  }

  @HostListener('click', ['$event']) addToCart(e): void {
    e.preventDefault();
    this.emitsuccess = false;
    this.disable = true;
    if (!this.validate()) {
      this.cartError.emit();
      return;
    }
    this.addingtocartLoad.emit(true);
    const VCAddtocartItems: VisionCareAddToCart = {
      entries: this.product,
      orderFlowType: this.orderType,
      trialProduct: false,
      positionId: this.positionId,
    };
    if (this.cartType === CART_TYPE.UPDATE) {
      this.cartItemsService
        .updateCart({ VCAddtocartItems })
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (updatedLineItem: { entries: VisionCareGroupEntries; positionId: string }) => {
            this.updateLineItem.emit(updatedLineItem.entries);
          },
          (error) => {
            this.emitErrorMessage.emit(error);
          }
        );
    } else {
      this.store.dispatch(SaveCart({ VCAddtocartItems }));
    }
  }

  private validate(): boolean {
    let flag = true;
    let totalQuantitySelected = 0;
    this.product.forEach((item) => {
      totalQuantitySelected = totalQuantitySelected + Number(item.quantity);
    });
    if (this.maxQuantity) {
      if (this.maxQuantity < totalQuantitySelected) {
        flag = false;
      }
    }
    if (this.trailLimit && this.trailPack) {
      if (this.trailLimit < totalQuantitySelected) {
        flag = false;
      }
    }

    return flag;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
