import { Component, Input } from '@angular/core';
import { ContactusConfig } from '../../../../shared/config/contactus-config';
import { ModalOptions } from '../../../../shared/model/common.mode';

@Component({
  selector: 'app-contact-us-modal',
  templateUrl: './contact-us-modal.component.html',
  styleUrls: ['./contact-us-modal.component.scss'],
})
export class ContactUsModalComponent {
  @Input() configs: ContactusConfig;
  @Input() options = {
    open: false,
    modalTitle: '',
  } as ModalOptions;
  @Input() formConfig: any;
  @Input() thankYouModalOptions: ModalOptions;
}
