import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeliveryMode } from '@spartacus/cart/base/root';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class PracticeorderDeliverymodeService {
  constructor(private http: HttpClient, private occEndPoints: OccEndpointsService) {}
  getSupportDeliveryModes(userId, code): Observable<DeliveryMode[]> {
    const url = this.occEndPoints.buildUrl(`users/${userId}/carts/${code}/deliverymodes`);
    return this.http.get<any>(url).pipe(
      map((deliveryMode: any) => {
        return deliveryMode.deliveryModes;
      })
    );
  }
  setDeliveryMode(userId, code, deliveryModeId): Observable<DeliveryMode> {
    const url = this.occEndPoints.buildUrl(
      `users/${userId}/carts/${code}/deliverymode?deliveryModeId=${deliveryModeId}`
    );
    return this.http.put<any>(url, {});
  }
}
