import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OccConfig } from '@spartacus/core';
import { FavouriteIconSize, PageType } from 'src/app/shared/model/common.mode';
@Component({
  selector: 'app-cart-product-information',
  templateUrl: './cart-product-information.component.html',
  styleUrls: ['./cart-product-information.component.scss'],
})
export class CartProductInformationComponent implements OnInit, OnChanges {
  trialOrMlProduct: any;
  isValuePackType: boolean;
  selectedPackSize: any;
  constructor(private occConfig: OccConfig, private router: ActivatedRoute) {}
  @Input() cartEntry: any;
  iconSize = FavouriteIconSize.SMALL;
  @Input() showPatientReference: boolean;
  @Input() isFromValuePack = false;
  pageType: string;
  isConfirmationPage = false;
  isOrderDetailsPage = false;
  ngOnInit(): void {
    this.pageType = this.router.snapshot.data.cxRoute;
    this.isConfirmationPage = this.pageType.toLowerCase() === PageType.ORDER_CONFIRMATION.toLowerCase();
    this.isOrderDetailsPage = this.pageType.toLowerCase() === PageType.ORDER_DETAILS.toLowerCase();
    this.trialOrMlProduct =
      this.cartEntry?.product?.packSize?.toLowerCase().includes('trial') ||
      this.cartEntry?.product?.packSize?.toLowerCase().includes('ml');
    this.isValuePackType = this.cartEntry?.valuePackAttributes?.valuePackType;
  }
  ngOnChanges(value: SimpleChanges): void {
    this.cartEntry = { ...value?.cartEntry?.currentValue };
  }
}
