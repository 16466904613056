export default class Enviorment{
    constructor(env) {
        this.env=env;
    }

    getCredentials =()=>{
        let obj={};
        if(this.env=="prod"){
            obj={
                baseURL: env.SAP_VALIDATOR_API_BASE_URL_PROD,
                headers: {
                  Authorization: `Bearer ${env.SAP_VALIDATOR_API_KEY_PROD}`,
                  client_id: env.SAP_VALIDATOR_API_CLIENT_ID_PROD,
                  client_secret: env.SAP_VALIDATOR_API_CLIENT_SECRET_PROD
                }
              };
        }else if(this.env=="dev"){
            obj={
                baseURL: env.SAP_VALIDATOR_API_BASE_URL_DEV,
                headers: {
                  Authorization: `Bearer ${env.SAP_VALIDATOR_API_KEY_DEV}`,
                  client_id: env.SAP_VALIDATOR_API_CLIENT_ID_DEV,
                  client_secret: env.SAP_VALIDATOR_API_CLIENT_SECRET_DEV
                }
              };
        }else if(this.env=="qa"){
            obj={
                baseURL: env.SAP_VALIDATOR_API_BASE_URL_QA,
                headers: {
                  Authorization: `Bearer ${env.SAP_VALIDATOR_API_KEY_QA}`,
                  client_id: env.SAP_VALIDATOR_API_CLIENT_ID_QA,
                  client_secret: env.SAP_VALIDATOR_API_CLIENT_SECRET_QA
                }
              };
        }
        return obj; 
    }
}