import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SearchboxService, SemanticPathService, TranslationService } from '@spartacus/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { PatientService } from 'src/app/services/patient/patient.service';
import { VcGlobalSearchService } from 'src/app/services/vc-global-search/vc-global-search.service';
import { NoDataText } from 'src/app/shared/constants/constants';
import { DropdownConfig, DropdownOptions, SwitchOptions } from 'src/app/shared/model/common.mode';
import { AlconUserPermissionService } from '../../../shared-modules/alcon-user-permission-provider/service/alcon-user-permission.service';

@Component({
  selector: 'app-visioncare-search',
  templateUrl: './visioncare-search.component.html',
  styleUrls: ['./visioncare-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VisioncareSearchComponent implements OnInit {
  get noDatamsg(): typeof NoDataText {
    return NoDataText;
  }
  config: DropdownConfig = {
    showOptionHeader: false,
    maxItemsShown: 5,
    typeAheadConfig: {
      typeAhead: true,
      typeAheadLength: 3,
      internalSearch: false,
    },
    iconReference: 'SearchIcon',
    noDataText: this.noDatamsg.NoProductsFound,
  };

  patientsConfig: DropdownConfig = {
    ...this.config,
    noDataText: this.noDatamsg.NoPatientsFound,
  };
  searchProducts: Observable<DropdownOptions[]> = this.searchboxService.getResults().pipe(
    filter((plp) => !(plp && Object.keys(plp).length === 0 && Object.getPrototypeOf(plp) === Object.prototype)),
    map((plp) => {
      return plp.products.map(
        (prd) =>
          ({
            value: prd.code,
            text: prd.name,
            selected: false,
          } as DropdownOptions)
      );
    })
  );

  toggleSelector: Array<SwitchOptions> = [];
  searchPatientSubject = new Subject<string>();
  searchPatients = this.searchPatientSubject.asObservable();
  searchPatientResult: Observable<DropdownOptions[]> = combineLatest([
    this.patientService.getPatientStoreData(),
    this.searchPatients,
  ]).pipe(
    map(([patients, selectText]) => {
      return patients
        .slice()
        .sort((option1, option2) => (option1.lastName.toLowerCase() > option2.lastName.toLowerCase() ? 1 : -1))
        .map((patient) => {
          return {
            text: patient.lastName + ', ' + patient.firstName,
            value: patient.uid,
            selected: false,
          } as DropdownOptions;
        })
        .filter((option) => {
          return option.text.trim().toLowerCase().includes(selectText.trim().toLowerCase());
        });
    })
  );

  constructor(
    private searchboxService: SearchboxService,
    private route: Router,
    private semanticPathService: SemanticPathService,
    private vcGlobalSearchService: VcGlobalSearchService,
    private patientService: PatientService,
    private userPermissionService: AlconUserPermissionService,
    private translation: TranslationService
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.translation.translate('visioncareSearch.searchProductPlaceholder'),
      this.translation.translate('visioncareSearch.searchPatientPlaceholder'),
      this.translation.translate('visioncareSearch.patient'),
      this.translation.translate('visioncareSearch.product'),
    ]).subscribe(([typeProductNameText, typePatientNameText, patient, product]) => {
      this.config.defaultSelectText = typeProductNameText;
      this.patientsConfig.defaultSelectText = typePatientNameText;
      this.initializeConfig(patient, product);
      this.setToggleSelectorState();
    });
  }

  setToggleSelectorState(): void {
    if (!this.userPermissionService.getPDPButtonpermission()) {
      this.toggleSelector.splice(0, 1);
      this.toggleSelector[0].isSelected = true;
    }

    if (!this.userPermissionService.getPatientManagementPermission()) {
      this.toggleSelector.splice(1, 1);
    }
  }

  initializeConfig(patient: string, product: string): void {
    this.toggleSelector = [
      {
        code: 'product',
        isSelected: true,
        value: product,
      },
      {
        code: 'patient',
        isSelected: false,
        value: patient,
      },
    ];
  }

  get selectedData(): string {
    return this.toggleSelector.find((selector) => selector.isSelected).code;
  }

  getProducts(searchText): void {
    if (searchText && searchText.trim().length > 2) {
      this.searchboxService.search(searchText);
    } else {
      this.searchboxService.clearResults();
    }
  }

  getPatients(value): void {
    this.searchPatientSubject.next(value);
  }

  selectedProduct(selectedItem: DropdownOptions): void {
    this.vcGlobalSearchService.productSearch.next(false);
    this.route.navigate(
      this.semanticPathService.transform({
        cxRoute: 'product',
        params: { productCode: selectedItem.value },
      })
    );
  }
  selectedPatient(selectedItem: DropdownOptions): void {
    this.vcGlobalSearchService.productSearch.next(false);
    this.route.navigate(
      this.semanticPathService.transform({
        cxRoute: 'patientProfile',
        params: { profileId: selectedItem.value },
      })
    );
  }
}
