import { IconConfig, IconResourceType } from '@spartacus/storefront';

export const iconConfig: IconConfig = {
  icon: {
    symbols: {
      AddOrders: 'addOrder',
      SubscriptionDTP: 'dtp',
      SubscriptionDTO: 'dto',
      Notification: 'notification',
      CloseNotification: 'closeNotification',
      ArrowRight: 'arrowRight',
      DropdownExpand: 'dropdownExpand',
      DropdownCollapse: 'dropdownCollapse',
      DropdownExpandBlack: 'dropdownExpandBlack',
      DropdownCollapseBlack: 'dropdownCollapseBlack',
      HeartFilled: 'heartFilled',
      HeartFilledSm: 'heartFilledSm',
      HeartEmpty: 'heartEmpty',
      HeartEmptySm: 'heartEmptySm',
      IconTrash: 'iconTrash',
      DownloadIcon: 'downloadIcon',
      PLPShowMore: 'plpshowmore',
      PLPShowLess: 'plpshowless',
      navArrowIcon: 'nav-arrow-icon',
      closeProfileIcon: 'close-profile-icon',
      SortByHigh: 'sortbyHigh',
      SortByLow: 'sortbyLow',
      CloseMark: 'closeMark',
      Search: 'search',
      Close: 'close',
      ExpandUp: 'expandUp',
      ExpandDown: 'expandDown',
      LoadIcon: 'loadIcon',
      TickIcon: 'tickIcon',
      Remove: 'remove',
      ExpandRight: 'expandRight',
      Edit: 'edit',
      SafeOrdering: 'safeOrdering',
      HideEye: 'hideeye',
      ShowEye: 'showeye',
      AddOrderOption: 'addOrderOption',
      Option: 'option',
      CopyIcon: 'copyIcon',
      EditBlue: 'editBlue',
      CartEdit: 'cartEdit',
      AlphabetAsc: 'alphabetAsc',
      AlphabetDesc: 'alphabetDesc',
      MiniCart: 'minicart',
      OrderHistory: 'orderHistory',
      EditInfo: 'editInfo',
      IconClock: 'iconClock',
      IndividualOrderActionLink: 'individualOrderActionLink',
      StockOrderActionLink: 'stockOrderActionLink',
      BulkOrderActionLink: 'bulkOrderActionLink',
      BackButton: 'backButton',
      PatientOrderActionLink: 'patientOrderActionLink',
      SearchIcon: 'searchIcon',
      QuestionMark: 'questionMark',
      ContactusThankyou: 'contactusthankyou',
      MinusEndIcon: 'minusEndIcon',
      MinusIcon: 'minusIcon',
      PlusIcon: 'plusIcon',
      PartialBackorder: 'partialBackorder',
      DisabledCounter: 'disabledCounter',
      Shipped: 'shipped',
      NewOrder: 'newOrder',
      Cancelled: 'cancelled',
      Backorder: 'backorder',
      Calender: 'calender',
      ChangePassword: 'changePassword',
      Valid: 'validInput',
      PageLoader: 'pageLoader',
      SuccessTick: 'successTick',
      SuccessClose: 'successClose',
      Print: 'print',
      DisabledPlusCounter: 'disabledPlusCounter',
      DisabledMinusCounter: 'disabledMinusCounter',
      ArrowWhite: 'arrowWhite',
      DisabledEdit: 'disabledEdit',
      DeliveryIcon: 'deliveryIcon',
      PaymentIcon: 'paymentIcon',
      InvalidInput: 'invalidInput',
      DisablePassword: 'disablePassword',
      DisabledDelete: 'disabledDelete',
      Refresh: 'refresh',
      ReturnProduct: 'returnProduct',
      blueCloseIcon: 'blueCloseIcon',
      DropdownExpandSmall: 'dropdownExpandSmall',
      DropdownCollapseSmall: 'dropdownCollapseSmall',
      pendingConsolidationSmallIcon: 'pendingConsolidationSmallIcon',
      PendingConsolidationBigIcon: 'pendingConsolidationBigIcon',
      AccountDetailsConsolidation: 'accountDetailsConsolidation',
      ReOrder: 'reOrder',
      ReOrderDisable: 'reOrderDisable',
      UserProfile: 'userProfile',
      DisabledClose: 'disabledClose',
      SearchOrder: 'searchOrder',
      AlertIcon: 'alertIcon',
      InformationCircleIcon: 'informationCircleIcon',
      Change: 'change',
      VISA: 'visa',
      MASTER: 'mastercard',
      OrderDetails: 'orderDetails',
      OrderLineIteamInfo: 'orderLineIteamInfo',
      Delivered: 'delivered',
      CounterError: 'counterError',
      WarningIcon: 'warningIcon',
      AccessPending: 'AccessPending',
      PermissionExpand: 'PermissionExpand',
      PermissionCollapse: 'PermissionCollapse',
      DisabledPermissionCollapse: 'disabledPermissionCollapse',
      PermissionCloseDisabled: 'permissionCloseDisabled',
      PermissionCloseEnabled: 'permissionCloseEnabled',
    },
    resources: [
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/add.svg',
        types: ['AddOrders'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/dropdown-expand.svg',
        types: ['DropdownExpand'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/dropdown-collapse.svg',
        types: ['DropdownCollapse'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/dropdown-expand-black.svg',
        types: ['DropdownExpandBlack'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/dropdown-collapse-black.svg',
        types: ['DropdownCollapseBlack'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/subscriptiondtp.svg',
        types: ['SubscriptionDTP'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/subscriptiondto.svg',
        types: ['SubscriptionDTO'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/notification-icon.svg',
        types: ['Notification'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/notification-close-icon.svg',
        types: ['CloseNotification'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/arrow-right.svg',
        types: ['ArrowRight'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/heart-filled.svg',
        types: ['HeartFilled'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/heart-filled-sm.svg',
        types: ['HeartFilledSm'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/heart-empty.svg',
        types: ['HeartEmpty'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/heart-empty-sm.svg',
        types: ['HeartEmptySm'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/icon-trash.svg',
        types: ['IconTrash'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/download-icon.svg',
        types: ['DownloadIcon'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/filter_show_more.svg',
        types: ['PLPShowMore'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/filter_show_less.svg',
        types: ['PLPShowLess'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/nav-arrow-icon.svg',
        types: ['navArrowIcon'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/close-profile.svg',
        types: ['closeProfileIcon'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/sortBy-high-icon.svg',
        types: ['SortByHigh'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/sortBy-low-icon.svg',
        types: ['SortByLow'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/closeMark.svg',
        types: ['CloseMark'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/search.svg',
        types: ['Search'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/close.svg',
        types: ['Close'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/expand-up.svg',
        types: ['ExpandUp'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/expand-down.svg',
        types: ['ExpandDown'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/remove.svg',
        types: ['Remove'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/load-icon.svg',
        types: ['LoadIcon'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/tick-icon.svg',
        types: ['TickIcon'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/expand-right.svg',
        types: ['ExpandRight'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/edit.svg',
        types: ['Edit'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/safe-ordering.svg',
        types: ['SafeOrdering'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/showeye.svg',
        types: ['ShowEye'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/hideeye.svg',
        types: ['HideEye'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/addOrder.svg',
        types: ['AddOrderOption'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/option.svg',
        types: ['Option'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/copyIcon.svg',
        types: ['CopyIcon'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/edit-blue.svg',
        types: ['EditBlue'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/icon-cart-edit.svg',
        types: ['CartEdit'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/alphabet_asc.svg',
        types: ['AlphabetAsc'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/alphabet_desc.svg',
        types: ['AlphabetDesc'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/basket.svg',
        types: ['MiniCart'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/edit-info.svg',
        types: ['EditInfo'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/order-history.svg',
        types: ['OrderHistory'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/IndividualOrderActionLink.svg',
        types: ['IndividualOrderActionLink'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/StockOrderActionLink.svg',
        types: ['StockOrderActionLink'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/BulkOrderActionLink.svg',
        types: ['BulkOrderActionLink'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/backButton.svg',
        types: ['BackButton'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/PatientOrderActionLink.svg',
        types: ['PatientOrderActionLink'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/icon-clock.svg',
        types: ['IconClock'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/searchIcon.svg',
        types: ['SearchIcon'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/question-mark.svg',
        types: ['QuestionMark'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/contactus-thankyou.svg',
        types: ['ContactusThankyou'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/minusEndIcon.svg',
        types: ['MinusEndIcon'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/plusIcon.svg',
        types: ['PlusIcon'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/minusIcon.svg',
        types: ['MinusIcon'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/partially-shipped.svg',
        types: ['PartialBackorder'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/disabled-counter.svg',
        types: ['DisabledCounter'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/calender.svg',
        types: ['Calender'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/change-password.svg',
        types: ['ChangePassword'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/valid-input.svg',
        types: ['Valid'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/shipped-icon.svg',
        types: ['Shipped'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/neworder-icon.svg',
        types: ['NewOrder'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/cancelled-icon.svg',
        types: ['Cancelled'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/delayed-icon.svg',
        types: ['Backorder'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/page-loader.svg',
        types: ['PageLoader'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/success-tick.svg',
        types: ['SuccessTick'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/success-close.svg',
        types: ['SuccessClose'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/print.svg',
        types: ['Print'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/disabledPlusCounter.svg',
        types: ['DisabledPlusCounter'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/disabledMinusCounter.svg',
        types: ['DisabledMinusCounter'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/arrow-white.svg',
        types: ['ArrowWhite'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/disabledEdit.svg',
        types: ['DisabledEdit'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/paymentIcon.svg',
        types: ['PaymentIcon'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/deliveryIcon.svg',
        types: ['DeliveryIcon'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/invalid-input.svg',
        types: ['InvalidInput'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/disable-password.svg',
        types: ['DisablePassword'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/disabled-delete.svg',
        types: ['DisabledDelete'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/refresh.svg',
        types: ['Refresh'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/return-product.svg',
        types: ['ReturnProduct'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/blue-close-icon.svg',
        types: ['blueCloseIcon'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/dropdown-expand-small.svg',
        types: ['DropdownExpandSmall'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/dropdown-collapse-small.svg',
        types: ['DropdownCollapseSmall'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/pending_consolidation_big_icon.svg',
        types: ['PendingConsolidationBigIcon'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/pending_consolidation_small_icon.svg',
        types: ['pendingConsolidationSmallIcon'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/account_details_consolidation.svg',
        types: ['AccountDetailsConsolidation'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/reOrder.svg',
        types: ['ReOrder'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/reOrder-disable.svg',
        types: ['ReOrderDisable'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/userProfile.svg',
        types: ['UserProfile'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/disabledclose.svg',
        types: ['DisabledClose'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/searchOrder.svg',
        types: ['SearchOrder'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/info-round.svg',
        types: ['InformationCircleIcon'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/change.svg',
        types: ['Change'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/visa.svg',
        types: ['VISA'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/mastercard.svg',
        types: ['MASTER'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/alert.svg',
        types: ['AlertIcon'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/orderDetails.svg',
        types: ['OrderDetails'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/info_icon_orange.svg',
        types: ['OrderLineIteamInfo'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/delivered-icon.svg',
        types: ['Delivered'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/counter-error.svg',
        types: ['CounterError'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/warning-icon.svg',
        types: ['WarningIcon'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/access-pending.svg',
        types: ['AccessPending'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/permission-expand.svg',
        types: ['PermissionExpand'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/permission-collapse.svg',
        types: ['PermissionCollapse'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/disabled-permission-collapse.svg',
        types: ['DisabledPermissionCollapse'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/permission-close-disabled.svg',
        types: ['PermissionCloseDisabled'],
      },
      {
        type: IconResourceType.SVG,
        url: './assets/Icons/permission-close-enabled.svg',
        types: ['PermissionCloseEnabled'],
      },
    ],
  },
};
