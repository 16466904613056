import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { CmsService, PageMeta, PageMetaService } from '@spartacus/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { InvoiceList } from 'src/app/shared/model/invoice-list.model';
import { AlconUserPermissionService } from '../alcon-user-permission-provider/service/alcon-user-permission.service';
import { InvoicesService } from './services/invoices.service';

@Component({
  selector: 'app-invoices-page',
  templateUrl: './invoices-page.component.html',
  styleUrls: ['./invoices-page.component.scss'],
})
export class InvoicesPageComponent implements OnInit, OnDestroy {
  invoicesCount: number;
  title$: Observable<string>;
  searchParams: Params;
  searchNumber: string;
  disableSearch: boolean;
  showInvoicesPage = true;
  destroy$ = new Subject<void>();

  errorImage;
  subscriptions: Subscription[] = [];

  constructor(
    private pageMetaService: PageMetaService,
    private route: ActivatedRoute,
    private invoiceService: InvoicesService,
    private userPermission: AlconUserPermissionService,
    private cmsService: CmsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.showInvoicesPage = this.userPermission.getPriceStatementPermission();
    this.title$ = this.pageMetaService.getMeta().pipe(
      filter(Boolean),
      map((meta: PageMeta) => meta.heading || meta.title)
    );
    this.searchParams = this.route.snapshot.queryParams;

    this.subscriptions.push(
      this.cmsService
        .getComponentData('ErrorPageImageComponent')
        .pipe(takeUntil(this.destroy$))
        .subscribe((imageContent) => {
          this.errorImage = imageContent;
        })
    );

    this.invoicesCount = Number(this.route.snapshot.data?.invoices?.pagination?.totalNumberOfResults);
    this.invoiceService.invoiceListData.pipe(takeUntil(this.destroy$)).subscribe((invoiceList: InvoiceList) => {
      this.invoicesCount = Number(invoiceList?.pagination?.totalNumberOfResults);
      this.searchParams = this.route.snapshot.queryParams;
      this.cd.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subs) => {
      subs.unsubscribe();
    });
    this.destroy$.next();
    this.destroy$.complete();
  }
}
