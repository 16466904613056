<div *ngIf="!returnAccordionStatus && productsArray.length > 1" class="d-flex d-lg-none justify-content-end mb-4">
  <button class="btn btn-primary btn-save returnForm--return-product" (click)="saveReturnProducts()">
    {{ 'returnForm.returnproduct' | cxTranslate }}
  </button>
</div>
<div
  class="returnForm justify-content-between returnForm--background"
  [ngClass]="productsArray.length > 0 ? 'd-none' : 'd-flex'"
>
  <div class="returnForm__productselector">
    <h3 class="returnForm__selectorHeader">{{ 'returnForm.selectProductHeader' | cxTranslate }}</h3>
    <app-return-form-product-selector
      [products]="products$ | async"
      (productSelected)="setSelectedProductDetails($event)"
    ></app-return-form-product-selector>
  </div>
  <div class="d-flex align-items-center returnForm__imageHold">
    <cx-media [container]="(returnFormImgContent | async).media" alt="Return Form Image"></cx-media>
  </div>
</div>
<div #productconfiguratortemplate></div>

<div *ngIf="!returnAccordionStatus && productsArray.length > 0" class="d-flex justify-content-end mt-4">
  <button class="btn btn-primary btn-save returnForm--return-product" (click)="saveReturnProducts()">
    {{ 'returnForm.returnproduct' | cxTranslate }}
  </button>
</div>

<div *ngIf="!returnAccordionStatus && productsArray.length > 0" class="returnForm__addanother mt-3">
  <h3 class="returnForm__selectorHeader">{{ 'returnForm.addAnotherProduct' | cxTranslate }}</h3>
  <app-return-form-product-selector
    [products]="products$ | async"
    (productSelected)="setSelectedProductDetails($event)"
  ></app-return-form-product-selector>
</div>
