<div class="accessPopup">
  <div class="accessPopup__close">
    <cx-icon type="Close" (click)="cancelForm()" class="close-icon cx-icon close"> </cx-icon>
  </div>
  <h3 class="accessPopup__header">{{ 'multiAccountSelector.requestAdditionalAccount' | cxTranslate }}</h3>
  <form class="accessPopup__additional-account-form">
    <span class="accessPopup__additional-account-form__container">
      <label class="accessPopup__additional-account-form__label d-none d-lg-block">{{
        'multiAccountSelector.requestLabel' | cxTranslate
      }}</label>
      <input
        type="text"
        class="form-control accessPopup__additional-account-form__select"
        maxlength="10"
        appOnlyNumeric
        [(ngModel)]="accountNumber"
        name="accountNumber"
        (ngModelChange)="accountNumberChange($event)"
        [ngClass]="{
          'accessPopup__additional-account-form__invalid-field-border': checkAccountExist || varifyAccountAccess
        }"
      />
      <cx-icon
        type="Close"
        class="accessPopup__additional-account-form__icon close-icon cx-icon close"
        *ngIf="accountNumber"
        queryParamsHandling="merge"
        (click)="clearSearch()"
      >
      </cx-icon>
    </span>
    <div
      *ngIf="checkAccountExist || varifyAccountAccess"
      class="d-flex accessPopup__additional-account-form__error-message-styles"
    >
      <cx-icon type="CounterError"> </cx-icon>
      <span
        *ngIf="checkAccountExist"
        class="accessPopup__additional-account-form__error-message-text"
        [innerHtml]="'multiAccountSelector.alreadyExistAccount' | cxTranslate"
      ></span>
      <span
        *ngIf="varifyAccountAccess"
        class="d-block accessPopup__additional-account-form__error-message-text"
        [innerHtml]="'multiAccountSelector.verifyAccessError' | cxTranslate"
      >
      </span>
    </div>
  </form>

  <div class="row padding-x offset-5 justify-content-sm-end justify-content-md-end">
    <div class="col-2 d-flex align-items-center accessPopup__additional-account-form__cancel" (click)="cancelForm()">
      {{ 'myPatientsPage.addNewPatient.form.cancel' | cxTranslate }}
    </div>
    <div class="col-8 accessPopup__additional-account-form__pr-35">
      <button
        [disabled]="accountNumber?.length < 9 || checkAccountExist || varifyAccountAccess || requestSending"
        class="btn btn-primary text-uppercase float-right accessPopup__additional-account-form__btn-save"
        (click)="onSubmit()"
      >
        {{
          requestSending
            ? ('multiAccountSelector.requestSending' | cxTranslate)
            : ('multiAccountSelector.requestSend' | cxTranslate)
        }}
        <span *ngIf="requestSending" class="accessPopup__additional-account-form__loading-icon">
          <cx-icon class="load-icon" type="LoadIcon"></cx-icon>
        </span>
      </button>
    </div>
  </div>
</div>
