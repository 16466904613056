import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OktaAuth } from '@okta/okta-auth-js';
import { SiteContextConfig } from '@spartacus/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OktaService {
  constructor(private http: HttpClient, private siteContextConfig: SiteContextConfig) {}
  getOktaConfig(titles?, labels?): any {
    return {
      baseUrl: environment.BASE_URL,
      clientId: environment.CLIENT_ID,
      redirectUri: environment.REDIRECT_URL,
      issuer: environment.ISSUER,
      PKCE: true,
      authParams: {
        issuer: environment.ISSUER,
        responseType: ['token', 'id_token'],
        display: 'page',
      },
      widgetRoutes: {
        [':country/registration']: 'register',
      },
      features: {
        scrollOnError: false,
        registration: true,
        handleInitRoute: true,
        showPasswordToggleOnRegPage: true,
        sapValidation: true,
      },
      i18n: {
        en: {
          'primaryauth.title': ' ',
          'primaryauth.username.placeholder': 'E-mail address',
          'primaryauth.submit': 'LOG IN',
          'error.username.required': ' Please enter a valid e-mail',
          'error.password.required': 'Please enter your password',
          'password.forgot.email.or.username.placeholder': 'E-mail address',
          'password.forgot.email.or.username.tooltip': 'E-mail address',
          goback: 'Cancel',
          'registration.form.submit': 'NEXT',
          'registration.form.title': 'Registration to MyAlcon',
          'registration.required.fields.label': '* Mandatory fields',
          'enroll.choices.list.setup': ' ',
          'enroll.choices.submit.configure': 'Next',
        },
      },
      sapValidation: {
        emailField: 'email',
        phoneField: 'mobilePhone',
        sapIdField: 'sapId',
        env: environment.OKTA_WIDGET_ENV, // qa | prod | dev
      },
      registration: {
        parseSchema: (schema, onSuccess): void => {
          schema.profileSchema.properties.password.allOf = [
            {
              description: 'At least 8 characters',
            },
            {
              description: 'Contains an upper case character, a lower case character and a number',
            },
            {
              description: 'Can’t contain your name or e-mail address',
            },
          ];
          // This configurations will add an additional field to the registration form
          schema.profileSchema.properties.title = {
            type: 'string',
            title: 'Title',
            withLabel: true,
            oneOf: titles,
          };
          schema.profileSchema.properties.confirmPassword = {
            type: 'password',
            title: 'Confirm pasword',
          };
          schema.profileSchema.properties.mobilePhone.type = 'phone';
          schema.profileSchema.properties.email.maxLength = 50;
          schema.profileSchema.properties.sapId = {
            type: 'sapId',
            title: 'Alcon Account number',
          };
          schema.profileSchema.properties.firstName.maxLength = 30;
          schema.profileSchema.properties.lastName.maxLength = 30;

          // This configurations will add consents to the registration form
          const [selectedMarket] = this.siteContextConfig.context.custom;
          const consentParams = {
            application_name: 'MyAlconStore',
            country_code: selectedMarket.toUpperCase(),

            pnp: {
              text: 'I have read and agreed Alcon’s Terms and Conditions.',
              links: [
                {
                  href: labels[13],
                  word: 'Terms and Conditions',
                },
              ],
              type: 'operational',
              visibility: true,
              required: true,
              default: false,
            },

            tnc: {
              text: 'I have read and agreed Alcon’s Privacy Policy.',
              links: [
                {
                  href: labels[14],
                  word: 'Privacy Policy',
                },
              ],
              type: 'operational',
              visibility: true,
              required: true,
              default: false,
            },

            mnc: {
              text:
                'By checking the checkbox, you confirm that you consent to join our mailing list to receive information and news from Alcon on promotional and product information, events and trainings, the Alcon newsletter(s), and the Alcon Experience Academy. You can opt out of receiving these messages from Alcon at any time through clicking on the unsubscribe link included in emails or replying STOP via SMS. If you would like more information on Alcon and our approach to privacy please see our privacy policy.',
              links: [
                {
                  href: labels[15],
                  word: 'privacy policy',
                },
              ],
              type: 'marketing',
              visibility: true,
              required: false,
              default: false,
            },
          };

          schema.profileSchema.properties.consentData = {
            type: 'Consent',
            objStr: JSON.stringify(consentParams),
          };

          schema.profileSchema.fieldOrder = [
            'title',
            'firstName',
            'lastName',
            'mobilePhone',
            'email',
            'sapId',
            'password',
            'confirmPassword',
            'consent',
          ];
          schema.profileSchema.required = ['firstName', 'lastName', 'email', 'sapId', 'password', 'confirmPassword'];
          onSuccess(schema);
        },
        preSubmit: (postData, onSuccess, onFailure): void => {
          postData.groupAssignment = 'MyAlconIntl_SurgicalUsers';
          let error = false;

          if (postData.password !== postData.confirmPassword) {
            onFailure({
              errorSummary: 'API Error',
              errorCauses: [
                {
                  errorSummary: 'Passwords do not match',
                  property: 'confirmPassword',
                },
              ],
            });
            error = true;
          }

          if (!error) {
            onSuccess(postData);
          }
        },
      },
    };
  }

  sendTokenToHybris(accesstoken): any {
    const params = new HttpParams()
      .set('client_id', 'Okta_client')
      .set('grant_type', 'urn:ietf:params:oauth:grant-type:jwt-bearer')
      .set('client_secret', 'secret')
      .set('token', accesstoken);
    return this.http.post<any>(
      environment.API_BASE_URL + '/authorizationserver/oauth/token',
      {},
      {
        params,
      }
    );
  }
  async resetPassword(): Promise<any> {
    const authClient = new OktaAuth(this.getOktaConfig());
    const accessToken = await authClient.tokenManager.get('accessToken');
    const headers = new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json');
    const body = {
      username: accessToken.claims.email_id,
      factorType: 'EMAIL',
    };
    return this.http
      .post<any>(`${environment.BASE_URL}/api/v1/authn/recovery/password`, body, { headers })
      .toPromise();
  }
}
