import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  GlobalMessageService,
  GlobalMessageType,
  HttpErrorHandler,
  HttpResponseStatus,
  Priority,
  TranslationService,
} from '@spartacus/core';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UnauthorizedErrorHandler extends HttpErrorHandler {
  responseStatus = HttpResponseStatus.UNAUTHORIZED;

  constructor(
    private translation: TranslationService,
    globalMessageService: GlobalMessageService,
    @Inject(PLATFORM_ID) platformId?: object
  ) {
    super(globalMessageService, platformId);
  }

  getPriority?(): Priority {
    return Priority.LOW;
  }

  handleError(request: HttpRequest<any>, response: HttpErrorResponse): void {
    let parsedJson;
    try {
      parsedJson = JSON.parse(response?.error?.error_description);
    } catch (err) {
      return;
    }
    let translationCode;
    switch (parsedJson?.errorType) {
      case 'unauthorized_01':
        translationCode = 'errorHandler.unauthorized_01';
        this.translation
          .translate(translationCode)
          .pipe(take(1))
          .subscribe((translatedString) => {
            this.globalMessageService.add(translatedString, GlobalMessageType.MSG_TYPE_ERROR);
          });
        break;
      case 'unauthorized_02':
        translationCode = 'errorHandler.unauthorized_02';
        break;
      default:
        translationCode = 'errorHandler.message';
        break;
    }
  }
}
