import { Component, OnInit } from '@angular/core';
import { PageMeta, PageMetaService, CmsService, CmsParagraphComponent, ContentSlotData } from '@spartacus/core';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-tools-resources',
  templateUrl: './tools-resources.component.html',
  styleUrls: ['./tools-resources.component.scss'],
})
export class ToolsAndResourcesComponent implements OnInit {
  title$: Observable<string>;
  subHeading: Observable<string>;
  panelList: Observable<ContentSlotData>;

  constructor(private pageMetaService: PageMetaService, private cmsService: CmsService) {}

  ngOnInit(): void {
    this.title$ = this.pageMetaService.getMeta().pipe(
      filter(Boolean),
      map((meta: PageMeta) => meta.heading || meta.title)
    );

    this.subHeading = this.cmsService
      .getComponentData<CmsParagraphComponent>('ToolsResourcesMessageComponent')
      .pipe(map((resp: CmsParagraphComponent) => resp.content));

    this.panelList = this.cmsService.getContentSlot('ToolsResourcesListPosition');
  }
}
