import { _, internal, Input, registerInput, loc } from 'okta'
import { template } from 'handlebars';
import Util from "../../util/Util";
const TextBox = internal.views.forms.inputs.TextBox;
const TYPE = 'Roles';
const HOST_CLASS_NAME = 'okta-form-input-field input-fix';
const ERROR_ROLE_MAP = [
  'model.validation.field.roles.notSelected',
];
Util.logConsoleError('something roles');
const RolesList = {
  "vc_doctor":"VC Doctor",
  "vc_staff":"VC Staff",
  "vc_admin":"VC Admin",
  "sx_doctor":"SX Doctor",
  "sx_staff":"SX Staff",
  "sx_admin":"SX Admin"
};
function lookupPropertyDefaultFn(parent, propertyName) {
  if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
    return parent[propertyName];
  }
  return undefined;
};
export const PhoneInput = TextBox.extend({
  constructor() {
    TextBox.apply(this, arguments);
    const props = this.model.__schema__.props[this.options.name] || {};
    props.validate = this.validationDelegate2.bind(this);
  },
  template: template({
    'compiler': [8, '>= 4.3.0'],
    'main': function main(_container, depth0, _helpers, _partials, _data) {
       
      const lookupProperty = _container.lookupProperty || lookupPropertyDefaultFn;
      const inputId = lookupProperty(depth0, 'inputId') || '';
      const name = lookupProperty(depth0, 'name') || '';
      const placeholder = lookupProperty(depth0, 'placeholder') || '';
      const props = depth0.model.__schema__.props[depth0.name];
      const required = lookupProperty(depth0, 'required') || props && lookupProperty(props, 'required') || false;
      //const dateFormat = lookupProperty(depth0, 'dateFormat') || '';
      //const dateFormat = lookupProperty(depth0, 'dateFormat') || props && lookupProperty(props, 'dateFormat') || '';
      //const dateFormat = depth0._getPropByKey('dateFormat');
      //const dateFormat = depth0.dateFormat;
      //const dateFormat = depth0.model.__schema__.props[depth0.dateFormat];
      

      // if the dob format is DD-MM-YYYY
      
        var html=``;
        for (var key in RolesList) {
            html+=`<input type="radio" name="${name}" id="${key}" data-val="${key}" value="`+ RolesList[key]+`">
                <label for="`+ key+`">`+ RolesList[key]+`</label><br>`;

        }
        return html;
      

    },
    'useData': true
  }),
  /**
   * @Override
   */
  events: {
    'input input': 'update',
    'change input': 'update',
    'keydown input': 'update',
    'countrychange input': 'update',
    'keyup input': function keyupInput(e) {
      if (internal.util.Keys.isEsc(e)) {
        this.model.trigger('form:cancel');
      }
    }
  },

  /**
   * @Override
   */
  postRender() {
    TextBox.prototype.postRender.call(this);
    const params = this.options.params;
    const inputElement = this.$(`input[name="${this.options.name}"]`);
  },

 /**
   * @Override
   */
  val() {
    return this.$(`input[name="${this.options.name}"]:checked`).attr("data-val");
  },

  /**
   * 
   * @param {string} value 
   * @returns {string | boolean} 
   */
   validationDelegate2(value) {
    Util.logConsoleError(value);
    if (typeof value == 'undefined'){
     return ERROR_ROLE_MAP[0];
    }
 
     return true;
   }
});

registerInput(TYPE, PhoneInput);

