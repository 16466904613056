import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { OrderHistoryService } from '../services/order-history/order-history.service';

@Injectable({
  providedIn: 'root',
})
export class PatientOrderhistoryResolver implements Resolve<boolean> {
  constructor(private orderHistoryservice: OrderHistoryService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.orderHistoryservice.getPatientOrderHistory(route.paramMap.get('profileId'));
  }
}
