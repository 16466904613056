<ng-container *ngIf="accounts$ | async; else loadUnits">
  <div class="selector-container d-flex flex-column align-items-center m-auto bg-white">
    <h1 class="selector-container__heading mb-0">{{ 'multiAccountSelector.title' | cxTranslate }}</h1>
    <section class="selector-container__welcome text-center" *ngIf="useraccountInfo$ | async as userinfo">
      <p *ngIf="isFromPage">
        {{ 'multiAccountSelector.welcomeMessage' | cxTranslate }}
        {{ userinfo.name }}!
      </p>
      <p class="mb-1">{{ 'multiAccountSelector.message' | cxTranslate }}</p>
    </section>
    <ng-container *ngIf="basesites$ | async as basesites">
      <section
        *ngIf="basesites?.additionalAccountEnabledForSx && basesites?.isSxStorefront && !isFromPage"
        class="text-center"
      >
        <span class="selector-container__find-text">{{
          'multiAccountSelector.soldToselector.canNotFoundAccount' | cxTranslate
        }}</span>
        <span (click)="openRequestPopup()" class="selector-container__label-link">{{
          'multiAccountSelector.soldToselector.requestAdditionalAccount' | cxTranslate
        }}</span>
      </section>
    </ng-container>
    <section class="selector-container__accounts d-flex flex-wrap">
      <app-account-details
        *ngFor="let account of accounts$ | async"
        [address]="account"
        (setUnitData)="setUnitData($event)"
      >
      </app-account-details>
    </section>
  </div>
</ng-container>
<ng-template #loadUnits>
  <div class="d-flex mt-5 justify-content-center">
    <strong>{{ 'multiAccountSelector.loaderMessage' | cxTranslate }}</strong>
    <div class="spinner-border ml-5" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
