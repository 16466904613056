import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductErrorsService {
  private errorSubject = new Subject<any[]>();
  productErros = this.errorSubject.asObservable();

  private error: any[] = [];
  constructor() {}

  addProductError(error): void {
    this.error.push(error);
    this.errorSubject.next([...this.error]);
  }

  removeProductError(): void {
    this.error = [];
  }
}
