import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CmsService } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { OrderDetailsService } from 'src/app/services/order-details/order-details.service';
@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.scss'],
})
export class OrderConfirmationComponent implements OnInit, OnDestroy {
  constructor(
    private cmsService: CmsService,
    private activatedRoute: ActivatedRoute,
    private orderDetailsService: OrderDetailsService
  ) {}
  subscriptions: Subscription[] = [];
  orderDesc1;
  orderDesc2;
  orderNumber;
  ngOnInit(): void {
    this.orderNumber = this.activatedRoute.snapshot.params.orderid;
    this.subscriptions.push(
      this.cmsService.getComponentData('OrderConfirmationDescriptionComponent1').subscribe((val) => {
        this.orderDesc1 = val;
      })
    );
    this.subscriptions.push(
      this.cmsService.getComponentData('OrderConfirmationDescriptionComponent2').subscribe((val) => {
        this.orderDesc2 = val;
      })
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subs) => {
      subs.unsubscribe();
    });
  }
}
