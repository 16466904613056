<div class="dtp-confirmed-wrapper">
  <div class="d-flex align-items: center; justify-content-between dtp-confirmed-wrapper__header">
    <div>
      <label class="dtp-confirmed-wrapper__header--label">{{ 'miniCartPage.' + dtpOrders?.label | cxTranslate }}</label>
    </div>
    <div class="dtp-confirmed-wrapper__header--delivery">
      <label class="dtp-confirmed-wrapper__header--delivery--label">{{
        'orderConfirmation.deliveryMethod' | cxTranslate
      }}</label>
      <label class="dtp-confirmed-wrapper__header--delivery--value">{{
        'orderConfirmation.deliveryMethodDetails' | cxTranslate
      }}</label>
    </div>
  </div>
  <div *ngFor="let dtpOrder of dtpOrders?.entries">
    <app-dtp-confirmed-order-details [dtpOrderDetails]="dtpOrder" [finalPriceCalculated]="finalPriceCalculated">
    </app-dtp-confirmed-order-details>
  </div>
  <div *ngFor="let valuePack of dtpOrders?.children[0]?.entries">
    <app-dtp-confirmed-order-details [dtpOrderDetails]="valuePack" [finalPriceCalculated]="finalPriceCalculated">
    </app-dtp-confirmed-order-details>
  </div>
</div>
