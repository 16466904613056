<div *ngFor="let condition of termscondtions; let i = index" class="ml-0 pl-0 d-flex">
  <div class="terms-conditions" *ngIf="condition.text">
    <input
      type="checkbox"
      id="terms-conditions{{ i }}"
      name="terms-conditions{{ i }}"
      (change)="selectCheckBox()"
      [checked]="condition.checked"
      class="alconInput__checkbox"
      [(ngModel)]="condition.checked"
    />
    <label for="terms-conditions{{ i }}" class="terms-condition__label" [innerHtml]="condition.text"></label>
  </div>
</div>
