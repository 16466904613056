import { Pipe, PipeTransform } from '@angular/core';
import { NavgationMenu } from 'src/app/shared/model/common.mode';
import { AlconUserPermissionService } from '../shared-modules/alcon-user-permission-provider/service/alcon-user-permission.service';
@Pipe({
  name: 'navOptions',
})
export class NavOptionsPipe implements PipeTransform {
  shipToPages = [];
  constructor(private userPermission: AlconUserPermissionService) {
    if (!this.userPermission.getPatientManagementPermission()) {
      this.shipToPages.push('My patients');
    }
    if (!this.userPermission.getOrderhistoryPermission()) {
      this.shipToPages.push('Order history');
    }
    if (!this.userPermission.getInvoicesPermission()) {
      this.shipToPages.push('Invoices');
    }
  }
  transform(headerNavOptions: NavgationMenu[]): NavgationMenu[] {
    return this.shipToPages.length > 0 ? this.getFilteredOptions(headerNavOptions) : headerNavOptions;
  }
  getFilteredOptions(headerNavOptions: NavgationMenu[]): NavgationMenu[] {
    return headerNavOptions?.filter((navOption: NavgationMenu) => {
      const option = navOption?.children ? this.getChildNavOption(navOption) : this.getNavOption(navOption);
      return option;
    });
  }
  getNavOption(navOption: NavgationMenu): NavgationMenu | boolean {
    const selected = this.shipToPages.find((page) => page === navOption.title);
    return selected ? false : navOption;
  }
  getChildNavOption(navOption: NavgationMenu): NavgationMenu | boolean {
    const navOptionChildArray = navOption.children.filter((option) => {
      return this.getNavOption(option);
    });
    navOption.children = navOptionChildArray;
    return navOptionChildArray.length > 0 ? navOption : false;
  }
}
