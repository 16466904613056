<div class="minicartVP">
  <app-mini-cart-product-details *ngFor="let vpEntry of valuePackComponentData.entries" [productData]="vpEntry">
  </app-mini-cart-product-details>

  <div
    *ngIf="!((onCartPage | async)?.cxRoute === 'cart')"
    class="minicartVP__removeBtn"
    appRemoveFromCart
    [entries]="valuePackComponentData.entryNumberList"
  >
    <cx-icon class="Remove" type="Remove"> </cx-icon>
  </div>
  <p class="minicartVP__vpDuration">
    {{ 'miniCartPage.valuePackDuration' | cxTranslate
    }}{{ valuePackComponentData.entries[0]?.valuePackAttributes?.valuePackDuration
    }}{{ 'miniCartPage.valuePackMonth' | cxTranslate }}:
    <span class="minicartVP__valuepack-price">
      <app-show-price [productDetails]="valuePackComponentData"></app-show-price>
    </span>
  </p>
</div>
