import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslationService } from '@spartacus/core';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ProductDetailsService } from 'src/app/services/alcon-pdp/product-details.service';
import { LocalStorageService } from 'src/app/services/localstorage/local-storage.service';
import { CART_VARIENTS, DIRECT_TO_PRACTICE, Variants } from 'src/app/shared/constants/constants';
import { DropdownOptions, ErrorItem } from 'src/app/shared/model/common.mode';
import {
  BasicProductSelectedVariants,
  PackSize,
  ProductInfo,
  ProductItems,
  ProductVarientTree,
  VarientCode,
} from 'src/app/shared/model/product.model';
import { Utilities } from 'src/app/shared/utility/utility';
import { VisionCareAddtocartProductRequest } from 'src/app/store/States/cartItem.state';

@Component({
  selector: 'app-stockorder-details-panel-component',
  templateUrl: './stockorder-details-panel.component.html',
  styleUrls: ['./stockorder-details-panel.component.scss'],
})
export class StockorderDetailsPanelComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() selectedProduct: ProductInfo;
  displayErrMsg: string;
  @Input() cartType: string;
  @Input() editCart: string;
  @Input() errorObject: ErrorItem[];
  clearQuantity = { status: false };
  selectedPackSize: string;
  lensSelectedData: string;
  selectedBC: string;
  selectedDiameter: string;
  lensSelectedValuesData: string;
  powerIndexMinus = -1;
  powerIndexPlus = -1;
  contactLensType: string;
  maxQuantity: string;
  packSize: Array<PackSize> = [];
  price: string;
  quantityError = false;
  product: ProductItems;
  varientStorage: Array<ProductInfo> = [];
  toricProduct = DIRECT_TO_PRACTICE.PRODUCT_TORIC;
  orderType = DIRECT_TO_PRACTICE.STOCK_ORDER;
  disabled = true;
  varients = {
    packSize: null,
    basecurve: null,
    diameter: null,
    color: null,
    powerAdd: null,
    cylinder: null,
    lenspower: null,
    axis: null,
  } as BasicProductSelectedVariants;
  productCode: string;
  variantTree: ProductVarientTree;
  BaseCurveSelector: Array<DropdownOptions> = [];
  DiameterSelector: Array<DropdownOptions> = [];
  LensPowerSelector: Array<VarientCode> = [];
  colorSelector: Array<DropdownOptions> = [];
  addSelector: Array<DropdownOptions> = [];
  lenscylinderSelector: Array<DropdownOptions> = [];
  axisSelector: Array<VarientCode>;
  lensSelectedValues: Array<VisionCareAddtocartProductRequest> = [];
  changedLensSelectedValues: Array<VisionCareAddtocartProductRequest> = [];
  totalQuantitySelected = 0;
  addingtocartLoading = false;
  addingtocartTick = false;
  addToCartText = 'ADD TO CART';
  updateTOcartText = 'UPDATE';
  addToCartTextLoading: string;
  addToCartTextSuccess: string;
  updateToCartLoading = 'Updating Cart';
  updateToCartSuccess = 'Updated Cart';
  addToCart: string;
  defaultPackSize: string;
  disablePanel = false;
  displayError = false;
  storageFalg = false;
  positionId: string;
  @Output() closeHandeller = new EventEmitter();
  @Output() emitUpdatedData: EventEmitter<any> = new EventEmitter();
  private destroy$ = new Subject<void>();
  resetPanel = false;
  showLoader = false;
  trailLimit = 0;
  trailPack = false;
  trailLimitError = false;
  errorResponse: ErrorItem[];

  constructor(
    private productService: ProductDetailsService,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private translation: TranslationService,
    private localStorageService: LocalStorageService
  ) {
    this.getTranslatedValues();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.errorObject?.currentValue) {
      this.displayErrMsg = this.errorObject[0].message;
    }
  }
  getTranslatedValues(): any {
    return combineLatest([
      this.translation.translate('stockOrderPage.addToCart'),
      this.translation.translate('stockOrderPage.addToCartLoading'),
      this.translation.translate('stockOrderPage.addToCartSuccess'),
    ])
      .pipe(
        map(([addToCart, addToCartLoading, addToCartSuccess]) => {
          return {
            addToCart,
            addToCartLoading,
            addToCartSuccess,
          };
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        this.addToCart = data.addToCart;
        this.addToCartTextLoading = data.addToCartLoading;
        this.addToCartTextSuccess = data.addToCartSuccess;
      });
  }

  setUpdateSelectedVarients(): void {
    this.varients.basecurve = this.genarateSelectedVarients(CART_VARIENTS.BASE_CURVE);
    this.varients.diameter = this.genarateSelectedVarients('DIA');
    switch (this.contactLensType) {
      case DIRECT_TO_PRACTICE.PRODUCT_MULTIFOCAL:
        this.varients.powerAdd = this.genarateSelectedVarients(CART_VARIENTS.ADD);
        break;
      case DIRECT_TO_PRACTICE.PRODUCT_COLOR:
        this.varients.color = this.genarateSelectedVarients(CART_VARIENTS.COLOR);
        break;
      case DIRECT_TO_PRACTICE.PRODUCT_TORIC:
        this.varients.cylinder = this.genarateSelectedVarients(CART_VARIENTS.CYLINDER);
        break;
    }
  }

  getTheStorage(): void {
    const productInfo = this.localStorageService.getTheStorage(this.selectedProduct.productCode);
    if (productInfo && !this.editCart) {
      this.selectedProduct = productInfo;
      this.cartType = 'update';
      this.storageFalg = true;
    }
  }
  ngOnInit(): void {}
  ngAfterViewInit(): void {
    this.showLoader = true;
    this.getTheStorage();
    this.productService
      .getProductInfo(this.selectedProduct.productCode, DIRECT_TO_PRACTICE.STOCK_ORDER_PAGE)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.showLoader = false;
        this.variantTree = JSON.parse(data.variantTree);
        this.price = data?.price?.formattedValue;
        this.contactLensType = data.contactLensType;
        let selectedPackSize = '';
        if (this.cartType === 'update') {
          selectedPackSize = this.selectedProduct.product.packSize;
          this.setUpdateSelectedVarients();
          this.addToCartText = this.storageFalg ? this.addToCartText : this.updateTOcartText;
        }
        this.packSize = Utilities.setSelectedPackSize(data, selectedPackSize);
        this.product = data;
        this.productCode = data.code;
        this.maxQuantity = data.maxPurchaseableQty;
        this.trailLimit = data.trialLimit;
        this.cd.detectChanges();
      });
  }
  getSelectedPackSize(): string {
    const selectedPack = this.selectedProduct.entries[0].product.categories.find(
      ({ code }) => code.match('Pack Size') !== null
    );
    return selectedPack.name;
  }

  genarateSelectedVarients(param: string): string {
    return this.selectedProduct?.specifications.find((node) => node.specificationKey === param)?.specificationValue;
  }
  checkTheStorageWithVarient(varientKey, type): void {
    if (
      this.varients[varientKey] !==
      this.selectedProduct?.specifications?.find((node) => node.specificationKey === type)?.specificationValue
    ) {
      if (!this.editCart) {
        this.localStorageService.removeProductFromStorage(this.productCode);
        this.selectedProduct.specifications = [];
        this.selectedProduct.entries = [];
        this.quantityError = false;
        this.displayError = false;
        this.trailLimitError = false;
        this.displayErrMsg = '';
      } else {
        this.clearLensSelectedValues(true);
      }
    }
  }

  baseCurveSelected(value: DropdownOptions): void {
    this.varients.basecurve = value.text;
    this.varients.diameter = null;
    this.DiameterSelector = this.productService.formatDropdown(
      this.productService.generateVarientDropdownoptions(
        this.variantTree.childrenNodes,
        Variants.DIAMETER,
        this.varients,
        false
      )
    );
    this.checkTheStorageWithVarient(Variants.BASECURVE, 'BC');
  }
  DiameterSelected(value: DropdownOptions): void {
    this.varients.diameter = value.text;
    this.getDropdownFromVarients();
    this.clearLensSelectedValues();
    if (DIRECT_TO_PRACTICE.PRODUCT_SPERICAL === this.contactLensType) {
      if (this.varients.basecurve) {
        this.getLensPower(false);
      }
    } else {
      this.LensPowerSelector = this.formatLenspower(
        this.productService.generateVarientDropdownoptions(
          this.variantTree.childrenNodes,
          Variants.LENSPOWER,
          this.varients
        )
      );
      this.getLessMinusPowers();
      this.getLessPlusPowers();
      this.LensPowerSelector = this.productService.sortArray(this.LensPowerSelector);
      if (this.cartType === 'update' && !this.resetPanel) {
        this.getLensPower(true);
      }
    }
    if (this.storageFalg) {
      this.clearLensSelectedValues();
    }
    this.checkTheStorageWithVarient(Variants.DIAMETER, 'DIA');
  }
  getTheLensSelectedValues(): Array<VisionCareAddtocartProductRequest> {
    const lensData = [];
    let changeProduct = false;
    this.selectedProduct.entries.forEach((entry) => {
      if (
        this.getSelectedPackSize() !== this.varients.packSize ||
        entry.specifications.find((specification) => specification.specificationKey === 'BC').specificationValue !==
          this.varients.basecurve ||
        entry.specifications.find((specification) => specification.specificationKey === 'DIA').specificationValue !==
          this.varients.diameter ||
        (this.varients.color &&
          entry.specifications.find((specification) => specification.specificationKey === 'Colour')
            .specificationValue !== this.varients.color) ||
        (this.varients.powerAdd &&
          entry.specifications.find((specification) => specification.specificationKey === 'ADD').specificationValue !==
            this.varients.powerAdd)
      ) {
        changeProduct = true;
      }
    });
    this.selectedProduct.entries.forEach((element) => {
      if (changeProduct) {
        lensData.push({
          quantity: 0,
          orderEntryFlowType: DIRECT_TO_PRACTICE.STOCK_ORDER,
          product: { code: element.product.code },
          entryNumber: element.entryNumber,
        });
      }
    });
    return lensData;
  }

  getLensPower(flag: boolean): void {
    const varientValues = [];
    this.changedLensSelectedValues = [];
    const LensPowerSelector = this.productService.generateVarientDropdownoptions(
      this.variantTree.childrenNodes,
      Variants.LENSPOWER,
      this.varients,
      flag
    );
    LensPowerSelector.forEach((power) => {
      const varient = this.productService.generateVarientCodes(this.variantTree.childrenNodes, {
        ...this.varients,
        lenspower: power,
      });
      if (varient) {
        const varientInfo = {
          text: power,
          value: varient.code,
          quantity: '',
          maxQuantity: varient.maxQuantity,
          error: '',
        } as VarientCode;
        if (this.cartType === 'update') {
          const quantity: any = this.productService.getVarientQuantity(varient.code, this.selectedProduct);
          if (quantity) {
            varientInfo.quantity = quantity.quantity.toString();
            varientInfo.entryNumber = quantity.entryNumber;
          }
          this.positionId = this.selectedProduct.positionId;
          this.validateMaxQuantity(varientInfo);
        }
        varientValues.push(varientInfo);
      }
    });
    this.LensPowerSelector = [...varientValues];
    this.LensPowerSelector = this.productService.sortArray(this.LensPowerSelector);
    this.getLessMinusPowers();
    this.getLessPlusPowers();
    if (!this.displayError) {
      this.totalQuantitySelected = this.productService.getTotalQuantity(this.LensPowerSelector);
    }
    if (this.LensPowerSelector.find((variant) => variant.displayError)) {
      this.lensSelectedValues = [];
      this.totalQuantitySelected = null;
      return;
    }
  }
  colorSelected(value): void {
    this.varients.color = value.text;
    this.clearLensSelectedValues();
    this.getLensPower(true);
    this.checkTheStorageWithVarient(Variants.COLOR, 'Colour');
  }
  addSelected(value): void {
    this.varients.powerAdd = value.text;
    this.clearLensSelectedValues();
    this.getLensPower(true);
    this.checkTheStorageWithVarient(Variants.POWERADD, 'ADD');
  }
  lenscylinderSelected(value): void {
    this.varients.cylinder = value.text;
    if (this.storageFalg) {
      this.getTheStorage();
    }
    this.clearLensSelectedValues();
    this.checkTheStorageWithVarient(Variants.CYLINDER, 'CY');
    this.varients = { ...this.varients };
    this.variantTree = { ...this.variantTree };
  }
  checkforEmit(): void {
    this.lensSelectedValues = [];
    this.totalQuantitySelected = 0;
  }

  showPower(record, index): boolean {
    if (this.LensPowerSelector.length > 40) {
      if (record.text >= 0) {
        if (index < this.powerIndexPlus) {
          return true;
        }
      } else if (record.text < 0) {
        if (index > this.powerIndexMinus) {
          return true;
        }
      }
    } else {
      return true;
    }
  }
  getMoreMinusPowers(): void {
    if (this.LensPowerSelector.length > 40) {
      this.powerIndexMinus = -1;
    }
  }
  getLessMinusPowers(): void {
    this.powerIndexMinus = (this.LensPowerSelector.length - 40) / 2;
  }
  getLessPlusPowers(): void {
    this.powerIndexPlus = 40 + (this.LensPowerSelector.length - 40) / 2;
  }
  getMorePlusPowers(): void {
    if (this.LensPowerSelector.length > 40) {
      this.powerIndexPlus = this.LensPowerSelector.length;
    }
  }

  getQuantityForVarients(quantity): void {
    this.totalQuantitySelected = quantity;
  }
  ValidateValue(updatedLensPower): boolean {
    if (!updatedLensPower.value) {
      return true;
    }
    this.quantityError = false;
    this.validateMaxQuantity(updatedLensPower);
    if (updatedLensPower.displayError) {
      return true;
    }
    this.LensPowerSelector.forEach((power) => {
      if (
        (power.quantity || power.quantity.toString() === '0') &&
        (Number(power.quantity) <= 0 || power.quantity.match(/[^0-9]/g))
      ) {
        this.quantityError = true;
      }
    });

    if (
      (updatedLensPower.entryNumber && updatedLensPower.quantity && updatedLensPower.quantity.match(/[^0-9]/g)) ||
      (!updatedLensPower.entryNumber &&
        (updatedLensPower.quantity || updatedLensPower.quantity.toString() === '0') &&
        Number(updatedLensPower.quantity) <= 0) ||
      updatedLensPower.quantity.match(/[^0-9]/g)
    ) {
      this.quantityError = true;
    }

    return this.quantityError;
  }
  updateQuantityToPower(updatedLensPower: VarientCode): void {
    this.displayError = false;
    this.trailLimitError = false;
    this.displayErrMsg = '';
    this.LensPowerSelector?.forEach((element) => {
      if (element.quantity) {
        this.validateMaxQuantity(element);
      }
    });
    if (!this.editCart) {
      this.localStorageService.setStorage(this.productCode, this.varients, updatedLensPower);
    }

    if (this.ValidateValue(updatedLensPower) || this.LensPowerSelector.find((variant) => variant.displayError)) {
      this.lensSelectedValues = [];
      this.totalQuantitySelected = null;
      return;
    } else {
      let lensVarients;
      if (!this.editCart) {
        lensVarients = this.productService.getVaientsFromStorage(this.productCode);
        this.lensSelectedValues = lensVarients;
      }
    }
    this.lensSelectedValues = [
      ...this.productService.getLensVarientNodeList(updatedLensPower, [...this.lensSelectedValues], this.cartType),
    ];
    if (DIRECT_TO_PRACTICE.PRODUCT_TORIC !== this.contactLensType) {
      this.totalQuantitySelected = this.productService.getTotalQuantity(this.LensPowerSelector);
    }
  }

  getTheVarients(lensData: Array<VisionCareAddtocartProductRequest>): void {
    this.lensSelectedValues = [...lensData];
    this.totalQuantitySelected = this.productService.getTotalQuantity(this.lensSelectedValues);
  }

  clearAll(): void {
    this.totalQuantitySelected = 0;
    if (this.contactLensType !== DIRECT_TO_PRACTICE.PRODUCT_TORIC) {
      this.LensPowerSelector.forEach((element) => {
        const key = 'quantity';
        element[key] = '';
        element.displayError = false;
      });
    } else {
      this.clearQuantity = { ...{ status: true } };
      this.displayError = false;
      this.displayErrMsg = '';
    }
    this.displayErrMsg = '';
    this.trailLimitError = false;
    this.lensSelectedValues = [];
    this.localStorageService.removeProductFromStorage(this.productCode);
  }

  formatLenspower(data: Set<string>): Array<VarientCode> {
    const options = [];
    data.forEach((element) => {
      options.push({ text: element, value: null, quantity: '', error: '' } as VarientCode);
    });
    return options;
  }

  getDropdownFromVarients(): void {
    if (DIRECT_TO_PRACTICE.PRODUCT_MULTIFOCAL === this.contactLensType) {
      this.addSelector = this.productService.formatDropdown(
        this.productService.generateVarientDropdownoptions(
          this.variantTree.childrenNodes,
          Variants.POWERADD,
          this.varients
        ),
        Variants.POWERADD,
        this.cartType === 'update',
        this.varients
      );
    }
    if (DIRECT_TO_PRACTICE.PRODUCT_COLOR === this.contactLensType) {
      this.colorSelector = this.productService.formatDropdown(
        this.productService.generateVarientDropdownoptions(
          this.variantTree.childrenNodes,
          Variants.COLOR,
          this.varients
        ),
        Variants.COLOR,
        this.cartType === 'update',
        this.varients
      );
      this.getLensPower(true);
    }
    if (DIRECT_TO_PRACTICE.PRODUCT_TORIC === this.contactLensType) {
      this.lenscylinderSelector = this.productService.formatDropdown(
        this.productService.generateVarientDropdownoptions(
          this.variantTree.childrenNodes,
          Variants.CYLINDER,
          this.varients
        ),
        Variants.CYLINDER,
        this.cartType === 'update',
        this.varients
      );
      if (this.varients.cylinder) {
        this.lenscylinderSelected({ text: this.varients.cylinder });
      }
    }
  }
  getDefaultDropDowns(): void {
    this.BaseCurveSelector = this.productService.formatDropdown(
      this.productService.generateVarientDropdownoptions(
        this.variantTree.childrenNodes,
        Variants.BASECURVE,
        this.varients
      ),
      Variants.BASECURVE,
      this.cartType === 'update',
      this.varients
    );

    this.DiameterSelector = this.productService.formatDropdown(
      this.productService.generateVarientDropdownoptions(
        this.variantTree.childrenNodes,
        Variants.DIAMETER,
        this.varients
      ),
      Variants.DIAMETER,
      this.cartType === 'update',
      this.varients
    );
    this.getDropdownFromVarients();
    this.LensPowerSelector = this.formatLenspower(
      this.productService.generateVarientDropdownoptions(
        this.variantTree.childrenNodes,
        Variants.LENSPOWER,
        this.varients
      )
    );
  }

  changePacksize(value: string): void {
    this.defaultPackSize = value;
    this.changedLensSelectedValues = [];
    value = value.replace('_', ' ');
    if (this.selectedProduct?.product?.packSize !== value) {
      this.resetPanel = true;
      this.clearVarients();
      this.totalQuantitySelected = 0;
    } else {
      this.resetPanel = false;
      this.setUpdateSelectedVarients();
    }
    this.varients.packSize = value.replace(' ', '_');
    if (this.varients.packSize.toLocaleLowerCase().includes('trial')) {
      this.trailPack = true;
    } else {
      this.trailPack = false;
    }
    this.getDefaultDropDowns();
    this.cd.detectChanges();
  }

  clearVarients(): void {
    this.varients = {
      packSize: null,
      basecurve: null,
      diameter: null,
      color: null,
      powerAdd: null,
      cylinder: null,
      lenspower: null,
      axis: null,
    } as BasicProductSelectedVariants;
  }
  addingtocartLoad(loading): void {
    this.displayError = false;
    if (loading) {
      this.disablePanel = true;
      this.addingtocartLoading = loading;
      if (this.cartType === 'update') {
        this.addToCartText = this.storageFalg ? this.addToCartTextLoading : this.updateToCartLoading;
      } else {
        this.addToCartText = this.addToCartTextLoading;
      }
    } else {
      if (this.cartType === 'update') {
        this.disablePanel = false;
        this.addToCartText = this.storageFalg ? this.addToCartText : this.updateTOcartText;
        this.addingtocartTick = false;
        this.addingtocartLoading = loading;
      } else {
        this.disablePanel = false;
        this.addingtocartLoading = loading;
        this.addToCartText = this.addToCart;
        this.addingtocartTick = this.disablePanel = false;
        // this.clearVarients();
        this.varients.packSize = this.defaultPackSize;
        this.totalQuantitySelected = 0;
        /** no need to clare the values if the error comes */
        // this.changePacksize(this.defaultPackSize);
      }
    }
  }

  clearLensSelectedValues(clearflag: boolean = null): void {
    this.lensSelectedValues = [];
    if (this.cartType === 'update' && !this.displayError && !clearflag) {
      this.lensSelectedValues = this.productService.getVarienntsFormCartObject(
        this.selectedProduct,
        this.storageFalg ? !this.editCart : true,
        this.resetPanel
      );
    } else if (clearflag) {
      this.lensSelectedValues = this.productService.getVarienntsFormCartObject(this.selectedProduct, false);
    }
    if (this.storageFalg && !this.displayError) {
      this.getTheVarients(this.lensSelectedValues);
    }
  }
  addingtocartSuccess(tickValue): void {
    this.addingtocartLoading = false;
    this.totalQuantitySelected = 0;
    if (this.cartType === 'update') {
      this.addToCartText = this.storageFalg ? this.addToCartTextSuccess : this.updateToCartSuccess;
    } else {
      this.addToCartText = this.addToCartTextSuccess;
    }
    this.addingtocartTick = tickValue;
    setTimeout(() => {
      this.addToCartText = this.addToCart;
      this.storageFalg = false;
      this.changedLensSelectedValues = [];
      this.selectedProduct = this.localStorageService.removeProductFromStorage(this.productCode);
      this.addingtocartTick = this.disablePanel = false;
      this.varients.packSize = this.defaultPackSize;
      this.clearVarients();
      this.cartType = '';
      this.lensSelectedValues = [];
      this.changePacksize(this.defaultPackSize);
    }, DIRECT_TO_PRACTICE.TIMEOUTRANGE);
  }
  cartError(): void {
    if (DIRECT_TO_PRACTICE.PRODUCT_TORIC === this.contactLensType) {
      this.errorResponse = [];
    } else {
      this.validateQuantityfields([]);
    }
    this.trailLimitError = true;
  }
  closePopup(): void {
    this.closeHandeller.emit(false);
    document.body.classList.remove('modal-open');
  }

  emitErrorMessage(errorMsg): void {
    const errors = errorMsg.error?.errors;
    if (DIRECT_TO_PRACTICE.PRODUCT_TORIC === this.contactLensType) {
      this.errorResponse = [...errors];
    } else {
      this.validateQuantityfields(errors);
    }
    this.displayErrMsg = errors[0].message;
  }

  setUpdatedData(value): void {
    this.emitUpdatedData.emit(value);
  }
  validateMaxQuantity(varientInfo): void {
    if (
      varientInfo.quantity &&
      varientInfo.maxQuantity &&
      Number(varientInfo.quantity) > Number(varientInfo.maxQuantity)
    ) {
      varientInfo.displayError = true;
      this.displayError = true;
      this.maxQuantity = varientInfo.maxQuantity;
      this.lensSelectedValues = [];
    } else {
      varientInfo.displayError = false;
    }
  }
  validateQuantityfields(errorObj): void {
    if (errorObj && errorObj.length > 0) {
      errorObj?.forEach((errorItem: any) => {
        this.LensPowerSelector?.forEach((element: any) => {
          if (element.quantity && element.value === errorItem.subject) {
            element.displayError = true;
          }
        });
      });
    } else {
      this.LensPowerSelector?.forEach((element) => {
        if (element.quantity) {
          element.displayError = true;
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
