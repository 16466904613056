import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthRedirectStorageService,
  AuthService,
  GlobalMessageService,
  GlobalMessageType,
  PageMetaService,
} from '@spartacus/core';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { LegalAnnouncementService } from 'src/app/services/legal-announcement/legal-announcement.service';
import { GlobalMessage } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-legal-announcement',
  templateUrl: './legal-announcement.component.html',
  styleUrls: ['./legal-announcement.component.scss'],
})
export class LegalAnnouncementComponent implements OnInit, OnDestroy {
  pageTitle: string;
  private destroy$ = new Subject<void>();
  legalContent$: Observable<string>;
  loading: boolean;

  constructor(
    private auth: AuthService,
    private authRedirectStorageService: AuthRedirectStorageService,
    private router: Router,
    private pageMetaService: PageMetaService,
    private legalAnnouncementService: LegalAnnouncementService,
    private globalMessageService: GlobalMessageService
  ) {}

  ngOnInit(): void {
    this.legalAnnouncementService
      .getTemplates()
      .pipe(takeUntil(this.destroy$))
      .subscribe((templates) => {
        if (templates?.length) {
          if (templates.every((template) => template.hasOwnProperty('currentConsent'))) {
            this.router.navigate(['/']);
          } else {
            this.getTemplateText();
          }
        } else {
          // this is the case when legal announcement are not applicable for site
          this.router.navigate(['/']);
        }
      });

    this.pageMetaService
      .getMeta()
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.pageTitle = val.title;
      });
  }

  getTemplateText(): void {
    this.loading = true;
    this.legalContent$ = this.legalAnnouncementService.getTemplates().pipe(
      map((templates) => {
        let content = '';
        templates.forEach((template) => (content += template.description + ' '));
        this.loading = false;
        return content.trim();
      })
    );
  }

  redirectToDashBoard(): void {
    this.authRedirectStorageService.setRedirectUrl('/');
    this.legalAnnouncementService
      .acceptLegalConsent()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          this.router.navigate(['/']);
        },
        (error) => {
          this.globalMessageService.add(error, GlobalMessageType.MSG_TYPE_ERROR, GlobalMessage.TIMEOUT);
        }
      );
  }

  logout(): void {
    this.auth.logout();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
