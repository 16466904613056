import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductDetailsService } from 'src/app/services/alcon-pdp/product-details.service';

@Component({
  selector: 'app-product-detail-tile',
  templateUrl: './product-detail-tile.component.html',
  styleUrls: ['./product-detail-tile.component.scss'],
})
export class ProductDetailTileComponent {
  @Input() product;
  isFavouritePage$: Observable<boolean> = this.activatedRoute.data.pipe(
    map(({ cxRoute = 'undefined' }) => cxRoute !== 'favouritePage')
  );
  price$ = this.productService.packSizePriceChanged$$.pipe(
    map((array) => array.find((prd) => prd.code === this.product.code))
  );

  constructor(private productService: ProductDetailsService, private activatedRoute: ActivatedRoute) {}
}
