import { ChangeDetectorRef, Component, ElementRef, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OccConfig } from '@spartacus/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DashboardPageService } from 'src/app/services/dashboard/dashboard-page.service';
import { LoadItems } from 'src/app/store/Actions/cartItem.action';
import { getCartRootGroups, getTotalUnitCount } from 'src/app/store/Selectors/cartItem.selector';
import { VisionCareEntries } from 'src/app/store/States/cartItem.state';

@Component({
  selector: 'app-alconminicart',
  templateUrl: './alconminicart.component.html',
  styleUrls: ['./alconminicart.component.scss'],
})
export class AlconminicartComponent implements OnInit, OnDestroy {
  title: string;
  entries = [];
  newEntries = [];
  cartvalue = false;
  emptyCartvalue = false;
  moreItemFlag: boolean;
  isCartDisplay = false;
  dtpValuePackCount = 0;
  individaulValuePackCount = 0;
  private destroy$ = new Subject<void>();
  totalItems: Observable<number> = this.store.select(getTotalUnitCount);
  @HostBinding('class') hostClass = 'position-relative';
  @HostListener('document:click', ['$event'])
  hideDropdown(event): void {
    if (!this.eleRef.nativeElement.contains(event.target)) {
      this.isCartDisplay = false;
    }
  }

  constructor(
    private store: Store,
    private cd: ChangeDetectorRef,
    private occConfig: OccConfig,
    private dashboardPageService: DashboardPageService,
    private eleRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(select(getCartRootGroups), takeUntil(this.destroy$))
      .subscribe((rootGroups: Array<VisionCareEntries>) => {
        if (rootGroups.length > 0) {
          this.cartvalue = true;
          this.emptyCartvalue = false;
          this.entries = JSON.parse(JSON.stringify(rootGroups));
          const newEntries = JSON.parse(JSON.stringify(this.entries));
          const createdEntries = [];
          let practiseOrderEntries: VisionCareEntries;
          let dtpEntries;
          newEntries.forEach((val) => {
            switch (val.label) {
              case 'PRACTICE_ORDER': {
                practiseOrderEntries = { ...val };
                const { children } = val;
                const indvidualOrder = children.find((child) => child.label === 'INDIVIDUAL_ORDER');
                indvidualOrder.children = !!indvidualOrder.children ? indvidualOrder.children : [{ entries: [] }];
                const [{ entries: vpEntries = [] }] = indvidualOrder.children;
                if (vpEntries.length > 0) {
                  vpEntries[0].showLabel = true;
                }
                practiseOrderEntries.children = [...practiseOrderEntries.children, ...vpEntries];
                practiseOrderEntries.children = practiseOrderEntries.children.filter((child) => {
                  const flg = child.label === 'INDIVIDUAL_ORDER' && child.entries === undefined;
                  return !flg;
                });
                this.individaulValuePackCount = vpEntries.length;
                createdEntries.push(practiseOrderEntries);
                break;
              }
              case 'DIRECT_TO_PATIENT': {
                const { groupItemCount, groupNumber, label } = val;
                dtpEntries = {
                  groupItemCount,
                  groupNumber,
                  label,
                };
                const { entries = [], children = [{ entries: [] }] } = val;
                const [{ entries: DTPvpEntries = [] }] = children;
                if (DTPvpEntries.length > 0) {
                  DTPvpEntries[0].showLabel = true;
                }
                dtpEntries.children = [...entries, ...DTPvpEntries];
                this.dtpValuePackCount = DTPvpEntries.length;
                createdEntries.push(dtpEntries);
                break;
              }
            }
          });
          this.newEntries = [...createdEntries];
          this.cd.detectChanges();
        } else {
          this.cartvalue = false;
          this.emptyCartvalue = true;
          this.cd.detectChanges();
        }
      });
    this.store.dispatch(LoadItems());
  }
  getImageUrl(imageUrl): string {
    return `${this.occConfig.backend.occ.baseUrl}/${imageUrl}`;
  }

  newOrder(): void {
    this.dashboardPageService.getNewOrderClick();
  }
  showCart(event: any): void {
    event.stopPropagation();
    this.isCartDisplay = !this.isCartDisplay;
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
