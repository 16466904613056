import { Component, Input, OnInit } from '@angular/core';
import { CART_GROUP } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-mini-cart-dtp-item-list',
  templateUrl: './mini-cart-dtp-item-list.component.html',
  styleUrls: ['./mini-cart-dtp-item-list.component.scss'],
})
export class MiniCartDtpItemListComponent implements OnInit {
  @Input() dtpEntryData;
  isDVP = false;
  dtpEntryCount: number; // total enties count
  displayCount: number; // no of entries on UI

  constructor() {}

  ngOnInit(): void {
    const data = JSON.parse(JSON.stringify(this.dtpEntryData));
    this.getDTPData(data);
  }

  /**
   * If data has regular items only last 3 items will be pushed to entries
   * If user has value packs only last 1 value pack will be pushed to entrie
   * If user has combination of regular items and value pack - last value pack is pushed to entries
   * @param data holds dtpentries
   */
  getDTPData(data): void {
    this.dtpEntryCount = data.entries.length;
    const dtpVPEntries = data.entries.find((element) => element.orderEntryFlowType === CART_GROUP.DTP_VALUE_PACK);

    if (dtpVPEntries) {
      data.entries = [{ ...dtpVPEntries }];
      this.displayCount = 1;
      this.isDVP = true;
    } else {
      this.isDVP = false;
      data.entries = data.entries.slice(0, 3);
      this.displayCount = data.entries.length;
    }
    this.dtpEntryData = data;
  }
}
