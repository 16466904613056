import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-switch-toggle',
  templateUrl: './switch-toggle.component.html',
  styleUrls: ['./switch-toggle.component.scss'],
})
export class SwitchToggleComponent {
  constructor() {}
  @Input() options;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  switchToggle(option): void {
    this.options.forEach((switchOption) => {
      switchOption.isSelected = false;
    });
    option.isSelected = true;

    this.valueChange.emit(option);
  }
}
