import { Component, Input } from '@angular/core';
import { CmsResponsiveBannerComponentMedia } from '@spartacus/core';

@Component({
  selector: 'app-responsive-media',
  templateUrl: './responsive-media.component.html',
})
export class ResponsiveMediaComponent {
  @Input() media: CmsResponsiveBannerComponentMedia;
}
