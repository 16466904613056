import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  EventService,
  GlobalMessageService,
  GlobalMessageType,
  OccEndpointsService,
  UserIdService,
} from '@spartacus/core';
import { User, UserAccountChangedEvent } from '@spartacus/user/account/root';
import { UpdateProfileComponentService } from '@spartacus/user/profile/components';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { CurrentUserIdService } from '../../shared-modules/user-id-provider/service/current-user-id.service';

@Injectable({
  providedIn: 'root',
})
export class UpdateUserProfileService extends UpdateProfileComponentService {
  constructor(
    userProfile: UserProfileFacade,
    globalMessageService: GlobalMessageService,
    private userIdService: UserIdService,
    private http: HttpClient,
    private occEndPoints: OccEndpointsService,
    private eventService: EventService,
    private currentUserService: CurrentUserIdService
  ) {
    super(userProfile, globalMessageService);
  }
  onSuccess(): void {
    this.busy$.next(false);
    this.form.reset();
  }
  getUserDetails(): Observable<User> {
    return this.userProfile.get();
  }
  notificationSuccess(message: string): void {
    this.globalMessageService.add(message, GlobalMessageType.MSG_TYPE_CONFIRMATION, 10000);
  }

  notificationError(message: string): void {
    this.globalMessageService.add(message, GlobalMessageType.MSG_TYPE_ERROR, 10000);
  }

  updateAutoOrderStatus(isAutoOrder: boolean, timeStamp = ''): Observable<any> {
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap((userId) => {
        let url = this.occEndPoints.buildUrl(`/users/${userId}/updateaccount?enableAutoOrdering=${isAutoOrder}`);
        if (timeStamp !== '') {
          url = url + `&autoOrderingTimeGroup=${timeStamp}`;
        }
        return this.http.patch<any>(url, '');
      })
    );
  }

  updateCustomerEmailPreferences(active: boolean): Observable<User> {
    const userId = this.currentUserService.getUserId();
    const url = this.occEndPoints.buildUrl(`/users/${userId}/updateCustomerEmailPreferences`);
    let params = new HttpParams();

    params = params.append('emailFlagsEnabled', active);

    return this.http.patch<User>(url, {}, { params });
  }

  /**
   * updates user current API with updated account data
   * @param user //  response
   */
  updateUserCurrentData(user: User): void {
    this.eventService.dispatch({ user }, UserAccountChangedEvent);
  }
}
