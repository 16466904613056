import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserIdService {
  private userId?: string;
  setUserId(userId): void {
    this.userId = userId;
  }
  getUserId(): string {
    return this.userId;
  }
}
