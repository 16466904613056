import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfigModule, NotAuthGuard } from '@spartacus/core';
import { CmsPageGuard, LogoutGuard, PageComponentModule, PageSlotModule } from '@spartacus/storefront';
import { AlconCmsComponentsModule } from 'src/app/cms-components/alcon-cms-components.module';
import { HasBaseSiteGuard } from 'src/app/shared/guards/has-base-site.guard';
import { ClearStoreGuard } from '../login-logout/guards/clear-store/clear-store.guard';
import { OktaLogoutGaurd } from '../login-logout/guards/okta-logout-gaurd/okta-logout-gaurd';
import { AxonOktaLoginComponent } from './axon-okta-login/axon-okta-login.component';
import { BaseLoginComponent } from './base-login/base-login.component';

@NgModule({
  declarations: [BaseLoginComponent, AxonOktaLoginComponent],
  imports: [
    CommonModule,
    PageSlotModule,
    RouterModule.forChild([
      {
        path: null,
        component: BaseLoginComponent,
        canActivate: [HasBaseSiteGuard, NotAuthGuard, CmsPageGuard],
        data: {
          cxRoute: 'login',
          pageLabel: 'homepage',
          Type: 'ContentPage',
          showGlobalMessage: true,
        },
      },
      {
        path: null,
        component: BaseLoginComponent,
        canActivate: [ClearStoreGuard, OktaLogoutGaurd],
        data: {
          cxRoute: 'logout',
        },
      },
      {
        path: null,
        component: BaseLoginComponent,
        canActivate: [LogoutGuard],
        data: {
          cxRoute: 'logoutAll',
        },
      },
    ]),
    ConfigModule.withConfig({
      routing: {
        protected: true,
        routes: {
          login: {
            paths: ['login'],
          },
          logout: {
            paths: ['logout-alcon'],
          },
          logoutAll: {
            paths: ['clear-session'],
          },
        },
      },
    }),
    PageComponentModule,
    AlconCmsComponentsModule,
  ],
  exports: [BaseLoginComponent, AxonOktaLoginComponent],
})
export class LoginLogoutModule {}
