import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OccEndpointsService } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { Counties, Countries } from 'src/app/shared/model/patient.model';
import { setCountries } from 'src/app/store/Actions/countyCountry.action';
import { getCountries } from 'src/app/store/Selectors/countyCountry.selector';

@Injectable({
  providedIn: 'root',
})
export class CountryCountyService {
  constructor(private httpClient: HttpClient, private occEndPoints: OccEndpointsService, private store: Store) {}

  getCountries(): Observable<Countries> {
    return this.store.select(getCountries).pipe(
      take(1),
      switchMap((data) => {
        return data ? of({ countries: data }) : this.fetchCountries();
      })
    );
  }

  fetchCountries(): Observable<Countries> {
    const url = this.occEndPoints.buildUrl(`countries`);
    const options = {
      params: new HttpParams({
        fromString: 'type=SHIPPING',
      }),
    };
    return this.httpClient
      .get<Countries>(url, options)
      .pipe(tap((countries) => this.store.dispatch(setCountries(countries))));
  }

  getCounties(countryIsocode: string): Observable<Counties> {
    const url = this.occEndPoints.buildUrl('regions', { urlParams: { isoCode: countryIsocode } });
    return this.httpClient.get<Counties>(url);
  }
}
