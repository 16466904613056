<div class="reOrder-validation">
  <div class="reOrder-validation__close">
    <cx-icon type="Close" class="close-icon cx-icon close" (click)="closeModal()"> </cx-icon>
  </div>
  <h2 class="reOrder-validation__header">
    {{ 'reOrderValidation.heading' | cxTranslate }}
  </h2>
  <div *ngFor="let errorType of reOrderResponse?.validationErrors?.errors">
    <p class="reOrder-validation__error reOrder-validation__error--font">{{ errorType.message }}</p>
    <p
      *ngIf="
        errorType.reason === reOrderValidationReason.itemDiscontinued ||
        errorType.reason === reOrderValidationReason.alconBusinessException
      "
      class="reOrder-validation__error"
    >
      {{ 'reOrderValidation.itemDiscontinued' | cxTranslate }}
    </p>
    <p *ngIf="errorType.reason === reOrderValidationReason.valuePackDiscontinued" class="reOrder-validation__error">
      {{ 'reOrderValidation.vpSKUDiscontinued' | cxTranslate }}
    </p>
    <p *ngIf="errorType.reason === reOrderValidationReason.maxLimit" class="reOrder-validation__error">
      {{ 'reOrderValidation.maxLimit' | cxTranslate }}
    </p>
    <p *ngIf="errorType.reason === reOrderValidationReason.DTPMaxLimit" class="reOrder-validation__error">
      {{ 'reOrderValidation.maxLimit' | cxTranslate }}
    </p>
    <p *ngIf="errorType.reason === reOrderValidationReason.patientDeleted" class="reOrder-validation__error">
      {{ 'reOrderValidation.patientDeleted' | cxTranslate }}
    </p>
    <div class="reOrder-validation__errorblock">
      <ng-container *ngFor="let entry of errorType.entries">
        <ng-container
          *ngIf="entry.orderEntryFlowType === 'VALUE_PACK' || entry.orderEntryFlowType === 'DTP_VALUE_PACK'"
        >
          <ng-container *ngFor="let valuePackentry of entry.entries; let valuepkIndex = index">
            <div class="d-flex justify-content-between mt-30">
              <app-cart-product-information [cartEntry]="valuePackentry" [showPatientReference]="false">
              </app-cart-product-information>
              <div class="w-25">
                <p *ngIf="valuepkIndex === 0" class="reOrder-validation__quantity">
                  {{ 'reOrderValidation.valuePackQauntity' | cxTranslate }}
                </p>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <div
          *ngIf="entry.orderEntryFlowType !== 'VALUE_PACK' && entry.orderEntryFlowType !== 'DTP_VALUE_PACK'"
          class="d-flex justify-content-between mt-30"
        >
          <app-cart-product-information [cartEntry]="entry" [showPatientReference]="false">
          </app-cart-product-information>
          <div class="w-25">
            <p class="reOrder-validation__quantity">{{ entry.quantity }} items</p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="d-flex justify-content-end mt-4">
    <button class="reOrder-validation__button" (click)="closeModal()">
      {{ 'reOrderValidation.ok' | cxTranslate }}
    </button>
  </div>
</div>
