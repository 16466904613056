import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { INVOICES_TYPE } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-invoices-list-filter',
  templateUrl: './invoices-list-filter.component.html',
  styleUrls: ['./invoices-list-filter.component.scss'],
})
export class InvoicesListFilterComponent implements OnInit {
  invoicesFilterList = [
    {
      label: 'ALL',
      name: INVOICES_TYPE.ALL,
    },
    {
      label: 'PAID',
      name: INVOICES_TYPE.PAID,
    },
    {
      label: 'UNPAID',
      name: INVOICES_TYPE.UNPAID,
    },
    {
      label: 'OVERDUE',
      name: INVOICES_TYPE.OVERDUE,
    },
  ];
  showFilter = false;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {}
}
