import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoutesService, GlobalMessageService } from '@spartacus/core';
import { Subject } from 'rxjs';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-alcon-global-message',
  templateUrl: './alcon-global-message.component.html',
  styleUrls: ['./alcon-global-message.component.scss'],
})
export class AlconGlobalMessageComponent implements OnInit, OnDestroy {
  constructor(
    private globalMessageService: GlobalMessageService,
    private activatedRoutesService: ActivatedRoutesService
  ) {}
  message: string;
  errormessage: string;
  private destroy$ = new Subject<void>();
  ngOnInit(): void {
    this.globalMessageService
      .get()
      .pipe(
        withLatestFrom(this.activatedRoutesService.routes$),
        filter(([message, routeData]) => {
          const route = [...routeData].pop();
          const { showGlobalMessage = true } = route.data;
          return showGlobalMessage;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(([message]) => {
        this.message = message.hasOwnProperty('[GlobalMessage] Confirmation')
          ? message['[GlobalMessage] Confirmation'][0]?.raw
          : '';
        this.errormessage = message.hasOwnProperty('[GlobalMessage] Error')
          ? message['[GlobalMessage] Error'][0]?.raw
          : '';
      });
  }
  closeMessage(): void {
    this.message = '';
    this.errormessage = '';
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
