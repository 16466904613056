import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlconUserPermissionService } from '../shared-modules/alcon-user-permission-provider/service/alcon-user-permission.service';
import { SaveValuePackCart } from '../store/Actions/cartItem.action';
import { getCartItems } from '../store/Selectors/cartItem.selector';
import { VisionCareAddToCart, VisionCareCartItems } from '../store/States/cartItem.state';

@Directive({
  selector: '[appAddToCartValuePack]',
})
export class AddToCartValuePackDirective implements OnInit, OnChanges, OnDestroy {
  @HostBinding('disabled') disable = true;
  @Input() product: any;
  @Input() maxQuantity: number;
  emitsuccess: boolean;
  errorMessage: string;
  @Input() set disableButton(value) {
    this.disable = value;
  }
  @Output() addingtocartLoad = new EventEmitter();
  @Output() addingtocartSuccess = new EventEmitter();
  @Output() cartError = new EventEmitter();
  @Output() emitErrorMessage = new EventEmitter();
  private destroy$ = new Subject<void>();

  constructor(private store: Store, private alconUserPermissionService: AlconUserPermissionService) {
    store.pipe(select(getCartItems), takeUntil(this.destroy$)).subscribe((data: VisionCareCartItems) => {
      if (data && !data.cartError) {
        if (!this.emitsuccess) {
          this.disable = false;
          this.addingtocartSuccess.emit(true);
        }
      } else {
        this.errorMessage = data.cartError;
        if (!this.emitsuccess) {
          this.disable = true;
          this.emitErrorMessage.emit(this.errorMessage);
          this.addingtocartLoad.emit(false);
        }
      }
      this.emitsuccess = true;
    });
  }
  public ngOnInit(): void {
    this.enableAddToCart();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.product) {
      this.product = changes.product.currentValue;
      this.enableAddToCart();
    }
  }
  @HostListener('click', ['$event']) addToCart(e): void {
    e.preventDefault();
    this.emitsuccess = false;
    if (this.validate()) {
      this.cartError.emit();
      return;
    }
    this.disable = true;
    this.addingtocartLoad.emit(true);
    const VCAddtocartItems: VisionCareAddToCart = {
      entries: this.product,
      orderFlowType: this.product[0]?.orderEntryFlowType,
      trialProduct: false,
    };
    this.store.dispatch(SaveValuePackCart({ VCAddtocartItems }));
  }
  private enableAddToCart(): void {
    this.disable = this.alconUserPermissionService.getAddToCartEnabled()
      ? this.product?.length === 0 || this.product?.length < 3
      : true;
    if (this.product?.length === 3) {
      this.disable = this.alconUserPermissionService.getAddToCartEnabled() ? this.validate() : true;
    }
  }
  private validate(): boolean {
    let ValuepackFlag = false;
    this.product.forEach((item) => {
      if (!item.valuePackAttributes.valuePackId) {
        ValuepackFlag = true;
        return;
      }
    });
    return ValuepackFlag;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
