import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConverterService, OccEndpointsService, OccProductAdapter, OccRequestsOptimizerService } from '@spartacus/core';
import { CurrentUserIdService } from 'src/app/shared-modules/user-id-provider/service/current-user-id.service';
@Injectable()
export class VcProductAdapter extends OccProductAdapter {
  constructor(
    http: HttpClient,
    occEndpoints: OccEndpointsService,
    converter: ConverterService,
    requestsOptimizer: OccRequestsOptimizerService,
    private currentUID: CurrentUserIdService
  ) {
    super(http, occEndpoints, converter, requestsOptimizer);
  }
  protected getEndpoint(code: string, scope?: string): string {
    const userId = this.currentUID.getUserId();
    return this.occEndpoints.buildUrl('product', {
      urlParams: { productCode: code, userId },
      scope,
    });
  }
}
