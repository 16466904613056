import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { LOCALSTORAGE_KEY } from 'src/app/shared/constants/constants';
import { ClearB2BUnitStore } from 'src/app/store/Actions/b2bUnits.action';
import { ClearStore } from 'src/app/store/Actions/cartItem.action';
import { ClearPatientStore } from 'src/app/store/Actions/patient.action';

@Injectable({
  providedIn: 'root',
})
export class ClearStoreGuard implements CanActivate {
  constructor(private store: Store) {}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    localStorage.removeItem(LOCALSTORAGE_KEY.SELECTED_SOLD_TO);
    localStorage.removeItem(LOCALSTORAGE_KEY.SELECTED_SOLD_TO_COUNT);
    localStorage.removeItem(LOCALSTORAGE_KEY.SHIP_TO_COUNT);
    localStorage.removeItem(LOCALSTORAGE_KEY.SELECTED_SHIP_TO);
    this.store.dispatch(ClearStore());
    this.store.dispatch(ClearPatientStore());
    this.store.dispatch(ClearB2BUnitStore());
    return true;
  }
}
