import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccountSelectorService } from '../../services/account-selector.service';

@Component({
  selector: 'app-ship-to-selector',
  templateUrl: './ship-to-selector.component.html',
  styleUrls: ['./ship-to-selector.component.scss'],
})
export class ShipToSelectorComponent implements OnDestroy, OnInit {
  searchAddress: string;
  disableSearch = false;
  @Input() shipTOAddresses;
  @Output() redirect = new EventEmitter();
  @Output() redirectOnSoldTo = new EventEmitter();
  shipToSubscription: Subscription;
  shipToDetails: any = [];
  isMultipleSoldTo: boolean;
  @Input() ifShipToSelector;

  constructor(private accountSelectorService: AccountSelectorService) {}

  ngOnInit(): void {
    if (this.shipTOAddresses === undefined) {
      this.shipToSubscription = this.accountSelectorService.getShiptoAddresses().subscribe((shipToAddressList) => {
        this.shipTOAddresses = shipToAddressList;
        this.shipToDetails = this.shipTOAddresses.shiptoList;
      });
    } else {
      this.shipToDetails = this.shipTOAddresses.shiptoList;
    }

    const isMultipleSoldTo = this.accountSelectorService.getLocalStorageItem();
    this.isMultipleSoldTo = isMultipleSoldTo.selectedSoldToCount === 'true';
  }

  filterAddress(searchedAddressValue): void {
    if (searchedAddressValue?.length >= 3) {
      searchedAddressValue = searchedAddressValue.toLowerCase();
      this.shipToDetails = this.shipTOAddresses.shiptoList.filter((element) => {
        return (
          element?.sapCustomerId.match(searchedAddressValue) ||
          element?.name?.toLowerCase().match(searchedAddressValue) ||
          element?.line1?.toLowerCase().match(searchedAddressValue) ||
          element?.line2?.toLowerCase().match(searchedAddressValue) ||
          element?.town?.toLowerCase().match(searchedAddressValue) ||
          element?.appartment?.toLowerCase().match(searchedAddressValue) ||
          element?.building?.toLowerCase().match(searchedAddressValue) ||
          element?.postalCode?.toLowerCase().match(searchedAddressValue) ||
          element?.region?.name?.toLowerCase().match(searchedAddressValue) ||
          element?.country?.name?.toLowerCase().match(searchedAddressValue)
        );
      });
    } else {
      this.shipToDetails = this.shipTOAddresses.shiptoList;
    }
  }
  setUnitData(sapCustomerId): void {
    if (sapCustomerId) {
      this.shipToSubscription = this.accountSelectorService.setShipToAddress(sapCustomerId).subscribe((data) => {
        if (data) {
          this.accountSelectorService.setLocalStorageShipTo(sapCustomerId);
          this.accountSelectorService.setLocalStorageShipToCount(true);
          this.redirect.emit(true);
        }
      });
    }
  }

  navigateToSoldTo(): void {
    this.redirectOnSoldTo.emit(this.isMultipleSoldTo);
  }
  resetSearch(): void {
    this.searchAddress = '';
    this.disableSearch = false;
    this.shipToDetails = this.shipTOAddresses.shiptoList;
  }

  ngOnDestroy(): void {
    if (this.shipToSubscription) {
      this.shipToSubscription.unsubscribe();
    }
  }
}
