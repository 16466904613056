import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ModalOptions } from '../../../shared/model/common.mode';
@Component({
  selector: 'app-modal',
  templateUrl: 'modal.component.html',
  styleUrls: ['modal.component.scss'],
})
export class ModalComponent implements OnChanges {
  pdp = false;
  @Input() modalOptions = {
    open: false,
    modalTitle: '',
  } as ModalOptions;
  @Input() showBigPopup;
  @Output() closeHandeller = new EventEmitter();
  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.modalOptions.currentValue) {
      this.modalOptions = changes.modalOptions.currentValue;
    }
  }

  closePopup(): void {
    this.modalOptions.open = false;
    this.closeHandeller.emit(this.modalOptions.open);
    document.body.classList.remove('modal-open');
  }
}
