import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Specifications } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-cart-item-specifications',
  templateUrl: './cart-item-specifications.component.html',
  styleUrls: ['./cart-item-specifications.component.scss'],
})
export class CartItemSpecificationsComponent implements OnInit, OnChanges {
  constructor() {}
  specifications = Specifications;
  @Input() cartEntry: any;
  @Input() showQuantity?: boolean;
  specificationsData = [];
  ngOnInit(): void {
    this.getSpecifications();
  }

  private getSpecifications(): void {
    this.specificationsData = [];
    this.specifications.forEach((value) => {
      const specificationsArray = { key: '', value: '' };
      const specificationValue = this.cartEntry?.specifications?.find(
        (specifications) => specifications?.specificationKey === value
      );
      if (specificationValue) {
        specificationsArray.key = specificationValue?.specificationKey;
        specificationsArray.value = specificationValue?.specificationValue;
        this.specificationsData.push(specificationsArray);
      }
    });
  }

  ngOnChanges(value: SimpleChanges): void {
    this.cartEntry = { ...value.cartEntry.currentValue };
    this.getSpecifications();
  }
}
