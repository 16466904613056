import { createAction, props } from '@ngrx/store';

export enum FavouriteCount {
  LoadFavourite = '[Favourite Count] get favourites',
  SetFavourite = '[Favourite Count] set initial favouritescount',
  IncrementCount = '[Favourite Count] Increment Count',
  DecrementCount = '[Favourite Count] Decrement Count',
}

export const LoadAllFavourite = createAction(FavouriteCount.LoadFavourite);
export const SetFavouriteCount = createAction(FavouriteCount.SetFavourite, props<{ count: number }>());
export const IncrementCount = createAction(FavouriteCount.IncrementCount);
export const DecrementCount = createAction(FavouriteCount.DecrementCount);
