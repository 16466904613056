<div class="dropdown__container">
  <div
    class="dropdown-header"
    (click)="showDropDown()"
    [ngClass]="{
      'dropdown-header__disabled': optionsDisabled || disable,
      'dropdown-header__valid-icon': selectedStatusSuccess && !disable
    }"
  >
    {{ selectText }}
    <cx-icon
      class="dropdown__icon"
      [ngClass]="{
        'd-none': optionsDisabled || disable,
        'dropdown__icon--rotate': isDropdownOpen && defaultConfig.iconReference === 'DropdownExpand',
        'dropdown__icon--change-pos': selectedStatusSuccess
      }"
      [type]="defaultConfig.iconReference"
    ></cx-icon>
  </div>

  <div class="dropdown-options" [ngClass]="{ 'd-none': !isDropdownOpen }" *ngIf="!optionsDisabled">
    <input
      type="text"
      *ngIf="defaultConfig?.typeAheadConfig.typeAhead"
      #typeAhead
      [(ngModel)]="inputText"
      (ngModelChange)="filterText()"
      maxlength="30"
      class="dropdown-input"
    />
    <h4 class="dropdown-options__header" *ngIf="defaultConfig.showOptionHeader">{{ selectText }}</h4>
    <ul #dropdownOptionContainer class="dropdown-options__content" *ngIf="filteredOptions.length">
      <li
        *ngFor="let option of filteredOptions"
        (click)="select(option)"
        class="dropdown-options__item"
        [ngClass]="{ 'dropdown-options__item--selected': option.selected }"
      >
        <img *ngIf="option?.image" [src]="option?.image" class="dropdown-options__image" />
        <h4 class="dropdown-options__header-option" *ngIf="option.title">
          {{ option.title }}
        </h4>
        <span class="dropdown-options__text">{{ option.text }}</span>
      </li>
    </ul>
    <p
      class="dropdown__no-result"
      *ngIf="inputText?.length >= defaultConfig.typeAheadConfig.typeAheadLength && filteredOptions.length === 0"
    >
      {{ defaultConfig.noDataText }}
      <ng-content></ng-content>
    </p>

    <ng-container *ngIf="defaultConfig?.buttons?.length">
      <button
        (click)="buttonAction(button.buttonId)"
        *ngFor="let button of defaultConfig?.buttons"
        class="dropdown__add--option"
      >
        <cx-icon [type]="button.iconRef"> </cx-icon>
        <span class="dropdown__add--option--text">{{ button.name }}</span>
      </button>
    </ng-container>
  </div>
</div>
