import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FacetValue } from '@spartacus/core';
import { FacetService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { ProductListService } from 'src/app/services/product-list-page/product-list.service';

@Component({
  selector: 'app-facet-link',
  templateUrl: './facet-link.component.html',
  styleUrls: ['./facet-link.component.scss'],
})
export class FacetLinkComponent implements OnInit {
  @Input() translate = false;
  @Input() item?: FacetValue;
  @Input() checkBox = false;
  @Input() checkBoxId = 'facetId';
  @Input() individualOrderDD = false;
  @Output() checkBoxChanged = new EventEmitter<{ [key: string]: boolean }>();
  categoryCode = '';
  facetvalue = false;
  constructor(private facetService: FacetService, private productListService: ProductListService) {}

  ngOnInit(): void {
    this.facetvalue = this.item?.selected;
  }
  getLinkParams(): { [key: string]: string } {
    const params = this.facetService.getLinkParams(this.item?.query?.query?.value);
    return params;
  }
  getRouterLink(): Observable<any[]> {
    return this.productListService.getFacetLink();
  }
  checkBoxChangeHandler(): void {
    const object: { [key: string]: boolean } = {};
    object[this.item.name] = this.facetvalue;
    this.checkBoxChanged.emit(object);
  }
}
