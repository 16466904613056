<ng-container *ngIf="isAutoOrder != undefined">
  <section class="autoOrderingContainer">
    <div class="row autoOrderingContainer__heading">
      <h6 class="col-6 autoOrderingContainer__heading__text">{{ 'autoOrderingTranslations.heading' | cxTranslate }}</h6>
      <app-toggle-button [toggleConfig]="toggleConfig" (toggled)="onToggleChange($event)"> </app-toggle-button>
    </div>
    <p class="autoOrderingContainer__autoOrderingText">
      <cx-page-slot position="AccountDetailsAutoOrderPosition"></cx-page-slot>
    </p>
  </section>
  <section class="autoOrderingTimePicker" *ngIf="isAutoOrder">
    <span class="autoOrderingTimePicker__text">
      {{
        isEditMode
          ? ('autoOrderingTranslations.timeSet' | cxTranslate)
          : ('autoOrderingTranslations.setTime' | cxTranslate)
      }}
    </span>
    <app-timepicker
      [meridian]="meridian"
      [time]="time"
      (emitMeridianChanged)="meridianChanged($event)"
      (emitTimeError)="setTimeError($event)"
      [resetError]="resetError"
      [disable]="isEditMode"
    >
    </app-timepicker>
    <span class="autoOrderingTimePicker__text" *ngIf="isEditMode">
      <cx-icon type="EditBlue" alt="editTime" (click)="onEdit()"
        ><span class="autoOrderingTimePicker__iconEditStyles autoOrderingTimePicker__spacing">{{
          'patientProfilePage.edit' | cxTranslate
        }}</span>
      </cx-icon>
    </span>
    <button
      *ngIf="!isEditMode"
      type="button"
      class="btn btn-link shadow-none autoOrderingTimePicker__btnLinks ml-23 p-0"
      (click)="onSaveOrCancel('Save')"
      [disabled]="timeError"
    >
      {{ 'autoOrderingTranslations.save' | cxTranslate }}
    </button>
    <button
      *ngIf="!isEditMode"
      type="button"
      class="btn btn-link shadow-none autoOrderingTimePicker__btnLinks ml-3 p-0"
      (click)="onSaveOrCancel()"
    >
      {{ 'autoOrderingTranslations.cancel' | cxTranslate }}
    </button>
    <p class="timeError" *ngIf="timeError">{{ 'autoOrderingTranslations.timeErrorMsg' | cxTranslate }}</p>
  </section>
</ng-container>
