<div class="patient-ordersContainer mb-30 pt-6">
  <div class="orders__filter-row mb-5 pt-4">
    <div class="orders__date-filter">
      <label class="orders__date-filter-label">{{ 'orderHistoryPage.dateRange' | cxTranslate }}</label>
      <input
        class="orders__date-filter-input"
        type="text"
        [placeholder]="'orderHistoryPage.selectDate' | cxTranslate"
        [(ngModel)]="selectedPeriod"
        readonly
      />
      <cx-icon
        class="orders__calender-icon"
        appCalendar
        [configuration]="calenderConfigration"
        (dateSelected)="getSelectedDate($event)"
        type="Calender"
      ></cx-icon>
    </div>
    <div class="orders__button-box">
      <button class="btn btn-primary button_style button_style__search text-uppercase" (click)="getPatientOrderList()">
        {{ 'orderHistoryPage.searchButton' | cxTranslate }}
      </button>
    </div>
  </div>
  <div class="patient-ordersContainer__list" *ngIf="orderCount || scheduleOrderCount">
    <ul
      ngbNav
      #nav="ngbNav"
      class="nav-tabs nav-pills nav-justified"
      [(activeId)]="activeIndex"
      (navChange)="onNavChange($event)"
    >
      <li [ngbNavItem]="1" [disabled]="!orderCount">
        <a ngbNavLink>{{ 'patientOrderHistory.io' | cxTranslate }}({{ orderCount }})</a>
        <ng-template ngbNavContent>
          <app-generic-order-list
            [componentData]="orderList.slice(0, pagination)"
            [listType]="'Individual'"
            [patientUID]="patientUID"
          ></app-generic-order-list>
        </ng-template>
      </li>
      <li [ngbNavItem]="2" [disabled]="!scheduleOrderCount" *ngIf="subscriptionsVisibility">
        <a ngbNavLink>{{ 'patientOrderHistory.subscription' | cxTranslate }} ({{ scheduleOrderCount }})</a>
        <ng-template ngbNavContent>
          <app-generic-order-list
            [componentData]="scheduledOrderList.slice(0, pagination)"
            [listType]="'Scheduled'"
            [patientUID]="patientUID"
            (statusChanged)="onStatusChange($event)"
            (refreshList)="getPatientOrderList(true)"
          ></app-generic-order-list>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div>
  </div>
  <p class="mb-0" *ngIf="NoOrders">{{ 'patientOrderHistory.nof' | cxTranslate }}</p>
</div>
<div *ngIf="count > this.pagination">
  <button (click)="loadPagination()" class="p-2 col-4" class="btn button_style">
    <span>{{ 'orderHistoryPage.showMore' | cxTranslate }} {{ count }} ORDERS</span>
    <span class="float-right">
      <cx-icon class="dropdown__icon" type="DropdownExpand"></cx-icon>
    </span>
  </button>
</div>
