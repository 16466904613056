import { Component, ComponentFactoryResolver, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { CmsService, GlobalMessageService, GlobalMessageType, SemanticPathService } from '@spartacus/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReturnFormService } from 'src/app/services/returnform/return-form.service';
import { GlobalMessage } from 'src/app/shared/constants/constants';
import { DropdownOptions } from 'src/app/shared/model/common.mode';
import { RetrunProductData } from 'src/app/shared/model/return-product.model';
import { ReturnFormProductConfiguratorComponent } from '../return-form-product-configurator/return-form-product-configurator.component';

@Component({
  selector: 'app-return-form-create-page',
  templateUrl: './return-form-create-page.component.html',
  styleUrls: ['./return-form-create-page.component.scss'],
})
export class ReturnFormCreatePageComponent implements OnDestroy {
  productsArray: Array<RetrunProductData> = [];
  returnFormImgContent = this.cmsService.getComponentData('ReturnformPageImageComponent');
  products$: Observable<DropdownOptions[]> = this.returnFormService.getProducts();
  @ViewChild('productconfiguratortemplate', { read: ViewContainerRef, static: false })
  productconfiguratortemplate: ViewContainerRef;
  isAccordionOpen = true;
  email: string;
  savedProducts = [];
  private destroy$ = new Subject<void>();
  constructor(
    private cmsService: CmsService,
    private returnFormService: ReturnFormService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private globalMessageService: GlobalMessageService,
    private route: Router,
    private semantiPathService: SemanticPathService
  ) {}
  setSelectedProductDetails(product): void {
    const returnPanelId = new Date().getTime(); // generating the unique panel id
    this.productsArray.push({
      productCode: product.code,
      isProductAdded: false,
      panelID: returnPanelId,
    } as RetrunProductData);
    this.createReturnProductPanel(product.code, returnPanelId);
  }
  createReturnProductPanel(productCode, panelID): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      ReturnFormProductConfiguratorComponent
    );
    const componentRef =
      this.productconfiguratortemplate.createComponent<ReturnFormProductConfiguratorComponent>(componentFactory);
    componentRef.instance.productCode = productCode;
    componentRef.instance.panelID = panelID;
    componentRef.instance.selectedReturnProductdetails.pipe(takeUntil(this.destroy$)).subscribe((returnInfo) => {
      const error =
        this.productsArray.length > 1
          ? this.validateOrdernumber(returnInfo.orderNumber, panelID, returnInfo.variantCode)
          : false;
      if (error) {
        componentRef.instance.isAccordionOpen = true;
        componentRef.instance.orderNumberexists = true;
        componentRef.instance.isProductAdded = false;
      } else {
        componentRef.instance.isAccordionOpen = false;
        componentRef.instance.orderNumberexists = false;
        componentRef.instance.isEditPanel = true;
        componentRef.instance.showSpecifications = true;
        componentRef.instance.isProductAdded = true;
      }
      if (!error || this.productsArray.length === 1) {
        this.productsArray.forEach((returnItem) => {
          if (returnItem.panelID === panelID) {
            returnItem.orderNumber = returnInfo.orderNumber;
            returnItem.variantCode = returnInfo.variantCode;
            returnItem.quantity = returnInfo.quantity;
            returnItem.reasonCode = returnInfo.reason;
            returnItem.orderEntryNumber = returnInfo.entryNumber;
            returnItem.isProductAdded = true;
          }
        });
      }
    });
    componentRef.instance.deleteSelectedReturnProduct.pipe(takeUntil(this.destroy$)).subscribe((panelid) => {
      this.productsArray.forEach((item, index) => {
        if (item.panelID === panelid) {
          this.productconfiguratortemplate.remove(index);
        }
      });
      this.productsArray = this.productsArray.filter((item) => item.panelID !== panelid);
    });
    componentRef.instance.editSelectedReturnProduct.pipe(takeUntil(this.destroy$)).subscribe((isEditenabled) => {
      this.productsArray.find((item) => {
        if (item.panelID === panelID) {
          item.isProductAdded = !isEditenabled;
        }
      });
    });
    this.returnFormService.getAccordionStatus.pipe(takeUntil(this.destroy$)).subscribe((panelNumber) => {
      if (
        componentRef.instance.panelID === panelNumber &&
        (!this.returnAccordionStatus || !componentRef.instance.isEditPanel)
      ) {
        componentRef.instance.isAccordionOpen = !componentRef.instance.isAccordionOpen;
      } else {
        if (componentRef.instance.isProductAdded) {
          componentRef.instance.isAccordionOpen = false;
        }
      }
    });
  }
  get returnAccordionStatus(): boolean {
    return !!this.productsArray.find((product) => !product.isProductAdded);
  }
  validateOrdernumber(orderNumber, panelID, variantCode): boolean {
    return !!this.productsArray.find(
      (product) =>
        product?.orderNumber === orderNumber && product?.panelID !== panelID && product?.variantCode === variantCode
    );
  }
  saveReturnProducts(): void {
    this.savedProducts = [];
    this.savedProducts = this.productsArray?.map(
      ({ orderNumber: orderCode, orderEntryNumber, quantity, reasonCode }) => ({
        orderCode,
        orderEntryNumber,
        quantity,
        reasonCode,
      })
    );
    const returnObject = { returnRequestEntryInputs: this.savedProducts };
    this.returnFormService
      .saveReturnProductInfo(returnObject)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          this.email = response?.emailAddress;
          this.route.navigate(
            this.semantiPathService.transform([
              { cxRoute: 'returnFormPage' },
              {
                cxRoute: 'returnFormConfirmationPage',
                params: { email: this.email, returnCode: response?.code },
              },
            ])
          );
        },
        (error) => {
          const message = error?.error?.errors[0].message;
          this.globalMessageService.add(message, GlobalMessageType.MSG_TYPE_ERROR, GlobalMessage.TIMEOUT);
        }
      );
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
