export interface CalendarConfiguration {
  header?: HeaderConfiguration;
  defaultDate?: Date;
  footer?: FooterConfiguration;
  daysName?: Array<string>;
  minDate?: Date;
  maxDate?: Date;
  showOtherMonths?: boolean;
  styles?: {
    left?: string;
    top?: string;
    right?: string;
  };
  startDate?: any;
  endDate?: any;
  selectionMode?: string;
  rangeDates?: Array<Date>;
}

export enum SelectionMode {
  SINGLE = 'single',
  RANGE = 'range',
}

export interface HeaderConfiguration {
  year: number;
  dayAndDate: string;
}

export interface FooterConfiguration {
  option1: string;
  option2: string;
}

export interface LocaleConfiguration {
  locales: string;
  options: {
    weekday: string;
    month: string;
    day: string;
  };
}
