import { HostBinding } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-safe-ordering',
  templateUrl: './safe-ordering.component.html',
  styleUrls: ['./safe-ordering.component.scss']
})
export class SafeOrderingComponent implements OnInit {
  @HostBinding('class') hostClass = 'position-relative';
  constructor() { }

  ngOnInit(): void {
  }

}
