import { Component, Input, OnInit } from '@angular/core';
import { VisionCareEntries } from 'src/app/store/States/cartItem.state';

@Component({
  selector: 'app-direct-to-patient-confirmed-order',
  templateUrl: './direct-to-patient-confirmed-order.component.html',
  styleUrls: ['./direct-to-patient-confirmed-order.component.scss'],
})
export class DirectToPatientConfirmedOrderComponent {
  constructor() {}
  @Input() dtpOrders: VisionCareEntries;
  @Input() finalPriceCalculated;
  openConfirmedOrder = true;
}
