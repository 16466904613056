import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductDetailsService } from 'src/app/services/alcon-pdp/product-details.service';
import { DIRECT_TO_PRACTICE, PageType, Variants } from '../constants/constants';
import { DropdownOptions } from '../model/common.mode';
import { BasicProductSelectedVariants, SKULevelDetails, VariantTree } from '../model/product.model';

@Component({
  template: '',
})
export abstract class VarientSelectorComponent {
  @Input() variantTree!: VariantTree;
  @Input() contactLensType: string;
  @Input() packSize;
  @Input() set selectedVariant(val) {
    this.selectedVariants = {
      ...this.selectedVariants,
      ...val,
    } as BasicProductSelectedVariants;
  }
  @Output() ProductCodeSelected: EventEmitter<SKULevelDetails> = new EventEmitter<SKULevelDetails>();
  @Output() editProductCodeSelected: EventEmitter<SKULevelDetails> = new EventEmitter<SKULevelDetails>();
  BaseCurveSelector: Array<DropdownOptions> = [];
  DiameterSelector: Array<DropdownOptions> = [];
  LensPowerSelector: Array<DropdownOptions> = [];
  ColorSelector: Array<DropdownOptions> = [];
  AddSelector: Array<DropdownOptions> = [];
  CylinderSelector: Array<DropdownOptions> = [];
  AxisSelector: Array<DropdownOptions> = [];
  pageRoute = '';

  public selectedVariants = {
    basecurve: null,
    diameter: null,
    lenspower: null,
    powerAdd: null,
    color: null,
    cylinder: null,
    axis: null,
    packSize: null,
  } as BasicProductSelectedVariants;

  constructor(public productService: ProductDetailsService, public cd: ChangeDetectorRef) {}

  changePackSize(value: string): void {
    this.packSize = value;
    this.selectedVariants.packSize = value;
    this.getDefaultDropDowns();
    this.cd.detectChanges();
  }
  editPanelPackSize(value: string): void {
    this.packSize = value;
    this.selectedVariants.packSize = value;
    this.cd.detectChanges();
  }
  public getEditPanelDropDowns(): void {
    this.BaseCurveSelector = this.productService.formatDropdown(
      this.productService.generateVarientDropdownoptions(
        this.variantTree.childrenNodes,
        Variants.BASECURVE,
        this.selectedVariants
      ),
      Variants.BASECURVE,
      true,
      { ...this.selectedVariants }
    );
    this.DiameterSelector = this.productService.formatDropdown(
      this.productService.generateVarientDropdownoptions(
        this.variantTree.childrenNodes,
        Variants.DIAMETER,
        this.selectedVariants
      ),
      Variants.DIAMETER,
      true,
      { ...this.selectedVariants }
    );
    this.LensPowerSelector = this.productService.formatDropdown(
      this.productService.generateVarientDropdownoptions(
        this.variantTree.childrenNodes,
        Variants.LENSPOWER,
        this.selectedVariants
      ),
      Variants.LENSPOWER,
      true,
      { ...this.selectedVariants }
    );
    this.ColorSelector = this.productService.formatDropdown(
      this.productService.generateVarientDropdownoptions(
        this.variantTree.childrenNodes,
        Variants.COLOR,
        this.selectedVariants
      ),
      Variants.COLOR,
      true,
      { ...this.selectedVariants }
    );
    this.AddSelector = this.productService.formatDropdown(
      this.productService.generateVarientDropdownoptions(
        this.variantTree.childrenNodes,
        Variants.POWERADD,
        this.selectedVariants
      ),
      Variants.POWERADD,
      true,
      { ...this.selectedVariants }
    );
    this.CylinderSelector = this.productService.formatDropdown(
      this.productService.generateVarientDropdownoptions(
        this.variantTree.childrenNodes,
        Variants.CYLINDER,
        this.selectedVariants
      ),
      Variants.CYLINDER,
      true,
      { ...this.selectedVariants }
    );
    this.AxisSelector = this.productService.formatDropdown(
      this.productService.generateVarientDropdownoptions(
        this.variantTree.childrenNodes,
        Variants.AXIS,
        this.selectedVariants,
        false
      ),
      Variants.AXIS,
      true,
      { ...this.selectedVariants }
    );
    const skuDetails = this.productService.generateVarientCodes(this.variantTree.childrenNodes, this.selectedVariants);
    this.editProductCodeSelected.emit(skuDetails);
    if (this.pageRoute === PageType.EditScheduleOrder && skuDetails) {
      this.ProductCodeSelected.emit(skuDetails);
    }
  }

  public getDefaultDropDowns(): void {
    const isSelected = !!this.selectedVariants.basecurve;
    this.BaseCurveSelector = this.productService.formatDropdown(
      this.productService.generateVarientDropdownoptions(
        this.variantTree.childrenNodes,
        Variants.BASECURVE,
        this.selectedVariants
      ),
      Variants.BASECURVE,
      isSelected,
      this.selectedVariants
    );
    if (this.BaseCurveSelector.length === 1) {
      this.BaseCurveSelector[0].selected = true;
    }
    if (this.BaseCurveSelector.find((selector) => selector.selected)) {
      this.baseCurveSelected(
        this.BaseCurveSelector.find((selector) => selector.selected),
        isSelected
      );
    } else {
      this.ProductCodeSelected.emit(null);
    }
  }

  baseCurveSelected(value: DropdownOptions, isSelected = false): void {
    this.selectedVariants.basecurve = value.text;
    if (!isSelected) {
      this.selectedVariants.diameter = null;
      this.selectedVariants.lenspower = null;
      this.selectedVariants.color = null;
      this.selectedVariants.powerAdd = null;
      this.selectedVariants.axis = null;
      this.selectedVariants.cylinder = null;
    }
    this.LensPowerSelector = [];
    this.AddSelector = [];
    this.ColorSelector = [];
    this.CylinderSelector = [];
    this.AxisSelector = [];
    this.DiameterSelector = this.productService.formatDropdown(
      this.productService.generateVarientDropdownoptions(
        this.variantTree.childrenNodes,
        Variants.DIAMETER,
        this.selectedVariants,
        false
      ),
      Variants.DIAMETER,
      isSelected,
      this.selectedVariants
    );
    if (this.DiameterSelector.length === 1) {
      this.DiameterSelector[0].selected = true;
    }
    if (this.DiameterSelector.find((selector) => selector.selected)) {
      this.diameterSelected(
        this.DiameterSelector.find((selector) => selector.selected),
        isSelected
      );
    } else {
      this.ProductCodeSelected.emit(null);
    }
  }

  diameterSelected(value: DropdownOptions, isSelected = false): void {
    this.selectedVariants.diameter = value.text;
    if (!isSelected) {
      this.selectedVariants.lenspower = null;
      this.selectedVariants.color = null;
      this.selectedVariants.powerAdd = null;
      this.selectedVariants.axis = null;
      this.selectedVariants.cylinder = null;
    }
    this.AddSelector = [];
    this.ColorSelector = [];
    this.CylinderSelector = [];
    this.AxisSelector = [];
    this.LensPowerSelector = this.productService.formatDropdown(
      this.productService.generateVarientDropdownoptions(
        this.variantTree.childrenNodes,
        Variants.LENSPOWER,
        this.selectedVariants,
        false
      ),
      Variants.LENSPOWER,
      isSelected,
      this.selectedVariants
    );
    if (this.LensPowerSelector.length === 1) {
      this.LensPowerSelector[0].selected = true;
    }
    if (this.LensPowerSelector.find((selector) => selector.selected)) {
      this.lensPowerSelected(
        this.LensPowerSelector.find((selector) => selector.selected),
        isSelected
      );
    } else {
      this.ProductCodeSelected.emit(null);
    }
  }

  lensPowerSelected(value, isSelected = false): void {
    this.selectedVariants.lenspower = value.text;
    if (!isSelected) {
      this.selectedVariants.color = null;
      this.selectedVariants.powerAdd = null;
      this.selectedVariants.axis = null;
      this.selectedVariants.cylinder = null;
    }
    switch (this.contactLensType) {
      case DIRECT_TO_PRACTICE.PRODUCT_SPERICAL:
        {
          this.emitValues();
        }
        break;
      case DIRECT_TO_PRACTICE.PRODUCT_COLOR:
        {
          this.ColorSelector = this.productService.formatDropdown(
            this.productService.generateVarientDropdownoptions(
              this.variantTree.childrenNodes,
              Variants.COLOR,
              this.selectedVariants
            ),
            Variants.COLOR,
            isSelected,
            this.selectedVariants
          );
          if (this.ColorSelector.length === 1) {
            this.ColorSelector[0].selected = true;
          }
          if (this.ColorSelector.find((selector) => selector.selected)) {
            this.colorSelected(
              this.ColorSelector.find((selector) => selector.selected),
              isSelected
            );
          } else {
            this.ProductCodeSelected.emit(null);
          }
        }
        break;
      case DIRECT_TO_PRACTICE.PRODUCT_MULTIFOCAL:
        {
          this.AddSelector = this.productService.formatDropdown(
            this.productService.generateVarientDropdownoptions(
              this.variantTree.childrenNodes,
              Variants.POWERADD,
              this.selectedVariants
            ),
            Variants.POWERADD,
            isSelected,
            this.selectedVariants
          );
          if (this.AddSelector.length === 1) {
            this.AddSelector[0].selected = true;
          }
          if (this.AddSelector.find((selector) => selector.selected)) {
            this.addSelected(
              this.AddSelector.find((selector) => selector.selected),
              isSelected
            );
          } else {
            this.ProductCodeSelected.emit(null);
          }
        }
        break;
      case DIRECT_TO_PRACTICE.PRODUCT_TORIC:
        {
          this.AxisSelector = [];
          if (!isSelected) {
            this.selectedVariants.axis = null;
            this.selectedVariants.cylinder = null;
          }
          this.CylinderSelector = this.productService.formatDropdown(
            this.productService.generateVarientDropdownoptions(
              this.variantTree.childrenNodes,
              Variants.CYLINDER,
              this.selectedVariants
            ),
            Variants.CYLINDER,
            isSelected,
            this.selectedVariants
          );
          if (this.CylinderSelector.length === 1) {
            this.CylinderSelector[0].selected = true;
          }
          if (this.CylinderSelector.find((selector) => selector.selected)) {
            this.cylinderSelected(
              this.CylinderSelector.find((selector) => selector.selected),
              isSelected
            );
          } else {
            this.ProductCodeSelected.emit(null);
          }
        }
        break;
    }
  }
  colorSelected(value, isSelected = true): void {
    this.selectedVariants.color = value.text;
    this.emitValues();
  }
  addSelected(value, isSelected = true): void {
    this.selectedVariants.powerAdd = value.text;
    this.emitValues();
  }

  cylinderSelected(value, isSelected = false): void {
    this.selectedVariants.cylinder = value.text;
    if (!isSelected) {
      this.selectedVariants.axis = null;
    }

    this.AxisSelector = this.productService.formatDropdown(
      this.productService.generateVarientDropdownoptions(
        this.variantTree.childrenNodes,
        Variants.AXIS,
        this.selectedVariants,
        false
      ),
      Variants.AXIS,
      isSelected,
      this.selectedVariants
    );
    if (this.AxisSelector.length === 1) {
      this.AxisSelector[0].selected = true;
    }
    if (this.AxisSelector.find((selector) => selector.selected)) {
      this.axisSelected(
        this.AxisSelector.find((selector) => selector.selected),
        isSelected
      );
    } else {
      this.ProductCodeSelected.emit(null);
    }
  }

  axisSelected(value, isSelected = false): void {
    this.selectedVariants.axis = value.text;
    this.emitValues();
  }

  emitValues(): void {
    const skuDetails = this.productService.generateVarientCodes(this.variantTree.childrenNodes, this.selectedVariants);
    this.ProductCodeSelected.emit(skuDetails);
  }

  updateSelectedVariants(key): void {
    switch (key.specificationKey) {
      case 'BC': {
        this.selectedVariants.basecurve = key.specificationValue;
        break;
      }
      case 'DIA': {
        this.selectedVariants.diameter = key.specificationValue;
        break;
      }
      case 'ADD': {
        this.selectedVariants.powerAdd = key.specificationValue;
        break;
      }
      case 'LP': {
        this.selectedVariants.lenspower = key.specificationValue;
        break;
      }
      case 'CY': {
        this.selectedVariants.cylinder = key.specificationValue;
        break;
      }
      case 'AX': {
        this.selectedVariants.axis = key.specificationValue;
        break;
      }
      case 'Colour': {
        this.selectedVariants.color = key.specificationValue;
        break;
      }
    }
  }
}
