<section class="patient-list">
  <button
    class="patient-list__filter-label"
    *ngIf="!showFilter"
    (click)="showFilter = true"
    [disabled]="(isOrdersLoaded$ | async) === false"
    [ngClass]="{ 'filter-disabled': (isOrdersLoaded$ | async) === false }"
  >
    {{ 'patientListFilters.filterPlus' | cxTranslate }}
    <span *ngIf="filtersCount > 0">({{ filtersCount }})</span>
  </button>
  <button class="patient-list__filter-label" *ngIf="showFilter" (click)="showFilter = false">
    {{ 'patientListFilters.filterMinus' | cxTranslate }}
  </button>
</section>
<section class="patient-list-filter" *ngIf="showFilter">
  <div class="d-flex flex-wrap flex-lg-nowrap">
    <div class="d-flex">
      <label class="patient-list-filter__label">{{ 'patientListFilters.ordertype' | cxTranslate }}</label>
      <ng-container>
        <ul class="patient-list-filter__facet--list">
          <li class="pr-3" *ngFor="let items of orderTypes">
            <input
              id="items_{{ items.value }}"
              [(ngModel)]="items.checked"
              type="checkbox"
              [name]="items.value"
              (ngModelChange)="changeFilterSelection($event, items.value)"
              [checked]="items.checked"
              [disabled]="items.disabled"
              class="alconInput__checkbox"
            />
            <label for="items_{{ items.value }}">
              {{ 'patientOrderHistory.' + (items.label | removeWhitespace | lowercase) | cxTranslate }}
            </label>
          </li>
        </ul>
      </ng-container>
    </div>
    <div class="d-flex patient-list-filter__status" *ngIf="orderTypes[0].checked === true">
      <label class="patient-list-filter__label">{{ 'patientListFilters.status' | cxTranslate }}</label>
      <ng-container>
        <ul class="patient-list-filter__facet--list">
          <li class="pr-3" *ngFor="let items of statuses">
            <input
              id="items_{{ items.value }}"
              [(ngModel)]="items.checked"
              type="checkbox"
              [name]="items.value"
              (ngModelChange)="changeFilterSelection($event, items.value)"
              [checked]="items.checked"
              [disabled]="items.disabled"
              class="alconInput__checkbox"
            />
            <label for="items_{{ items.value }}">
              {{ items.label }}
            </label>
          </li>
        </ul>
      </ng-container>
    </div>
  </div>
  <div class="d-flex pt-3" *ngIf="isValuePackEnabled$ | async">
    <label class="patient-list-filter__label">{{ 'patientListFilters.packType' | cxTranslate }}</label>
    <ng-container>
      <ul class="patient-list-filter__facet--list">
        <li class="pr-3" *ngFor="let items of packTypes">
          <input
            id="items_{{ items.value }}"
            [(ngModel)]="items.checked"
            type="checkbox"
            [name]="items.value"
            (ngModelChange)="changeFilterSelection($event, items.value)"
            [checked]="items.checked"
            [disabled]="items.disabled"
            class="alconInput__checkbox"
          />
          <label for="items_{{ items.value }}">
            {{ 'patientOrderHistory.' + (items.label | removeWhitespace | lowercase) | cxTranslate }}
          </label>
        </li>
      </ul>
    </ng-container>
  </div>
  <div class="d-flex justify-content-between pt-3">
    <section class="d-flex">
      <label class="patient-list-filter__label">{{ 'patientListFilters.delivery' | cxTranslate }}</label>
      <ng-container>
        <ul class="patient-list-filter__facet--list">
          <li class="pr-3" *ngFor="let items of deliveryOptions">
            <input
              id="items_{{ items.value }}"
              [(ngModel)]="items.checked"
              type="checkbox"
              [name]="items.value"
              (ngModelChange)="changeFilterSelection($event, items.value)"
              [checked]="items.checked"
              [disabled]="items.disabled"
              class="alconInput__checkbox"
            />
            <label for="items_{{ items.value }}">
              {{ 'patientOrderHistory.' + (items.label | removeWhitespace | lowercase) | cxTranslate }}
            </label>
          </li>
        </ul>
      </ng-container>
    </section>
    <section class="pt-2">
      <button
        class="patient-list-filter__clearall"
        [ngClass]="{ 'patient-list-filter__clearall--disabled': filtersCount === 0 }"
        [disabled]="filtersCount === 0"
        (click)="clearFilters()"
      >
        <cx-icon *ngIf="filtersCount > 0" type="CloseMark"></cx-icon>
        <cx-icon *ngIf="filtersCount === 0" type="DisabledClose"></cx-icon>
        {{ 'patientListFilters.clearfilters' | cxTranslate }}
      </button>
    </section>
  </div>
</section>
