<fieldset>
  <div class="timepick-tp">
    <div class="timepick-tp-input-container timepick-tp-hour">
      <input
        type="text"
        maxlength="2"
        inputmode="numeric"
        placeholder="HH"
        aria-label="Hours"
        class="timepick-tp-input form-control"
        [(ngModel)]="displayTime.hour"
        (input)="formatTime($event)"
        (change)="formatDigits()"
        [disabled]="disabled"
        [ngClass]="{ errorStyle: timeError }"
      />
    </div>
    <div class="timepick-tp-spacer">:</div>
    <div class="timepick-tp-input-container timepick-tp-minute">
      <input
        type="text"
        maxlength="2"
        inputmode="numeric"
        placeholder="MM"
        aria-label="Minutes"
        class="timepick-tp-input form-control"
        [(ngModel)]="displayTime.minute"
        (input)="formatTime($event)"
        (change)="formatDigits()"
        [disabled]="disabled"
        [ngClass]="{ errorStyle: timeError }"
      />
    </div>
    <div class="timepick-tp-spacer"></div>
    <div class="timepick-tp-meridian" *ngIf="meridianFlag">
      <input
        type="button"
        class="btn btn-outline-primary disabled"
        (click)="toggleMeridian()"
        (change)="meridianChanged()"
        [(ngModel)]="meridianValue"
        [disabled]="disabled"
      />
    </div>
  </div>
</fieldset>
