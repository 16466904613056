import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CmsService, PageMeta, PageMetaService } from '@spartacus/core';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { FavoriteProductListService } from 'src/app/services/favorite-product-list/favorite-product-list.service';
@Component({
  selector: 'app-alcon-favorite-list',
  templateUrl: './alcon-favorite-list.component.html',
  styleUrls: ['./alcon-favorite-list.component.scss'],
})
export class AlconFavoriteListComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  favoriteList;
  itemInRowRange = [];
  title$: Observable<string>;
  noFavouriteImgContent;
  constructor(
    private favoriteProductListService: FavoriteProductListService,
    private pageMetaService: PageMetaService,
    private route: ActivatedRoute,
    private cmsService: CmsService
  ) {}
  ngOnInit(): void {
    this.title$ = this.pageMetaService.getMeta().pipe(
      filter(Boolean),
      map((meta: PageMeta) => meta.heading || meta.title)
    );
    this.cmsService
      .getComponentData('FavouritesImageComponent')
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.noFavouriteImgContent = val;
      });
    this.favoriteList = this.route.snapshot.data.favouriteList.products;
    this.itemInRowRange = Array.from(Array(Math.ceil(this.favoriteList.length / 5)).keys());
    this.favoriteProductListService.removeProduct.pipe(takeUntil(this.destroy$)).subscribe((code) => {
      const removeProduct = this.favoriteList.findIndex((val) => val.code === code);
      this.favoriteList.splice(removeProduct, 1);
    });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
