import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-alcon-footer',
  templateUrl: './alcon-footer.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./alcon-footer.component.scss'],
})
export class AlconFooterComponent implements OnInit, OnDestroy {
  showReferenceSection = false;
  showReferences = false;
  private destroy$ = new Subject<void>();
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof ActivationStart) {
        this.showReferenceSection = event.snapshot.data.cxRoute === 'product' ? true : false;
      }
    });
  }
  openReference(): void {
    this.showReferences = !this.showReferences;
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
