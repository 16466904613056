import { Pipe, PipeTransform } from '@angular/core';
import { PROTOCOL } from '../shared/constants/constants';

type Config = {
  width: string;
  height: string;
  position: string;
  color: string;
  quality: string;
};

type ConfigMap = {
  [key: string]: Config;
};

const FORMAT_TYPES = ['thumbnail', 'product', 'PRIMARY'];

const CONFIG: ConfigMap = {
  product: {
    width: '190',
    height: '100',
    position: 'c',
    color: 'ffffffff',
    quality: '80',
  },
  thumbnail: {
    width: '101',
    height: '101',
    position: 'c',
    color: 'ffffffff',
    quality: '80',
  },
};

@Pipe({
  name: 'imageFormat',
})
export class ImageFormatPipe implements PipeTransform {
  transform(product: { url?: string }, configName: string): any {
    const configuration: Config = CONFIG[configName] || ({} as Config);

    if (Boolean(product)) {
      const productInfo = JSON.parse(JSON.stringify(product));

      this.findAndParameterizeUrl(productInfo, configuration);

      return productInfo;
    }
  }

  private findAndParameterizeUrl(item: { url?: string }, config: Config): void {
    if (item.url) {
      item.url = this.addParams(item.url, config);
    }

    for (const formatType of FORMAT_TYPES) {
      if (item.hasOwnProperty(formatType)) {
        this.findAndParameterizeUrl(item[formatType], config);
      }
    }
  }

  private addParams(imageurl: string, config: Config): string {
    if (this.isValidHttpUrl(imageurl)) {
      const url = new URL(imageurl);

      url.searchParams.set('w', config.width);
      url.searchParams.set('h', config.height);
      url.searchParams.set('position', config.position);
      url.searchParams.set('color', config.color);
      url.searchParams.set('quality', config.quality);

      return url.href;
    }
  }

  private isValidHttpUrl(str: string): boolean {
    let url;

    try {
      url = new URL(str);
    } catch (_) {
      return false;
    }

    return url.protocol === PROTOCOL.HTTP || url.protocol === PROTOCOL.HTTPS;
  }
}
