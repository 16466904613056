import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { ProductDetailsService } from 'src/app/services/alcon-pdp/product-details.service';
import { DIRECT_TO_PATIENT, DIRECT_TO_PRACTICE, EYESIDE, PACK_SIZE } from 'src/app/shared/constants/constants';
import {
  BasicProductSelectedVariants,
  SKULevelDetails,
  SelectedVariantDetails,
  SelectedVariantValue,
  VariantTree,
} from 'src/app/shared/model/product.model';
import { Utilities } from 'src/app/shared/utility/utility';
import {
  VisionCareAddtocartProductRequest,
  VisionCareAddtocartProductRequestInfo,
} from 'src/app/store/States/cartItem.state';

@Component({
  selector: 'app-basic-product-edit-selector',
  templateUrl: './basic-product-edit-selector.component.html',
  styleUrls: ['./basic-product-edit-selector.component.scss'],
})
export class BasicProductEditSelectorComponent implements OnInit, AfterViewInit, OnDestroy {
  positionId: string;
  @Input() set editProductData(val) {
    this.productData = val;
    this.selectedProduct = val.product.baseProduct;
  }
  @Input() cartType;
  @Output() closeHandeller = new EventEmitter();
  price$ = this.productService.packSizePriceChanged$$.pipe(
    map((array) => array.find((prd) => prd.code === this.selectedProduct))
  );
  selectedProduct;
  productData;
  packSize = [];
  contactLensType = '';
  variantTree!: VariantTree;
  botheye = false;
  leftEye = false;
  rightEye = false;
  product: any;
  selectedPackSize = null;
  varients = {
    basecurve: null,
    diameter: null,
    lenspower: null,
    powerAdd: null,
    color: null,
    cylinder: null,
    axis: null,
    packSize: null,
  } as BasicProductSelectedVariants;
  public selectedVariantCode = {
    quantity: 0,
    isSelected: false,
    details: {
      code: null,
      maxQuantity: null,
    } as SelectedVariantDetails,
    eyeSide: 'left',
  } as SelectedVariantValue;
  lensSelectedValues = [];
  quantity = 1;
  patientName: string;
  patientId: string;
  solutionProducts = false;
  disableCheckbox = true;
  orderType = DIRECT_TO_PRACTICE.REGULAR;
  eyeSide: string;
  errorMessage: string;
  dtpMaxLimit: number;
  showErrorMessage = false;
  private destroySubscriptions$ = new Subject<void>();
  showLoader = false;
  qtyFactor = 1;
  readonly productType = DIRECT_TO_PRACTICE;
  @Output() emitUpdatedData: EventEmitter<any> = new EventEmitter();

  constructor(private productService: ProductDetailsService, private cd: ChangeDetectorRef) {}
  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.showLoader = true;
    this.eyeSide = this.productData.eyeSight?.toLowerCase();
    this.orderType = this.productData.orderEntryFlowType;
    this.quantity = this.productData.quantity;
    this.productService
      .getProductInfo(this.selectedProduct, DIRECT_TO_PRACTICE.PRACTICE_ORDER_PAGE)
      .pipe(takeUntil(this.destroySubscriptions$))
      .subscribe((data) => {
        this.showLoader = false;
        this.variantTree = JSON.parse(data.variantTree);
        this.product = data;
        this.contactLensType = data.contactLensType;
        this.solutionProducts = data.isLensCareSolution || data.isDryEyeSolution;
        const selectedPackSize = this.productData.product.packSize;
        this.packSize = Utilities.setSelectedPackSize(data, selectedPackSize);
        this.cd.detectChanges();
      });
  }

  changePackSize(value): void {
    if (value === PACK_SIZE.NINETY) {
      // Load price for the first time if the 90 pack was preselected.
      // Once user changes packsize then load from selected variant code
      if (!this.selectedPackSize) {
        this.getNintyPackPrice(String(this.productData.product.code));
      }
    } else {
      this.productService.getMaterialGroupPrice(value, this.product?.packSizePricingMap, this.product.code);
    }
    if (this.solutionProducts) {
      let selectedPack;
      selectedPack = this.packSize.filter((data) => {
        if (value === data.value) {
          return data;
        }
      });
      const selectedVariants = { packSize: selectedPack[0].value };
      const skuDetails = this.productService.generateVarientCodes(this.variantTree.childrenNodes, selectedVariants);
      const pack = this.productData.product.categories.find((packsize) => packsize.name === selectedVariants.packSize);
      this.quantity = !pack ? skuDetails.unitFactor : this.productData.quantity;
      this.qtyFactor = skuDetails.unitFactor;
      this.dtpMaxLimit = skuDetails.dtpMaxLimit;
      this.selectedVariantCode.details.code = skuDetails.code?.toString();
      this.selectedVariantCode.isSelected = true;
      this.selectedVariantCode.eyeSide = this.eyeSide;
      this.productData?.product?.packSize !== value ? this.validateAddToCart() : (this.lensSelectedValues = []);
    }
    this.selectedPackSize = value;
    this.varients.packSize = value;
    if (!this.solutionProducts) {
      this.lensSelectedValues = [];
    }
    this.cd.detectChanges();
  }

  closePopup(): void {
    this.closeHandeller.emit(false);
    document.body.classList.remove('modal-open');
  }
  selectedCode(skuDetails: SKULevelDetails): void {
    this.setCode(skuDetails?.code);
    if (skuDetails) {
      if (this.selectedPackSize === PACK_SIZE.NINETY) {
        this.getNintyPackPrice(String(this.selectedVariantCode.details.code));
      }
    } else {
      // reset price only if skuDetails are not there and it is 90 pack
      if (this.selectedPackSize === PACK_SIZE.NINETY) {
        this.productService.emitPrice(this.selectedProduct, null);
      }
    }
    this.validateAddToCart();
  }

  private getNintyPackPrice(variantCode: string): void {
    this.showLoader = true;
    this.productService
      .getMaterialPrice(variantCode)
      .pipe(take(1))
      .subscribe((response) => {
        this.showLoader = false;
        response?.formattedValue
          ? this.productService.emitPrice(this.selectedProduct, response)
          : this.productService.emitPrice(this.selectedProduct, null);
      });
  }

  setCode(productcode): void {
    this.selectedVariantCode.details.code = productcode;
    this.selectedVariantCode.isSelected = true;
    this.selectedVariantCode.eyeSide = this.eyeSide;
  }

  setProductQuantity(value): void {
    this.quantity = value;
    if (!this.selectedVariantCode.details.code) {
      this.setCode(this.productData.product.code);
      this.validateAddToCart();
    } else {
      this.validateAddToCart();
    }
  }

  checkDTPmaxCount(skuDetails): void {
    this.dtpMaxLimit = skuDetails?.dtpMaxLimit;
    this.setCode(skuDetails?.code);
  }

  private validateAddToCart(): void {
    const dtp = this.orderType === DIRECT_TO_PATIENT.DIRECT_TO_PATIENT_ORDER;
    if (this.dtpMaxLimit && this.dtpMaxLimit < this.quantity && dtp) {
      this.lensSelectedValues = [];
      this.showErrorMessage = true;
      return;
    } else {
      this.showErrorMessage = false;
    }
    const updateVariantsDetails = [];
    updateVariantsDetails.push({ ...this.selectedVariantCode });
    let lensSelectedValues = updateVariantsDetails
      .filter((power) => {
        return power.isSelected && power.details.code !== null && power.details.code !== undefined;
      })
      .map((lensPower) => {
        let productData: Array<VisionCareAddtocartProductRequest> | VisionCareAddtocartProductRequest = {
          product: { code: lensPower.details.code } as VisionCareAddtocartProductRequestInfo,
          quantity: this.quantity?.toString(),
          orderEntryFlowType:
            this.orderType === DIRECT_TO_PATIENT.DIRECT_TO_PATIENT_ORDER
              ? DIRECT_TO_PATIENT.DIRECT_TO_PATIENT_ORDER
              : DIRECT_TO_PRACTICE.REGULAR,
          eyeSight: lensPower.eyeSide ? (lensPower.eyeSide === EYESIDE.RIGHT ? 'R' : 'L') : '',
          patientShippingAddressMain:
            this.orderType === DIRECT_TO_PATIENT.DIRECT_TO_PATIENT_ORDER
              ? this.productData?.patientShippingAddressMain
              : '',
          patientId: this.orderType === DIRECT_TO_PATIENT.DIRECT_TO_PATIENT_ORDER ? this.productData.patientId : '',
          patientName: this.productData?.patientName,
        } as VisionCareAddtocartProductRequest;
        if (this.productData?.product?.code === this.selectedVariantCode?.details?.code) {
          productData.entryNumber = this.productData.entryNumber;
          productData = [{ ...productData }];
          return productData;
        } else {
          productData = [
            { ...productData },
            {
              product: { code: this.productData.product.code } as VisionCareAddtocartProductRequestInfo,
              quantity: '0',
              orderEntryFlowType:
                this.orderType === DIRECT_TO_PATIENT.DIRECT_TO_PATIENT_ORDER
                  ? DIRECT_TO_PATIENT.DIRECT_TO_PATIENT_ORDER
                  : DIRECT_TO_PRACTICE.REGULAR,
              eyeSight: lensPower.eyeSide ? (lensPower.eyeSide === EYESIDE.RIGHT ? 'R' : 'L') : '',
              patientName: this.productData?.patientName,
              patientShippingAddressMain:
                this.orderType === DIRECT_TO_PATIENT.DIRECT_TO_PATIENT_ORDER
                  ? this.productData?.patientShippingAddressMain
                  : '',
              entryNumber: this.productData.entryNumber,
              patientId: this.orderType === DIRECT_TO_PATIENT.DIRECT_TO_PATIENT_ORDER ? this.productData.patientId : '',
            } as VisionCareAddtocartProductRequest,
          ];
          return productData;
        }
      });
    if (updateVariantsDetails.filter((power) => power.isSelected).length !== lensSelectedValues.length) {
      lensSelectedValues = [];
    }
    this.lensSelectedValues = [...(lensSelectedValues[0] || [])];
    this.positionId = this.productData.positionId;
  }
  emitErrorMessage(errorMsg): void {
    this.errorMessage = errorMsg?.error?.errors[0]?.message;
  }

  setUpdatedData(value): void {
    this.emitUpdatedData.emit(value);
  }

  ngOnDestroy(): void {
    this.destroySubscriptions$.next();
    this.destroySubscriptions$.complete();
  }
}
