import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-description-page',
  templateUrl: './product-description-page.component.html',
  styleUrls: ['./product-description-page.component.scss'],
})
export class ProductDescriptionPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
