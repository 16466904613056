import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ConfigModule, HttpErrorHandler, I18nModule, provideConfig, provideDefaultConfig } from '@spartacus/core';
import {
  organizationTranslationChunksConfig,
  organizationTranslations,
} from '@spartacus/organization/administration/assets';
import { AdministrationRootModule } from '@spartacus/organization/administration/root';
import {
  orderApprovalTranslationChunksConfig,
  orderApprovalTranslations,
} from '@spartacus/organization/order-approval/assets';
import { OrderApprovalRootModule } from '@spartacus/organization/order-approval/root';
import { defaultB2bOccConfig } from '@spartacus/setup';
import { IconModule, MediaModule, PageSlotModule } from '@spartacus/storefront';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AlconCmsComponentsModule } from './cms-components/alcon-cms-components.module';
import { AlconGlobalMessageComponent } from './cms-components/alcon-global-message/alcon-global-message.component';
import { AlconCommonModule } from './cms-components/common/alcon-common.module';
import { AlconFooterComponent } from './cms-components/navigation/alcon-footer/alcon-footer.component';
import { HeaderNavComponent } from './cms-components/navigation/header-nav/header-nav.component';
import { HeaderModule } from './cms-components/navigation/header.module';
import { AlconCmsPagesModule } from './cms-pages/alcon-cms-pages.module';
import { PageNotFoundComponent } from './cms-pages/page-not-found/page-not-found.component';
import { CustomerContactDetailsModule } from './shared-modules/customer-contact-details/customer-contact-details.module';
import { FAQModule } from './shared-modules/faq-module/faq.module';
import { InvoicesPageModule } from './shared-modules/invoices-page/invoices-page.module';
import { LoginLogoutModule } from './shared-modules/login-logout/login-logout.module';
import { ShowPriceModule } from './shared-modules/show-price/show-price.module';
import { UserIdProviderModule } from './shared-modules/user-id-provider/user-id-provider.module';
import { iconConfig } from './shared/config/icon-config';
import { InvalidSiteErrorHandler } from './shared/errorhandlers/invalid-site-error';
import { UnauthorizedErrorHandler } from './shared/errorhandlers/unauthorized-error';
import { SpartacusModule } from './spartacus/spartacus.module';
import { B2BUnitsEffects } from './store/Effects/b2bUnits.effects';
import { CartEffects } from './store/Effects/cartItem.effects';
import { FavouriteCountEffect } from './store/Effects/favouriteCount.effects';
import { ValuePackEffects } from './store/Effects/valuePack.effects';
import { b2bUnitsReducer } from './store/Reducers/b2bUnits.reducer';
import { cartItemsReducer } from './store/Reducers/cartItems.reducer';
import { countryReducer } from './store/Reducers/countyCountry.reducer';
import { favouriteCountReducer } from './store/Reducers/favouriteCount.reducer';
import { fetchPatientOrderReducer } from './store/Reducers/patient-orders-flag.reducer';
import { patientItemsReducer } from './store/Reducers/patient.reducer';
import { valuePackReducer } from './store/Reducers/valuePack.reducer';
import { TemplatesModule } from './templates/templates.module';
import { UserActivityComponent } from './user-activity/user-activity.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderNavComponent,
    AlconFooterComponent,
    UserActivityComponent,
    AlconGlobalMessageComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    StoreModule.forRoot({
      visionCareCart: cartItemsReducer,
      b2bUnits: b2bUnitsReducer,
      countries: countryReducer,
      favouriteCount: favouriteCountReducer,
      valuePackVarients: valuePackReducer,
      patients: patientItemsReducer,
      fetchPatientOrders: fetchPatientOrderReducer,
    }),
    EffectsModule.forRoot([CartEffects, FavouriteCountEffect, ValuePackEffects, B2BUnitsEffects]),
    SpartacusModule,
    PageSlotModule,
    UserIdProviderModule,
    ConfigModule.withConfig(iconConfig),
    I18nModule,
    IconModule,
    MediaModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HeaderModule,
    AlconCommonModule,
    AlconCmsPagesModule,
    AlconCmsComponentsModule,
    AdministrationRootModule,
    OrderApprovalRootModule,
    FAQModule,
    InvoicesPageModule,
    ShowPriceModule,
    CustomerContactDetailsModule,
    LoginLogoutModule,
    TemplatesModule,
    I18nModule,
  ],
  providers: [
    provideDefaultConfig(defaultB2bOccConfig),
    provideConfig({
      i18n: {
        resources: organizationTranslations,
        chunks: organizationTranslationChunksConfig,
      },
    }),
    provideConfig({
      i18n: {
        resources: orderApprovalTranslations,
        chunks: orderApprovalTranslationChunksConfig,
      },
    }),
    {
      provide: HttpErrorHandler,
      useExisting: InvalidSiteErrorHandler,
      multi: true,
    },
    {
      provide: HttpErrorHandler,
      useExisting: UnauthorizedErrorHandler,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
