import { Component, OnInit } from '@angular/core';
import { PageMeta, PageMetaService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-product-list-page',
  templateUrl: './product-list-page.component.html',
  styleUrls: ['./product-list-page.component.scss'],
})
export class ProductListPageComponent implements OnInit {
  title$: Observable<string> = this.pageMetaService.getMeta().pipe(
    filter(Boolean),
    map((meta: PageMeta) => meta.heading || meta.title)
  );
  constructor(private pageMetaService: PageMetaService) {}

  ngOnInit(): void {}
}
