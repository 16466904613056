import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ProductDetailsService } from 'src/app/services/alcon-pdp/product-details.service';
import { DIRECT_TO_PRACTICE, Variants } from 'src/app/shared/constants/constants';
import { DropdownOptions } from 'src/app/shared/model/common.mode';
import { BasicProductSelectedVariants, ProductVarientTree, Specs } from 'src/app/shared/model/product.model';

@Component({
  selector: 'app-variant-specs-selector',
  templateUrl: './variant-specs-selector.component.html',
  styleUrls: ['./variant-specs-selector.component.scss'],
})
export class VariantSpecsSelectorComponent implements OnChanges {
  @Input() updateCart: boolean;
  @Input() variantTree: ProductVarientTree;
  @Input() contactLensType;
  packSize: any;
  @Input() set selectedPack(value) {
    this.variants.packSize = value;
  }
  @Input() set variantsData(value) {
    this.variants = { ...value };
  }
  variants = {
    packSize: null,
    basecurve: null,
    diameter: null,
    color: null,
    powerAdd: null,
    cylinder: null,
  } as BasicProductSelectedVariants;
  addSelector: DropdownOptions[];
  colorSelector: DropdownOptions[];
  toricSelector: DropdownOptions[];
  baseCurveSelector: DropdownOptions[];
  diameterSelector: DropdownOptions[];
  @Output() specsSelected: EventEmitter<Specs> = new EventEmitter();
  constructor(private productService: ProductDetailsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.selectedPack) {
      this.packSize = changes?.selectedPack?.currentValue;
      this.createSpecs();
    }
  }

  createSpecs(): void {
    this.baseCurveSelector = this.getSpecValues(Variants.BASECURVE);
    if (this.baseCurveSelector.length === 1 || this.updateCart) {
      this.baseCurveSelected(this.baseCurveSelector[0]);
    }
  }

  createOtherSpecs(): void {
    switch (this.contactLensType) {
      case DIRECT_TO_PRACTICE.PRODUCT_MULTIFOCAL:
        this.addSelector = this.getSpecValues(Variants.POWERADD);
        this.setSelectedVariant(this.addSelector, Variants.POWERADD);
        break;
      case DIRECT_TO_PRACTICE.PRODUCT_COLOR:
        this.colorSelector = this.getSpecValues(Variants.COLOR);
        this.setSelectedVariant(this.colorSelector, Variants.COLOR);
        break;
      case DIRECT_TO_PRACTICE.PRODUCT_TORIC:
        this.toricSelector = this.getSpecValues(Variants.CYLINDER);
        this.setSelectedVariant(this.toricSelector, Variants.CYLINDER);
        break;
    }
  }

  getSpecValues(specType): Array<DropdownOptions> {
    return this.productService.formatDropdown(
      this.productService.generateVarientDropdownoptions(this.variantTree?.childrenNodes, specType, this.variants),
      specType,
      this.updateCart,
      this.variants
    );
  }

  setSelectedVariant(options: Array<DropdownOptions>, variantType: string): void {
    if (this.isOneItemInDropdown(options)) {
      this.variants[variantType] = options[0].text;
    }

    if (this.isOneItemInDropdown(options) || this.updateCart) {
      this.specsSelected.emit({ variantType, variants: this.variants });
    }
  }

  baseCurveSelected(value: DropdownOptions): void {
    this.variants.basecurve = value.text;
    this.variants.diameter = null;
    if (!this.updateCart) {
      this.resetOtherSpecs();
    }
    this.diameterSelector = this.productService.formatDropdown(
      this.productService.generateVarientDropdownoptions(
        this.variantTree?.childrenNodes,
        Variants.DIAMETER,
        this.variants,
        false
      )
    );
    if (this.diameterSelector.length === 1 || this.updateCart) {
      this.diameterSelected(this.diameterSelector[0]);
    }
  }

  diameterSelected(value: DropdownOptions): void {
    this.variants.diameter = value.text;
    if (!this.updateCart) {
      this.resetOtherSpecs();
    }
    this.createOtherSpecs();
    if (this.contactLensType === DIRECT_TO_PRACTICE.PRODUCT_SPERICAL) {
      this.specsSelected.emit({ variantType: Variants.DIAMETER, variants: this.variants });
    }
  }

  private resetOtherSpecs(): void {
    this.variants.color = null;
    this.variants.cylinder = null;
    this.variants.powerAdd = null;
  }

  colorSelected(value): void {
    this.variants.color = value.text;
    this.specsSelected.emit({ variantType: Variants.COLOR, variants: this.variants });
  }

  addSelected(value): void {
    this.variants.powerAdd = value.text;
    this.specsSelected.emit({ variantType: Variants.POWERADD, variants: this.variants });
  }

  lensCylinderSelected(value): void {
    this.variants.cylinder = value.text;
    this.specsSelected.emit({ variantType: Variants.CYLINDER, variants: this.variants });
  }

  private isOneItemInDropdown(options: DropdownOptions[]): boolean {
    return Array.isArray(options) && options.length === 1;
  }
}
