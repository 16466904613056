<div class="faq-search">
  <div class="faq-search__box">
    <label class="faq-search__label d-md-none d-lg-block">{{ 'faqSearch.searchLabel' | cxTranslate }}</label>
    <h2
      class="col-10 text-center faq-search__tablet-heading d-lg-none d-md-block"
      [innerHTML]="subHeading$ | async"
    ></h2>

    <app-dropdown-selector-v2
      [options]="[]"
      [config]="defaultConfig"
      [filteredOptions]="filterOptions"
      (searchTextChange)="getQuestions($event)"
      (dropDownSelected)="selectSearch($event)"
    >
      <cx-page-slot position="VCFAQRightDownContentSlotPosition"></cx-page-slot>
    </app-dropdown-selector-v2>

    <p class="faq-search__search-suggestion text-center">{{ 'faqSearch.searchSuggestion' | cxTranslate }}</p>
  </div>
</div>
