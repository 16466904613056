<div class="select-product d-flex flex-column flex-lg-row">
  <div class="select-product__container d-flex flex-column flex-grow-1">
    <div class="select-product__main">
      <h1 class="patient-name">{{ 'selectProductPage.patientName' | cxTranslate }}{{ patientName }}</h1>
    </div>
    <div class="select-packtype">
      <h4 class="select-packtype__selectLabel col-3">{{ 'selectProductPage.selectPackType' | cxTranslate }}</h4>
      <div class="select-packtype__radio-button-margin select-packtype__radio-button-margin__bottom">
        <input
          type="radio"
          [value]="true"
          [(ngModel)]="radioPacktype"
          (ngModelChange)="packTypeChanged($event)"
          id="packtype-regular"
          selected="true"
          name="radioPacktype"
          class="alconInput__radio"
          [disabled]="scheduledOrderList[0]?.replenishmentOrderCode"
        />
        <label for="packtype-regular" class="select-packtype__radio-label">{{
          'selectProductPage.regular' | cxTranslate
        }}</label>
      </div>
      <div
        class="select-packtype__radio-button-margin select-packtype__radio-button-margin__top"
        *ngIf="isValuePackEnabled$ | async"
      >
        <input
          type="radio"
          [value]="false"
          [(ngModel)]="radioPacktype"
          (ngModelChange)="packTypeChanged($event)"
          id="packtype-valuePack"
          name="radioPacktype"
          class="alconInput__radio"
          [disabled]="scheduledOrderList[0]?.replenishmentOrderCode"
        />
        <label for="packtype-valuePack" class="select-packtype__radio-label"
          >{{ 'selectProductPage.valuePack' | cxTranslate }}
          <cx-icon
            [ngbPopover]="popoverTmpl"
            popoverClass="popover--sm"
            placement="bottom-left"
            triggers="mouseenter:mouseleave"
            #p="ngbPopover"
            (click)="p.open()"
            class="select-packtype__question-Icon"
            type="QuestionMark"
          >
          </cx-icon>

          <ng-template #popoverTmpl>
            {{ 'selectProductPage.valuePacktooltip' | cxTranslate }}
          </ng-template>
        </label>
      </div>
      <div class="select-packtype__pack-options">
        <app-product-pack-size
          *ngIf="isValuePack"
          [packSizeList]="packSizeList | async"
          (packSizeChanged)="changePackSize($event)"
        >
        </app-product-pack-size>
      </div>
    </div>
    <app-neworder-regular-pack
      *ngIf="radioPacktype"
      [isDTP]="isDTP"
      (selectedProductLensValues)="getUpdatedProductValues($event)"
      (isDTPErrorFlag)="getIsDTPErrorFlag($event)"
      [scheduledOrderList]="scheduledOrderList"
    ></app-neworder-regular-pack>

    <app-neworder-value-pack
      *ngIf="!radioPacktype"
      (selectedProductLensValues)="getUpdatedProductValues($event)"
      [isDTP]="isDTP"
      (isDTPErrorFlag)="getIsDTPErrorFlag($event)"
    ></app-neworder-value-pack>
  </div>
  <div>
    <app-patient-order-selection-panel
      [selectedProductValue]="selectedProductValue"
      [selectedPackType]="radioPacktype"
      (setToDefaults)="setToDefaults()"
      (orderFlowChange)="setOrderFlowChange($event)"
      (isEditRightPanel)="isEditRightPanel()"
      [isDTPError]="isDTPError"
      [scheduledOrderList]="scheduledOrderList[0]"
      [selectedOrderType]="selectedOrderType"
      [selectedDeliveryType]="selectedDeliveryType"
      [selectedScheduledOrderData]="selectedScheduledOrderData"
    ></app-patient-order-selection-panel>
  </div>
</div>
