import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountSelectorModalWrapperComponent } from '../shared-modules/multi-account-selector/components/account-selector-modal-wrapper/account-selector-modal-wrapper.component';
import { AccountSelectorService } from '../shared-modules/multi-account-selector/services/account-selector.service';
import { MODAL_POPUP_CONFIG } from '../shared/constants/constants';

@Directive({
  selector: '[appAccountSwitch]',
})
export class AccountSwitchDirective {
  @Input() isSoldto: boolean;

  constructor(private modalService: NgbModal, private accountSelectorService: AccountSelectorService) {}
  @HostBinding('class.d-none') get showButton(): boolean {
    const localStorageItem = this.accountSelectorService.getLocalStorageItem();
    const showSwitchButton = this.isSoldto ? localStorageItem.selectedSoldToCount : localStorageItem.shipToCount;
    const isHidden = showSwitchButton === 'false' ? true : false;
    return !showSwitchButton || isHidden;
  }
  @HostListener('click', ['$event']) openWrapperModal(e): void {
    const modalRef = this.modalService.open(AccountSelectorModalWrapperComponent, {
      ...MODAL_POPUP_CONFIG,
      size: 'xl',
      windowClass: this.isSoldto ? 'soldto_alertPopup' : 'shipto_alertPopup',
      backdropClass: 'true-blue-backdrop',
      centered: false,
    });

    if (this.isSoldto) {
      modalRef.componentInstance.showSoldToSelector = true;
    } else {
      modalRef.componentInstance.showSoldToSelector = false;
    }
  }
}
