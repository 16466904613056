import { NgModule } from '@angular/core';
import { CheckoutModule } from '@spartacus/checkout/base';
import { CheckoutCoreModule } from '@spartacus/checkout/base/core';
import { CheckoutRootModule } from '@spartacus/checkout/base/root';
import { CartBaseCoreModule } from '@spartacus/cart/base/core';
import { CartBaseOccModule } from '@spartacus/cart/base/occ';
import {
  AuthModule,
  GlobalMessageModule,
  ProductModule,
  ProductOccModule,
  UserOccModule,
  UserModule,
} from '@spartacus/core';
import {
  BannerModule,
  CmsParagraphModule,
  FooterNavigationModule,
  NavigationModule,
  PaymentMethodsModule,
  PDFModule,
  ProductImagesModule,
  ProductListingPageModule,
  ProductListModule,
  ProductPageEventModule,
} from '@spartacus/storefront';
import { AsmFeatureModule } from './features/asm/asm-feature.module';
import { UserFeatureModule } from './features/user/user-feature.module';
@NgModule({
  declarations: [],
  imports: [
    AuthModule.forRoot(),
    ProductModule.forRoot(),
    CmsParagraphModule,
    ProductListModule,
    BannerModule,
    NavigationModule,
    FooterNavigationModule,
    ProductListingPageModule,
    ProductPageEventModule,
    ProductOccModule,
    PaymentMethodsModule,
    CheckoutCoreModule,
    CheckoutRootModule,
    CheckoutModule,
    ProductImagesModule,
    GlobalMessageModule,
    AsmFeatureModule,
    UserFeatureModule,
    CartBaseCoreModule,
    CartBaseOccModule,
    UserOccModule,
    UserModule,
    PDFModule,
  ],
})
export class SpartacusFeaturesModule {}
