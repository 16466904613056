import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductSearchService, TranslationService, User } from '@spartacus/core';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { Observable, Subject, forkJoin } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ProductDetailsService } from 'src/app/services/alcon-pdp/product-details.service';
import { OrderHistoryService } from 'src/app/services/order-history/order-history.service';
import { ValuePackService } from 'src/app/services/value-pack/valuePack.service';
import { Category, VALUE_PACK_TYPES } from 'src/app/shared/constants/constants';
import { DropdownOptions } from 'src/app/shared/model/common.mode';
import {
  DeliveryTypesMapping,
  OrderType,
  RecurrencePeriod,
  ScheduledOrderData,
  VisionCareAddtocartProductRequest,
} from 'src/app/store/States/cartItem.state';
import { PatientOrderData } from 'src/app/store/States/orderHistory';
import { SolutionProductsComponent } from '../solution-products/solution-products.component';
import { NeworderRegularPackComponent } from './neworder-regular-pack/neworder-regular-pack.component';
import { NeworderValuePackComponent } from './neworder-value-pack/neworder-value-pack.component';
declare module 'src/app/shared/model/common.mode' {
  interface DropdownOptions {
    valuePackNodeId?: number;
    isDTPEnabled?: boolean;
  }
}
declare module '@spartacus/core' {
  interface User {
    isValuePackEnabled?: boolean;
  }
}

@Component({
  selector: 'app-neworder-selectproduct',
  templateUrl: './neworder-selectproduct.component.html',
  styleUrls: ['./neworder-selectproduct.component.scss'],
})
export class NeworderSelectproductComponent implements OnInit, OnDestroy {
  patientID;
  patientName;
  radioPacktype = true;
  selectedValuePack: string;
  resetDropDown: boolean;
  private destroySubscriptions$ = new Subject<void>();
  selectedProductValue: Array<VisionCareAddtocartProductRequest> = [];
  selectedSolutionsValue: Array<Array<VisionCareAddtocartProductRequest>> = [[]];
  selectedContactLensValue: Array<Array<VisionCareAddtocartProductRequest>> = [[]];
  isValuePack: boolean;
  packSizeList: Observable<{ factor: string; value: string; selected?: boolean }[]>;
  valuePackSolutionsList = [];
  firstValuePackProduct: DropdownOptions;
  valuePackVariantTree: any;
  valuePackGroupOneChildProducts: any;
  selectedSolution: DropdownOptions;
  selectedValuePackSolution: DropdownOptions;
  disableAddLens = true;
  addLens = false;
  isDTP = false;
  private panelErrors: Array<{ baseProductCode: string; isDTPError: boolean; isMaxQuantityError: boolean }> = [];
  disableCheckbox = false;
  private SolutionsPanel: Array<SolutionProductsComponent> = [];
  searchResultCategories = [Category.CONTACT_LENSES, Category.CONTACT_LENS_SOLUTION, Category.DRY_EYE_PRODUCTS];

  @ViewChild(NeworderRegularPackComponent, { static: false }) newOrderRegularPack: NeworderRegularPackComponent;
  @ViewChild(NeworderValuePackComponent, { static: false }) newOrderValuePackRefrence: NeworderValuePackComponent;

  editSolutions = [];
  editlenses = [];
  replenishmentOrderCode: string;
  scheduledOrderList = [];
  editScheduledData = false;
  scheduledOrderData = {} as PatientOrderData;
  selectedOrderType: string;
  selectedScheduledOrderData: ScheduledOrderData;
  solutionsData = {} as { key: DropdownOptions[] };
  selectedDeliveryType: string;
  packValue;
  resetMaxQuantityError: string;
  isDTPError = false;
  isValuePackEnabled$: Observable<boolean> = this.userProfileService.get().pipe(
    filter((response: User) => !!response),
    map((response: User) => response.isValuePackEnabled)
  );
  get isMaxQuantityError(): boolean {
    const isError = !!this.panelErrors.find((errorPanel) => errorPanel.isMaxQuantityError);
    return isError;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    protected productSearchService: ProductSearchService,
    private cd: ChangeDetectorRef,
    private translation: TranslationService,
    private orderService: OrderHistoryService,
    private valuePackService: ValuePackService,
    private productDetailsService: ProductDetailsService,
    private userProfileService: UserProfileFacade
  ) {}

  ngOnInit(): void {
    this.patientID = this.activatedRoute.snapshot.data.newOrderPatientInfo.patientId;
    const editPaneldata = this.activatedRoute.snapshot.data.editScheduledOrdersInfo;
    if (editPaneldata) {
      this.packValue = editPaneldata?.entries[0]?.valuePackAttributes?.valuePackDuration;
      this.editScheduledData = true;
      this.selectedScheduledOrderData = {
        repeatOrderOption:
          editPaneldata.trigger.displayTimeTable.split(' ')[1] === 'week'
            ? RecurrencePeriod.weekly
            : RecurrencePeriod.monthly,
        repeatOrderValue: editPaneldata.trigger.displayTimeTable.split(' ')[0],
        defaultDate: new Date(editPaneldata.trigger.activationTime),
      };
      this.selectedOrderType = OrderType.Scheduled;
      this.selectedDeliveryType = DeliveryTypesMapping[editPaneldata.rootGroups[0].label];
      this.scheduledOrderList = this.orderService.createComponentData(
        [{ ...editPaneldata }],
        this.scheduledOrderData,
        this.scheduledOrderList,
        this.patientID
      );
      this.radioPacktype = !(this.scheduledOrderList[0].packType === 'Value Pack');
      this.isValuePack = this.scheduledOrderList[0].packType === 'Value Pack';
      this.scheduledOrderList[0].productDetails.forEach((val) => {
        if (val.specifications.length > 0) {
          this.editlenses.push(val);
        }
        if (val.isDryEye || val.isClc) {
          this.editSolutions.push(val);
        }
      });
    }

    this.packSizeList = forkJoin([
      this.translation.translate('selectProductPage.valuePackAttributes.threeMonthValuePack').pipe(take(1)),
      this.translation.translate('selectProductPage.valuePackAttributes.sixMonthValuePack').pipe(take(1)),
    ]).pipe(
      take(1),
      map(([threeMonthValuePack, sixMonthValuePack]) => {
        this.selectedValuePack =
          this.packValue?.toString() === '6' ? VALUE_PACK_TYPES.SIX_MONTH : VALUE_PACK_TYPES.THREE_MONTH;
        return [
          {
            factor: '1',
            value: threeMonthValuePack,
            selected: this.selectedValuePack === threeMonthValuePack,
          },
          {
            factor: '1',
            value: sixMonthValuePack,
            selected: this.selectedValuePack === sixMonthValuePack,
          },
        ];
      })
    );

    const { firstName, lastName } = this.activatedRoute.snapshot?.data.newOrderPatientInfo;
    this.patientName = lastName + ', ' + firstName;
    this.selectedContactLensValue[0] = [];
  }

  productSearchResult(val?): void {
    const query = ':relevance:allCategories:' + val;
    this.productSearchService.search(query, { pageSize: 10000 });
  }

  ngOnDestroy(): void {
    this.destroySubscriptions$.next();
    this.destroySubscriptions$.complete();
  }

  setToDefaults(): void {
    this.resetAdditionalControls();
    if (this.newOrderValuePackRefrence || this.newOrderRegularPack) {
      if (this.isValuePack) {
        this.newOrderValuePackRefrence.setToDefaults();
      } else {
        this.newOrderRegularPack.setToDefaults();
      }
    }
  }

  private resetAdditionalControls(): void {
    this.selectedProductValue = [];
    this.isDTPError = false;
    this.cd.detectChanges();
  }

  packTypeChanged(value): void {
    this.valuePackService.changeInValuePackPrice('0');
    this.isValuePack = !value;
    this.resetAdditionalControls();
    if (this.newOrderValuePackRefrence || this.newOrderRegularPack) {
      if (this.isValuePack) {
        this.newOrderValuePackRefrence.packTypeChanged(value);
      } else {
        this.newOrderRegularPack.packTypeChanged(value);
      }
    }
  }

  changePackSize(value): void {
    this.cd.detectChanges();
    if (this.newOrderValuePackRefrence) {
      this.newOrderValuePackRefrence.changePackSize(value);
    }
  }

  setOrderFlowChange(isdtp: boolean): void {
    this.isDTP = isdtp;
    this.productDetailsService.emitDTPFlag(this.isDTP);
    if (this.SolutionsPanel.length) {
      this.SolutionsPanel.forEach((panel) => {
        panel.isDTP = isdtp;
      });
    }
    this.cd.detectChanges();
  }

  isEditRightPanel(): void {
    if (this.newOrderValuePackRefrence) {
      this.newOrderValuePackRefrence.updateSelectedProductValue();
    }
    if (this.newOrderRegularPack) {
      this.newOrderRegularPack.updateSelectedProductValue();
    }
  }
  getUpdatedProductValues(product?): void {
    this.selectedProductValue = product;
  }
  getIsDTPErrorFlag(isDTPErrorFlag): void {
    this.isDTPError = isDTPErrorFlag;
    this.cd.detectChanges();
  }
  changedDeliveryType(): void {
    this.resetMaxQuantityError = Math.random().toString();
  }
}
