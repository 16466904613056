import { Injectable } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { DropdownOptions } from 'src/app/shared/model/common.mode';
declare module '@spartacus/core' {
  interface ContentSlotComponentData {
    question?: string;
    answers?: string;
    topic?: string;
  }
}

@Injectable({
  providedIn: 'root',
})
export class FaqService {
  searchQuestionsList: Array<DropdownOptions> = [];
  constructor(private cmsservice: CmsService) {}

  getFaqQuestion(): DropdownOptions[] {
    return this.searchQuestionsList;
  }

  setFaqQuestionList(faqinfo): void {
    const index = this.searchQuestionsList.findIndex((question) => question.uid === faqinfo.answers);
    if (index < 0) {
      this.searchQuestionsList.push({
        title: faqinfo.FAQContainer.topicName,
        value: faqinfo.question,
        text: faqinfo.question,
        uid: faqinfo.answers,
      });
    }
  }
}
