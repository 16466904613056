export const frFR = {
  contactlenses: {
    contactlenses: 'Lentilles de contact',
  },
  dailycontactlenses: {
    dailycontactlenses: 'Lentilles de contact journalières',
  },
  monthlycontactlenses: {
    monthlycontactlenses: 'Lentilles de contact mensuelles',
  },
  dryeyeproducts: {
    dryeyeproducts: 'Produits pour sécheresse oculaire',
  },
  contactlenssolutions: {
    contactlenssolutions: 'Solutions pour lentilles de contact',
  },
  contactlenssolution: {
    contactlenssolution: 'Solutions pour lentilles de contact',
  },
  multifocal: {
    multifocal: 'Multifocal',
  },
  colour: {
    colour: 'Couleur',
  },
  color: {
    color: 'Couleur',
  },
  spherical: {
    spherical: 'sphérique',
  },
  toric: {
    toric: 'Toriques',
  },

  common: {
    common: {
      home: 'Accueil',
    },
  },
  cms: {
    login: {
      backToLogin: 'Retour à la connexion',
      backToLoginPage: 'Retour à la page de connexion',
      emailAddress: 'Adresse e-mail',
      emailPlaceholer: 'Votre adresse e-mail',
      emailSentConfirmText: 'Un e-mail avec des instructions pour réinitialiser votre mot de passe a été envoyé.',
      forgotLink: 'Mot de passe oublié ?',
      forgotPwdText: 'Saisissez votre adresse e-mail et nous vous enverrons un lien pour modifier votre mot de passe :',
      logIn: 'connexion',
      passwordLbl: 'Mot de passe',
      requireEmail: 'Veuillez saisir une adresse e-mail valide',
      requirePassword: 'Veuillez saisir votre mot de passe',
      sendEmail: 'Envoyer',
      forgotYourPwd: 'Vous avez oublié votre mot de passe ?',
    },
    productListingPage: {
      moreFilter: 'Plus de filtres',
      lessFilter: 'Moins de filtres',
      product: 'produit',
      seeAll: 'Afficher tout',
      select: 'Sélectionnez',
      showMore: 'Afficher plus',
      showLess: 'Afficher moins',
      sortBy: 'Trier par',
      results: 'Résultats',
      products: 'produits',
      nameAZ: 'Nom AZ',
      price: 'Prix',
      oxygenPermeability: 'Perméabilité à l’oxygène',
      brand: 'Marque',
      categories: 'Catégories',
      showAll: 'Afficher tout',
      configureProducts: 'Configurer les produits',
      valuepack: 'Formats économiques',
    },
    productPage: {
      productDescription: 'Description du produit',
      productSpecification: 'Spécifications du produit',
      features: 'Caractéristiques',
      benefits: 'Avantages',
      downloads: 'Téléchargements :',
    },
    stockOrderProductListPage: {
      from: 'de',
    },
    productDetailsPage: {
      privateLabel: 'Marque distributeur',
      patientReference: 'Référence du patient :',
      parametersFor: 'Paramètres pour :',
      left: 'Gauche',
      right: 'Droit',
      baseCurve: 'Rayon de courbure :*',
      power: 'Puissance :*',
      add: 'Addition* :',
      colour: 'Couleur* :',
      cylinder: 'Cylindre* :',
      axis: 'Axe* :',
      sameForBothEye: 'Identique pour les deux yeux',
      packSize: 'Format :',
      practiceOrder: 'Commande de cabinet médical',
      individualOrders: 'Commandes individuelles',
      dtpError: 'Ce produit ne peut être livré qu’à un cabinet médical.',
      dtpSummaryError: 'Cette commande ne peut être livrée qu’à un cabinet médical.',
      addressError: 'Veuillez saisir une adresse valide',
      directToPatient: 'Directement au patient',
      errorMessage: 'Vous ne pouvez pas saisir plus de 18 caractères.',
      quantityErrorMsg: 'La quantité maximale de cet article qui peut être commandée est de',
      PurchaseStockOrder: 'Commande de stock',
      perEye: 'par œil',
    },
    practiceOrderPage: {
      patientReference: 'Référence du patient :',
      parametersFor: 'Paramètres pour :',
      left: 'Œil gauche',
      right: 'Œil droit',
      baseCurve: 'Rayon de courbure :',
      power: 'Puissance :*',
      add: 'Addition :*',
      colour: 'Couleur :*',
      cylinder: 'Cylindre :*',
      axis: 'Axe :*',
      sameForBothEye: 'Identique pour les deux yeux',
      packSize: 'Format :',
      practiceOrder: 'Commande de cabinet médical',
      individualOrders: 'Commandes individuelles',
      diameter: 'Diamètre :',
      typeOfWear: 'Type de port :',
      material: 'Matériau :',
      waterContent: 'Teneur en eau :',
      schedule: 'Programmer une commande récurrente',
      prefer: 'Vous préférez un format économique ?',
      errorMessage: 'Trop d’articles sélectionnés. Veuillez saisir une quantité ne dépassant pas la quantité maximale',
      LEFT: 'Œil gauche',
      RIGHT: 'Œil droit',
      update: 'METTRE À JOUR',
      cancel: 'Annuler',
      dtpLimitMessage: 'La quantité maximale pour une livraison directement au patient est de',
    },
    favoriteListPage: {
      yourFavProduct: 'Vos produits favoris',
      products: 'produits',
      product: 'produit',
      noFavProduct:
        'Vous n’avez actuellement aucun produit favori. Pour ajouter des produits à la liste de favoris, cliquez sur l’icône en forme de cœur à côté du produit',
    },
    legalAnnouncement: {
      accept: 'ACCEPTER',
      decline: 'REFUSER',
    },
    practicePage: {
      mostFrequentOrders: 'Commandes les plus fréquentes',
    },
    stockOrderPage: {
      configureProducts: 'Configurer le produit',
      packSize: 'Format :*',
      baseCurve: 'Rayon de courbure :',
      diameter: 'Diamètre :',
      color: 'Couleur :',
      add: 'Addition :',
      cancel: 'Annuler',
      lenscylinder: 'Cylindre de lentille :*',
      moreMinus: 'Plus de puissances négatives (-)',
      lessMinus: 'Moins de puissances négatives (-)',
      morePlus: 'Plus de puissances positives (+)',
      lessPlus: 'Moins de puissances positives (+)',
      clearAll: 'Effacer tout',
      addToCart: 'AJOUTER AU PANIER',
      addToCartLoading: 'AJOUT AU PANIER',
      addToCartSuccess: 'AJOUTÉ AU PANIER',
      updateToCartText: 'METTRE À JOUR',
      updatingCart: 'Mise à jour du panier',
      updateToCartSuccess: 'Panier mis à jour',
      maxQty: 'La quantité totale maximale pour ce produit est de',
      errorMessage: 'Trop d’articles sélectionnés. Veuillez saisir une quantité ne dépassant pas la quantité maximale',
      inputMessageError: 'La quantité doit être un nombre supérieur à 0',
      each: 'chaque',
      forthisbrand: 'pour cette marque',
    },
    miniCartPage: {
      PRACTICE_ORDER: 'Commandes de cabinet médical',
      INDIVIDUAL_ORDER: 'Commandes individuelles',
      VALUE_PACK: 'Format économique',
      DTP_VALUE_PACK: 'Format économique',
      DIRECT_TO_PATIENT: 'Directement au patient',
      STOCK_ORDER: 'Commande de stock',
      title: 'Votre panier',
      RIGHT: 'Droit',
      LEFT: 'Gauche',
      total: 'Total',
      delete: 'Delete (Supprimer)',
      gotocart: 'Accéder au panier',
      moreitem: 'plus d’articles',
      totalprice: '£00.00',
      yourcartIsEmpty: 'Votre panier est vide',
      goTo: 'Accéder à',
      productCatalogue: 'Catalogue de produits',
      orClick: 'ou cliquez sur',
      orClickthe: 'ou cliquez sur le',
      newOrder: 'Nouvelle commande',
      editOrder: 'Modifier commande',
      toAddItems: 'pour ajouter des articles',
      edit: 'Modifier',
      patientReference: 'Référence du patient',
      quantity: 'Quantité',
      YouHaveNoProductsInYourCart: 'Vous n’avez aucun produit dans votre panier',
      seeProduct: 'Afficher les produits',
      hideProduct: 'Masquer les produits',
      deliveryMethod: 'Mode de livraison :',
      poNumber: 'Numéro du BdC :',
      orderNumber: 'Numéro de commande :',
      LP: 'PWR',
      AX: 'AX',
      CY: 'CYL',
      Colour: 'Couleur ',
      DIA: 'DIA',
      BC: 'BC',
      ADD: 'ADD',
      PatientReference: 'Référence du patient',
      PowerShipped: 'puissances livrées',
      PowersInProgress: 'puissances en cours',
      cartMessage1: 'Vous n’avez ajouté aucun produit à votre panier.',
      cartMessage2: 'Utilisez l’une des méthodes ci-dessous pour ajouter des produits à votre commande. ',
      cartMessage:
        'Vous n’avez ajouté aucun produit à votre panier. Utilisez l’une des méthodes ci-dessous pour ajouter des produits à votre commande. ',
      valuePack: 'Format économique :',
      valuePacks: 'Formats économiques',
      valuePackDuration: 'Format économique (',
      valuePackMonth: '-mois)',
      moreitems: 'plus d’articles',
    },
    cartPagev2: {
      INDIVIDUAL_ORDER: 'Commandes individuelles',
      VALUE_PACK: 'Formats économiques',
      STOCK_ORDER: 'Commandes de stock',
      DIRECT_TO_PATIENT: 'Directement aux patients',
      orderAndPay: 'COMMANDER ET PAYER',
      simulation: {
        errorMessage:
          'Le prix final ne peut pas être calculé pour le moment. Une fois ce problème résolu, vous verrez les prix dans l’historique des commandes et les factures.',
      },
      disclaimer:
        'En cliquant sur COMMANDER ET PAYER, vous reconnaissez et acceptez 1) les conditions générales de MyAlcon que vous avez signées lorsque vous avez enregistré vos coordonnées pour utiliser ce système et 2) confirmez que, s’il s’agit d’une commande envoyée directement au patient, a) le patient a 16 ans ou plus, et b) vous avez informé le patient que ses informations seront partagées via MyAlcon, et avez obtenu les consentements nécessaires auprès du patient.',
      deliveryCost: 'Frais de livraison',
      taxes: 'Taxes',
      total: 'Total',
    },
    stockToricPage: {
      lensPower: 'Puissance de lentille',
      lensAxis: 'Axe de lentille',
      message: 'Sélectionnez la puissance pour voir l’axe de lentille adapté',
    },
    cartPage: {
      PRACTICE_ORDER: 'Commandes de cabinet médical',
      summary: 'Résumé',
      goToCheckout: 'PASSER À LA CAISSE',
      ShippingAddress: 'Adresse d’expédition',
      DeliveryMethod: 'Mode de livraison',
      calcFinalPrice: 'CALCULER LE PRIX FINAL',
      DIRECT_TO_PATIENT: 'Directement au patient',
      deliveryMethod: 'Mode de livraison :',
      deliveryMethodDetails: 'Livraison standard (2-3 jours ouvrables)',
      IndividualOrders: 'Commandes individuelles',
      StockOrders: 'Commandes de stock',
      ValuePacks: 'Formats économiques',
      DirecttoPatient: 'Directement aux patients',
      products: 'Produits',
      shippingsuggestion: 'Les frais de livraison seront calculés au moment du paiement.',
      patients: 'patients',
      externalOrderMsg: 'Le prix final apparaîtra sur la facture',
      cancelOrder: 'ANNULER LA COMMANDE',
    },
    orderConfirmation: {
      orderNumber: 'Numéro de commande :',
      shippingAddress: 'Adresse d’expédition :',
      deliveryMethod: 'Mode de livraison :',
      deliveryMethodDetails: 'Livraison standard par la poste',
      poNumber: 'Numéro du BdC :',
      valuePackQuantity: '1 article',
    },
    checkoutPage: {
      paymentMethod: 'Mode de paiement',
      paymentType1: 'Carte bancaire',
      paymentType2: 'Facturation de compte',
      valuePackQuantity: '1 article',
      poNumber: 'Numéro du BdC :',
      LP: 'Puissance',
      AX: 'Axe',
      CY: 'Cylindre',
      Colour: 'Couleur',
      DIA: 'DIA',
      BC: 'BC',
      ADD: 'ADD',
      print: 'Imprimer',
      finalPrice: {
        products: 'Produits',
        delivery: 'Livraison',
        taxes: 'Taxes',
        total: 'TOTAL',
        placeOrder: 'Passer commande',
        obligationText: 'avec obligation de paiement',
        estimatedDelivery: 'Délai de livraison estimé :',
        loaderMessage: 'Veuillez patienter quelques instants, nous collectons les données',
      },
      delivery: 'Livraison :',
      practiceOrders: 'Commandes de cabinet médical ',
      practiceOrdersPanel: 'Commandes individuelles, formats économiques, commandes de stock',
      directtoPatients: 'Directement aux patients ',
      seeAll: 'Afficher tout',
      patients: 'patients',
      addNewCardHeading: 'Ajouter une nouvelle carte',
      setDefaultCard: 'Définir cette carte comme carte par défaut',
      selectPaymentCard: 'Sélectionner une carte de paiement',
      selectCardtitle:
        'Sélectionnez une carte de paiement parmi les cartes enregistrées ou ajoutez une nouvelle carte.',
    },
    myPatientsPage: {
      addItems: '+ AJOUTER PATIENT',
      addNewPatient: {
        heading: 'Ajouter un nouveau patient',
        editHeading: 'Modifier les informations du patient',
        personalData: 'Données personnelles',
        mainAddress: 'Adresse principale',
        additionalAddress: 'Adresse supplémentaire',
        form: {
          title: 'Titre',
          firstName: 'Prénom*',
          surName: 'Nom*',
          email: 'Adresse e-mail',
          phoneNumber: 'Numéro de téléphone',
          referenceNumber: 'Numéro de référence',
          address: {
            street: 'Rue et numéro *',
            building: 'Bâtiment/appartement/autre',
            postcode: 'Code postal *',
            city: 'Ville *',
            county: 'État',
            notMandatoryCounty: 'État',
            country: 'Pays *',
          },
          mandatoryFields: '* Champs obligatoires',
          additionalAddress: 'Ajouter une adresse supplémentaire',
          deleteadditionaladdress: 'Supprimer une adresse supplémentaire',
          clearAll: 'Effacer tout',
          save: 'enregistrer',
          cancel: 'Annuler',
          update: 'Mettre à jour',
          errorMessages: {
            title: 'Veuillez saisir un titre.',
            mandatoryField: 'Ce champ est obligatoire.',
            firstName: `Veuillez saisir le prénom du patient.`,
            surName: `Veuillez saisir le nom du patient.`,
            email: 'Le format de l’e-mail est incorrect. Veuillez utiliser le format suivant : john@gmail.com',
            phoneNumber: 'Vous ne pouvez pas utiliser de lettres dans ce champ.',
            phoneNumberMinLength:
              'Le numéro de téléphone est trop court. Veuillez vérifier que vous avez saisi tous les caractères.',
            moreThanThirtyCharacters: 'Vous ne pouvez pas saisir plus de 30 caractères dans ce champ.',
            street: `Veuillez saisir l’adresse postale du patient.`,
            buildingNumberName: `Veuillez saisir le numéro du bâtiment du patient.`,
            postcode: `Veuillez saisir le code postal du patient.`,
            city: `Veuillez saisir la ville du patient.`,
            county: `Veuillez saisir l’État du patient.`,
            country: `Veuillez saisir le pays du patient.`,
            invalidPattren: 'Code postal erroné',
            selectCountry: 'Veuillez sélectionner un pays',
          },
        },
        patientAddedSuccessfully: 'Le patient a bien été ajouté',
      },
      address: 'Adresse',
      showMore: 'Afficher plus',
      showLess: 'Afficher moins',
      mostRecentOrders: 'Plus de commandes récentes',
      order: 'Commande',
      delivery: 'Livraison',
      shippingAddress: 'Adresse d’expédition',
      status: 'Statut',
      packtype: 'Type de format',
      date: 'Date',
      frequency: 'Fréquence',
      addOrder: 'Ajouter une commande',
      addOrderButton: '+ AJOUTER UNE COMMANDE',
      options: 'Options',
      active: 'actif',
      inactive: 'inactif',
      switchtoSubscription: 'Passer à un abonnement',
      sort: 'Trier :',
      orderHistory: 'Historique des commandes',
      editPatient: 'Modifier patient',
      deletePatient: 'Supprimer patient',
      search: 'Rechercher le nom du patient, n° de référence',
      noPatientFound:
        'Vous n’avez pas encore de patients. Ajoutez votre premier patient en cliquant sur le bouton ci-dessous.',
      searchNoPatient: 'Aucun patient trouvé. Vérifiez votre saisie.',
      top: 'Haut',
    },
    patientProfilePage: {
      personalData: 'Données personnelles',
      mainAddress: 'Adresse principale',
      additionalAddress: 'Adresse supplémentaire',
      edit: 'Modifier',
      tel: 'Tél. : ',
      eMail: 'E-mail : ',
      refNumber: 'N° de référence : ',
      addOrder: '+ AJOUTER UNE COMMANDE',
      noOrder: 'Il n’y a pas encore de commande',
    },
    orderHistoryPage: {
      orderDate: 'Date de la commande ',
      orderPlaced: 'Commande passée',
      orderNo: 'N° commande',
      type: 'Type',
      totalPrice: 'Prix total',
      status: 'Statut',
      invoice: 'Facture',
      search: 'Rechercher un numéro de commande ou de BdC',
      searchButton: 'rechercher',
      timePeriod: 'Durée :',
      dateRange: 'Plage de dates :',
      selectDate: 'Sélectionnez la ou les dates',
      sort: 'Trier :',
      reorder: 'COMMANDER À NOUVEAU',
      viewOrderdetails: 'Afficher les informations de la commande',
      noOrders: 'Vous n’avez pas encore commandé de produits.',
      noOrderText:
        'Lorsque vous soumettrez une commande, vous pourrez voir le statut et les informations de votre commande ici.',
      filter: {
        ALL: 'Tous',
        ACTIVE: 'Actif',
        COMPLETED: 'Terminé',
        STATUS: 'Statut',
        filterPlus: '+ de filtres',
        filterMinus: '- de filtres',
        orderStatus: {
          NEW: 'Nouveau',
          IN_PROGRESS: 'En cours',
          DELAYED: 'En retard',
          SHIPPED: 'Livré',
          CANCELLED: 'Annulé',
          PARTIALLY_SHIPPED: 'Livré partiellement',
          DELIVERED: 'Remis',
          PARTIAL_BACKORDER: 'Livré partiellement',
          BACKORDER: 'En retard',
          BILLED: 'Facturé',
        },
      },
      showMore: 'AFFICHER 10 DE PLUS SUR',
      noResults:
        'Aucune commande n’a été trouvée. Vérifiez que vous avez saisi le bon numéro ou essayez avec une autre commande.',
      noResultsFilter: 'Aucune commande correspondant aux critères de filtrage sélectionnés n’a été trouvée',
      cancel: 'ANNULER',
    },
    baseLoginPage: {
      youWereLoggedOut: 'Vous avez été déconnecté',
      logoutMsg:
        'Votre session a expiré. Pour votre sécurité et celle de vos patients, vous avez été déconnecté automatiquement.',
      welcome: 'Bienvenue',
      backToLogin: 'Retour à la page de connexion',
    },
    sxRegistration: {
      consentLinks: {
        termsCondition: 'https://www.au.alcon.com/terms-use',
        privacyPolicy: 'https://www.au.alcon.com/privacy-policy',
        marketingPrivacyPolicy: 'https://www.au.alcon.com/privacy-policy',
      },
    },
    orderStatus: {
      NEW: 'Nouveau',
      IN_PROGRESS: 'En cours',
      DELAYED: 'En retard',
      SHIPPED: 'Livré',
      CANCELLED: 'Annulé',
      PARTIALLY_SHIPPED: 'Livré partiellement',
      PENDING_CONSOLIDATION: 'En attente de regroupement de commandes',
      DELIVERED: 'Remis',
      NOT_SHOWN: 'Non_affiché',
      BILLED: 'Facturé',
      PARTIAL_BACKORDER: 'Livré partiellement',
      BACKORDER: 'En retard',
    },
    confirmEmail: {
      success: 'Adresse e-mail confirmée. Notre représentant commercial vous contactera très prochainement.',
      failure: 'Lien expiré. Veuillez remplir à nouveau le formulaire d’inscription.',
    },
    registerPage: {
      registrationFormTitle:
        'Créez un compte pour pouvoir accéder 24 h/24 et 7 j/7 aux commandes en ligne, aux factures, aux relevés, au suivi de commande et à d’autres fonctionnalités de gestion de compte.',
      imAlconCustomer: 'Je suis client Alcon',
      iDoNotHaveAnAccountTitle: 'Je n’ai pas encore de compte Alcon',
      iDoNotHaveAnAccountDescription: `Aucun problème ! Vous pouvez nous laisser vos coordonnées et un représentant commercial Alcon vous contactera très prochainement !`,
      termsAndConditions: 'Conditions générales',
      consent: 'Consentement',
      termsAndConditionTitle: 'Enregistrement sur le site de commerce électronique Alcon',
      termsAndConditionLabel: `J’ai lu et j’accepte les conditions énoncées dans les <a src="#">Conditions générales de vente d’Alcon Eye Care UK Limited.</a>*`,
      termsAndConditionDisclaimer: `Alcon traitera toujours vos données à caractère personnel conformément à l’ensemble des lois applicables. </br></br>Alcon fait partie du groupe Alcon, un groupe international qui possède des bases de données dans différents pays, dont certaines sont exploitées par la filiale Alcon locale, et d’autres par des tiers pour le compte de la filiale Alcon locale. Alcon est susceptible de transférer vos données vers les bases de données de l’une ou plusieurs de ses sociétés affiliées, y compris vers des pays qui n’offrent peut-être pas un niveau de protection de vos données à caractère personnel équivalent à celui offert au Royaume-Uni et dans l’UE.</br></br>Alcon ne vendra pas vos données à caractère personnel à quelque fin que ce soit.</br></br>Si vous souhaitez nous contacter concernant notre utilisation de vos données à caractère personnel, pour modifier vos préférences ou vous opposer au traitement de vos données à caractère personnel, envoyez-nous un e-mail à privacy.uk@alcon.com.`,
      setUpAnAccount: 'Créer un compte',
      title: 'Titre',
      firstName: 'Prénom*',
      lastName: 'Nom de famille*',
      fullCompanyName: 'Nom complet de la société*',
      email: 'Adresse e-mail pour le compte MyAlcon Store*',
      accountNumber: 'Numéro de compte Alcon *',
      next: 'Next (Suivant)',
      back: 'Retour',
      cancel: 'Annuler',
      submit: 'Envoyer',
      mandatory: '* Champs obligatoires',
      successText: `Une copie de ce formulaire sera envoyée à votre boîte de réception.</br></br>Notez que la création d’un nouveau compte peut prendre jusqu’à trois jours ouvrables à compter de la date à laquelle nous avons reçu le formulaire rempli contenant un numéro GOC/GMC valide.`,
      successTitle: 'Veuillez remplir ce formulaire de demande pour créer un compte chez Alcon Eye Care UK Limited.',
      goToLoginPage: 'Accéder à la page de connexion',
      errorMessages: {
        mandatoryField: 'Ce champ est obligatoire.',
        maxLengthReached: 'Vous ne pouvez pas saisir plus de {{characterNumber}} caractères dans ce champ.',
        couldContainsOnlyNumbers: 'Vous ne pouvez pas utiliser de lettres dans ce champ.',
        firstName: 'Veuillez renseigner le champ Prénom',
        lastName: 'Veuillez renseigner le champ Nom de famille',
        fullCompanyName: 'Veuillez renseigner le champ Nom complet de la société',
        email: 'Veuillez renseigner le champ Adresse e-mail',
        emailPattern: 'Le format de l’e-mail est incorrect. Veuillez utiliser le format suivant : john@gmail.com',
        accountNumber: 'Veuillez renseigner le champ Numéro de compte Alcon',
        termsAndConditions: 'Veuillez accepter les champs obligatoires',
        responseError:
          'Un problème est survenu lors de la soumission de votre demande. Veuillez réessayer ou contacter le service clientèle',
      },
    },
    contactusPage: {
      pleaseSelectATopic: 'Veuillez choisir un sujet :*',
      typeYourMessageHere: 'Saisissez votre message ici :*',
      contactMethod: 'Veuillez sélectionner votre moyen de contact préféré pour cette demande :*',
      contactMethodLabel1: 'E-mail',
      contactMethodLabel2: 'Téléphone',
      emailAddress: 'Adresse e-mail',
      emailInfo:
        'Les informations saisies ci-dessus ne modifient pas les coordonnées associées à votre compte. Vous pouvez modifier votre numéro de téléphone dans My Profile (Mon profil) ou contacter le service clientèle pour modifier votre adresse e-mail.',
      termsConditions: `Alcon utilisera ces informations pour répondre à votre demande. J’ai lu la Politique de confidentialité d’Alcon et j’en accepte les conditions.*`,
      preferredTime: 'Veuillez sélectionner votre heure préférée :*',
      preferredTimeLabel1: 'Matin',
      preferredTimeLabel2: 'Après-midi',
      contactName: 'Veuillez saisir un nom de contact :*',
      contactNameLabel: ' Nom du contact',
      fullCompanyName: 'Nom complet de la société',
      returnToLastVisitedPage: 'Retourner à la dernière page consultée',
      mandatoryFields: '* Champs obligatoires',
      provideContactName: 'Veuillez saisir un nom de contact :*',
      contactusSubmit: 'Envoyer',
      phoneNumber: 'Numéro de téléphone',
      characters: '1 000 caractères',
      thankyoumsg: 'Merci pour votre soumission',
      additionalmsg: 'Un représentant Alcon vous recontactera à ce sujet sous 1 jour ouvrable.',
      returntoHome: 'Retourner à la page d’accueil',
      returnProdct: 'Retourner produit',
      gotoform: 'Accéder au formulaire',
      selectFile: 'Sélectionner fichier',
      errorMessages: {
        topic: 'Veuillez choisir un sujet.',
        mandatoryField: 'Ce champ est obligatoire.',
        message: `Veuillez saisir le message.`,
        contactmethod: 'Veuillez sélectionner au moins un moyen de contact',
        email: 'Veuillez saisir l’e-mail',
        emailPattren: 'Le format de l’e-mail est incorrect. Veuillez utiliser le format suivant : john@gmail.com',
        phoneNumber: `Veuillez saisir le numéro de téléphone.`,
        phoneNumberCharecters: 'Vous ne pouvez pas utiliser de lettres dans ce champ.',
        phoneNumberMinLength:
          'Le numéro de téléphone est trop court. Veuillez vérifier que vous avez saisi tous les caractères.',
        preferredTime: 'Veuillez saisir une heure de contact préférée',
        contactName: 'Veuillez saisir le nom du contact',
        termsConditions: 'Veuillez cocher les termes légaux',
        contactNamePattern: 'Vous ne pouvez pas saisir plus de 30 caractères dans ce champ',
      },
    },
    selectProductPage: {
      selectPackType: 'Sélectionnez le type de format',
      selectProducts: 'Sélectionner des produits',
      regular: 'Régulier',
      valuePack: 'Format économique',
      chooseLenses: 'Choisir des lentilles :*',
      patientName: 'Patient : ',
      valuePacktooltip: 'Certaines lentilles de contact et solutions sont proposées sous forme de lot.',
      additionalProducts: 'Choisir des produits supplémentaires :',
      contactLens: 'Solution pour lentilles de contact',
      dryEye: 'Produits pour sécheresse oculaire',
      addAnotherproduct: 'Ajouter un autre produit',
      mandatory: '* Champs obligatoires',
      addOtherLenses: 'AJOUTER D’AUTRES LENTILLES',
      valuePackAttributes: {
        threeMonthValuePack: 'Format 3 mois',
        sixMonthValuePack: 'Format 6 mois',
        chooseSolution: 'Choisir une solution :*',
      },
      patientOrderPanel: {
        delivery: '3. Sélectionner la livraison',
        practiceOrder: 'Commande de cabinet médical',
        directToPatient: 'Directement au patient',
        selectOrderType: 'Sélectionner le type de commande',
        individual: 'Individuelle',
        total: 'Total :',
        addToCart: 'AJOUTER AU PANIER',
        addToCartSuccess: 'AJOUTÉ AU PANIER',
        cancel: 'Annuler',
        address: 'Adresse :',
        delivertodiffAddress: 'Livrer à une autre adresse',
        selectdeliveryAddress: 'Sélectionner l’adresse de livraison :',
        dropdown: {
          buttonText: 'Ajouter patient',
        },
        scheduled: {
          orderType: 'Programmé',
          repeatEveryText: 'Renouveler chaque :',
          week: 'semaine',
          month: 'mois',
          startDate: 'Date de début :',
          save: 'Enregistrer',
          startSubscriptions: 'DÉMARRER ABONNEMENTS',
          addToCartNow: 'AJOUTER AU PANIER MAINTENANT',
        },
        title: `L’ajout au panier a échoué`,
        description: `Les articles n’ont pas été ajoutés au panier. <br> Veuillez résoudre le problème puis réessayer.`,
        redirectButton: 'OK',
      },
      tooltipText: `Vous ne pouvez pas sélectionner un 2e produit si les deux yeux sont sélectionnés. Si vous voulez ajouter un autre produit pour le même patient, décochez un œil.`,
    },
    safeOrderingPage: {
      safeOrdering: 'Commande sécurisée ',
    },
    configureProducts: {
      deliveryPanel: {
        delivery: 'Livraison',
        selectDelivery: 'Sélectionner la livraison',
        practiceOrder: 'Commande de cabinet médical',
        directToPatient: 'Directement au patient',
        patientReferencePlaceholder: 'Saisir la référence',
        patientReference: 'Référence du patient :',
        patientReferenceMandatory: 'Référence du patient :*',
        needStockOrder: 'Besoin de commander du stock ?',
        practiceErrorMessage: 'Vous ne pouvez pas saisir plus de 30 caractères.',
        noResultsFound: 'Aucun résultat n’a été trouvé',
        selectFromList: 'Sélectionner dans la liste',
        deliveryToDifferentAddress: 'Livraison à une autre adresse',
      },
    },
    orderDetailsPage: {
      orderNo: 'N° commande',
      shipped: 'Livré',
      inProgess: 'En cours',
      delivered: 'Remis',
      billOnly: 'Facture uniquement',
      products: 'Produits',
      ACCOUNT: 'Facturation de compte',
      CARD: 'Carte bancaire',
      statusPanel: {
        partiallyShipped: 'Livré partiellement',
        trackingno: 'N° de suivi :',
      },
      paymentMethodPanel: {
        orderPlaced: 'Commande passée :',
        orderDate: 'Date de la commande :',
        orderSource: 'Source de la commande :',
        paymentMethod: 'Mode de paiement :',
        cardEndingWith: 'Carte se terminant par ****',
        invoiceno: 'N° de facture',
        addNewCard: 'Ajouter une nouvelle carte',
        noCreditCard: 'Vous n’avez aucune carte de paiement liée à ce compte.',
        Change: 'Changement',
        useOneTime: 'UTILISER LA CARTE UNE SEULE FOIS',
        useAndSaveLater: 'UTILISER LA CARTE ET L’ENREGISTRER POUR PLUS TARD',
        useSelectedCard: 'UTILISER LA CARTE SÉLECTIONNÉE',
        useAndSaveDefault: 'UTILISER LA CARTE ET L’ENREGISTRER COMME CARTE PAR DÉFAUT',
        useNewCard: 'UTILISER UNE NOUVELLE CARTE',
      },
      deliveryMethodPanel: {
        practiceOrders: 'Commandes de cabinet médical',
        deliveryMethod: 'Mode de livraison :',
        shippingAddress: 'Adresse d’expédition :',
        poNumber: 'Numéro du BdC :',
        DTPOrders: 'Directement au patient',
      },
      finalPrice: {
        reOrder: 'commander à nouveau',
      },
      orderItemPage: {
        INDIVIDUAL_ORDER: 'Commandes individuelles',
        STOCK_ORDER: 'Commande de stock',
        VALUE_PACK: 'Format économique',
        total: 'Total',
        item: '{{count}} article',
        item_plural: '{{count}} articles',
        materialNumber: 'N° article',
        serialNumber: 'N° de série',
        IOL_Bill: 'Facture IOL',
        diopter: 'Dioptrie',
        estDeliveryDate: 'Date de livraison estimée',
        deliveryDate: 'Date de livraison',
        'labelstatus_In transit': 'En transit :',
        'labelstatus_In delivery': 'En livraison :',
        labelstatus_delivered: 'Remis :',
        labelstatus_undefined: '',
        fromitem: '{{othercount}} sur {{count}} articles',
        tooltip: '{{othercount}} sur {{count}} articles sont {{status}}. Les articles restants ont différents statuts',
      },
    },
    selectDeliveryAddressPage: {
      mainaddress: 'Adresse principale',
      additonaladdress: 'Adresse supplémentaire',
      edit: 'Modifier',
      cancel: 'Annuler',
      save: 'Enregistrer',
      mandatory: '* Champs obligatoires',
    },
    patientAddressPage: {
      heading: 'Adresse :',
      form: {
        address: {
          street: 'Rue et numéro *',
          building: 'Bâtiment/appartement/autre',
          postcode: 'Code postal *',
          city: 'Ville *',
          county: 'État',
          notMandatoryCounty: 'État',
          country: 'Pays *',
        },
        mandatoryFields: '* Champs obligatoires',
        additionalAddress: 'Ajouter une adresse supplémentaire',
        clearAll: 'Effacer tout',
        save: 'enregistrer',
        cancel: 'Annuler',
        update: 'Mettre à jour',
        deleteadditionaladdress: 'Supprimer une adresse supplémentaire',
        errorMessages: {
          mandatoryField: 'Ce champ est obligatoire.',
          street: `Veuillez saisir l’adresse postale du patient.`,
          buildingNumberName: `Veuillez saisir le numéro du bâtiment du patient.`,
          postcode: `Veuillez saisir le code postal du patient.`,
          city: `Veuillez saisir la ville du patient.`,
          county: `Veuillez saisir l’État du patient.`,
          country: `Veuillez saisir le pays du patient.`,
          invalidPattren: 'Code postal erroné',
          selectCountry: 'Veuillez sélectionner un pays',
        },
      },
    },
    recentOrders: {
      lastOrders: 'Précédentes commandes',
      latestOrders: 'Dernières commandes',
      seeMore: 'Consultez plus de commandes dans l’historique des commandes',
      noOrders: `Vous n’avez aucune commande.`,
      noOrderText: 'Lorsque vous soumettrez une commande, vous pourrez voir le statut de votre commande ici. ',
      noOrdersSX: `Vous n’avez pas encore commandé de produits. Lorsque vous soumettrez la commande, vous pourrez voir le statut de votre commande ici.`,
    },
    myProfilePage: {
      emailAddress: 'Adresse e-mail :',
      phoneNumber: 'Numéro de téléphone :',
      password: 'Mot de passe',
      changepassword: 'Changer de mot de passe',
      edit: 'Modifier',
    },
    contactInformation: {
      title: 'Titre',
      firstName: 'Prénom*',
      lastName: 'Nom de famille*',
      email: 'Adresse e-mail',
      phonenumber: 'Numéro de téléphone',
      cancel: 'Annuler',
      save: 'Enregistrer',
      errorMessages: {
        requireFirstName: 'Veuillez renseigner le champ Prénom',
        moreThanThirtyCharacters: 'Vous ne pouvez pas saisir plus de 30 caractères dans ce champ.',
        requireLastName: 'Veuillez renseigner le champ Nom de famille',
        phoneNumber: 'Vous ne pouvez pas utiliser de lettres dans ce champ.',
        phoneNumberMinLength:
          'Le numéro de téléphone est trop court. Veuillez vérifier que vous avez saisi tous les caractères.',
      },
    },
    changePassword: {
      currentpassword: 'Mot de passe actuel*',
      newpassword: 'Nouveau mot de passe*',
      reenterpassword: 'Saisir à nouveau le nouveau mot de passe*',
      mandatory: '* Champs obligatoires',
      showpassword: 'Afficher le mot de passe',
      cancel: 'Annuler',
      save: 'Enregistrer',
      suggestion: 'Utilisez au moins 8 caractères en mélangeant des lettres, des chiffres et des symboles',
      errorMessages: {
        requireCurrentpassword: 'Veuillez saisir le mot de passe actuel',
        requireNewPassword: 'Veuillez saisir le nouveau mot de passe',
        requireReEnterpassword: 'Veuillez saisir à nouveau le nouveau mot de passe',
        match: `Vos mots de passe ne correspondent pas. Essayez à nouveau.`,
        strong:
          'Veuillez choisir un mot de passe plus fort. Essayez de mélanger des lettres, des chiffres et des symboles.',
        maxCharcters: 'Utilisez au moins 8 caractères pour votre mot de passe.',
      },
    },
    patientOrderHistory: {
      io: 'Commandes individuelles ',
      subscription: 'Abonnements ',
      switchSub: 'Passer à un abonnement',
      nof: 'Aucune commande n’a été trouvée dans la période spécifiée',
      orderNo: 'N° commande',
      mainAddress: 'Adresse principale',
      addAddress: 'Adresse supplémentaire',
      orderPlaced: 'Commande passée :',
      orderSent: 'Commande envoyée :',
      nextOrder: 'Prochaine commande :',
      subCreated: 'Abonnement créé :',
      every: 'Tous les',
      active: 'ACTIF',
      inactive: 'INACTIF',
      noOrdersYet: 'Pas encore de commande',
      oneTime: 'Une fois',
      frequency: 'Fréquence',
    },
    accountDetailsPage: {
      requestChangeAddress: 'Demander un changement d’adresse',
      shippingAddress: 'Adresse d’expédition',
      billingAddress: 'Adresse de facturation',
      orderconsolidationenabled: 'Regroupement de commandes activé',
      alconAccount: 'de compte Alcon',
      shippingAccount: 'Shipping account',
    },
    commonTitles: {
      edit: 'Modifier',
      delete: 'Delete (Supprimer)',
    },
    visioncareSearch: {
      searchFor: 'Rechercher :',
    },
    subscriptions: {
      nosubscriptions: `Vous n’avez aucun abonnement`,
      subscriptions: 'Abonnements',
      allPatients: 'Tous les patients',
      subscriptionMsg:
        'Les abonnements sont des commandes programmées qui sont livrées directement aux patients ou à un cabinet médical.',
    },
    returnForm: {
      noProducts: 'Il n’y a aucun produit à retourner',
      searchProducts: 'Recherchez un produit ou sélectionnez-en un dans la liste',
      selectProductHeader: 'Sélectionnez le produit que vous souhaitez retourner :',
      addAnotherProduct: 'Ajoutez un autre produit à retourner :',
      baseCurve: 'Rayon de courbure :*',
      power: 'Puissance :*',
      add: 'Addition* :',
      colour: 'Couleur* :',
      cylinder: 'Cylindre* :',
      axis: 'Axe* :',
      packSize: 'Format :',
      diameter: 'Diamètre :',
      ordernumber: 'N° commande :*',
      quantity: 'Quantité :*',
      mandatory: '* Champs obligatoires',
      delete: 'Supprimer produit',
      save: 'SAUVEGARDER PRODUIT',
      returnReason: 'Motif du retour',
      dateoforder: 'Date de commande :',
      reasonMandatory: 'Veuillez indiquer le motif du retour des produits *',
      selectreason: 'Sélectionner motif',
      quantityError:
        'Vous ne pouvez pas choisir plus de produits d’un type donné qui a été commandé. Si vous êtes sûr de la quantité de produits, vérifiez le numéro de commande',
      orderNumberError:
        'Ce numéro de commande a déjà été utilisé dans votre formulaire de retour. Modifiez votre numéro de commande ou supprimez cet article',
      returnproduct: 'RETOURNER PRODUIT',
      specifications: {
        baseCurve: 'Rayon de courbure :',
        power: 'Puissance :',
        add: 'Addition :',
        colour: 'Couleur :',
        cylinder: 'Cylindre :',
        axis: 'Axe :',
        packSize: 'Format :',
        diameter: 'Diamètre :',
        ordernumber: 'N° commande :',
        quantity: 'Quantité :',
      },
    },
    statements: {
      statementForDownload: 'Mentions d’information à télécharger',
      tooltipText: 'La première mention d’information sera disponible au début du mois prochain.',
      downloadText: 'Télécharger une mention d’information (PDF)',
    },
    reOrderValidation: {
      heading: 'Un problème est survenu lors de votre renouvellement de commande',
      ok: 'OK',
      valuePackQauntity: '1 article',
      itemDiscontinued: 'Veuillez contacter le service clientèle',
      maxLimit:
        'Veuillez valider votre panier actuel avant de commander à nouveau des produits ou de reconfigurer votre commande',
      patientDeleted: 'Le patient a été supprimé et ne peut plus être sélectionné pour passer commande',
      valuPackexist: 'Veuillez valider votre panier actuel avant de commander à nouveau des produits',
      GENERIC_API_ERRORMESSAGE:
        'Les articles n’ont pas été ajoutés au panier. Veuillez réessayer ultérieurement ou contacter le service clientèle',
      DIRECT_TO_PATIENT_MESSAGE: `Les articles ont été ajoutés au panier pour l’adresse principale du patient. Vérifiez l’adresse dans le panier`,
      GENERIC_MESSAGE: 'Les articles ont été ajoutés au panier',
    },
  },
};
