import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OccEndpointsService, UserIdService } from '@spartacus/core';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { PatientProfileData } from 'src/app/shared/model/patient.model';
import { DeletePatient, LoadOrderFlag, LoadPatient } from 'src/app/store/Actions/patient.action';
@Injectable({
  providedIn: 'root',
})
// TODO : Handle error scenarios using global error service
export class PatientListService {
  private userId: string;
  patientProfileSearch = new Subject<string>();
  patientSearchSubject = this.patientProfileSearch.asObservable();
  orderFiltersSubject = new Subject<any>();
  patientorderFilters = this.orderFiltersSubject.asObservable();
  clearFiltersSubject = new Subject<string>();
  ClearFilters = this.clearFiltersSubject.asObservable();
  constructor(
    private http: HttpClient,
    private userIdService: UserIdService,
    private occEndPoints: OccEndpointsService,
    private store: Store
  ) {}

  getPatientsList(ordersRequired: boolean = false): Observable<PatientProfileData[]> {
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap((userId) => {
        const url = this.occEndPoints.buildUrl(`users/${userId}/patients/patientList?ordersRequired=${ordersRequired}`);
        return this.http.get<any>(url).pipe(
          tap((patientData) => {
            this.store.dispatch(LoadPatient({ patients: patientData.patients }));
            this.store.dispatch(LoadOrderFlag({ fetchPatientOrders: ordersRequired }));
          }),
          map((patientData: any) => {
            return patientData.patients;
          })
        );
      })
    );
  }

  sortBy(list, event): void {
    switch (event.value) {
      case 'Name_Desc': {
        list?.sort((val1, val2) => 0 - (val1.lastName.toLowerCase() < val2.lastName.toLowerCase() ? -1 : 1));
        break;
      }
      default: {
        list?.sort((val1, val2) => 0 - (val1.lastName.toLowerCase() > val2.lastName.toLowerCase() ? -1 : 1));
        break;
      }
    }
    return list;
  }

  filterPatientList(searchParam): void {
    this.patientProfileSearch.next(searchParam);
  }

  /**
   * Performs delete operation
   * Validates patient order against cart
   * Dispatch action to store
   * @param patientId holds patiend Id
   */
  deletePatientFromList(patientId): Observable<any> {
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap((userId) => {
        const url = this.occEndPoints.buildUrl(`/users/${userId}/patients/deletePatient/${patientId}`);
        return this.http.post<any>(url, {}).pipe(
          tap(() => {
            this.store.dispatch(DeletePatient({ patientId }));
          })
        );
      })
    );
  }
}
