export const ptBR = {
  contactlenses: {
    contactlenses: 'Lentes de Contato',
  },
  dailycontactlenses: {
    dailycontactlenses: 'Lentes de Contato Diárias',
  },
  fortnightlycontactlenses: {
    fortnightlycontactlenses: 'Lentes de contato quinzenais',
  },
  monthlycontactlenses: {
    monthlycontactlenses: 'Lentes de Contato Mensais',
  },
  dryeyeproducts: {
    dryeyeproducts: 'Produtos para Olho Seco',
  },
  contactlenssolutions: {
    contactlenssolutions: 'Solução para Lentes de Contato',
  },
  contactlenssolution: {
    contactlenssolution: 'Solução para Lentes de Contato',
  },
  multifocal: {
    multifocal: 'Multifocal',
  },
  colour: {
    colour: 'Colorida',
  },
  color: {
    color: 'Colorida',
  },
  spherical: {
    spherical: 'Esférica',
  },
  toric: {
    toric: 'Tórica',
  },
  directtopatient: {
    directtopatient: 'Direto ao paciente',
  },
  practiceorder: {
    practiceorder: 'Pedido do profissional',
  },
  practice: {
    practice: 'profissional',
  },
  stockorder: {
    stockorder: 'Pedido em estoque',
  },
  regular: {
    regular: 'Regular',
  },
  individualorders: {
    individualorders: 'Pedidos individuais',
  },
  stockorders: {
    stockorders: 'Pedidos em estoque',
  },
  directtopatients: {
    directtopatients: 'Direto ao paciente',
  },
  directpurchase: {
    directpurchase: 'Compra Direta',
  },
  packsize: {
    packsize: 'Tamanho do pacote',
  },
  diameter: {
    diameter: 'Diâmetro',
  },
  lenscylinder: {
    lenscylinder: 'Cilíndro da lente',
  },
  lensaxis: {
    lensaxis: 'Eixos das lentes',
  },

  common: {
    formErrors: {
      required: 'Campo obrigatórios',
    },
    common: {
      home: 'Página Inicial',
    },
    skipLink: {
      skipTo: 'Pular',
      labels: {
        header: 'Cabeçalho',
        main: 'Conteúdo Principal',
        footer: 'Rodapé',
        productFacets: 'Product Facets',
        productList: 'Lista de produtos',
      },
    },
    pageMetaResolver: {
      category: {
        title: '{{count}} resultados para {{query}}',
        title_plural: '{{count}} resultados para {{query}}',
      },
      checkout: {
        title: 'Check-out',
      },
      search: {
        title: '{{count}} resultados para "{{query}}"',
        title_plural: '{{count}} resultados para "{{query}}"',
        findProductTitle: '{{count}} resultados para cupons "{{coupon}}"',
        findProductTitle_plural: '{{count}} resultados para cupons "{{coupon}}"',
        default_title: 'Todos os produtos',
      },
      product: {
        description: '{{description}}',
        heading: '{{heading}}',
        title: '{{title}}',
      },
    },
  },
  cms: {
    login: {
      backToLogin: 'Voltar ao Login',
      backToLoginPage: 'Voltar a página de login',
      emailAddress: 'Endereço de e-mail',
      emailPlaceholer: 'Seu endereço de e-mail',
      emailSentConfirmText: 'Um e-mail foi enviado com as instruções para redefinir sua senha.',
      forgotLink: 'Esqueceu sua senha?',
      forgotPwdText: 'Digite seu endereço de e-mail e enviaremos um link para alterar sua senha:',
      logIn: 'Acesso',
      passwordLbl: 'Senha',
      requireEmail: 'Por favor insira um endereço de e-mail válido',
      requirePassword: 'Por favor insira um endereço de e-mail válido',
      sendEmail: 'Enviar',
      forgotYourPwd: 'Esqueceu sua senha?',
      applyNow: 'Aplicar agora',
    },
    multiAccountSelector: {
      message: 'Escolha uma conta para continuar. A disponibilidade dos produtos pode variar.',
      title: 'Selecionar uma conta Alcon',
      loaderMessage: ' Carregando contas...',
      welcomeMessage: 'Bem vindo ',
      verifyAccessError:
        '<b>Não foi possível verificar o acesso a esta conta.</b><br>Verifique o número da conta e tente novamente.<br>Ou entre em contato com o atendimento ao cliente em caso de dúvida.',
      requestAdditionalAccount: 'Solicitar acesso a uma conta Alcon adicional',
      requestLabel: 'Digite o número da conta Alcon',
      requestSend: 'Enviar pedido',
      requestSending: 'Enviando pedido',
      alreadyExistAccount: 'Você já possui acesso a essa conta.',
      loading: 'Carregando...',
      shipToSelector: {
        title: 'Sua conta Alcon:',
        searchboxHeading: 'Selecionar um endereço de entrega',
        searchPlaceholderTxt: 'Adicionar endereço de entrega',
        noResultFound: 'Nenhum resultado encontrado',
      },
      soldToselector: {
        requestAdditionalAccount: 'Solicitar acesso a uma conta adicional',
        canNotFoundAccount: 'Não consegue encontrar sua conta?',
      },
    },
    productListingPage: {
      moreFilter: 'Mais filtros',
      lessFilter: 'Menos filtros',
      product: 'produto',
      seeAll: 'Ver tudo',
      select: 'Selecionar',
      showMore: 'Mostrar mais',
      showLess: 'Mostrar menos',
      sortBy: 'Mostrar por',
      results: 'Resultados',
      products: 'Produtos para Olho Seco',
      nameAZ: 'Nome A-Z',
      price: 'Preço',
      oxygenPermeability: 'Permeabilidade ao oxigênio',
      brand: 'Marca',
      categories: 'Categorias',
      showAll: 'Mostrar tudo',
      configureProducts: 'Configurar produtos',
      valuepack: 'Pacote econômico',
      details: 'Detalhes',
    },
    productPage: {
      productDescription: 'Descrição do produto',
      productSpecification: 'Especificações do produto',
      features: 'Características',
      benefits: 'Benefícios',
      downloads: 'Downloads :',
    },
    stockOrderProductListPage: {
      from: 'de',
    },
    productDetailsPage: {
      privateLabel: 'Marca própria',
      patientReference: 'Referência do paciente:',
      parametersFor: 'Paramêtros para:',
      left: 'Esquerda',
      right: 'Direita',
      baseCurve: 'Curva de base:*',
      power: 'Potência:*',
      add: 'Adicionar*:',
      colour: 'Cor*:',
      cylinder: 'Cilíndor*:',
      axis: 'Eixo*:',
      sameForBothEye: 'Mesmo para ambos os olhos',
      packSize: 'Tamanho do pacote:',
      practiceOrder: 'Pedido do profissional',
      individualOrders: 'Pedidos individuais',
      dtpError: 'Esse pedido deve ser enviado para o profissional da saúde',
      dtpSummaryError: 'Esse pedido deve ser enviado para o profissional da saúde',
      addressError: 'Por favor informe o endereço correto',
      directToPatient: 'Direto para o paciente',
      errorMessage: 'Você não pode inserir mais de 18 caracteres',
      quantityErrorMsg: 'Máx. quantidade de compra para este item é',
      PurchaseStockOrder: 'Pedido em estoque',
      perEye: 'por olho',
      each: 'cada',
      finalPrice: '* O preço final será calculado no processo de fechamento do pedido',
    },
    practiceOrderPage: {
      patientReference: 'Referência do paciente:',
      parametersFor: 'Parâmetros para:',
      left: 'Olho esquerdo',
      right: 'Olho direito',
      baseCurve: 'Curva de Base:',
      power: 'Potência:*',
      add: 'Adicionar*:',
      colour: 'Cor*:',
      cylinder: 'Cilíndro:*',
      axis: 'Eixo*:',
      sameForBothEye: 'Mesmo para ambos os olhos',
      packSize: 'Tamanho do pacote:',
      practiceOrder: 'Pedido do profissional',
      individualOrders: 'Pedidos individuais',
      diameter: 'Diâmetro:',
      typeOfWear: 'Tipo de uso:',
      material: 'Material:',
      waterContent: 'Gradiente de água:',
      schedule: 'Agendar como pedido recorrente',
      prefer: 'Pacote econômico?',
      errorMessage: 'Muitos itens selecionados. Por favor, diminua para a quantidade máxima',
      LEFT: 'Olho esquerdo',
      RIGHT: 'Olho direito',
      update: 'Atualizar',
      productColor: 'Colorida',
      productMultifocal: 'Multifocal',
      productSpherical: 'Esférica',
      productToric: 'Tórica',
      cancel: 'Cancelar',
      dtpLimitMessage: 'Quantidade máxima para entrega direta ao paciente é',
      regularInCap: 'REGULAR',
      regularInSmall: 'regular',
      directToPatient: 'Direto ao paciente',
      practice: 'Clínica',
      quantity: 'Quantidade',
    },
    favoriteListPage: {
      yourFavProduct: 'Seus produtos favoritos',
      products: 'produtos',
      product: 'produto',
      noFavProduct:
        'No momento, você não tem nenhum produto favorito listado. Para adicionar produtos à lista de favoritos clique no ícone de coração ao lado do produto',
    },
    legalAnnouncement: {
      accept: 'Aceitar',
      decline: 'Declinar',
    },
    practicePage: {
      mostFrequentOrders: 'Pedidos frequentes',
    },
    stockOrderPage: {
      configureProducts: 'Configurar produto',
      packSize: 'Tamanho do pacote:*',
      baseCurve: 'Curva de Base:',
      diameter: 'Diâmetro:',
      color: 'Cor:',
      add: 'Adicionar:',
      cancel: 'Cancelar',
      lenscylinder: 'Cilíndro da lente:*',
      moreMinus: 'Mais potências menos (-)',
      lessMinus: 'Menos potências menos (-)',
      morePlus: 'Mais potências de mais (+)',
      lessPlus: 'Menos potências de mais (+)',
      clearAll: 'Limpar tudo',
      addToCart: 'Adicionar ao carrinho',
      addToCartLoading: 'Adicionado ao carrinho',
      addToCartSuccess: 'Adicionado ao carrinho',
      updateToCartText: 'Atualizar',
      updatingCart: 'Atualizando carrinho',
      updateToCartSuccess: 'Carrinho atualizado',
      maxQty: 'A quantidade máxima para esse produto é',
      errorMessage: 'Itens demais foram selecionados, por  favor, reduza a quantidade para no máx.',
      inputMessageError: 'A quantidade deve ser maior que zero',
      each: 'cada',
      forthisbrand: 'para essa marca',
    },
    miniCartPage: {
      PRACTICE_ORDER: 'Pedidos do profissional',
      INDIVIDUAL_ORDER: 'Pedidos individuais',
      VALUE_PACK: 'Pacote econômico',
      DTP_VALUE_PACK: 'Pacote econômico',
      DIRECT_TO_PATIENT: 'Direto ao paciente',
      STOCK_ORDER: 'Pedido em estoque',
      title: 'Seu carrinho',
      RIGHT: 'Direita',
      LEFT: 'Esquerda',
      total: 'Total',
      delete: 'Deletar',
      gotocart: 'Ir para carrinho',
      moreitem: 'mais item',
      totalprice: 'R$,00,00',
      yourcartIsEmpty: 'Seu carrinho esta vazio',
      goTo: 'Ir para',
      productCatalogue: 'Catalógo de produto',
      orClick: 'ou clique',
      orClickthe: 'ou clique o ',
      newOrder: 'Novo pedido',
      editOrder: 'Editar pedido',
      toAddItems: 'para adicionar itens',
      edit: 'Editar pedido',
      patientReference: 'Referência do paciente',
      quantity: 'Qtd.',
      YouHaveNoProductsInYourCart: 'Você não tem produtos no seu carrinho',
      seeProduct: 'Ver produtos',
      hideProduct: 'Esconder produtos',
      deliveryMethod: 'Metódo de entrega:',
      poNumber: 'Número da PO:',
      orderNumber: 'Número do pedido:',
      LP: 'Potência',
      AX: 'Eixo',
      CY: 'CYL',
      Colour: 'Cor',
      DIA: 'DIA',
      BC: 'BC',
      ADD: 'Adicionar',
      PatientReference: 'Referência do Paciente',
      PowerShipped: 'potências enviadas',
      PowersInProgress: 'potências em andamento',
      cartMessage1: 'Você não tem nenhum produto adicionado ao seu carrinho',
      cartMessage2: 'Use qualquer um dos métodos a seguir para adicionar produtos ao seu pedido. ',
      cartMessage:
        'Você não tem nenhum produto adicionado ao seu carrinho. Use qualquer um dos métodos a seguir para adicionar produtos ao seu pedido.',
      valuePack: 'Pacote econômico:',
      valuePacks: 'Pacote econômico',
      valuePackDuration: 'Pacote econômico (',
      valuePackMonth: '-meses)',
      moreitems: 'mais itens',
    },
    cartPagev2: {
      INDIVIDUAL_ORDER: 'Pedidos individuais',
      VALUE_PACK: 'Pacote econômico',
      STOCK_ORDER: 'Pedidos em estoque',
      DIRECT_TO_PATIENT: 'Direto ao paciente',
      orderAndPay: 'PEDIR E PAGAR',
      simulation: {
        errorMessage:
          'O preço final não pode ser calculado neste momento. Assim que este problema for resolvido, você verá os preços no histórico de pedidos e nas faturas.',
      },
      directToPatientWarning: 'O preço final do pedido de Direto ao paciente estará disponível em sua fatura',
      warningOnCheckout:
        '*Observe que os preços na MyAlcon Store estão sujeitos a alterações e podem mudar durante o processo de pedido. O preço exato do produto será mostrado na sua fatura.',
      practiceWarning: 'O preço final do pedido de prática estará disponível em sua fatura',
      disclaimer:
        'Ao clicar em ENCOMENDAR E PAGAR, você reconhece e concorda com 1) os termos e condições do MyAlcon aos quais se inscreveu quando registrou seus dados para usar este sistema e 2) confirma que, quando for um pedido direto ao paciente, a) o paciente tem 16 anos ou mais e b) você informou ao paciente que suas informações serão compartilhadas via MyAlcon e obteve todos os consentimentos necessários do paciente.',
      deliveryCost: 'Custos de envio',
      estimatedTotalPrice: 'Estimativa de total do pedido',
      taxes: 'Taxas',
      total: 'Total',
    },
    stockToricPage: {
      lensPower: 'Potências das lentes',
      lensAxis: 'Eixos das lentes',
      message: 'Selecione a potência para ver o eixo da lente ajustadaSelect power to see fitted lens axis',
    },
    cartPage: {
      PRACTICE_ORDER: 'Pedidos do profissional',
      summary: 'Resumo',
      goToCheckout: 'Ir para check-out',
      ShippingAddress: 'Endereço de envio',
      DeliveryMethod: 'Método de entrega',
      calcFinalPrice: 'CALCULAR PREÇO FINAL',
      DIRECT_TO_PATIENT: 'Direto ao paciente',
      deliveryMethod: 'Método de envio:',
      deliveryMethodDetails: 'Entrega padrão (2-3 dias úteis)',
      IndividualOrders: 'Pedidos individuais',
      StockOrders: 'Pedidos em estoque',
      ValuePacks: 'Pacote econômico',
      DirecttoPatient: 'Direto ao paciente',
      products: 'Produtos',
      shippingsuggestion: 'Prazo de entrega será calculado no check-out',
      patients: 'pacientes',
      externalOrderMsg: 'O preço final será exibido na fatura',
      cancelOrder: 'Cancelar Pedido',
    },
    orderConfirmation: {
      orderNumber: 'Número do pedido:',
      shippingAddress: 'Endereço de entrega:',
      deliveryMethod: 'Método de entrega:',
      deliveryMethodDetails: 'Entrega padrão via correio',
      poNumber: 'Número da PO:',
      valuePackQuantity: '1 item',
      item: 'itens',
    },
    checkoutPage: {
      paymentMethod: 'Método de pagamento',
      paymentType1: 'Cartão de Crédito',
      paymentType2: 'Enviar fatura para a conta',
      valuePackQuantity: '1 item',
      poNumber: 'Número da PO:',
      LP: 'Potência',
      AX: 'Eixo*:',
      CY: 'Cilíndro',
      Colour: 'Cor',
      DIA: 'DIA',
      BC: 'BC',
      ADD: 'Adicionar',
      print: 'Imprimir',
      finalPrice: {
        products: 'Produtos',
        delivery: 'Entrega',
        taxes: 'Taxas',
        total: 'TOTAL',
        placeOrder: 'Fazer pedido',
        obligationText: 'com obrigação de pagar',
        estimatedDelivery: 'Prazo estimado de entrega:',
        loaderMessage: 'Aguarde um momento, estamos coletando os dados',
      },
      delivery: 'Entrega:',
      practiceOrders: 'Pedidos do profissional ',
      orderTypes: {
        individual: 'Pedidos individuais',
        valuePack: 'pacotes econômicos',
        stock: 'pedidos em estoque',
      },
      practiceOrdersPanel: 'Pedidos individuais, pacotes econômicos, pedidos em estoque',
      directtoPatients: 'Direto ao paciente',
      seeAll: 'Ver tudo',
      status: 'Status',
      patients: 'pacientes',
      addNewCardHeading: 'Adicionar um novo cartão',
      setDefaultCard: 'Definir este cartão como padrão',
      selectPaymentCard: 'Selecione o cartão para pagamento',
      selectCardtitle: 'Selecione um cartão de pagamento dos seus cartões salvos ou adicione um novo cartão.',
    },
    myPatientsPage: {
      addItems: '+ Adicionar Paciente',
      addNewPatient: {
        heading: 'Adicionar novo paciente',
        editHeading: 'Editar informações do paciente',
        personalData: 'Dados pessoais',
        mainAddress: 'Endereço principal',
        additionalAddress: 'Endereço Adicional',
        form: {
          title: 'Título',
          firstName: 'Nome *',
          surName: 'Sobrenome *',
          email: 'Endereço de e-mail',
          phoneNumber: 'Número de telefone',
          referenceNumber: 'Número de referência',
          address: {
            street: 'Número de referência *',
            building: 'Bloco/Apartamento/Outro',
            postcode: 'Código postal *',
            city: 'Cidade *',
            county: 'Estado',
            notMandatoryCounty: 'Estado',
            country: 'País *',
          },
          mandatoryFields: '* Campos obrigatórios',
          additionalAddress: 'Adicionar outro endereço',
          deleteadditionaladdress: 'Deletar endereço adicional',
          clearAll: 'Limpar tudo',
          save: 'salvar',
          cancel: 'Cancelar',
          update: 'Atualizar',
          errorMessages: {
            title: 'Por favor, selecione título',
            mandatoryField: 'Esse campo é obrigatório',
            firstName: 'Por favor, preencha com o primeiro nome do paciente.',
            surName: 'Por favor, preencha o sobrenome do paciente.',
            email: 'Por favor, preencha o sobrenome do paciente.',
            phoneNumber: 'Você não pode usar letras neste campo.',
            phoneNumberMinLength: 'O número de telefone é muito curto. Verifique se você digitou todos os caracteres.',
            moreThanThirtyCharacters: 'Você não pode inserir mais de 30 caracteres neste campo.',
            street: 'Por favor, preencha o endereço do paciente.',
            buildingNumberName: 'Por favor, preencha o número do prédio do paciente.',
            postcode: 'Por favor, preencha o código postal do paciente.',
            city: 'Por favor, preencha a cidade do paciente.',
            county: 'Por favor, preencha o município do paciente.',
            country: 'Por favor, preencha o país do paciente.',
            // invalidPattren: 'Invalid postcode',
            // selectCountry: 'Please select a country',
          },
        },
        patientAddedSuccessfully: 'O paciente foi adicionado com sucesso',
      },
      address: 'Endereço',
      showMore: 'Mostrar mais',
      showLess: 'Mostrar menos',
      mostRecentOrders: 'Pedidos mais recentes',
      order: 'Pedido',
      orderNo: 'Nº do pedido ',
      subscription: 'Assinatura',
      shippingAddress: 'Endereço de entrega:',
      status: 'Status',
      delivery: 'Entrega',
      packtype: 'Tipo de pacote',
      date: 'Data',
      frequency: 'Frequência',
      addOrderButton: '+ Adicionar Pedido',
      addOrder: 'Adicionar pedido',
      options: 'Opções',
      active: 'Ativo',
      inactive: 'Inativo',
      switchtoSubscription: 'Mudar para assinatura',
      sort: 'Ordernar',
      orderHistory: 'Histórico',
      editPatient: 'Editar paciente',
      deletePatient: 'Deletar paciente',
      search: 'Pesquise o nome do paciente, número de referência.',
      noPatientFound: 'Você ainda não tem pacientes. Adicione seu primeiro paciente clicando no botão abaixo.',
      searchNoPatient: 'Nenhum paciente encontrado. Verifique se você digitou tudo corretamente.',
      noPatientsFound: 'Nenhum paciente encontrado. ',
      adjustFilters: 'Tente ajustar os filtros aplicados ou ',
      addessRemovedMsg:
        'O endereço de entrega desta assinatura foi alterado para o principal, pois o endereço adicional não está mais disponível',
      ok: 'Ok',
      top: 'Parte superior',
      fetchingOrders: 'Obtendo Pedidos…',
      patientName: 'Nome do paciente',
    },
    patientListFilters: {
      filterPlus: '+  Filtros',
      filterMinus: '-  Filtros',
      ordertype: ' Tipo de pedido:',
      status: 'Status:',
      packType: 'Tipo de pacote:',
      delivery: 'Entrega:',
      clearfilters: 'Limpar filtros',
      clearallfilters: 'Limpar todos os filtros',
    },
    patientProfilePage: {
      personalData: 'Dados pessoais',
      mainAddress: 'Endereço principal',
      additionalAddress: 'Endereço Adicional',
      edit: 'Editar',
      tel: 'Tel: ',
      eMail: 'E-mail: ',
      refNumber: 'Número de referência:',
      addOrder: '+ Adicionar Pedido',
      noOrder: 'Ainda não existem pedidos',
    },
    orderHistoryPage: {
      orderPlaced: 'Pedido inserido',
      orderDate: 'Data do pedido:',
      orderNo: 'Nº do pedido',
      type: 'Digite',
      orderSource: 'Origem do pedido',
      shippingLocation: 'Endereço de entrega',
      totalPrice: 'Preço total',
      status: 'Status',
      invoice: 'Fatura',
      search: 'Procurar número do pedido ou número da PO',
      searchButton: 'buscar',
      timePeriod: 'Período de tempo:',
      dateRange: 'Intervalo de datas:',
      selectDate: 'Selecionar data(s)',
      sort: 'Ordernar:',
      reorder: 'REFAZER PEDIDO',
      viewOrderdetails: 'Ver detalhes do pedido',
      noOrders: 'Você ainda não fez nenhum pedido.',
      noOrderText: 'Ao enviar um pedido, você poderá visualizar o status e os detalhes do seu pedido aqui.',
      filter: {
        ALL: 'Tudo',
        ACTIVE: 'Ativo',
        COMPLETED: 'Finalizado',
        STATUS: 'Status',
        filterPlus: '+ Filtros',
        filterMinus: '- Filters',
        orderStatus: {
          NEW: 'Novo',
          IN_PROGRESS: 'Em andamento',
          DELAYED: 'Atrasado',
          SHIPPED: 'Enviado',
          CANCELLED: 'Cancelado',
          BILLED: 'Faturado',
          PARTIALLY_SHIPPED: 'Enviado parcialmente',
          BACKORDER: 'Em falta',
          PARTIAL_BACKORDER: 'Parcialmente em falta',
        },
      },
      showMore: 'MOSTRAR 10 MAIS DE',
      noResults: 'Nenhum pedido encontrado. Verifique se digitou o número correto ou tente com outro.',
      noResultsFilter: 'Nenhum pedido encontrado para os critérios de filtro especificados',
      cancel: 'Cancelar',
      Order: '1 pedido encontrado',
      Orders: '{{count}} pedidos encontrados',
      poNumber: 'Número da PO',
      cancelOrderConfirmation: 'Você tem certeza que quer cancelar esse pedido?',
      cancelOrderInfoMsg: 'Esta ação não pode ser revertida.',
      cancelOrder: 'Cancelar Pedido',
      keepOrder: 'Manter pedido',
      orderCancelSuccessfull: 'O pedido foi cancelado com sucesso',
    },
    invoicesListPage: {
      invoiceNo: 'Nº do Fatura',
      dueDate: 'Data de vencimento',
      orderNo: 'Nº do pedido',
      totalPrice: 'Preço total',
      dateRange: 'Intervalo de datas',
      sort: 'Ordernar:',
      newest: 'Do mais novo',
      oldest: 'Do mais antigo',
      invoicesHeading: 'Faturas',
      orders: 'Pedidos',
      orderNumber: 'Nº do pedido',

      filter: {
        ALL: 'All',
        PAID: 'Paid',
        UNPAID: 'Unpaid',
        OVERDUE: 'Overdue',
        filterPlus: '+  Filtros',
        filterMinus: '-  Filtros',
      },
      search: {
        DAILYINVOICE: 'Pesquise número da fatura ou número do pedido',
        INVOICELIST: 'Pesquise número da fatura',
        searchButton: 'Buscar',
        noResults: 'Nenhum fatura encontrado. Verifique se você digitou o número correto ou tente com outro.',
        invoice: '1 fatura encontrado',
        invoices: '{{count}} faturas encontrado',
      },
    },
    baseLoginPage: {
      youWereLoggedOut: 'Você foi desconectado',
      logoutMsg: 'Sua sessão expirou. Para sua segurança e de seus pacientes, você foi desconectado automaticamente.',
      welcome: 'Bem vindo',
      backToLogin: 'Voltar a página de login',
    },
    orderStatus: {
      NEW: 'Novo',
      IN_PROGRESS: 'Em andamento',
      DELAYED: 'Atrasado',
      SHIPPED: 'Enviado',
      CANCELLED: 'Cancelado',
      PARTIALLY_SHIPPED: 'Enviado parcialmente',
      PENDING_CONSOLIDATION: 'Aguardando consolidação do pedido',
      BILLED: 'Faturado',
      PARTIAL_BACKORDER: 'Parcialmente em falta',
      BACKORDER: 'Em falta',
    },
    confirmEmail: {
      success: 'Endereço de e-mail confirmado. Nosso representante de vendas entrará em contato com você em breve.',
      failure: 'Link expirado. Por favor, preencha o formulário de registro novamente.',
    },
    registerPage: {
      registrationFormTitle:
        'Abra uma conta para obter acesso 24 horas por dia, 7 dias por semana, a pedidos online, faturas, extratos, rastreamento de pedidos e outros recursos de gerenciamento de contas.',
      imAlconCustomer: 'Eu sou um cliente Alcon',
      iDoNotHaveAnAccountTitle: 'Ainda não possuo uma conta Alcon',
      iDoNotHaveAnAccountDescription:
        'Isso não é problema algum! Você pode deixar suas informações de contato e o representante de vendas da Alcon entrará em contato com você em breve!',
      termsAndConditions: 'Termos e Condições',
      consent: 'Consentimento',
      termsAndConditionTitle: 'Cadastro no e-commerce da Alcon',
      termsAndConditionLabel:
        'Li e concordei com os termos e condições estabelecidos nas <a src="#">Condições gerais de venda da Alcon Eye Care UK Limited.</a> * ',
      termsAndConditionDisclaimer:
        'A Alcon sempre tratará seus dados pessoais de acordo com todas as leis aplicáveis. </br></br>A Alcon faz parte do Grupo Alcon, que é um grupo global de empresas e possui bancos de dados em diferentes países, alguns dos quais são operados pela afiliada local da Alcon, e alguns dos quais são operados por terceiros em nome da afiliada local da Alcon. A Alcon pode transferir seus dados para um ou mais bancos de dados de suas afiliadas fora do seu país de residência, incluindo países que podem não exigir um nível adequado de proteção para seus dados pessoais em comparação com o fornecido no Brasil.</br></br>A Alcon não venderá seus dados pessoais a terceiros para qualquer finalidade.</br></br>Se você deseja entrar em contato conosco sobre o uso de seus dados pessoais, alterar suas preferências ou se opor ao processamento de seus dados pessoais, envie-nos um e-mail para privacy@alcon.com.',
      setUpAnAccount: 'Configurar uma conta',
      title: 'Título',
      firstName: 'Nome*',
      lastName: 'Sobrenome*',
      fullCompanyName: 'Nome completo da empresa*',
      email: 'Email*',
      accountNumber: 'Número da conta Alcon*',
      next: 'Próximo',
      back: 'Voltar',
      cancel: 'Cancelar',
      submit: 'Enviar',
      mandatory: '* Campos obrigatórios',
      successText:
        'Uma cópia deste formulário será enviada para sua caixa de entrada.</br></br>Observação - pode levar até três dias úteis para configurar uma nova conta a partir do dia em que recebermos o formulário preenchido, incluindo um GOC / Número GMC.',
      successTitle: 'Obrigado por preencher seu formulário de inscrição para abrir uma conta na Alcon Brasil.',
      goToLoginPage: 'Ir para página do login',
      TypeOfProducts: 'Tipo de produtos*',
      selectProductType: 'Selecionar tipo de produto',
      productTypeSurgical: 'Produtos cirúrgicos e IOLs',
      productTypeVisionCare: 'Lentes de contato e produtos Vision Care',
      errorMessages: {
        mandatoryField: 'Este campo é obrigatório.',
        maxLengthReached: 'Você não pode usar mais de {{characterNumber}} caracteres neste campo.',
        couldContainsOnlyNumbers: 'Você não pode usar letras neste campo.',
        firstName: 'Por favor, preencha com o primeiro nome',
        lastName: 'Por favor, preencha com o sobrenome',
        fullCompanyName: 'Por favor, preencha com o nome completo da empresa',
        email: 'Por favor, preencha com o endereço de e-mail',
        emailPattern: 'O e-mail está no formato incorreto. Use o seguinte formato: joh@gmail.com',
        accountNumber: 'Por favor, preencha o número da conta Alcon',
        termsAndConditions: 'Por favor, aceite os campos obrigatórios',
        responseError:
          'Houve algum problema com o seu pedido. Tente novamente ou entre em contato com o atendimento ao cliente',
        TypeOfProducts: 'Por favor selecione o tipo de produto',
      },
    },
    franchisePage: {
      surgicalProduct: 'Produtos Cirúrgicos (Pacotes Personalizados, IOLs, etc.)',
      visionCareProduct: 'Produtos para cuidados com a visão (lentes de contato, etc.)',
      next: 'Próximo',
    },
    contactusPage: {
      pleaseSelectATopic: 'Selecione um tópico:*',
      typeYourMessageHere: 'Digite sua mensagem aqui:*',
      contactMethod: 'Selecione seu método de contato preferido para esta solicitação:*',
      contactMethodLabel1: 'E-mail',
      contactMethodLabel2: 'Telefone',
      emailAddress: 'Endereço de e-mail',
      emailInfo:
        'As informações inseridas acima não alteram as informações de contato associadas à sua conta. Você pode alterar seu número de telefone em Meu perfil ou entrar em contato com o Atendimento ao cliente para alterar seu e-mail.',
      termsConditions:
        'A Alcon usará essas informações para responder à sua solicitação. Li a Política de Privacidade da Alcon e concordo com seus termos.*',
      preferredTime:
        'A Alcon usará essas informações para responder à sua solicitação. Li a Política de Privacidade da Alcon e concordo com seus termos.*',
      preferredTimeLabel1: 'Manhã',
      preferredTimeLabel2: 'Tarde',
      contactName: 'Forneça um nome de contato:*',
      contactNameLabel: ' Nome de Contato',
      mandatoryFields: '* Campos obrigatórios',
      provideContactName: 'Forneça um nome de contato:*',
      fullCompanyName: 'Nome completo da empresa',
      returnToLastVisitedPage: 'Voltar à última página visitada',
      contactusSubmit: 'Enviar',
      phoneNumber: 'Número de telefone',
      characters: '1.000 caracteres',
      thankyoumsg: 'Obrigada pelo envio',
      additionalmsg:
        'Um representante da Alcon entrará em contato com você em relaçao a esse assunto em até 1 dia útil.',
      returntoHome: 'Retornar a página inicial',
      returnProdct: 'Retornar a produto',
      gotoform: 'Ir para formulário',
      selectFile: 'Selecionar arquivo',
      errorMessages: {
        topic: 'Selecione um tópico.',
        mandatoryField: 'Esse campo é obrigatório',
        message: 'Por favor, preencha a mensagem.',
        contactmethod: 'Selecione pelo menos um método de contato',
        email: 'Por favor, preencha o e-mail',
        emailPattren: 'O e-mail está no formato incorreto. Por favor, use o seguinte formato: john@gmail.com',
        phoneNumber: 'Por favor, preencha o número de telefone.',
        phoneNumberCharecters: 'Você não pode usar letras neste campo.',
        phoneNumberMinLength: 'O número de telefone é muito curto. Verifique se você digitou todos os caracteres.',
        preferredTime: 'Selecione o horário de contato preferido',
        contactName: 'Por favor, preencha o nome do contato',
        termsConditions: 'Selecione os termos e condições',
        contactNamePattern: 'Você não pode inserir mais de 30 caracteres neste campo.',
      },
      salesRep: {
        title: 'Meu consultor de vendas',
        phone: 'Telefone:',
        email: 'Email:',
      },
      selectone: 'Selecionar',
    },
    selectProductPage: {
      selectPackType: 'Pacote econômico',
      selectProducts: 'Selecionar produtos',
      regular: 'Regular',
      valuePack: 'Pacote econômico',
      chooseLenses: 'Escolher lentes:*',
      patientName: 'Paciente:',
      valuePacktooltip: 'Certas lentes de contato e soluções são oferecidas como um pacote conveniente.',
      additionalProducts: 'Escolher produtos adicionais:',
      contactLens: 'Solução para Lentes de Contato',
      dryEye: 'Produtos para Olho Seco',
      addAnotherproduct: 'Adicionar outro produto',
      mandatory: '* Campos obrigatórios',
      addOtherLenses: 'Adicionar mais',
      valuePackAttributes: {
        threeMonthValuePack: 'Pacote de 3 meses',
        sixMonthValuePack: 'Pacote de 6 meses',
        chooseSolution: 'Escolher solução:*',
      },
      patientOrderPanel: {
        delivery: 'Entrega',
        practiceOrder: 'Pedido do profissional',
        directToPatient: 'Direto ao paciente',
        selectOrderType: 'Selecione o tipo de pedido',
        individual: 'Individual',
        total: 'Total:',
        addToCart: 'Adicionar ao carrinho',
        addToCartSuccess: 'Adicionar ao carrinho',
        cancel: 'Cancelar',
        address: 'Endereço:',
        delivertodiffAddress: 'Entregar em endereço diferente',
        selectdeliveryAddress: 'Selecionar endereço de entrega:',
        dropdown: {
          buttonText: 'Adicionar paciente',
        },
        scheduled: {
          orderType: 'Agendado',
          repeatEveryText: 'Repetir todo:',
          week: 'semanas',
          month: 'meses',
          startDate: 'Data de Início:',
          save: 'Salvar',
          startSubscriptions: 'Iniciar inscrição',
          addToCartNow: 'ADICIONAR AO CARRINHO',
        },
        title: 'Falha ao adicionar ao carrinho',
        description: 'Os itens não foram adicionados ao carrinho.<br> Resolva os problemas e tente novamente.',
        redirectButton: 'OK',
      },
      tooltipText:
        'Você não pode selecionar um segundo produto se dois olhos forem selecionados. Se você deseja adicionar outro produto para o mesmo paciente desmarque um olho.',
    },
    siteDirectory: {
      errorMessage: {
        accountNotSetup: 'Sua conta não está configurada para este país. Escolha o site correto.',
      },
      heading: 'Diretório Global da Loja MyAlcon',
      locationHeading: 'Escolha uma localização diferente',
      discoverHeading: 'Descubra os produtos Alcon para cuidados com a visão',
      suggestedLocation: 'Localizações sugeridas',
      chooseLocation: 'Escolha sua localização',
    },
    safeOrderingPage: {
      safeOrdering: 'Pedido seguro ',
    },
    configureProducts: {
      deliveryPanel: {
        delivery: 'Entrega',
        selectDelivery: 'Selecionar entrega',
        practiceOrder: 'Pedido do profissional',
        directToPatient: 'Direto ao paciente',
        patientReferencePlaceholder: 'Digite referência',
        patientReference: 'Referência do paciente:',
        patientReferenceMandatory: 'Referência do paciente:*',
        needStockOrder: 'Precisa de um pedido de estoque?',
        practiceErrorMessage: 'You cannot enter more than 30 characters.',
        noResultsFound: 'Nenhum resultado encontrado',
        selectFromList: 'Selecionar da lista',
        deliveryToDifferentAddress: 'Entregar em endereço diferente',
      },
    },
    orderDetailsPage: {
      orderNo: 'Nº do pedido.',
      shipped: 'Enviado',
      inProgess: 'Em andamento',
      delivered: 'Entregue',
      billOnly: 'Faturar apenas',
      products: 'Produtos',
      ACCOUNT: 'Enviar fatura para a conta',
      CASH: 'Dinheiro na entrega',
      CARD: 'Cartão de Crédito',
      statusPanel: {
        partiallyShipped: 'Enviado parcialmente',
        trackingno: 'Nº de rastreio.:',
      },
      paymentMethodPanel: {
        orderPlaced: 'Pedido realizado:',
        orderDate: 'Data do pedido:',
        orderSource: 'Order source:',
        paymentMethod: 'Método de pagamento:',
        cardEndingWith: 'Cartão com final ***',
        cardEndsWith: 'Cartão com final ',
        invoiceno: 'Número da fatura.',
        addNewCard: 'Adicionar um novo cartão',
        noCreditCard: 'Você não possui cartões de crédito vinculados a esta conta.',
        Change: 'Alterar',
        useOneTime: 'USAR CARTÃO UMA VEZ',
        useAndSaveLater: 'Usar e salvar para depois',
        useSelectedCard: 'USAR CARTÃO SELECIONADO',
        useAndSaveDefault: 'Usar e salvar como padrão',
        useNewCard: 'USAR NOVO CARTÃO',
        invoiceToAccount: 'Enviar fatura para a conta',
      },
      deliveryMethodPanel: {
        practiceOrders: 'Pedido do profissional',
        deliveryMethod: 'Método de entrega:',
        shippingAddress: 'Endereço de entrega:',
        poNumber: 'Numéro da PO:',
        DTPOrders: 'Direto ao paciente',
      },
      finalPrice: {
        reOrder: 'Refazer o pedido',
      },
      orderItemPage: {
        INDIVIDUAL_ORDER: 'Pedidos individuais',
        STOCK_ORDER: 'Pedido em estoque',
        VALUE_PACK: 'Pacote econômico',
        total: 'Total:',
        Items: 'Itens',
        item: '{{count}} item',
        item_plural: '{{count}} itens',
        materialNumber: 'Item número:',
        serialNumber: 'Número de série:',
        IOL_Bill: 'Fatura de IOL',
        diopter: 'Dioptria',
        estDeliveryDate: 'Data de entrega estimada',
        deliveryDate: 'Data de entrega',
        'labelstatus_In transit': 'Em transito:',
        'labelstatus_In delivery': 'Em entrega:',
        labelstatus_delivered: 'Entregue:',
        labelstatus_undefined: '',
        fromitem: '{{othercount}} de {{count}} itens',
        tooltip: '{othercount}} de {{count}} itens são {{status}}. O resto dos itens têm status diferentes',
        exptDeliveryDate: 'Data de entrega esperada',
        customerReference: 'Referência do cliente',
        partialBODate_itemsShipped:
          '{{shippedQuantity}} itens foram enviados para você. Os itens {{backOrderQuantity}} restantes serão enviados após {{backOrderDate}}',
        partialBODate_itemsNotShipped:
          'Você receberá {{unshippedQuantity}} itens. Os itens {{backOrderQuantity}} restantes serão enviados após {{backOrderDate}}',
        partialBOWithoutDate_itemsShipped:
          '{{shippedQuantity}} itens foram enviados para você. Os itens {{backOrderQuantity}} restantes serão enviados a você o mais rápido possível.',
        partialBOWithoutDate_itemsNotShipped:
          'Você receberá {{unshippedQuantity}} itens. Os itens {{backOrderQuantity}} restantes serão enviados a você o mais rápido possível.',
        fullBO_withDate: 'Este produto está temporariamente fora de estoque. Será enviado após {{backOrderDate}}',
        fullBO_withoutDate:
          'Este produto está temporariamente fora de estoque. Ele será enviado a você o mais rápido possível quando estiver disponível.',
      },
    },
    selectDeliveryAddressPage: {
      mainaddress: 'Endereço principal',
      additonaladdress: 'Endereço Adicional',
      edit: 'Editar',
      cancel: 'Cancelar',
      save: 'Salvar',
      mandatory: '*Campo obrigatório',
    },
    patientAddressPage: {
      heading: 'Endereço:',
      form: {
        address: {
          street: 'Endereço com número *',
          building: 'Bloco/Apartamento/Outro',
          postcode: 'Código postal *',
          city: 'Cidade *',
          county: 'Estado',
          notMandatoryCounty: 'Estado',
          country: 'País *',
        },
        mandatoryFields: '*Campo obrigatório',
        additionalAddress: 'Adicionar novo endereço',
        clearAll: 'Limpar tudo',
        save: 'salvar',
        cancel: 'Cancelar',
        update: 'Atualizar',
        deleteadditionaladdress: 'Deletar endereço adicional',
        errorMessages: {
          mandatoryField: 'Esse campo é obrigatório.',
          street: 'Por favor, preencha o endereço do paciente.',
          buildingNumberName: 'Por favor, preencha o número da residência do paciente.',
          postcode: 'Por favor, preencha o código postal do paciente.',
          city: 'Por favor, preencha a cidade do paciente.',
          county: 'Por favor, preencha o município do paciente.',
          country: 'Por favor, preencha o país do paciente..',
          invalidPattren: 'Invalid postcode',
          selectCountry: 'Por favor selecione um país',
        },
      },
    },
    recentOrders: {
      lastOrders: 'Últimos pedidos',
      latestOrders: 'Latest orders',
      seeMore: 'Ver mais em histórico do pedido ',
      noOrders: 'Você não tem nenhum pedido.',
      noOrderText: 'Ao enviar um pedido, você poderá ver o status do seu pedido aqui.',
      noOrdersSX:
        'Você ainda não encomendou nenhum produto. Ao enviar o pedido, você poderá visualizar o status do seu pedido aqui.',
    },
    myProfilePage: {
      emailAddress: 'Endereço de e-mail:',
      phoneNumber: 'Número de telefone:',
      password: 'Senha',
      changepassword: 'Mudar senha',
      edit: 'Editar',
      toggleLabelOn: 'SIM',
      toggleLabelOff: 'NÃO',
      preferences: 'Preferências',
      receiveOrderConfirmation: 'Desejo receber a confirmação do pedido e o e-mail de notificação de envio',
      updatePreferencesSuccess: 'Suas preferências foram alteradas com sucesso',
      contactInformationUpdateSuccess: 'Seus dados de contato foram atualizados com sucesso',
      updatePreferencesFail:
        'Houve algum problema com a atualização de suas preferências. Tente novamente ou entre em contato com o atendimento ao cliente',
    },
    contactInformation: {
      title: 'Título',
      firstName: 'Nome*',
      lastName: 'Sobrenome*',
      email: 'Endereço de e-mail',
      phonenumber: 'Número de telefone',
      cancel: 'Cancelar',
      save: 'Salvar',
      clear: 'Limpar',
      errorMessages: {
        requireFirstName: 'Por favor, preencha o primeiro nome',
        moreThanThirtyCharacters: 'Você não pode inserir mais de 30 caracteres neste campo.',
        requireLastName: 'Por favor, preencha o sobrenome',
        phoneNumber: 'Você não pode usar letras neste campo.',
        phoneNumberMinLength: 'O número de telefone é muito curto. Verifique se você digitou todos os caracteres.',
      },
    },
    changePassword: {
      currentpassword: 'Senha atual*',
      newpassword: 'Nova senha*',
      reenterpassword: 'Inserir novamente nova senha*',
      mandatory: '*Campo obrigatório',
      showpassword: 'Mostrar senha',
      cancel: 'Cancelar',
      save: 'Salvar',
      resetPassword: 'Enviar e-mail de redefinição de senha',
      suggestion: 'Use 8 ou mais caracteres com uma mistura de letras, números e símbolos',
      errorMessages: {
        requireCurrentpassword: 'Por favor, inserir senha atual',
        requireNewPassword: 'Por favor, inserir nova senha',
        requireReEnterpassword: 'Inserir novamente nova senha*',
        match: 'Suas senhas não correspondem. Tente novamente.',
        strong: 'Por favor, escolha uma senha mais forte. Experimente uma mistura de letras, números e símbolos.',
        maxCharcters: 'Use 8 caracteres ou mais para sua senha.',
      },
      resetPasswordConfirmation: 'E-mail de redefinição de senha enviado',
      resetPasswordError: 'Algo deu errado. Tente novamente ou entre em contato com o atendimento ao cliente',
    },
    patientOrderHistory: {
      io: 'Pedidos individuais ',
      subscription: 'Assinaturas ',
      subscriptions: 'Assinaturas',
      individualorders: 'Pedidos individuais',
      regular: 'Regular',
      valuepack: 'Pacote econômico',
      practiceorder: 'Pedido do profissional',
      directtopatient: 'Direto ao paciente',
      switchSub: 'Mudar para assinatura',
      nof: 'Nenhum pedido encontrado no período especificado',
      orderNo: 'Nº do pedido.',
      mainAddress: 'Endereço principal',
      addAddress: 'Endereço Adicional',
      orderPlaced: 'Pedido realizado:',
      orderSent: 'Pedido enviado:',
      nextOrder: 'Próximo pedido:',
      subCreated: 'Assinatura criada:',
      every: 'Todo',
      active: 'ATIVO',
      inactive: 'INATIVO',
      noOrdersYet: 'Sem pedidos até agora',
      oneTime: 'Única vez',
      reOrder: 'REFAZER PEDIDO',
      frequency: 'Frequência',
      deleteSub: 'Tem certeza de que deseja excluir <br> esta assinatura?',
      editSub: 'Deseja ativar <br> esta assinatura?',
      scheduleOrderYes: 'Sim',
      scheduleOrderNo: 'Não',
      canDelOnlyActive: 'Você pode excluir apenas assinaturas inativas',
      deactivateScheduleOrder: 'Tem certeza de que deseja desativar <br> este pedido agendado?',
      popupYesText: 'Sim',
      popupNoText: 'Não',
      activeToInactive: 'Assinatura ativa \n Clique para desativar',
      inactiveToActive: 'Assinatura inativa \n Clique para ativar',
      delPatientConfirmation: 'Você tem certeza que quer deletar esse paciente?',
      delPatient: 'Você tem certeza que quer deletar ?',
      delPatientSuceess: 'Paciente deletado com sucesso',
      deletedSuccessfully: 'Deleted successfully',
      delPatientFail: 'Não foi possível deletar esse paciente ',
      delFailMsg:
        'Existem alguns itens para este paciente em seu carrinho. \n Por favor, remova esses itens e use a opção Apagar novamente.',
      goToCart: 'Ir para carrinho',
      cartLink: 'carrinho',
      hasActiveOrders: 'O paciente tem um pedido ativo no carrinho.',
      infoMsg:
        'Este paciente ainda tem pedidos agendados ativos. Se você <br> excluir este paciente agora, os pedidos enviados serão entregues <br> e todos os pedidos agendados serão cancelados. <br> Isso não pode ser desfeito.',
    },
    accountDetailsPage: {
      requestChangeAddress: 'Solicitar mudança de endereço',
      shippingAddress: 'Endereço de entrega',
      billingAddress: 'Endereço de Cobrança',
      orderconsolidationenabled: 'Consolidação de pedidos ativada',
      alconAccount: 'Conta Alcon',
      shippingAccount: 'Conta de envio',
      switchText: 'Trocar',
    },
    commonTitles: {
      edit: 'Editar',
      delete: 'Deletar',
    },
    visioncareSearch: {
      searchFor: 'Buscar por:',
      searchProductPlaceholder: 'Digite o nome do produto',
      searchPatientPlaceholder: 'Digite o nome do paciente',
      patient: 'Paciente',
      product: 'Produto',
      invoicetoaccount: 'Enviar fatura para a conta',
    },
    subscriptions: {
      nosubscriptions: 'Você não possui assinaturas',
      subscriptions: 'Assinaturas',
      allPatients: 'Todos os pacientes',
      subscriptionMsg:
        'Assinaturas são pedidos programados que são entregues diretamente aos pacientes ou a um profissional',
      subscriptionSuccessMessage: 'Assinatura está ativa. O primeiro pedido será enviado no dia selecionado.',
      subscriptionInactiveMessage: 'Assinatura está inativa. Alterações salvas.',
    },
    returnForm: {
      noProducts: 'Assinaturas são pedidos programados que são entregues diretamente aos pacientes ou a uma prática.',
      searchProducts: 'Pesquise produto ou selecione na lista',
      selectProductHeader: 'Selecione o produto que deseja devolver:',
      addAnotherProduct: 'Adicionar outro produto para devolução',
      baseCurve: 'Curva de Base:*',
      power: 'Potência:*',
      add: 'Adicionar*:',
      colour: 'Cor*:',
      cylinder: 'Cilindro*:',
      axis: 'Eixo*:',
      packSize: 'Tamanho do pacote:',
      diameter: 'Diâmetro:',
      ordernumber: 'Nº do pedido:*',
      quantity: 'Quantidade:*',
      mandatory: '*Campo obrigatório',
      delete: 'Deletar produto',
      save: 'SALVAR PRODUTO',
      returnReason: 'Motivo da devolução ',
      dateoforder: 'Data do pedido:',
      reasonMandatory: 'Identifique o motivo para devolução dos produtos *',
      selectreason: 'Selecione motivo',
      quantityError:
        'Você não pode escolher mais produtos de um determinado tipo do que foi solicitado. Se você tiver certeza sobre a quantidade do produto, verifique o número do pedido',
      orderNumberError:
        'Esse número de pedido já foi usado em seu formulário de devolução. Altere o número do seu pedido ou exclua este item',
      returnproduct: 'DEVOLVER PRODUTO',
      specifications: {
        baseCurve: 'Curva de Base:',
        power: 'Potência:',
        add: 'Adicionar:',
        colour: 'Cor:',
        cylinder: 'Cilíndro:',
        axis: 'Eixo:',
        packSize: 'Tamanho do pacote:',
        diameter: 'Diâmetro:',
        ordernumber: 'Nº do pedido:',
        quantity: 'Quantidade:',
      },
      confirmation: {
        header: 'Sua devolução esta pronta',
        returnInfoMessage: 'Seu formulário de devolução será enviado para o seu endereço de e-mail:',
        remember: 'Lembrar',
        sendcopy: 'Enviar cópia para endereço de e-mail alternativo',
        alternativeEmail: 'Forneça um e-mail alternativo',
        send: 'Enviar',
        emailError: 'O e-mail está no formato incorreto. Por favor, use o seguinte formato: john@gmail.com',
      },
    },
    statements: {
      statementForDownload: 'Extratos para download',
      tooltipText: 'O primeiro extrato estará disponível no início do mês seguinte.',
      downloadText: 'Baixar declaração (PDF)',
    },
    reOrderValidation: {
      heading: 'Há um problema com seu repedido',
      ok: 'OK',
      valuePackQauntity: '1 items',
      itemDiscontinued: 'Entre em contato com o serviço ao cliente',
      patientDeleted: 'O paciente foi excluído e não está mais disponível para pedidos',
      maxLimit: 'Por favor, envie seu carrinho atual antes de reordenar os produtos',
      GENERIC_API_ERRORMESSAGE:
        'Os itens foram adicionados ao carrinho para o endereço principal do paciente. Verifique o endereço no carrinho',
      DIRECT_TO_PATIENT_MESSAGE:
        'Os itens não foram adicionados ao carrinho. Tente novamente mais tarde ou entre em contato com o atendimento ao cliente',
      GENERIC_MESSAGE: 'Os itens foram adicionados ao carrinho',
    },
    autoOrderingTranslations: {
      heading: 'Carrinho de compras automático ativado',
      timeSet: 'Horário definido',
      setTime: 'Definir horário',
      save: 'Salvar',
      cancel: 'Cancelar',
      ENABLE_SUCCESS_MSG: 'Carrinho de compras automático está ativado para horário especificado',
      DISABLE_SUCCESS_MSG: 'Pedido de compras atutomático está desativado.',
      ERROR_MSG:
        'Algo deu errado. Por favor, tente novamente. Se o problema estiver se repetindo, entre em contato com o atendimento ao cliente.',
    },
    faqSearch: {
      searchLabel: 'Procurar uma resposta',
      searchSuggestion: 'Você também pode navegar pelos tópicos abaixo para encontrar o que está procurando. ',
    },
    sxRegistration: {
      email: 'Endereço de e-mail',
      cancel: 'Cancelar',
      next: 'Próximo',
      emailVal: 'Por favor, insira um endereço de e-mail válido',
      title: 'Cadastro no MyAlcon',
      passwordVal: 'Por favor, insira a sua senha',
      mandatory: '* Campos obrigatórios',
      login: 'Acesso',
      invalidEmail: 'Endereço de e-mail inválido',
      emailExist: 'Já existe um usuário com este {0}',
      phonelong: 'Número de telefone é muito longo',
      phoneshort: 'O número de telefone é muito curto',
      select: 'Selecionar',
      consentLinks: {
        termsCondition: 'https://www.alcon.com/terms-use?_ga=2.124201935.434608583.1555915818-1936277745.1549032292',
        privacyPolicy:
          'https://www.alcon.com/privacy-policy?_ga=2.124201935.434608583.1555915818-1936277745.1549032292',
        marketingPrivacyPolicy:
          'https://www.alcon.com/privacy-policy?_ga=2.124201935.434608583.1555915818-1936277745.1549032292',
      },
    },
    loginPage: {
      email: 'Endereço de e-mail',
      cancel: 'Voltar ao Login',
      emailVal: 'Por favor, insira um endereço de e-mail válido',
      passwordVal: 'Por favor, insira a sua senha',
      login: 'Acesso',
      sxTitle: 'Cirúrgica Índia',
      vcTitle: 'Vision Care Índia',
      selectZone: 'Selecionar zona',
      selectBranch: 'Selecione filial',
    },
    toggleValues: {
      active: 'ATIVO',
      inactive: 'INATIVO',
      yes: 'SIM',
      no: 'NÃO',
    },
  },

  asm: {
    asm: {
      mainLogoLabel: 'ASM',
      mainTitle: 'Modo de serviço assistido',
      logout: 'Sair',
      hideUi: 'Fechar ASM',
      toggleUi: {
        collapse: 'Ocultar ASM',
        expand: 'Mostras ASM',
      },
      loginForm: {
        submit: 'Entrar',
        userId: {
          label: 'ID do agente',
          required: 'O ID do agente é necessário',
        },
        password: {
          label: 'Senha',
          required: 'Senha é necessária',
        },
      },
      customerSearch: {
        searchTerm: {
          label: 'Nome/endereço de e-mail do cliente',
        },
        submit: 'Iniciar sessão',
        noMatch: 'Nenhum cliente encontrado.',
      },
      endSession: 'Finalizar sessão',
      agentSessionTimer: {
        label: 'Tempo da sessão esgotado',
        minutes: 'Minutos',
        reset: 'Redefenir',
      },
    },
  },
  autoOrderingTranslations: {
    heading: 'Carrinho de compras automático ativado',
    timeSet: 'Horário definido',
    setTime: 'Definir horário',
    save: 'Salvar',
    cancel: 'Cancelar',
    ENABLE_SUCCESS_MSG: 'Carrinho de compras automático está ativado para horário especificado',
    DISABLE_SUCCESS_MSG: 'Pedido de compras atutomático está desativado.',
    ERROR_MSG:
      'Algo deu errado. Por favor, tente novamente. Se o problema estiver se repetindo, entre em contato com o atendimento ao cliente.',
  },
};
