import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslationService } from '@spartacus/core';
import { Observable, Subject, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { PatientService } from 'src/app/services/patient/patient.service';
import { ProductListService } from 'src/app/services/product-list-page/product-list.service';
import { AlconUserPermissionService } from 'src/app/shared-modules/alcon-user-permission-provider/service/alcon-user-permission.service';
import { DropdownConfig, DropdownOptions } from 'src/app/shared/model/common.mode';
import { AddressInfo } from 'src/app/shared/model/patient.model';
import { getPatientAddress, getPatientData } from 'src/app/store/Selectors/patient.selector';

@Component({
  selector: 'app-patient-info-selection',
  templateUrl: './patient-info-selection.component.html',
  styleUrls: ['./patient-info-selection.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PatientInfoSelectionComponent implements OnInit, OnDestroy {
  selectedPatientAddress: AddressInfo;
  patients: Observable<DropdownOptions[]>;
  @Output() changeAddress: EventEmitter<string> = new EventEmitter();
  @Output() addPatient: EventEmitter<string> = new EventEmitter();
  @Output() patientSelected: EventEmitter<{}> = new EventEmitter();
  @Output() hasAddress: EventEmitter<{}> = new EventEmitter();
  @Input() disable = false;
  @Input() dropdownConfig: DropdownConfig = {
    defaultSelectText: '',
    typeAheadConfig: { typeAhead: true },
    showOptionHeader: false,
    maxItemsShown: 5,
  };
  buttonText: string;
  DTP: boolean;
  dtpDefaultText: string;
  noMainAddress = false;
  @Input() set reset(value) {
    this.DTP = false;
    this.noMainAddress = false;
    this.dropdownConfig = {
      ...this.dropdownConfig,
      defaultSelectText: '',
    };
    this.patientID = '';
    this.setHasAddress();
  }
  @Input() set isDTP(value) {
    if (!value) {
      this.selectedPatientAddress = undefined;
      this.noMainAddress = false;
      this.setHasAddress();
    }

    this.DTP = value;
    this.dropdownConfig = {
      ...this.dropdownConfig,
      defaultSelectText: value ? this.dtpDefaultText : '',
    };
  }
  patientReference: string;
  patientID: string;
  patientDropdownData$: any;
  destorySubscription$ = new Subject<void>();
  showLoader = true;
  showPatientDropdown = false;
  constructor(
    private translation: TranslationService,
    private productListService: ProductListService,
    private patientService: PatientService,
    private store: Store,
    private alconUserPermissionService: AlconUserPermissionService
  ) {
    this.getTranslations();
  }

  private getTranslations(): void {
    combineLatest([
      this.translation.translate('selectProductPage.patientOrderPanel.dropdown.buttonText'),
      this.translation.translate('configureProducts.deliveryPanel.selectFromList'),
    ])
      .pipe(
        map(([buttonText, dtpDefaultText]) => {
          return {
            buttonText,
            dtpDefaultText,
          };
        }),
        take(1)
      )
      .subscribe((data) => {
        this.dtpDefaultText = data.dtpDefaultText;
        this.dropdownConfig = {
          ...this.dropdownConfig,
          buttons: this.alconUserPermissionService.getPatientManagementPermission()
            ? [
                {
                  name: data.buttonText,
                  iconRef: 'PlusIcon',
                  buttonId: 'add-patient-button',
                },
              ]
            : [],
        };
      });
  }

  ngOnInit(): void {
    this.getTranslations();
    this.patientService.getPatientStoreData().pipe(take(1)).subscribe();
    this.showPatientDropdown = this.alconUserPermissionService.getPatientManagementPermission();
    if (!this.showPatientDropdown) {
      this.showLoader = false;
      return;
    }
    this.patients = combineLatest([
      this.store.pipe(select(getPatientData)),
      this.productListService.newPatientId,
      this.productListService.changedAddressType,
    ]).pipe(
      map(([patientList, newPatientID, changedAddressType]) => {
        this.showLoader = false;
        return patientList
          .slice()
          .sort((option1, option2) => (option1.lastName.toLowerCase() > option2.lastName.toLowerCase() ? 1 : -1))
          .map((patient) => {
            const dropdownData = {
              text: patient.lastName + ', ' + patient.firstName,
              value: patient.patientId,
              selected: newPatientID ? patient.patientId === newPatientID : patient.patientId === this.patientID,
            } as DropdownOptions;
            if (patient.patientId === newPatientID) {
              this.dropDownSelected(dropdownData, changedAddressType);
            } else if (patient.patientId === this.patientID) {
              this.dropDownSelected(dropdownData, changedAddressType);
            }
            return dropdownData;
          });
      })
    );
  }

  dropDownSelected(option: DropdownOptions, addressType: boolean): void {
    this.patientReference = option.text;
    this.patientID = option.value;
    if (this.DTP) {
      this.showAddress(option.value, addressType);
    }
    this.patientSelected.emit({ patientID: this.patientID, patientReference: this.patientReference, addressType });
  }

  private showAddress(patientID: string, addressType: boolean): void {
    this.store
      .select(getPatientAddress(patientID, addressType))
      .pipe(take(1))
      .subscribe((address) => {
        if (address) {
          this.selectedPatientAddress = { ...address };
          this.noMainAddress = false;
        } else {
          this.noMainAddress = true;
          this.selectedPatientAddress = undefined;
        }
        this.setHasAddress();
      });
  }

  searchTextChange(value: string): void {
    this.patientReference = value;
    this.patientID = undefined;
    this.patientSelected.emit({ patientID: this.patientID, patientReference: this.patientReference });
  }

  changeDeliveryAddress(): void {
    if (this.disable) {
      return;
    }
    this.changeAddress.emit(this.patientID);
  }

  addOption(buttonId: string): void {
    this.addPatient.emit(buttonId);
  }

  /**
   * Emits noMainAddress when there is change in address
   */
  setHasAddress(): void {
    this.hasAddress.emit(this.noMainAddress);
  }

  ngOnDestroy(): void {
    this.destorySubscription$.next();
    this.destorySubscription$.complete();
    this.productListService.newPatientIdSubject.next('');
  }
}
