<div class="mt-20">
  <app-dropdown-selector-v2
    [disableDropDown]="disable"
    [options]="BaseCurveSelector"
    (dropDownSelected)="baseCurveSelected($event)"
  >
  </app-dropdown-selector-v2>
</div>
<div class="mt-20">
  <app-dropdown-selector-v2
    [disableDropDown]="disable"
    [options]="DiameterSelector"
    (dropDownSelected)="diameterSelected($event)"
  >
  </app-dropdown-selector-v2>
</div>
<div class="mt-20">
  <app-dropdown-selector-v2
    [disableDropDown]="disable"
    [options]="LensPowerSelector"
    (dropDownSelected)="lensPowerSelected($event)"
  >
  </app-dropdown-selector-v2>
</div>
<div *ngIf="contactLensType === 'COLOR'" class="mt-20">
  <app-dropdown-selector-v2
    [disableDropDown]="disable"
    [options]="ColorSelector"
    (dropDownSelected)="colorSelected($event)"
  >
  </app-dropdown-selector-v2>
</div>
<div *ngIf="contactLensType === 'MULTIFOCAL'" class="mt-20">
  <app-dropdown-selector-v2
    [disableDropDown]="disable"
    [options]="AddSelector"
    (dropDownSelected)="addSelected($event)"
  >
  </app-dropdown-selector-v2>
</div>
<div *ngIf="contactLensType === 'TORIC'" class="mt-20">
  <app-dropdown-selector-v2
    [disableDropDown]="disable"
    [options]="CylinderSelector"
    (dropDownSelected)="cylinderSelected($event)"
  >
  </app-dropdown-selector-v2>
</div>
<div *ngIf="contactLensType === 'TORIC'" class="mt-20">
  <app-dropdown-selector-v2
    [disableDropDown]="disable"
    [options]="AxisSelector"
    (dropDownSelected)="axisSelected($event)"
  >
  </app-dropdown-selector-v2>
</div>
