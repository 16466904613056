import { internal, registerInput } from 'okta'
import intlTelInput from 'intl-tel-input';
import * as utilsScript from 'intl-tel-input/build/js/utils';

const TextBox = internal.views.forms.inputs.TextBox;
const TYPE = 'phone';

const ERROR_PHONE_MAP = [
  'model.validation.field.phone.invalidNumber',
  'model.validation.field.phone.invalidCountryCode',
  'model.validation.field.phone.numberIsTooShort',
  'model.validation.field.phone.numberIsTooLong',
  'model.validation.field.phone.invalidNumber',
];

export const PhoneInput = TextBox.extend({
  constructor() {
    TextBox.apply(this, arguments);
    const props = this.model.__schema__.props[this.options.name] || {};
    props.validate = this.validationDelegate.bind(this);
  },

  /**
   * @Override
   */
  events: {
    'input input': 'update',
    'change input': 'update',
    'keydown input': 'update',
    'countrychange input': 'update',
    'keyup input': function keyupInput(e) {
      if (internal.util.Keys.isEsc(e)) {
        this.model.trigger('form:cancel');
      }
    }
  },

  /**
   * @Override
   */
  postRender() {
    TextBox.prototype.postRender.call(this);
    const params = this.options.params;
    const inputElement = this.$(`input[name="${this.options.name}"]`);
    inputElement.attr('type', 'tel');
    const phoneInputParams = params && params.phoneInputParams || {};
    this.phoneInput = intlTelInput(inputElement[0], {
      ...phoneInputParams,
      utilsScript
    });
  },

  /**
   * @Override
   */
  val() {
    return this.phoneInput.getNumber();
  },

  /**
   * 
   * @param {string} value 
   * @returns {string | boolean} 
   */
  validationDelegate(value) {
    if (!this.phoneInput.selectedCountryData.iso2) {
      return ERROR_PHONE_MAP[1];
    }
    if (!this.phoneInput.isValidNumber() && value) {
      var errorCode = this.phoneInput.getValidationError();
      return ERROR_PHONE_MAP[errorCode] || ERROR_PHONE_MAP[0];
    }
    return true;
  }
});

registerInput(TYPE, PhoneInput);
