<div class="minicartDetails">
  <h4 class="minicartDetails__orderType">
    {{ orderType | removeWhitespace | lowercase | cxTranslate }} ({{ orderCount }})
  </h4>

  <ng-container *ngIf="orderType === orderLabel.STOCK_ORDER || orderType === orderLabel.INDIVIDUAL_ORDER">
    <app-minicart-item-list *ngFor="let eachProductList of cartData" [listData]="eachProductList">
    </app-minicart-item-list>
  </ng-container>

  <ng-container *ngIf="orderType === orderLabel.DIRECT_TO_PATIENT">
    <app-mini-cart-dtp-item-list *ngFor="let dtpEntry of cartData" [dtpEntryData]="dtpEntry">
    </app-mini-cart-dtp-item-list>
  </ng-container>

  <app-mini-cart-value-pack-item-list *ngIf="orderType == orderLabel.VALUE_PACK" [valuePackComponentData]="cartData">
  </app-mini-cart-value-pack-item-list>

  <p *ngIf="orderCount > displayCount && orderType !== orderLabel.DIRECT_TO_PATIENT" class="minicartDetails__moreItem">
    ({{ orderCount - displayCount }}
    {{ (orderCount - displayCount > 1 ? 'miniCartPage.moreitems' : 'miniCartPage.moreitem') | cxTranslate }}
    )
  </p>
</div>
