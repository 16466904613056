import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { OrderHistoryService } from 'src/app/services/order-history/order-history.service';

@Component({
  selector: 'app-patient-profile',
  templateUrl: './patient-profile.component.html',
  styleUrls: ['./patient-profile.component.scss'],
})
export class PatientProfileComponent implements OnInit {
  patientSuccessMessage = '';
  patientOrderhistory;
  orderCount: Observable<boolean> = of(false);

  constructor(private route: ActivatedRoute, private orderService: OrderHistoryService) {}

  ngOnInit(): void {
    this.patientOrderhistory = this.route.snapshot.data.patientOrderhistory;
    this.orderCount = this.orderService.getPatientOrderHistoryCount.pipe(
      startWith(this.patientOrderhistory.orders.length > 0 || this.patientOrderhistory.replenishmentOrders.length > 0)
    );
  }
}
