import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ReturnFormService } from 'src/app/services/returnform/return-form.service';
import { DIRECT_TO_PRACTICE } from 'src/app/shared/constants/constants';
import { DropdownOptions } from 'src/app/shared/model/common.mode';
import { BasicProductSelectedVariants, SKULevelDetails } from 'src/app/shared/model/product.model';
import { ReturnResponse } from 'src/app/shared/model/return-product.model';

@Component({
  selector: 'app-return-form-product-configurator',
  templateUrl: './return-form-product-configurator.component.html',
  styleUrls: ['./return-form-product-configurator.component.scss'],
})
export class ReturnFormProductConfiguratorComponent implements OnInit {
  constructor(private returnFormService: ReturnFormService, private cd: ChangeDetectorRef) {}
  returnProductData: ReturnResponse;
  contactLensType: string;
  quantity = 1;
  returnSKUdetails: SKULevelDetails;
  quantityError = false;
  selectedReason: string;
  returnReasons: Array<DropdownOptions> = [];
  returnVariantTree: string;
  selectedPacksize: any;
  packsizeList = [];
  public selectedReturnProductInfo: Subject<any> = new Subject();
  selectedReturnProductdetails = this.selectedReturnProductInfo.asObservable();
  public deleteReturnProductInfo: Subject<any> = new Subject();
  deleteSelectedReturnProduct = this.deleteReturnProductInfo.asObservable();
  public editReturnProductInfo: Subject<any> = new Subject();
  editSelectedReturnProduct = this.editReturnProductInfo.asObservable();
  isLoading = false;
  isAccordionOpen = true;
  selectedReturnVariants: BasicProductSelectedVariants;
  readonly color = DIRECT_TO_PRACTICE.PRODUCT_COLOR;
  readonly toric = DIRECT_TO_PRACTICE.PRODUCT_TORIC;
  readonly multifocal = DIRECT_TO_PRACTICE.PRODUCT_MULTIFOCAL;
  panelID: number;
  orderNumberexists = false;
  showSpecifications = false;
  isEditPanel = false;
  isProductAdded = false;

  ngOnInit(): void {
    this.isLoading = true;
  }

  set productCode(productCode) {
    this.returnFormService
      .getReturnProductInfo(productCode)
      .pipe(take(1))
      .subscribe((data) => {
        this.returnProductData = data;
        this.returnVariantTree = JSON.parse(this.returnProductData?.returnBaseProductTree);
        this.returnProductData?.reasonCodes.forEach((reason) => {
          this.returnReasons.push({ text: reason, value: reason });
        });
        this.returnReasons = [...this.returnReasons];
        this.packsizeList = this.returnProductData?.packSizeList;
        this.contactLensType = this.returnProductData.contactLensType;
        this.isLoading = false;
        this.cd.detectChanges();
      });
  }
  selectedSKUdetails(skuDetails): void {
    this.returnSKUdetails = skuDetails;
    this.clearValues();
    if (skuDetails === null || !skuDetails?.orderDate) {
      this.selectedReason = '';
    }
  }
  setProductReturnQuantity(value): void {
    this.clearValues();
    if (this.returnSKUdetails && this.returnSKUdetails.quantity && value > this.returnSKUdetails.quantity) {
      this.quantityError = true;
    }
    this.quantity = value;
  }
  changePackSize(event): void {
    this.clearValues();
    this.selectedPacksize = event;
  }
  reasonSelected(event): void {
    this.selectedReason = event.value;
  }
  emitselectedReturnvariants(returnVariantsData): void {
    this.selectedReturnVariants = returnVariantsData;
  }
  saveProduct(): void {
    this.selectedReturnProductInfo.next({
      orderNumber: this.selectedReturnVariants.orders,
      variantCode: this.selectedReturnVariants.variant,
      productCode: this.returnProductData.productCode,
      quantity: this.quantity,
      reason: this.selectedReason,
      entryNumber: this.returnSKUdetails?.entryNumber,
    });
  }
  deleteProduct(): void {
    this.deleteReturnProductInfo.next(this.panelID);
  }
  toggleAccordion(): void {
    this.returnFormService.accordionStatus.next(this.panelID);
  }
  clearValues(): void {
    this.quantityError = false;
    this.orderNumberexists = false;
  }
  editProduct(): void {
    this.isEditPanel = !this.isEditPanel;
    this.isProductAdded = false;
    this.showSpecifications = !this.showSpecifications;
    this.editReturnProductInfo.next(true);
  }
}
