import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CmsService, ContentSlotComponentData } from '@spartacus/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-faq-answer-page',
  templateUrl: './faq-answer-page.component.html',
  styleUrls: ['./faq-answer-page.component.scss'],
})
export class FaqAnswerPageComponent implements OnInit {
  answerInfo$: Observable<ContentSlotComponentData>;
  constructor(private cmsService: CmsService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    const componentId = this.route.snapshot.params?.id;
    this.answerInfo$ = this.cmsService.getComponentData(componentId);
  }
}
