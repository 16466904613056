import { ChangeDetectorRef, Component, EventEmitter, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProductDetailsService } from 'src/app/services/alcon-pdp/product-details.service';
import { VarientSelectorComponent } from 'src/app/shared/classes/variant-selector';
import { DIRECT_TO_PRACTICE, Variants } from 'src/app/shared/constants/constants';
import { DropdownConfig, DropdownOptions } from 'src/app/shared/model/common.mode';
import { BasicProductSelectedVariants, SKULevelDetails } from 'src/app/shared/model/product.model';
@Component({
  selector: 'app-return-form-variant-selector',
  templateUrl: './return-form-variant-selector.component.html',
  styleUrls: ['./return-form-variant-selector.component.scss'],
})
export class ReturnFormVariantSelectorComponent extends VarientSelectorComponent implements OnChanges, OnDestroy {
  OrderNumbers: Array<DropdownOptions> = [];
  VariantCodes: Array<DropdownOptions> = [];
  orderNumber: string;
  private destroy$ = new Subject<void>();
  returnskuInfo: SKULevelDetails;
  readonly color = DIRECT_TO_PRACTICE.PRODUCT_COLOR;
  readonly toric = DIRECT_TO_PRACTICE.PRODUCT_TORIC;
  readonly multifocal = DIRECT_TO_PRACTICE.PRODUCT_MULTIFOCAL;
  orderdropdownConfig: DropdownConfig = {
    defaultSelectText: 'Select order number',
  };
  @Output()
  selectedReturnVariants: EventEmitter<BasicProductSelectedVariants> = new EventEmitter<BasicProductSelectedVariants>();
  constructor(productService: ProductDetailsService, cd: ChangeDetectorRef) {
    super(productService, cd);
    this.ProductCodeSelected.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (data === null) {
        this.selectedVariants.orders = '';
        this.selectedVariants.variant = '';
        this.OrderNumbers = [];
        this.VariantCodes = [];
      }
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.packSize && this.variantTree && (changes.packSize || changes.variantTree)) {
      this.changePackSize(this.packSize);
    }
    if (!this.contactLensType) {
      this.emitValues();
    }
  }
  emitValues(): void {
    this.selectedVariants.variant = '';
    this.selectedVariants.orders = '';
    this.OrderNumbers = [];
    this.VariantCodes = this.productService.formatDropdown(
      this.productService.generateVarientDropdownoptions(
        this.variantTree?.childrenNodes,
        Variants.VARIANTCODE,
        this.selectedVariants
      ),
      Variants.VARIANTCODE,
      true,
      this.selectedVariants
    );
    if (this.VariantCodes.length === 1) {
      this.getOrderNumbers(this.VariantCodes[0].value);
    }
  }
  getOrderNumbers(variantCode): void {
    this.selectedVariants.variant = variantCode;
    this.OrderNumbers = this.productService.formatDropdown(
      this.productService.generateVarientDropdownoptions(
        this.variantTree.childrenNodes,
        Variants.ORDERNUMBER,
        this.selectedVariants
      ),
      Variants.ORDERNUMBER,
      true,
      this.selectedVariants
    );
    if (this.OrderNumbers.length === 1) {
      this.orderNumber = this.OrderNumbers[0].value;
      this.emitReturnVariantDetails();
    } else {
      this.ProductCodeSelected.emit({});
    }
  }
  emitReturnVariantDetails(): void {
    this.selectedVariants.orders = this.orderNumber;
    this.returnskuInfo = this.productService.generateReturnVarientCodes(
      this.variantTree.childrenNodes,
      this.selectedVariants
    );
    this.ProductCodeSelected.emit(this.returnskuInfo);
    this.selectedReturnVariants.emit(this.selectedVariants);
  }
  orderNumberSelected(event): void {
    this.orderNumber = event.value;
    this.emitReturnVariantDetails();
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
