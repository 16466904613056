import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { ProductDetailsService } from 'src/app/services/alcon-pdp/product-details.service';
import { AlconUserPermissionService } from 'src/app/shared-modules/alcon-user-permission-provider/service/alcon-user-permission.service';
import { DIRECT_TO_PRACTICE } from 'src/app/shared/constants/constants';
import { QuantityErrorCodes } from 'src/app/shared/model/product.model';
@Component({
  selector: 'app-basic-product-selector',
  templateUrl: './basic-product-selector.component.html',
  styleUrls: ['./basic-product-selector.component.scss'],
})
export class BasicProductSelectorComponent implements OnInit, OnChanges, OnDestroy {
  product: any;
  @Input() selectedProduct;
  @Input() refreshProductPanel;
  @Input() isValuePack: boolean;
  @Output() selectedProductLensValue: EventEmitter<any> = new EventEmitter<any>();
  @Output() checkboxStatus: EventEmitter<any> = new EventEmitter<any>();
  @Output() Errors: EventEmitter<QuantityErrorCodes | boolean> = new EventEmitter<QuantityErrorCodes | boolean>();
  @Output() changeDtpError: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() disable: boolean;
  @Input() disableCheckbox = false;
  @Input() isValuePackSolution = false;
  @Input() editproductData;
  @Input() panelConfiguration;
  @Input() dtpError: boolean;
  @Input() resetMaxQuantityError?: string;

  public isDTP$: Observable<boolean> = this.productService.receiveDTPFlag();

  private destroySubscriptions$ = new Subject<void>();
  isFavouritePage$: Observable<boolean> = this.activatedRoute.data.pipe(
    map(({ cxRoute = 'undefined' }) => cxRoute !== 'favouritePage')
  );
  showLoader = false;
  qtyFactor = 1;
  maxQuantityLeftLimit: number;
  maxQuantityRightLimit: number;
  maxQuantityError = false;
  trialErrorMsg: string;
  bothEyeErrorMsg = '';
  price$: Observable<any>;
  productPrice: any;
  showPrices = true;
  constructor(
    private activatedRoute: ActivatedRoute,
    private productService: ProductDetailsService,
    private cd: ChangeDetectorRef,
    private userPermissionService: AlconUserPermissionService
  ) {}

  ngOnInit(): void {
    this.showPrices = this.userPermissionService.getPricesVisibilityPermission();
    this.price$ = this.productService.packSizePriceChanged$$.pipe(
      map((array) => array.find((prd) => prd.code === this.selectedProduct)),
      tap((price) => (this.productPrice = price?.price?.value))
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedProduct) {
      this.afterViewInit();
    }
  }

  afterViewInit(): void {
    this.showLoader = false;
    this.productService
      .getProductInfo(this.selectedProduct, DIRECT_TO_PRACTICE.PRACTICE_ORDER_PAGE)
      .pipe(takeUntil(this.destroySubscriptions$))
      .subscribe((data) => {
        this.showLoader = false;
        this.product = data;
        this.cd.detectChanges();
      });
  }

  checkboxStatusChange(checkboxPanelStatus): void {
    this.checkboxStatus.emit(checkboxPanelStatus);
  }

  setPanelErrors(errorMsg): void {
    this.Errors.emit(errorMsg);
  }

  selectedProductLens(product): void {
    this.selectedProductLensValue.emit(product);
  }

  selectedProductQuantity(quantity): void {
    this.productService.calculatePrice(this.productPrice * quantity, this.selectedProduct);
  }

  ngOnDestroy(): void {
    this.destroySubscriptions$.next();
    this.destroySubscriptions$.complete();
    this.productService.removePrice(this.selectedProduct);
    this.productService.removeAddedItem(this.selectedProduct);
  }

  setDtpError(error: boolean): void {
    this.dtpError = error;
    this.changeDtpError.emit(this.dtpError);
  }
}
