import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-mini-cart-product-details',
  templateUrl: './mini-cart-product-details.component.html',
  styleUrls: ['./mini-cart-product-details.component.scss'],
})
export class MiniCartProductDetailsComponent implements OnInit {
  @Input() productData;
  showQuantity;
  constructor() {}

  ngOnInit(): void {
    this.showQuantity = this.productData.orderEntryFlowType.includes('VALUE_PACK') ? false : true;
  }
}
