import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { SemanticPathService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { AlconUserPermissionService } from 'src/app/shared-modules/alcon-user-permission-provider/service/alcon-user-permission.service';

@Injectable({
  providedIn: 'root',
})
export class PdpAccessGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.userPermissionService.getPDPButtonpermission()) {
      this.router.navigate(
        this.semanticPathService.transform({
          cxRoute: 'notFound',
        })
      );
    } else {
      return true;
    }
  }

  constructor(
    private userPermissionService: AlconUserPermissionService,
    private router: Router,
    private semanticPathService: SemanticPathService
  ) {}
}
