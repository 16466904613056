<div class="cart-actions">
  <button class="cart-actions__button cart-actions__button--delete" (click)="deleteItem($event)">
    <cx-icon
      appRemoveFromCart
      title="{{ 'commonTitles.delete' | cxTranslate }}"
      [entries]="cartEntry?.entryNumberList"
      type="Remove"
    >
    </cx-icon>
    <span class="cart-actions__icon-text">{{ 'commonTitles.delete' | cxTranslate }}</span>
  </button>

  <button
    class="cart-actions__button cart-actions__button--edit"
    (click)="updateItem($event)"
    [disabled]="disableEditButton"
  >
    <ng-container *ngIf="disableEditButton; else enabled">
      <cx-icon
        title="{{ 'commonTitles.edit' | cxTranslate }}"
        *ngIf="disableEditButton"
        class="ml-15"
        type="DisabledEdit"
      ></cx-icon>
    </ng-container>
    <ng-template #enabled>
      <cx-icon title="{{ 'commonTitles.edit' | cxTranslate }}" *ngIf="!disableEditButton" class="ml-15" type="CartEdit">
      </cx-icon>
    </ng-template>
    <span class="cart-actions__icon-text">{{ 'commonTitles.edit' | cxTranslate }}</span>
  </button>
</div>
