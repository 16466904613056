<form #form="ngForm" class="franchise" (ngSubmit)="onSubmit(form)">
  <div class="franchise-selector">
    <div class="d-flex flex-column franchise-selector__container">
      <div class="d-flex franchise-selector__row flex-wrap">
        <div class="franchise-selector__radio-button-margin">
          <input
            ngModel
            required
            type="radio"
            value="sx"
            name="SVCFranchise"
            id="surgical-franchise"
            class="alconInput__radio"
          />
          <label for="surgical-franchise" class="franchise-selector__radio-label">{{
            'franchisePage.surgicalProduct' | cxTranslate
          }}</label>
        </div>
      </div>
    </div>
  </div>
  <div class="franchise-selector">
    <div class="d-flex flex-column franchise-selector__container">
      <div class="d-flex franchise-selector__row flex-wrap">
        <div class="franchise-selector__radio-button-margin">
          <input
            ngModel
            required
            type="radio"
            value="vc"
            name="SVCFranchise"
            id="vision-care-franchise"
            class="alconInput__radio"
          />
          <label for="vision-care-franchise" class="franchise-selector__radio-label">{{
            'franchisePage.visionCareProduct' | cxTranslate
          }}</label>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-end">
    <button type="submit" [disabled]="!form.valid" class="btn-primary text-uppercase franchise__button-submit">
      {{ 'franchisePage.next' | cxTranslate }}
    </button>
  </div>
</form>
