import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductDetailsService } from 'src/app/services/alcon-pdp/product-details.service';
import { VarientSelectorComponent } from 'src/app/shared/classes/variant-selector';
import { ProductDetails } from 'src/app/store/States/orderHistory';

@Component({
  selector: 'app-basic-variant-selector',
  templateUrl: './basic-variant-selector.component.html',
  styleUrls: ['./basic-variant-selector.component.scss'],
})
export class BasicVariantSelectorComponent extends VarientSelectorComponent implements OnInit, OnChanges {
  @Input() productData: ProductDetails;
  @Input() editPanel: boolean;
  @Input() disable: boolean;
  constructor(productService: ProductDetailsService, cd: ChangeDetectorRef, private route: ActivatedRoute) {
    super(productService, cd);
  }

  ngOnInit(): void {
    this.selectedVariants.packSize = this.packSize;
    if (this.editPanel) {
      this.getEditPanelDropDowns();
    } else {
      this.getDefaultDropDowns();
    }
    this.pageRoute = this.route?.snapshot?.data?.cxRoute;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.editPanel && this.editPanel) {
      this.productData?.specifications?.forEach((key) => {
        this.updateSelectedVariants(key);
      });
      this.getEditPanelDropDowns();
    }
    if ((changes.packSize || changes.variantTree) && !changes.editPanel) {
      this.changePackSize(this.packSize);
    }
    if (changes.packSize && changes.editPanel) {
      this.editPanelPackSize(this.packSize);
    }
  }
}
