<ng-template cxOutletRef="cx-header">
  <app-new-order-nav *ngIf="isNewOrderDisplay"></app-new-order-nav>
  <cx-page-slot position="PositionSlotMarketingBanner"></cx-page-slot>
  <header>
    <nav class="navbar navbar-expand-md navbar-dark bg-primary fixed-top">
      <ng-container *ngIf="(productSearch$ | async) === false">
        <div class="col-7 col-xl-6 d-flex">
          <cx-page-slot
            class="navbar-brand col-xxl-1 justify-content-center d-block d-sm-none"
            position="PositionSlot1A"
          >
          </cx-page-slot>
          <div class="navbar-brand logo d-none d-sm-block">
            <cx-page-slot class="navbar-brand d-flex justify-content-center" position="PositionSlot1A"></cx-page-slot>
          </div>
          <!-- Need to handle for smaller screens -->
          <div class="col-xl-11 navigation-main">
            <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="navbarsDefault">
              <cx-page-slot position="PositionSlot1B"></cx-page-slot>
            </div>
          </div>
        </div>
        <div class="col-3 col-xl-4 pr-0 d-flex justify-content-end">
          <cx-page-slot position="PositionSlot1G" class="d-flex justify-content-end"></cx-page-slot>
          <cx-page-slot position="PositionSlot1F" class="d-flex justify-content-end"></cx-page-slot>
          <cx-page-slot position="PositionSlot1H" class="d-flex justify-content-end"></cx-page-slot>
          <cx-page-slot position="SafeOrdering" class="d-flex justify-content-end"></cx-page-slot>
          <cx-page-slot position="PositionSlot1C" class="d-flex justify-content-end"></cx-page-slot>
        </div>
      </ng-container>
    </nav>
  </header>
  <div
    class="searchbar__container"
    *ngIf="(productSearch$ | async) === true"
    (click)="vcGlobalsearchoutsideclick($event)"
  >
    <div class="col-10 d-flex searchbar__header">
      <div class="navbar-brand logo d-none d-sm-block">
        <cx-page-slot class="navbar-brand d-flex justify-content-center" position="PositionSlot1A"></cx-page-slot>
      </div>
      <div class="col-7 align-items-center pt-1">
        <app-visioncare-search></app-visioncare-search>
      </div>
    </div>
  </div>

  <footer class="fixed-bottom" *ngIf="routeType !== 'Checkout' && isUserLoggedIn">
    <nav class="navbar navbar-expand-md navbar-dark bg-white shadow-lg row no-gutters">
      <div class="col-10 d-none d-md-block d-xl-none">
        <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse col-10" id="navbarsDefault">
          <cx-page-slot class="col-10" position="PositionSlot1E"></cx-page-slot>
        </div>
      </div>
      <div class="col-10 d-block d-md-none">
        <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="navbarsDefault">
          <cx-page-slot position="PositionSlot1D"></cx-page-slot>
        </div>
      </div>
    </nav>
  </footer>
</ng-template>
<ng-template cxOutletRef="PositionSlot1C" let-model>
  <ng-container *ngIf="model.components$ | async as components">
    <ul class="icons" *ngIf="components.length">
      <li class="icon profile" [ngClass]="{ open: isProfileDisplay }">
        <a href="#" (click)="toggleProfileMenu($event)">
          <icon *ngIf="!isProfileDisplay"><img src="/assets/images/profile.svg" alt="profile" /></icon>
          <icon *ngIf="isProfileDisplay">
            <cx-icon type="closeProfileIcon"></cx-icon>
          </icon>
        </a>
        <div [ngClass]="{ open: isProfileDisplay }">
          <cx-page-slot position="AccountSection"></cx-page-slot>
        </div>
      </li>
    </ul>
  </ng-container>
</ng-template>
