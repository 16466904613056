export const esES = {
  contactlenses: {
    contactlenses: 'Lentes de contacto',
  },
  dailycontactlenses: {
    dailycontactlenses: 'Lentes de contacto diarias',
  },
  monthlycontactlenses: {
    monthlycontactlenses: 'Lentes de contacto mensuales',
  },
  dryeyeproducts: {
    dryeyeproducts: 'Productos para ojos secos',
  },
  contactlenssolutions: {
    contactlenssolutions: 'Soluciones para lentes de contacto',
  },
  contactlenssolution: {
    contactlenssolution: 'Soluciones para lentes de contacto',
  },
  multifocal: {
    multifocal: 'Multifocales',
  },
  colour: {
    colour: 'Color',
  },
  color: {
    color: 'Color',
  },
  spherical: {
    spherical: 'Esférica',
  },
  toric: {
    toric: 'Tóricas',
  },

  common: {
    common: {
      home: 'Inicio',
    },
  },
  cms: {
    login: {
      backToLogin: 'Volver al inicio de sesión',
      backToLoginPage: 'Volver a la página de inicio de sesión',
      emailAddress: 'Dirección de correo electrónico',
      emailPlaceholer: 'Su dirección de correo electrónico',
      emailSentConfirmText: 'Se ha enviado un correo electrónico con las instrucciones para restablecer su contraseña.',
      forgotLink: '¿Ha olvidado su contraseña?',
      forgotPwdText:
        'Introduzca su dirección de correo electrónico y le enviaremos un enlace para cambiar su contraseña:',
      logIn: 'iniciar sesión',
      passwordLbl: 'Contraseña',
      requireEmail: 'Introduzca una dirección de correo electrónico válida',
      requirePassword: 'Introduzca su contraseña',
      sendEmail: 'Enviar',
      forgotYourPwd: '¿Ha olvidado su contraseña?',
    },
    productListingPage: {
      moreFilter: 'Más filtros',
      lessFilter: 'Menos filtros',
      product: 'producto',
      seeAll: 'Ver todo',
      select: 'Seleccionar',
      showMore: 'Mostrar más',
      showLess: 'Mostrar menos',
      sortBy: 'Ordenar por',
      results: 'Resultados',
      products: 'productos',
      nameAZ: 'Nombre A-Z',
      price: 'Precio',
      oxygenPermeability: 'Permeabilidad al oxígeno',
      brand: 'Marca',
      categories: 'Categorías',
      showAll: 'Mostrar todo',
      configureProducts: 'Configurar productos',
      valuepack: 'Packs ahorro',
    },
    productPage: {
      productDescription: 'Descripción del producto',
      productSpecification: 'Especificaciones del producto',
      features: 'Características',
      benefits: 'Beneficios',
      downloads: 'Descargas:',
    },
    stockOrderProductListPage: {
      from: 'de',
    },
    productDetailsPage: {
      privateLabel: 'Etiqueta privada',
      patientReference: 'Referencia del paciente:',
      parametersFor: 'Parámetros para:',
      left: 'Izquierdo',
      right: 'Derecho',
      baseCurve: 'Curva base:*',
      power: 'Dioptría:*',
      add: 'Add*:',
      colour: 'Color*:',
      cylinder: 'Cilindro*:',
      axis: 'Eje*:',
      sameForBothEye: 'Igual para ambos ojos',
      packSize: 'Tamaño del envase:',
      practiceOrder: 'Pedido de consulta',
      individualOrders: 'Pedidos individuales',
      dtpError: 'Este producto solo se puede enviar a una consulta.',
      dtpSummaryError: 'Este pedido solo se puede enviar a una consulta.',
      addressError: 'Indíquenos una dirección correcta',
      directToPatient: 'Directo al paciente',
      errorMessage: 'No pueden introducir más de 18 caracteres en este campo.',
      quantityErrorMsg: 'El número máximo de artículos que se pueden comprar es',
      PurchaseStockOrder: 'Compra del pedido de stock',
      perEye: 'por ojo',
    },
    practiceOrderPage: {
      patientReference: 'Referencia del paciente:',
      parametersFor: 'Parámetros para:',
      left: 'Ojo izquierdo',
      right: 'Ojo derecho',
      baseCurve: 'Curva base:',
      power: 'Dioptría:*',
      add: 'Add:*',
      colour: 'Color:*',
      cylinder: 'Cilindro:*',
      axis: 'Ejes:*',
      sameForBothEye: 'Igual para ambos ojos',
      packSize: 'Tamaño del envase:',
      practiceOrder: 'Pedido de consulta',
      individualOrders: 'Pedidos individuales',
      diameter: 'Diámetro:',
      typeOfWear: 'Tipo de uso:',
      material: 'Material:',
      waterContent: 'Contenido de agua:',
      schedule: 'Programar un pedido recurrente',
      prefer: '¿Prefiere un pack ahorro?',
      errorMessage: 'Ha elegido demasiados artículos. Reduzca la cantidad al máximo permitido.',
      LEFT: 'Ojo izquierdo',
      RIGHT: 'Ojo derecho',
      update: 'ACTUALIZACIÓN',
      cancel: 'Cancelar',
      dtpLimitMessage: 'La cantidad máxima para el envío directo al paciente es',
    },
    favoriteListPage: {
      yourFavProduct: 'Tus productos favoritos',
      products: 'productos',
      product: 'producto',
      noFavProduct:
        'Actualmente no tiene ningún producto favorito en la lista. Para añadir productos a la lista de favoritos haga clic en el icono del corazón junto al producto',
    },
    legalAnnouncement: {
      accept: 'ACEPTAR',
      decline: 'DECLINAR',
    },
    practicePage: {
      mostFrequentOrders: 'Pedidos más frecuentes',
    },
    stockOrderPage: {
      configureProducts: 'Configurar producto',
      packSize: 'Tamaño del envase:*',
      baseCurve: 'Curva base:',
      diameter: 'Diámetro:',
      color: 'Color:',
      add: 'Add:',
      cancel: 'Cancelar',
      lenscylinder: 'Cilindro de la lente de contacto:*',
      moreMinus: 'Más minus (-) dioptrías',
      lessMinus: 'Menos minus (-) dioptrías',
      morePlus: 'Más plus (+) dioptrías',
      lessPlus: 'Menos plus (+) dioptrías',
      clearAll: 'Borrar todo',
      addToCart: 'AÑADIR AL CARRITO',
      addToCartLoading: 'AÑADIR AL CARRITO',
      addToCartSuccess: 'AÑADIDO AL CARRITO',
      updateToCartText: 'ACTUALIZACIÓN',
      updatingCart: 'Actualizar carrito',
      updateToCartSuccess: 'Carrito actualizado',
      maxQty: 'La cantidad máxima total para este producto es',
      errorMessage: 'Ha elegido demasiados artículos. Reduzca la cantidad al máximo permitido.',
      inputMessageError: 'La cantidad debe ser mayor que 0',
      each: 'cada',
      forthisbrand: 'para esta marca',
    },
    miniCartPage: {
      PRACTICE_ORDER: 'Pedidos de consulta',
      INDIVIDUAL_ORDER: 'Pedidos individuales',
      VALUE_PACK: 'Pack ahorro',
      DTP_VALUE_PACK: 'Pack ahorro',
      DIRECT_TO_PATIENT: 'Directo al paciente',
      STOCK_ORDER: 'Pedido de stock',
      title: 'Su carrito',
      RIGHT: 'Derecho',
      LEFT: 'Izquierdo',
      total: 'Total',
      delete: 'Eliminar',
      gotocart: 'Ir al carrito',
      moreitem: 'más artículos',
      totalprice: '00,00 £',
      yourcartIsEmpty: 'Su carrito está vacío',
      goTo: 'Ir a',
      productCatalogue: 'Catálogo de productos',
      orClick: 'o haga clic en',
      orClickthe: 'o haga clic en el',
      newOrder: 'Nuevo pedido',
      editOrder: 'Editar pedido',
      toAddItems: 'para añadir artículos',
      edit: 'Editar',
      patientReference: 'Referencia del paciente',
      quantity: 'Cantidad',
      YouHaveNoProductsInYourCart: 'No tiene productos en su carrito',
      seeProduct: 'Ver productos',
      hideProduct: 'Ocultar productos',
      deliveryMethod: 'Método de envío:',
      poNumber: 'Número de pedido:',
      orderNumber: 'Número de pedido:',
      LP: 'PWR',
      AX: 'AX',
      CY: 'CYL',
      Colour: 'Color',
      DIA: 'DIA',
      BC: 'BC',
      ADD: 'ADD',
      PatientReference: 'Referencia del paciente',
      PowerShipped: 'dioptrías enviadas',
      PowersInProgress: 'dioptrías en progreso',
      cartMessage1: 'No tiene ningún producto añadido a su carrito.',
      cartMessage2: 'Utilice cualquiera de los siguientes métodos para agregar productos a su pedido. ',
      cartMessage:
        'No tiene ningún producto añadido a su carrito. Utilice cualquiera de los siguientes métodos para agregar productos a su pedido. ',
      valuePack: 'Pack ahorro:',
      valuePacks: 'Packs ahorro',
      valuePackDuration: 'Pack ahorro (',
      valuePackMonth: '-meses)',
      moreitems: 'más artículos',
    },
    cartPagev2: {
      INDIVIDUAL_ORDER: 'Pedidos individuales',
      VALUE_PACK: 'Packs ahorro',
      STOCK_ORDER: 'Pedidos de stock',
      DIRECT_TO_PATIENT: 'Directo a los pacientes',
      orderAndPay: 'REALIZAR PEDIDO Y PAGAR',
      simulation: {
        errorMessage:
          'El precio final no se puede calcular en este momento. Una vez resuelto este problema, verá los precios en el historial de pedidos y las facturas.',
      },
      disclaimer:
        'Al hacer clic en REALIZAR PEDIDO Y PAGAR, reconoce y acepta 1) los términos y condiciones de MyAlcon a los que se inscribió cuando registró sus datos para usar este sistema y 2) confirma que cuando se trata de un pedido directo al paciente a) el paciente tiene 16 años o más y b) ha informado al paciente que su información será compartida a través de MyAlcon y ha obtenido el consentimiento del paciente necesario.',
      deliveryCost: 'Gastos de envío',
      taxes: 'Impuestos',
      total: 'Total',
    },
    stockToricPage: {
      lensPower: 'Dioptrías de las lentes de contacto',
      lensAxis: 'Eje de las lentes de contacto',
      message: 'Seleccione la dioptría para ver el eje de la lente de contacto ajustado',
    },
    cartPage: {
      PRACTICE_ORDER: 'Pedidos de consulta',
      summary: 'Resumen',
      goToCheckout: 'PROCEDER AL PAGO',
      ShippingAddress: 'Dirección de envío',
      DeliveryMethod: 'Método de envío',
      calcFinalPrice: 'CALCULAR PRECIO FINAL',
      DIRECT_TO_PATIENT: 'Directo al paciente',
      deliveryMethod: 'Método de envío:',
      deliveryMethodDetails: 'Envío medio (de 2 a 3 días laborables)',
      IndividualOrders: 'Pedidos individuales',
      StockOrders: 'Pedidos de stock',
      ValuePacks: 'Packs ahorro',
      DirecttoPatient: 'Directo a los pacientes',
      products: 'Productos',
      shippingsuggestion: 'El envío se calcula en el momento del pago.',
      patients: 'pacientes',
      externalOrderMsg: 'El precio final aparecerá en la factura',
      cancelOrder: 'CANCELAR PEDIDO',
    },
    orderConfirmation: {
      orderNumber: 'Número de pedido:',
      shippingAddress: 'Dirección de envío:',
      deliveryMethod: 'Método de envío:',
      deliveryMethodDetails: 'Envío estándar por correo',
      poNumber: 'Número de pedido:',
      valuePackQuantity: '1 artículo',
    },
    checkoutPage: {
      paymentMethod: 'Método de pago',
      paymentType1: 'Tarjeta de crédito',
      paymentType2: 'Facturar a la cuenta',
      valuePackQuantity: '1 artículo',
      poNumber: 'Número de pedido:',
      LP: 'Dioptría',
      AX: 'Eje',
      CY: 'Cilindro',
      Colour: 'Color',
      DIA: 'DIA',
      BC: 'BC',
      ADD: 'ADD',
      print: 'Imprimir',
      finalPrice: {
        products: 'Productos',
        delivery: 'Entrega',
        taxes: 'Impuestos',
        total: 'TOTAL',
        placeOrder: 'Realizar pedido',
        obligationText: 'con obligación de pago',
        estimatedDelivery: 'Tiempo estimado de entrega:',
        loaderMessage: 'Espere un momento, estamos recopilando los datos',
      },
      delivery: 'Envío:',
      practiceOrders: 'Pedidos de consulta ',
      practiceOrdersPanel: 'Pedidos individuales, pack de ahorro, pedidos de stock',
      directtoPatients: 'Directo a los pacientes ',
      seeAll: 'Ver todo',
      patients: 'pacientes',
      addNewCardHeading: 'Añadir una nueva tarjeta',
      setDefaultCard: 'Establecer esta tarjeta como predeterminada',
      selectPaymentCard: 'Seleccionar el método de pago',
      selectCardtitle: 'Seleccionar una tarjeta de pago de las guardadas o añadir una nueva.',
    },
    myPatientsPage: {
      addItems: 'PACIENTE +ADD',
      addNewPatient: {
        heading: 'Añadir nuevo paciente',
        editHeading: 'Editar la información del paciente',
        personalData: 'Datos personales',
        mainAddress: 'Dirección principal',
        additionalAddress: 'Dirección adicional',
        form: {
          title: 'Título',
          firstName: 'Nombre *',
          surName: 'Apellidos *',
          email: 'Dirección de correo electrónico',
          phoneNumber: 'Número de teléfono',
          referenceNumber: 'Número de referencia',
          address: {
            street: 'Calle y número *',
            building: 'Edificio, apartamento u otro',
            postcode: 'Código postal *',
            city: 'Ciudad *',
            county: 'Estado',
            notMandatoryCounty: 'Estado',
            country: 'País *',
          },
          mandatoryFields: '* Campos obligatorios',
          additionalAddress: 'Añadir una dirección adicional',
          deleteadditionaladdress: 'Eliminar la dirección adicional',
          clearAll: 'Borrar todo',
          save: 'guardar',
          cancel: 'Cancelar',
          update: 'Actualización',
          errorMessages: {
            title: 'Seleccione un título.',
            mandatoryField: 'Este campo es obligatorio.',
            firstName: `Rellene el nombre del paciente.`,
            surName: `Rellene los apellidos del paciente.`,
            email: 'El formato del correo electrónico no es correcto. Utilice el siguiente formato: john@gmail.com',
            phoneNumber: 'No se pueden usar letras en este campo.',
            phoneNumberMinLength: 'El número de teléfono es demasiado corto. Verifique que están todos los caracteres.',
            moreThanThirtyCharacters: 'No puede introducir más de 30 caracteres en este campo.',
            street: `Rellene la calle del paciente.`,
            buildingNumberName: `Rellene el número del portal del paciente.`,
            postcode: `Rellene el código postal del paciente.`,
            city: `Rellene la ciudad del paciente.`,
            county: `Rellene el estado del paciente.`,
            country: `Rellene el país del paciente.`,
            invalidPattren: 'Código postal no válido',
            selectCountry: 'Seleccione un país',
          },
        },
        patientAddedSuccessfully: 'El paciente se ha añadido correctamente',
      },
      address: 'Dirección',
      showMore: 'Mostrar más',
      showLess: 'Mostrar menos',
      mostRecentOrders: 'Pedidos más recientes',
      order: 'Pedido',
      delivery: 'Entrega',
      shippingAddress: 'Dirección de envío',
      status: 'Estado',
      packtype: 'Tipo de pack',
      date: 'Fecha',
      frequency: 'Frecuencia',
      addOrder: 'Añadir pedido',
      addOrderButton: 'PEDIDO +ADD',
      options: 'Opciones',
      active: 'activo',
      inactive: 'inactivo',
      switchtoSubscription: 'Cambiar a suscripción',
      sort: 'Ordenar:',
      orderHistory: 'Historial de pedidos',
      editPatient: 'Editar paciente',
      deletePatient: 'Eliminar paciente',
      search: 'Buscar el nombre del paciente y n.º de referencia',
      noPatientFound: 'Aún no dispone de pacientes. Añada su primer paciente haciendo clic en el botón a continuación.',
      searchNoPatient: 'No se ha encontrado ningún paciente. Marque si ha escrito toda la información correctamente.',
      top: 'Arriba',
    },
    patientProfilePage: {
      personalData: 'Datos personales',
      mainAddress: 'Dirección principal',
      additionalAddress: 'Dirección adicional',
      edit: 'Editar',
      tel: 'Tel: ',
      eMail: 'Correo electrónico: ',
      refNumber: 'N.º de referencia: ',
      addOrder: 'PEDIDO +ADD',
      noOrder: 'Aún no hay pedidos',
    },
    orderHistoryPage: {
      orderPlaced: 'Pedido realizado',
      orderNo: 'N.º de pedido',
      orderDate: 'Fecha del pedido',
      type: 'Tipo',
      shippingLocation: 'Dirección de envío',
      totalPrice: 'Precio total',
      status: 'Estado',
      invoice: 'Factura',
      search: 'Buscar el número de pedido',
      searchButton: 'buscar',
      timePeriod: 'Periodo de tiempo:',
      dateRange: 'Rango de fechas.',
      selectDate: 'Seleccionar fecha(s)',
      sort: 'Ordenar:',
      reorder: 'VOLVER A PEDIR',
      viewOrderdetails: 'Ver detalles del pedido',
      noOrders: 'No ha realizado ningún pedido de productos aún.',
      noOrderText: 'Cuando envíe un pedido, podrá ver el estado y los detalles de su pedido aquí.',
      filter: {
        ALL: 'Todos',
        ACTIVE: 'Activo',
        COMPLETED: 'Finalizado',
        STATUS: 'Estado',
        filterPlus: 'Más filtros',
        filterMinus: 'Menos filtros',
        orderStatus: {
          NEW: 'Novedad',
          IN_PROGRESS: 'En proceso',
          DELAYED: 'Con retraso',
          SHIPPED: 'Enviado',
          CANCELLED: 'Cancelado',
          PARTIALLY_SHIPPED: 'Envío parcial',
          DELIVERED: 'Entregado',
          PARTIAL_BACKORDER: 'Envío parcial',
          BILLED: 'FACTURADO',
          BACKORDER: 'Con retraso',
        },
      },
      showMore: 'MOSTRÁR 10 MÁS DE',
      noResults: 'No se han encontrado pedidos. Compruebe si ha escrito el número correcto o intente con otro.',
      noResultsFilter: 'No se encontraron pedidos para los criterios de filtro especificados',
      poNumber: 'Número de pedido',
      cancel: 'CANCELAR',
    },
    baseLoginPage: {
      youWereLoggedOut: 'Ha cerrado sesión',
      logoutMsg:
        'Su sesión ha caducado. Para la seguridad de usted y de sus pacientes, se ha cerrado la sesión automáticamente.',
      welcome: 'Bienvenidos',
      backToLogin: 'Volver a la página de inicio de sesión',
    },
    sxRegistration: {
      email: 'Dirección de correo electrónico',
      cancel: 'Cancelar',
      next: 'SIGUIENTE',
      emailVal: 'Introduzca una dirección de correo electrónico válida',
      title: 'Registration to MyAlcon',
      passwordVal: 'Introduzca su contraseña',
      mandatory: '* Campos obligatorios',
      login: 'Iniciar sesión',
      invalidEmail: 'Invalid email address',
      emailExist: 'A user with this {0} already exists',
      phonelong: 'Phone number is too long',
      phoneshort: 'El número de teléfono es demasiado corto',
      select: 'Seleccionar',
      consentLinks: {
        termsCondition: 'https://www.au.alcon.com/terms-use',
        privacyPolicy: 'https://www.au.alcon.com/privacy-policy',
        marketingPrivacyPolicy: 'https://www.au.alcon.com/privacy-policy',
      },
    },
    orderStatus: {
      NEW: 'Novedad',
      IN_PROGRESS: 'En proceso',
      DELAYED: 'Con retraso',
      SHIPPED: 'Enviado',
      CANCELLED: 'Cancelado',
      PARTIALLY_SHIPPED: 'Envío parcial',
      PENDING_CONSOLIDATION: 'Esperando la consolidación de la orden',
      DELIVERED: 'Entregado',
      NOT_SHOWN: 'No se muestra',
      BILLED: 'Facturado',
      PARTIAL_BACKORDER: 'Envío parcial',
      BACKORDER: 'Con retraso',
    },
    confirmEmail: {
      success: 'Correo electrónico confirmado. Nuestro representante de ventas contactará con usted en breve.',
      failure: 'El enlace ha caducado. Rellene el formulario de registro de nuevo.',
    },
    registerPage: {
      registrationFormTitle:
        'Abra una cuenta para obtener acceso 24 horas diarias a pedidos en línea, facturas, declaraciones, seguimiento de pedidos y otras funciones de administración de cuentas.',
      imAlconCustomer: 'Soy cliente de Alcon',
      iDoNotHaveAnAccountTitle: 'Aún no tengo una cuenta en Alcon',
      iDoNotHaveAnAccountDescription:
        '¡Eso no es un problema! ¡Puede dejar sus datos de contacto y el representante de ventas de Alcon se pondrá en contacto con usted en breve!',
      termsAndConditions: 'Términos y condiciones',
      consent: 'Consentimiento',
      termsAndConditionTitle: 'Registro en el comercio electrónico de Alcon',
      termsAndConditionLabel: `He leído y acepto los términos y condiciones establecidos en <a src="#">las Condiciones Generales de venta de Alcon Eye Care UK Limited.</a>*`,
      termsAndConditionDisclaimer: `Alcon tratará siempre sus datos personales de acuerdo con todas las leyes aplicables. </br></br>Alcon forma parte del Grupo Alcon, que es un grupo global de empresas y tiene bases de datos en diferentes países, algunas de las cuales son operadas por la filial local de Alcon, algunas de las cuales son operadas por terceros en nombre de la filial local de Alcon. Alcon puede transferir sus datos a una o más de las bases de datos de sus filiales fuera de su país de residencia, incluidos los países que no requieren un nivel adecuado de protección de sus datos personales en comparación con el proporcionado en el Reino Unido o la UE.</br></br>Alcon no venderá sus datos personales a terceros con ningún fin.</br></br>Si desea ponerse en contacto con nosotros en relación con el uso de sus datos personales, cambiar sus preferencias u oponerse al procesamiento de sus datos personales, envíenos un correo electrónico a privacy.uk@alcon.com.`,
      setUpAnAccount: 'Crear una cuenta',
      TypeOfProducts: 'Tipo de productos*',
      title: 'Título',
      firstName: 'Nombre*',
      lastName: 'Apellidos*',
      fullCompanyName: 'Nombre completo de la empresa*',
      email: 'Dirección de correo electrónico de la cuenta de MyAlcon Store*',
      accountNumber: 'Número de cuenta de Alcon*',
      next: 'Siguiente',
      back: 'Atrás',
      cancel: 'Cancelar',
      submit: 'Enviar',
      mandatory: '* Campos obligatorios',
      successText: `Una copia de este formulario será enviada a su bandeja de entrada.</br></br>Tenga en cuenta que se puede tardar hasta tres días hábiles en configurar una nueva cuenta desde el día en que recibimos el formulario completado, incluyendo un número válido de GOC o GMC.`,
      successTitle:
        'Gracias por completar su formulario de solicitud para abrir una cuenta con Alcon Eye Care UK Limited.',
      goToLoginPage: 'Volver a la página de inicio de sesión',
      errorMessages: {
        mandatoryField: 'Este campo es obligatorio.',
        maxLengthReached: 'No puede introducir más de {{characterNumber}} caracteres en este campo.',
        couldContainsOnlyNumbers: 'No se pueden usar letras en este campo.',
        firstName: 'Rellene el nombre',
        lastName: 'Rellene los apellidos',
        fullCompanyName: 'Introduzca el nombre completo de la empresa',
        email: 'Introduzca la dirección de correo electrónico',
        emailPattern: 'El formato del correo electrónico no es correcto. Utilice el siguiente formato: john@gmail.com',
        accountNumber: 'Introduzca el número de cuenta de Alcon',
        termsAndConditions: 'Acepte los campos obligatorios',
        responseError: 'Hay algún problema con su solicitud. Inténtalo de nuevo o contacta con atención al cliente',
      },
    },
    contactusPage: {
      pleaseSelectATopic: 'Seleccione un tema:*',
      typeYourMessageHere: 'Escriba aquí su mensaje:*',
      contactMethod: 'Seleccione su método de contacto preferido para esta petición:*',
      contactMethodLabel1: 'Correo electrónico',
      contactMethodLabel2: 'Teléfono',
      emailAddress: 'Dirección de correo electrónico',
      emailInfo:
        'La información proporcionada no cambiará los datos de contacto asociados con su cuenta. Puede cambiar su número de teléfono en Mi perfil o ponerse en contacto con el servicio de atención al cliente para cambiar su correo electrónico.',
      termsConditions: `Alcon sólo empleara la información proporcionada para contestar a su solicitud. He leído la Política de Privacidad de Alcon y acepto sus términos.*`,
      preferredTime: 'Seleccione su franja horaria preferida:*',
      preferredTimeLabel1: 'Por la mañana',
      preferredTimeLabel2: 'Por la tarde',
      contactName: 'Proporcione un nombre de contacto:*',
      contactNameLabel: ' Nombre del contacto',
      fullCompanyName: 'Nombre completo de la empresa',
      returnToLastVisitedPage: 'Volver a la última página visitada',
      mandatoryFields: '* Campos obligatorios',
      provideContactName: 'Proporcione un nombre de contacto:*',
      contactusSubmit: 'Enviar',
      phoneNumber: 'Número de teléfono',
      characters: '1000 caracteres',
      thankyoumsg: 'Gracias por su envío',
      additionalmsg: 'Un representante de Alcon se encargará de esta cuestión y contactará con usted en 1 día laboral.',
      returntoHome: 'Volver a la página de inicio',
      returnProdct: 'Devoluciones de productos',
      gotoform: 'Ir al formulario',
      selectFile: 'Seleccionar archivo',
      errorMessages: {
        topic: 'Seleccione un tema.',
        mandatoryField: 'Este campo es obligatorio.',
        message: `Escriba su mensaje.`,
        contactmethod: 'Seleccione al menos un método de contacto',
        email: 'Introduzca el correo electrónico',
        emailPattren: 'El formato del correo electrónico no es correcto. Utilice el siguiente formato: john@gmail.com',
        phoneNumber: `Introduzca el número de teléfono.`,
        phoneNumberCharecters: 'No se pueden usar letras en este campo.',
        phoneNumberMinLength: 'El número de teléfono es demasiado corto. Verifique que están todos los caracteres.',
        preferredTime: 'Indique su franja horaria preferida',
        contactName: 'Introduzca el nombre de contacto',
        termsConditions: 'Seleccione los términos y condiciones',
        contactNamePattern: 'No puede introducir más de 30 caracteres en este campo',
      },
    },
    selectProductPage: {
      selectPackType: 'Seleccione el tipo de pack',
      selectProducts: 'Seleccione los productos',
      regular: 'Normal',
      valuePack: 'Pack ahorro',
      chooseLenses: 'Elegir lentes de contacto:*',
      patientName: 'Paciente: ',
      valuePacktooltip: 'Ciertas lentes de contacto y soluciones se ofrecen como un cómodo conjunto.',
      additionalProducts: 'Elegir productos adicionales:',
      contactLens: 'Solución para lentes de contacto',
      dryEye: 'Productos para ojos secos',
      addAnotherproduct: 'Añadir otro producto',
      mandatory: '* Campos obligatorios',
      addOtherLenses: 'AÑADIR OTRAS LENTES DE CONTACTO',
      valuePackAttributes: {
        threeMonthValuePack: 'Pack para 3 meses',
        sixMonthValuePack: 'Pack para 6 meses',
        chooseSolution: 'Elegir solución:*',
      },
      patientOrderPanel: {
        delivery: '3. Seleccione el método de envío',
        practiceOrder: 'Pedido de consulta',
        directToPatient: 'Directo al paciente',
        selectOrderType: 'Seleccionar tipo de pedido',
        individual: 'Individual',
        total: 'Total:',
        addToCart: 'AÑADIR AL CARRITO',
        addToCartSuccess: 'AÑADIDO AL CARRITO',
        cancel: 'Cancelar',
        address: 'Dirección:',
        delivertodiffAddress: 'Enviar a una dirección diferente',
        selectdeliveryAddress: 'Seleccionar la dirección de envío:',
        dropdown: {
          buttonText: 'Añadir paciente',
        },
        scheduled: {
          orderType: 'Programado',
          repeatEveryText: 'Repetir cada:',
          week: 'semanas',
          month: 'meses',
          startDate: 'Fecha de inicio:',
          save: 'Guardar',
          startSubscriptions: 'COMENZAR LAS SUSCRIPCIONES',
          addToCartNow: 'AÑADIR AL CARRITO AHORA',
        },
        title: `La adición al carrito ha fallado.`,
        description: `Los artículos no se han añadido al carrito. <br> Solucione el problema e inténtelo de nuevo.`,
        redirectButton: 'Aceptar',
      },
      tooltipText: `Puede elegir un segundo producto si ha seleccionado ambos ojos. Si desea añadir otro producto para el mismo paciente desmarque "un ojo".`,
    },
    safeOrderingPage: {
      safeOrdering: 'Pedidos seguros ',
    },
    configureProducts: {
      deliveryPanel: {
        delivery: 'Entrega',
        selectDelivery: 'Seleccione el método de envío',
        practiceOrder: 'Pedido de consulta',
        directToPatient: 'Directo al paciente',
        patientReferencePlaceholder: 'Tipo de referencia',
        patientReference: 'Referencia del paciente:',
        patientReferenceMandatory: 'Referencia del paciente:*',
        needStockOrder: '¿Necesita hacer pedido de stock?',
        practiceErrorMessage: 'No pueden introducir más de 30 caracteres.',
        noResultsFound: 'No se han encontrado resultados',
        selectFromList: 'Seleccione de la lista',
        deliveryToDifferentAddress: 'Enviar a una dirección diferente',
      },
    },
    orderDetailsPage: {
      orderNo: 'N.º de pedido',
      shipped: 'Enviado',
      inProgess: 'En proceso',
      delivered: 'Entregado',
      ACCOUNT: 'Facturar a la cuenta',
      CARD: 'Tarjeta de crédito',
      billOnly: 'Solo facturar',
      products: 'Productos',
      statusPanel: {
        partiallyShipped: 'Envío parcial',
        trackingno: 'N.º de seguimiento:',
      },
      paymentMethodPanel: {
        orderPlaced: 'Pedido realizado:',
        orderDate: 'Fecha del pedido:',
        orderSource: 'Origen del pedido:',
        paymentMethod: 'Método de pago:',
        cardEndingWith: 'La tarjeta termina en ****',
        invoiceno: 'N.º de factura',
        addNewCard: 'Añadir una nueva tarjeta',
        noCreditCard: 'No tiene tarjetas de crédito enlazadas con esta cuenta.',
        Change: 'Cambio',
        useOneTime: 'UTILICE LA TARJETA UNA VEZ',
        useAndSaveLater: 'USE Y GUARDE PARA MÁS ADELANTE',
        useSelectedCard: 'USE LA TARJETA SELECCIONADA',
        useAndSaveDefault: 'USAR Y GUARDAR COMO PREDETERMINADA',
        useNewCard: 'USAR UNA TARJETA NUEVA',
      },
      deliveryMethodPanel: {
        practiceOrders: 'Pedidos de consulta',
        deliveryMethod: 'Método de envío:',
        shippingAddress: 'Dirección de envío:',
        poNumber: 'Número de pedido:',
        DTPOrders: 'Directo al paciente',
      },
      finalPrice: {
        reOrder: 'volver a pedir',
      },
      orderItemPage: {
        INDIVIDUAL_ORDER: 'Pedidos individuales',
        STOCK_ORDER: 'Pedido de stock',
        VALUE_PACK: 'Pack ahorro',
        total: 'Total',
        item: '{{count}} artículo',
        item_plural: '{{count}} artículos',
        materialNumber: 'N.º de material',
        serialNumber: 'N.º de serie',
        IOL_Bill: 'Factura IOL',
        diopter: 'Dioptría',
        exptDeliveryDate: 'Fecha estimada de entrega',
        estDeliveryDate: 'Fecha estimada de entrega',
        deliveryDate: 'Fecha de entrega',
        'labelstatus_In transit': 'En tránsito:',
        'labelstatus_In delivery': 'En reparto:',
        labelstatus_delivered: 'Entregado:',
        labelstatus_undefined: '',
        fromitem: '{{othercount}} de {{count}} artículos',
        tooltip:
          '{{othercount}} de {{count}} artículos están {{status}}. El resto de los artículos tienen diferentes estatus',
      },
    },
    selectDeliveryAddressPage: {
      mainaddress: 'Dirección principal',
      additonaladdress: 'Dirección adicional',
      edit: 'Editar',
      cancel: 'Cancelar',
      save: 'Guardar',
      mandatory: '* Campos obligatorios',
    },
    patientAddressPage: {
      heading: 'Dirección:',
      form: {
        address: {
          street: 'Calle y número *',
          building: 'Edificio, apartamento u otro',
          postcode: 'Código postal *',
          city: 'Ciudad *',
          county: 'Estado',
          notMandatoryCounty: 'Estado',
          country: 'País *',
        },
        mandatoryFields: '* Campos obligatorios',
        additionalAddress: 'Añadir una dirección adicional',
        clearAll: 'Borrar todo',
        save: 'guardar',
        cancel: 'Cancelar',
        update: 'Actualización',
        deleteadditionaladdress: 'Eliminar la dirección adicional',
        errorMessages: {
          mandatoryField: 'Este campo es obligatorio.',
          street: `Rellene la calle del paciente.`,
          buildingNumberName: `Rellene el número del portal del paciente.`,
          postcode: `Rellene el código postal del paciente.`,
          city: `Rellene la ciudad del paciente.`,
          county: `Rellene el estado del paciente.`,
          country: `Rellene el país del paciente.`,
          invalidPattren: 'Código postal no válido',
          selectCountry: 'Seleccione un país',
        },
      },
    },
    recentOrders: {
      lastOrders: 'Últimos pedidos',
      latestOrders: 'Últimos pedidos',
      seeMore: 'Ver más del historial de pedidos',
      noOrders: `No tiene ningún pedido.`,
      noOrderText: 'Cuando envíe un pedido, podrá ver el estado y los detalles de su pedido aquí. ',
      noOrdersSX: `No ha realizado ningún pedido de productos aún. Cuando envíe un pedido, podrá ver el estado y los detalles de su pedido aquí.`,
    },
    myProfilePage: {
      emailAddress: 'Dirección de correo electrónico:',
      phoneNumber: 'Número de teléfono:',
      password: 'Contraseña',
      changepassword: 'Cambiar contraseña',
      edit: 'Editar',
    },
    contactInformation: {
      title: 'Título',
      firstName: 'Nombre*',
      lastName: 'Apellidos*',
      email: 'Dirección de correo electrónico',
      phonenumber: 'Número de teléfono',
      cancel: 'Cancelar',
      save: 'Guardar',
      errorMessages: {
        requireFirstName: 'Rellene el nombre',
        moreThanThirtyCharacters: 'No puede introducir más de 30 caracteres en este campo.',
        requireLastName: 'Rellene los apellidos',
        phoneNumber: 'No se pueden usar letras en este campo.',
        phoneNumberMinLength: 'El número de teléfono es demasiado corto. Verifique que están todos los caracteres.',
      },
    },
    changePassword: {
      currentpassword: 'Contraseña actual*',
      newpassword: 'Nueva contraseña*',
      reenterpassword: 'Vuelva a introducir la nueva contraseña*',
      mandatory: '* Campos obligatorios',
      showpassword: 'Mostrar contraseña',
      cancel: 'Cancelar',
      save: 'Guardar',
      suggestion: 'Usa 8 o más caracteres con letras, números y símbolos',
      errorMessages: {
        requireCurrentpassword: 'Introduzca la contraseña actual',
        requireNewPassword: 'Introduzca una nueva contraseña',
        requireReEnterpassword: 'Vuelva a introducir la nueva contraseña',
        match: `Las contraseñas no coinciden. Vuelva a intentarlo.`,
        strong: 'Elija una contraseña más segura. Pruebe con una mezcla de letras, números y símbolos.',
        maxCharcters: 'Usa 8 caracteres o más para su contraseña.',
      },
    },
    patientOrderHistory: {
      io: 'Pedidos individuales ',
      subscription: 'Suscripciones ',
      switchSub: 'Cambiar a suscripción',
      nof: 'No se encontraron pedidos en el período de tiempo especificado',
      orderNo: 'N.º de pedido',
      mainAddress: 'Dirección principal',
      addAddress: 'Dirección adicional',
      orderPlaced: 'Pedido realizado:',
      orderSent: 'Pedido enviado:',
      nextOrder: 'Próximo pedido:',
      subCreated: 'Se ha creado la suscripción:',
      every: 'Cada',
      active: 'ACTIVO',
      inactive: 'INACTIVO',
      noOrdersYet: 'Aún no hay pedidos',
      oneTime: 'Una vez',
      frequency: 'Frecuencia',
    },
    accountDetailsPage: {
      requestChangeAddress: 'Solicite un cambio de dirección',
      shippingAddress: 'Dirección de envío',
      billingAddress: 'Dirección de facturación',
      orderconsolidationenabled: 'Consolidación de pedido habilitada',
      alconAccount: 'Cuenta de Alcon',
      shippingAccount: 'Shipping account',
    },
    commonTitles: {
      edit: 'Editar',
      delete: 'Eliminar',
    },
    visioncareSearch: {
      searchFor: 'Buscar para:',
    },
    subscriptions: {
      nosubscriptions: `No tiene ninguna suscripción`,
      subscriptions: 'Suscripciones',
      allPatients: 'Todos los pacientes',
      subscriptionMsg:
        'Las suscripciones con pedidos programados que se envían directamente a los pacientes o a una consulta. ',
    },
    returnForm: {
      noProducts: 'No hay productos para devolver',
      searchProducts: 'Busque un producto o selecciónelo de la lista',
      selectProductHeader: 'Seleccione el producto que desea devolver:',
      addAnotherProduct: 'Añada otro producto para devolver:',
      baseCurve: 'Curva base:*',
      power: 'Dioptría:*',
      add: 'Add*:',
      colour: 'Color*:',
      cylinder: 'Cilindro*:',
      axis: 'Eje*:',
      packSize: 'Tamaño del envase:',
      diameter: 'Diámetro:',
      ordernumber: 'N.º de pedido:*',
      quantity: 'Cantidad:*',
      mandatory: '* Campos obligatorios',
      delete: 'Eliminar producto',
      save: 'GUARDAR PRODUCTO',
      returnReason: 'Motivo de la devolución',
      dateoforder: 'Fecha del pedido:',
      reasonMandatory: 'Indique el motivo de la devolución de los productos *',
      selectreason: 'Elija un motivo',
      quantityError:
        'No puede elegir más productos por el tipo de producto que ha pedido. Si está seguro de la cantidad del producto, verifique el número de pedido',
      orderNumberError:
        'Este número de pedido ya se utilizó en su formulario de devolución. Cambie su número de pedido o elimine este artículo',
      returnproduct: 'DEVOLVER PRODUCTOS',
      specifications: {
        baseCurve: 'Curva base:',
        power: 'Dioptría:',
        add: 'Add:',
        colour: 'Color:',
        cylinder: 'Cilindro:',
        axis: 'Eje:',
        packSize: 'Tamaño del envase:',
        diameter: 'Diámetro:',
        ordernumber: 'N.º de pedido:',
        quantity: 'Cantidad:',
      },
    },
    statements: {
      statementForDownload: 'Declaraciones para descargar',
      tooltipText: 'La primera declaración estará disponible a principios del próximo mes.',
      downloadText: 'Descargar declaración (PDF)',
    },
    reOrderValidation: {
      heading: 'Hay un problema con la repetición de su pedido',
      ok: 'Aceptar',
      valuePackQauntity: '1 artículo',
      itemDiscontinued: 'Contacte con atención al cliente',
      maxLimit: 'Envíe su carrito actual antes de volver a pedir productos o reconfigurar su pedido',
      patientDeleted: 'El paciente fue eliminado y ya no está disponible para los pedidos',
      valuPackexist: 'Envíe su carrito actual antes de volver a pedir productos',
      GENERIC_API_ERRORMESSAGE:
        'Los artículos no se han añadido al carrito. Inténtelo de nuevo más tarde o póngase en contacto con atención al cliente',
      DIRECT_TO_PATIENT_MESSAGE: `Se agregaron artículos al carrito para la dirección principal del paciente. Verifique la dirección en el carrito`,
      GENERIC_MESSAGE: 'Los artículos no se han añadido al carrito',
    },
    loginPage: {
      email: 'Dirección de correo electrónico',
      cancel: 'Volver al inicio de sesión',
      emailVal: 'Introduzca una dirección de correo electrónico válida',
      passwordVal: 'Introduzca su contraseña',
      login: 'Iniciar sesión',
    },
  },
};
