<div class="row">
  <div class="col">
    <app-basic-product-selector
      *ngIf="clcDryeyeFlag"
      [isDTP]="isDTP"
      [dtpError]="dtpError"
      [selectedProduct]="productCode"
      [refreshProductPanel]="refreshProductPanel"
      [disable]="disable | async"
      [resetMaxQuantityError]="resetMaxQuantityError"
      (selectedProductLensValue)="selectedProductLensValue($event)"
    >
    </app-basic-product-selector>
    <app-product-wrapper
      *ngIf="!clcDryeyeFlag"
      [isDTP]="isDTP"
      [dtpError]="dtpError"
      (changeDtpError)="dtpError = $event"
      [selectedProduct]="productCode"
      [refreshProductPanel]="refreshProductPanel"
      [disable]="disable | async"
      (selectedProductValueEmit)="selectedProductLensValue($event)"
    ></app-product-wrapper>
  </div>
  <div class="col d-flex justify-content-lg-end justify-content-xl-end">
    <app-delivery-panel
      class="d-block"
      [selectedProductValue]="selectedProductValue"
      [dtpError]="dtpError"
      (changeAddressTypeEmitter)="changeAddressType($event)"
      (emitCloseModal)="closeModal()"
      (setToDefaults)="setToDefaults($event)"
      (changedDeliveryType)="changeDeliveryType($event)"
    ></app-delivery-panel>
  </div>
</div>
