import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { Address } from '@spartacus/core';

@Component({
  selector: 'app-address-block',
  templateUrl: './address-block.component.html',
  styleUrls: ['./address-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressBlockComponent {
  /** Provide address object */
  @Input() address: Address = {};

  /** Used for specifying text size (sm, md) */
  @Input() textSize = 'sm';

  /** Adds vertical spacing to items (sm, md) */
  @Input() itemGap = 'sm';

  @HostBinding('class.text-size-sm') get textSizeSm(): boolean {
    return this.textSize === 'sm';
  }

  @HostBinding('class.text-size-md') get textSizeMd(): boolean {
    return this.textSize === 'md';
  }

  @HostBinding('class.item-gap-sm') get itemGapSm(): boolean {
    return this.itemGap === 'sm';
  }

  @HostBinding('class.item-gap-md') get itemGapMd(): boolean {
    return this.itemGap === 'md';
  }

  /** Used for specifying whether to remove/add line-height from text  */
  @Input()
  @HostBinding('class.line-height-none')
  removeLineHeight = false;
}
