<div class="row register-success justify-content-center">
  <div class="col-5 p-0 p-lg-2 pb-5 pb-lg-0">
    <h1 class="register-success__title d-none d-lg-block">
      <cx-page-slot position="LeftPane1A"></cx-page-slot>
    </h1>
    <div class="mr-4 mr-lg-0">
      <p class="register-success__text" [innerHTML]="(par1$ | async)?.content"></p>
      <p class="register-success__text" [innerHTML]="(par2$ | async)?.content"></p>
      <p class="register-success__text" [innerHTML]="(par3$ | async)?.content"></p>
      <button
        class="btn btn-primary text-uppercase register-success__goToLogin"
        type="submit"
        [routerLink]="{ cxRoute: (button$ | async)?.url } | cxUrl"
      >
        {{ (button$ | async)?.linkName }}
      </button>
    </div>
  </div>
  <cx-media
    class="register-success__image col-5 align-self-center float-right p-0"
    [container]="(image$ | async)?.media"
  ></cx-media>
</div>
