import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PACK_SIZE } from 'src/app/shared/constants/constants';
import { PageType } from 'src/app/shared/model/common.mode';
import { PackSize } from 'src/app/shared/model/product.model';
@Component({
  selector: 'app-product-pack-size',
  templateUrl: './product-pack-size.component.html',
  styleUrls: ['./product-pack-size.component.scss'],
})
export class ProductPackSizeComponent implements OnInit, OnChanges {
  @Input() packSizeList: Array<PackSize>;
  @Input() isSolutionProducts: boolean;
  @Input() selectedPackSize: string;
  @Input() disabledPackSizeList;
  @Input() packStyles?: object;
  @Input() disable: boolean;
  @Output() packSizeChanged: EventEmitter<any> = new EventEmitter();
  packSize: Array<PackSize> = [];
  isPacksizeChanged = false;
  @Input() idPrefix: number;
  trialOrMlProduct: boolean;
  isValuePackType: boolean;
  returnFormCreatePage: any;
  pageType: any;
  constructor(private router: ActivatedRoute) {}

  ngOnInit(): void {
    this.pageType = this.router.snapshot.data.cxRoute;
    this.returnFormCreatePage = this.pageType.toLowerCase() === PageType.RETURN_FORM_CREATE_PAGE.toLowerCase();
    this.trialOrMlProduct = this.selectedPackSize?.toLowerCase().includes('trial') || this.isSolutionProducts;
    this.isValuePackType =
      this.selectedPackSize?.toLowerCase().includes('3-month pack') ||
      this.selectedPackSize?.toLowerCase().includes('6-month pack');
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.packSizeList) {
      if (this.packSizeList?.length > 0) {
        this.selectedPackSize = '';
        this.packSize = [];
        this.setPackSize(this.packSizeList, this.isSolutionProducts);
      }
    }
    this.disablePackSize();
  }

  disablePackSize(): void {
    if (!this.disabledPackSizeList) {
      return;
    }
    this.packSize.forEach((packSize) => {
      const disabledPackSize = this.disabledPackSizeList.find((packValue) => packSize.value.includes(packValue));
      packSize.isEnabled = !(disabledPackSize && disabledPackSize.length > 0);
    });
  }

  changePackSize(): void {
    this.packSizeChanged.emit(this.selectedPackSize);
    this.isPacksizeChanged = true;
    this.trialOrMlProduct = this.selectedPackSize.toLowerCase().includes('trial') || this.isSolutionProducts;
  }

  setPackSize(packSize: Array<PackSize>, isSolutionProducts: boolean): void {
    this.packSize = [...packSize]
      .map((pack) => {
        return {
          ...pack,
          value: pack.value.toLowerCase() === PACK_SIZE.TRIAL.toLowerCase() ? '0' : pack.value,
          factor: pack.factor === 'null' ? '1' : pack.factor,
        };
      })
      .sort((a, b) => {
        return Number(a.value) * Number(a.factor) - Number(b.value) * Number(b.factor);
      })
      .map((pack) => {
        pack.value = pack.value === '0' ? PACK_SIZE.TRIAL + '_' + pack.trialCount : pack.value;
        pack.isEnabled = true;
        pack.display = pack.value.includes(PACK_SIZE.TRIAL)
          ? PACK_SIZE.TRIAL + ' (' + pack.trialCount + ')'
          : pack.value;
        pack.selected = pack.selected;
        if (isSolutionProducts) {
          pack.unit = pack?.unit;
          pack.display = pack.factor === '1' ? '' : `${pack.factor} x`;
          pack.display = `${pack.display} ${pack.value}`;
          pack.display = pack.display.trim();
        }
        return pack;
      });
    let selected: PackSize | Array<PackSize> = this.packSize.find((pack) => pack.selected);
    // below logic as per story CEBI-4382
    if (!selected) {
      [selected] =
        this.packSize.length === 1
          ? this.packSize
          : this.packSize.filter((pack) => !pack.value.includes(PACK_SIZE.TRIAL));
    }
    this.selectedPackSize = selected.value;
    this.packSizeChanged.emit(this.selectedPackSize);
  }
}
