import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-minicart-items-dtp',
  templateUrl: './minicart-items-dtp.component.html',
  styleUrls: ['./minicart-items-dtp.component.scss'],
})
export class MinicartItemsDtpComponent implements OnInit {
  @Input() item;

  constructor() {}

  ngOnInit(): void {}
}
