import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CartItemsService } from '../../services/cart-items/cart-items.service';
import {
  AddToCart,
  ErrorToDoAction,
  FinalPriceSimulated,
  GetFinalPriceSimulation,
  LoadItems,
  ReloadCart,
  Remove,
  RemoveFromCart,
  RetrievCartList,
  SaveCart,
  SaveValuePackCart,
} from '../Actions/cartItem.action';
import { VisionCareCartItems } from '../States/cartItem.state';

@Injectable()
export class CartEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private cartItemsService: CartItemsService,
    private router: Router
  ) {}

  loadCartItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoadItems),
      mergeMap(() =>
        this.cartItemsService.getCartItems().pipe(
          map((cart: VisionCareCartItems) => {
            return RetrievCartList({ vCcartItems: cart });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });
  AddToCart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SaveCart),
      mergeMap((action) =>
        this.cartItemsService.addTocart(action).pipe(
          map((data: VisionCareCartItems) => {
            return AddToCart({ vCcartItems: data });
          }),
          catchError((error: Error) => {
            return of(ErrorToDoAction(error));
          })
        )
      )
    );
  });

  SaveValuePackCart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SaveValuePackCart),
      mergeMap((action) =>
        this.cartItemsService.addTocartForValuePack(action).pipe(
          map((data: VisionCareCartItems) => {
            return AddToCart({ vCcartItems: data });
          }),
          catchError((error: Error) => {
            return of(ErrorToDoAction(error));
          })
        )
      )
    );
  });

  SimulateFinalPrice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GetFinalPriceSimulation),
      mergeMap(() =>
        this.cartItemsService.simulateFinalPrice().pipe(
          map((data: any) => {
            return FinalPriceSimulated({ simulatedFinalPriceCartItems: data });
          }),
          catchError((error: Error) => {
            return of(ErrorToDoAction(error));
          })
        )
      )
    );
  });
  RemoveFromCart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(Remove),
      mergeMap((action) =>
        this.cartItemsService.removeFromCart(action.entries).pipe(
          map((data: VisionCareCartItems) => {
            return RemoveFromCart({ vCcartItems: data });
          }),
          catchError((error: Error) => {
            return of(ErrorToDoAction(error));
          })
        )
      )
    );
  });
  reloadCartItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReloadCart),
      mergeMap(() =>
        this.cartItemsService.getCartItems().pipe(
          map((cart: VisionCareCartItems) => {
            this.cartItemsService.reloadCartData(cart);
            return RetrievCartList({ vCcartItems: cart });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });
}
