<div class="value-pack-items" *ngIf="valuePack">
  <div class="d-flex justify-content-between value-pack-items__wrapper" *ngFor="let valuePackItem of valuePack">
    <div class="d-flex">
      <div class="d-flex flex-column">
        <img
          class="value-pack-items__image"
          [attr.src]="valuePackItem.image?.url"
          alt="{{ valuePackItem.productName }}"
        />
      </div>
      <div [ngClass]="orderDetailsPage ? 'value-pack-items__details-page' : 'value-pack-items__details'">
        <app-cart-product-details [cartEntry]="valuePackItem"> </app-cart-product-details>
      </div>
    </div>
    <div class="d-flex m-w value-pack-items__cartrefCenter">
      <div
        [ngClass]="
          valuePackItem?.shippingAddress && valuePackItem.orderEntryFlowType === orderEntryFlowType.DTP_VALUE_PACK
            ? 'value-pack-items__dtp-reference'
            : 'value-pack-items__reference'
        "
        *ngIf="valuePackItem.eyeSight?.toLowerCase() === 'right'"
      >
        <label
          *ngIf="valuePackItem?.patientName && !isOrderConfirmationPage && !orderDetailsPage"
          [ngClass]="
            valuePackItem?.shippingAddress && valuePackItem.orderEntryFlowType === orderEntryFlowType.DTP_VALUE_PACK
              ? 'value-pack-items__patient-name-dtp'
              : 'value-pack-items__patient-individual'
          "
        >
          {{ valuePackItem?.patientName }}</label
        >
        <div
          *ngIf="
            valuePackItem?.shippingAddress &&
            valuePackItem.orderEntryFlowType === orderEntryFlowType.DTP_VALUE_PACK &&
            !isOrderConfirmationPage &&
            !orderDetailsPage
          "
          [ngClass]="
            valuePackItem?.shippingAddress ? 'value-pack-items__patient-dtp' : 'value-pack-items__patient-individual'
          "
        >
          {{ valuePackItem.shippingAddress?.line1 }} {{ valuePackItem.shippingAddress?.line2 }}<br />
          {{ valuePackItem.shippingAddress?.town }} {{ valuePackItem.shippingAddress?.postalCode }}<br />
          {{ valuePackItem.shippingAddress?.region?.name }} {{ valuePackItem.shippingAddress?.country?.name }}
        </div>
      </div>
      <div class="flex-column value-pack-items__counter" *ngIf="valuePackItem.eyeSight?.toLowerCase() === 'right'">
        <div *ngIf="cartPage">
          <cx-icon type="DisabledMinusCounter"> </cx-icon>
          <input class="value-pack-items__counter__value" type="text" value="1" disabled />
          <cx-icon type="DisabledPlusCounter"> </cx-icon>
        </div>
        <span *ngIf="!cartPage" class="value-pack-items__counter--quantity">{{
          'checkoutPage.valuePackQuantity' | cxTranslate
        }}</span>
      </div>
      <div
        [ngClass]="{
          'value-pack-items__checkout-formatprice': isCheckoutPage,
          'flex-column value-pack-items__formatprice': !isCheckoutPage,
          'value-pack-items__confirmationprice': finalPriceCalculated,
          'value-pack-items__confirmationprice-dtp':
            valuePackItem.orderEntryFlowType === orderEntryFlowType.DTP_VALUE_PACK
        }"
        *ngIf="valuePackItem.eyeSight?.toLowerCase() === 'right'"
      >
        <label>
          <!--Temp fix R1-->
          <ng-container
            *ngIf="
              orderDetailsPage &&
                valuePackItem.orderEntryFlowType === orderEntryFlowType.DTP_VALUE_PACK &&
                status === 'New';
              else notDTP
            "
          >
            -
          </ng-container>
          <ng-template #notDTP>
            {{
              finalPriceCalculated && !externalOrder
                ? (valuePackPrice | currency: (currencyIso$ | async):'symbol-narrow')
                : '-'
            }}
          </ng-template>
        </label>
      </div>
    </div>
  </div>
  <div class="d-flex value-pack-items__update-product">
    <div *ngIf="cartPage">
      <cx-icon type="Remove"> </cx-icon>
      <a href="javascript:void(0)" [entries]="entryList" appRemoveFromCart>{{ 'miniCartPage.delete' | cxTranslate }}</a>
    </div>
  </div>
</div>
