<div class="d-flex flex-column items-center deliverymode-panel">
  <div class="deliverymode" *ngFor="let key of deliveryMethods">
    <div class="deliverymode-wrapper">
      <input
        id="key_{{ key.name }}"
        [value]="key.name"
        type="radio"
        name="radioSelected"
        [(ngModel)]="radioSelected"
        (ngModelChange)="changeDeliveryMode(key)"
        class="alconInput__radio"
      />
      <label for="key_{{ key.name }}" class="deliverymode-wrapper__label--checkbox">
        {{ key.name }}
      </label>
    </div>
  </div>
</div>
