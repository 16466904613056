import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { GlobalMessageService, GlobalMessageType, SemanticPathService, TranslationService } from '@spartacus/core';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { PatientService } from 'src/app/services/patient/patient.service';
import { PatientListService } from 'src/app/services/patients-list/patient-list.service';
import { DELIVERY_TYPE, GlobalMessage, MODAL_POPUP_CONFIG } from 'src/app/shared/constants/constants';
import { ModalOptions } from 'src/app/shared/model/common.mode';
import { Utilities } from 'src/app/shared/utility/utility';
import { getOrderFlag } from 'src/app/store/Selectors/patient.selector';
import { AlconAlertPopupComponent } from '../../alcon-alert-popup/alcon-alert-popup.component';
import { AlconRedirectPopupComponent } from '../../alcon-redirect-popup/alcon-redirect-popup.component';
@Component({
  selector: 'app-single-patient-details',
  templateUrl: './single-patient-details.component.html',
  styleUrls: ['./single-patient-details.component.scss'],
})
export class SinglePatientDetailsComponent implements OnInit, OnDestroy {
  public modalOptions = {
    open: false,
    header: false,
    modalTitle: '',
  } as ModalOptions;
  productOpen: boolean;
  genericErrorMessage: string;
  orderLoadedFlag: Observable<boolean>;
  directToPatientMessage: string;
  genericMessage: string;
  cancelOrderButtonText: string;
  constructor(
    private eRef: ElementRef,
    private router: Router,
    private patientService: PatientService,
    private patientListService: PatientListService,
    private globalMessageService: GlobalMessageService,
    private modalService: NgbModal,
    private semanticPathService: SemanticPathService,
    private translation: TranslationService,
    private store: Store
  ) {
    this.getTranslatedValues();
  }
  optionsList = false;
  showLoader = false;
  @Input() patientData;
  modalSubscription: Subscription;
  orderNo: string;
  isAddressCollapsed: Array<boolean> = [];
  isOrdersCollapsed = Utilities.isTablet() || Utilities.isMobile();
  deliveryTypes = DELIVERY_TYPE;
  private destroy$ = new Subject<void>();
  translationArray = [];

  @HostListener('document:click', ['$event'])
  clickout(event): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.optionsList = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  private explodeOrCollapsOrders(): void {
    this.isOrdersCollapsed = Utilities.isTablet() || Utilities.isMobile();
  }

  ngOnInit(): void {
    if (this.patientData) {
      this.isAddressCollapsed = new Array(this.patientData.recentOrders.length).fill(true);
    }
    this.orderLoadedFlag = this.store.select(getOrderFlag);
  }

  getTranslatedValues(): void {
    combineLatest([
      this.translation.translate('reOrderValidation.GENERIC_API_ERRORMESSAGE'),
      this.translation.translate('reOrderValidation.DIRECT_TO_PATIENT_MESSAGE'),
      this.translation.translate('reOrderValidation.GENERIC_MESSAGE'),
      this.translation.translate('orderHistoryPage.cancel'),
      this.translation.translate('patientOrderHistory.popupYesText'),
      this.translation.translate('patientOrderHistory.popupNoText'),
      this.translation.translate('patientOrderHistory.delPatientConfirmation'),
      this.translation.translate('patientOrderHistory.delPatient'),
      this.translation.translate('patientOrderHistory.infoMsg'),
      this.translation.translate('patientOrderHistory.delPatientSuceess'),
      this.translation.translate('patientOrderHistory.hasActiveOrders'),
      this.translation.translate('patientOrderHistory.delPatientFail'),
      this.translation.translate('patientOrderHistory.delFailMsg'),
      this.translation.translate('patientOrderHistory.goToCart'),
      this.translation.translate('patientOrderHistory.cartLink'),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.translationArray = data;
      });
  }

  optionList(): void {
    this.optionsList = true;
  }
  patientOrderHistory(): void {
    this.router.navigate(
      this.semanticPathService.transform({
        cxRoute: 'patientProfile',
        params: { profileId: this.patientData.uid },
      })
    );
  }
  editPatientDetails(): void {
    this.productOpen = true;
    this.modalOptions.open = true;
    document.body.classList.add('modal-open');
  }
  closeModal(): void {
    this.productOpen = false;
    this.modalOptions.open = false;
    document.body.classList.remove('modal-open');
  }
  patientAddedSuccessfully(): void {
    this.globalMessageService.add('Changes saved', GlobalMessageType.MSG_TYPE_CONFIRMATION, GlobalMessage.TIMEOUT);
  }
  addOrder(): void {
    this.patientService.setPatientInfo(this.patientData);
    this.router.navigate(
      this.semanticPathService.transform({
        cxRoute: 'newOrderPage',
        params: { patientID: this.patientData.uid },
      })
    );
  }
  /**
   * triggers on subscription status change
   * updates patient data
   * @param data scheduled order data
   * @param index row index
   */
  updatePatientData(data: any, index: number): void {
    this.patientData.recentOrders[index] = data;
    this.patientService.getPatientProfileInformation(this.patientData.uid).pipe(take(1)).subscribe();
  }

  /**
   * Triggers Alert Popoup
   */
  deletePatient(): void {
    const status = this.patientData?.hasActiveSubscription ?? false;
    this.triggerAlertPopup(status);
  }

  /**
   * Opens Alert Popup with user confirmation message
   * In case of active schedule order adds extra info msg to alert component
   * One yes -Triggers validateAndDeletePatient
   * On NO -no action
   */
  triggerAlertPopup(activeStatus = false): void {
    const modalRef = this.modalService.open(AlconAlertPopupComponent, {
      ...MODAL_POPUP_CONFIG,
      windowClass: 'patientOrderList_alertPopup',
    });
    modalRef.componentInstance.alertConfig = {
      msg: activeStatus ? this.translationArray[7] : this.translationArray[6],
      btnOneTxt: this.translationArray[4],
      btnTwoTxt: this.translationArray[5],
    };
    if (activeStatus) {
      modalRef.componentInstance.alertConfig.infoMsg = this.translationArray[8];
    }
    this.modalSubscription = modalRef.componentInstance.btnClick
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: string) => {
        if (value === this.translationArray[4]) {
          this.validateAndDeletePatient();
          modalRef.close();
        } else {
          modalRef.close();
        }
      });
  }

  /**
   * Invoke delete patient API by passing patient ID
   * On success displays success global message
   * On error triggers Alert Redirect Popup
   *
   */
  validateAndDeletePatient(): void {
    this.patientListService
      .deletePatientFromList(this.patientData.patientId)
      .pipe(take(1))
      .subscribe(
        (success) => {
          if (success === true) {
            this.globalMessageService.add(this.translationArray[9], GlobalMessageType.MSG_TYPE_CONFIRMATION, 3000);
          }
        },
        (error) => {
          const errorMsg = error.error.errors ? error.error.errors[0]?.message : error.error;
          if (errorMsg && errorMsg === this.translationArray[10]) {
            this.triggerRedirectPopup();
          } else {
            this.globalMessageService.add(errorMsg, GlobalMessageType.MSG_TYPE_ERROR, 3000);
          }
        }
      );
  }

  /**
   * Triggers Alert redirect popup
   */
  triggerRedirectPopup(): void {
    const modalRef = this.modalService.open(AlconRedirectPopupComponent, {
      ...MODAL_POPUP_CONFIG,
      windowClass: 'patientOrderList_alertPopup',
    });
    modalRef.componentInstance.alertRedirectConfig = {
      title: this.translationArray[11],
      desc: this.translationArray[12],
      redirectBtnText: this.translationArray[13],
      redirectLink: this.translationArray[14],
    };
  }

  reOrderResponse(isSuccess, orderType): void {
    if (isSuccess) {
      this.globalMessageService.add(this.genericErrorMessage, GlobalMessageType.MSG_TYPE_ERROR, GlobalMessage.TIMEOUT);
    } else {
      const successMessage =
        orderType.trim() === DELIVERY_TYPE.DIRECT_TO_PATIENT_ORDER_TYPE.trim()
          ? this.translationArray[1]
          : this.translationArray[2];
      this.globalMessageService.add(successMessage, GlobalMessageType.MSG_TYPE_CONFIRMATION, GlobalMessage.TIMEOUT);
    }
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
