import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import {
  CurrencyService,
  GlobalMessageService,
  GlobalMessageType,
  SemanticPathService,
  TranslationService,
} from '@spartacus/core';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { map, skip, startWith, take, takeUntil } from 'rxjs/operators';
import { ProductDetailsService } from 'src/app/services/alcon-pdp/product-details.service';
import { OrderHistoryService } from 'src/app/services/order-history/order-history.service';
import { PatientService } from 'src/app/services/patient/patient.service';
import { ProductErrorsService } from 'src/app/services/product-errors.service';
import { UpdateUserProfileService } from 'src/app/services/updateuserprofile/update-user-profile.service';
import { ValuePackService } from 'src/app/services/value-pack/valuePack.service';
import { AlconUserPermissionService } from 'src/app/shared-modules/alcon-user-permission-provider/service/alcon-user-permission.service';
import {
  addressType,
  DELIVERY_TYPE,
  DIRECT_TO_PATIENT,
  DIRECT_TO_PRACTICE,
  ErrorResponse,
  GlobalMessage,
  INITIAL_PRICE,
  MODAL_POPUP_CONFIG,
  SCHEDULE_ORDER,
} from 'src/app/shared/constants/constants';
import { ModalOptions } from 'src/app/shared/model/common.mode';
import { AddressInfo, PatientProfileData } from 'src/app/shared/model/patient.model';
import { UserFranchise } from 'src/app/shared/model/user.model';
import { LoadItems } from 'src/app/store/Actions/cartItem.action';
import { getPatientInfo } from 'src/app/store/Selectors/patient.selector';
import {
  DeliveryTypesMapping,
  OrderType,
  RecurrencePeriod,
  ScheduledOrderData,
  VisionCareAddtocartProductRequest,
} from 'src/app/store/States/cartItem.state';
import { PatientOrderData, ToggleConfiguration } from 'src/app/store/States/orderHistory';
import { CalendarConfiguration } from '../../../shared/model/calendar.model';
import { AlconAlertPopupComponent } from '../../alcon-alert-popup/alcon-alert-popup.component';
import { AlconRedirectPopupComponent } from '../../alcon-redirect-popup/alcon-redirect-popup.component';

@Component({
  selector: 'app-patient-order-selection-panel',
  templateUrl: './patient-order-selection-panel.component.html',
  styleUrls: ['./patient-order-selection-panel.component.scss'],
})
export class PatientOrderSelectionPanelComponent implements OnInit, OnDestroy {
  showPrices: boolean;
  currencyIso$ = this.currencyService.getActive();
  orderType = OrderType.Individual;
  modalSubscription: Subscription;
  maxQuantity;
  totalQuantitySelected;
  addingtocartLoadingText;
  deliveryType: string = DELIVERY_TYPE.REGULAR;
  packType: boolean;
  addToCartText: string;
  addToCartNowText: string;
  addingtoCartSuccessText: any;
  cartType: string;
  addToCartTextLoading: boolean;
  patientOrders: Array<VisionCareAddtocartProductRequest> = [];
  addToCartButtonText: string;
  addToCartNowButtonText: string;
  deliverToMainAddress = true;
  orderFlowType;
  initialScheduleDefaultDate = new Date(new Date().setDate(new Date().getDate() + 1));
  scheduledOrderData = {
    repeatOrderOption: RecurrencePeriod.monthly.toString(),
    repeatOrderValue: '3',
    defaultDate: this.initialScheduleDefaultDate,
  };
  isVCUser$ = this.updateService.getUserDetails().pipe(map((user) => user?.roles.includes(UserFranchise.VC)));
  @Output() setToDefaults: EventEmitter<any> = new EventEmitter();
  @Output() orderFlowChange: EventEmitter<boolean> = new EventEmitter();
  @Output() changedDeliveryType: EventEmitter<boolean> = new EventEmitter();
  @Output() isEditRightPanel: EventEmitter<boolean> = new EventEmitter();
  saveScheduleOrderText: string;
  scheduledOrderConfig: CalendarConfiguration;
  disableButton = true;
  deliveryAddressError = false;
  subscriptionSuccessMessage = '';
  subscriptionInactiveMessage = '';
  editSubMessage: string;
  scheduleOrderYes: string;
  scheduleOrderNo: string;

  get scheduleOrder(): {} {
    return SCHEDULE_ORDER;
  }

  get recurrenceType(): {} {
    return RecurrencePeriod;
  }
  @Input() scheduledOrderList: PatientOrderData;
  @Input() toggleData: ToggleConfiguration;
  @Input() set selectedPackType(packType: boolean) {
    this.packType = packType;
    this.assigningDeliveryType(this.deliveryType);
  }
  @Input() set selectedOrderType(orderType) {
    this.orderType = orderType ? orderType : this.orderType;
  }
  @Input() set selectedDeliveryType(deliveryType) {
    this.deliveryType = deliveryType ? deliveryType : this.deliveryType;
  }
  @Input() set selectedScheduledOrderData(data: ScheduledOrderData) {
    if (data) {
      this.scheduledOrderData = data;
      this.scheduledOrderConfig = {
        header: {
          year: data.defaultDate.getFullYear(),
          dayAndDate: data.defaultDate.toLocaleDateString('en-us', {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
          }),
        },
        defaultDate: data.defaultDate,
        minDate: this.initialScheduleDefaultDate,
        maxDate: new Date(new Date().setFullYear(this.initialScheduleDefaultDate.getFullYear() + 1)),
        styles: {
          left: '0',
          top: '6.25rem',
        },
      };
    }
  }
  @Input() set selectedProductValue(products: Array<VisionCareAddtocartProductRequest>) {
    const { firstName, lastName, patientId } = this.activatedRoute.snapshot.data.newOrderPatientInfo;
    if (products && this.validateMainAddress()) {
      this.patientOrders = products?.map((product: VisionCareAddtocartProductRequest) => {
        if (!this.packType) {
          return {
            ...product,
            patientId,
            valuePackAttributes: product.valuePackAttributes,
            patientName: lastName + ', ' + firstName,
            patientShippingAddressMain: this.deliverToMainAddress,
          } as VisionCareAddtocartProductRequest;
        } else {
          return {
            ...product,
            patientId,
            patientName: lastName + ', ' + firstName,
            patientShippingAddressMain: this.deliverToMainAddress,
          } as VisionCareAddtocartProductRequest;
        }
      });
      this.assigningDeliveryType(this.deliveryType);
      this.patientOrders = [...this.patientOrders];
    } else {
      this.patientOrders = [];
    }
  }
  deliveryAddress: AddressInfo;
  openSelectdelivery = false;
  public modalOptions = {
    open: false,
    header: false,
    modalTitle: '',
    borderRadius: '20px',
    width: '1080px',
  } as ModalOptions;
  patientData: PatientProfileData;
  @Input() isDTPError = false;
  private destroy$ = new Subject<void>();
  subscriptionsVisibility: boolean;

  readonly deliveryTypes = DELIVERY_TYPE;
  totalPrice = 0;
  valuePackPrice: any;
  isValuePack: boolean;
  isPriceVisible: boolean;

  constructor(
    private orderService: OrderHistoryService,
    private activatedRoute: ActivatedRoute,
    private translation: TranslationService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private location: Location,
    private globalMessageService: GlobalMessageService,
    private modalService: NgbModal,
    private productErrorsService: ProductErrorsService,
    private store: Store,
    private semanticPathService: SemanticPathService,
    private patientService: PatientService,
    private productService: ProductDetailsService,
    private valuePackService: ValuePackService,
    private currencyService: CurrencyService,
    private userPermissionService: AlconUserPermissionService,
    private updateService: UpdateUserProfileService
  ) {}

  ngOnInit(): void {
    this.getTranslatedValues();
    this.subscriptionsVisibility = this.userPermissionService.getSubscriptionsPermission();
    this.isPriceVisible = this.userPermissionService.getPriceStatementPermission();
    this.showPrices = this.userPermissionService.getPricesVisibilityPermission();
    this.productService.productSelectionChanged.pipe(takeUntil(this.destroy$)).subscribe((products) => {
      this.isValuePack = this.orderFlowType.includes('VALUE_PACK');
      this.totalPrice = 0;
      for (const product of products) {
        this.totalPrice += product?.price ? product.price : 0;
      }
    });
    this.valuePackService.valuePackPriceChanged.pipe(takeUntil(this.destroy$)).subscribe((price) => {
      this.isValuePack = this.orderFlowType.includes('VALUE_PACK');
      price = price === undefined ? INITIAL_PRICE.totalprice : price;
      this.valuePackPrice = price;
    });
    const patientID = this.activatedRoute.snapshot.params.patientID;
    this.store
      .select(getPatientInfo(patientID))
      .pipe(skip(1), startWith(this.activatedRoute.snapshot.data.newOrderPatientInfo), takeUntil(this.destroy$))
      .subscribe((patient: PatientProfileData) => {
        if (patient) {
          this.patientData = JSON.parse(JSON.stringify(patient));
          this.patientData.addressType = this.deliverToMainAddress
            ? addressType.MAIN_ADDRESS
            : addressType.ADDITIONAL_ADDRESS;
          delete this.patientData.type;
        }
      });
    if (this.scheduledOrderList) {
      this.patientData.addressType = this.scheduledOrderList.defaultAddress
        ? addressType.MAIN_ADDRESS
        : addressType.ADDITIONAL_ADDRESS;
      this.deliveryAddress = this.scheduledOrderList.address;
      this.deliverToMainAddress = this.scheduledOrderList?.defaultAddress;
    } else {
      this.patientData.addressType = addressType.MAIN_ADDRESS;
      this.deliveryAddress = this.patientData?.addresses?.find((address) => address);
    }
    this.deliveryAddressError = !this.deliveryAddress;
  }

  getTranslatedValues(): void {
    combineLatest([
      this.translation.translate('selectProductPage.patientOrderPanel.addToCart'),
      this.translation.translate('selectProductPage.patientOrderPanel.addToCartSuccess'),
      this.translation.translate('subscriptions.subscriptionSuccessMessage'),
      this.translation.translate('subscriptions.subscriptionInactiveMessage'),
      this.translation.translate('selectProductPage.patientOrderPanel.scheduled.addToCartNow'),
      this.translation.translate('patientOrderHistory.editSub'),
      this.translation.translate('patientOrderHistory.scheduleOrderYes'),
      this.translation.translate('patientOrderHistory.scheduleOrderNo'),
    ])
      .pipe(
        map(
          ([
            addToCartText,
            addToCartSuccessText,
            subscriptionSucccessMessage,
            subscriptionInactiveMessage,
            addToCartNow,
            editSub,
            scheduleOrderYes,
            scheduleOrderNo,
          ]) => {
            return {
              addToCartText,
              addToCartSuccessText,
              subscriptionSucccessMessage,
              subscriptionInactiveMessage,
              addToCartNow,
              editSub,
              scheduleOrderYes,
              scheduleOrderNo,
            };
          }
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        this.addToCartText = data.addToCartText;
        this.addToCartButtonText = data.addToCartText;
        this.addToCartNowButtonText = data.addToCartNow;
        this.addToCartNowText = data.addToCartNow;
        this.addingtoCartSuccessText = data.addToCartSuccessText;
        this.subscriptionSuccessMessage = data.subscriptionSucccessMessage;
        this.subscriptionInactiveMessage = data.subscriptionInactiveMessage;
        this.editSubMessage = data.editSub;
        this.scheduleOrderYes = data.scheduleOrderYes;
        this.scheduleOrderNo = data.scheduleOrderNo;
      });
  }

  addingtocartLoad(loading): void {
    if (loading) {
      this.addToCartTextLoading = loading;
    } else {
      this.addToCartTextLoading = loading;
    }
  }

  addingtoCartSuccess(tickValue, resetCartData = false): void {
    this.addToCartTextLoading = false;
    this.addToCartButtonText = this.addingtoCartSuccessText;
    this.addToCartNowButtonText = this.addingtoCartSuccessText;
    setTimeout(() => {
      this.addToCartButtonText = this.addToCartText;
      this.addToCartNowButtonText = this.addToCartNowText;
      if (!resetCartData) {
        this.setToDefaults.emit('');
      }
      this.cartType = '';
      this.cd.detectChanges();
    }, 3000);
  }

  cartError(): void {}

  emitErrorMessage(errorMsg): void {
    errorMsg.error.errors.forEach((err) => {
      this.productErrorsService.addProductError(err);
    });
    const { message = '', reason = '' } = errorMsg.error.errors[0];
    if (message && (reason === ErrorResponse.CART_MAX_EXCEPTION || reason === ErrorResponse.ALCON_BUSINESS_EXCEPTION)) {
      this.triggerRedirectPopup(message);
    } else {
      this.globalMessageService.add(message, GlobalMessageType.MSG_TYPE_ERROR, GlobalMessage.TIMEOUT);
      this.setToDefaults.emit('');
      this.addToCartTextLoading = false;
    }
  }

  triggerRedirectPopup(message): void {
    combineLatest([
      this.translation.translate('selectProductPage.patientOrderPanel.title'),
      this.translation.translate('selectProductPage.patientOrderPanel.description'),
      this.translation.translate('selectProductPage.patientOrderPanel.redirectButton'),
    ])
      .pipe(
        map(([title, description, redirectBtnText]) => {
          return {
            title,
            description,
            redirectBtnText,
          };
        }),
        take(1)
      )
      .subscribe((data) => {
        const modalRef = this.modalService.open(AlconRedirectPopupComponent, {
          ...MODAL_POPUP_CONFIG,
          windowClass: 'patientOrderList_alertPopup',
        });
        modalRef.componentInstance.alertRedirectConfig = {
          title: this.packType ? data.title : '',
          desc: this.packType ? data.description : message,
          redirectBtnText: data.redirectBtnText,
        };
      });
  }

  changeDeliveryAddress(): void {
    this.openSelectdelivery = true;
    this.modalOptions.open = true;
    document.body.classList.add('modal-open');
  }
  closeModal(): void {
    this.openSelectdelivery = false;
    this.modalOptions.open = false;
    document.body.classList.remove('modal-open');
  }
  saveAddressInfo(address): void {
    this.modalOptions.open = false;
    document.body.classList.remove('modal-open');
    this.openSelectdelivery = false;
    this.deliveryAddress = address;
    this.deliveryAddressError = false;
    this.deliverToMainAddress = address.defaultAddress;
    this.patientData.addressType = address.defaultAddress ? addressType.MAIN_ADDRESS : addressType.ADDITIONAL_ADDRESS;
    this.patientOrders?.forEach((product) => {
      product.patientShippingAddressMain = address.defaultAddress;
    });
    this.isEditRightPanel.emit(true);
    this.store.dispatch(LoadItems());
  }
  changeDeliveryType(deliveryType: string): void {
    this.deliveryType = deliveryType;
    this.assigningDeliveryType(deliveryType);
    this.productService.emitDTPFlag(deliveryType !== DELIVERY_TYPE.REGULAR);
    this.changedDeliveryType.emit(true);
  }
  assigningDeliveryType(selectedType): void {
    this.orderFlowType = this.packType
      ? selectedType === DELIVERY_TYPE.DIRECT_TO_PATIENT
        ? DIRECT_TO_PATIENT.DIRECT_TO_PATIENT_ORDER
        : DIRECT_TO_PRACTICE.REGULAR
      : !this.packType
      ? selectedType === DELIVERY_TYPE.DIRECT_TO_PATIENT
        ? DIRECT_TO_PRACTICE.DTP_VALUE_PACK
        : DIRECT_TO_PRACTICE.VALUE_PACK
      : DIRECT_TO_PRACTICE.REGULAR;
    this.orderFlowChange.emit(selectedType === DELIVERY_TYPE.DIRECT_TO_PATIENT);
    this.patientOrders?.forEach((product) => {
      product.orderEntryFlowType = this.packType
        ? selectedType === DELIVERY_TYPE.DIRECT_TO_PATIENT
          ? DIRECT_TO_PATIENT.DIRECT_TO_PATIENT_ORDER
          : DIRECT_TO_PRACTICE.REGULAR
        : !this.packType
        ? selectedType === DELIVERY_TYPE.DIRECT_TO_PATIENT
          ? DIRECT_TO_PRACTICE.DTP_VALUE_PACK
          : DIRECT_TO_PRACTICE.VALUE_PACK
        : DIRECT_TO_PRACTICE.REGULAR;
    });
    this.patientOrders = [...this.patientOrders];
  }

  changeOrderType(value: string): void {
    this.scheduledOrderConfig = {
      header: {
        year: this.scheduledOrderData.defaultDate.getFullYear(),
        dayAndDate: this.scheduledOrderData.defaultDate.toLocaleDateString('en-us', {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
        }),
      },
      defaultDate: this.scheduledOrderData.defaultDate,
      minDate: this.initialScheduleDefaultDate,
      maxDate: new Date(new Date().setFullYear(this.initialScheduleDefaultDate.getFullYear() + 1)),
      styles: {
        left: '0',
        top: '6.25rem',
      },
    };
  }

  changeRepeatOrderTime(event): void {
    this.isEditRightPanel.emit(true);
  }

  repeatOrderValueChange(event): void {
    if (/^[0-9]+$/.test(event)) {
      if (Number(event) < SCHEDULE_ORDER.MININUM_REPEAT_VALUE || Number(event) > SCHEDULE_ORDER.MAXIMUM_REPEAT_VALUE) {
        this.scheduledOrderData = { ...this.scheduledOrderData, repeatOrderValue: '' };
      } else {
        this.scheduledOrderData = { ...this.scheduledOrderData, repeatOrderValue: event };
      }
    } else {
      this.scheduledOrderData = { ...this.scheduledOrderData, repeatOrderValue: '' };
    }
    this.isEditRightPanel.emit(true);
  }

  dateSelected(date): void {
    this.scheduledOrderData = {
      ...this.scheduledOrderData,
      defaultDate: date ? date : this.scheduledOrderData.defaultDate,
    };
    this.scheduledOrderConfig = {
      ...this.scheduledOrderConfig,
      defaultDate: date ? date : this.scheduledOrderData.defaultDate,
    };
    this.isEditRightPanel.emit(true);
  }

  navigateToPatient(): void {
    this.router.navigate(
      this.semanticPathService.transform({
        cxRoute: 'patientProfile',
        params: { profileId: this.patientData.uid },
        queryParams: {
          isScheduled: true,
          orderScheduled: this.subscriptionInactiveMessage,
        },
      })
    );
  }
  orderScheduledSuccess(event): void {
    // to update patient list page with recent orders
    // Edit mode when we are changing the status from inActive to active
    if (this.scheduledOrderList && !this.scheduledOrderList?.toggleConfig?.status) {
      const modalRef = this.modalService.open(AlconAlertPopupComponent, {
        ...MODAL_POPUP_CONFIG,
        windowClass: 'patientOrderList_alertPopup',
      });
      modalRef.componentInstance.alertConfig = {
        msg: this.editSubMessage,
        btnOneTxt: this.scheduleOrderNo,
        btnTwoTxt: this.scheduleOrderYes,
      };
      this.modalSubscription = modalRef.componentInstance.btnClick
        .pipe(takeUntil(this.destroy$))
        .subscribe((value: string) => {
          if (value === this.scheduleOrderYes) {
            this.updateScheduleActiveStatus(true);
            modalRef.close();
            this.globalMessageService.add(
              this.subscriptionSuccessMessage,
              GlobalMessageType.MSG_TYPE_CONFIRMATION,
              GlobalMessage.TIMEOUT
            );
          } else {
            modalRef.close();
            this.globalMessageService.add(
              this.subscriptionInactiveMessage,
              GlobalMessageType.MSG_TYPE_CONFIRMATION,
              GlobalMessage.TIMEOUT
            );
            this.navigateToPatient();
          }
        });
    } else {
      this.globalMessageService.add(
        this.subscriptionSuccessMessage,
        GlobalMessageType.MSG_TYPE_CONFIRMATION,
        GlobalMessage.TIMEOUT
      );
      this.navigateToPatient();
    }

    this.patientService.getPatientProfileInformation(this.patientData.uid).pipe(take(1)).subscribe();
  }

  /**
   * @param status Flag which says the state of order
   */
  updateScheduleActiveStatus(status: boolean): void {
    this.orderService
      .updateActiveStatus(this.scheduledOrderList?.replenishmentOrderCode, status)
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          this.navigateToPatient();
          this.setToDefaults.emit('');
        }
      });
  }

  orderScheduledLoad(event): void {
    this.disableButton = true;
  }

  getBackToHistory(): void {
    this.location.back();
  }

  validateMainAddress(): boolean {
    const { addresses } = this.activatedRoute.snapshot.data.newOrderPatientInfo;
    const mainAddress = addresses?.find((address) => address.defaultAddress);
    if (!mainAddress && this.deliveryType === DeliveryTypesMapping.DIRECT_TO_PATIENT && !this.deliveryAddress) {
      this.deliveryAddressError = true;
      return false;
    } else {
      this.deliveryAddressError = false;
      return true;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
