<div class="row justify-content-center benifit-container" *ngIf="product$ | async as productData">
  <div class="col-10 col-lg-7">
    <table>
      <thead>
        <tr class="benifit-container__heading">
          <th class="b2b-col-39 pl-0">{{ 'productPage.features' | cxTranslate }}</th>
          <th class="b2b-col-61 b2b-pl-33" colspan="2">{{ 'productPage.benefits' | cxTranslate }}</th>
        </tr>
      </thead>
      <tbody class="align-top">
        <ng-container *ngFor="let feature of getFeaturesList(productData)">
          <tr *ngIf="feature.comparable">
            <td
              class="benifit-container__tableColumn benifit-container__feature-width-tablet"
              [innerHTML]="feature.name"
            ></td>
            <td class="benifit-container__tableColumn">
              <cx-icon class="arrowRight" type="ArrowRight"> </cx-icon>
            </td>
            <td
              class="benifit-container__tableColumn benifit-container__tableColumn--benefits"
              *ngIf="feature.featureValues && feature.featureValues.length"
              [innerHTML]="feature.featureValues[0].value"
            ></td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="col-10 col-lg-3 p-0">
    <section class="d-md-flex flex-md-column benifit-container__section">
      <div class="benifit-container__section-text">
        <h4 class="benifit-container__heading mb-0" [innerHTML]="productData?.techBenefitId"></h4>
        <p class="b2b-p-20 benifit-container__infographic__txt" [innerHTML]="productData?.techBenefit"></p>
      </div>
      <img
        *ngIf="productData?.techBenefitUrl"
        class="benifit-container__infographic__img"
        src="{{ productData?.techBenefitUrl }}"
        alt="Infographic Image"
      />
    </section>
  </div>
</div>
