<div class="container-fluid cart">
  <div class="row justify-content-center">
    <div class="col-10 col-xl-10 pb-3" *ngIf="!emptyCartvalue">
      <h1 class="mb-0 cart__title">{{ title$ | async }}</h1>
      <cx-page-slot position="CartDescriptionSlotPosition"></cx-page-slot>
    </div>
  </div>
  <div *ngIf="!emptyCartvalue" class="row justify-content-between justify-content-lg-center">
    <div class="col-7 orders">
      <cx-page-slot position="CartOrdersPaneSlotPosition"></cx-page-slot>
    </div>
    <div class="col-3 sticky-cart">
      <cx-page-slot position="CartSummarySlotPosition"></cx-page-slot>
      <aside *ngIf="(userInfo | async)?.autoOrdering" id="cartAutoOrderingSection">
        <cx-page-slot position="CartAutoOrderSlotPosition"></cx-page-slot>
      </aside>
    </div>
  </div>
  <div class="row justify-content-center" *ngIf="emptyCartvalue">
    <div class="col-10 col-xl-10">
      <div class="cart-emptyCart">
        <label class="cart-emptyCart__label">{{ 'miniCartPage.yourcartIsEmpty' | cxTranslate }}.</label>
      </div>
      <div class="cart-emptyCart__description">
        <p class="cart-emptyCart__description--message">{{ 'miniCartPage.cartMessage1' | cxTranslate }}</p>
        <p class="cart-emptyCart__description--message">{{ 'miniCartPage.cartMessage2' | cxTranslate }}</p>
      </div>
      <div class="cart-emptyCart__panel-list">
        <app-no-order-panel></app-no-order-panel>
      </div>
    </div>
  </div>
</div>
