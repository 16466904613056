<div *ngIf="product$ | async as productData" class="row d-flex pdpContainer">
  <!-- Desktop -->
  <div class="col-4 d-md-none d-lg-block">
    <div class="d-flex" (click)="toggleDescFunction()">
      <div class="pdpContainer__toggleStyle" [ngClass]="{ 'toggle-active': prodDescription }"></div>
      <div class="pdpContainer__leftHeading" [ngClass]="{ boldFontFamily: prodDescription }">
        {{ 'productPage.productDescription' | cxTranslate }}
      </div>
    </div>
    <div class="d-flex" (click)="toggleSpecFunction()">
      <div class="pdpContainer__toggleStyle" [ngClass]="{ 'toggle-active': !prodDescription }"></div>
      <div class="pdpContainer__leftHeading" [ngClass]="{ boldFontFamily: !prodDescription }">
        {{ 'productPage.productSpecification' | cxTranslate }}
      </div>
    </div>
  </div>
  <div class="col-6 d-md-none d-lg-block">
    <div *ngIf="prodDescription" class="pdpContainer__description">
      <ng-template [ngTemplateOutlet]="description"></ng-template>
    </div>
    <div class="pdpContainer__specification" *ngIf="!prodDescription">
      <ng-template [ngTemplateOutlet]="specification"></ng-template>
    </div>
  </div>

  <!-- Tablet -->
  <div class="w-100 d-lg-none d-md-block">
    <!--NG-Bootstrap Attribute (16.x.x)-->
    <div
      ngbAccordion
      #accordian="ngbAccordion"
      activeIds="description, specification"
      [closeOthers]="true"
      [destroyOnHide]="false"
    >
      <div
        ngbAccordionItem
        id="descriptionId"
        [collapsed]="true"
        NgbAccordionItem="descriptionId"
        #descriptionId="ngbAccordionItem"
      >
        <div ngbAccordionHeader class="d-flex acc-header align-items-center justify-content-between">
          <h5 class="m-0">{{ 'productPage.productDescription' | cxTranslate }}</h5>
          <button ngbAccordionButton class="btn acc-btn btn-link p-0">
            <cx-icon ngbAccordionButton role="button" *ngIf="descriptionId.collapsed" type="DropdownExpand"></cx-icon>
            <cx-icon
              ngbAccordionButton
              role="button"
              *ngIf="!descriptionId.collapsed"
              type="DropdownCollapse"
            ></cx-icon>
          </button>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template [ngTemplateOutlet]="description"></ng-template>
          </div>
        </div>
      </div>
      <div
        ngbAccordionItem
        id="specififcationId"
        [collapsed]="true"
        NgbAccordionItem="specififcationId"
        #specififcationId="ngbAccordionItem"
      >
        <div ngbAccordionHeader class="d-flex acc-header align-items-center justify-content-between">
          <h5 class="m-0">{{ 'productPage.productSpecification' | cxTranslate }}</h5>
          <button ngbAccordionButton class="btn acc-btn btn-link p-0">
            <cx-icon
              ngbAccordionButton
              role="button"
              *ngIf="specififcationId.collapsed"
              type="DropdownExpand"
            ></cx-icon>
            <cx-icon
              ngbAccordionButton
              role="button"
              *ngIf="!specififcationId.collapsed"
              type="DropdownCollapse"
            ></cx-icon>
          </button>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template [ngTemplateOutlet]="specification"></ng-template>
          </div>
        </div>
      </div>
    </div>

    <!-- 
    <ngb-accordion #accordian="ngbAccordion" activeIds="description, specification">
      <ngb-panel id="description">
        <ng-template ngbPanelHeader let-opened="opened">
          <div class="d-flex acc-header align-items-center justify-content-between" [class.opened]="opened">
            <h5 class="m-0">{{ 'productPage.productDescription' | cxTranslate }}</h5>
            <button ngbPanelToggle class="btn">
              <cx-icon ngbPanelToggle role="button" *ngIf="!opened" type="DropdownExpand"></cx-icon>
              <cx-icon ngbPanelToggle role="button" *ngIf="opened" type="DropdownCollapse"></cx-icon>
            </button>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <ng-template [ngTemplateOutlet]="description"></ng-template>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="specification">
        <ng-template ngbPanelHeader let-opened="opened">
          <div class="d-flex acc-header align-items-center justify-content-between" [class.opened]="opened">
            <h5 class="m-0">{{ 'productPage.productSpecification' | cxTranslate }}</h5>
            <button ngbPanelToggle class="btn">
              <cx-icon ngbPanelToggle role="button" *ngIf="!opened" type="DropdownExpand"></cx-icon>
              <cx-icon ngbPanelToggle role="button" *ngIf="opened" type="DropdownCollapse"></cx-icon>
            </button>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <ng-template [ngTemplateOutlet]="specification"></ng-template>
        </ng-template>
      </ngb-panel>
    </ngb-accordion> -->
  </div>

  <!-- common templates for Desktop as well as Tablet -->
  <ng-template #description>
    <div class="mb-4" [innerHTML]="productData.description"></div>
    <ng-container *ngIf="downloadsObject.length > 0">
      <h6 class="font-size14 boldFontFamily">{{ 'productPage.downloads' | cxTranslate }}</h6>
      <ng-container *ngFor="let feature of downloadsObject">
        <div class="d-flex-column">
          <a
            class="pdpContainer__description__download d-flex"
            target="_blank"
            href="{{ feature.featureValues[0].value }}"
            download="{{ feature.name }}"
          >
            <cx-icon class="pdpContainer__description__icon" type="DownloadIcon"></cx-icon>
            <p class="my-0">{{ feature.name }}</p>
          </a>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>
  <ng-template #specification>
    <ng-container *ngFor="let feature of featuresObject?.length ? featuresObject[0].features : []">
      <div class="d-flex" *ngIf="feature?.externalID && feature?.externalID !== downloads">
        <span class="flex-shrink-0 pdpContainer__specification__types">{{ feature?.name }} </span>
        <span [innerHTML]="feature?.featureValues[0].value"></span>
      </div>
    </ng-container>
  </ng-template>
</div>
