import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentDetails, UserPaymentService } from '@spartacus/core';
import { filter, take } from 'rxjs/operators';
import { CreditCardListService } from 'src/app/services/payment-type/credit-card-list.service';
import { BACKDROP_CLICK } from 'src/app/shared/constants/constants';
import { AccessTokenRequestData } from 'src/app/shared/model/checkout.model';
declare var $XIFrame: any;

@Component({
  selector: 'app-creditcard-payment',
  templateUrl: './creditcard-payment.component.html',
  styleUrls: ['./creditcard-payment.component.scss'],
})
export class CreditCardPaymentComponent implements OnInit {
  accessToken: string;
  isDefaultCard = true;
  radioSelected: string;
  showIframe: boolean;
  selectedCard: PaymentDetails;
  constructor(
    private creditCardListService: CreditCardListService,
    private sanitizer: DomSanitizer,
    private activeModal: NgbActiveModal,
    private userPaymentService: UserPaymentService
  ) {}

  iframeurl: SafeResourceUrl;
  url: string;
  @Input() cartdata: AccessTokenRequestData;
  savedCardList: Array<PaymentDetails>;
  @Output() emitCardDetails: EventEmitter<PaymentDetails | PaymentDetails[]> = new EventEmitter();
  @ViewChild('radiobtn', { static: false }) radiobtn: ElementRef;

  ngOnInit(): void {
    this.getListofSavedCards();
  }

  loadPayment(): void {
    const iframe = document.getElementsByName('dieCommFrame');
    if (iframe) {
      $XIFrame.onload({
        iFrameId: 'dieCommFrame',
        targetUrl: iframe[0].getAttribute('src'),
        onSuccess: (msg) => msg,
        onError: (msg) => msg,
      });
    }
  }

  submitPayment(isSaveCard: boolean): void {
    const iframe = document.getElementsByName('dieCommFrame');
    if (iframe.length) {
      $XIFrame.submit({
        iFrameId: 'dieCommFrame',
        targetUrl: iframe[0].getAttribute('src'),
        onSuccess: (msg) => {
          const message = JSON.parse(msg);
          if (message && message?.data?.HasPassed) {
            this.creditCardListService
              .submitForm(this.accessToken, isSaveCard, this.isDefaultCard)
              .pipe(take(1))
              .subscribe((response) => {
                this.emitCardDetails.emit(response);
              });
          }
        },
        onError: (msg) => msg,
        threeDSVersion: '2.2.0',
      });
    }
  }

  useSelectedCard(): void {
    this.emitCardDetails.emit(this.selectedCard);
  }

  closePopup(): void {
    this.activeModal.dismiss(BACKDROP_CLICK.backdropclick);
  }

  checkBoxChangeHandler(event): void {
    this.isDefaultCard = event?.target?.checked;
  }

  addNewCard(): void {
    this.getAccessToken();
    this.showIframe = true;
    this.radiobtn.nativeElement.checked = false;
  }

  getAccessToken(): void {
    const requestdata = {
      code: this.cartdata.code,
      totalPrice: this.cartdata.totalPrice,
    };
    this.creditCardListService
      .getTheToken(requestdata)
      .pipe(take(1))
      .subscribe((res) => {
        this.url =
          'https://cert-xiecomm.paymetric.com/diecomm/view/iframe/' +
          res.paymentInfo.signatureMerchantGuidCC +
          '/' +
          res.paymentInfo.accessToken +
          '/true';
        this.iframeurl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
        this.accessToken = res.paymentInfo.accessToken;
      });
  }

  change(event): void {
    this.selectedCard = this.savedCardList.find((item) => item.id === event.target.value);
  }

  getListofSavedCards(): void {
    this.userPaymentService
      .getPaymentMethods()
      .pipe(
        take(1),
        filter((res) => !!res)
      )
      .subscribe((res) => {
        this.savedCardList = res;
        if (!this.savedCardList.length) {
          this.getAccessToken();
        }
      });
  }
}
