import { Component } from '@angular/core';
import { ContentSlotComponentData } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';

@Component({
  selector: 'app-faq-answer',
  templateUrl: './faq-answer.component.html',
  styleUrls: ['./faq-answer.component.scss'],
})
export class FaqAnswerComponent {
  answer$ = this.componentData.data$;
  constructor(private componentData: CmsComponentData<ContentSlotComponentData>) {}
}
