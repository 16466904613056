<div
  class="d-flex align-items-center pb-2 pt-3"
  [ngClass]="{ 'brand-selected': product.isOpen, 'brand-tile-container': !product.isOpen }"
>
  <div class="brand-img-container pl-4 pr-5">
    <div class="stock-image-holder">
      <cx-media [container]="product.images?.PRIMARY" alt="{{ product.name }}"></cx-media>
    </div>
  </div>
  <div
    class="d-flex justify-content-start align-items-center font-weight-bold"
    [ngClass]="{ 'brand-name-open': product.isOpen, 'brand-name-close': !product.isOpen }"
  >
    {{ product.name }}
  </div>
  <div class="fav-wrap flex-fill">
    <app-favorite-product
      [isFavourite]="product.favourite"
      [productCode]="product.code"
      [isWishList]="isWishList"
      *ngIf="product && !product.isOpen"
    ></app-favorite-product>

    <app-add-to-cart-plp
      *ngIf="!product.isOpen"
      [productCode]="product.code"
      (validateCart)="getValidate($event)"
    ></app-add-to-cart-plp>
  </div>

  <div class="mr-1" *ngIf="product && !product.isOpen && product.price?.formattedValue">
    {{ 'stockOrderProductListPage.from' | cxTranslate }}
  </div>
  <div
    class="d-flex align-items-center justify-content-start product-price font-weight-bold"
    *ngIf="product && !product.isOpen"
  >
    {{ product.price?.formattedValue }}
  </div>
  <div class="expand-content-down mr-3" (click)="selectProduct(product)" *ngIf="product && !product.isOpen"></div>
  <div class="expand-content-up mr-3" (click)="hideProduct(product)" *ngIf="product && product.isOpen"></div>
</div>
<app-stockorder-details-panel-component
  *ngIf="product && product.isOpen"
  [selectedProduct]="{ productCode: product.code }"
  [errorObject]="errorResponse"
></app-stockorder-details-panel-component>
