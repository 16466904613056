import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslationService } from '@spartacus/core';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ProductDetailsService } from 'src/app/services/alcon-pdp/product-details.service';
import { LocalStorageService } from 'src/app/services/localstorage/local-storage.service';
import { DIRECT_TO_PRACTICE } from 'src/app/shared/constants/constants';
import { ProductInfo } from 'src/app/shared/model/product.model';
import { VisionCareAddtocartProductRequest } from 'src/app/store/States/cartItem.state';

@Component({
  selector: 'app-add-to-cart-plp',
  templateUrl: './add-to-cart-plp.component.html',
  styleUrls: ['./add-to-cart-plp.component.scss'],
})
export class AddToCartPlpComponent implements OnInit, OnDestroy {
  selectedProduct: ProductInfo;
  @Input() productCode: string;
  @Input() errorMessage: string;
  @Output() validateCart = new EventEmitter();
  addToCartTextLoading: string;
  addToCartTextSuccess: string;
  addingtocartLoading = false;
  addToCart: string;
  addingtocartTick = false;
  addToCartText = 'Add to cart';
  lensSelectedValues: Array<VisionCareAddtocartProductRequest> = [];
  private destroy$ = new Subject<void>();

  constructor(
    private productDetailsService: ProductDetailsService,
    private translation: TranslationService,
    private localStorageService: LocalStorageService,
    private cd: ChangeDetectorRef
  ) {}
  ngOnInit(): void {
    this.getTranslatedValues();
    this.selectedProduct = this.localStorageService.getTheStorage(this.productCode);
    if (this.selectedProduct) {
      this.lensSelectedValues = [...this.productDetailsService.getVarienntsFormCartObject(this.selectedProduct, true)];
    }
  }
  getTranslatedValues(): any {
    return combineLatest([
      this.translation.translate('stockOrderPage.addToCart'),
      this.translation.translate('stockOrderPage.addToCartLoading'),
      this.translation.translate('stockOrderPage.addToCartSuccess'),
    ])
      .pipe(
        map(([addToCart, addToCartLoading, addToCartSuccess]) => {
          return {
            addToCart,
            addToCartLoading,
            addToCartSuccess,
          };
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        this.addToCart = data.addToCart;
        this.addToCartTextLoading = data.addToCartLoading;
        this.addToCartTextSuccess = data.addToCartSuccess;
      });
  }
  addingtocartLoad(tickValue): void {
    this.addToCartText = this.addToCartTextLoading;
  }
  addingtocartSuccess(tickValue): void {
    this.addToCartText = this.addToCartTextSuccess;
    this.addingtocartTick = tickValue;
    setTimeout(() => {
      this.addToCartText = this.addToCart;
      this.localStorageService.removeProductFromStorage(this.productCode);
      this.selectedProduct = null;
      this.addingtocartTick = false;
      this.cd.detectChanges();
    }, DIRECT_TO_PRACTICE.TIMEOUTRANGE);
  }
  emitErrorMessage(errorMsg): void {
    this.errorMessage = errorMsg;
    this.validateCart.emit(errorMsg);
  }
  cartError(): void {
    this.lensSelectedValues = [];
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
