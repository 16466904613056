import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VcGlobalSearchService {
  constructor() {}
  productSearch: Subject<boolean> = new Subject<boolean>();
}
