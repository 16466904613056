import { ChangeDetectorRef, Component, Input, ViewEncapsulation } from '@angular/core';
import { catchError, delay, map } from 'rxjs/operators';
import { IRegisterStep } from './i-register-step';
import { EMPTY, of } from 'rxjs';
import { IRegisterFormData } from './i-register-form-data';
import { RegisterService } from 'src/app/services/register/register.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RegisterComponent {
  @Input() step: IRegisterStep = 0;

  public datas: IRegisterFormData = {} as IRegisterFormData;
  public disabledSubmit = false;

  constructor(private registerService: RegisterService, private cd: ChangeDetectorRef) {}

  submittedData(data: any, step: IRegisterStep): void {
    this.collectData(data);
    this.nextStep(step);
  }

  goToStep(step: IRegisterStep): void {
    this.step = step;
  }

  collectData(event: any): void {
    for (const key in event) {
      if (key) {
        this.datas[key] = event[key];
      }
    }
  }

  nextStep(step: IRegisterStep): void {
    if (step === 0) {
      this.goToStep(1);
    }
    if (step === 1) {
      // call registration API
      this.disabledSubmit = true;
      this.registerService
        .registerExistingUser({
          ...this.datas,
          accountNumber:
            this.datas.accountNumber.charAt(0) !== '0' ? '0' + this.datas.accountNumber : this.datas.accountNumber,
        })
        .pipe(
          catchError(() => {
            this.disabledSubmit = false;
            this.goToStep(0);
            this.cd.detectChanges();
            return EMPTY;
          })
        )
        .subscribe((response) => {
          this.goToStep(2);
          this.cd.detectChanges();
        });
    }
  }
}
