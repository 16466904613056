import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { I18nModule } from '@spartacus/core';
import { IconModule, MediaModule, PageSlotModule } from '@spartacus/storefront';
import { AlconCommonModule } from '../common/alcon-common.module';
import { VisioncareSearchComponent } from './visioncare-search/visioncare-search.component';
@NgModule({
  declarations: [VisioncareSearchComponent],
  imports: [CommonModule, FormsModule, AlconCommonModule, PageSlotModule, MediaModule, I18nModule, IconModule],
  exports: [VisioncareSearchComponent],
})
export class VisioncareGlobalSearchModule {}
