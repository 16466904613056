import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseSiteService } from '@spartacus/core';
import { User } from '@spartacus/user/account/root';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { Observable, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { RequestAccessAdditionalAccountComponent } from 'src/app/shared-modules/multi-account-selector/components/request-access-additional-account/request-access-additional-account.component';
import { MODAL_POPUP_CONFIG } from 'src/app/shared/constants/constants';
import { AlconBaseSite } from 'src/app/shared/model/alcon-basesite.model';
import { UserFranchise } from 'src/app/shared/model/user.model';

declare module '@spartacus/core' {
  interface Address {
    appartment?: string;
    building?: string;
  }
}

declare module '@spartacus/user/account/root' {
  interface User {
    eligibleOrderFlowTypes: Array<string>;
    addresses?: Address[];
    shipToAddress?: Address;
    billToAddress?: Address;
    autoOrderingTimeGroup?: any;
    autoOrdering?: boolean;
    isValuePackEnabled?: boolean;
    subscriptionsVisibility?: boolean;
    statementVisibility?: boolean;
    priceVisibility?: boolean;
  }
}
@Component({
  selector: 'app-alcon-account-details',
  templateUrl: './alcon-account-details.component.html',
  styleUrls: ['./alcon-account-details.component.scss'],
})
export class AlconAccountDetailsComponent implements OnDestroy {
  constructor(
    private userProfileService: UserProfileFacade,
    private modalService: NgbModal,
    private baseSite: BaseSiteService,
    private changeDetector: ChangeDetectorRef
  ) {}
  showEdit = false;
  isSXUser = false;
  modalSubscription: Subscription;
  useraccountInfo$: Observable<User> = this.userProfileService.get().pipe(
    map((userdata: User) => {
      this.isSXUser = userdata?.roles.includes(UserFranchise.SX);
      return {
        ...userdata,
        shipToAddress: userdata?.addresses.find((address) => address.shippingAddress),
        billToAddress: userdata?.addresses.find((address) => !address.shippingAddress),
        isValuePackEnabled: userdata?.isValuePackEnabled,
      } as User;
    })
  );
  basesites$: Observable<any> = this.baseSite.getActive().pipe(
    switchMap((currentSite) => {
      return this.baseSite.get(currentSite).pipe(
        map((siteDetails: AlconBaseSite) => {
          return {
            isSxStorefront: siteDetails?.isSxStorefront,
            additionalAccountEnabledForSx: siteDetails?.additionalAccountEnabledForSx,
          } as AlconBaseSite;
        })
      );
    })
  );
  openRequestPopup(): void {
    const modalRef = this.modalService.open(RequestAccessAdditionalAccountComponent, {
      ...MODAL_POPUP_CONFIG,
      windowClass: 'additional-account_accessPopup',
      backdropClass: 'true-blue-backdrop',
      backdrop: false,
    });
    this.modalSubscription = modalRef.dismissed.subscribe((value: boolean) => {
      if (value) {
        this.changeDetector.detectChanges();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
  }
}
