import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertConfig } from 'src/app/shared/model/alert.model';

@Component({
  selector: 'app-alcon-alert-popup',
  templateUrl: './alcon-alert-popup.component.html',
  styleUrls: ['./alcon-alert-popup.component.scss'],
})
export class AlconAlertPopupComponent {
  @Input() alertConfig: AlertConfig;
  @Output() btnClick: EventEmitter<any> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) {}

  alertBtnClick(ev): void {
    this.btnClick.emit(ev.target.innerText);
  }
}
