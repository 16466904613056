import { Injectable } from '@angular/core';
import { CmsBannerComponentMedia, CmsNavigationComponent, CmsService, SemanticPathService } from '@spartacus/core';
import { NavigationNode, NavigationService } from '@spartacus/storefront';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

export interface AlconNavigationNode extends NavigationNode {
  image?: CmsBannerComponentMedia;
  external?: string | boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AlconNavigationService extends NavigationService {
  constructor(protected cmsService: CmsService, protected semanticPathService: SemanticPathService) {
    super(cmsService, semanticPathService);
  }
  public getAlconNavigationNode(data$: Observable<CmsNavigationComponent>): Observable<any> {
    if (!data$) {
      return of();
    }
    return data$.pipe(
      filter((data) => !!data),
      switchMap((data) => {
        const navigation = data.navigationNode ? data.navigationNode : data;
        return this.cmsService.getNavigationEntryItems(navigation.uid).pipe(
          tap((items) => {
            if (items === undefined) {
              this.alconLoadNavigationEntryItems(navigation, true);
            } else {
              // we should check whether the existing node items are what expected
              const expectedItems = [];
              this.alconLoadNavigationEntryItems(navigation, false, expectedItems);
              const existingItems = Object.keys(items).map((key) => items[key].uid);
              const missingItems = expectedItems.filter((it) => !existingItems.includes(it.id));
              if (missingItems.length > 0) {
                this.cmsService.loadNavigationItems(navigation.uid, missingItems);
              }
            }
          }),
          filter(Boolean),
          map((items) => this.populateAlconNavigationNode(navigation, items))
        );
      })
    );
  }
  /**
   * @description
   * Loads all navigation entry items' type and id. Dispatch action to load all these items
   * @param nodeData node data
   * @param root root of node data
   * @param itemsList items list
   */
  private alconLoadNavigationEntryItems(nodeData: any, root: boolean, itemsList = []): void {
    if (nodeData.entries && nodeData.entries.length > 0) {
      nodeData.entries.forEach((entry) => {
        itemsList.push({
          superType: entry.itemSuperType,
          id: entry.itemId,
        });
      });
    }

    if (nodeData.children && nodeData.children.length > 0) {
      nodeData.children.forEach((child) => this.alconLoadNavigationEntryItems(child, false, itemsList));
    }

    if (root) {
      this.cmsService.loadNavigationItems(nodeData.uid, itemsList);
    }
  }
  /**
   * @description
   * Create a new node tree for the view
   * @param nodeData node data
   * @param items node items
   */
  private populateAlconNavigationNode(nodeData: any, items: any): AlconNavigationNode {
    const node: AlconNavigationNode = {};

    if (nodeData.title) {
      // the node title will be populated by the first entry (if any)
      // if there's no nodeData.title available
      node.title = nodeData.title;
    }

    // populate style classes to apply CMS driven styling
    if (nodeData.styleClasses) {
      node.styleClasses = nodeData.styleClasses;
    }

    // populate style classes to apply CMS driven styling
    if (nodeData.styleClasses) {
      node.styleClasses = nodeData.styleClasses;
    }
    // populate style attributes to apply CMS driven styling
    if (nodeData.styleAttributes) {
      node.styleAttributes = nodeData.styleAttributes;
    }
    if (nodeData.image) {
      node.image = nodeData.image;
    }

    if (nodeData.entries && nodeData.entries.length > 0) {
      this.populateAlconLink(node, nodeData.entries[0], items);
    }

    if (nodeData.children?.length > 0) {
      const children = nodeData.children.map((child) => this.populateAlconNavigationNode(child, items)).filter(Boolean);
      if (children.length > 0) {
        node.children = children;
      }
    }

    // return null in case there are no children
    return Object.keys(node).length === 0 ? null : node;
  }
  /**
   * The node link is driven by the first entry.
   */
  private populateAlconLink(node: AlconNavigationNode, entry, items): void {
    const item = items[`${entry.itemId}_${entry.itemSuperType}`];

    // now we only consider CMSLinkComponent
    if (item && (entry.itemType === 'CMSLinkComponent' || entry.itemType === 'AlconVCCategoryLinkComponent')) {
      if (!node.title) {
        node.title = item.linkName;
      }
      const url = this.getLink(item);
      // only populate the node link if we have a visible node
      if (node.title && url) {
        node.url = url;
        if ((item.external === 'true' || item.external === true) && (item.target === 'true' || item.target === true)) {
          node.external = true;
        }
        // the backend provide boolean value for the target
        // in case the link should be opened in a new window
        if (item.target === 'true' || item.target === true) {
          node.target = '_blank';
        }
      }
      if (item.image) {
        node.image = item.image;
      }
      // populate style classes to apply CMS driven styling
      if (item.styleClasses) {
        node.styleClasses = item.styleClasses;
      }
      // populate style attributes to apply CMS driven styling
      if (item.styleAttributes) {
        node.styleAttributes = item.styleAttributes;
      }
    }
  }
}
