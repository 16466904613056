<div class="franchise-selector-page">
  <div class="container-fluid">
    <div class="d-flex justify-content-center px-4 px-xl-0 pb-70">
      <div class="col-8 section-full franchisePanel">
        <cx-page-slot position="TopPane1A"></cx-page-slot>
        <app-franchise-selector></app-franchise-selector>
      </div>
    </div>
  </div>
</div>
