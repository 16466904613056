import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { combineLatest, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

declare module '@spartacus/core' {
  interface CmsComponent {
    content?: string;
  }
}

interface TermsAndConditions {
  checked: boolean;
  text: string;
}

@Component({
  selector: 'app-alcon-terms-conditions',
  templateUrl: './alcon-terms-conditions.component.html',
  styleUrls: ['./alcon-terms-conditions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AlconTermsConditionsComponent implements OnInit {
  termscondtions: TermsAndConditions[];
  @Output() emitCheckBox: EventEmitter<any> = new EventEmitter();

  constructor(private cmsService: CmsService) {}

  ngOnInit(): void {
    this.cmsService
      .getContentSlot('CheckoutSummaryCheckboxSlotPosition')
      .pipe(
        map(({ components }) => {
          if (!components) {
            return [];
          }
          return [...components];
        }),
        switchMap((components) => {
          if (components.length === 0) {
            return of([]);
          } else {
            return combineLatest(components.map((componet) => this.cmsService.getComponentData(componet.uid)));
          }
        }),
        filter((componentsList) => {
          componentsList = componentsList?.filter((item) => !!item.content);
          if (!componentsList || componentsList?.length === 0) {
            this.emitCheckBox.emit(true);
            return false;
          }
          return true;
        }),
        map((components) =>
          components?.map(
            (componet) =>
              ({
                checked: false,
                text: componet?.content,
              } as TermsAndConditions)
          )
        )
      )
      .subscribe((componets) => {
        this.termscondtions = componets;
      });
  }

  selectCheckBox(): void {
    this.emitCheckBox.emit(this.termscondtions.every((condition) => condition.checked));
  }
}
