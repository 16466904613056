<div class="search">
  <span class="search__container">
    <label class="search__label d-none d-lg-block">{{
      'invoicesListPage.search.' + invoiceList.invoiceType | cxTranslate
    }}</label>
    <input
      class="search__select d-md-block d-lg-none"
      placeholder="{{ 'invoicesListPage.search.' + invoiceList.invoiceType | cxTranslate }}"
      type="text"
      [disabled]="disableSearch"
      [(ngModel)]="searchNumber"
    />
    <input class="search__select d-none d-lg-block" type="text" [disabled]="disableSearch" [(ngModel)]="searchNumber" />
    <cx-icon class="search__icon" type="SearchIcon" *ngIf="!searchNumber"></cx-icon>
    <cx-icon
      type="Close"
      class="search__icon close-icon cx-icon close"
      *ngIf="searchNumber"
      queryParamsHandling="merge"
      [queryParams]="{ searchString: null }"
      [routerLink]="{ cxRoute: 'invoices' } | cxUrl"
      (click)="searchNumber = ''; disableSearch = false"
    >
    </cx-icon>
  </span>
  <span class="search__button-box">
    <button class="btn btn-primary search__button text-uppercase" (click)="search()">
      {{ 'invoicesListPage.search.searchButton' | cxTranslate }}
    </button>
  </span>
</div>
