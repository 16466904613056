import { Component } from '@angular/core';
import { ContentSlotComponentData } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { tap } from 'rxjs/operators';
import { FaqService } from '../../services/faq.service';
@Component({
  selector: 'app-faq-question',
  templateUrl: './faq-question.component.html',
  styleUrls: ['./faq-question.component.scss'],
})
export class FaqQuestionComponent {
  questions$ = this.componentData.data$.pipe(
    tap((res) => {
      this.faqService.setFaqQuestionList(res);
    })
  );
  constructor(private componentData: CmsComponentData<ContentSlotComponentData>, private faqService: FaqService) {}
}
