import { Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
  selector: 'app-cart-section-header',
  templateUrl: './cart-section-header.component.html',
  styleUrls: ['./cart-section-header.component.scss'],
})
export class CartSectionHeaderComponent {
  @Input() expandAccordion = true;
  @Input() title: string;
  @Input() count: number;
  @Output() accordionStatus: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor() {}
  toggleAccordion(): void {
    this.expandAccordion = !this.expandAccordion;
    this.accordionStatus.emit(this.expandAccordion);
  }
}
