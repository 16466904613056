import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserIdService, OccEndpointsService } from '@spartacus/core';
import { Observable, of, combineLatest } from 'rxjs';
import { take, switchMap, tap } from 'rxjs/operators';
import { PatientProfileData } from 'src/app/shared/model/patient.model';

@Injectable({
  providedIn: 'root',
})
export class EditScheduledOrderService {
  constructor(
    private http: HttpClient,
    private userIdService: UserIdService,
    private occEndPoints: OccEndpointsService
  ) {}

  getEditScheduledOrderData(replenishmentOrderCode): any {
    return combineLatest([this.userIdService.getUserId()]).pipe(
      take(1),
      switchMap(([userId]) => {
        const url = this.occEndPoints.buildUrl(`users/${userId}/replenishmentOrders/${replenishmentOrderCode}`);
        return this.http.get<any>(url);
      })
    );
  }
}
