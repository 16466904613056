import { Component, Input, OnChanges } from '@angular/core';
import { OccConfig } from '@spartacus/core';

@Component({
  selector: 'app-minicart-items',
  templateUrl: './minicart-items.component.html',
  styleUrls: ['./minicart-items.component.scss'],
})
export class MinicartItemsComponent implements OnChanges {
  @Input() item;
  specificationValues = ['BC', 'DIA', 'LP', 'ADD', 'CY', 'AX', 'Colour'];

  constructor(private occConfig: OccConfig) {}

  ngOnChanges(): void {
    if (this.item) {
      this.item.specifications = this.specificationSort(this.item?.specifications, []);
    }
  }

  getImageUrl(imageUrl): string {
    return `${this.occConfig.backend?.occ?.baseUrl}/${imageUrl}`;
  }

  specificationSort(specVal = [], returnVal): any {
    this.specificationValues.forEach((value) => {
      const specificationsArray = { specificationKey: '', specificationValue: '' };
      const specificationValue = specVal.find((specifications) => specifications.specificationKey === value);
      if (specificationValue) {
        specificationsArray.specificationKey = specificationValue?.specificationKey;
        specificationsArray.specificationValue = specificationValue?.specificationValue;
        returnVal.push(specificationsArray);
      }
    });
    return returnVal;
  }
}
