import { Component, HostBinding, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CmsBannerCarouselComponent as model, CmsService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { MODAL_POPUP_CONFIG } from 'src/app/shared/constants/constants';
import { MarkettingBannerComponent } from '../marketting-banner/marketting-banner.component';

declare module '@spartacus/core' {
  interface CmsBannerCarouselComponent {
    respBanners: string;
  }
}

@Component({
  selector: 'app-marketing-popup',
  templateUrl: './marketing-popup.component.html',
  styleUrls: ['./marketing-popup.component.scss'],
})
export class MarketingPopupComponent implements OnInit {
  @HostBinding('class') theme = '';

  itemsList$: Observable<any>[];

  private componentData$: Observable<model> = this.componentData.data$.pipe(
    filter(Boolean),
    tap((d: model) => (this.theme = `${d.effect}-theme`))
  );
  private items$ = this.componentData$.pipe(
    map((data) => data.respBanners.trim().split(' ')),
    map((codes) => codes.map((code) => this.cmsService.getComponentData(code)))
  );

  constructor(
    private componentData: CmsComponentData<model>,
    private cmsService: CmsService,
    private modalService: NgbModal
  ) {}
  ngOnInit(): void {
    this.items$
      .pipe(
        tap((data) => {
          let modalInstance: any;
          const modalRef = this.modalService.open(MarkettingBannerComponent, {
            ...MODAL_POPUP_CONFIG,
            windowClass: 'marketing-Banner_Popup',
            size: 'xl',
            backdropClass: 'true-blue-backdrop',
          });
          modalInstance = modalRef.componentInstance;
          modalInstance.items$ = data;
          modalRef.result.finally(() => {});
        }),
        take(1)
      )
      .subscribe();
  }
}
