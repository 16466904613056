import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SemanticPathService, TranslationService } from '@spartacus/core';
import { combineLatest, Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { INVOICELIST_TYPES } from 'src/app/shared/constants/constants';
import { InvoicesService } from '../../services/invoices.service';
import { AlconUserPermissionService } from '../../../alcon-user-permission-provider/service/alcon-user-permission.service';

@Component({
  selector: 'app-invoices-list',
  templateUrl: './invoices-list.component.html',
  styleUrls: ['./invoices-list.component.scss'],
})
export class InvoicesListComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  invoicesList: any;
  recordsFound: number;
  fromNewest: string;
  fromOldest: string;
  sortInvoiceListOptions: any;
  invoiceListType: string;
  dailyInvoice = INVOICELIST_TYPES.DAILYINVOICE;

  defaultPanelConfiguration = {
    pagination: true,
  };

  invoicesPagination;
  showStatements: boolean;
  maxSize = 6;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private invoiceService: InvoicesService,
    private translation: TranslationService,
    private semanticPathService: SemanticPathService,
    private userPermissionsService: AlconUserPermissionService,
    private cd: ChangeDetectorRef
  ) {
    combineLatest([
      this.translation.translate('invoicesListPage.newest'),
      this.translation.translate('invoicesListPage.oldest'),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: [string, string]) => {
        this.fromNewest = data[0];
        this.fromOldest = data[1];
      });

    this.sortInvoiceListOptions = [
      { text: this.fromNewest, iconType: 'SortByHigh', value: 'DESC', selected: true },
      { text: this.fromOldest, iconType: 'SortByLow', value: 'ASC', selected: false },
    ];
  }

  ngOnInit(): void {
    this.showStatements = this.userPermissionsService.getStatementsPermission();
    this.invoicesList = this.route.snapshot.data.invoices;
    this.setInvoicesParameters();
    this.route.queryParams.pipe(skip(1), takeUntil(this.destroy$)).subscribe((param) => {
      this.invoiceService.paginateInvoiceList(param);
    });

    this.invoiceService.invoiceListData.pipe(takeUntil(this.destroy$)).subscribe((list) => {
      this.invoicesList = list;
      this.setInvoicesParameters();
      this.cd.detectChanges();
    });
  }

  setInvoicesParameters(): void {
    this.invoiceListType = this.invoicesList?.invoiceType;
    this.invoicesPagination = this.invoicesList?.pagination;
    this.recordsFound = Number(this.invoicesList?.pagination?.totalNumberOfResults);
  }

  navigate(param): void {
    this.router.navigate(
      this.semanticPathService.transform({
        cxRoute: 'invoices',
      }),
      {
        queryParams: param,
        queryParamsHandling: 'merge',
      }
    );
  }

  setPage(event: number): void {
    this.navigate({ currentPage: event });
  }

  sortBy(event): void {
    this.navigate({ sortDir: event?.value });
  }

  downloadInvoice(invoiceNumber: string): void {
    this.invoiceService
      .downloadInvoice(invoiceNumber)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        const file = new Blob([data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
