<div class="order-details-wrapper">
  <div class="d-flex align-items-center justify-content-between order-details-wrapper__header">
    <div class="order-details-wrapper__header__name">{{ dtpOrderDetails?.patientName }}</div>
    <div class="order-details-wrapper__header__order">
      <label class="order-details-wrapper__header__order--items">{{ dtpOrderDetails?.quantity }} items</label>
      <label class="order-details-wrapper__header__order--total"
        >{{ 'miniCartPage.total' | cxTranslate }}:
        <span>{{ dtpOrderDetails?.totalPrice?.formattedValue }}</span>
      </label>
    </div>
  </div>
  <div class="d-flex align-items-center order-details-wrapper__order--number">
    <label class="order-details-wrapper__order--number--label">
      {{ 'orderConfirmation.orderNumber' | cxTranslate }}
    </label>
    <p class="order-details-wrapper__order--number--value">
      {{ dtpOrderDetails?.splitOrderRef }}
    </p>
  </div>
  <div class="d-flex align-items-center order-details-wrapper__shipping--address">
    <div class="order-details-wrapper__shipping--address--label">
      {{ 'orderConfirmation.shippingAddress' | cxTranslate }}
    </div>
    <address class="order-details-wrapper__shipping--address--value">
      {{ dtpOrderDetails?.shippingAddress?.line1 }}<br />
      <ng-container *ngIf="dtpOrderDetails?.shippingAddress?.line2"
        >{{ dtpOrderDetails.shippingAddress?.line2 }}<br
      /></ng-container>
      {{ dtpOrderDetails?.shippingAddress?.town }} {{ dtpOrderDetails?.shippingAddress?.postalCode }}<br />
      {{ dtpOrderDetails?.shippingAddress?.region?.name }}, {{ dtpOrderDetails?.shippingAddress?.country?.name }}
    </address>
  </div>

  <div class="order-details-wrapper__toggle d-print-none">
    <cx-icon type="ShowEye" *ngIf="openConfirmedOrder"> </cx-icon>
    <cx-icon type="HideEye" *ngIf="!openConfirmedOrder"> </cx-icon>
    <button
      *ngIf="openConfirmedOrder"
      (click)="openConfirmedOrder = !openConfirmedOrder"
      class="order-details-wrapper__toggle--label"
    >
      {{ 'miniCartPage.seeProduct' | cxTranslate }}
    </button>
    <button
      *ngIf="!openConfirmedOrder"
      (click)="openConfirmedOrder = !openConfirmedOrder"
      class="order-details-wrapper__toggle--label"
    >
      {{ 'miniCartPage.hideProduct' | cxTranslate }}
    </button>
  </div>

  <div
    *ngIf="dtpOrderDetails?.orderEntryFlowType === orderEntryFlowType.DIRECT_TO_PATIENT"
    [ngClass]="{ 'd-block': !openConfirmedOrder, 'd-none': openConfirmedOrder }"
    class="d-print-block"
  >
    <app-alcon-cart-data
      [entryLabel]="dtpOrderDetails?.orderEntryFlowType"
      [cartEntry]="cartEntry"
      [finalPriceCalculated]="finalPriceCalculated"
      *ngFor="let cartEntry of dtpOrderDetails.entries"
    >
    </app-alcon-cart-data>
  </div>
  <div
    *ngIf="dtpOrderDetails?.orderEntryFlowType === orderEntryFlowType.DTP_VALUE_PACK"
    [ngClass]="{ 'd-block': !openConfirmedOrder, 'd-none': openConfirmedOrder }"
    class="d-print-block"
  >
    <label class="order-details-wrapper__valuepack">
      {{ 'miniCartPage.' + dtpOrderDetails.orderEntryFlowType | cxTranslate }}
    </label>
    <app-value-pack-items [finalPriceCalculated]="finalPriceCalculated" [valuePack]="dtpOrderDetails?.entries">
    </app-value-pack-items>
  </div>
</div>
