import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SemanticPathService } from '@spartacus/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { CartItemsService } from 'src/app/services/cart-items/cart-items.service';
import { AlconUserPermissionService } from 'src/app/shared-modules/alcon-user-permission-provider/service/alcon-user-permission.service';
import { LineItemCountComponent } from 'src/app/shared/classes/lineitem.count';
import { CART_GROUP } from 'src/app/shared/constants/constants';
import { PageType } from 'src/app/shared/model/common.mode';
import { ClearStore } from 'src/app/store/Actions/cartItem.action';
import { LoadOrderFlag } from 'src/app/store/Actions/patient.action';
import {
  getDTPOrdersummary,
  getIndividualOrdersummary,
  getPracticeItemExist,
  getStockOrdersummary,
  getTotalPriceVaules,
  getValuePackOrdersummary,
} from 'src/app/store/Selectors/cartItem.selector';
import { OrderDetailsObj, OrderTypeSummary } from 'src/app/store/States/cartItem.state';

@Component({
  selector: 'app-alcon-cart-summary',
  templateUrl: './alcon-cart-summary.component.html',
  styleUrls: ['./alcon-cart-summary.component.scss'],
})
export class AlconCartSummaryComponent implements OnInit, OnDestroy {
  // isMaxPurchaseError = false;
  get isMaxPurchaseError() {
    return (
      this.individualOrderMaxPurchaseError.length > 0 ||
      this.stockOrderMaxPurchaseError.length > 0 ||
      this.valuePackMaxPurchaseError.length > 0 ||
      this.dtpOrderMaxPurchaseError.length > 0
    );
  }
  individualOrderMaxPurchaseError = [];
  stockOrderMaxPurchaseError = [];
  valuePackMaxPurchaseError = [];
  dtpOrderMaxPurchaseError = [];
  isSimulationException = false;
  pageType: string;
  isCalculated: boolean;
  isOnlyDTPOrder = new BehaviorSubject(true);
  constructor(
    private store: Store,
    private cartItemsService: CartItemsService,
    private router: Router,
    private route: ActivatedRoute,
    private semanticPathService: SemanticPathService,
    private userPermissionService: AlconUserPermissionService
  ) {}
  individualOrderInfo$: Observable<OrderTypeSummary>;
  stockOrderInfo$: Observable<OrderTypeSummary>;
  valuePacksInfo$: Observable<OrderTypeSummary>;
  dtpInfo$: Observable<OrderTypeSummary>;
  dtpPracticeInfo;
  summaryPrices$: Observable<any> = this.store.select(getTotalPriceVaules).pipe(
    tap((data: any) => {
      this.finalCartValues = data;
      this.isCalculated = data?.calculated;
    })
  );
  private destroy$ = new Subject<void>();
  finalCartValues;
  isConfirmationPage = false;
  cartError$: Observable<boolean>;
  showLoader = false;
  enableOrderPay = false;
  isCartPage = false;
  isPriceVisible: boolean;
  isValuePackPrice = true;
  isStockOrderPrice = true;
  isIndividualOrderPrice = true;
  isDirectPatientOrderPrice = true;
  showTotalPrice = new BehaviorSubject(true);
  isCheckoutPage = false;

  ngOnInit(): void {
    this.isPriceVisible =
      this.userPermissionService.getPriceStatementPermission() &&
      this.userPermissionService.getPricesVisibilityPermission();
    this.pageType = this.route.snapshot.data.cxRoute.toLowerCase();
    this.isCartPage = this.pageType === PageType.CART.toLowerCase();
    this.isCheckoutPage = this.pageType === PageType.CHECKOUT.toLowerCase();
    this.isConfirmationPage = this.pageType === PageType.ORDER_CONFIRMATION.toLowerCase();
    this.isSimulationException =
      this.pageType === PageType.CART.toLowerCase() ? false : this.cartItemsService.isExceptionForSimulation;
    if (this.isConfirmationPage) {
      const orderConfirmation = {
        calculated: this.route.snapshot.data?.vcCart?.calculated,
        deliveryCost: this.route.snapshot.data?.vcCart?.deliveryCost,
        totalPrice: this.route.snapshot.data?.vcCart?.totalPrice,
        totalTax: this.route.snapshot.data?.vcCart?.totalTax,
      };
      this.summaryPrices$ = new Observable((subscriber) => {
        subscriber.next(orderConfirmation);
      });
      this.isCalculated = this.route.snapshot.data?.vcCart?.calculated;
      const { rootGroups = [] } = this.route.snapshot.data?.vcCart;
      this.valuePacksInfo$ = of(LineItemCountComponent.valuePackCount(rootGroups)).pipe(
        tap((data) => {
          if (data?.orderCount && (this.isSimulationException || !this.isCalculated)) {
            this.isValuePackPrice = this.setPrice(data);
          }
        })
      );
      this.individualOrderInfo$ = of(
        LineItemCountComponent.PracticeOrderCount(rootGroups, CART_GROUP.INDIVIDUAL_ORDER)
      ).pipe(
        tap((data) => {
          if (data?.orderCount && (this.isSimulationException || !this.isCalculated)) {
            this.isIndividualOrderPrice = this.setPrice(data);
          }
        })
      );
      this.stockOrderInfo$ = of(LineItemCountComponent.PracticeOrderCount(rootGroups, CART_GROUP.STOCK_ORDER)).pipe(
        tap((data) => {
          if (data?.orderCount && (this.isSimulationException || !this.isCalculated)) {
            this.isStockOrderPrice = this.setPrice(data);
          }
        })
      );
      this.dtpInfo$ = of(LineItemCountComponent.DTPCount(rootGroups)).pipe(
        tap((data) => {
          const tempOrder = {
            orders: [],
          };
          data?.orders?.forEach((element) => {
            element.entries.forEach((entry) => {
              tempOrder.orders.push(entry);
            });
          });
          if (data?.orderCount) {
            this.isDirectPatientOrderPrice = this.setPrice(tempOrder);
          }
        })
      );
    } else {
      this.cartError$ = this.cartItemsService.cartError;
      if (this.isCheckoutPage && this.route.snapshot.data?.orderSimulate?.calculated && !this.isSimulationException) {
        this.setOrders(false);
      } else {
        this.setOrders(true);
      }
      this.store
        .select(getPracticeItemExist)
        .pipe(takeUntil(this.destroy$))
        .subscribe((info) => {
          this.dtpPracticeInfo = info;
        });
    }
  }

  checkForErrorMessage(orders, errorArray): void {
    if (this.isCartPage) {
      orders.forEach((order) => {
        if (order?.errorMessage) {
          errorArray.push(order);
          // this.isMaxPurchaseError = true;
          return;
        }
      });
    }
  }

  setOrders(isSimulationFail: boolean): void {
    this.individualOrderInfo$ = this.store.select(getIndividualOrdersummary).pipe(
      tap((order: any) => {
        this.individualOrderMaxPurchaseError = [];
        if (order?.orderCount) {
          this.isOnlyDTPOrder.next(false);
          this.isIndividualOrderPrice = isSimulationFail ? this.setPrice(order) : true;
          this.checkForErrorMessage(order?.orders, this.individualOrderMaxPurchaseError);
        }
      })
    );
    this.stockOrderInfo$ = this.store.select(getStockOrdersummary).pipe(
      tap((order: any) => {
        this.stockOrderMaxPurchaseError = [];
        if (order?.orderCount) {
          this.isOnlyDTPOrder.next(false);
          this.isStockOrderPrice = isSimulationFail ? this.setPrice(order) : true;
          this.checkForErrorMessage(this.createOrderObject(order)?.orders, this.stockOrderMaxPurchaseError);
        }
      })
    );
    this.valuePacksInfo$ = this.store.select(getValuePackOrdersummary).pipe(
      tap((order: any) => {
        this.valuePackMaxPurchaseError = [];
        if (order?.orderCount) {
          this.isOnlyDTPOrder.next(false);
          this.isValuePackPrice = isSimulationFail ? this.setPrice(order) : true;
          this.checkForErrorMessage(order?.orders, this.valuePackMaxPurchaseError);
        }
      })
    );
    this.dtpInfo$ = this.store.select(getDTPOrdersummary).pipe(
      tap((order: any) => {
        this.dtpOrderMaxPurchaseError = [];
        const tempOrder = this.createOrderObject(order);
        if (order?.orderCount) {
          this.isDirectPatientOrderPrice = this.setPrice(tempOrder);
          this.checkForErrorMessage(tempOrder?.orders, this.dtpOrderMaxPurchaseError);
        }
      })
    );
  }

  createOrderObject(order: any): any {
    const tempOrder = {
      orders: [],
    };
    order?.orders?.forEach((element) => {
      element.entries.forEach((entry) => {
        tempOrder.orders.push(entry);
      });
    });
    return tempOrder;
  }

  setPrice(orders): boolean {
    let counter = 0;
    orders?.orders?.forEach((element) => {
      if (element?.totalPrice?.value === 0 && !element?.product?.packSize?.toLowerCase().includes('trial')) {
        counter++;
        // Changes done as per demo feedback of story CEBI-4294
        // For-now, only hide in cart.
        if (this.isCartPage) {
          this.showTotalPrice.next(false);
        }
      }
    });
    return counter > 0 ? false : true;
  }

  placeOrder(): void {
    if (
      (this.dtpPracticeInfo.practiceExist &&
        this.finalCartValues.deliveryAddress.isPONumberRequired &&
        !this.cartItemsService.getPOnumber()) ||
      this.cartItemsService.getPOnumber()?.length > 30
    ) {
      this.cartItemsService.ValidatePonumber(true);
    } else {
      this.showLoader = true;
      this.cartItemsService
        .placeOrder()
        .pipe(take(1))
        .subscribe((orderInfo: OrderDetailsObj) => {
          if (orderInfo && orderInfo.status) {
            this.store.dispatch(ClearStore());
            this.store.dispatch(LoadOrderFlag({ fetchPatientOrders: false }));
            this.router.navigate(
              this.semanticPathService.transform({
                cxRoute: 'orderConfirmation',
                params: { orderid: orderInfo.code },
              })
            );
          }
        });
    }
  }
  getSelectedCheckBox(flag): void {
    this.enableOrderPay = flag;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
