<p-calendar
  class="datepicker"
  [ngStyle]="positions"
  [inline]="true"
  [ngStyle]="positions"
  [(ngModel)]="rangeDates"
  [selectionMode]="calendarConfig.selectionMode"
  [minDate]="calendarConfig.minDate"
  [maxDate]="calendarConfig.maxDate"
  [showOtherMonths]="calendarConfig.showOtherMonths"
  inputId="datepicker"
  id="datepicker"
>
  <ng-template pTemplate="header">
    <div class="datepicker__header">
      <div class="datepicker__header-year">
        {{ calendarConfig.header.year }}
      </div>
      <div class="datepicker__header-day">
        {{ calendarConfig.header.dayAndDate }}
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="datepicker__footer">
      <span (click)="option1Clicked()" class="datepicker__footer-option1">{{
        calendarConfig.footer.option1 | uppercase
      }}</span>
      <span (click)="option2Clicked()" class="datepicker__footer-option2">{{
        calendarConfig.footer.option2 | uppercase
      }}</span>
    </div>
  </ng-template>
</p-calendar>
