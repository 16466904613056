<div class="account-container d-flex flex-column align-items-left" (click)="setData()">
  <h5 class="account-container__name mb-0" title="{{ address?.name }}" *ngIf="address?.name">{{ address?.name }}</h5>
  <h6 class="account-container__id" title="{{ address?.uid }}" *ngIf="address?.uid || address?.sapCustomerId">
    {{ address?.uid ? address?.uid : address?.sapCustomerId }}
  </h6>
  <address class="account-container__address mb-0 mt-auto">
    <ng-container *ngIf="address?.line1">{{ address?.line1 }}<br /> </ng-container>
    <ng-container *ngIf="address?.line2">{{ address?.line2 }}<br /></ng-container>
    <ng-container *ngIf="address?.building">{{ address?.building }}, </ng-container>
    <ng-container *ngIf="address?.appartment">{{ address?.appartment }}<br /></ng-container>
    <ng-container *ngIf="address?.town">{{ address?.town }} </ng-container>
    <ng-container *ngIf="address?.postalCode">{{ address?.postalCode }}<br /></ng-container>
    <ng-container *ngIf="address?.region?.name">{{ address?.region?.name }}</ng-container>

    <ng-container *ngIf="address?.country?.name && ifShipToSelector">
      <ng-container *ngIf="address?.region?.name">, </ng-container>{{ address?.country?.name }}
    </ng-container>
  </address>
</div>
