import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OccEndpointsService, ProductService, UserIdService } from '@spartacus/core';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DecrementCount, IncrementCount } from 'src/app/store/Actions/favouriteCount.action';

@Injectable({
  providedIn: 'root',
})
export class FavoriteProductListService {
  private userId: string;
  removeProduct = new Subject<string>();
  private favouriteStatusSubject = new Subject<{
    code: string;
    status: boolean;
  }>();
  favouriteStatus = this.favouriteStatusSubject.asObservable();
  constructor(
    private httpClient: HttpClient,
    private occEndPoints: OccEndpointsService,
    private userIdService: UserIdService,
    private prdService: ProductService,
    private favouriteStore: Store
  ) {
    this.userIdService.getUserId().subscribe((userid) => {
      this.userId = userid;
    });
  }

  addtoFavoriteList(code): Observable<any> {
    const url = this.occEndPoints.buildUrl(`users/${this.userId}/${code}/addFavourite`);
    return this.httpClient.post<any>(url, {}).pipe(
      tap((favourite) => {
        this.favouriteStore.dispatch(IncrementCount());
        this.favouriteStatusSubject.next({
          code,
          status: true,
        });
        this.prdService.get(code, 'alconDetails');
      })
    );
  }

  removeFromFavoriteList(code): Observable<any> {
    const url = this.occEndPoints.buildUrl(`users/${this.userId}/${code}/removeFavourite`);
    return this.httpClient.delete<any>(url).pipe(
      tap((favourite) => {
        this.favouriteStore.dispatch(DecrementCount());
        this.favouriteStatusSubject.next({
          code,
          status: false,
        });
        this.prdService.get(code, 'alconDetails');
      })
    );
  }

  getFavoriteList(): Observable<any> {
    const url = this.occEndPoints.buildUrl(`users/${this.userId}/viewAllFavourites`);
    return this.httpClient.get<any>(url);
  }
}
