<div class="d-flex editProduct">
  <div class="editProduct__description d-flex flex-column">
    <app-product-detail-tile *ngIf="product" [product]="product"></app-product-detail-tile>
    <div
      *ngIf="productData?.orderEntryFlowType !== productType.DIRECT_TO_PATIENT && productData?.patientName"
      class="editProduct__description__patientName d-flex flex-column"
    >
      <label for="patientName" class="editProduct__description__patientName__label m-auto">{{
        'practiceOrderPage.patientReference' | cxTranslate
      }}</label>
      <input
        type="text"
        [disabled]="true"
        value="{{ productData?.patientName }}"
        class="editProduct__description__patientName__text"
      />
    </div>
  </div>
  <div class="editProduct__details d-flex flex-column">
    <div class="editProduct__details__specs" *ngIf="product">
      <div *ngIf="!solutionProducts" class="d-flex">
        <label class="editProduct__details__label d-flex">{{ 'practiceOrderPage.parametersFor' | cxTranslate }}</label>
        <input
          type="checkbox"
          [disabled]="disableCheckbox"
          name="productData.eyeSight"
          class="alconInput__checkbox"
          id="productData.eyeSight"
        />
        <label
          for="prd_checkbox"
          class="editProduct__details__label d-flex editProduct__details__label--checkbox"
          for="productData.eyeSight"
          >{{ 'practiceOrderPage.' + productData.eyeSight | cxTranslate }}</label
        >
      </div>
      <div class="editProduct__details__specs__packSize">
        <div class="d-flex align-items-baseline">
          <label class="editProduct__details__label d-flex" [ngClass]="{ 'mb-3': solutionProducts }">{{
            'productDetailsPage.packSize' | cxTranslate
          }}</label>
          <app-product-pack-size
            [packSizeList]="packSize"
            (packSizeChanged)="changePackSize($event)"
            [isSolutionProducts]="solutionProducts"
            [idPrefix]="selectedProduct"
          ></app-product-pack-size>
        </div>
      </div>
      <div *ngIf="!solutionProducts" class="d-flex">
        <div class="d-flex flex-column justify-content-around">
          <label class="editProduct__details__label d-flex">{{ 'practiceOrderPage.baseCurve' | cxTranslate }}</label>
          <label class="editProduct__details__label d-flex">{{ 'practiceOrderPage.diameter' | cxTranslate }}</label>
          <label class="editProduct__details__label d-flex">{{ 'practiceOrderPage.power' | cxTranslate }}</label>
          <label *ngIf="contactLensType === productType.PRODUCT_COLOR" class="editProduct__details__label d-flex">{{
            'practiceOrderPage.colour' | cxTranslate
          }}</label>
          <label
            *ngIf="contactLensType === productType.PRODUCT_MULTIFOCAL"
            class="editProduct__details__label d-flex"
            >{{ 'practiceOrderPage.add' | cxTranslate }}</label
          >
          <label *ngIf="contactLensType === productType.PRODUCT_TORIC" class="editProduct__details__label d-flex">{{
            'practiceOrderPage.cylinder' | cxTranslate
          }}</label>
          <label *ngIf="contactLensType === productType.PRODUCT_TORIC" class="editProduct__details__label d-flex">{{
            'practiceOrderPage.axis' | cxTranslate
          }}</label>
        </div>
        <app-basic-variant-selector
          *ngIf="variantTree && selectedPackSize"
          [contactLensType]="product.contactLensType"
          [variantTree]="variantTree"
          [packSize]="selectedPackSize"
          [editPanel]="true"
          [productData]="productData"
          (editProductCodeSelected)="checkDTPmaxCount($event)"
          (ProductCodeSelected)="selectedCode($event)"
          class="editProduct__details__specs__variants"
        ></app-basic-variant-selector>
      </div>
    </div>
    <div class="editProduct__details__quantity d-flex">
      <label for="patientName" class="editProduct__details__label editProduct__details__quantity__label d-flex">{{
        'practiceOrderPage.quantity' | cxTranslate
      }}</label>
      <div class="editProduct__details__quantity__counter">
        <app-alcon-item-counter
          [minimum]="1"
          [defaultValue]="quantity"
          (ValueChanged)="setProductQuantity($event)"
          [qtyFactor]="orderType === 'DIRECT_TO_PATIENT' ? 1 : qtyFactor"
        >
        </app-alcon-item-counter>
      </div>
      <p class="editProduct__details__quantity__totalPrice">
        <ng-container *ngIf="price$ | async as priceData">
          <span class="productTile__price__value" *ngIf="priceData.price?.value">
            {{ priceData.price?.currencySymbol }}{{ priceData.price?.value * quantity | number }}
          </span>
        </ng-container>
      </p>
    </div>
    <div class="editProduct__details__errors d-flex flex-column">
      <p class="editProduct__details__errors__styles" [innerHTML]="errorMessage"></p>
      <p class="editProduct__details__errors__styles" *ngIf="showErrorMessage">
        {{ 'practiceOrderPage.dtpLimitMessage' | cxTranslate }} {{ dtpMaxLimit }}.
      </p>
    </div>
    <div class="editProduct__details__actions d-flex flex-column">
      <button
        appAddToCart
        [cartType]="cartType"
        [orderType]="orderType"
        [positionId]="positionId"
        [product]="lensSelectedValues"
        (emitErrorMessage)="emitErrorMessage($event)"
        (updateLineItem)="setUpdatedData($event)"
        class="btn btn-primary text-uppercase editProduct__details__actions__button"
      >
        {{ 'practiceOrderPage.update' | cxTranslate }}
      </button>
      <a role="button" class="editProduct__details__actions__cancel ml-auto mr-auto" (click)="closePopup()">
        {{ 'practiceOrderPage.cancel' | cxTranslate }}
      </a>
    </div>
  </div>
</div>
<app-loader-icon *ngIf="showLoader"></app-loader-icon>
