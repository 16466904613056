import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ORDER_STATUS } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-direct-to-patient-lineitem',
  templateUrl: './direct-to-patient-lineitem.component.html',
  styleUrls: ['./direct-to-patient-lineitem.component.scss'],
})
export class DirectToPatientLineitemComponent implements OnInit {
  @Input() cartEntry: any;
  @Input() entryLabel: any;
  @Input() quantityEdit: boolean;
  @Input() finalPriceCalculated: boolean;
  @Input() isCheckoutPage = false;
  @Input() status: string;
  @Input() index: number;
  @Input() externalOrder: boolean;
  valuePack = [];
  showLineItem = false;
  pageType: string;
  maxIteration: number;
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.pageType = this.route.snapshot.data.Type;
    const { statusDisplay } = this.route.snapshot.data.vcCart;
    this.maxIteration = statusDisplay === ORDER_STATUS.PARTIAL_BACKORDER ? 2 : 1;
    this.showLineItem =
      (this.cartEntry.unShipped && this.index === 1) ||
      (!this.cartEntry.shipped && !this.cartEntry.unShipped && this.index === 1) ||
      (this.cartEntry.shipped && this.index === 0) ||
      (!this.cartEntry.unShipped && !this.cartEntry.shipped && this.maxIteration === 1);
    this.cartEntry?.entries ? (this.valuePack = [...this.cartEntry?.entries]) : (this.valuePack = []);
  }
}
