import { Pipe, PipeTransform } from '@angular/core';
import { SORT } from '../shared/constants/constants';
import { DropdownOptions } from '../shared/model/common.mode';

@Pipe({
  name: 'sortPipe',
})
export class SortPipe implements PipeTransform {
  transform(options: DropdownOptions[], sortType: string): DropdownOptions[] {
    if (sortType === SORT.sortTypeAsc) {
      return options.sort((val1, val2) => (val1.text < val2.text ? -1 : 1));
    } else {
      return options.sort((val1, val2) => (val1.text > val2.text ? -1 : 1));
    }
  }
}
