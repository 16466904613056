import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Product } from '@spartacus/core';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ProductDetailsService } from 'src/app/services/alcon-pdp/product-details.service';
import { ProductListService } from 'src/app/services/product-list-page/product-list.service';
import { addressType, DELIVERY_TYPE } from 'src/app/shared/constants/constants';
import { ModalOptions } from 'src/app/shared/model/common.mode';
import {
  BasicProductSelectedCheckboxStatus,
  BasicProductSelectorPanelConfig,
} from 'src/app/shared/model/product.model';
import { LoadItems } from 'src/app/store/Actions/cartItem.action';
import { VisionCareAddtocartProductRequest } from 'src/app/store/States/cartItem.state';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  noAddress = false;
  public modalOptions = {
    open: false,
    header: false,
    modalTitle: '',
    borderRadius: '20px',
    width: '1080px',
  } as ModalOptions;
  @Input() isWishList = false;
  @Input() resetMaxQuantityError?: string;
  dtpError = false;
  private destroy$ = new Subject<void>();
  patientInfo$ = this.productListService.setPatientInfo.pipe(
    map((address) => {
      if (!address) {
        return undefined;
      }
      return {
        ...address,
        addressType: this.defaultAddress ? addressType.MAIN_ADDRESS : addressType.ADDITIONAL_ADDRESS,
      };
    })
  );

  createPatient$ = this.productListService.disablePanel;
  product: Observable<Product> = this.productService.getProduct('alconDetails').pipe(takeUntil(this.destroy$));
  patientReference = '';
  refreshProductPanel;
  invalidPatientReference = false;
  selectedProductValue: Array<VisionCareAddtocartProductRequest>;
  orderTo = DELIVERY_TYPE.REGULAR;
  resetPatientInfo: string;
  public isDTP$: Observable<boolean> = this.productService.receiveDTPFlag();
  showPanel = true;
  get deliveryTypes(): typeof DELIVERY_TYPE {
    return DELIVERY_TYPE;
  }
  panelConfiguration: BasicProductSelectorPanelConfig = {
    checkboxStatus: {
      leftEye: true,
      rightEye: true,
      bothEye: false,
    } as BasicProductSelectedCheckboxStatus,
    solutions: {
      multiLine: false,
    },
  };
  defaultAddress = true;
  patientData = { patientID: '', patientReference: '', addressType: true };
  constructor(
    private productListService: ProductListService,
    private productService: ProductDetailsService,
    private cd: ChangeDetectorRef,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.patientInfo$ = this.productListService.setPatientInfo.pipe(
      map((address) => {
        if (!address) {
          return undefined;
        }
        return {
          ...address,
          addressType: this.defaultAddress ? addressType.MAIN_ADDRESS : addressType.ADDITIONAL_ADDRESS,
        };
      })
    );
  }
  ngAfterViewInit(): void {
    this.productService.emitDTPFlag(this.deliveryTypes.REGULAR !== DELIVERY_TYPE.REGULAR);
    this.productService.refreshPanel.pipe(takeUntil(this.destroy$)).subscribe((panel) => {
      if (panel) {
        this.resetPatientInfo = Math.random().toString();
        this.patientReference = '';
        this.orderTo = this.deliveryTypes.REGULAR;
        this.refreshProductPanel = Math.random().toString();
        this.showPanel = false;
        this.cd.detectChanges();
        this.showPanel = true;
      }
      this.cd.detectChanges();
    });
  }

  orderToChange(deliveryType): void {
    this.orderTo = deliveryType;
    this.productService.emitDTPFlag(deliveryType !== DELIVERY_TYPE.REGULAR);
    this.patientData = { patientID: '', patientReference: '', addressType: true };
    this.resetMaxQuantityError = Math.random().toString();
    this.setPatientReference(this.selectedProductValue);
  }

  selectedProductLensValue(productsVal): void {
    this.selectedProductValue = productsVal;
    this.setPatientReference(productsVal);
  }

  private setPatientReference(productsVal): void {
    if (this.selectedProductValue) {
      productsVal.forEach((entry: VisionCareAddtocartProductRequest) => {
        if (Object.keys(this.patientData).length) {
          entry.patientName = this.patientData.patientReference.trim();
          entry.patientId = this.patientData.patientID;
          entry.patientShippingAddressMain = this.patientData.addressType;
          entry.orderEntryFlowType = this.orderTo;
        }
      });

      if (
        this.orderTo === this.deliveryTypes.DIRECT_TO_PATIENT &&
        this.patientData.patientID.length &&
        !this.noAddress
      ) {
        this.productService.sendVariantDetails(productsVal);
      } else if (this.orderTo === this.deliveryTypes.REGULAR) {
        this.productService.sendVariantDetails(productsVal);
      } else {
        this.productService.sendVariantDetails([]);
      }
    } else {
      this.productService.sendVariantDetails([]);
    }
  }

  patientSelected(patientData): void {
    this.defaultAddress = patientData.addressType;
    this.patientData = patientData;
    this.setPatientReference(this.selectedProductValue);
  }

  changeAddress(patientID: string): void {
    this.modalOptions.open = true;
    this.productListService.setPatientData(patientID);
    this.productListService.patientCreated.next(patientID);
  }

  addPatient(buttonId: string): void {
    this.modalOptions.open = true;
    this.productListService.addOption(buttonId);
  }

  saveAddressInfo(address): void {
    this.productListService.newPatientIdSubject.next(null);
    this.defaultAddress = address.defaultAddress;
    this.modalOptions.open = false;
    this.productListService.changedAddressTypeSubject.next(this.defaultAddress);
    this.productListService.setPatientAddress(address);
    this.productListService.clearPatientData();
    this.store.dispatch(LoadItems());
  }

  closeAddressModal(): void {
    this.modalOptions.open = false;
    this.productListService.clearPatientData();
  }

  closeModal(): void {
    this.modalOptions.open = false;
    document.body.classList.remove('modal-open');
    this.productListService.clearPatientData();
    this.productListService.enablePanel();
  }

  closeAddPatientSection(): void {
    this.modalOptions.open = false;
    this.productListService.enablePanel();
  }

  patientAddedSuccessfully(patientID): void {
    this.modalOptions.open = false;
    this.defaultAddress = true;
    this.productListService.changedAddressTypeSubject.next(true);
    this.productListService.enablePanel(patientID);
  }

  setDtpError(error: boolean): void {
    this.dtpError = error;
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
