import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CartItemsService } from 'src/app/services/cart-items/cart-items.service';
import { ReloadCart } from '../store/Actions/cartItem.action';
import { VisionCareCartItems } from '../store/States/cartItem.state';

@Injectable({ providedIn: 'root' })
export class CartItemsResolver implements Resolve<Observable<any>> {
  constructor(private cartItemsService: CartItemsService, private store: Store) {}

  resolve(): Observable<VisionCareCartItems> {
    this.store.dispatch(ReloadCart());
    return this.cartItemsService.loadVcCart();
  }
}
