import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductDetailsService } from 'src/app/services/alcon-pdp/product-details.service';
import { ProductListService } from 'src/app/services/product-list-page/product-list.service';
import { DELIVERY_TYPE } from 'src/app/shared/constants/constants';
import { VisionCareAddtocartProductRequest } from '../../../../store/States/cartItem.state';

@Component({
  selector: 'app-configure-products',
  templateUrl: './configure-products.component.html',
  styleUrls: ['./configure-products.component.scss'],
})
export class ConfigureProductsComponent implements OnInit {
  @Output() emitCloseModal: EventEmitter<any> = new EventEmitter();
  @Output() changeAddressTypeEmitter: EventEmitter<boolean> = new EventEmitter();

  refreshProductPanel;
  resetMaxQuantityError: string;

  @Input() productCode: string;
  @Input() clcDryeyeFlag: boolean;
  @Input() dtpError: boolean;
  disable = this.productListService.disablePanel;
  selectedProductValue: Array<VisionCareAddtocartProductRequest>;
  deliveryTypes = DELIVERY_TYPE;
  isDTP = false;
  constructor(
    private productListService: ProductListService,
    private productDetailService: ProductDetailsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  closeModal(): void {
    this.emitCloseModal.emit('');
  }
  selectedProductLensValue(val): void {
    this.selectedProductValue = val;
  }

  setToDefaults(value: boolean): void {
    this.refreshProductPanel = Math.random().toString();
  }

  changeAddressType(addressType: boolean): void {
    this.changeAddressTypeEmitter.emit(addressType);
  }

  changeDeliveryType(value: string): void {
    this.isDTP = value === this.deliveryTypes.DIRECT_TO_PATIENT;
    this.productDetailService.emitDTPFlag(this.isDTP);
    this.resetMaxQuantityError = Math.random().toString();
    this.cd.detectChanges();
  }
}
