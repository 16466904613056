import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalMessageService, GlobalMessageType, TranslationService } from '@spartacus/core';
import { combineLatest, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { UpdateUserProfileService } from 'src/app/services/updateuserprofile/update-user-profile.service';
import { AUTO_ORDER } from 'src/app/shared/constants/constants';
import { ToggleConfiguration } from 'src/app/store/States/orderHistory';

declare module '@spartacus/user/account/root' {
  interface User {
    autoOrderingTimeGroupDisplay?: any;
    autoOrdering?: boolean;
  }
}

@Component({
  selector: 'app-alcon-auto-ordering',
  templateUrl: './alcon-auto-ordering.component.html',
  styleUrls: ['./alcon-auto-ordering.component.scss'],
})
export class AlconAutoOrderingComponent implements OnInit, OnDestroy {
  meridian = false;
  isAutoOrder: boolean;
  toggleConfig: ToggleConfiguration;
  meridianChangedValue = 'PM';
  time = { hour: 0, minute: 0 };
  isEditMode = true;
  tempTime: any;
  timeError = false;
  private destroy$ = new Subject<void>();
  translationArray = [];
  resetError: boolean;
  constructor(
    private userProfileService: UpdateUserProfileService,
    private globalMessageService: GlobalMessageService,
    private changeDetector: ChangeDetectorRef,
    private translation: TranslationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.userProfileService
      .getUserDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          this.isAutoOrder = res.autoOrdering;
          this.changeDetector.detectChanges();
          this.toggleConfig = { status: res.autoOrdering, onLabel: AUTO_ORDER.YES, offLabel: AUTO_ORDER.NO };
          let resTime = res.autoOrderingTimeGroupDisplay.split(':');
          resTime = this.checkForMeridian(resTime);
          this.time = { hour: +resTime[0], minute: +resTime[1] };
          this.changeDetector.detectChanges();
        }
      });
    this.getTransalatedValues();
  }

  checkForMeridian(resTime): Array<number> {
    if (resTime[1].toLowerCase().includes('pm') || resTime[1].toLowerCase().includes('am')) {
      this.meridian = true;
      const num = resTime[1].split(' ');
      resTime[1] = num[0];
      resTime[0] = num[1].toLowerCase() === 'pm' ? Number(resTime[0]) + 12 : resTime[0];
    }
    resTime = [Number(resTime[0]), Number(resTime[1])];
    return resTime;
  }

  setTimeError(event): void {
    this.timeError = event;
  }

  getTransalatedValues(): void {
    combineLatest([
      this.translation.translate('autoOrderingTranslations.ENABLE_SUCCESS_MSG'),
      this.translation.translate('autoOrderingTranslations.DISABLE_SUCCESS_MSG'),
      this.translation.translate('autoOrderingTranslations.ERROR_MSG'),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.translationArray = data;
      });
  }
  /**
   * Triggers on Active/Inactive toggle change
   */
  onToggleChange(event): void {
    event.preventDefault();
    this.updateAutoOrderFlag(event.target.checked);
  }

  /**
   *
   * @param status // Active or Inactive
   * @param isSave // true in case of save button click
   * @param selectedTime // time set by user
   */
  updateAutoOrderFlag(status: boolean, isSave = false, selectedTime = ''): void {
    this.userProfileService
      .updateAutoOrderStatus(status, selectedTime)
      .pipe(take(1))
      .subscribe(
        (apiResponse) => {
          if (apiResponse?.autoOrdering === true) {
            this.setGlobalMessage(this.translationArray[0], GlobalMessageType.MSG_TYPE_CONFIRMATION);
            this.setAutoOrderingObject(apiResponse);
          } else if (apiResponse?.autoOrdering === false) {
            this.setGlobalMessage(this.translationArray[1], GlobalMessageType.MSG_TYPE_CONFIRMATION);
            this.setAutoOrderingObject(apiResponse);
          }
          this.changeDetector.detectChanges();
        },
        (error) => {
          if (isSave) {
            this.resetTimeToDefault();
          }
          this.setGlobalMessage(this.translationArray[2], GlobalMessageType.MSG_TYPE_ERROR);
        }
      );
  }

  setGlobalMessage(msg: string, type: GlobalMessageType): void {
    this.globalMessageService.add(msg, type, 3000);
  }

  /**
   * Only after succes API response changes value of toggle button
   * Also updates the user current account details w.r.t response
   * @param response // BE API response
   */
  setAutoOrderingObject(response): void {
    this.toggleConfig.status = response.autoOrdering;
    this.userProfileService.updateUserCurrentData(response);
  }

  onEdit(): void {
    this.isEditMode = false;
    this.changeDetector.detectChanges();
    // to reset the time to previous state in case of cancel and API error
    this.tempTime = { ...this.time };
  }

  onSaveOrCancel(type = AUTO_ORDER.Cancel): void {
    this.resetError = !this.resetError;
    this.isEditMode = true;
    this.changeDetector.detectChanges();
    if (type === AUTO_ORDER.Save && this.time) {
      const timeSelected = this.setTimeToBeSaved();
      this.updateAutoOrderFlag(true, true, timeSelected);
      this.time = { hour: +timeSelected.split(':')[0], minute: +timeSelected.split(':')[1] };
    } else {
      this.resetTimeToDefault();
    }
  }
  meridianChanged(event): void {
    this.meridianChangedValue = event;
  }

  setTimeToBeSaved(): string {
    let timeSelected;
    if (this.meridian) {
      return this.meridianChangedValue === 'PM' ? this.handlePMCalculations() : this.handleAMcalculations();
    } else {
      timeSelected = Number(this.time.hour) === 0 ? this.appendZeros() : this.unModifiedTime();
      return timeSelected;
    }
  }

  unModifiedTime(): string {
    return this.time?.hour + ':' + this.time?.minute.toString().padStart(2, '0');
  }

  appendZeros(): string {
    return '00' + ':' + this.time?.minute.toString().padStart(2, '0');
  }

  handleAMcalculations(): string {
    let timeSelected;
    if (Number(this.time?.hour) >= 12) {
      timeSelected = Number(this.time?.hour) > 12 ? this.modifySelectedTime(false) : this.appendZeros();
      return timeSelected;
    } else {
      return (timeSelected = this.unModifiedTime());
    }
  }

  handlePMCalculations(): string {
    let timeSelected;
    timeSelected = Number(this.time?.hour) === 12 ? this.unModifiedTime() : this.modifySelectedTime(true);
    return timeSelected;
  }

  modifySelectedTime(isAdd: boolean): string {
    let resultTime;
    resultTime = isAdd ? Number(this.time?.hour) + 12 : Number(this.time?.hour) - 12;
    return resultTime + ':' + this.time?.minute.toString().padStart(2, '0');
  }

  resetTimeToDefault(): void {
    this.time = { ...this.tempTime };
    this.timeError = false;
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
