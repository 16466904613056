<div class="minicart__icon" [ngClass]="isCartDisplay ? 'minicart__icon' : 'minicart__iconModalOpen'">
  <a (click)="showCart($event)" class="position-relative">
    <cx-icon type="MiniCart" *ngIf="!isCartDisplay"></cx-icon>
    <cx-icon type="closeProfileIcon" *ngIf="isCartDisplay"></cx-icon>
    <span class="header-counter minicart__counter" *ngIf="(totalItems | async) && !isCartDisplay">{{
      totalItems | async
    }}</span>
  </a>
</div>

<div *ngIf="isCartDisplay" class="minicart__modal">
  <div class="minicart-header">
    <nav>
      <label class="minicart-header__title">{{ 'miniCartPage.title' | cxTranslate }}</label>
    </nav>
  </div>
  <div *ngIf="cartvalue" class="minicart-wrapper" id="cart-id">
    <div *ngFor="let entry of newEntries">
      <label class="minicart-wrapper__lableEntry">{{ 'miniCartPage.' + entry.label | cxTranslate }}</label>
      <div class="minicart-wrapper__cartData" *ngFor="let eachEntry of entry.children">
        <label
          class="minicart-wrapper__lableEachEntry"
          *ngIf="entry.label !== 'DIRECT_TO_PATIENT' && eachEntry.orderEntryFlowType !== 'VALUE_PACK'"
          >{{ 'miniCartPage.' + eachEntry.label | cxTranslate }} ({{ eachEntry.count }})</label
        >
        <label
          class="minicart-wrapper__lableEachEntry"
          *ngIf="eachEntry.orderEntryFlowType === 'VALUE_PACK' && eachEntry.showLabel"
          >{{ 'miniCartPage.valuePacks' | cxTranslate }} ({{ individaulValuePackCount }})</label
        >
        <label
          class="minicart-wrapper__lableEachEntry"
          *ngIf="eachEntry.orderEntryFlowType === 'DTP_VALUE_PACK' && eachEntry.showLabel"
          >{{ 'miniCartPage.valuePacks' | cxTranslate }} ({{ dtpValuePackCount }})</label
        >
        <h4 class="minicart-wrapper__itemName" *ngIf="entry.label === 'DIRECT_TO_PATIENT'">
          {{ eachEntry.patientName }}
        </h4>
        <div
          class="minicart-wrapper__cartdetails"
          *ngIf="eachEntry.orderEntryFlowType !== 'DTP_VALUE_PACK' && eachEntry.orderEntryFlowType !== 'VALUE_PACK'"
        >
          <div>
            <app-minicart-items
              *ngFor="let cartEntry of eachEntry.entries.slice(0, 2)"
              [item]="cartEntry"
            ></app-minicart-items>
          </div>
        </div>
        <div
          *ngIf="
            eachEntry?.entries?.length > 2 &&
            eachEntry.orderEntryFlowType !== 'DTP_VALUE_PACK' &&
            eachEntry.orderEntryFlowType !== 'VALUE_PACK'
          "
        >
          <a href="javascript:void(0)" (click)="isCartDisplay = false" routerLink="/cart">
            <label class="minicart-wrapper__cartData--itemclass"
              >({{ eachEntry.entries.length - 2 }} {{ 'miniCartPage.moreitem' | cxTranslate }} )</label
            >
          </a>
        </div>
        <div
          class="minicart-wrapper__cartdetails"
          *ngIf="eachEntry.orderEntryFlowType === 'DTP_VALUE_PACK' || eachEntry.orderEntryFlowType === 'VALUE_PACK'"
        >
          <app-minicart-dtp-valuepack [valuePack]="eachEntry.entries"></app-minicart-dtp-valuepack>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="cartvalue" class="minicart-footer">
    <div class="minicart-footer__content">
      <label class="minicart-footer--total">{{ 'miniCartPage.total' | cxTranslate }}:</label>
      <label class="minicart-footer__content--price">{{ 'miniCartPage.totalprice' | cxTranslate }}</label>
    </div>
    <div class="button-hold">
      <a
        href="javascript:void(0)"
        routerLink="/cart"
        (click)="isCartDisplay = false"
        class="btn btn-primary text-uppercase minicart-footer__button"
        >{{ 'miniCartPage.gotocart' | cxTranslate }}
      </a>
    </div>
  </div>
  <div *ngIf="emptyCartvalue" class="minicart-emptyCart">
    <label class="minicart-emptyCart__label">{{ 'miniCartPage.yourcartIsEmpty' | cxTranslate }}. </label>
    <p class="minicart-emptyCart__message">{{ 'miniCartPage.cartMessage' | cxTranslate }}</p>

    <div class="minicart-emptyCart__panel-list">
      <app-mini-no-order-panel></app-mini-no-order-panel>
    </div>
  </div>
</div>
