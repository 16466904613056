<button
  [disabled]="disableReOrder"
  [ngClass]="{ 'reorder-button-disable': disableReOrder }"
  *ngIf="buttonType !== 'patientReorder'"
  class="reorder-button"
  (click)="reOrder($event)"
>
  {{ 'orderHistoryPage.reorder' | cxTranslate }}
</button>

<button
  class="reorder-patient-button"
  *ngIf="buttonType === 'patientReorder'"
  (click)="reOrder($event)"
  [disabled]="disableReOrder"
  [ngClass]="{ 'reorder-pe-none': disableReOrder }"
>
  <cx-icon [type]="disableReOrder ? 'ReOrderDisable' : 'ReOrder'"
    ><span class="reorder-text">{{ 'patientOrderHistory.reOrder' | cxTranslate }}</span></cx-icon
  >
</button>

<app-loader-icon *ngIf="showLoader"></app-loader-icon>
<app-modal [modalOptions]="modalOptions" [showBigPopup]="false">
  <div *ngIf="reOrderResponseObj" class="modal-block">
    <app-re-order-validation [reOrderResponse]="reOrderResponseObj" (closePopup)="closeModal()">
    </app-re-order-validation>
  </div>
</app-modal>
