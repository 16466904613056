import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { POSTAL_CODE_VALIDATOR_CONST } from 'src/app/shared/constants/constants';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  constructor(public addressFormBuilder: UntypedFormBuilder) {}
  postalCodeCountryValidator(): ValidatorFn {
    return (address: UntypedFormGroup) => {
      let error = null;
      if (!address.get('country').get('isocode').valid && address.get('country').touched) {
        error = { coutryMatch: true };
      } else {
        const selectedCountry = address.get('country').get('isocode').value;
        if (this.isValidationExist(POSTAL_CODE_VALIDATOR_CONST, selectedCountry)) {
          const { pattern } = POSTAL_CODE_VALIDATOR_CONST[address.get('country').get('isocode').value];
          const regPttern = new RegExp(pattern);
          if (!regPttern.test(address.get('postalCode').value)) {
            error = { invalidPostalCode: true };
          }
        } else {
          error = null;
        }
      }
      return error;
    };
  }

  isValidationExist(validator, value): string {
    return Object.keys(validator).find((key) => key === value);
  }

  getAddressFields(): UntypedFormGroup {
    const addressFields: UntypedFormGroup = this.addressFormBuilder.group(
      {
        line1: ['', [Validators.required, Validators.maxLength(30)]],
        line2: ['', [Validators.maxLength(30)]],
        town: ['', [Validators.required, Validators.maxLength(20)]],
        postalCode: ['', [Validators.required]],
        country: this.addressFormBuilder.group({
          isocode: ['', Validators.required],
          name: [''],
        }),
        region: this.addressFormBuilder.group({
          isocode: [''],
          name: [''],
        }),
        defaultAddress: [false],
        id: [''],
      },
      {
        validators: [this.postalCodeCountryValidator()],
      }
    );
    return addressFields;
  }
}
