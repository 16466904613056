<div class="cart--wrapper" *ngIf="(individualOrderInfo$ | async)?.orderCount">
  <app-cart-section-header
    [title]="'cartPagev2.INDIVIDUAL_ORDER' | cxTranslate"
    [count]="(individualOrderInfo$ | async)?.orderCount"
    (accordionStatus)="toggle($event, cartGroup.INDIVIDUAL_ORDER)"
  >
  </app-cart-section-header>
  <div
    class="d-print-block cart__header"
    [ngClass]="{
      'd-block': showindividualOrders,
      'd-none': !showindividualOrders
    }"
  >
    <app-delivery-method [deliveryInfo]="deliveryInfo" *ngIf="showAddress"></app-delivery-method>
    <app-cart-list-items [entries]="individualOrders" [orderType]="cartGroup.INDIVIDUAL_ORDER"></app-cart-list-items>
  </div>
</div>

<div class="cart--wrapper" *ngIf="(valuePacksInfo$ | async)?.orderCount">
  <app-cart-section-header
    [title]="'cartPagev2.VALUE_PACK' | cxTranslate"
    [count]="(valuePacksInfo$ | async)?.orderCount"
    (accordionStatus)="toggle($event, cartGroup.VALUE_PACK)"
  >
  </app-cart-section-header>
  <div
    class="d-print-block cart__header"
    [ngClass]="{
      'd-block': showvaluePackOrders,
      'd-none': !showvaluePackOrders
    }"
  >
    <app-delivery-method [deliveryInfo]="deliveryInfo" *ngIf="showAddress"></app-delivery-method>
    <app-cart-list-items [entries]="valuePackOrders" [orderType]="cartGroup.VALUE_PACK"></app-cart-list-items>
  </div>
</div>

<div class="cart--wrapper d-print-block" *ngIf="(stockOrderInfo$ | async)?.orderCount">
  <app-cart-section-header
    [title]="'cartPagev2.STOCK_ORDER' | cxTranslate"
    [count]="(stockOrderInfo$ | async)?.orderCount"
    (accordionStatus)="toggle($event, cartGroup.STOCK_ORDER)"
  >
  </app-cart-section-header>
  <div
    class="d-print-block cart__header"
    [ngClass]="{
      'd-block': showstockorders,
      'd-none': !showstockorders
    }"
  >
    <app-delivery-method [deliveryInfo]="deliveryInfo" *ngIf="showAddress"></app-delivery-method>
    <app-cart-list-items
      [entries]="stockorders"
      [enableCounter]="false"
      [showPatientReference]="false"
      [orderType]="cartGroup.STOCK_ORDER"
    ></app-cart-list-items>
  </div>
</div>

<div class="cart--wrapper" *ngIf="(dtpInfo$ | async)?.orderCount">
  <app-cart-section-header
    [title]="'cartPagev2.DIRECT_TO_PATIENT' | cxTranslate"
    [count]="(dtpInfo$ | async)?.orderCount"
    (accordionStatus)="toggle($event, cartGroup.DIRECT_TO_PATIENT)"
  >
  </app-cart-section-header>
  <div
    class="d-print-block cart__header"
    [ngClass]="{
      'd-block': showdtporders,
      'd-none': !showdtporders
    }"
  >
    <app-cart-list-items [entries]="dtporders" [enableCounter]="false" [orderType]="cartGroup.DIRECT_TO_PATIENT">
    </app-cart-list-items>
  </div>
</div>
<app-loader-icon *ngIf="loading$ | async"></app-loader-icon>
