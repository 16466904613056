import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SemanticPathService } from '@spartacus/core';
import { FRANCHISE_SELECTOR } from 'src/app/shared/constants/constants';

@Component({
  selector: 'app-franchise-selector',
  templateUrl: './franchise-selector.component.html',
  styleUrls: ['./franchise-selector.component.scss'],
})
export class FranchiseSelectorComponent implements OnInit {
  constructor(private router: Router, private semanticPathService: SemanticPathService) {}

  ngOnInit(): void {}
  onSubmit(form): void {
    const franchiseSelector = form.value.SVCFranchise;
    if (franchiseSelector === FRANCHISE_SELECTOR.SURGICAL) {
      this.router.navigate(
        this.semanticPathService.transform({
          cxRoute: 'registration',
        })
      );
    } else if (franchiseSelector === FRANCHISE_SELECTOR.VISION_CARE) {
      this.router.navigate(
        this.semanticPathService.transform({
          cxRoute: 'register',
        })
      );
    }
  }
}
