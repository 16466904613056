<div class="cart-wrapper">
  <div class="d-flex justify-content-between cart-wrapper__body">
    <label class="cart-wrapper__entry">{{ 'miniCartPage.PRACTICE_ORDER' | cxTranslate }}</label>
    <div class="cart-wrapper__shippingAddress">
      <app-alcon-shipping-address class="cart-wrapper__shippingAddress-body"></app-alcon-shipping-address>
    </div>
  </div>
  <div class="cart-wrapper__panel">
    <label class="cart-wrapper__order">{{ 'miniCartPage.orderNumber' | cxTranslate }}</label>
    <label class="orderNumberValue">{{ orderNumber }}</label>
  </div>
  <div class="cart-wrapper__panel d-flex justify-content-between">
    <div>
      <label class="cart-wrapper__method">{{ 'miniCartPage.deliveryMethod' | cxTranslate }}</label>
      <label class="orderValue">{{ deliveryMethod }}</label>
    </div>
    <div class="cart-wrapper__panel__po-number">
      <label class="cart-wrapper__method">{{ 'miniCartPage.poNumber' | cxTranslate }}</label>
      <label class="orderValue">{{ ponumber }}</label>
    </div>
  </div>
  <div *ngIf="practiceOrderEntries">
    <app-alcon-list-items
      [entry]="entry"
      [finalPriceCalculated]="finalPriceCalculated"
      *ngFor="let entry of practiceOrderEntries[0]?.children"
    >
    </app-alcon-list-items>
  </div>
  <div *ngIf="directToPracticeEntries?.length > 0" class="cart-wrapper__dtp-section">
    <app-direct-to-patient-confirmed-order
      [dtpOrders]="directToPracticeEntries[0]"
      [finalPriceCalculated]="finalPriceCalculated"
    >
    </app-direct-to-patient-confirmed-order>
  </div>
</div>
