<div class="productImageContainer row justify-content-center">
  <div
    class="col-10 productImageContainer__mainImage"
    [ngClass]="{
      'col-lg-8': !privateLabel,
      'col-lg-10': privateLabel
    }"
  >
    <ng-container *ngIf="mainImage$ | async as main; else loader">
      <cx-media class="productImageContainer__mainImage__block" [container]="main" alt="Product Image"> </cx-media>
      <!-- <cx-icon class="search" type="Search" (click)="openModal(main)"></cx-icon> -->
    </ng-container>
  </div>
  <div class="col-10 col-lg-2 productImageContainer__thumbnails" *ngIf="!privateLabel">
    <ng-container *ngIf="thumbs$ | async as thumbs; else loader">
      <cx-carousel
        *ngIf="thumbs.length"
        class="productImageContainer__thumbnails__thumb"
        [items]="thumbs"
        itemWidth="96px"
        [hideIndicators]="true"
        [template]="thumb"
      ></cx-carousel>
    </ng-container>
    <!-- As per requirement needed in future -->
    <!-- <ng-template #thumb let-item="item">
      <cx-media [container]="item.container" tabindex="0" format="product"> </cx-media>
    </ng-template> -->
  </div>
</div>
<app-modal [modalOptions]="modalOptions" (closeHandeller)="closeModal()">
  <div class="modal-block">
    <div class="zoom-image">
      <ng-container *ngIf="zoomImage">
        <cx-media [container]="zoomImage" alt="Zoomed Product Image"> </cx-media>
      </ng-container>
    </div>
  </div>
</app-modal>
<ng-template #loader>
  <app-loader-icon></app-loader-icon>
</ng-template>
