import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConsentTemplate, OccEndpointsService, UserIdService } from '@spartacus/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LegalAnnouncementService {
  private userId: string;
  templates: Array<ConsentTemplate> = [];
  constructor(
    private httpClient: HttpClient,
    private occEndPoints: OccEndpointsService,
    private userIdService: UserIdService
  ) {
    this.userIdService.getUserId().subscribe((userid) => {
      this.userId = userid;
    });
  }

  acceptLegalConsent(): Observable<any> {
    const consents = [];
    this.templates.forEach((template) => {
      const url = this.occEndPoints.buildUrl(`/users/${this.userId}/consents`);
      const params = new HttpParams()
        .set('consentTemplateId', template.id)
        .set('consentTemplateVersion', template.version?.toString());
      consents.push(this.httpClient.post<any>(url, {}, { params }));
    });
    return forkJoin(consents);
  }

  getTemplates(): Observable<Array<ConsentTemplate>> {
    const url = this.occEndPoints.buildUrl(`/users/${this.userId}/consenttemplates`);
    return this.httpClient.get<any>(url).pipe(
      tap((templates) => (this.templates = [...templates.consentTemplates])),
      map((templates) => [...templates.consentTemplates])
    );
  }
}
