import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { UserIdService } from '@spartacus/core';
import { CurrentUserIdService } from './service/current-user-id.service';

function userIdfactory(userIdService: UserIdService, currentUserId: CurrentUserIdService): () => void {
  return () => {
    userIdService.getUserId().subscribe((userID) => {
      currentUserId.setUserId(userID);
    });
  };
}

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: userIdfactory,
      deps: [UserIdService, CurrentUserIdService],
      multi: true,
    },
  ],
})
export class UserIdProviderModule {}
