<div class="patient">
  <div class="patient__reference">
    <div class="patient__reference__label">
      <label>
        {{
          (DTP
            ? 'configureProducts.deliveryPanel.patientReferenceMandatory'
            : 'configureProducts.deliveryPanel.patientReference'
          ) | cxTranslate
        }}
      </label>
    </div>
    <div class="patient__reference__selector">
      <app-dropdown-selector-v2
        *ngIf="showPatientDropdown"
        [config]="dropdownConfig"
        [disableDropDown]="disable"
        [options]="patients | async"
        (dropDownSelected)="dropDownSelected($event, true)"
        (searchTextChange)="searchTextChange($event)"
        (buttonEvent)="addOption($event)"
      >
      </app-dropdown-selector-v2>
      <input
        *ngIf="!showPatientDropdown"
        type="text"
        (input)="searchTextChange($event.target.value)"
        value=""
        class="bg-white w-100 text-left text-dark patient__reference__selector__text"
      />
    </div>
  </div>
  <div class="patient__address">
    <div class="patient__address__selector" *ngIf="selectedPatientAddress || noMainAddress">
      <app-patient-address [patientAddress]="selectedPatientAddress"> </app-patient-address>
      <a
        class="patient__address__selector__change--address"
        [ngClass]="{ 'patient__address__selector__change--address-disabled': disable }"
        (click)="changeDeliveryAddress()"
      >
        {{ 'configureProducts.deliveryPanel.deliveryToDifferentAddress' | cxTranslate }}
      </a>
    </div>
  </div>
</div>
<app-loader-icon *ngIf="showLoader"></app-loader-icon>
