<app-patient-list-filters></app-patient-list-filters>
<div class="patientsTable">
  <div
    class="d-flex flex-column align-items-center"
    *ngIf="patientsList?.length === 0 && !patientSearch && !filtersSelected"
  >
    <p class="noPatientFound b2b-col-48">{{ 'myPatientsPage.noPatientFound' | cxTranslate }}</p>
    <app-add-patient-button-popup></app-add-patient-button-popup>
  </div>
  <div class="d-flex flex-column align-items-center" *ngIf="patientsList?.length === 0 && patientSearch">
    <app-add-patient-button-popup class="patient-search"></app-add-patient-button-popup>
    <p class="noPatientFound-search b2b-col-48">{{ 'myPatientsPage.searchNoPatient' | cxTranslate }}</p>
  </div>
  <div
    class="no-patient d-flex flex-column justify-content-center align-items-center"
    *ngIf="patientsList?.length === 0 && filtersSelected"
  >
    <p class="noPatientFound-search b2b-col-48 mb-4">{{ 'myPatientsPage.noPatientsFound' | cxTranslate }}</p>
    <span class="adjustfilters">
      {{ 'myPatientsPage.adjustFilters' | cxTranslate
      }}<a class="clear-all" (click)="clearallFilters()">{{ 'patientListFilters.clearallfilters' | cxTranslate }}</a>
    </span>
  </div>

  <div *ngIf="patientsList?.length">
    <table class="table table-borderless">
      <thead>
        <tr>
          <th class="col-3 pl-0">
            <app-add-patient-button-popup [btnWidth]="199"></app-add-patient-button-popup>
          </th>
          <th class="pr-0 dropdown-data">
            <p class="sortStyle">{{ 'myPatientsPage.sort' | cxTranslate }}</p>
            <app-dropdown-selector
              [selectedIndex]="0"
              [options]="sortPatientsList"
              (dropDownSelected)="sortBy(patientsList, $event)"
              [textColorBlack]="true"
            ></app-dropdown-selector>
          </th>
        </tr>
      </thead>
    </table>
  </div>

  <div *ngIf="patientsList?.length">
    <app-single-patient-details
      *ngFor="let patientData of patientsList | slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize"
      [patientData]="patientData"
    ></app-single-patient-details>
  </div>
  <button class="btn btn-default goToTop d-block d-lg-none" (click)="scrollTop()">
    <cx-icon class="goToTop__icon" role="button" type="DropdownCollapse"></cx-icon>
    <p class="goToTop__text">{{ 'myPatientsPage.top' | cxTranslate }}</p>
  </button>
  <ngb-pagination
    *ngIf="patientsList?.length > pageSize"
    class="d-flex justify-content-center mt-4 pt-1"
    [collectionSize]="patientsList?.length"
    [pageSize]="pageSize"
    [(page)]="page"
    [maxSize]="maxSize"
    [ellipses]="false"
    [rotate]="true"
  ></ngb-pagination>
</div>
