import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { I18nModule } from '@spartacus/core';
import { IconModule, MediaModule } from '@spartacus/storefront';
import { AlconCommonModule } from '../common/alcon-common.module';
import { BasicProductSelectorComponent } from './basic-product-selector/basic-product-selector.component';
import { ProductSelectorComponent } from './product-selector/product-selector.component';

@NgModule({
  imports: [CommonModule, FormsModule, I18nModule, MediaModule, AlconCommonModule, IconModule],
  declarations: [BasicProductSelectorComponent, ProductSelectorComponent],
  exports: [BasicProductSelectorComponent, ProductSelectorComponent],
})
export class AlconProductSelectorModule {}
