import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-loader-icon',
  templateUrl: './loader-icon.component.html',
  styleUrls: ['./loader-icon.component.scss'],
})
export class LoaderIconComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}
}
