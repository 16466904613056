<header class="site-not-found__header">
  <img src="/assets/images/alconLogo.svg" class="pl-3 pt-1" alt="Alcon" />
</header>
<div class="site-not-found__header-block">
  <h3 class="site-not-found--title mb-0 text-center">{{ 'siteDirectory.discoverHeading' | cxTranslate }}</h3>
  <h2 class="site-not-found--choose-location text-center">{{ 'siteDirectory.chooseLocation' | cxTranslate }}</h2>
</div>

<div class="site-not-found__navigation">
  <ng-container *ngIf="(siteData$ | async)?.sitesByZones as sitesData">
    <div class="site-not-found__nav d-flex justify-content-center">
      <nav ngbNav #nav2="ngbNav" [(activeId)]="active2" class="nav-tabs">
        <ng-container *ngFor="let siteData of sitesData" [ngbNavItem]="siteData.zoneLabel">
          <a ngbNavLink>{{ siteData?.zoneLabel }}</a>
          <ng-template ngbNavContent>
            <div
              class="site-not-found__nav--counties-div justify-content-center"
              *ngFor="let site of siteData?.applicableSites"
            >
              <ul>
                <li *ngFor="let store of site?.stores" class="site-not-found__nav--list d-flex flex-row">
                  <p class="site-not-found__nav--flag">
                    <cx-media
                      [container]="store?.deliveryCountries[0]?.flagMediaUrl"
                      alt="{{ store?.deliveryCountries[0]?.isocode }}"
                    ></cx-media>
                  </p>
                  <div class="site-not-found__nav--country-details">
                    <p class="site-not-found__nav--country-name">
                      {{ store?.deliveryCountries[0]?.name }}
                    </p>
                    <p class="site-not-found__nav--language" *ngFor="let language of store.languages">
                      <a
                        class="site-not-found__nav--country-language"
                        [href]="store?.deliveryCountries[0]?.isocode | lowercase"
                      >
                        {{ language?.name }}
                      </a>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </ng-template>
        </ng-container>
      </nav>
    </div>
    <div [ngbNavOutlet]="nav2" class="mt-2"></div>
  </ng-container>
</div>

<app-loader-icon *ngIf="loading"></app-loader-icon>
