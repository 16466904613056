const INPUT_DEFAULT_VALUE = 'defaultValue';
const INPUT_TYPE = 'type';
const INPUT_PHONE = 'phone';
const INPUT_SAP = 'sapId';
const INPUT_PASSWORD = 'password';
const INPUT_DATE = 'date';
const INPUT_DATE_FORMAT = 'dateFormat';
const INPUT_MAX_DATE = 'maxDate';
const INPUT_MIN_DATE = 'minDate';
const INPUT_NAME = 'name';
const FEATURES_SHOW_PASSWORD_TOGGLE_ON_REG_PAGE = 'features.showPasswordToggleOnRegPage';
const FEATURES_PHONE_INPUT_PREFERRED_COUNTRIES = 'features.phoneInput.preferredCountries';
const FEATURES_SAP_VALIDATION_SAP_ID = 'features.sapValidation.sapId';
const FEATURES_PHONE_INPUT_INITIAL_COUNTRY = 'features.phoneInput.initialCountry';
const FEATURES_PHONE_INPUT_ONLY_COUNTRIES = 'features.phoneInput.onlyCountries';
const INPUT_OPTIONS_PARAMS_PATTERNS = [
  {
    predicate: function (schemaProperty) {
      const type = schemaProperty.get(INPUT_TYPE);
      return type === "Consent";
    },
    populate: function (schemaProperty, _) {
      const objStr = schemaProperty.get("objStr");
      return objStr ;
    }
  },
  {
    predicate: function (schemaProperty) {
      const type = schemaProperty.get(INPUT_TYPE);
      return type === INPUT_DATE;
    },
    populate: function (schemaProperty, _) {
      const dateFormat = schemaProperty.get(INPUT_DATE_FORMAT);
      const maxDate = schemaProperty.get(INPUT_MAX_DATE);
      const minDate = schemaProperty.get(INPUT_MIN_DATE);
      return { dateFormat, maxDate, minDate };
    }
  },
  {
    predicate: function (schemaProperty) {
      const type = schemaProperty.get(INPUT_TYPE);
      return type === INPUT_PHONE;
    },
    populate: function (_, settings) {
      const preferredCountries = settings.get(FEATURES_PHONE_INPUT_PREFERRED_COUNTRIES);
      const initialCountry = settings.get(FEATURES_PHONE_INPUT_INITIAL_COUNTRY);
      const onlyCountries = settings.get(FEATURES_PHONE_INPUT_ONLY_COUNTRIES);
      const params = {
        phoneInputParams: {}
      };
      if (preferredCountries) {
        params.phoneInputParams.preferredCountries = preferredCountries;
      }
      if (initialCountry) {
        params.phoneInputParams.initialCountry = initialCountry;
      }
      if (onlyCountries) {
        params.phoneInputParams.onlyCountries = onlyCountries;
      }
      return params;
    }
  },
  {
    predicate: function (schemaProperty) {
      const type = schemaProperty.get(INPUT_TYPE);
      const name = schemaProperty.get(INPUT_NAME);
      return type === INPUT_PASSWORD || name === INPUT_PASSWORD;
    },
    populate: function (_, settings) {
      return {
        showPasswordToggle: settings.get(FEATURES_SHOW_PASSWORD_TOGGLE_ON_REG_PAGE)
      };
    }
  },
  {
    predicate: function (schemaProperty) {
      const type = schemaProperty.get(INPUT_TYPE);
      return type === INPUT_SAP;
    },
    populate: function (_, settings) {
      return {
        sapId: settings.get(FEATURES_SAP_VALIDATION_SAP_ID)
      };
    }
  },
];

function populateInputOptionsParams(schemaProperty, settings, inputOptions) {
  for (let index = 0; index < INPUT_OPTIONS_PARAMS_PATTERNS.length; index++) {
    let pattern = INPUT_OPTIONS_PARAMS_PATTERNS[index];
    if (pattern.predicate(schemaProperty)) {
      inputOptions.params = {
        ...inputOptions.params,
        ...pattern.populate(schemaProperty, settings)
      };
    }
  }
  return inputOptions;
};

function populateDefaultValuesToModel(schemaProperty, model) {
  const name = schemaProperty.get(INPUT_NAME);
  const defaultValue = schemaProperty.get(INPUT_DEFAULT_VALUE);
  if (defaultValue != null) {
    model.set(name, defaultValue);
  }
}

export default {
  populateInputOptionsParams,
  populateDefaultValuesToModel
};
