import { CommonModule, DatePipe } from '@angular/common';
import { Route } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CmsConfig, ConfigModule, I18nModule, provideConfig, UrlModule } from '@spartacus/core';
import { CarouselModule, IconModule, MediaModule, PageComponentModule, PageSlotModule } from '@spartacus/storefront';
import { UpdateProfileModule } from '@spartacus/user/profile/components';
import { LegalAnnouncementComponent } from '../cms-pages/legal-announcement/legal-announcement.component';
import { SalesRepComponent } from '../cms-pages/sales-rep/sales-rep.component';
import { DeliveryPanelComponent } from '../delivery-panel/delivery-panel.component';
import { AddToCartValuePackDirective } from '../directives/add-to-cart-value-pack.directive';
import { RangeCheckDirective } from '../directives/app-range-check.directive';
import { CalendarDirective } from '../directives/calendar.directive';
import { SaveScheduledOrderDirective } from '../directives/save-scheduled-order.directive';
//  import { SurgicalCartSummaryComponent } from './surgical-cart-summary/surgical-cart-summary.component';
import { ContactUsModalComponent } from '../shared-modules/contact-us/components/contact-us-modal/contact-us-modal.component';
import { PatientGuard } from '../shared-modules/patient/guard/patient.guard';
import { ShowPriceModule } from '../shared-modules/show-price/show-price.module';
import { AccountDetailsConsolidationComponent } from './account-details-consolidation/account-details-consolidation.component';
import { AlconAccountDetailsComponent } from './alcon-account-details/alcon-account-details.component';
// import { AlconAlertPopupComponent } from './alcon-alert-popup/alcon-alert-popup.component';
import { AlconAutoOrderingComponent } from './alcon-auto-ordering/alcon-auto-ordering.component';
import { AlconCartDataComponent } from './alcon-cart-data/alcon-cart-data.component';
import { AlconCartSummaryComponent } from './alcon-cart-v2/alcon-cart-summary/alcon-cart-summary.component';
import { AlconCartModule } from './alcon-cart-v2/alcon-cart.module';
import { AlconcartComponent } from './alcon-cart-v2/alconcart/alconcart.component';
import { AlconListItemsComponent } from './alcon-list-items/alcon-list-items.component';
import { AlconListPageFilterComponent } from './alcon-list-page-filter/alcon-list-page-filter.component';
import { AlconPrintComponent } from './alcon-print/alcon-print.component';
import { AlconRedirectPopupComponent } from './alcon-redirect-popup/alcon-redirect-popup.component';
import { AlconMiniCartModule } from './alconminicart-v2/alcon-mini-cart.module';
import { AlconminicartV2Component } from './alconminicart-v2/alcon-mini-cart/alconminicart-v2.component';
import { AlconminicartComponent } from './alconminicart/alconminicart.component';
import { MinicartDtpValuepackComponent } from './alconminicart/minicart-dtp-valuepack/minicart-dtp-valuepack.component';
import { MinicartItemsDtpComponent } from './alconminicart/minicart-items-dtp/minicart-items-dtp.component';
import { MinicartItemsComponent } from './alconminicart/minicart-items/minicart-items.component';
import { AlconsearchComponent } from './alconsearch/alconsearch.component';
import { AlconProductSelectorModule } from './basic-product-variant-selector/product-selector.module';
import { CartProductDetailsComponent } from './cart-product-details/cart-product-details.component';
//  import { CartSummaryComponent } from './cart-summary/cart-summary.component';
import { AlconCheckoutComponent } from './checkout/alcon-checkout/alcon-checkout.component';
import { CheckoutModule } from './checkout/checkout.module';
import { PaymentMethodComponent } from './checkout/payment-method/payment-method.component';
import { AlconCommonModule } from './common/alcon-common.module';
//  import { StatusBarComponent } from './common/status-bar/status-bar.component';
//  import { ToggleButtonComponent } from './common/toggle-button/toggle-button.component';
import { AlconConfirmationComponent } from './confirmation/alcon-confirmation/alcon-confirmation.component';
import { DirectToPatientConfirmedOrderComponent } from './confirmation/direct-to-patient-confirmed-order/direct-to-patient-confirmed-order.component';
import { DtpConfirmedOrderDetailsComponent } from './confirmation/dtp-confirmed-order-details/dtp-confirmed-order-details.component';
import { OrderPaymentMethodComponent } from './confirmation/order-payment-method/order-payment-method.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
//  import { DashboardCategoryLinkComponent } from './dashboard-components/dashboard-category-link/dashboard-category-link.component';
//  import { NotificationComponent } from './dashboard-components/notification/notification.component';
//  import { RecentInvoicesDashboardComponent } from './dashboard-components/recent-invoices-dashboard/recent-invoices-dashboard.component';
// import { NavigationComponent } from './dashboard-components/navigation/navigation.component';
//  import { RotatingBannerCarouselComponent } from './dashboard-components/rotating-banner-carousel/rotating-banner-carousel.component';
import { DirectToPatientLineitemComponent } from './direct-to-patient-lineitem/direct-to-patient-lineitem.component';
import { DirectToPatientOrdersComponent } from './direct-to-patient-orders/direct-to-patient-orders.component';
import { DirectToPracticeProductListingComponent } from './direct-to-practice-product-listing/direct-to-practice-product-listing.component';
import { FavouriteLinkComponent } from './favourite-link/favourite-link.component';
import { FranchiseSelectorComponent } from './franchise-selector/franchise-selector.component';
import { GenericOrderListComponent } from './generic-order-list/generic-order-list.component';
import { LoginComponent } from './login/login/login.component';
import { AlconPatientProfileComponent } from './my-patients/alcon-patient-profile/alcon-patient-profile.component';
import { PatientListFiltersComponent } from './my-patients/patient-list-filters/patient-list-filters.component';
import { PatientOrdersComponent } from './my-patients/patient-orders/patient-orders.component';
import { PatientsListComponent } from './my-patients/patients-list/patients-list.component';
import { SinglePatientDetailsComponent } from './my-patients/single-patient-details/single-patient-details.component';
import { NeworderRegularPackComponent } from './new-order/neworder-selectproduct/neworder-regular-pack/neworder-regular-pack.component';
import { NeworderSelectproductComponent } from './new-order/neworder-selectproduct/neworder-selectproduct.component';
import { NeworderValuePackComponent } from './new-order/neworder-selectproduct/neworder-value-pack/neworder-value-pack.component';
import { PatientOrderSelectionPanelComponent } from './new-order/patient-order-selection-panel/patient-order-selection-panel.component';
import { SolutionProductsComponent } from './new-order/solution-products/solution-products.component';
import { NoOrderPanelComponent } from './no-order-panel/no-order-panel.component';
//  import { OrderCancelComponent } from './order-history-panel/order-cancel/order-cancel.component';
//  import { OrderHistoryFilterComponent } from './order-history-panel/order-history-filter/order-history-filter.component';
//  import { OrderHistoryPanelComponent } from './order-history-panel/order-history-panel.component';
//  import { ReOrderValidationComponent } from './order-history-panel/re-order-validation/re-order-validation.component';
//  import { ReOrderComponent } from './order-history-panel/re-order/re-order.component'
import { AlconPatientModule } from './patient/patient.module';
import { AlconProductDetailsComponentsModule } from './product-details-components/product-details-components.module';
import { AlconProductDetailsPageModule } from './product-details-page/product-details-page.module';
import { AlconProductDisplayListComponent } from './product-listing-page/alcon-product-display-list/alcon-product-display-list.component';
import { BrandFilterComponent } from './product-listing-page/brand-filter/brand-filter.component';
import { FacetLinkComponent } from './product-listing-page/facet-link/facet-link.component';
import { FilterDropdownSelectorComponent } from './product-listing-page/filter-dropdown-selector/filter-dropdown-selector.component';
import { ProductCategoryComponent } from './product-listing-page/product-category/product-category.component';
import { SingleListviewProductTileComponent } from './product-listing-page/single-listview-product-tile/single-listview-product-tile.component';
import { ConfigureProductsComponent } from './product-listing-page/single-poduct-tile/configure-products/configure-products.component';
import { ProductModalPopupComponent } from './product-listing-page/single-poduct-tile/product-modal-popup/product-modal-popup.component';
import { ProductWrapperComponent } from './product-listing-page/single-poduct-tile/product-wrapper/product-wrapper.component';
import { SinglePoductTileComponent } from './product-listing-page/single-poduct-tile/single-poduct-tile.component';
import { RegisterFormStepComponent } from './register/register-form-step/register-form-step.component';
import { RegisterSuccessStepComponent } from './register/register-success-step/register-success-step.component';
import { RegisterTermsStepComponent } from './register/register-terms-step/register-terms-step.component';
import { RegisterComponent } from './register/register.component';
import { ResponsiveMediaComponent } from './responsive-media/responsive-media.component';
import { ReturnConfirmationStepsComponent } from './return-form/return-form-confirmation-steps/return-form-confirmation-steps.component';
import { ReturnFormModule } from './return-form/return-form.module';
import { SafeOrderingComponent } from './safe-ordering/safe-ordering.component';
import { ToolsResourcesPanelComponent } from './tools-resources-panel/tools-resources-panel.component';
import { ValuePackItemsComponent } from './value-pack-items/value-pack-items.component';

const routes: Route[] = [];

@NgModule({
  declarations: [
    // NotificationComponent,
    // DashboardCategoryLinkComponent,
    // RecentInvoicesDashboardComponent,
    // RecentOrdersDashboardComponent,
    // SubscriptionDashboardComponent,
    LoginComponent,
    SinglePoductTileComponent,
    BrandFilterComponent,
    AlconProductDisplayListComponent,
    CartProductDetailsComponent,
    FilterDropdownSelectorComponent,
    FacetLinkComponent,
    DirectToPracticeProductListingComponent,
    SingleListviewProductTileComponent,
    // BannerCarouselComponent,
    // RotatingBannerCarouselComponent,
    AlconminicartComponent,
    AlconsearchComponent,
    SafeOrderingComponent,
    // CartSummaryComponent,
    AlconCartDataComponent,
    AlconListItemsComponent,
    AddToCartValuePackDirective,
    PatientsListComponent,
    SinglePatientDetailsComponent,
    AlconConfirmationComponent,
    OrderPaymentMethodComponent,
    AlconPatientProfileComponent,
    // OrderHistoryPanelComponent,
    NoOrderPanelComponent,
    FavouriteLinkComponent,
    // StatusBarComponent,
    ContactUsComponent,
    NeworderSelectproductComponent,
    PatientOrderSelectionPanelComponent,
    ConfigureProductsComponent,
    DeliveryPanelComponent,
    // OrderDetailsStatusComponent,
    // OrderDetailsPaymentmethodComponent,
    // OrderDetailsDeliverymethodComponent,
    // OrderDetailsGroupingItemsComponent,
    DirectToPatientOrdersComponent,
    ValuePackItemsComponent,
    ToolsResourcesPanelComponent,
    DirectToPatientLineitemComponent,
    MinicartItemsComponent,
    MinicartItemsDtpComponent,
    SolutionProductsComponent,
    MinicartDtpValuepackComponent,
    // OrderHistoryFilterComponent,
    CalendarDirective,
    RangeCheckDirective,
    SaveScheduledOrderDirective,
    PatientOrdersComponent,
    GenericOrderListComponent,
    AlconPrintComponent,
    ProductModalPopupComponent,
    DirectToPatientConfirmedOrderComponent,
    DtpConfirmedOrderDetailsComponent,
    // AlconAlertPopupComponent,
    AlconRedirectPopupComponent,
    AlconAccountDetailsComponent,
    // ReOrderComponent,
    ProductWrapperComponent,
    // ReOrderValidationComponent,
    // OrderDetailsLineitemComponent,
    // OrderDetailsGroupHeaderComponent,
    // OrderDetailsGroupLineitemComponent,
    // OrderDetailsGroupValuepackComponent,
    // OrderCancelComponent,
    AccountDetailsConsolidationComponent,
    AlconAutoOrderingComponent,
    NeworderRegularPackComponent,
    NeworderValuePackComponent,
    SalesRepComponent,
    PatientListFiltersComponent,
    // SurgicalCartSummaryComponent,
    // LineItemComponent,
    // LineItemInformationComponent,
    // SurgicalOrderDetailsGroupingItemsComponent,
    RegisterComponent,
    RegisterFormStepComponent,
    RegisterTermsStepComponent,
    RegisterSuccessStepComponent,
    ProductCategoryComponent,
    AlconListPageFilterComponent,
    ResponsiveMediaComponent,
    ContactUsModalComponent,
    FranchiseSelectorComponent,
    // NavigationComponent,
  ],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        // RecentOrderFlexComponent: {
        //   component: RecentOrdersDashboardComponent,
        // },
        // RecentInvoicesFlexComponent: {
        //   component: RecentInvoicesDashboardComponent,
        // },
        // SubscriptionsFlexComponent: {
        //   component: SubscriptionDashboardComponent,
        // },
        // AlconNotificationComponent: {
        //   component: NotificationComponent,
        // },
        // AlconCategoryNavigationComponent: {
        //   component: NavigationComponent,
        // },
        BrandsListFlexComponent: {
          component: BrandFilterComponent,
        },
        ProductListComponent: {
          component: AlconProductDisplayListComponent,
        },
        RegisterPageFlexComponent: {
          component: RegisterComponent,
        },
        VCOrderingProductListingFlexComponent: {
          component: DirectToPracticeProductListingComponent,
        },
        MiniCartComponent: {
          component: AlconminicartV2Component,
        },
        SearchBoxComponent: {
          component: AlconsearchComponent,
        },
        CartOrdersFlexComponent: {
          component: AlconcartComponent,
        },
        CartSummaryFlexComponent: {
          component: AlconCartSummaryComponent,
        },
        CheckoutSafeFlexComponent: {
          component: SafeOrderingComponent,
        },
        CheckoutPaymentMethodFlexComponent: {
          component: PaymentMethodComponent,
        },
        CheckoutOrdersFlexComponent: {
          component: AlconCheckoutComponent,
        },
        MyPatientsListFlexComponent: {
          component: PatientsListComponent,
          guards: [PatientGuard],
        },
        OrderConfirmationLineItemFlexComponent: {
          component: AlconcartComponent,
        },
        OrderConfirmationSummaryFlexComponent: {
          component: AlconCartSummaryComponent,
        },
        OrderConfirmationPaymentMethodFlexComponent: {
          component: OrderPaymentMethodComponent,
        },
        PatientProfileDetailsFlexComponent: {
          component: AlconPatientProfileComponent,
          guards: [PatientGuard],
        },
        // MyOrdersFlexComponent: {
        //   component: OrderHistoryPanelComponent,
        // },
        ContactUsFormFlexComponent: {
          component: ContactUsComponent,
        },
        FavoriteListFlexComponent: {
          component: FavouriteLinkComponent,
        },
        AddNewOrderSelectProductFlexComponent: {
          component: NeworderSelectproductComponent,
          guards: [PatientGuard],
        },
        // OrderDetailsStatusFlexComponent: {
        //   component: OrderDetailsStatusComponent,
        // },
        // SurgicalOrderDetailsPaymentMethodFlexComponent: {
        //   component: SurgicalOrderDetailsPaymentmethodComponent,
        // },
        // OrderDetailsPaymentMethodFlexComponent: {
        //   component: OrderDetailsPaymentmethodComponent,
        // },
        // OrderDetailsDeliveryMethodFlexComponent: {
        //   component: OrderDetailsDeliverymethodComponent,
        // },
        // OrderDetailsSummaryFlexComponent: {
        //   component: CartSummaryComponent,
        // },
        // SurgicalOrderDetailsSummaryFlexComponent: {
        //   component: SurgicalCartSummaryComponent,
        // },
        // OrderDetailsLineItemFlexComponent: {
        //   component: OrderDetailsGroupingItemsComponent,
        // },
        // SurgicalOrderDetailsLineItemFlexComponent: {
        //   component: SurgicalOrderDetailsGroupingItemsComponent,
        // },
        VisioncareToolsAndResourcesComponent: {
          component: ToolsResourcesPanelComponent,
        },
        PatientOrdersFlexComponent: {
          component: PatientOrdersComponent,
        },
        AccountDetailsContentFlexComponent: {
          component: AlconAccountDetailsComponent,
        },
        LegalAnnouncementFlexComponent: {
          component: LegalAnnouncementComponent,
        },
        AlconReturnConfirmationComponent: {
          component: ReturnConfirmationStepsComponent,
        },
        SalesRepresentativeFlexComponent: {
          component: SalesRepComponent,
        },
        AccountDetailsShoppingCartFlexComponent: {
          component: AlconAutoOrderingComponent,
        },
      },
    }),
    IconModule,
    UrlModule,
    RouterModule.forChild(routes),
    PageSlotModule,
    PageComponentModule,
    ReactiveFormsModule,
    MediaModule,
    FormsModule,
    I18nModule,
    CarouselModule,
    AlconCommonModule,
    AlconCartModule,
    AlconMiniCartModule,
    CheckoutModule,
    NgbModule,
    AlconProductDetailsPageModule,
    AlconProductDetailsComponentsModule,
    AlconProductSelectorModule,
    AlconPatientModule,
    ReturnFormModule,
    ShowPriceModule,
  ],
  exports: [
    // DashboardCategoryLinkComponent,
    LoginComponent,
    SinglePoductTileComponent,
    BrandFilterComponent,
    AlconProductDisplayListComponent,
    NoOrderPanelComponent,
    ConfigureProductsComponent,
    UpdateProfileModule,
    AlconListPageFilterComponent,
    ResponsiveMediaComponent,
    ContactUsComponent,
    ContactUsModalComponent,
    FranchiseSelectorComponent,
  ],
  providers: [
    DatePipe,
    provideConfig({
      featureModules: {
        UserManagementModule: {
          module: () =>
            import('../shared-modules/user-management/user-management.module').then((m) => m.UserManagementModule),
          cmsComponents: ['UserManagmentFlexComponent', 'ManageUserFlexComponent'],
        },
        myProfilePage: {
          module: () => import('../shared-modules/my-profile/my-profile.module').then((m) => m.MyProfileModule),
          cmsComponents: ['MyProfileContentFlexComponent', 'CustomerPreferencesFlexComponent'],
        },
        DashboardModule: {
          module: () => import('../shared-modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
          cmsComponents: [
            'AlconRotatingImagesComponent',
            'AlconNotificationComponent',
            'RecentInvoicesFlexComponent',
            'SubscriptionsFlexComponent',
            'VisioncareAlertComponent',
            'AlconCategoryNavigationComponent',
          ],
        },
        OrderHistoryModule: {
          module: () =>
            import('../shared-modules/order-history/order-history.module').then((m) => m.OrderHistoryModule),
          cmsComponents: ['MyOrdersFlexComponent', 'RecentOrderFlexComponent', 'VcMyOrderSearchFlexComponent'],
        },
        OrderDetailsModule: {
          module: () =>
            import('../shared-modules/order-details/order-details.module').then((m) => m.OrderDetailsModule),
          cmsComponents: [
            'OrderDetailsStatusFlexComponent',
            'OrderDetailsPaymentMethodFlexComponent',
            'OrderDetailsDeliveryMethodFlexComponent',
            'OrderDetailsSummaryFlexComponent',
            'OrderDetailsLineItemFlexComponent',
            'SurgicalOrderDetailsLineItemFlexComponent',
            'SurgicalOrderDetailsPaymentMethodFlexComponent',
            'SurgicalOrderDetailsSummaryFlexComponent',
          ],
        },
      },
    } as CmsConfig),
  ],
})
export class AlconCmsComponentsModule {}
