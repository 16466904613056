import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService, SemanticPathService } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { LoadB2BUnits } from 'src/app/store/Actions/b2bUnits.action';
import { getb2bUnitsData } from 'src/app/store/Selectors/b2bUnits.selector';
import { B2BUnits } from 'src/app/store/States/b2bUnits.state';
import { AccountSelectorService } from '../services/account-selector.service';
@Injectable({
  providedIn: 'root',
})
export class AccountSelectorGuard implements CanActivate {
  b2bUnitsData: Partial<B2BUnits>;

  constructor(
    private accountSelectorService: AccountSelectorService,
    private router: Router,
    private accountStore: Store,
    private semanticPathService: SemanticPathService,
    private authService: AuthService
  ) {}

  canActivate(): any {
    const localStorageItem = this.accountSelectorService.getLocalStorageItem();
    const selectedShipTo = localStorageItem.selectedShipTo;
    let selectedSoldTo = localStorageItem.selectedSoldTo;
    if (selectedSoldTo && selectedShipTo) {
      return true;
    } else {
      return this.authService.isUserLoggedIn().pipe(
        filter((isLoggedin) => isLoggedin),
        tap((isLoggedIn) => {
          this.accountStore.dispatch(LoadB2BUnits());
        }),
        switchMap((isLoggedIn) =>
          this.accountStore.select(getb2bUnitsData).pipe(
            filter((b2bUnitsData) => b2bUnitsData.currentUnit !== ''),
            switchMap((b2bUnitsData) => {
              this.b2bUnitsData = { ...b2bUnitsData };
              const noOfAccounts = this.b2bUnitsData?.units?.length;
              if (noOfAccounts) {
                if (noOfAccounts === 1) {
                  this.accountSelectorService.setLocalStorageSoldTo(this.b2bUnitsData.currentUnit);
                  selectedSoldTo = this.b2bUnitsData.currentUnit;
                }
                if (selectedSoldTo) {
                  return this.checkShipTo();
                } else {
                  this.router.navigate(
                    this.semanticPathService.transform({
                      cxRoute: 'accountSelector',
                    })
                  );
                  return of(false);
                }
              } else {
                return of(true);
              }
            })
          )
        )
      );
    }
  }

  checkShipTo(): Observable<boolean> {
    return this.accountSelectorService.getShiptoAddresses().pipe(
      map((shipToAddress) => {
        let shouldRedirect = false;
        if (shipToAddress.shiptoList.length > 1) {
          this.router.navigate(
            this.semanticPathService.transform({
              cxRoute: 'accountSelector',
            })
          );
          shouldRedirect = false;
        } else {
          const [firstItem] = shipToAddress.shiptoList;
          this.accountSelectorService.setLocalStorageShipTo(firstItem.sapCustomerId);
          this.accountSelectorService.setLocalStorageShipToCount(false);
          shouldRedirect = true;
        }
        return shouldRedirect;
      })
    );
  }
}
