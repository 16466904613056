<div class="address-section-division pb-3">
  <div class="address-section-address w-50">
    <app-dropdown-selector-v2
      [options]="zoneDropdownOptions | async"
      (dropDownSelected)="zoneSelected($event)"
      [config]="zoneLabel | async"
    >
    </app-dropdown-selector-v2>
  </div>
  <div class="address-section-address w-50">
    <app-dropdown-selector-v2
      [options]="branches"
      [disableDropDown]="disableBranch"
      (dropDownSelected)="branchSelected($event)"
      [config]="branchesLabel | async"
    >
    </app-dropdown-selector-v2>
  </div>
</div>
<div *ngIf="contactDetails.length" class="address-section-division">
  <div *ngFor="let branch of contactDetails" class="address-section-address w-50">
    <p>
      {{ (branch.isVCS ? 'loginPage.vcTitle' : 'loginPage.sxTitle') | cxTranslate }}
      <br />
      T: {{ branch?.phone }} <br />
      {{ branch?.email }}
    </p>
  </div>
</div>
