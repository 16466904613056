<div class="soProduct">
  <div class="soProduct__header">
    <h3 class="soProduct__header__heading">{{ 'stockOrderPage.configureProducts' | cxTranslate }}</h3>
  </div>
  <div class="soProduct__configurator">
    <app-stockorder-variant-selector
      [product]="product"
      [cartData]="cartData"
      (specsSelectedEmit)="specsSelected($event)"
      (resetLensPowers)="resetLensPowers()"
    ></app-stockorder-variant-selector>
    <app-lens-power-selector
      class="soProduct__configurator__lens-selector d-block"
      *ngIf="product.contactLensType !== lensType.PRODUCT_TORIC"
      [lensPowerSelector]="lensPowerSelector"
      (updatedPower)="selectedLenses($event)"
    >
    </app-lens-power-selector>
    <app-stockorder-toric
      class="soProduct__configurator__toric-lens-selector d-block"
      *ngIf="product.contactLensType === lensType.PRODUCT_TORIC"
      [clearLensValues]="clearLensValues"
      [clearToricQuantity]="clearQuantity"
      [productVarientTree]="variantTree"
      [varients]="variants"
      [cartType]="cartType"
      [cartData]="cartType === cartTypes.UPDATE ? cartData : product"
      (getSelectedToricList)="selectedLenses($event)"
      (clearLensSelected)="lensSelectedValues = []"
      (getQuantity)="getQuantityForVarients($event)"
      (setVarients)="getTheVarients($event)"
      (toricPowers)="validateQuantity($event)"
    >
    </app-stockorder-toric>
  </div>
  <div class="soProduct__footer d-flex flex-row justify-content-between align-items-baseline">
    <div class="soProduct__footer__other">
      <p class="soProduct__footer__other__error-block errorText errorDisplay d-flex" *ngIf="maxQuantityError">
        {{ 'stockOrderPage.errorMessage' | cxTranslate }} {{ maxQuantity }} {{ 'stockOrderPage.each' | cxTranslate }}
      </p>
      <p
        class="soProduct__footer__other__error-block errorText errorDisplay d-flex"
        *ngIf="trailLimitError && trialPack"
      >
        {{ 'stockOrderPage.errorMessage' | cxTranslate }} {{ trailLimit }}
        {{ 'stockOrderPage.forthisbrand' | cxTranslate }}
      </p>
      <p
        class="soProduct__footer__other__error-block errorText errorDisplay d-flex"
        *ngIf="displayErrMsg && !maxQuantityError"
      >
        {{ displayErrMsg }}
      </p>
      <p class="soProduct__footer__other__error-block errorText errorDisplay d-flex" *ngIf="quantityError">
        {{ 'stockOrderPage.inputMessageError' | cxTranslate }}
      </p>
    </div>
    <div class="d-flex align-items-baseline">
      <div class="d-flex justify-content-end align-items-baseline ml-auto">
        <button
          class="soProduct__footer__clear"
          (click)="clearAll()"
          *ngIf="totalQuantitySelected > 0 && cartType !== cartTypes.UPDATE"
        >
          <cx-icon class="close close-icon" type="Close"></cx-icon>
          <label class="soProduct__footer__clear__label">{{ 'stockOrderPage.clearAll' | cxTranslate }}</label>
        </button>
        <button class="soProduct__footer__cancel" *ngIf="cartType === cartTypes.UPDATE" (click)="closeConfigurator()">
          {{ 'stockOrderPage.cancel' | cxTranslate }}
        </button>
      </div>
      <button
        appAddToCart
        [orderType]="lensType.STOCK_ORDER"
        [product]="lensSelectedValues"
        [positionId]="positionId"
        [cartType]="cartType"
        (addingtocartLoad)="addingtocartLoad($event)"
        (addingtocartSuccess)="addingtocartSuccess($event)"
        (emitErrorMessage)="emitErrorMessage($event)"
        (cartError)="cartError()"
        (updateLineItem)="setUpdatedData($event)"
        [trailPack]="trialPack"
        [trailLimit]="trialLimit"
        class="btn btn-primary text-uppercase soProduct__footer__button"
      >
        {{ addUpdateButtonText }}
        <span *ngIf="totalQuantitySelected">({{ totalQuantitySelected }})</span>
        <span class="addedToCartIcon" *ngIf="addingToCartLoading">
          <cx-icon class="load-icon" type="LoadIcon"></cx-icon>
        </span>
        <span class="addedToCartIcon" *ngIf="addingToCartTick">
          <cx-icon class="tick-icon" type="TickIcon"></cx-icon>
        </span>
      </button>
    </div>
  </div>
</div>
