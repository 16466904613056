<app-modal [modalOptions]="modalOptions" [showBigPopup]="true" (closeHandeller)="closeModal()">
  <div class="product-modal" [ngClass]="{ 'border-bottom-0': disable$ | async, 'bg-white': patientInfo$ | async }">
    <div class="product-modal__close">
      <cx-icon type="Close" class="close-icon cx-icon close" (click)="closeModal()"> </cx-icon>
    </div>
    <h3 class="product-modal__header" [ngClass]="{ 'd-none': patientInfo$ | async }">
      {{ 'productListingPage.configureProducts' | cxTranslate }}
    </h3>
    <app-configure-products
      *ngIf="productCode"
      [ngClass]="{ 'd-none': patientInfo$ | async }"
      [productCode]="productCode"
      [isDTP]="isDTP$ | async"
      [clcDryeyeFlag]="clcDryeyeFlag"
      (emitCloseModal)="closeModal()"
      (changeAddressTypeEmitter)="changeAddressType($event)"
    ></app-configure-products>

    <h3 *ngIf="patientInfo$ | async" class="delivery-address-modal__header">
      {{ 'selectProductPage.patientOrderPanel.selectdeliveryAddress' | cxTranslate }}
    </h3>
    <ng-container *ngIf="patientInfo$ | async as patientInfo">
      <app-select-delivery-address
        [patientInfo]="patientInfo"
        (saveAddressInfo)="saveAddressInfo($event)"
        (cancel)="closeAddressModal()"
      >
      </app-select-delivery-address>
    </ng-container>
  </div>
  <div *ngIf="disable$ | async" class="patient-section" #addNewPatient>
    <app-add-new-patient
      [showAdditionalAddress]="false"
      (cancel)="closeAddPatientSection()"
      (patientAddedSuccessfully)="patientAddedSuccessfully($event)"
    >
    </app-add-new-patient>
  </div>
</app-modal>
