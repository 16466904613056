<div class="variantSelector d-flex">
  <div class="variantSelector__image">
    <div class="variantSelector__image__pack">
      <div class="variantSelector__image__product-tile d-flex flex-column align-items-center">
        <div class="d-flex w-100 justify-content-end">
          <app-favorite-product [isFavourite]="product?.favourite" [productCode]="product?.code" iconSize="sm">
          </app-favorite-product>
        </div>
        <div class="variantSelector__image__product-tile__image-holder">
          <cx-media [container]="product?.images" alt="{{ product?.name }}"></cx-media>
        </div>
        <p class="d-md-none d-lg-block variantSelector__image__product-tile__product-name">
          {{ product?.name }}
        </p>
        <div class="d-none variantSelector__image__product-tile__footer">
          <p class="variantSelector__image__product-tile__price">
            {{ product?.price?.formattedValue }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="variantSelector__product">
    <input
      type="text"
      class="d-md-block d-lg-none variantSelector__product-tablet-product-name pl-3"
      [value]="product?.name"
      disabled
    />
    <div class="variantSelector__product__pack d-flex flex-row align-items-center">
      <label class="variantSelector__product__pack--label">{{ 'stockOrderPage.packSize' | cxTranslate }}</label>
      <app-product-pack-size
        *ngIf="packsizeList?.length"
        [packSizeList]="packsizeList"
        (packSizeChanged)="changePacksize($event)"
      >
      </app-product-pack-size>
    </div>
    <div class="variantSelector__product__variant">
      <app-variant-specs-selector
        [variantTree]="variantTree"
        [updateCart]="updateCart"
        [variantsData]="variantSpecs"
        [contactLensType]="contactLensType"
        [selectedPack]="selectedPack"
        (specsSelected)="specsSelected($event)"
      >
      </app-variant-specs-selector>
    </div>
  </div>
</div>
