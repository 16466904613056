import { Component, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ADDITIONAL_ACCOUNT, ERROR_KEYS, GlobalMessage, LOCALSTORAGE_KEY } from 'src/app/shared/constants/constants';
import { LoadB2BUnits } from 'src/app/store/Actions/b2bUnits.action';
import { AccountSelectorService } from '../../services/account-selector.service';

@Component({
  selector: 'app-request-access-additional-account',
  templateUrl: './request-access-additional-account.component.html',
  styleUrls: ['./request-access-additional-account.component.scss'],
})
export class RequestAccessAdditionalAccountComponent implements OnDestroy {
  accountNumber: string;
  private destroy$ = new Subject<void>();
  checkAccountExist = false;
  varifyAccountAccess = false;
  requestSending = false;
  constructor(
    private b2bUnitsService: AccountSelectorService,
    public activeModal: NgbActiveModal,
    private globalMessageService: GlobalMessageService,
    private accountStore: Store
  ) {}
  accountNumberChange(accountNumber): void {
    this.varifyAccountAccess = false;
    this.checkAccountExist = false;
    const numPattren = /^[0-9]+$/;
    if (!numPattren.test(accountNumber)) {
      this.accountNumber = accountNumber.replace(/[^0-9.]*/g, '');
    }
  }
  onSubmit(): void {
    this.requestSending = true;
    this.b2bUnitsService
      .checkMuleSoft(this.accountNumber)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.createNewB2B();
        },
        (error) => {
          this.handlingError(error.error.errors[0].message);
        }
      );
  }

  createNewB2B(): void {
    this.b2bUnitsService
      .createNewB2BCustomer(this.accountNumber)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.setB2BUnits();
          this.setGlobalMessage(ADDITIONAL_ACCOUNT.CREATED_SUCCESSFULLY, GlobalMessageType.MSG_TYPE_CONFIRMATION);
        },
        (error) => {
          this.handlingError(error.error.errors[0].message);
        }
      );
  }

  handlingError(error): void {
    if (error === ERROR_KEYS.CHECK_ACCOUNT_EXIST) {
      this.checkAccountExist = true;
    } else if (error === ERROR_KEYS.VARIFY_ACCOUNT_ACCESS) {
      this.varifyAccountAccess = true;
    } else {
      this.checkAccountExist = false;
      this.varifyAccountAccess = false;
    }
    this.requestSending = false;
  }

  cancelForm(): void {
    this.activeModal.dismiss();
    this.clearSearch();
  }

  clearSearch(): void {
    this.accountNumber = '';
    this.varifyAccountAccess = false;
    this.checkAccountExist = false;
    this.requestSending = false;
  }

  setGlobalMessage(msg: string, type: GlobalMessageType): void {
    this.globalMessageService.add(msg, type, GlobalMessage.TIMEOUT);
  }

  setB2BUnits(): void {
    this.accountStore.dispatch(LoadB2BUnits());
    this.b2bUnitsService.b2bUnitLoaded$.pipe(take(1)).subscribe((b2bUnitsData) => {
      const b2bUnitsResult = { ...b2bUnitsData };
      const noOfAccounts = b2bUnitsResult?.b2bUnits?.units?.length;
      if (noOfAccounts > 1) {
        localStorage.setItem(LOCALSTORAGE_KEY.SELECTED_SOLD_TO_COUNT, 'true');
        this.activeModal.dismiss(true);
      } else {
        this.activeModal.dismiss();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
