import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appRangeCheck]',
})
export class RangeCheckDirective {
  constructor(private elementRef: ElementRef) {}
  @Input() min;
  @Input() max;

  @HostListener('input', ['$event']) onInputChange(event): any {
    const initialValue = this.elementRef.nativeElement.value;
    const numPattren = /^[0-9]+$/;
    if (!numPattren.test(initialValue)) {
      this.elementRef.nativeElement.value = '';
      event.preventDefault();
      return;
    }
    if (Number(initialValue) < this.min || Number(initialValue) > this.max) {
      this.elementRef.nativeElement.value = '';
    }
  }
}
