<app-toggle-button
  [ngbPopover]="popoverTmpl"
  [toggleConfig]="toggleData"
  popoverClass="popover--sm"
  placement="bottom"
  triggers="mouseenter:mouseleave"
  (toggled)="onStatusChange($event)"
>
</app-toggle-button>

<ng-template #popoverTmpl>
  {{ tooltipText }}
</ng-template>
