<div class="line-1">{{ address?.line1 }}</div>
<div *ngIf="address?.line2" class="line-2">{{ address?.line2 }}</div>
<div>
  <span class="appartment" *ngIf="address?.building">{{ address?.building }}</span>
  <span class="building" *ngIf="address?.appartment">, {{ address?.appartment }}</span>
</div>

<div class="city">{{ address?.town }} {{ address?.postalCode }}</div>
<div class="region">
  <span class="region-name" *ngIf="address?.region?.name">{{ address?.region?.name }}, </span>
  <span class="country-name">{{ address?.country?.name }}</span>
</div>
