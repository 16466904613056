import { Component, Input, OnInit } from '@angular/core';
import { OccConfig } from '@spartacus/core';
import { Utilities } from 'src/app/shared/utility/utility';
@Component({
  selector: 'app-minicart-dtp-valuepack',
  templateUrl: './minicart-dtp-valuepack.component.html',
  styleUrls: ['./minicart-dtp-valuepack.component.scss'],
})
export class MinicartDtpValuepackComponent implements OnInit {
  constructor(private occConfig: OccConfig) {}
  @Input() valuePack = [];
  ngOnInit(): void {
    this.valuePack = Utilities.getSortMiniCart(this.valuePack);
  }

  getImageUrl(imageUrl): string {
    return `${this.occConfig.backend?.occ?.baseUrl}/${imageUrl}`;
  }
  getEntryNumberList(): any {
    const entries = [];
    this.valuePack.forEach((item) => {
      entries.push(item?.entryNumber);
    });
    return entries;
  }
}
