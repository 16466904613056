<div class="container-fluid">
  <div class="row justify-content-center d-print-none">
    <div class="col-10 col-xl-10">
      <p class="order-confirmation__confMsg1">
        <cx-icon type="Valid" class="order-confirmation__valid-icon"></cx-icon>{{ orderDesc1.content }}
      </p>
      <p class="order-confirmation__confMsg2 d-none">{{ orderDesc2.content }}</p>
      <cx-page-slot position="OrderConfirmationMessagePosition"></cx-page-slot>
    </div>
  </div>

  <div class="row order-confirmation__details d-print-block">
    <div class="col-lg-7 col-10">
      <cx-page-slot position="OrderConfirmationPaymentMethodSlotPosition"></cx-page-slot>
      <div class="d-print-flex">
        <cx-page-slot position="OrderConfirmationLineItemPosition"></cx-page-slot>
        <cx-page-slot class="d-print-block d-none" position="OrderConfirmationSummarySlotPosition"></cx-page-slot>
      </div>
    </div>
    <div class="col-3 d-print-none d-none d-lg-block">
      <cx-page-slot position="OrderConfirmationSummarySlotPosition"></cx-page-slot>
    </div>
  </div>
</div>
