import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccConfig, OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CurrentUserIdService } from 'src/app/shared-modules/user-id-provider/service/current-user-id.service';

@Injectable({
  providedIn: 'root',
})
export class ContactUsService {
  userId = this.currentUID.getUserId();

  constructor(
    private occ: OccConfig,
    private occEndPoints: OccEndpointsService,
    private httpClient: HttpClient,
    private currentUID: CurrentUserIdService
  ) {}
  getContactUsDetails(): Observable<any> {
    const url = this.occEndPoints.buildUrl(`/users/${this.userId}/contact`);
    return this.httpClient.get<any>(url);
  }
  saveContactUsDetails(contactusInfo): Observable<any> {
    const url = this.occEndPoints.buildUrl(`/users/${this.userId}/submitContactForm`);
    return this.httpClient.post<any>(url, contactusInfo);
  }

  requestAccountCreation(contactusInfo): Observable<any> {
    const url = this.occEndPoints.buildUrl(`/users/${this.userId}/createAccount`);
    return this.httpClient.post<any>(url, contactusInfo);
  }

  requestDemo(contactusInfo): Observable<any> {
    const url = this.occEndPoints.buildUrl(`/users/${this.userId}/createAccount?demoRequest=true`);
    return this.httpClient.post<any>(url, contactusInfo);
  }
}
