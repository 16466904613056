<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="pl-4 col-10 col-xl-10 pb-3">
      <h1 class="mb-0 pb-2">{{ title$ | async }}</h1>
      <cx-page-slot position="CheckoutDescriptionSlotPosition"></cx-page-slot>
    </div>
  </div>
  <div class="d-flex justify-content-center checkout-container">
    <div class="payment_checkout flex-column">
      <cx-page-slot position="CheckoutPaymentMethodSlotPosition"></cx-page-slot>
      <cx-page-slot position="CheckoutOrdersPaneSlotPosition"></cx-page-slot>
    </div>
    <div class="flex-column sticky-checkout">
      <cx-page-slot position="CheckoutSummarySlotPosition"></cx-page-slot>
    </div>
  </div>
</div>
