import { Component, OnInit } from '@angular/core';
import { CmsComponent, CmsService, ContentSlotComponentData, Page } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-no-order-panel',
  templateUrl: './no-order-panel.component.html',
  styleUrls: ['./no-order-panel.component.scss'],
})
export class NoOrderPanelComponent implements OnInit {
  links$: Observable<Observable<CmsComponent>[]>;
  constructor(private cmsService: CmsService) {}

  ngOnInit(): void {
    this.links$ = this.cmsService.getCurrentPage().pipe(
      map((pageData: Page) => {
        return pageData.slots.PositionSlot1M.components.map((value: ContentSlotComponentData) => {
          return this.cmsService.getComponentData(value.uid);
        });
      })
    );
  }
}
