<div class="dtp-lineitem">
  <div
    [ngClass]="{
      'dtp-lineitem__block': !cartEntry?.entries && showLineItem
    }"
  >
    <div class="dtp-lineitem__cartdetails" *ngIf="(!cartEntry?.entries && !externalOrder) || externalOrder">
      <app-alcon-cart-data
        [quantityEdit]="entryLabel === 'DIRECT_TO_PATIENT'"
        [entryLabel]="entryLabel"
        [cartEntry]="cartEntry"
        [status]="status"
        *ngIf="cartEntry.shipped && index === 0"
        [externalOrder]="externalOrder"
        [finalPriceCalculated]="finalPriceCalculated"
      >
      </app-alcon-cart-data>
      <app-alcon-cart-data
        [quantityEdit]="entryLabel === 'DIRECT_TO_PATIENT'"
        [entryLabel]="entryLabel"
        [cartEntry]="cartEntry"
        [status]="status"
        [externalOrder]="externalOrder"
        *ngIf="(cartEntry.unShipped && index === 1) || (!cartEntry.shipped && !cartEntry.unShipped && index === 1)"
        [finalPriceCalculated]="finalPriceCalculated"
      >
      </app-alcon-cart-data>
      <app-alcon-cart-data
        [quantityEdit]="entryLabel === 'DIRECT_TO_PATIENT'"
        [entryLabel]="entryLabel"
        [cartEntry]="cartEntry"
        [status]="status"
        [externalOrder]="externalOrder"
        *ngIf="!cartEntry.unShipped && !cartEntry.shipped && maxIteration === 1"
        [finalPriceCalculated]="finalPriceCalculated"
      >
      </app-alcon-cart-data>

      <div
        class="dtp-lineitem__cartrefCenter"
        *ngIf="!status && pageType !== 'OrderDetails'"
        [ngClass]="{
          'dtp-lineitem__cartrefCenter--top': !cartEntry?.entries
        }"
      >
        <p *ngIf="cartEntry?.shippingAddress" class="dtp-lineitem__shipping-address">
          <b>{{ cartEntry?.patientName }}</b
          ><br />
          {{ cartEntry.shippingAddress?.line1 }} {{ cartEntry.shippingAddress?.line2 }}<br />
          {{ cartEntry.shippingAddress?.town }} {{ cartEntry.shippingAddress?.postalCode }}<br />
          {{ cartEntry.shippingAddress?.region?.name }}, {{ cartEntry.shippingAddress?.country?.name }}
        </p>
      </div>
    </div>
    <div *ngIf="valuePack.length && !externalOrder">
      <div class="order-details-grouping-panel__value-pack__label">
        <span *ngIf="cartEntry.shipped && index === 0">{{
          'miniCartPage.' + cartEntry.orderEntryFlowType | cxTranslate
        }}</span>
        <span
          *ngIf="(cartEntry.unShipped && index === 1) || (!cartEntry.shipped && !cartEntry.unShipped && index === 1)"
          >{{ 'miniCartPage.' + cartEntry.orderEntryFlowType | cxTranslate }}</span
        >
        <span *ngIf="!cartEntry.unShipped && !cartEntry.shipped && maxIteration === 1">{{
          'miniCartPage.' + cartEntry.orderEntryFlowType | cxTranslate
        }}</span>
      </div>

      <div class="order-details-grouping-panel__value-pack">
        <!-- Temp fix R1 -->
        <app-value-pack-items
          [finalPriceCalculated]="finalPriceCalculated"
          [valuePack]="valuePack"
          [status]="status"
          *ngIf="cartEntry.shipped && index === 0"
        ></app-value-pack-items>
        <app-value-pack-items
          [finalPriceCalculated]="finalPriceCalculated"
          [valuePack]="valuePack"
          [status]="status"
          *ngIf="(cartEntry.unShipped && index === 1) || (!cartEntry.shipped && !cartEntry.unShipped && index === 1)"
        ></app-value-pack-items>
        <app-value-pack-items
          [finalPriceCalculated]="finalPriceCalculated"
          [valuePack]="valuePack"
          [status]="status"
          *ngIf="!cartEntry.unShipped && !cartEntry.shipped && maxIteration === 1"
        ></app-value-pack-items>
      </div>
    </div>
  </div>
</div>
