import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-alcon-item-counter',
  templateUrl: './alcon-item-counter.component.html',
  styleUrls: ['./alcon-item-counter.component.scss'],
})
export class AlconItemCounterComponent implements OnInit, OnChanges {
  countervalue = 0;
  @Output() ValueChanged: EventEmitter<number> = new EventEmitter();
  @Input() defaultValue;
  @Input() minimum = 0;
  @Input() qtyFactor = 1;
  @Input() cartEntry;
  @Input() disabled = false;
  @Input() productType;
  initialvalue = 0;
  numPattren = /^[0-9]+$/;
  constructor() {}

  ngOnInit(): void {
    this.setCouterDefaultValue();
  }
  ngOnChanges(): void {
    this.minimum = this.qtyFactor;
    this.setCouterDefaultValue();
  }
  decrement(): void {
    if (this.numPattren.test(this.countervalue?.toString())) {
      this.countervalue =
        this.countervalue - this.qtyFactor <= this.minimum ? this.minimum : this.countervalue - this.qtyFactor;
      this.ValueChanged.emit(this.countervalue);
    } else {
      this.ValueChanged.emit();
    }
    if (this.countervalue === null || this.countervalue < this.minimum) {
      this.setCouterDefaultValue();
    }
  }
  increment(): void {
    if (this.numPattren.test(this.countervalue?.toString())) {
      this.countervalue = Number(this.countervalue) + Number(this.qtyFactor);
      this.ValueChanged.emit(this.countervalue);
    } else {
      this.ValueChanged.emit();
    }
    if (this.countervalue === null || this.countervalue < this.minimum) {
      this.setCouterDefaultValue();
    }
  }

  emitValue(): void {
    if (this.countervalue != null && this.numPattren.test(this.countervalue?.toString())) {
      this.initialvalue = this.countervalue;
      this.countervalue = this.countervalue < this.minimum ? this.minimum : this.countervalue;
      this.ValueChanged.emit(this.countervalue);
    } else {
      this.ValueChanged.emit();
    }
    if (this.countervalue === null || this.countervalue < this.minimum) {
      this.setCouterDefaultValue();
    }
  }

  setCouterDefaultValue(): void {
    this.countervalue = this.defaultValue ? this.defaultValue : this.qtyFactor;
  }

  enterCounterVaue(event): void | boolean {
    if (this.countervalue == null) {
      this.countervalue = this.minimum;
      return;
    }
    const { key } = event;

    if (!isNaN(key * 1)) {
      const val = this.countervalue != null ? (this.countervalue + key) * 1 : key * 1;
      if (val < this.minimum) {
        event.preventDefault();
      }
    }
  }
}
