<app-loader-icon *ngIf="isLoading"></app-loader-icon>
<div class="return-productconfig" [ngClass]="{ 'return-productconfig__accordion': !isAccordionOpen }">
  <div class="d-flex align-items-center return-productconfig__container">
    <img
      class="return-productconfig__image"
      [attr.src]="returnProductData?.images[0]?.url"
      alt="{{ returnProductData?.name }}"
    />
    <p *ngIf="isAccordionOpen" class="return-productconfig__product-name">{{ returnProductData?.name }}</p>
    <div class="return-productconfig__product-info" *ngIf="!isAccordionOpen">
      <p class="return-productconfig__product-name">{{ returnProductData?.name }}</p>
      <p class="return-productconfig__product-info--packsize" *ngIf="selectedReturnVariants?.packSize">
        {{ selectedReturnVariants?.packSize }} pack
      </p>
    </div>
    <div class="return-productconfig__specifications" *ngIf="!isAccordionOpen && showSpecifications">
      <div class="d-flex flex-wrap" *ngIf="contactLensType">
        <p>{{ 'returnForm.specifications.baseCurve' | cxTranslate }} {{ selectedReturnVariants?.basecurve }}</p>
        <p>{{ 'returnForm.specifications.diameter' | cxTranslate }} {{ selectedReturnVariants?.diameter }}</p>
        <p *ngIf="contactLensType === toric">
          {{ 'returnForm.specifications.cylinder' | cxTranslate }} {{ selectedReturnVariants?.cylinder }}
        </p>
        <p *ngIf="contactLensType === toric">
          {{ 'returnForm.specifications.axis' | cxTranslate }} {{ selectedReturnVariants?.axis }}
        </p>
        <p *ngIf="contactLensType === multifocal">
          {{ 'returnForm.specifications.add' | cxTranslate }} {{ selectedReturnVariants?.powerAdd }}
        </p>
      </div>
      <div class="d-flex">
        <p>{{ 'returnForm.specifications.power' | cxTranslate }} {{ selectedReturnVariants?.lenspower }}</p>
        <p *ngIf="contactLensType === color" class="pl-2">
          {{ 'returnForm.specifications.colour' | cxTranslate }} {{ selectedReturnVariants?.color }}
        </p>
        <p class="pl-2">{{ 'returnForm.specifications.quantity' | cxTranslate }} {{ quantity }}</p>
      </div>
      <div>
        <p>{{ 'returnForm.specifications.ordernumber' | cxTranslate }} {{ selectedReturnVariants?.orders }}</p>
      </div>
    </div>
    <p *ngIf="!isAccordionOpen && !showSpecifications" class="return-productconfig__validation">
      {{ 'returnForm.completeFormError' | cxTranslate }}
    </p>
    <cx-icon role="button" type="DropdownExpand" *ngIf="!isAccordionOpen" (click)="toggleAccordion()"></cx-icon>
    <cx-icon role="button" type="DropdownCollapse" *ngIf="isAccordionOpen" (click)="toggleAccordion()"></cx-icon>
  </div>
  <div
    [ngClass]="{
      'd-block': isAccordionOpen,
      'd-none': !isAccordionOpen
    }"
  >
    <div
      [ngClass]="{
        'return-productconfig--disable': isEditPanel,
        'return-productconfig--enable': !isEditPanel
      }"
      class="d-flex"
    >
      <div class="w-50">
        <div class="d-flex flex-wrap flex-grow-1 justify-content-end align-items-end">
          <div class="d-flex flex-grow-1 return-productconfig__item">
            <label class="return-productconfig__label mt-0">{{ 'returnForm.packSize' | cxTranslate }}</label>
            <app-product-pack-size
              [packSizeList]="packsizeList"
              [idPrefix]="panelID + '_'"
              (packSizeChanged)="changePackSize($event)"
            ></app-product-pack-size>
          </div>
        </div>
        <div class="d-flex">
          <app-return-form-variant-selector
            [variantTree]="returnVariantTree"
            [packSize]="selectedPacksize"
            [contactLensType]="contactLensType"
            (ProductCodeSelected)="selectedSKUdetails($event)"
            (selectedReturnVariants)="emitselectedReturnvariants($event)"
          ></app-return-form-variant-selector>
        </div>
        <div *ngIf="returnSKUdetails?.orderDate" class="d-flex flex-grow-1">
          <p class="return-productconfig__orderDate">
            {{ 'returnForm.dateoforder' | cxTranslate }} {{ returnSKUdetails?.orderDate }}
          </p>
        </div>
        <div *ngIf="returnSKUdetails?.quantity" class="d-flex flex-grow-1 mt-4">
          <label class="return-productconfig__label mt-0">{{ 'returnForm.quantity' | cxTranslate }}</label>
          <app-alcon-item-counter [minimum]="1" [defaultValue]="1" (ValueChanged)="setProductReturnQuantity($event)">
          </app-alcon-item-counter>
        </div>
        <div *ngIf="quantityError" class="d-flex flex-grow-1">
          <p class="return-productconfig__error">
            {{ 'returnForm.quantityError' | cxTranslate }}
          </p>
        </div>
      </div>
      <div *ngIf="returnSKUdetails?.orderDate" class="w-50 return-productconfig__sku">
        <h6 class="return-productconfig__reason mt-3 ml-lg-0 ml-4">
          {{ 'returnForm.returnReason' | cxTranslate }}
        </h6>
        <p class="return-productconfig__mandatory return-productconfig__reasonmessage ml-lg-0 ml-4 mt-3">
          {{ 'returnForm.reasonMandatory' | cxTranslate }}
        </p>
        <label class="return-productconfig__label ml-lg-3 ml-4">{{ 'returnForm.selectreason' | cxTranslate }}</label>
        <div class="mt-3 return-productconfig__reason_chooser ml-lg-0 ml-4">
          <app-dropdown-selector-v2 [options]="returnReasons" (dropDownSelected)="reasonSelected($event)">
          </app-dropdown-selector-v2>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <p *ngIf="orderNumberexists" class="return-productconfig__error pl-0 mb-2 w-50">
        {{ 'returnForm.orderNumberError' | cxTranslate }}
      </p>
    </div>
    <div class="d-flex justify-content-between flex-column" [ngClass]="{ 'pt-4': isEditPanel }">
      <div class="d-flex align-items-center justify-content-between">
        <p class="return-productconfig__mandatory mt-4">{{ 'returnForm.mandatory' | cxTranslate }}</p>
        <div *ngIf="isEditPanel" class="d-flex align-items-center">
          <cx-icon type="EditBlue" (click)="editProduct()"
            ><span class="return-productconfig--edit">{{ 'returnForm.edit' | cxTranslate }}</span>
          </cx-icon>
        </div>
      </div>
      <div *ngIf="!isEditPanel" class="d-flex align-items-center mt-lg-0 mt-4">
        <div class="col d-flex justify-content-end return-productconfig--delete" (click)="deleteProduct()">
          {{ 'returnForm.delete' | cxTranslate }}
        </div>
        <button
          class="btn btn-primary btn-save return-productconfig--save"
          (click)="saveProduct()"
          [disabled]="!returnSKUdetails?.orderDate || quantityError || !selectedReason || orderNumberexists"
        >
          {{ 'returnForm.save' | cxTranslate }}
        </button>
      </div>
    </div>
  </div>
</div>
