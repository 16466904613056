import { createAction, props } from '@ngrx/store';
import { VisionCareAddToCart, VisionCareCartItems } from '../States/cartItem.state';

export enum ActionTypes {
  Add = '[Product] Add to cart',
  Remove = '[Product] Remove cartitem',
  retrievedCartList = '[Product/API] Retrieve CartItems Success',
  loadItems = '[Products] Load successfully',
  saveCart = '[Product] Begin to add to cart',
  saveValuePackCart = '[Product] Begin to add to cart for value pack',
  saveScheduledOrder = '[Product] Begin saving scheduled order',
  updateScheduledOrder = '[Product] Begin updating scheduled order',
  updatedScheduledOrder = '[Product] updated scheduled order',
  simulateFinalPrice = '[Product] - Simulate Price for cart items',
  simulatedFinalPrice = '[Product] - Simulated Price for cart items',
  updateCalculatedFlag = '[Cart] - Updating calculated flag',
  removeFromCart = '[Product] Remove from cart',
  handleErrors = '[Product] - Error',
  updatedCart = '[Product] update to cart',
  updateToCart = '[Product] updated to cart',
  clearStore = '[Store] Cleared',
  reloadCart = '[PATIENT]  reload cart after entry address update',
}
export const LoadItems = createAction(ActionTypes.loadItems);
export const RetrievCartList = createAction(
  ActionTypes.retrievedCartList,
  props<{ vCcartItems: VisionCareCartItems }>()
);
export const SaveCart = createAction(ActionTypes.saveCart, props<{ VCAddtocartItems: VisionCareAddToCart }>());
export const SaveValuePackCart = createAction(
  ActionTypes.saveValuePackCart,
  props<{ VCAddtocartItems: VisionCareAddToCart }>()
);

export const AddToCart = createAction(ActionTypes.Add, props<{ vCcartItems: VisionCareCartItems }>());
export const ErrorToDoAction = createAction(ActionTypes.handleErrors, props<Error>());
export const GetFinalPriceSimulation = createAction(ActionTypes.simulateFinalPrice);
export const FinalPriceSimulated = createAction(
  ActionTypes.simulatedFinalPrice,
  props<{ simulatedFinalPriceCartItems }>()
);
export const UpdateCalculatedFlag = createAction(ActionTypes.updateCalculatedFlag, props<{ calculated: boolean }>());
export const RemoveFromCart = createAction(ActionTypes.removeFromCart, props<{ vCcartItems: VisionCareCartItems }>());
export const Remove = createAction(ActionTypes.Remove, props<{ entries: Array<number> }>());
export const UpdateToCart = createAction(ActionTypes.updateToCart, props<{ VCAddtocartItems: VisionCareAddToCart }>());
export const UpdatedCart = createAction(ActionTypes.updatedCart, props<{ vCcartItems: VisionCareCartItems }>());
export const ClearStore = createAction(ActionTypes.clearStore);
export const ReloadCart = createAction(ActionTypes.reloadCart);
