import { Component, OnInit } from '@angular/core';
import { CmsComponent, CmsService } from '@spartacus/core';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
declare module '@spartacus/core' {
  interface CmsComponent {
    content?: string;
  }
}
@Component({
  selector: 'app-legal-statement',
  templateUrl: './legal-statement.component.html',
  styleUrls: ['./legal-statement.component.scss'],
})
export class LegalStatementComponent implements OnInit {
  legalConditions$: Observable<CmsComponent[]>;

  constructor(private cmsService: CmsService) {}

  ngOnInit(): void {
    this.legalConditions$ = this.cmsService.getContentSlot('AddPatientLegalMessagePosition').pipe(
      map(({ components }) => [...components]),
      switchMap((components) => {
        return combineLatest(components.map((componet) => this.cmsService.getComponentData(componet.uid)));
      })
    );
  }
}
