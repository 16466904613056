import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Params } from '@angular/router';
import { Product } from '@spartacus/core/src/model/product.model';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ProductListService } from 'src/app/services/product-list-page/product-list.service';
import { CategoryPage, CategoryProducts } from 'src/app/shared/model/product.model';

@Component({
  selector: 'app-direct-to-practice-product-listing',
  templateUrl: './direct-to-practice-product-listing.component.html',
  styleUrls: ['./direct-to-practice-product-listing.component.scss'],
})
export class DirectToPracticeProductListingComponent implements OnInit, OnDestroy {
  isLandingPage;
  defaultProducts: number;
  stockorderProducts: CategoryPage;
  activatedRoutesService: any;
  products!: CategoryProducts[];
  private destroy$ = new Subject<void>();

  constructor(
    private productListService: ProductListService,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.data.pipe(takeUntil(this.destroy$)).subscribe((data: Data) => {
      this.setPageData({ ...data.stockorderInfo });
    });
    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe((param: Params) => {
      if (!param.query) {
        return;
      }
      this.productListService.searchStockOrderProducts(param.query);
      this.productListService
        .getGroupedStockOrderedProductsList()
        .pipe(take(1))
        .subscribe((pageData) => {
          this.setPageData(pageData);
        });
    });
  }
  setPageData(pageData: CategoryPage): void {
    if (pageData && pageData.products) {
      this.products = [...pageData.products];
    }
    this.cd.detectChanges();
  }
  productExpanded(product): void {
    this.products.forEach((child) => {
      child.children.forEach((products: CategoryProducts) => {
        products.products.forEach((productObj: Product) => (productObj.isOpen = false));
      });
    });
    this.cd.detectChanges();
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
