<div class="minicart--iconPadding" [ngClass]="{ minicart__icon: isCartDisplay }">
  <a (click)="showCart($event)" class="position-relative">
    <cx-icon type="MiniCart" *ngIf="!isCartDisplay"></cx-icon>
    <cx-icon type="closeProfileIcon" *ngIf="isCartDisplay"></cx-icon>
    <span class="header-counter minicart__counter" *ngIf="(totalItems | async) && !isCartDisplay">{{
      totalItems | async
    }}</span>
  </a>
</div>

<div *ngIf="isCartDisplay" class="minicart__modal">
  <div class="minicart__modal__header">
    <label class="minicart__modal__label">{{ 'miniCartPage.title' | cxTranslate }}</label>
  </div>

  <div *ngIf="cartvalue" class="minicart__modal__body" id="cart-id">
    <app-minicart-order-type-list
      *ngIf="regularEntries.length > 0"
      [cartData]="regularEntries.slice(0, 3)"
      [orderType]="orderLabels.INDIVIDUAL_ORDER"
      [orderCount]="regularEntries.length"
    >
    </app-minicart-order-type-list>
    <app-minicart-order-type-list
      *ngIf="valuePackEntries.length > 0"
      [cartData]="valuePackEntries[0]"
      [orderType]="orderLabels.VALUE_PACK"
      [orderCount]="valuePackEntries.length"
    >
    </app-minicart-order-type-list>
    <app-minicart-order-type-list
      *ngIf="stockEntries.length > 0"
      [cartData]="stockEntries.slice(0, 3)"
      [orderType]="orderLabels.STOCK_ORDER"
      [orderCount]="stockEntries.length"
    >
    </app-minicart-order-type-list>
    <app-minicart-order-type-list
      *ngIf="dtpEntries.length > 0"
      [cartData]="dtpEntries"
      [orderType]="orderLabels.DIRECT_TO_PATIENT"
      [orderCount]="dtpLineItemCount"
    >
    </app-minicart-order-type-list>
  </div>

  <div *ngIf="cartvalue" class="minicart__modal__footer">
    <div class="d-flex justify-content-between" *ngIf="showPrices">
      <span>{{ 'miniCartPage.total' | cxTranslate }}:</span>
      <span *ngIf="isPriceVisible && (showTotalPrice$ | async); else hyphen">{{ totalMiniCartPrice$ | async }}</span>
      <ng-template #hyphen>-</ng-template>
    </div>
    <div class="d-flex justify-content-center">
      <a
        href="javascript:void(0)"
        routerLink="/cart"
        (click)="isCartDisplay = false"
        class="btn btn-primary text-uppercase minicart__modal__footer__button"
        >{{ 'miniCartPage.gotocart' | cxTranslate }}
      </a>
    </div>
  </div>
  <!-- Empty Cart template -->
  <div *ngIf="!cartvalue" class="minicart__modal__emptyCart">
    <label class="minicart__modal__emptyCart__label">{{ 'miniCartPage.yourcartIsEmpty' | cxTranslate }}. </label>
    <p class="minicart__modal__emptyCart__message">{{ 'miniCartPage.cartMessage' | cxTranslate }}</p>
    <app-mini-no-order-panel></app-mini-no-order-panel>
  </div>
</div>
