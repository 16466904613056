import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '@spartacus/core';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { AlconUserPermissionService } from '../shared-modules/alcon-user-permission-provider/service/alcon-user-permission.service';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionGuard implements CanActivate {
  constructor(
    protected authService: AuthService,
    private userProfileService: UserProfileFacade,
    private alconUserPermission: AlconUserPermissionService
  ) {}

  canActivate(): Observable<boolean> {
    return this.authService.isUserLoggedIn().pipe(
      switchMap((isLoggedIn) => {
        if (isLoggedIn) {
          return this.userProfileService.get().pipe(
            filter((userDetails) => !!userDetails),
            tap((userDetails) => {
              if (userDetails) {
                this.alconUserPermission.setPermissions(userDetails);
              }
            }),
            map((userDetails) => true)
          );
        } else {
          return of(true);
        }
      })
    );
  }
}
