import { createSelector } from '@ngrx/store';
import { FetchOrderFlagState, PatientsState } from '../States/patient.state';

export const getPatientData = createSelector(
  (state: PatientsState) => state.patients,
  (items) => items
);

export const getPatientInfo = (patientID: string) =>
  createSelector(
    (state: PatientsState) => state.patients,
    (patients) => {
      return patients.find((patient) => patient.patientId === patientID || patient.uid === patientID);
    }
  );

export const getPatientAddress = (patientID: string, defaultAddress: boolean) =>
  createSelector(
    (state: PatientsState) => state.patients,
    (patients) => {
      return patients
        .find((patient) => patient.patientId === patientID)
        ?.addresses?.find((address) => address?.defaultAddress === defaultAddress);
    }
  );

export const getPatientCount = createSelector(
  (state: PatientsState) => state.patients,
  (items) => items.length
);

export const getOrderFlag = createSelector(
  (state: FetchOrderFlagState) => state.fetchPatientOrders,
  (fetchPatientOrders) => fetchPatientOrders
);
