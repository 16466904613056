import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SemanticPathService } from '@spartacus/core';
import { AlconUserPermissionService } from '../../alcon-user-permission-provider/service/alcon-user-permission.service';
@Injectable({
  providedIn: 'root',
})
export class InvoiceListingPageGuard implements CanActivate {
  constructor(
    private userPermissionService: AlconUserPermissionService,
    private semanticPathService: SemanticPathService,
    private router: Router
  ) {}
  canActivate(): boolean {
    if (!this.userPermissionService.getInvoicesPermission()) {
      this.router.navigate(
        this.semanticPathService.transform({
          cxRoute: 'notFound',
        })
      );
      return false;
    }
    return true;
  }
}
