<form [formGroup]="returnConfirmationForm" novalidate>
  <div class="returnForm-confirmation justify-content-between">
    <div class="d-flex justify-content-between flex-column">
      <section class="d-block d-lg-flex">
        <div class="returnForm-confirmation__container">
          <h4 class="returnForm-confirmation__header">{{ 'returnForm.confirmation.header' | cxTranslate }}</h4>
          <p class="returnForm-confirmation__info-message">
            {{ 'returnForm.confirmation.returnInfoMessage' | cxTranslate }}
            <span class="returnForm-confirmation__email">
              {{ returnEmail }}
            </span>
          </p>
          <input
            type="checkbox"
            value="email"
            id="send-additional"
            class="alconInput__checkbox"
            (click)="selectAdditionalEmail()"
          />
          <label for="send-additional" class="returnForm-confirmation__send-copy">{{
            'returnForm.confirmation.sendcopy' | cxTranslate
          }}</label>
          <div *ngIf="isAdditionalEmailSelected" class="d-flex flex-column">
            <label class="label returnForm-confirmation__alternate-email">{{
              'returnForm.confirmation.alternativeEmail' | cxTranslate
            }}</label>
            <div class="d-flex">
              <input
                type="text"
                [ngClass]="{
                  'Alcon-form__invalid-input-field': email?.invalid && (email?.dirty || email?.touched),
                  'Alcon-form__valid-input-field': email?.valid && email?.dirty && email?.touched
                }"
                class="form-control returnForm-confirmation__input-styles"
                formControlName="email"
              />
              <button
                *ngIf="isAdditionalEmailSelected"
                type="submit"
                [disabled]="returnConfirmationForm?.invalid || !email?.value"
                class="btn btn-primary text-uppercase returnForm-confirmation__btn-send"
                (click)="sendReturnConfirmationEmail()"
              >
                {{ 'returnForm.confirmation.send' | cxTranslate }}
                <span class="returnForm-confirmation__tickIcon" *ngIf="isEmailSent">
                  <cx-icon class="tick-icon" type="TickIcon"></cx-icon>
                </span>
              </button>
            </div>
            <div *ngIf="email?.invalid && (email?.dirty || email?.touched)" class="returnForm-confirmation__error">
              <p class="mb-0" *ngIf="email.errors?.email || email.errors?.pattern">
                {{ 'returnForm.confirmation.emailError' | cxTranslate }}
              </p>
            </div>
          </div>
        </div>
        <div class="returnForm-confirmation__image d-lg-block d-none">
          <cx-media [container]="(returnFormImgContent | async).media" [format]="'mobile'"></cx-media>
        </div>
      </section>
      <div class="d-flex flex-row-reverse returnForm-confirmation__bottom_area">
        <div class="returnForm-confirmation__image d-block d-lg-none align-self-center">
          <cx-media [container]="(returnFormImgContent | async).media" [format]="'mobile'"></cx-media>
        </div>
        <div class="returnForm-confirmation__box">
          <h4 class="returnForm-confirmation__remember">
            {{ 'returnForm.confirmation.remember' | cxTranslate }}
          </h4>
          <div class="d-flex justify-content-between flex-column flex-lg-row">
            <ng-container *ngFor="let component of (componentList | async)?.components">
              <div class="returnForm-confirmation__step">
                <ng-container [cxComponentWrapper]="component"> </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
