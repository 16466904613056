import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { BaseSiteService } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from './../environments/environment';
import { TealiumService } from './services/tealium/tealium.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Alcon UK Storefront';
  clearSession = { status: false };
  loading$: Observable<boolean> = of(false);
  baseSite = this.baseSiteService.getActive().pipe(map((site) => site !== 'no_site'));

  constructor(private tealium: TealiumService, private router: Router, private baseSiteService: BaseSiteService) {
    this.tealium.setConfig({
      account: environment.TEALIUM_ACCOUNT,
      profile: environment.TEALIUM_PROFILE,
      environment: environment.TEALIUM_ENV,
    });
  }

  @HostListener('click', ['$event'])
  @HostListener('mouseover', ['$event'])
  @HostListener('tap', ['$event'])
  @HostListener('mouseup', ['$event'])
  @HostListener('mousemove', ['$event'])
  refreshUserState(): void {
    this.clearSession = { ...{ status: true } };
  }

  ngOnInit(): void {
    this.tealium.view({ event_name: 'init' });
    this.loading$ = this.router.events.pipe(
      filter(
        (e) =>
          e instanceof NavigationStart ||
          e instanceof NavigationEnd ||
          e instanceof NavigationCancel ||
          e instanceof NavigationError
      ),
      map((e) => e instanceof NavigationStart)
    );
  }
}
