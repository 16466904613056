import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import {
  GlobalMessageService,
  GlobalMessageType,
  HttpErrorHandler,
  HttpResponseStatus,
  Priority,
} from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class InvalidSiteErrorHandler extends HttpErrorHandler {
  responseStatus = HttpResponseStatus.BAD_REQUEST;

  constructor(
    private router: Router,
    globalMessageService: GlobalMessageService,
    @Inject(PLATFORM_ID) platformId?: object
  ) {
    super(globalMessageService, platformId);
  }

  getPriority?(): Priority {
    return Priority.LOW;
  }

  handleError(request: HttpRequest<any>, response: HttpErrorResponse): void {
    if (response.error?.errors[0].type === 'AlconSiteForbiddenError') {
      this.router.navigate(['/logout-alcon'], {
        queryParams: { deafultRedirect: false },
      });
    }

    this.globalMessageService.add({ key: 'httpHandlers.forbidden' }, GlobalMessageType.MSG_TYPE_ERROR);
  }
}
