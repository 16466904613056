import { createSelector } from '@ngrx/store';
import { LineItemCountComponent } from 'src/app/shared/classes/lineitem.count';
import { CART_GROUP } from 'src/app/shared/constants/constants';
import { AppState, VisionCareCartItems, VisionCareEntries, VisionCareGroupEntries } from '../States/cartItem.state';

export const getCartItems = createSelector(
  (state: AppState) => state.visionCareCart,
  (items) => items
);
export const getCartRootGroups = createSelector(
  (state: AppState) => state.visionCareCart,
  (visionCareCart: VisionCareCartItems) => visionCareCart.rootGroups
);
export const getCartDeliverAddress = createSelector(
  (state: AppState) => state.visionCareCart,
  (vcCart: VisionCareCartItems) => vcCart.deliveryAddress
);
export const getCalculatedFlag = createSelector(
  (state: AppState) => state.visionCareCart,
  (vcCart: VisionCareCartItems) => vcCart.calculated
);
export const isPONumberRequired = createSelector(
  (state: AppState) => state.visionCareCart,
  (vcCart: VisionCareCartItems) => vcCart.deliveryAddress.isPONumberRequired
);
export const getCartCode = createSelector(
  (state: AppState) => state.visionCareCart,
  (vcCart: VisionCareCartItems) => vcCart.code
);
export const getTotalUnitCount = createSelector(
  (state: AppState) => state.visionCareCart,
  (vcCart: VisionCareCartItems) => vcCart.totalUnitCount
);
export const getPaymentTypes = createSelector(
  (state: AppState) => state.visionCareCart,
  (vcCart: VisionCareCartItems) => vcCart.paymentType
);

export const getPatientDTPOrders = (patientID: string) =>
  createSelector(
    (state: AppState) => state.visionCareCart.rootGroups,
    (rootGroups: VisionCareEntries[]) => {
      return LineItemCountComponent.DTPPatientRecords(rootGroups, patientID);
    }
  );

export const getIndividualOrdersummary = createSelector(
  (state: AppState) => state.visionCareCart.rootGroups,
  (rootGroups: VisionCareEntries[]) => {
    return LineItemCountComponent.PracticeOrderCount(rootGroups, CART_GROUP.INDIVIDUAL_ORDER);
  }
);

export const getStockOrdersummary = createSelector(
  (state: AppState) => state.visionCareCart.rootGroups,
  (rootGroups: VisionCareEntries[]) => {
    return LineItemCountComponent.PracticeOrderCount(rootGroups, CART_GROUP.STOCK_ORDER);
  }
);

export const getValuePackOrdersummary = createSelector(
  (state: AppState) => state.visionCareCart.rootGroups,
  (rootGroups: VisionCareEntries[]) => {
    const cartrootGroups = JSON.parse(JSON.stringify(rootGroups));
    return LineItemCountComponent.valuePackCount(cartrootGroups);
  }
);

export const getDTPOrdersummary = createSelector(
  (state: AppState) => state.visionCareCart.rootGroups,
  (rootGroups: VisionCareEntries[]) => {
    return LineItemCountComponent.DTPCount(rootGroups);
  }
);

export const getTotalPriceVaules = createSelector(
  (state: AppState) => state.visionCareCart,
  (vcCart: VisionCareCartItems) => {
    const { calculated, deliveryCost, totalTax, totalPrice, deliveryAddress, subTotal } = vcCart;
    return { calculated, deliveryCost, totalTax, totalPrice, deliveryAddress, subTotal };
  }
);
export const getPracticeItemExist = createSelector(
  (state: AppState) => state.visionCareCart.rootGroups,
  (rootGroups: VisionCareEntries[]) => {
    return LineItemCountComponent.PracticeItemExist(rootGroups);
  }
);

export const getDTPEntryForEntryNumber = (entryNumber: number) =>
  createSelector(
    (state: AppState) => state.visionCareCart.rootGroups,
    (rootGroups: VisionCareEntries[]) => {
      const dtpEntries = rootGroups.find((items) => items.label === CART_GROUP.DIRECT_TO_PATIENT)?.entries;
      return dtpEntries?.filter((items) => items.entryNumber === entryNumber);
    }
  );

export const getDTPDefaultAddress = (entryNumber: number) =>
  createSelector(getDTPEntryForEntryNumber(entryNumber), (entry: VisionCareGroupEntries[]) => {
    if (entry.length) {
      return entry[0].shippingAddress.defaultAddress;
    }
    return false;
  });

export const getPatientIDForEntry = (entryNumber: number) =>
  createSelector(getDTPEntryForEntryNumber(entryNumber), (entry: VisionCareGroupEntries[]) => {
    if (entry.length) {
      return entry[0].patientId;
    }
    return '';
  });
