<div class="direct-to-patient-wrapper__heading" (click)="open()">
  <div class="d-flex align-items: center; justify-content-between direct-to-patient-wrapper__heading-body">
    <div>
      <div class="direct-to-patient-wrapper__lableEachEntry">
        {{ 'cartPage.' + entry.label | cxTranslate }}
        <span *ngIf="isCheckoutPage" class="direct-to-patient-wrapper__delivery-method">{{
          'cartPage.deliveryMethod' | cxTranslate
        }}</span>
        <span *ngIf="isCheckoutPage" class="direct-to-patient-wrapper__delivery-method-details">{{
          'cartPage.deliveryMethodDetails' | cxTranslate
        }}</span>
      </div>
    </div>
    <div class="d-flex align-items-center direct-to-patient-wrapper__totalText">
      <label
        [ngClass]="isCheckoutPage ? 'direct-to-patient-wrapper__checkout-items' : 'direct-to-patient-wrapper__items'"
        >{{ quantity }} items</label
      >
      <label class="direct-to-patient-wrapper__total"
        >{{ 'miniCartPage.total' | cxTranslate }}:
        <span
          [ngClass]="
            finalPriceCalculated
              ? 'direct-to-patient-wrapper__confirmationprice'
              : 'direct-to-patient-wrapper__formatprice'
          "
          >{{ finalPriceCalculated ? entry.totalPrice?.formattedValue : '-' }}</span
        >
      </label>
      <div class="direct-to-patient-wrapper__expand-content-down mr-3" *ngIf="!isAccordionChange"></div>
      <div class="direct-to-patient-wrapper__expand-content-up mr-3" *ngIf="isAccordionChange"></div>
    </div>
  </div>
</div>
<div *ngIf="isAccordionChange">
  <app-direct-to-patient-lineitem
    [quantityEdit]="entry.label === 'DIRECT_TO_PATIENT'"
    [entryLabel]="entry.label"
    [cartEntry]="cartEntry"
    [finalPriceCalculated]="finalPriceCalculated"
    [isCheckoutPage]="isCheckoutPage"
    *ngFor="let cartEntry of entry.entries"
  >
  </app-direct-to-patient-lineitem>
  <div class="direct-to-patient-wrapper__value-pack" *ngFor="let valuePack of sortedValuePacks">
    <div *ngIf="entry?.children" class="direct-to-patient-wrapper__value-pack__label">
      {{ 'miniCartPage.' + entry?.children[0].label | cxTranslate }}
    </div>
    <app-value-pack-items
      [valuePack]="valuePack"
      [finalPriceCalculated]="finalPriceCalculated"
      [isCheckoutPage]="isCheckoutPage"
    >
    </app-value-pack-items>
  </div>
</div>
