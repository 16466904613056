<div class="login-container">
  <form (ngSubmit)="submitForm()" [formGroup]="loginForm">
    <div class="form-group">
      <label for="" class="email-label">{{ 'login.emailAddress' | cxTranslate }}</label>
      <input type="email" class="form-control email-input-text" formControlName="loginEmail" />
      <div class="error-container">
        <p
          class="error-container__error"
          *ngIf="
            (loginForm.controls.loginEmail.touched && loginForm.controls.loginEmail.errors?.required) ||
            (loginForm.controls.loginEmail.touched && loginForm.controls.loginEmail.errors?.pattern) ||
            (loginForm.controls.loginEmail.touched && loginForm.controls.loginEmail.errors?.email)
          "
        >
          {{ 'login.requireEmail' | cxTranslate }}
        </p>
      </div>
    </div>
    <div class="form-group">
      <label for="" class="email-label">{{ 'login.passwordLbl' | cxTranslate }}</label>
      <input type="password" class="form-control email-input-text" formControlName="loginPassword" />
      <div class="error-container">
        <p
          class="error-container__error"
          *ngIf="loginForm.controls.loginPassword.touched && loginForm.controls.loginPassword.errors?.required"
        >
          {{ 'login.requirePassword' | cxTranslate }}
        </p>
      </div>
    </div>
    <div class="forgot-pwd-container justify-content-between">
      <span>
        <a class="forgot-password-link d-none" (click)="navigateToForgotPwd()">{{
          'login.forgotLink' | cxTranslate
        }}</a>
      </span>
      <div class="button-wrapper">
        <button type="submit" class="btn text-uppercase font-weight-bold login-btn">
          {{ 'login.logIn' | cxTranslate }}
        </button>
      </div>
    </div>
  </form>
</div>
