import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CART_GROUP } from 'src/app/shared/constants/constants';
import { PageType } from 'src/app/shared/model/common.mode';
@Component({
  selector: 'app-cart-list-items',
  templateUrl: './cart-list-items.component.html',
  styleUrls: ['./cart-list-items.component.scss'],
})
export class CartListItemsComponent implements OnInit {
  @Input() entries = [];
  @Input() enableCounter = true;
  @Input() orderType;
  showAddress = false;
  showActions = true;
  @Input() showPatientReference = true;
  pageType: string;
  get cartGroup(): typeof CART_GROUP {
    return CART_GROUP;
  }
  constructor(private route: ActivatedRoute) {}
  ngOnInit(): void {
    this.pageType = this.route.snapshot.data.Type;
    this.showAddress = this.pageType === PageType.ORDER_CONFIRMATION;
    this.showActions = !this.showAddress;
  }
}
