import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductDetailsService } from 'src/app/services/alcon-pdp/product-details.service';
import { ProductListService } from 'src/app/services/product-list-page/product-list.service';
import { addressType } from 'src/app/shared/constants/constants';
import { ModalOptions } from 'src/app/shared/model/common.mode';
import { LoadItems } from 'src/app/store/Actions/cartItem.action';
@Component({
  selector: 'app-product-modal-popup',
  templateUrl: './product-modal-popup.component.html',
  styleUrls: ['./product-modal-popup.component.scss'],
})
export class ProductModalPopupComponent implements OnInit, OnDestroy {
  @ViewChild('addNewPatient', { static: false }) set content(content: ElementRef) {
    if (content) {
      content.nativeElement.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }

  destroySubscription$ = new Subject<void>();
  @Input() productCode;
  @Input() clcDryeyeFlag;
  @Output() emitCloseModal: EventEmitter<any> = new EventEmitter();
  public isDTP$: Observable<boolean> = this.productDetailsService.receiveDTPFlag();
  public modalOptions = {
    open: true,
    header: false,
    modalTitle: '',
    borderRadius: '20px',
    width: '1080px',
  } as ModalOptions;
  addressType = addressType.MAIN_ADDRESS;
  patientInfo$ = this.productListService.setPatientInfo.pipe(
    map((address) => {
      if (!address) {
        return undefined;
      }
      return {
        ...address,
        addressType: this.defaultAddress ? addressType.MAIN_ADDRESS : addressType.ADDITIONAL_ADDRESS,
      };
    })
  );
  disable$ = this.productListService.disablePanel;

  defaultAddress = true;
  constructor(
    private store: Store,
    private productListService: ProductListService,
    public productDetailsService: ProductDetailsService
  ) {
    this.productListService.clearPatientData();
  }

  ngOnInit(): void {
    this.productDetailsService.emitDTPFlag(false);
    document.body.classList.add('modal-open');
    this.productListService.productServiceFalse(false);
    this.patientInfo$ = this.productListService.setPatientInfo.pipe(
      map((address) => {
        if (!address) {
          return undefined;
        }
        return {
          ...address,
          addressType: this.defaultAddress ? addressType.MAIN_ADDRESS : addressType.ADDITIONAL_ADDRESS,
        };
      })
    );
  }

  saveAddressInfo(address): void {
    this.productListService.newPatientIdSubject.next(null);
    this.defaultAddress = address.defaultAddress;
    this.productListService.changedAddressTypeSubject.next(this.defaultAddress);
    this.productListService.setPatientAddress(address);
    this.productListService.clearPatientData();
    this.store.dispatch(LoadItems());
  }

  closeAddressModal(): void {
    this.productListService.clearPatientData();
  }

  closeModal(): void {
    this.modalOptions.open = false;
    this.productListService.productServiceFalse(true);
    document.body.classList.remove('modal-open');
    this.productCode = null;
    this.emitCloseModal.emit(this.modalOptions.open);
  }

  closeAddPatientSection(): void {
    this.productListService.enablePanel();
  }

  patientAddedSuccessfully(patientID: string): void {
    this.defaultAddress = true;
    this.productListService.enablePanel(patientID);
    this.productListService.changedAddressTypeSubject.next(true);
  }

  changeAddressType(patientAddressType: boolean): void {
    this.defaultAddress = patientAddressType;
  }

  ngOnDestroy(): void {
    this.destroySubscription$.next();
    this.destroySubscription$.complete();
  }
}
