export const enAU = {
  contactlenses: {
    contactlenses: 'Contact Lens',
  },
  dailycontactlenses: {
    dailycontactlenses: 'Daily contact lenses',
  },
  fortnightlycontactlenses: {
    fortnightlycontactlenses: 'Fortnightly contact lenses',
  },
  monthlycontactlenses: {
    monthlycontactlenses: 'Monthly contact lenses',
  },
  dryeyeproducts: {
    dryeyeproducts: 'Dry eye products',
  },
  contactlenssolutions: {
    contactlenssolutions: 'Contact lens solutions',
  },
  contactlenssolution: {
    contactlenssolution: 'Contact lens solutions',
  },
  multifocal: {
    multifocal: 'Multifocal',
  },
  colour: {
    colour: 'Color',
  },
  color: {
    color: 'Color',
  },
  spherical: {
    spherical: 'Spherical',
  },
  toric: {
    toric: 'Toric',
  },
  packsize: {
    packsize: 'Pack size',
  },
  diameter: {
    diameter: 'Diameter',
  },
  lenscylinder: {
    lenscylinder: 'Lens Cylinder',
  },
  lensaxis: {
    lensaxis: 'Lens axis',
  },

  common: {
    common: {
      home: 'Home',
    },
  },
  cms: {
    login: {
      backToLogin: 'Back to login',
      backToLoginPage: 'Back to login page',
      emailAddress: 'E-mail address',
      emailPlaceholer: 'Your e-mail adress',
      emailSentConfirmText: 'An e-mail has been sent with the instructions to reset your password.',
      forgotLink: 'Forgot password?',
      forgotPwdText: 'Enter your e-mail address and we will send you a link to change your password:',
      logIn: 'log in',
      passwordLbl: 'Password',
      requireEmail: 'Please enter a valid e-mail',
      requirePassword: 'Please enter your password',
      sendEmail: 'Send',
      forgotYourPwd: 'Forgot your password?',
    },
    multiAccountSelector: {
      message: 'Choose an account to continue. Products availability and shopping experience may vary.',
      title: 'Select an Alcon account',
      loaderMessage: ' Loading Accounts ...',
      welcomeMessage: 'Welcome ',
      verifyAccessError: `<b>We couldn’t verify access to this account.</b><br>Please check the account number and try again.<br>Or contact customer service with any questions at +48 591 234 567.`,
      requestAdditionalAccount: 'Request access to an additional Alcon account',
      requestLabel: 'Enter Alcon account number',
      requestSend: 'Send Request',
      requestSending: 'Sending request',
      alreadyExistAccount: 'You already have access to this account.',
      loading: 'Loading...',
      shipToSelector: {
        title: 'Your Alcon account:',
        searchboxHeading: 'Select a shipping address',
        searchPlaceholderTxt: 'Enter shipping address',
        noResultFound: 'No results found',
      },
      soldToselector: {
        requestAdditionalAccount: 'Request access to an additional account',
        canNotFoundAccount: 'Can’t find your account?',
      },
    },
    productListingPage: {
      moreFilter: 'More filters',
      lessFilter: 'Less filters',
      product: 'product',
      seeAll: 'See All',
      select: 'Select',
      showMore: 'Show more',
      showLess: 'Show less',
      sortBy: 'Sort by',
      results: 'Results',
      products: 'products',
      nameAZ: 'Name AZ',
      price: 'Price',
      oxygenPermeability: 'Oxygen permeability',
      brand: 'Brand',
      categories: 'Categories',
      showAll: 'Show all',
      configureProducts: 'Configure products',
      valuepack: 'Value packs',
    },
    productPage: {
      productDescription: 'Product description',
      productSpecification: 'Product specifications',
      features: 'Features',
      benefits: 'Benefits',
      downloads: 'Downloads :',
    },
    stockOrderProductListPage: {
      from: 'from',
    },
    productDetailsPage: {
      privateLabel: 'Private label',
      patientReference: 'Patient reference:',
      parametersFor: 'Parameters for:',
      left: 'Left',
      right: 'Right',
      baseCurve: 'Base curve:*',
      power: 'Power:*',
      add: 'Add*:',
      colour: 'Colour*:',
      cylinder: 'Cylinder*:',
      axis: 'Axis*:',
      sameForBothEye: 'Same for both eye',
      packSize: 'Pack size:',
      practiceOrder: 'Practice order',
      individualOrders: 'Individual orders',
      dtpError: 'This product can only be delivered to practice.',
      dtpSummaryError: 'This order can only be delivered to practice.',
      addressError: 'Please provide correct address',
      directToPatient: 'Direct to patient',
      errorMessage: 'You cannot enter more than 18 characters.',
      quantityErrorMsg: 'Max. purchasable quantity for this item is',
      PurchaseStockOrder: 'Purchase in stock order',
      perEye: 'per eye',
    },
    practiceOrderPage: {
      patientReference: 'Patient reference:',
      parametersFor: 'Parameters for:',
      left: 'Left eye',
      right: 'Right eye',
      baseCurve: 'Base curve:',
      power: 'Power:*',
      add: 'Add:*',
      colour: 'Colour:*',
      cylinder: 'Cylinder:*',
      axis: 'Axis:*',
      sameForBothEye: 'Same for both eyes',
      packSize: 'Pack size:',
      practiceOrder: 'Practice order',
      individualOrders: 'Individual orders',
      diameter: 'Diameter:',
      typeOfWear: 'Type of wear:',
      material: 'Material:',
      waterContent: 'Water content:',
      schedule: 'Schedule recurring order',
      prefer: 'Prefer a value pack?',
      errorMessage: 'Too many items selected. Please lower quantity to max.',
      LEFT: 'Left eye',
      RIGHT: 'Right eye',
      update: 'UPDATE',
      cancel: 'Cancel',
      dtpLimitMessage: 'Max. quantity for Direct to Patient delivery is',
    },
    favoriteListPage: {
      yourFavProduct: 'Your favourite products',
      products: 'products',
      product: 'product',
      noFavProduct:
        'You currently do not have any favourite products listed. To add products to the favourites list click on the heart icon next to the product',
    },
    legalAnnouncement: {
      accept: 'ACCEPT',
      decline: 'DECLINE',
    },
    practicePage: {
      mostFrequentOrders: 'Most frequent orders',
    },
    stockOrderPage: {
      configureProducts: 'Configure product',
      packSize: 'Pack size:*',
      baseCurve: 'Base curve:',
      diameter: 'Diameter:',
      color: 'Color:',
      add: 'Add:',
      cancel: 'Cancel',
      lenscylinder: 'Lens Cylinder:*',
      moreMinus: 'More minus (-) powers',
      lessMinus: 'Less minus (-) powers',
      morePlus: 'More plus (+) powers',
      lessPlus: 'Less plus (+) powers',
      clearAll: 'Clear all',
      addToCart: 'ADD TO CART',
      addToCartLoading: 'ADDING TO CART',
      addToCartSuccess: 'ADDED TO CART',
      updateToCartText: 'UPDATE',
      updatingCart: 'Updating Cart',
      updateToCartSuccess: 'Updated Cart',
      maxQty: 'Max. total quantity for this product is',
      errorMessage: 'Too many items selected. Please lower quantity to max.',
      inputMessageError: 'Quantity must be number greater than 0',
      each: 'each',
      forthisbrand: 'for this brand',
    },
    miniCartPage: {
      PRACTICE_ORDER: 'Practice orders',
      INDIVIDUAL_ORDER: 'Individual orders',
      VALUE_PACK: 'Value pack',
      DTP_VALUE_PACK: 'Value pack',
      DIRECT_TO_PATIENT: 'Direct to patient',
      STOCK_ORDER: 'Stock order',
      title: 'Your cart',
      RIGHT: 'Right',
      LEFT: 'Left',
      total: 'Total',
      delete: 'Delete',
      gotocart: 'Go to Cart',
      moreitem: 'more item',
      totalprice: '£00.00',
      yourcartIsEmpty: 'Your cart is empty',
      goTo: 'Go to',
      productCatalogue: 'Product Catalogue',
      orClick: 'or click',
      orClickthe: 'or click the',
      newOrder: 'New order',
      editOrder: 'Edit order',
      toAddItems: 'to add items',
      edit: 'Edit',
      patientReference: 'Patient reference',
      quantity: 'Qty.',
      YouHaveNoProductsInYourCart: 'You have no products in your cart',
      seeProduct: 'See products',
      hideProduct: 'Hide products',
      deliveryMethod: 'Delivery method:',
      poNumber: 'PO number:',
      orderNumber: 'Order number:',
      LP: 'PWR',
      AX: 'AX',
      CY: 'CYL',
      Colour: 'Colour',
      DIA: 'DIA',
      BC: 'BC',
      ADD: 'ADD',
      PatientReference: 'Patient Reference',
      PowerShipped: 'powers shipped',
      PowersInProgress: 'powers in progress',
      cartMessage1: 'You do not have any products added to your cart.',
      cartMessage2: 'Use any of the following methods to add products to your order. ',
      cartMessage:
        'You do not have any products added to your cart. Use any of the following methods to add products to your order. ',
      valuePack: 'Value pack:',
      valuePacks: 'Value packs',
      valuePackDuration: 'Value pack (',
      valuePackMonth: '-months)',
      moreitems: 'more items',
    },
    cartPagev2: {
      INDIVIDUAL_ORDER: 'Individual orders',
      VALUE_PACK: 'Value packs',
      STOCK_ORDER: 'Stock orders',
      DIRECT_TO_PATIENT: 'Direct to patients',
      orderAndPay: 'ORDER AND PAY',
      simulation: {
        errorMessage:
          'The final price can not be calculated at this time. Once this issue has been resolved you will see the prices in the order history and invoices.',
      },
      directToPatientWarning: 'Final price of each Direct to patient order will be available in your invoice',
      warningOnCheckout:
        '*Please note that the prices on MyAlcon Store are indicative only and are subject to change without notice,  and could change during the ordering process. The exact price for the product will be shown on the invoice.',
      practiceWarning: 'Final price of Practice order will be available in your invoice',
      disclaimer:
        'By clicking ORDER AND PAY  you acknowledge and agree to 1) the MyAlcon terms and conditions which you signed up to when you registered your details to use this system and 2) confirm that where it is a direct-to-patient order a) the patient is 16 years or over and b) you have informed the patient that their information will be shared via MyAlcon and have obtained any necessary patient consents.',
      deliveryCost: 'Delivery costs',
      taxes: 'Taxes',
      estimatedTotalPrice: 'EST. ORDER TOTAL',
      total: 'Total',
    },
    stockToricPage: {
      lensPower: 'Lens power',
      lensAxis: 'Lens axis',
      message: 'Select power to see fitted lens axis',
    },
    cartPage: {
      PRACTICE_ORDER: 'Practice orders',
      summary: 'Summary',
      goToCheckout: 'GO TO CHECKOUT',
      ShippingAddress: 'Shipping address',
      DeliveryMethod: 'Delivery method',
      calcFinalPrice: 'CALCULATE FINAL PRICE',
      DIRECT_TO_PATIENT: 'Direct to patient',
      deliveryMethod: 'Delivery Method:',
      deliveryMethodDetails: 'Standard delivery (2-3 working days)',
      IndividualOrders: 'Individual orders',
      StockOrders: 'Stock orders',
      ValuePacks: 'Value packs',
      DirecttoPatient: 'Direct to patients',
      products: 'Products',
      shippingsuggestion: 'Shipping will be calculated at checkout.',
      patients: 'patients',
      externalOrderMsg: 'Final price will be revealed on the invoice',
      cancelOrder: 'CANCEL ORDER',
    },
    orderConfirmation: {
      orderNumber: 'Order number:',
      shippingAddress: 'Shipping address:',
      deliveryMethod: 'Delivery Method:',
      deliveryMethodDetails: 'Standard delivery via post',
      poNumber: 'PO number:',
      valuePackQuantity: '1 item',
    },
    checkoutPage: {
      paymentMethod: 'Payment method',
      paymentType1: 'Credit card',
      paymentType2: 'Invoice to account',
      valuePackQuantity: '1 item',
      poNumber: 'PO number:',
      LP: 'Power',
      AX: 'Axis',
      CY: 'Cylinder',
      Colour: 'Colour',
      DIA: 'DIA',
      BC: 'BC',
      ADD: 'ADD',
      print: 'Print',
      finalPrice: {
        products: 'Products',
        delivery: 'Delivery',
        taxes: 'Taxes',
        total: 'TOTAL',
        placeOrder: 'Place Order',
        obligationText: 'with obligation to pay',
        estimatedDelivery: 'Estimated delivery time:',
        loaderMessage: 'Please wait a moment, we are collecting the data',
      },
      delivery: 'Delivery:',
      practiceOrders: 'Practice orders ',
      orderTypes: {
        individual: 'Individual orders',
        valuePack: 'value packs',
        stock: 'stock orders',
      },
      practiceOrdersPanel: 'Individual orders, value packs, stock orders',
      directtoPatients: 'Direct to patients ',
      seeAll: 'See all',
      patients: 'patients',
      addNewCardHeading: 'Add a new card',
      setDefaultCard: 'Set this card as default',
      selectPaymentCard: 'Select payment card',
      selectCardtitle: 'Select a payment card from your saved cards or add a new card.',
    },
    myPatientsPage: {
      addItems: '+ ADD PATIENT',
      addNewPatient: {
        heading: 'Add new patient',
        editHeading: 'Edit patient information',
        personalData: 'Personal data',
        mainAddress: 'Main address',
        additionalAddress: 'Additional address',
        form: {
          title: 'Title',
          firstName: 'First name *',
          surName: 'Surname *',
          email: 'E-mail address',
          phoneNumber: 'Phone number',
          referenceNumber: 'Reference number',
          address: {
            street: 'Street and number *',
            building: 'Building / apartment / other',
            postcode: 'Postcode *',
            city: 'City *',
            county: 'State',
            notMandatoryCounty: 'State',
            country: 'Country *',
          },
          mandatoryFields: '* Mandatory fields',
          additionalAddress: 'Add additional address',
          deleteadditionaladdress: 'Delete additional address',
          clearAll: 'Clear all',
          save: 'save',
          cancel: 'Cancel',
          update: 'Update',
          errorMessages: {
            title: 'Please select title.',
            mandatoryField: 'This field is mandatory.',
            firstName: `Please fill in the patient's first name.`,
            surName: `Please fill in the patient's last name.`,
            email: 'E-mail is in the incorrect format. Please use the following format: john@gmail.com',
            phoneNumber: 'You cannot use letters in this field.',
            phoneNumberMinLength: 'Phone number is too short. Please verify if you entered all characters.',
            moreThanThirtyCharacters: 'You cannot enter more than 30 characters in this field.',
            street: `Please fill in the patient's street address.`,
            buildingNumberName: `Please fill in the patient's building number.`,
            postcode: `Please fill in the patient's postcode.`,
            city: `Please fill in the patient's city.`,
            county: `Please fill in the patient's state.`,
            country: `Please fill in the patient's country.`,
            invalidPattren: 'Invalid postcode',
            selectCountry: 'Please select a country',
          },
        },
        patientAddedSuccessfully: 'Patient has been successfully added',
      },
      address: 'Address',
      showMore: 'Show More',
      showLess: 'Show Less',
      mostRecentOrders: 'Most recent orders',
      order: 'Order',
      orderNo: 'Order no. ',
      subscription: 'Subscription',
      delivery: 'Delivery',
      shippingAddress: 'Shipping address',
      status: 'Status',
      packtype: 'Pack type',
      date: 'Date',
      frequency: 'Frequency',
      addOrder: 'Add order',
      addOrderButton: '+ ADD ORDER',
      options: 'Options',
      active: 'active',
      inactive: 'inactive',
      switchtoSubscription: 'Switch to subscription',
      sort: 'Sort:',
      orderHistory: 'Order history',
      editPatient: 'Edit patient',
      deletePatient: 'Delete patient',
      search: 'Search patient name, reference no.',
      noPatientFound: 'You have no patients yet. Add your first patient by clicking the button below.',
      searchNoPatient: 'No patient found. Check if you typed everything correctly.',
      top: 'Top',
      fetchingOrders: 'Fetching Orders ...',
    },
    patientProfilePage: {
      personalData: 'Personal data',
      mainAddress: 'Main address',
      additionalAddress: 'Additional address',
      edit: 'Edit',
      tel: 'Tel: ',
      eMail: 'E-mail: ',
      refNumber: 'Reference no.: ',
      addOrder: '+ ADD ORDER',
      noOrder: 'There are no orders yet',
    },
    orderHistoryPage: {
      orderPlaced: 'Order placed',
      orderNo: 'Order no.',
      type: 'Type',
      totalPrice: 'Total price',
      status: 'Status',
      invoice: 'Invoice',
      search: 'Search order number or PO number',
      searchButton: 'search',
      timePeriod: 'Time period:',
      dateRange: 'Date range:',
      selectDate: 'Select date(s)',
      sort: 'Sort:',
      reorder: 'REORDER',
      viewOrderdetails: 'View order details',
      noOrders: 'You have not ordered any products yet.',
      noOrderText: 'When you submit an order, you will be able to view the status and details of your order here.',
      filter: {
        ALL: 'All',
        ACTIVE: 'Active',
        COMPLETED: 'Completed',
        STATUS: 'Status',
        filterPlus: '+  Filters',
        filterMinus: '-  Filters',
        orderStatus: {
          NEW: 'New',
          IN_PROGRESS: 'In progress',
          SHIPPED: 'Shipped',
          CANCELLED: 'Cancelled',
          DELIVERED: 'Delivered',
          PARTIAL_BACKORDER: 'Partial backorder',
          BACKORDER: 'Backorder',
        },
      },
      showMore: 'SHOW 10 MORE OUT OF',
      noResults: 'No orders found. Check if you typed correct number or try with another one.',
      noResultsFilter: 'No orders found for the specified filter criteria',
      cancel: 'CANCEL',
    },
    baseLoginPage: {
      youWereLoggedOut: 'You were logged-out',
      logoutMsg:
        'Your session has been timed out. For the safety of you and your patients, you have been automatically logged out.',
      welcome: 'Welcome',
      backToLogin: 'Back to login page',
    },
    sxRegistration: {
      consentLinks: {
        termsCondition: 'https://www.au.alcon.com/terms-use',
        privacyPolicy: 'https://www.au.alcon.com/privacy-policy',
        marketingPrivacyPolicy: 'https://www.au.alcon.com/privacy-policy',
      },
    },
    orderStatus: {
      NEW: 'New',
      IN_PROGRESS: 'In progress',
      SHIPPED: 'Shipped',
      CANCELLED: 'Cancelled',
      PENDING_CONSOLIDATION: 'Waiting for order consolidation',
      DELIVERED: 'Delivered',
      NOT_SHOWN: 'Not_Shown',
      BILLED: 'Billed',
      PARTIAL_BACKORDER: 'Partial backorder',
      BACKORDER: 'Backorder',
    },
    confirmEmail: {
      success: 'E-mail address confirmed. Our sales representative will contact you shortly.',
      failure: 'Link expired. Please fill out registration form again.',
    },
    registerPage: {
      registrationFormTitle:
        'Open an account to gain 24/7 access to online ordering, invoices, statements, order tracking, and other account management features.',
      imAlconCustomer: 'I am an Alcon customer',
      iDoNotHaveAnAccountTitle: 'I do not have Alcon account yet',
      iDoNotHaveAnAccountDescription:
        'That is no problem at all! You can leave your contact details and Alcon sales representative will contact you shortly!',
      termsAndConditions: 'Terms and conditions',
      consent: 'Consent',
      termsAndConditionTitle: 'Registration to Alcon e-commerce',
      termsAndConditionLabel: `I have read and agreed to the terms and conditions stated in <a src="#">Alcon Eye Care UK Limited General Conditions of Sale.</a>*`,
      termsAndConditionDisclaimer: `Alcon will always handle your personal data in accordance with all applicable laws. </br></br>Alcon is part of the Alcon Group, which is a global group of companies and has databases in different countries, some of which are operated by the local Alcon affiliate, and some of which are operated by third parties on behalf of the local Alcon affiliate. Alcon may transfer your data to one or more of its affiliates’ databases outside your country of domicile, including countries which may not require an adequate level of protection for your personal data compared with that provided in the UK or EU.</br></br>Alcon will not sell your personal data to any third parties for any purpose.</br></br>If you wish to contact us regarding our use of your personal data, change your preferences or object to the processing of your personal data, please email us at privacy.uk@alcon.com.`,
      setUpAnAccount: 'Set up an account',
      title: 'Title',
      firstName: 'First name*',
      lastName: 'Last name*',
      fullCompanyName: 'Full company name*',
      email: 'E-mail address for MyAlcon Store account*',
      accountNumber: 'Alcon account number*',
      next: 'Next',
      back: 'Back',
      cancel: 'Cancel',
      submit: 'Submit',
      mandatory: '* Mandatory fields',
      successText: `A copy of this form will be sent to your inbox.</br></br>Please note - it can take up to three working days to set up a new account from the day we receive the completed form including a valid GOC / GMC number.`,
      successTitle: 'Thank you for completing your application form to open an account with Alcon Eye Care UK Limited.',
      goToLoginPage: 'Go to login page',
      TypeOfProducts: 'Type of products*',
      selectProductType: 'Select product type',
      productTypeSurgical: 'IOLs and Surgical products',
      productTypeVisionCare: 'Contact lenses and Vision Care products',
      errorMessages: {
        mandatoryField: 'This field is mandatory.',
        maxLengthReached: 'You cannot use more than {{characterNumber}} characters in this field.',
        couldContainsOnlyNumbers: 'You cannot use letters in this field.',
        firstName: 'Please fill in First name',
        lastName: 'Please fill in Last name',
        fullCompanyName: 'Please fill in Full company name',
        email: 'Please fill in E-mail address',
        emailPattern: 'E-mail is in the incorrect format. Please use the following format: john@gmail.com',
        accountNumber: 'Please fill in Alcon account number',
        termsAndConditions: 'Please accept the mandatory fields',
        responseError: 'There were some issue with your request. Please try again or contact customer service',
        TypeOfProducts: 'Please select type of product',
      },
    },
    contactusPage: {
      pleaseSelectATopic: 'Please select a topic:*',
      typeYourMessageHere: 'Type your message here:*',
      contactMethod: 'Please select your preferred contact method for this request:*',
      contactMethodLabel1: 'E-mail',
      contactMethodLabel2: 'Phone',
      emailAddress: 'E-mail address',
      emailInfo:
        'Information entered above does not change the contact information associated with your account. You can change your phone number in My Profile or contact Customer Service to change your e-mail.',
      termsConditions: `Alcon will use this information to respond to your request. I have read Alcon's Privacy Policy and agree to its terms.*`,
      preferredTime: 'Please select your preferred time:*',
      preferredTimeLabel1: 'Morning',
      preferredTimeLabel2: 'Afternoon',
      contactName: 'Please provide a contact name:*',
      contactNameLabel: ' Contact name',
      fullCompanyName: 'Full company name',
      returnToLastVisitedPage: 'Return to last visited page',
      mandatoryFields: '*  Mandatory fields',
      provideContactName: 'Please provide a contact name:*',
      contactusSubmit: 'Submit',
      phoneNumber: 'Phone number',
      characters: '1000 characters',
      thankyoumsg: 'Thank you for your submission',
      additionalmsg: 'An Alcon representative will follow up with you regarding this matter within 1 business day.',
      returntoHome: 'Return to homepage',
      returnProdct: 'Return product',
      gotoform: 'Go to form',
      selectFile: 'Select file',
      errorMessages: {
        topic: 'Please select Topic.',
        mandatoryField: 'This field is mandatory.',
        message: `Please fill in the message.`,
        contactmethod: 'Please select at least one contact method',
        email: 'Please fill in the email',
        emailPattren: 'E-mail is in the incorrect format. Please use the following format: john@gmail.com',
        phoneNumber: `Please fill in the phone number.`,
        phoneNumberCharecters: 'You cannot use letters in this field.',
        phoneNumberMinLength: 'Phone number is too short. Please verify if you entered all characters.',
        preferredTime: 'Please select preferred contact time',
        contactName: 'Please fill in the contact name',
        termsConditions: 'Please select terms & conditions',
        contactNamePattern: 'You cannot enter more than 30 characters in this field',
      },
    },
    selectProductPage: {
      selectPackType: 'Select pack type',
      selectProducts: 'Select products',
      regular: 'Regular',
      valuePack: 'Value pack',
      chooseLenses: 'Choose lenses:*',
      patientName: 'Patient: ',
      valuePacktooltip: 'Certain contact lenses and solutions are offered as a convenient bundle.',
      additionalProducts: 'Choose additional products:',
      contactLens: 'Contact lens solution',
      dryEye: 'Dry eye products',
      addAnotherproduct: 'Add another product',
      mandatory: '*  Mandatory fields',
      addOtherLenses: 'ADD OTHER LENSES',
      valuePackAttributes: {
        threeMonthValuePack: '3-month pack',
        sixMonthValuePack: '6-month pack',
        chooseSolution: 'Choose solution:*',
      },
      patientOrderPanel: {
        delivery: '3. Select delivery',
        practiceOrder: 'Practice Order',
        directToPatient: 'Direct to patient',
        selectOrderType: 'Select order type',
        individual: 'Individual',
        total: 'Total:',
        addToCart: 'ADD TO CART',
        addToCartSuccess: 'ADDED TO CART',
        cancel: 'Cancel',
        address: 'Address:',
        delivertodiffAddress: 'Deliver to different address',
        selectdeliveryAddress: 'Select delivery address:',
        dropdown: {
          buttonText: 'Add patient',
        },
        scheduled: {
          orderType: 'Scheduled',
          repeatEveryText: 'Repeat every:',
          week: 'weeks',
          month: 'months',
          startDate: 'Start Date:',
          save: 'Save',
          startSubscriptions: 'START SUBSCRIPTIONS',
          addToCartNow: 'ADD TO CART NOW',
        },
        title: `Adding to cart failed`,
        description: `Items were not added to the cart. <br> Please resolve issues and try again.`,
        redirectButton: 'OK',
      },
      tooltipText: `You can’t select 2nd product if two eyes are selected. If you want to add another product for the same patient uncheck one eye.`,
    },
    safeOrderingPage: {
      safeOrdering: 'Safe ordering ',
    },
    configureProducts: {
      deliveryPanel: {
        delivery: 'Delivery',
        selectDelivery: 'Select delivery',
        practiceOrder: 'Practice Order',
        directToPatient: 'Direct to patient',
        patientReferencePlaceholder: 'Type reference',
        patientReference: 'Patient reference:',
        patientReferenceMandatory: 'Patient reference:*',
        needStockOrder: 'Need a stock order?',
        practiceErrorMessage: 'You cannot enter more than 30 characters.',
        noResultsFound: 'No results found',
        selectFromList: 'Select from the list',
        deliveryToDifferentAddress: 'Delivery to different address',
      },
    },
    orderDetailsPage: {
      orderNo: 'Order no.',
      shipped: 'Shipped',
      inProgess: 'In progress',
      delivered: 'Delivered',
      billOnly: 'Bill only',
      products: 'Products',
      statusPanel: {
        partiallyShipped: 'Partially Shipped',
        trackingno: 'Tracking no.:',
      },
      paymentMethodPanel: {
        orderPlaced: 'Order placed:',
        orderDate: 'Order date:',
        orderSource: 'Order source:',
        paymentMethod: 'Payment Method:',
        cardEndingWith: 'Card ending with ****',
        invoiceno: 'Invoice no.',
        addNewCard: 'Add new card',
        noCreditCard: 'You have no credit cards linked to this account.',
        Change: 'Change',
        useOneTime: 'USE CARD ONE TIME',
        useAndSaveLater: 'USE AND SAVE FOR LATER',
        useSelectedCard: 'USE SELECTED CARD',
        useAndSaveDefault: 'USE AND SAVE AS DEFAULT',
        useNewCard: 'USE NEW CARD',
      },
      deliveryMethodPanel: {
        practiceOrders: 'Practice orders',
        deliveryMethod: 'Delivery method:',
        shippingAddress: 'Shipping Address:',
        poNumber: 'PO number:',
        DTPOrders: 'Direct to patient',
      },
      finalPrice: {
        reOrder: 'reorder',
      },
      orderItemPage: {
        INDIVIDUAL_ORDER: 'Individual orders',
        STOCK_ORDER: 'Stock order',
        VALUE_PACK: 'Value pack',
        total: 'Total',
        item: '{{count}} item',
        item_plural: '{{count}} items',
        materialNumber: 'Item no.',
        serialNumber: 'Serial no.',
        IOL_Bill: 'IOL Bill',
        diopter: 'Diopter',
        estDeliveryDate: 'Estimated delivery date',
        deliveryDate: 'Delivery date',
        'labelstatus_In transit': 'In transit:',
        'labelstatus_In delivery': 'In delivery:',
        labelstatus_delivered: 'Delivered:',
        labelstatus_undefined: '',
        fromitem: '{{othercount}} from {{count}} items',
        tooltip: '{{othercount}} out of {{count}} items are {{status}}. The rest of the items have different statuses',
      },
    },
    selectDeliveryAddressPage: {
      mainaddress: 'Main address',
      additonaladdress: 'Additional address',
      edit: 'Edit',
      cancel: 'Cancel',
      save: 'Save',
      mandatory: '* Mandatory fields',
    },
    patientAddressPage: {
      heading: 'Address:',
      form: {
        address: {
          street: 'Street and number *',
          building: 'Building / apartment / other',
          postcode: 'Postcode *',
          city: 'City *',
          county: 'State',
          notMandatoryCounty: 'State',
          country: 'Country *',
        },
        mandatoryFields: '* Mandatory fields',
        additionalAddress: 'Add additional address',
        clearAll: 'Clear all',
        save: 'save',
        cancel: 'Cancel',
        update: 'Update',
        deleteadditionaladdress: 'Delete additional address',
        errorMessages: {
          mandatoryField: 'This field is mandatory.',
          street: `Please fill in the patient's street address.`,
          buildingNumberName: `Please fill in the patient's building number.`,
          postcode: `Please fill in the patient's postcode.`,
          city: `Please fill in the patient's city.`,
          county: `Please fill in the patient's state.`,
          country: `Please fill in the patient's country.`,
          invalidPattren: 'Invalid postcode',
          selectCountry: 'Please select a country',
        },
      },
    },
    recentOrders: {
      lastOrders: 'Last orders',
      latestOrders: 'Latest orders',
      seeMore: 'See more in order history',
      noOrders: `You don't have any orders.`,
      noOrderText: 'When you submit an order, you will be able to view the status of your order here. ',
      noOrdersSX: `You have not ordered any products yet. When you submit the order, you will be able to view the status of your order here.`,
    },
    myProfilePage: {
      emailAddress: 'E-mail address:',
      phoneNumber: 'Phone number:',
      password: 'Password',
      changepassword: 'Change password',
      edit: 'Edit',
    },
    contactInformation: {
      title: 'Title',
      firstName: 'First name*',
      lastName: 'Last name*',
      email: 'E-mail address',
      phonenumber: 'Phone number',
      cancel: 'Cancel',
      save: 'Save',
      errorMessages: {
        requireFirstName: 'Please fill in First name',
        moreThanThirtyCharacters: 'You cannot enter more than 30 characters in this field.',
        requireLastName: 'Please fill in Last name',
        phoneNumber: 'You cannot use letters in this field.',
        phoneNumberMinLength: 'Phone number is too short. Please verify if you entered all characters.',
      },
    },
    changePassword: {
      currentpassword: 'Current password*',
      newpassword: 'New password*',
      reenterpassword: 'Re-enter new password*',
      mandatory: '* Mandatory fields',
      showpassword: 'Show password',
      cancel: 'Cancel',
      save: 'Save',
      suggestion: 'Use 8 or more characters with a mix of letters, numbers & symbols',
      errorMessages: {
        requireCurrentpassword: 'Please enter current password',
        requireNewPassword: 'Please enter new password',
        requireReEnterpassword: 'Please re-enter new password',
        match: `Your passwords don't match. Try again.`,
        strong: 'Please choose a stronger password. Try a mix of letters, numbers & symbols.',
        maxCharcters: 'Use 8 characters or more for your password.',
      },
    },
    patientOrderHistory: {
      io: 'Individual Orders ',
      subscription: 'Subscriptions ',
      switchSub: 'Switch to subscription',
      nof: 'No orders found in specified time period',
      orderNo: 'Order no.',
      mainAddress: 'Main address',
      addAddress: 'Additional Address',
      orderPlaced: 'Order placed:',
      orderSent: 'Order sent:',
      nextOrder: 'Next order:',
      subCreated: 'Subscription created:',
      every: 'Every',
      active: 'ACTIVE',
      inactive: 'INACTIVE',
      noOrdersYet: 'No orders yet',
      oneTime: 'One time',
      frequency: 'Frequency',
    },
    accountDetailsPage: {
      requestChangeAddress: 'Request change of address',
      shippingAddress: 'Shipping address',
      billingAddress: 'Billing address',
      orderconsolidationenabled: 'Order consolidation enabled',
      alconAccount: 'Alcon account',
      shippingAccount: 'Shipping account',
      switchText: 'Switch',
    },
    commonTitles: {
      edit: 'Edit',
      delete: 'Delete',
    },
    visioncareSearch: {
      searchFor: 'Search for:',
      searchProductPlaceholder: `Type product's name`,
      searchPatientPlaceholder: `Type patient's name`,
    },
    subscriptions: {
      nosubscriptions: `You don't have any subscriptions`,
      subscriptions: 'Subscriptions',
      allPatients: 'All Patients',
      subscriptionMsg: 'Subscriptions are scheduled orders that are delivered directly to patients or to a practice.',
    },
    returnForm: {
      noProducts: 'There are no products to be returned',
      searchProducts: 'Search product or select from the list',
      selectProductHeader: 'Select the product you want to return:',
      addAnotherProduct: 'Add another product to return:',
      baseCurve: 'Base curve:*',
      power: 'Power:*',
      add: 'Add*:',
      colour: 'Colour*:',
      cylinder: 'Cylinder*:',
      axis: 'Axis*:',
      packSize: 'Pack size:',
      diameter: 'Diameter:',
      ordernumber: 'Order no:*',
      quantity: 'Quantity:*',
      mandatory: '* Mandatory fields',
      delete: 'Delete product',
      save: 'SAVE PRODUCT',
      returnReason: 'Return reason',
      dateoforder: 'Date of order:',
      reasonMandatory: 'Please identify the reason for returning the products *',
      selectreason: 'Select reason',
      quantityError:
        'You cannot choose more products of a given type than was ordered. If you are certain about the product quantity, verify the order number',
      orderNumberError:
        'This order number was already used in your return form. Change your order number or delete this item',
      returnproduct: 'RETURN PRODUCT',
      specifications: {
        baseCurve: 'Base curve:',
        power: 'Power:',
        add: 'Add:',
        colour: 'Colour:',
        cylinder: 'Cylinder:',
        axis: 'Axis:',
        packSize: 'Pack size:',
        diameter: 'Diameter:',
        ordernumber: 'Order no:',
        quantity: 'Qty:',
      },
    },
    statements: {
      statementForDownload: 'Statements for download',
      tooltipText: 'The first statement will be available at the beginning of the next month.',
      downloadText: 'Download statement (PDF)',
    },
    reOrderValidation: {
      heading: 'There is an issue with your reorder',
      ok: 'OK',
      valuePackQauntity: '1 items',
      itemDiscontinued: 'Please contact customer service',
      maxLimit: 'Please submit your current cart before reordering products or reconfigure your order',
      patientDeleted: 'Patient was deleted and is no longer available for ordering',
      valuPackexist: 'Please submit your current cart before reordering products',
      GENERIC_API_ERRORMESSAGE: 'Items were not added to the cart. Please try again later or contact customer service',
      DIRECT_TO_PATIENT_MESSAGE: `Items were added to the cart for patient's main address. Verify address in the cart`,
      GENERIC_MESSAGE: 'Items were added to the cart',
    },
  },
};
