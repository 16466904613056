import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService, UserIdService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import {
  AccessTokenCartItems,
  AccessTokenRequestData,
  CreditCardList,
  Payments,
  PaymentsToken,
} from 'src/app/shared/model/checkout.model';
@Injectable({
  providedIn: 'root',
})
// TODO : Handle error scenarios using global error service
export class CreditCardListService {
  private userId: string;
  constructor(
    private http: HttpClient,
    private userIdService: UserIdService,
    private occEndPoints: OccEndpointsService
  ) {
    this.userIdService
      .getUserId()
      .pipe(take(1))
      .subscribe((userid) => {
        this.userId = userid;
      });
  }

  getListOfCreditcard(): Observable<Payments> {
    return this.userIdService.getUserId().pipe(
      take(1),
      switchMap((userId) => {
        const url = this.occEndPoints.buildUrl(`/users/${userId}/paymentdetails?saved=false`);
        return this.http.get<Payments>(url);
      })
    );
  }

  getTheToken(details: AccessTokenRequestData): Observable<PaymentsToken> {
    const url = this.occEndPoints.buildUrl(`users/${this.userId}/carts/current/accesstoken`);
    return this.http.post<AccessTokenCartItems>(url, details);
  }

  submitForm(accessToken: string, isSaveCard: boolean, isDefaultCard: boolean): Observable<CreditCardList> {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    const params = new HttpParams().set('saved', isSaveCard).set('isDefault', isDefaultCard);
    const url = this.occEndPoints.buildUrl(
      `users/${this.userId}/carts/current/payment/processResponse?id=${accessToken}`
    );
    return this.http.get<CreditCardList>(url, { headers, params });
  }

  getSetCartPaymentTypeEndpoint(userId: string, cartId: string, paymentType: string): Observable<PaymentsToken> {
    const url = this.occEndPoints.buildUrl(`users/${userId}/carts/${cartId}/paymenttype?&paymentType=${paymentType}`);
    return this.http.put<AccessTokenCartItems>(url, {});
  }
}
