<div
  class="favourite"
  [ngClass]="{
    'favourite--sm': iconSize === 'sm',
    'favourite-disabled': clicked
  }"
>
  <cx-icon
    class="icon-favourite"
    [type]="iconSize === 'sm' ? 'HeartEmptySm' : 'HeartEmpty'"
    (click)="addItem()"
    *ngIf="!isFavourite"
  ></cx-icon>
  <cx-icon
    class="icon-favourite"
    [type]="iconSize === 'sm' ? 'HeartFilledSm' : 'HeartFilled'"
    (click)="removeItem()"
    *ngIf="isFavourite && !isWishList"
  ></cx-icon>
  <cx-icon class="icon-favourite" type="IconTrash" (click)="removeItem()" *ngIf="isFavourite && isWishList"></cx-icon>
</div>
