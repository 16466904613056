import { createAction, props } from '@ngrx/store';
import { B2BUnits } from '../States/b2bUnits.state';
export enum B2BUnitsActionTypes {
  loadB2BUnits = '[B2B Units] Load B2B units into store',
  getB2BUnits = '[B2B Units] Get B2B units',
  clearUnitsStore = '[B2B Units] clear store',
}

export const LoadB2BUnits = createAction(B2BUnitsActionTypes.loadB2BUnits);

export const GetB2BUnitsList = createAction(B2BUnitsActionTypes.getB2BUnits, props<{ b2bUnits: Partial<B2BUnits> }>());

export const ClearB2BUnitStore = createAction(B2BUnitsActionTypes.clearUnitsStore);
