<div class="minicart-wrapper__cartinfo">
  <div>
    <img class="minicart-wrapper__cartimage" [attr.src]="getImageUrl(item?.image?.url)" alt="{{ item?.productName }}" />
  </div>
  <div class="minicart-wrapper__productdetails">
    <h4 class="minicart-wrapper__productdetails--cartProductName">{{ item?.productName }}</h4>
    <div class="minicart-wrapper__cartspecification">
      <span *ngIf="item?.eyeSight && item?.eyeSight" class="minicart-wrapper__cartspecification--eyesight">
        {{ 'miniCartPage.' + item?.eyeSight | cxTranslate }}
      </span>
      <span *ngFor="let specification of item?.specifications">
        {{ 'miniCartPage.' + specification?.specificationKey | cxTranslate }}:
        {{ specification?.specificationValue }}
      </span>
      <span>Qty:{{ item?.quantity }}</span
      ><br />
      <label *ngIf="item?.patientName && item?.orderEntryFlowType !== 'DIRECT_TO_PATIENT'"
        >{{ 'miniCartPage.PatientReference' | cxTranslate }}: {{ item.patientName }}</label
      >
    </div>
    <div class="minicart-wrapper__cartbottom">
      <div class="minicart-wrapper__cartbottom--icon-remove">
        <cx-icon class="Remove" type="Remove"> </cx-icon
        ><a href="javascript:void(0)" appRemoveFromCart [entries]="item?.entryNumberList">{{
          'miniCartPage.delete' | cxTranslate
        }}</a>
      </div>
      <label class="minicart-wrapper__formatprice">{{ item?.totalPrice?.formattedValue }} </label>
    </div>
  </div>
</div>
