<div class="cart-wrapper">
  <div
    class="cart-wrapper__cartdetails"
    [ngClass]="{
      'cart-wrapper__border': entryLabel !== 'DIRECT_TO_PATIENT'
    }"
  >
    <div *ngIf="errorMessage" class="cart-wrapper__errormsg">
      {{ errorMessage }}
    </div>
    <div class="cart-wrapper__cartinfo">
      <div class="d-flex flex-grow-1 w-55">
        <div class="cart-wrapper__cartimage--wrap">
          <img class="cart-wrapper__cartimage" [attr.src]="cartEntry?.image?.url" alt="{{ cartEntry?.productName }}" />
        </div>
        <div
          class="cart-wrapper__productdetails"
          [ngClass]="{
            'cart-wrapper__productdetails__mt-10': entryLabel !== 'DIRECT_TO_PATIENT'
          }"
        >
          <app-cart-product-details [cartEntry]="cartEntry" [status]="status"></app-cart-product-details>
          <div class="cart-wrapper__cartbottom" *ngIf="!status">
            <div class="cart-wrapper__cartbottom--icon-remove">
              <div class="d-flex">
                <cx-icon *ngIf="!isOrderConfirmationPage" type="Remove"> </cx-icon>
                <a
                  *ngIf="!isOrderConfirmationPage"
                  href="javascript:void(0)"
                  appRemoveFromCart
                  [entries]="cartEntry?.entryNumberList"
                  >{{ 'miniCartPage.delete' | cxTranslate }}</a
                >
              </div>
              <div class="d-flex ml-25" (click)="editItem()">
                <cx-icon *ngIf="!isCheckoutPage" type="EditBlue"> </cx-icon
                ><span class="cart-wrapper__cartbottom--edit" *ngIf="!isCheckoutPage">{{
                  'miniCartPage.edit' | cxTranslate
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex align-items-center w-45"
        [ngClass]="{
          'cart-wrapper__cartdata': entryLabel !== 'DIRECT_TO_PATIENT'
        }"
      >
        <div class="cart-wrapper__cartreference">
          <label *ngIf="cartEntry?.patientName && entryLabel !== 'DIRECT_TO_PATIENT'" class="cart-wrapper__reference">
            {{ cartEntry?.patientName }}</label
          >
        </div>
        <app-alcon-item-counter
          [minimum]="1"
          *ngIf="!isCheckoutPage && quantityEdit && !status"
          [defaultValue]="cartEntry?.quantity"
          [type]="cartType"
          [cartEntry]="cartEntry"
          [qtyFactor]="(isDTP$ | async) ? 1 : qtyFactor"
          (ValueChanged)="setProductQuantity($event)"
        ></app-alcon-item-counter>
        <label class="cart-wrapper__quantity" *ngIf="isCheckoutPage && quantityEdit && !isOrderConfirmationPage"
          >{{ cartEntry?.quantity }} Qty</label
        >
        <label class="cart-wrapper__quantity" *ngIf="!quantityEdit || orderDetailsPage || isOrderConfirmationPage"
          >{{ cartEntry?.quantity }} items</label
        >
        <label
          class="cart-wrapper__formatprice"
          [ngClass]="{
            'cart-wrapper__confirmationprice': finalPriceCalculated
          }"
        >
          <!--Temp fix R1-->
          <ng-container *ngIf="entryLabel === 'DIRECT_TO_PATIENT' && status === 'New'; else notDTP"> - </ng-container>
          <ng-template #notDTP>
            {{ finalPriceCalculated && !externalOrder ? cartEntry?.totalPrice?.formattedValue : '-' }}
          </ng-template>
        </label>
      </div>
    </div>
  </div>
</div>
<app-modal [modalOptions]="modalOptions" (closeHandeller)="closeModal()">
  <div class="modal-block">
    <ng-container>
      <app-basic-product-edit-selector
        *ngIf="(entryLabel === 'INDIVIDUAL_ORDER' || entryLabel === 'DIRECT_TO_PATIENT') && modalOptions?.open"
        [cartType]="cartType"
        [editProductData]="cartEntry"
        (closeHandeller)="closeModal()"
      ></app-basic-product-edit-selector>
      <app-stockorder-details-panel-component
        *ngIf="entryLabel === 'STOCK_ORDER' && modalOptions?.open"
        [selectedProduct]="cartEntry"
        [cartType]="cartType"
        [editCart]="cartType"
        (closeHandeller)="closeModal()"
      ></app-stockorder-details-panel-component>
    </ng-container>
  </div>
</app-modal>
