import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import { OrderDetailsService } from 'src/app/services/order-details/order-details.service';
import { OrdersInfo } from '../shared/model/orderHistory.model';

@Injectable({ providedIn: 'root' })
export class OrderDetailsResolver implements Resolve<Observable<any>> {
  constructor(private orderDetailsService: OrderDetailsService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<OrdersInfo> {
    const { orderid } = route.params;
    return this.orderDetailsService.getOrderInfo(orderid).pipe(
      first(),
      catchError(() => {
        this.router.navigate(['/']);
        return of();
      })
    );
  }
}
