/**
 * MaskData
 * A module for masking sensitive data so it can be displayed safely to the user
 * CompSci LLC.
 **/ 

import { _ } from 'okta';
import Util from './Util';

//Add functions in here for masking phone numbers

function mask_email_part(str){
	return _.map(str, function (char, idx){
		if(idx == 0 || idx == str.length - 1){
			return char;
		}

		return '*';
	}).join('');
}

function mask_email(email){
	let parts = email.split('@');
	let beforeAt = parts[0];
	let afterAtparts = parts[1].split('.');
	let beforeDot = afterAtparts[0];
	let afterDot = afterAtparts[1];

	let beforeAtmasked = mask_email_part(beforeAt);
	let beforeDotmasked = mask_email_part(beforeDot);

	return beforeAtmasked + '@' + beforeDotmasked + '.' + afterDot;
}


const MaskData = {
	
	mask_emails: function(emails){
		return _.map(emails, function(email){
			return mask_email(email);
		});
	}

};


export default MaskData;