import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AccountSelectorService } from 'src/app/shared-modules/multi-account-selector/services/account-selector.service';
import { GetB2BUnitsList, LoadB2BUnits } from '../Actions/b2bUnits.action';

@Injectable()
export class B2BUnitsEffects {
  constructor(private actions$: Actions, private accountSelectorService: AccountSelectorService) {}

  loadItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoadB2BUnits),
      mergeMap(() =>
        this.accountSelectorService.getB2bUnits().pipe(
          map((b2bUnits: any) => {
            return GetB2BUnitsList({ b2bUnits });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });
}
