import { createAction, props } from '@ngrx/store';
// import { ValuePackVarients } from '../States/valuePack.state';

export enum ActionTypes {
  retrievedValuePackVarients = '[ValuePack] Load value pack related varient success.',
  getValuePackVarients = '[ValuePack] Get value pack related varient tree from server.',
}
export const RetrievedValuePackVarients = createAction(
  ActionTypes.retrievedValuePackVarients,
  props<{ valuePackVarients: any }>()
);
export const GetValuePackVarients = createAction(ActionTypes.getValuePackVarients);
