<div class="modal-header">
  <cx-icon role="button" type="Close" class="btn-close closebtn" (click)="closePopup()"></cx-icon>
</div>
<div class="modal-body">
  <app-ship-to-selector
    *ngIf="!showSoldToSelector"
    (redirect)="redirect($event)"
    (redirectOnSoldTo)="redirectOnSoldTo($event)"
    [shipTOAddresses]="shipToAddressesDetails"
    [ifShipToSelector]="!showSoldToSelector"
  ></app-ship-to-selector>
  <app-sold-to-selector *ngIf="showSoldToSelector" (redirect)="redirect()" [isFromPage]="false"> </app-sold-to-selector>
</div>
