import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductSearchService } from '@spartacus/core';
import { DropdownConfig, DropdownOptions } from 'src/app/shared/model/common.mode';
import { VisionCareAddtocartProductRequest } from 'src/app/store/States/cartItem.state';
import {
  BasicProductSelectedCheckboxStatus,
  BasicProductSelectorPanelConfig,
  QuantityErrorCodes,
} from '../model/product.model';

@Component({
  template: '',
})
export abstract class WrapperProductComponent {
  @Input() selectedProduct: string;
  @Input() refreshProductPanel;
  @Input() disable;
  @Output() selectedProductValueEmit: EventEmitter<any> = new EventEmitter();

  selectedContactLensValue: Array<Array<VisionCareAddtocartProductRequest>> = [[]];

  showLoader = false;
  productsList: DropdownOptions[];
  clcList: DropdownOptions[] = [];
  dryeyeList: DropdownOptions[] = [];
  secondProductsList: DropdownOptions[] = [];
  firstproductsList: DropdownOptions[] = [];
  lensesLabel: DropdownConfig = {
    defaultSelectText: 'Select',
  };
  firstProduct: DropdownOptions;
  secondProduct: DropdownOptions;
  disableAddLens = true;
  disableCheckbox = false;
  selectedProductValue: Array<VisionCareAddtocartProductRequest> = [];
  selectedSolutionsValue: Array<Array<VisionCareAddtocartProductRequest>> = [[]];

  private panelErrors: Array<{ baseProductCode: string; isDTPError: boolean }> = [];
  get isDTPError(): boolean {
    const isError = !!this.panelErrors.find((errorPanel) => errorPanel.isDTPError);
    return isError;
  }
  isDTP = false;
  addLens = false;

  checkBoxStatus: BasicProductSelectedCheckboxStatus;
  panelConfiguration: BasicProductSelectorPanelConfig = {
    checkboxStatus: {
      leftEye: false,
      rightEye: false,
      bothEye: false,
    } as BasicProductSelectedCheckboxStatus,
    solutions: {
      multiLine: false,
    },
  };
  resetConfig: BasicProductSelectorPanelConfig = {
    checkboxStatus: {
      leftEye: true,
      rightEye: true,
      bothEye: false,
    } as BasicProductSelectedCheckboxStatus,
    solutions: {
      multiLine: false,
    },
  };
  constructor(public productSearchService: ProductSearchService, public cd: ChangeDetectorRef) {}
  productSearchResult(val?): void {
    const query = ':relevance:allCategories:' + val;
    this.productSearchService.search(query, { pageSize: 10000 });
  }

  firstProductDrpdwnSelected(product: DropdownOptions, resetDropDown: boolean = true): void {
    this.disableAddLens = true;
    this.disableCheckbox = false;
    this.removePanelErrors(this.firstProduct?.value);
    this.firstProduct = null;
    this.cd.detectChanges();
    this.firstProduct = product;
    this.secondProductsList = this.productsList.filter((val) => val.text !== this.firstProduct?.text);
    this.secondProductsList.forEach((prd) => {
      prd.selected = this.secondProduct && prd.value === this.secondProduct.value;
    });
    if (resetDropDown) {
      this.selectedContactLensValue[0] = [];
      this.secondProductsList.forEach((productName) => (productName.selected = false));
      this.secondProduct = undefined;
      this.addLens = false;
      this.updateSelectedProductValue();
    }
  }
  updateSelectedProductValue(): void {
    const selectedValues = this.selectedContactLensValue.reduce((a, b) => [...a, ...b], []);
    if (selectedValues.length < this.selectedContactLensValue.length) {
      this.selectedProductValue = [];
      this.selectedProductValueEmit.emit(this.selectedProductValue);
      return;
    }
    if (!this.isDTPError) {
      this.selectedProductValue = [...selectedValues];
    } else {
      this.selectedProductValue = [];
      this.selectedProductValueEmit.emit(this.selectedProductValue);
      return;
    }

    this.selectedProductValue = [...this.selectedProductValue];
    this.selectedProductValueEmit.emit(this.selectedProductValue);
  }

  setPanelErrors(value: boolean | QuantityErrorCodes, productCode: string): void {
    this.pushPanelErrors(productCode, value === QuantityErrorCodes.DTP_MAX_QUANTITY);
  }

  secondProductDrpdwnSelected(product: DropdownOptions, resetDropDown: boolean = true): void {
    this.removePanelErrors(this.secondProduct?.value);
    this.secondProduct = null;
    this.cd.detectChanges();
    this.secondProduct = product;
    this.firstproductsList = this.productsList.filter((val) => val.text !== this.secondProduct.text);
    this.firstproductsList.forEach((prd) => {
      prd.selected = this.firstProduct && prd.value === this.firstProduct.value;
    });
    if (resetDropDown) {
      this.selectedContactLensValue[1] = [];
      this.updateSelectedProductValue();
    }
  }

  selectedProductLensValue(val, index): void {
    this.selectedContactLensValue[index] = val;
    this.refreshProductPanel = true;
    this.updateSelectedProductValue();
  }
  pushPanelErrors(baseProductCode: string, isDTPError: boolean): void {
    const panel = this.panelErrors.find((panelError) => panelError.baseProductCode === baseProductCode);
    if (!panel) {
      this.panelErrors.push({
        baseProductCode,
        isDTPError,
      });
    } else {
      panel.isDTPError = isDTPError;
    }
  }
  removePanelErrors(baseProductCode: string): void {
    this.panelErrors.splice(
      this.panelErrors.findIndex((panel) => panel.baseProductCode === baseProductCode),
      1
    );
  }
  checkboxStatusChange(val: BasicProductSelectedCheckboxStatus): void {
    this.checkBoxStatus = val;
    this.disableAddLens = !((val.leftEye && !val.rightEye) || (val.rightEye && !val.leftEye));
  }
  getBasicPanelConfiguration(): BasicProductSelectorPanelConfig {
    if (this.secondProduct) {
      this.disableCheckbox = true;
      return { checkboxStatus: { ...this.checkBoxStatus } } as BasicProductSelectorPanelConfig;
    }
    return {} as BasicProductSelectorPanelConfig;
  }
  deleteAddedItem(): void {
    this.firstproductsList = [...this.productsList];
    this.firstproductsList.forEach((prd) => {
      prd.selected = this.firstProduct && prd.value === this.firstProduct.value;
    });
    this.addLens = false;
    this.removePanelErrors(this.secondProduct?.value);
    this.secondProduct = null;
    this.disableCheckbox = false;
    if (this.selectedContactLensValue.length > 1) {
      this.selectedContactLensValue.pop();
    }
    this.updateSelectedProductValue();
  }
  addotherLens(): void {
    this.selectedContactLensValue[1] = [];
    this.addLens = true;
    this.panelConfiguration.checkboxStatus.bothEye = this.checkBoxStatus.bothEye;
    this.panelConfiguration.checkboxStatus.leftEye = !this.checkBoxStatus.leftEye;
    this.panelConfiguration.checkboxStatus.rightEye = !this.checkBoxStatus.rightEye;
    this.disableCheckbox = true;
    this.updateSelectedProductValue();
  }
}
