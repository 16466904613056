import {
  Directive,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CartItemsService } from 'src/app/services/cart-items/cart-items.service';
import { PatientListService } from '../services/patients-list/patient-list.service';
import { Utilities } from '../shared/utility/utility';
import { RecurrencePeriod, ScheduledOrderData, VisionCareAddToCart } from '../store/States/cartItem.state';
import { PatientOrderData } from '../store/States/orderHistory';
import { AlconUserPermissionService } from '../shared-modules/alcon-user-permission-provider/service/alcon-user-permission.service';

@Directive({
  selector: '[appSaveScheduledOrder]',
})
export class SaveScheduledOrderDirective implements OnInit, OnChanges, OnDestroy {
  @Input() scheduledOrderData: ScheduledOrderData;
  @Input() product: any;
  @Input() maxQuantity: number;
  emitsuccess: boolean;
  errorMessage: string;
  isPageLoad = false;
  @HostBinding('disabled') disable = true;
  @Input() set disableButton(value) {
    this.disable = this.alconUserPermissionService.getAddToCartEnabled() ? value : true;
  }
  @Input() editScheduledOrderList: PatientOrderData;
  @Output() orderScheduledLoad = new EventEmitter();
  @Output() orderScheduledSuccess = new EventEmitter();
  @Output() cartError = new EventEmitter();
  @Output() emitErrorMessage = new EventEmitter();
  private destroy$ = new Subject<void>();
  constructor(
    private patientListService: PatientListService,
    private cartItemsService: CartItemsService,
    private alconUserPermissionService: AlconUserPermissionService
  ) {}

  public ngOnInit(): void {
    this.isPageLoad = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.product) {
      this.enableAddToCart();
    }
  }

  private enableAddToCart(): void {
    // Also checks for repeat every field in case of schedule order
    if (this.isPageLoad) {
      // to disable save button in edit mode until some changes happened
      this.disable = true;
      this.isPageLoad = false;
    } else {
      if (this.product?.length === 0 || !this.scheduledOrderData.repeatOrderValue) {
        this.disable = true;
      } else {
        this.disable = this.alconUserPermissionService.getAddToCartEnabled() ? false : true;
      }
    }
  }
  checkValidation(data): void {
    if (data && !data.cartError) {
      if (!this.emitsuccess) {
        this.disable = false;
        this.orderScheduledSuccess.emit();
      }
    } else {
      this.errorMessage = data.cartError.error.errors[0].message;
      if (!this.emitsuccess) {
        this.disable = true;
        this.emitErrorMessage.emit(this.errorMessage);
        this.orderScheduledLoad.emit(false);
      }
    }
    this.emitsuccess = true;
  }

  @HostListener('click', ['$event']) saveScheduledOrder(e): void {
    e.preventDefault();
    this.emitsuccess = false;
    this.disable = true;
    this.orderScheduledLoad.emit(true);
    const VCAddtocartItems: VisionCareAddToCart = {
      recurrencePeriod: this.scheduledOrderData.repeatOrderOption,
      numberOfWeeks:
        this.scheduledOrderData.repeatOrderOption === RecurrencePeriod.weekly
          ? this.scheduledOrderData.repeatOrderValue
          : '0',
      nthDayOfMonth:
        this.scheduledOrderData.repeatOrderOption === RecurrencePeriod.monthly
          ? this.scheduledOrderData.repeatOrderValue
          : '0',
      scheduledStartDate: Utilities.isoDateWithoutTimeZone(this.scheduledOrderData.defaultDate),
      entries: this.product,
      orderFlowType: this.product[0]?.orderEntryFlowType,
      trialProduct: false,
    };
    let sheduledOrderObj;
    if (this.editScheduledOrderList) {
      sheduledOrderObj = { VCAddtocartItems, code: this.editScheduledOrderList.replenishmentOrderCode };
    } else {
      sheduledOrderObj = { VCAddtocartItems };
    }
    this.cartItemsService
      .ScheduledOrder(sheduledOrderObj)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.checkValidation(res);
      });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
