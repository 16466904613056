import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { ContactUsService } from 'src/app/services/contact-us/contact-us.service';
import { ContactUsModel } from 'src/app/shared/model/contactus.model';

@Injectable({ providedIn: 'root' })
export class ContactUsResolver implements Resolve<Observable<any>> {
  constructor(private contactUsService: ContactUsService) {}

  resolve(): Observable<ContactUsModel> {
    return this.contactUsService.getContactUsDetails();
  }
}
