import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { InvoicesService } from '../services/invoices.service';

@Injectable({
  providedIn: 'root',
})
export class InvoicesResolver implements Resolve<Observable<any>> {
  constructor(private invoiceService: InvoicesService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.invoiceService.getInvoicesList(route.queryParams);
  }
}
