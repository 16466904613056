<div class="cart-product" [ngClass]="{ 'pt--30': isFromValuePack }">
  <div *ngIf="pageType !== 'orderConfirmation'" class="d-lg-none">
    <ng-container
      *ngTemplateOutlet="productName; context: { $implicit: { view: 'tablet', productName: cartEntry.productName } }"
    >
    </ng-container>
  </div>
  <div class="d-flex cart-product__info">
    <div class="cart-product__image-background d-flex align-items-center">
      <div class="d-flex">
        <cx-media
          class="cart-product__image"
          [container]="cartEntry?.image | imageFormat : 'thumbnail'"
          alt="{{ cartEntry.productName }}"
        ></cx-media>
      </div>
    </div>
    <div class="d-flex justify-content-between flex-column pl--20 cart-product__specs">
      <ng-container
        *ngTemplateOutlet="productName; context: { $implicit: { view: 'desktop', productName: cartEntry.productName } }"
      ></ng-container>
      <div class="cart-product__specs-wrap">
        <app-cart-item-specifications [cartEntry]="cartEntry"></app-cart-item-specifications>
      </div>
      <div *ngIf="!trialOrMlProduct && !isValuePackType && !isOrderDetailsPage && !isConfirmationPage">
        <p
          class="warning-message"
          style="font-size: 0.75rem; font-family: OpenSans-Regular; color: #ff6b13; width: max-content"
        >
          {{ 'pageMetaResolver.product.packSizeWarningMessage' | cxTranslate }}
        </p>
      </div>
      <p *ngIf="showPatientReference && cartEntry?.patientName" class="cart-product__patient-reference">
        {{ 'miniCartPage.patientReference' | cxTranslate }}:
        <span *ngIf="!isConfirmationPage"><br class="d-block d-lg-none" /></span>{{ cartEntry?.patientName }}
      </p>
    </div>
  </div>
</div>

<ng-template #productName let-item>
  <h4
    class="cart-product__name"
    [ngClass]="pageType !== 'orderConfirmation' ? ['cart-product__name--' + item.view] : 'cart-product__name--tablet'"
  >
    {{ item.productName }}
  </h4>
</ng-template>
