import { createReducer, on } from '@ngrx/store';

import { setCountries } from '../Actions/countyCountry.action';
import { CountryState } from '../States/countyCountry.state';

export const initialState: CountryState = null;

export const countryReducer = createReducer(
    initialState,
    on(setCountries, (state, { countries }) => countries)
);



