import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SemanticPathService, WindowRef } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { ShipToSelector } from 'src/app/shared/model/b2b-unit.model';
import { AccountSelectorService } from '../../services/account-selector.service';

@Component({
  selector: 'app-account-selector-modal-wrapper',
  templateUrl: './account-selector-modal-wrapper.component.html',
  styleUrls: ['./account-selector-modal-wrapper.component.scss'],
})
export class AccountSelectorModalWrapperComponent implements OnDestroy {
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private semanticPathService: SemanticPathService,
    private accountSelectorService: AccountSelectorService,
    private windowRef: WindowRef
  ) {}
  @Input() showSoldToSelector: boolean;
  shipToAddressesDetails: ShipToSelector;
  shipToSubscription: Subscription;

  closePopup(): void {
    this.modalService.dismissAll();
  }

  redirect(ifShipTo?): void {
    if (!ifShipTo) {
      this.shipToSubscription = this.accountSelectorService.getShiptoAddresses().subscribe((shipToDetails) => {
        if (shipToDetails.shiptoList.length > 1) {
          this.shipToAddressesDetails = shipToDetails;
          this.showSoldToSelector = false;
        } else {
          const [firstShipTo] = shipToDetails.shiptoList;
          this.accountSelectorService.setLocalStorageShipTo(firstShipTo.sapCustomerId);
          this.accountSelectorService.setLocalStorageShipToCount(false);
          this.closePopup();
          this.reloadPage();
        }
      });
    } else {
      this.closePopup();
      this.reloadPage(true);
    }
  }
  redirectOnSoldTo(isSholdto): void {
    this.showSoldToSelector = isSholdto;
  }
  ngOnDestroy(): void {
    if (this.shipToSubscription) {
      this.shipToSubscription.unsubscribe();
    }
  }
  reloadPage(redirect?: boolean): void {
    if (redirect) {
      // in case of ship-to need to navigate to home and reload
      this.router
        .navigate(
          this.semanticPathService.transform({
            cxRoute: 'home',
          })
        )
        .then(() => {
          this.windowRef.location.reload();
        });
    } else {
      // in case of sold-to need to reload only
      this.windowRef.location.reload();
    }
  }
}
